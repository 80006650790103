import { createAction, props } from '@ngrx/store';
import { IKnowledgeBase } from '../../kb-topic.interface';

export enum ActionTypes {
	COMPANY_KB_TOPICS_UPDATE = '[CompanyGlobalKbTopics] Update',
	COMPANY_GLOBAL_KB_TOPICS_UPDATE_SUCCESS = '[CompanyGlobalKbTopics] Update Success',
}

export const companyGlobalKbTopicsUpdateAction = createAction(
	ActionTypes.COMPANY_KB_TOPICS_UPDATE,
	props<Partial<IKnowledgeBase>>()
);

export const companyGlobalKbTopicsUpdateSuccessAction = createAction(
	ActionTypes.COMPANY_GLOBAL_KB_TOPICS_UPDATE_SUCCESS
);
