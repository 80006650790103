import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'filter-save-layout',
  templateUrl: './save-layout.component.html',
  styleUrls: ['./save-layout.component.scss']
})
export class SaveLayoutComponent implements OnInit, OnChanges{
  @Input() state: 'apply' | 'changed' = 'apply';
  @Input() isProcessing = false;
  @Output() save = new EventEmitter();

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
/*     if(changes['state'] && changes['state'].currentValue){

    } */
  }

  saveLayout(){
    this.save.emit();
  }

}
