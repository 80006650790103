import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { FormAnswerType, FormValidationType, SRType } from "@app/shared/constants";

export interface CustomFormCustomQuestionControl {
	[key: string]: FormGroup<CustomFormCustomQuestionCompanyControl>;
}

export interface CustomFormCustomQuestionCompanyControl {
	[key: string]: FormArray<FormGroup<CustomFormQuestionControl>>;
}

export interface CustomFormControl {
	general?: FormGroup<CustomFormGeneralControl>;
	visibilityBoard?: FormGroup<CustomFormVisibilityControl>;
	questions: FormArray<FormGroup<CustomFormQuestionControl>>;
	// customQuestions: FormArray<FormGroup<CustomFormQuestionControl>>;
	rewst?: FormGroup<CustomFormREWSTControl>;
	isPublish?: FormControl<boolean | null>;
}

export interface CustomFormGeneralControl {
	id: FormControl<number | null>;
	categoryTypeId: FormControl<SRType | null>;
	categoryId: FormControl<number[] | null>;
	categoryIds: FormArray<FormGroup<CustomFormCategoryControl>>;
	title: FormControl<string | null>;
	description: FormControl<string | null>;
	isRewst: FormControl<boolean | null>;
	rewstSubmissionLink: FormControl<string | null>;
  isCustomSummary: FormControl<boolean | null>;
  isCcEmail: FormControl<boolean | null>;
}

export interface CustomFormCategoryControl {
	mainCategoryId: FormControl<number | null>;
	subCategoryIds: FormControl<number[] | null>;
}

export interface CustomFormVisibilityControl {
	// visibilityTypeId: FormControl<VisibilityType|null>;
	verticalMarketId: FormControl<number[] | null>;
	companyId: FormControl<number[] | null>;
	// customBoardCompanyId: FormControl<number[]|null>;
	// commonBoardId: FormControl<number|null>;
	// boardId: FormControl<number|null>;
	defaultBoardId: FormControl<number | null>;
	setDefaultBoard: FormControl<boolean | null>;
	ticketTypeId: FormControl<number | null>;
	ticketSubtypeDetailId: FormControl<number | null>;
	ticketItemDetailId: FormControl<number | null>;
	companyBoardMapping: FormArray<FormGroup<CustomFormCompanyBoardControl>>;
}

export interface CustomFormCompanyBoardControl {
	companyId: FormControl<number | null>;
	boardId: FormControl<number | null>;
	verticalMarketId: FormControl<number | null>;
	// hasDefault: FormControl<boolean|null>;
	ticketTypeId: FormControl<number | null>;
	ticketSubtypeDetailId: FormControl<number | null>;
	ticketItemDetailId: FormControl<number | null>;
	userId: FormControl<number[] | null>;
}

export interface CustomFormREWSTControl {
	url: FormControl<string | null>;
	isActive: FormControl<boolean | null>;
}

export interface CustomFormQuestionControl {
	tempId?: FormControl<number | null>;
	id: FormControl<number | null>;
	parentQuestionId: FormControl<number | null>;
	questionText: FormControl<string | null>;
	subTitle: FormControl<string | null>;
	answerTypeId: FormControl<FormAnswerType | null>;
	required: FormControl<1 | 0 | null>;
	isRewst?: FormControl<boolean | null | undefined>;
	isGraph?: FormControl<boolean | null | undefined>;
	answerList: FormArray<FormGroup<CustomFormAnswerControl>>;
	isRewstDependentQuestion: FormControl<boolean | null>;
	rewstDependencyValue: FormControl<string | null>;
	rewstDependencyQuestionId: FormControl<number | null>;
	graphDatakey: FormControl<string | null>;
	graphDataSubKey: FormControl<string | null>;
	graphFieldName: FormControl<string | null>;
	// options: FormGroup<{
	//     answerCount: FormControl<number|null>;
	//     options: FormArray<FormControl<string|number|null>>;
	// }>;
	answerValidationList: FormArray<FormGroup<CustomFormAValidationControl>>;
	subQuestions: FormArray<FormGroup<CustomFormQuestionControl>>;
	questionAnswerDependencyList: FormArray<
		FormGroup<CustomFormConditionControl>
	>;
	companyId: FormControl<number | null>;
	rewstLink?: FormControl<string | null | undefined>;
	rewstLinkKey?: FormControl<string | null | undefined>;
	rewstLinkValue?: FormControl<string | null | undefined>;
	rewstRequestBody?: FormControl<string | null | undefined>;
	rewstDataObject?: FormControl<string | null | undefined>;
	rewstDataTextField?: FormControl<string | null | undefined>;
	rewstDataValueField?: FormControl<string | null | undefined>;
  isCustomSummary?: FormControl<boolean | null | undefined>;
  isCustomizable?: FormControl<boolean | null | undefined>;
  isCcEmail?: FormControl<boolean | null | undefined>;
  isSummary?: FormControl<boolean | null | undefined>;
}

export interface CustomFormAnswerControl {
	tempId?: FormControl<number | null>;
	id: FormControl<number | null>;
	label: FormControl<string | null>;
}

export interface CustomFormAValidationControl {
	answerValidationTypeId: FormControl<FormValidationType | null>;
	minLength?: FormControl<number | null>;
	maxLength?: FormControl<number | null>;
	minNumericValue?: FormControl<number | null>;
	maxNumericValue?: FormControl<number | null>;
	maxAllowSelected?: FormControl<number | null>;
}

export interface CustomFormConditionControl {
	tempId?: FormControl<number | null>;
	id: FormControl<number | null>;
	action: FormControl<0 | 1 | null>;
	questionId: FormControl<number | null>;
	answerId: FormControl<number[] | null>;
  isNotNull: FormControl<boolean | null>
	// hasValue: FormControl<string|string[]|boolean|Date|number|number[]|null>;
}

export interface CustomForm extends CustomFormGeneral {
	visibilityBoard: CustomFormVisibility;
	questions: CustomFormQuestion[];
	rewst?: CustomFormREWST;
	isPublish: boolean;
	customOrder: any;
  emailList?: any[];
}

export interface CustomFormGeneral {
	id: number;
	categoryTypeId: SRType;
	isRewst: boolean;
	categoryId: number[];
	formCategories?: CustomFormCategory[];
	formCategoryIds?: CustomFormCategory2[];
	title: string;
	description: string;
	companyId: number;
	rewstSubmissionLink: string;
  isCustomSummary: boolean;
  customSummary: string;
  isCcEmail: boolean;
}

export interface CustomFormCategory {
	categoryId: number;
	supportCategoryId: number;
}

export interface CustomFormCategory2 {
	mainCategoryId: number;
	subCategoryIds: number[];
}

export interface CustomFormREWST {
	url: string;
	isActive: boolean;
}

export interface CustomFormVisibility {
	defaultBoardId: number;
	setDefaultBoard: boolean;
	ticketTypeId: number;
	ticketSubtypeDetailId: number;
	ticketItemDetailId: number;
	companyBoardMapping: CustomFormCompanyBoard[];
}

export interface CustomFormCompanyBoard {
	companyId: number;
	// companyName: string;
	boardId: number;
	// boardName: string;
	verticalMarketId: number;
	// defaultBoardId: number;
	// hasDefault: boolean;
	// isDefault: boolean;
	// hideInCustomForm: 1 | 0;
	ticketTypeId?: number;
	ticketSubtypeDetailId?: number;
	ticketItemDetailId?: number;
	userId?: number[];
	// hasUserLevelPermission?: boolean;
}

export interface CustomFormQuestion {
	tempId?: number;
	id: number;
	parentQuestionId: number;
	// globalVisibility: boolean;
	questionText: string;
	subTitle: string;
	answerTypeId: FormAnswerType;
	required: 0 | 1;
	answerList: CustomFormAnswer[] | any[];
	answerValidationList: CustomFormAValidation[];
	subQuestions?: CustomFormQuestion[];
	isRewst?: boolean;
	isGraph?: boolean;
	isRewstDependentQuestion: boolean;
	rewstDependencyValue: string;
	rewstDependencyQuestionId: number;
	questionAnswerDependencyList: CustomFormCondition[];
	graphDatakey: string | null;
	graphDataSubKey: string | null;
	graphFieldName: string | null;
	companyId: number;
	rewstLink?: string | null;
	rewstLinkKey?: string | null;
	rewstLinkValue?: string | null;
	rewstRequestBody?: string | null;
	rewstDataObject?: string | null;
	rewstDataTextField?: string | null;
	rewstDataValueField?: string | null;
	customOrder?: any[];
	optionsForRewst?: any[];
  isCustomSummary?: boolean | null;
  isCustomizable?: boolean | null;
  isCcEmail?: boolean | null;
  isSummary?: boolean | null;
}
export interface CustomFormAnswer {
	tempId?: number;
	id: number;
	label: string;
	[key: string]: any;
}
export interface CustomFormAValidation {
	answerValidationTypeId: FormValidationType;
	minLength?: number;
	maxLength?: number;
	minNumericValue?: number;
	maxNumericValue?: number;
	maxAllowSelected?: number;
}
export interface CustomFormCondition {
	id: number;
	action: 0 | 1;
	questionId: number;
	answerId: number[];
  isNotNull: boolean;
}
