import { Component, Input, OnInit, inject } from '@angular/core';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { SharedModule } from '@app/shared/shared.module';
import { StatusComponent } from '@app/modules/dashboard/pages/dashboard/reporting/project/components/billing-records/status/status.component';

@Component({
	selector: 'app-view-details',
	templateUrl: './view-details.component.html',
	styleUrls: ['./view-details.component.scss'],
	standalone: true,
	imports: [SharedModule, StatusComponent],
})
export class ViewDetailsComponent implements OnInit {
	@Input() data: IDataModal;
	dataModalService = inject(DataModalService);

	constructor() {}

	ngOnInit(): void {}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}
}
