import { Component, OnInit } from '@angular/core';
import { TableMessages } from '@app/shared/constants';


@Component({
  selector: 'app-dummy-widget',
  templateUrl: './dummy-widget.component.html',
  styleUrls: ['./dummy-widget.component.scss']
})
export class DummyWidgetComponent implements OnInit {

  title = 'Application Name';
  displayedColumns = ['title'];
  appData: any[] = [];

  message = {
    noRecord: TableMessages.EmptyTable,
  };

  constructor() { }

  ngOnInit() {
  }

}
