import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { TableMessages } from '@app/shared/constants';
import { SeeMore, ServiceHealthStatus, TableDynamicColumns, TableMultiTemplateRows, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { BehaviorSubject, catchError, map, Observable, of, take, tap } from 'rxjs';

@Component({
  selector: 'app-service-health-microsoft-widget',
  templateUrl: './service-health-microsoft-widget.component.html',
  styleUrls: ['./service-health-microsoft-widget.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ServiceHealthMicrosoftWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild('dropdown') dropDownTpl: TemplateRef<any>

  serviceHealthStatus$: Observable<ServiceHealthStatus[] | null>;
  //displayedColumns = ['issueId', 'serviceName', 'serviceStatus'];
  dataSource:Observable<ServiceHealthStatus[] | any[]>;
  totalItems = 0;
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  displayedColumnsExpended = [] ; //  [...this.displayedColumns, 'expand']
  isExpanded=false;
  clickedIssueId:string = '';

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  //rowClick: (data:Order)=>void
  noClick: ()=>void
  rowClickable = true;
  isMultiTemplateRows = false
  multiTemplateRows: TableMultiTemplateRows

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dashboardService: DashboardService,
    private _cd: ChangeDetectorRef
  ){
    _dashboardService.initServiceHealthStatus();
    this.serviceHealthStatus$ = this._dashboardService.serviceHealthStatus$;
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  ngOnInit() {
  }

  getData(){
    this.dataSource = this.serviceHealthStatus$
      .pipe(
        map(v=>{
          return v!.map(x=>{
            return {...x, ...{id:x.issueId}}
          })
        }),
        catchError(e=>of([])),
        //tap(value=>this.totalItems=value.length), 
      );
    
    this.dataSource.subscribe(v=>{
      this.#createDataTable(v)
    })
  }

  #createDataTable(status: ServiceHealthStatus[]){
    this.source = status;
    this.columns = this.#getColumns()
    this.displayedColumns = this.#getDisplayedColumns()
    //this.rowClick = this.rowClickFn
    this.isMultiTemplateRows = true
    this.multiTemplateRows = this.#getMultiTemplateRows()
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
  }

  #getColumns(){
    return [{
        text: 'Issue ID',
        matColumnDef: 'issueId',
      },
      {
        text: 'Service',
        matColumnDef:'serviceName',
      },
      {
        text: 'Service Status',
        matColumnDef:'serviceStatus',
      },
    ]
  }

  #getDisplayedColumns(){
    return ['issueId', 'serviceName', 'serviceStatus']
  }

  #getMultiTemplateRows():TableMultiTemplateRows{
    return {
      expandedTd: this.dropDownTpl,
      columns: this.#getMultiTemplateRowsColumn()
    }
  }

  #getMultiTemplateRowsColumn(){
    return [
      {
        text:'Service ID:',
        column: 'serviceId'
      },
      {
        text:'Issue Title:',
        column: 'issueTitle'
      },
      {
        text:'Issue Status:',
        column: 'issueStatus'
      },
      {
        text:'Issue Type:',
        column: 'issueType'
      },
    ]
  }

/*   rowClickFn = ()=>{

  } */

  getDataTable(): WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      //rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      displayedColumns: this.displayedColumns,
      //seeMore: this.seeMore,
      rowClickable: this.rowClickable,
      isMultiTemplateRows: this.isMultiTemplateRows,
      multiTemplateRows: this.multiTemplateRows,
    }
  }

  clickedRow(row:any){
    if(this.clickedIssueId == row.issueId){
      this.clickedIssueId = '';
    }else{
      this.clickedIssueId = row.issueId;
    }
  }

}
