import { createAction, props } from '@ngrx/store';

import { IApplication } from '../../sp-admin/application.interface';

export enum ActionTypes {
	GROUP_APPLICATION_UPDATE = '[Groups Application List] Update',
	GROUP_APPLICATION_UPDATE_SUCCESS = '[Groups Application List] Update Success',
}

export const groupApplicationUpdateAction = createAction(
	ActionTypes.GROUP_APPLICATION_UPDATE,
	props<Partial<IApplication>>()
);

export const groupApplicationUpdateSuccessAction = createAction(
	ActionTypes.GROUP_APPLICATION_UPDATE_SUCCESS
);
