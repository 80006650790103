<a
	class="btn-2-nav sidebar-btn"
	(click)="clearGlobalSearch()"
	routerLinkActive="active-2"
	[routerLink]="[sideBarData.url]"
	#navLink
	[activityTracker]="sideBarData.id"
>
	<app-figma-icon-img
		[iconName]="sideBarData.icon"
		[hasSpaceOnRight]="false"
	></app-figma-icon-img>

	<div>{{ sideBarData.name }}</div>
</a>
