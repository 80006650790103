import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TypingNotAllowedModule } from '@app/shared/directives/typing-not-allowed/typing-not-allowed.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import {
	CustomCalendarHeaderComponent,
	DatePickerComponent,
} from './date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
	declarations: [
		CustomCalendarHeaderComponent,
		DatePickerComponent,
		DateRangePickerComponent,
		TimePickerComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		MatDatepickerModule,
		MatIconModule,
		MatInputModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		TypingNotAllowedModule,
	],
	exports: [
		CustomCalendarHeaderComponent,
		DatePickerComponent,
		DateRangePickerComponent,
		TimePickerComponent,
	],
})
export class DateTimeModule {}
