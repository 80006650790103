<ng-container *ngIf="sidebar$ | async as sidebar">
	<div class="q-d-flex q-jc-center">
		<img
			[src]="collapsed ? faviconPath : logoPath"
			image-error-detection
			component="side-bar.component.html"
			class="sidebar-logo"
			alt="Company Logo"
		/>
	</div>

	<div class="divider q-w-100"></div>

	<div
		#scroll
		qScroll
		class="q-d-flex q-f-column q-w-100 q-f-1 q-g-16 q-scroll qs-floating"
	>
		<ul class="navbar-nav q-g-8 accordion" id="accordion">
			<ng-container *ngFor="let menuItem of sidebar; let i = index">
				<ng-container
					*ngIf="
						menuItem.subMenus &&
							menuItem.subMenus.length > 0 &&
							menuItem.id !== 23 &&
							menuItem.id !== 30 &&
							menuItem.id !== 103 &&
							menuItem.id !== 135 &&
							menuItem.id !== 184 &&
							menuItem.id !== 336 &&
							menuItem.id !== 347 &&
							menuItem.id !== 186;
						else noDropdown
					"
				>
					<li
						app-side-bar-accordion
						class="nav-item"
						[sideBarData]="menuItem"
						[index]="i"
					></li>
				</ng-container>
				<ng-template #noDropdown>
					<li
						*ngIf="menuItem.id !== 197 && menuItem.id !== 206"
						app-side-bar-item
						class="nav-item"
						[sideBarData]="menuItem"
					></li>
				</ng-template>
			</ng-container>
		</ul>
	</div>

	<div
		class="bot-section q-d-flex q-f-column q-g-16 q-w-100 q-mt-auto q-pt-8 q-ai-center"
	>
		<new-update-card
			*ngIf="pwaService.versionCard$ | async as update"
			[status]="update"
			[isCollapsed]="collapsed"
			(closed)="pwaService.closeCard()"
		></new-update-card>

		<div
			class="q-d-flex q-w-100 q-ai-center"
			[ngClass]="{ 'q-f-column q-g-16': collapsed }"
		>
			<button
				*ngIf="hasFeedbackUrlLoaded$ | async"
				(click)="submitFeedback()"
				class="q-d-flex q-f-column q-f-1 q-ai-center font-b3 q-pointer q-w-max"
			>
				<mat-icon svgIcon="annotation-check"></mat-icon>
				<div>Feedback</div>
			</button>

			<button
				*ngIf="(user$ | async)?.systemRoleId !== UserTypes.User"
				(click)="switchMode(); scroll.scrollTo({ top: 0 })"
				class="q-d-flex q-f-column q-f-1 q-ai-center font-b3 q-pointer q-w-max"
			>
				<mat-icon svgIcon="repeat-01"></mat-icon>
				<div>{{ isUserMode ? 'Admin' : 'User' }}</div>
			</button>

			<button
				(click)="toggleCollapse()"
				class="q-d-flex q-f-column q-f-1 q-ai-center font-b3 q-pointer q-w-max"
			>
				<mat-icon
					[svgIcon]="collapsed ? 'align-right-01' : 'align-left-01'"
				></mat-icon>
				<div>Collapse</div>
			</button>
		</div>
	</div>
</ng-container>
