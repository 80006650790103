/* Angular Libraries */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/* Third Party Libraries */
import { finalize, Subject, takeUntil, tap } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { FinanceService } from '../../finance.service';
import { InvoiceService } from '../invoices/store/invoice.service';

/* Functions */
import { isValueEmpty } from '@app/shared/utilities/helper'; 

/* Components */
import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

/* Interfaces */
import { IPayments } from '@app/shared/interfaces/invoice.interface';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { ApplicationType } from '@app/shared/constants/global-enum';

@Component({
	selector: 'payments-table',
	templateUrl: './payments-table.component.html',
	styleUrls: ['./payments-table.component.scss'],
})
export class PaymentsTableComponent implements OnInit, OnDestroy {
	/* ViewChild */
	@ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;
	@ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
	@ViewChild('searchTextbox') searchTextbox: ElementRef;

	/* Properties */
	queryString: FormControl = new FormControl<string>('');
	selectedSortColumn: string = 'paymentDate';
	selectedSortType: string = 'desc';
	totalItems = 0;
	selectedPageSize: number = 10;
	selectedPage: number = 1;
	// pageSizes = PaginationConstants.pageSizes;
	searchTimeout: any;
	applicationType = ApplicationType;
	isFilterHaveBeenCalled: boolean = false;
	searchFilters: IPayments;

	/* [Start]::Filter Forms */
	startDateFilter: FormControl<string | null> = new FormControl<string | null>('');
	endDateFilter: FormControl<string | null> = new FormControl<string | null>('');
	/* [End]::Filter Forms */

	private _$unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor(
		private _financeService: FinanceService,
		private _spinner: SpinnerService,
		private _toastMessageService: ToastMessageService,
		private _invoiceService: InvoiceService,
		private _router: Router,
		private _route: ActivatedRoute,
	) {}

	get isFilterOn(): boolean {
		const hasStartDate = !isValueEmpty(this.startDateFilter.value) && ![null, 'Invalid date'].includes(this.startDateFilter.value);
		const hasEndDate = !isValueEmpty(this.endDateFilter.value) && ![null, 'Invalid date'].includes(this.endDateFilter.value);
		return hasStartDate || hasEndDate;
	}

	/* Methods */
	ngOnInit(): void {
		setTimeout(() => {
			this._initFilterValueChanges();
			this._eventListeners();
		}, 0);
	}

	queryChange(delayTime: number = 0) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.isFilterHaveBeenCalled = true;
			this.fetchNewData();
		}, delayTime);
	}

	fetchNewData() {
		const filters: IPayments = this._getFilters();
		this.searchFilters = filters;
	}

	onEmitTotalItems(totalItems: number) {
		this.totalItems = totalItems;
	}

	export(exportType: ApplicationType) {
		const params: any = {
			search: this.queryString.value,
			paymentStartDate: ![null, 'Invalid date'].includes(this.startDateFilter.value) ? this.startDateFilter.value!.trim() : '',
			paymentEndDate: ![null, 'Invalid date'].includes(this.endDateFilter.value) ? this.endDateFilter.value!.trim() : '',
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.selectedPage,
			pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.selectedPageSize,
			column: this.selectedSortColumn,
			order: this.selectedSortType,
			exportType: exportType,
		};

		if (this.totalItems === 0) {
			this._toastMessageService.showErrorMessage(NotificationMessages.NoRecordFound);

		} else {
			this._spinner.start();
			this._financeService
				.exportPayments(params)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (resp) => {
						const link = document.createElement('a');
						link.href = resp;
						link.setAttribute('download', 'Payments');
						document.body.appendChild(link);
						link.click();
					},
					error: () => {
						this._toastMessageService.showErrorMessage(
							NotificationMessages.FailedToGenerateFile
						);
					},
					complete: () => {
						this._toastMessageService.showSuccessMessage(
							NotificationMessages.Export
						);
					},
				});
		}
	}

	downloadReceipt(_invoice: any) {
		this._spinner.start();

		const id = _invoice.referenceNumber ? _invoice.referenceNumber : _invoice.invoiceReceiptId

		this._financeService
			.downloadReceiptByTransaction([id])
			.subscribe({
				next: (data: any) => {
					const link = document.createElement('a');
          link.href = data;
          link.setAttribute('download', `Invoice Receipt ${id}.pdf`);
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
					this._spinner.stop();
				},
				error: () => {
					this._spinner.stop();

					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
			});
	}

	private _viewPdf(data: any, invoiceId: number) {
		this._financeService.pdf$.next(data);
		this._router.navigate(['view-pdf', invoiceId], {
			queryParams: { isInvoiceType: true },
			relativeTo: this._route,
		});
	}

	private _initFilterValueChanges() {
		this.fetchNewData();
		this._valueChanges(this.startDateFilter);
		this._valueChanges(this.endDateFilter);

		setTimeout(() => {
			this.clearFilter.clearAll.pipe(takeUntil(this._$unsubscribe)).subscribe({
				next: () => {
					this.startDateFilter.setValue('');
					this.endDateFilter.setValue('');
					this.isFilterHaveBeenCalled = true;
				},
			});
		}, 100);
	}

	private _valueChanges(prop: FormControl) {
		prop.valueChanges.pipe(takeUntil(this._$unsubscribe)).subscribe({
			next: () => {
				this.isFilterHaveBeenCalled = true;
				this.fetchNewData();
			},
		});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}

	private _getFilters(): IPayments {
		return {
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.selectedPage,
			pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.selectedPageSize,
			column: this.selectedSortColumn,
			order: this.selectedSortType,
			paymentStartDate: ![null, 'Invalid date'].includes(this.startDateFilter.value) ? this.startDateFilter.value!.trim() : '',
			paymentEndDate: ![null, 'Invalid date'].includes(this.endDateFilter.value) ? this.endDateFilter.value!.trim() : '',
			searchText: this.queryString.value!.trim(),
			companyId: this._financeService.companyIdType
		};
	}

	private _eventListeners() {
		this._invoiceService.isLoading$
			.pipe(
				tap((isLoading) => {
					if (isLoading) {
						this.queryString.disable();
					} else {
						this.queryString.enable();
						(this.searchTextbox as any).focus();
					}
				})
			).subscribe()
	}
}
