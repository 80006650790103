import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private _img = 'assets/images/profile-pic/profilePic.png';

  constructor() {}

  isValidImageFile(file: any): boolean {
    if(!file) return false;
    const fileTypes = ['png', 'jpg', 'jpeg', 'webp'];
    const fileType = file.type.substr(6, file.type.length);
    const isValidType = fileTypes.indexOf(fileType) !== -1;
    const isValidImageFile =
      file && file['type'].split('/')[0] === 'image' && isValidType;
    return isValidImageFile;
  }

  getDefaultProfilePic(): string {
    return this._img;
  }
  getExt(attachment: any) {
    const ext = attachment.name.substring(attachment.name.lastIndexOf('.') + 1)
    if (ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpg' || ext.toLowerCase() === 'svg') {
      return 'image-01';
    } else {
      return 'file-05';
    }
  }
}
