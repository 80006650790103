import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoProfileAvatarComponent } from './no-profile-avatar.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NoProfileAvatarComponent],
  exports:[
    NoProfileAvatarComponent,
  ]
})
export class NoProfileAvatarModule { }
