export function setAppTheme(colorScheme: string, lightThemeColor?: string, darkThemeColor?: string) {
    // Now lightThemeColor and darkThemeColor is arriving here
    // TO DO: way to propagate the changes, and does it look good?
    // Think: way to customize not just the background but the highlight colors as well

    if (colorScheme === 'light') {
        if (lightThemeColor) {
            lightThemeColor = lightThemeColor.indexOf("#") === -1 ? "#" + lightThemeColor : lightThemeColor;
        }
        document.body.classList.remove('dark-body');
        document.body.classList.add(colorScheme + '-body');
    }
    else {
        if (darkThemeColor) {
            darkThemeColor = darkThemeColor.indexOf("#") === -1 ? "#" + darkThemeColor : darkThemeColor;
        }
        document.body.classList.remove('light-body');
        document.body.classList.add(colorScheme + '-body');
    }
}
