import { IInvoices } from './../invoices.interface';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";




export const userInvoicesSelector = (state: AppStateInterface): IInvoices => state.userInvoices;

export const pageSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.page);

export const pageSizeSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.pageSize);

export const orderSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.order);

export const columnSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.column);

export const statusIdsSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.statusIds);

export const invoiceStartDateSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.invoiceStartDate);

export const invoiceEndDateSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.invoiceEndDate);

export const dueStartDateSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.dueStartDate);

export const dueEndDateSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.dueEndDate);

export const haveBalanceSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.haveBalance);

export const companyIdsSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.companyIds);

export const companyIdSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.companyId);

export const invoiceSavedFiltersSelector =
createSelector(userInvoicesSelector, (state: IInvoices)=>state.invoiceSavedFilters);

export const invoiceFilterSelector = 
createSelector(userInvoicesSelector, (state:IInvoices)=>
  ({
    invoiceStartDate: state.invoiceStartDate,
    invoiceEndDate: state.invoiceEndDate,
    dueStartDate: state.dueStartDate,
    dueEndDate: state.dueEndDate,
    statusIds: state.statusIds,
    typeIds: state.typeIds,
    isAutopay: state.isAutopay,
    transactionType: state.transactionType,
  })
)

export const isInvoiceFilterChanged = 
createSelector(
  invoiceFilterSelector,
  invoiceSavedFiltersSelector,
  (filter, savedFilter)=>isSame(filter, savedFilter)
)

function isSame(val1: any, val2: any){
	return JSON.stringify(val1) === JSON.stringify(val2)
}