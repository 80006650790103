<div
	class="add-card-field"
	[ngClass]="{ 'add-cart-active': drawer.openWidgetList }"
	#container
>
	<button
		type="button"
		class="btn-close text-lg py-3 message-close opacity-10 d-flex align-items-center"
		aria-label="Close"
		(click)="closeWidgetList()"
	>
		<app-figma-icon-img
			[iconName]="'x'"
			[isForButton]="true"
			[hasSpaceOnRight]="false"
		></app-figma-icon-img>
	</button>

	<div class="card-title">
		<div class="title-content">
			<h5>Add cards to your home page</h5>
			<p>Drag a card to the location you want. or select Add card (+).</p>
		</div>
		<div class="add-card-search-area" #searchEl>
			<div class="input-group">
				<span class="input-group-text text-body">
					<app-figma-icon-img
						[iconName]="'search-sm'"
						[hasSpaceOnRight]="false"
						[width]="15"
						[height]="15"
					></app-figma-icon-img>
				</span>
				<input
					type="text"
					class="form-control"
					placeholder="Search cards"
					[formControl]="search"
				/>
			</div>
		</div>
	</div>

	<ng-container *ngIf="categories.filtered?.length">
		<div
			class="card-content"
			cdkDropList
			[cdkDropListConnectedTo]="['dashboard']"
			cdkDropListSortingDisabled="true"
			id="widgets"
			*ngFor="let cards of categories.filtered"
		>
			<!-- for category-->

			<ng-container *ngIf="cards.cards.length > 0">
				<h5>{{ cards.name }}</h5>
			</ng-container>
			<ng-container *ngFor="let i of cards.cards; let index = index">
				<div
					cdkDrag
					[cdkDragData]="i"
					(cdkDragStarted)="dragEvent($event, 'start')"
					(cdkDragDropped)="dragEvent($event, 'end')"
					class="card-box"
				>
					<!-- for widget list-->
					<div class="image">
						<img
							image-error-detection
							#cardImg
							[component]="'user-dashboard-card-add-list.component.html'"
							(error)="handleImgError(cardImg, $event)"
							[src]="i.icon ? i.icon : img"
						/>
					</div>
					<div class="card-text">
						<div class="card-text-title">
							<h6>{{ i.title }}</h6>
							<a
								class="cursor-pointer"
								(click)="addCard(i, index)"
								href="javascript:void(0)"
							>
								<app-local-figma-icon-img
									[filename]="'plus-blue'"
									[width]="20"
									[height]="20"
									[hasSpaceOnRight]="false"
								></app-local-figma-icon-img>
							</a>
						</div>
						<p>{{ i.description }}</p>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<ng-container *ngIf="!categories.filteredCount">
		<div class="content-center">
			<strong>No cards to add.</strong>
		</div>
	</ng-container>
</div>
