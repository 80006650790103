import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AccountRoutingModule } from './account-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import { UserComponent } from './pages/user/user.component';
import { NavComponent } from './pages/user/nav/nav.component';
import { ThemeComponent } from './pages/user/theme/theme.component';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { AuthenticationComponent } from './pages/user/authentication/authentication.component';
import { TwoFactorAuthComponent } from './pages/user/authentication/two-factor-auth/two-factor-auth.component';
import { DynamicTableModule } from './pages/user/profile/permission-tab/dynamic-table/dynamic-table.module';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMaskModule } from 'ngx-mask';
import { AuditTrailModule } from '@app/shared/components/audit-trail/audit-trail.module';

@NgModule({
  declarations: [
    UserComponent,
    NavComponent,
    ThemeComponent,
    NotificationsComponent,
    AuthenticationComponent,
    TwoFactorAuthComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule,
    FormTooltipModule,
    ClipboardModule,
    DynamicTableModule,
    MatTabsModule,
    NgxMaskModule.forRoot(),
    AuditTrailModule,
  ],
})
export class AccountModule {}
