import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { SideBarService } from '@app/shared/navigation/side-bar/side-bar.service';

@Component({
  selector: 'app-no-module-available',
  templateUrl: './no-module-available.component.html',
  styleUrls: ['./no-module-available.component.scss']
})
export class NoModuleAvailableComponent implements OnInit {

  constructor(
    private _router: Router,
    private _userService: UserService,
    private _sidebarService: SideBarService
  ) { }

  ngOnInit(): void {

  }

}
