import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserTypes } from '@app/shared/constants';
import { UserService } from '../services/user.service';
import { NavigationService } from '@app/shared/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class SpAdminGuard implements CanActivate {

  constructor(private _userService: UserService, private _navigationService: NavigationService) { }

  async canActivate(): Promise<boolean> {
    const roleId = this._userService.userRole,
    systemRoleId = this._userService.user?.systemRoleId;

    if(roleId === UserTypes.SourcepassAdmin) return true;
    // auto switch role if user's system role is SpAdmin
    else if(systemRoleId === UserTypes.SourcepassAdmin) {
      if(!sessionStorage.getItem('switchingRole')) {
        sessionStorage.setItem('switchingRole', 'true');
        await this._userService.switchUserRole(false);
        sessionStorage.removeItem('switchingRole');

        if(this._userService.userRole === systemRoleId) return true;
        else {
          this._navigationService.smartNavigate();
          return false;
        }
      }
      else return true;
    }
    else {
      this._navigationService.smartNavigate();
      return false;
    }
  }

}
