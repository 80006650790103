import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CheckboxService {
  lastChecked: number | null;
  selectedIds: number[] = [];

  getSelectedId(data: any[] | any, isChecked: boolean): Observable<any[]> {
    const selectedIds = this.collateIds(data, isChecked);
    return of(selectedIds);
  }

  collateIds(value: any[] | any, isChecked: boolean) {
    let selectedIds = this.selectedIds;
    if (Array.isArray(value)) {
      if (isChecked === true) {
        const filteredEventValue = value.filter(x => {
          return !selectedIds.includes(x);
        });
        filteredEventValue.forEach(x => {
          selectedIds.push(x);
        });
      } else {
        const selected = value as Number[];
        selectedIds = selectedIds.filter(x => {
          return selected.findIndex(val => val === x) === -1;
        });
      }
    } else {
      if (isChecked === true) {
        selectedIds.push(value);
      } else {
        const id = selectedIds.findIndex((e) => {
          return e === value;
        });
        selectedIds.splice(id, 1);
      }
    }
    return selectedIds;
  }

  clearSelectedIds() {
    this.selectedIds = [];
  }
}
