import {
	ChangeDetectionStrategy,
	Component,
	ChangeDetectorRef,
	Input,
	SimpleChanges,
	OnChanges,
} from '@angular/core';
import { Params } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import OrgChart from 'assets/balkanapp/orgchart';

import { YesNoModalComponent } from '../../modal/yes-no-modal/yes-no-modal.component';

import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ImageService } from '@app/shared/services/image.service';

@Component({
	selector: 'app-org-chart',
	templateUrl: './org-chart.component.html',
	styleUrls: ['./org-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgChartComponent implements OnChanges {
	@Input() companyId: number;
	@Input() employeeId: number;
	@Input() level: number;
	@Input() refreshOrgChart: boolean;

	private _payload: Params;
	private _levelOptions: any[] = [];
	private _chart: OrgChart;

	constructor(
		public spinner: SpinnerService,
		public dialog: MatDialog,
		private _cd: ChangeDetectorRef,
		private _companiesService: CompaniesService,
		private _notifService: NotificationService,
		private _userService: UserService,
		private _imageService: ImageService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		// Don't remove the undefined checking.
		// There are instance that the value of the company id and employeee id is zero
		// Which is read as false in if statement
		if (
			(this.companyId !== undefined && this.employeeId !== undefined) ||
			changes?.['refreshOrgChart']?.currentValue
		) {
			this.fetchNewData();
		}
	}

	fetchNewData() {
		this._companiesService
			.getOrgChartByUserId(this.companyId, this.employeeId, this.level)
			.subscribe((data: any) => {
				const defaultPic = 'assets/images/profile-pic/profilePic.png';
				const response = data as any[];

				for (const res of response) {
					if (this._isNullorEmpty(res.img)) {
						res.img = defaultPic;
					}

					if (this._isNullorEmpty(res.title)) {
						res.title = '';
					}
				}

				this._createOrgChart(data);
			});
	}

	private _isNullorEmpty(str: string) {
		return str === null || str === 'null' || str.trim() === '';
	}

	private _createOrgChart(data: any) {
		OrgChart.templates['customTemplate'] = Object.assign(
			{},
			OrgChart.templates['isla']
		);

		OrgChart.templates['customTemplate'].node =
			'<rect filter="url(#isla-shadow1)" x="-45" y="20" height="140" width="230" fill="#FFF"></rect> ' +
			'<circle cx="70" cy="20" fill="#ffffff" r="25" stroke="#757575" stroke-width="0.5"></circle>' +
			'<circle stroke="#757575" stroke-width="3" fill="#757575" cx="70" cy="10" r="8"></circle> ' +
			'<path d="M55,34 C55,17 85,17 85,34" stroke="#757575" stroke-width="1" fill="#757575"></path>';

		OrgChart.templates['customTemplate'].defs =
			'<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="isla-shadow1">' +
			'<feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
			'<feColorMatrix values="0 0 0 1 0   0 0 0 1 0   0 0 0 1 0  0 0 0 0.5 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
			'<feMerge>' +
			'<feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>';

		OrgChart.templates['customTemplate'].img_0 =
			'<circle cx="70" cy="20" r="30" fill="#ffffff" stroke-width="1" stroke="#757575"></circle>' +
			'<clipPath id="{randId}"><circle cx="70" cy="20" r="30"></circle></clipPath>' +
			'<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="40" y="-10"  width="60" height="60"></image>';

		OrgChart.templates['customTemplate']['field_0'] =
			'<text data-width="230" data-text-overflow="ellipsis" fill="#757575" x="70" y="80" text-anchor="middle" font-weight="bold" style="font-size: 16px; color: #5f5f5f; font-weight: 600;">{val}</text>';

		OrgChart.templates['customTemplate'][
			'field_1'
		] = `<text data-width="230" data-text-overflow="multiline-3-ellipsis" fill="#757575" x="70" y="105" text-anchor="middle" style="font-size: 13px; color: #5f5f5f;">{val}</text>`;

		OrgChart.templates['customTemplate'].link =
			'<path transform="translate(0, 2)" stroke-dasharray="6, 4" stroke-linejoin="round" stroke="#0fbef3" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';

		OrgChart.templates['customTemplate'].minus =
			'<circle cx="-5" cy="40" r="8" fill="#fff" stroke="#00baf2" stroke-width="1"></circle>' +
			'<line x1="-10" y1="40" x2="0.5" y2="40" stroke-width="1" stroke="#00baf2"></line>';

		OrgChart.templates['customTemplate'].plus =
			'<circle cx="-5" cy="40" r="8" fill="#ffffff" stroke="#00baf2" stroke-width="1"></circle>' +
			'<line x1="-10" y1="40" x2="0.5" y2="40" stroke-width="1" stroke="#00baf2"></line><line x1="-5" y1="35" x2="-5" y2="45" stroke-width="1" stroke="#00baf2"></line>';

		OrgChart.templates['customTemplate'].nodeMenuButton = '';

		OrgChart.templates['customTemplate'].linkAdjuster = {
			fromX: -20,
			fromY: -10,
			toX: -20,
			toY: 15,
		};

		OrgChart.RES.IT_IS_LONELY_HERE_LINK = 'No user found';

		this._chart = new OrgChart(document.getElementById('tree') as HTMLElement, {
			levelSeparation: 120,
			siblingSeparation: 90,
			keyNavigation: {
				focusId: this.employeeId,
			},
			lazyLoading: true,
			mouseScrool: OrgChart.action.none,
			layout: OrgChart.normal,
			enableSearch: false,
			searchDisplayField: 'fullName',
			columns: 30,
			template: 'customTemplate',
			nodeBinding: {
				field_0: 'fullName',
				field_1: 'title',
				field_2: 'title2',
				img_0: 'img',
				field_number_children: 'null',
				edit: 'null',
			},
			linkBinding: {
				link_field_0: 'createdAt',
			},
			toolbar: {
				fullScreen: true,
				zoom: true,
				fit: true,
				expandAll: true,
			},
			enableDragDrop: false,
			collapse: {
				level: this.level,
				allChildren: true,
			},
			menu: {
				export_pdf: {
					text: 'Export PDF',
					icon: OrgChart.icon.pdf(24, 24, '#7A7A7A'),
					onClick: () => {
						this._chart.exportPDF({ padding: 60 });
					},
				},
				export_png: {
					text: 'Export PNG',
					icon: OrgChart.icon.png(24, 24, '#7A7A7A'),
					onClick: () => {
						this._chart.exportPNG({ padding: 60 });
					},
				},
				export_svg: {
					text: 'Export SVG',
					icon: OrgChart.icon.svg(24, 24, '#7A7A7A'),
					onClick: () => {
						this._chart.exportSVG({ padding: 60 });
					},
				},
				csv: { text: 'Export CSV' },
				xml: { text: 'Export XML' },
			},
			nodeMouseClick: OrgChart.action.none,
		});

		this._chart
			.on('init', () => {
				this.spinner.stop();
			})
			.on('drop', (sender, draggedNodeId, droppedNodeId) => {
				const employee: any = this._chart.get(draggedNodeId);
				const manager: any = this._chart.get(droppedNodeId);
				let message =
					'Are you sure to remove the reporting manager of the user?';

				this._payload = {
					id: draggedNodeId,
					reportsToId: 0,
					companyId: this.companyId,
					roleId: this._userService.userRole,
					userId: this._userService.userId,
				};

				if (droppedNodeId) {
					message = `Are you sure to update the Reporting manager for ${employee.fullName} and set it as ${manager.fullName}?`;
					this._payload['reportsToId'] = droppedNodeId;
				}

				this._saveConfirmation(this._chart, message);

				return false;
			})
			.on('field', (sender, args) => {
				if (args.name == 'null') {
					const ctr = OrgChart.childrenCount(
						sender,
						this._chart.getNode(args.data.id)
					);
					const isZero = ctr === 0;

					args.value = isZero ? '' : ctr;
				}

				if (args.name === 'img') {
					if (args.value) {
						OrgChart.templates['isla'].node =
							'<rect filter="url(#isla-shadow)" x="0" y="20" rx="7" ry="7" height="100" width="180" fill="#FFF" stroke-width="1" stroke="#039BE5"></rect>' +
							'<rect x="25" y="75" rx="10" ry="10" height="20" width="130" fill="#039BE5" stroke-width="3" stroke="#039BE5"></rect>' +
							'<rect fill="#ffffff" stroke="#039BE5" stroke-width="1" x="70" y="0" rx="13" ry="13" width="40" height="40"></rect>' +
							'<circle stroke="transparent" stroke-width="3" fill="none" cx="90" cy="12" r="0"></circle>' +
							'<path d="M75,34 C75,17 105,17 105,34" stroke="transparent" stroke-width="3" fill="none"></path>';
					} else {
						args.value = this._imageService.getDefaultProfilePic();
					}
				}
			});

		this._chart.load(data);
		this._cd.detectChanges();
	}

	private async _saveConfirmation(chart: OrgChart, header: string) {
		const data = {
			options: {
				cancel: 'No',
				accept: 'Yes',
			},
			header,
			icon: {
				color: 'orange',
				name: '',
			},
		};
		const dialogRef = this.dialog.open(YesNoModalComponent, { data });
		const result = await dialogRef.beforeClosed().toPromise();

		if (result?.event === 'yes') {
			const node = chart.get(this._payload['id']) as any;
			const pid = this._payload['reportsToId'];
			node.pid = pid;

			this._getLevelOptions();
			chart.updateNode(node);
			this._updateReportsTo();
		}
	}

	private _updateReportsTo() {
		this._companiesService
			.updateReportsTo(this._payload['id'], this._payload)
			.subscribe(() => {
				this._notifService.notify('Successfully updated', {
					duration: 5,
					panelClass: 'success',
				});
				this._cd.markForCheck();
				this.spinner.stop();
			});
	}

	private _getLevelOptions() {
		return new Promise((resolve) => {
			this._companiesService
				.getLevelOptions(this.companyId)
				.subscribe((resp: any) => {
					this._levelOptions = resp;
					this._levelOptions.forEach((data: any) => {
						data.id = data.id + 1;
					});
					this._levelOptions.splice(-1);
					resolve(true);
					this._cd.detectChanges();
				});
		});
	}
}
