<div class="wrapper">
  <div class="container">
    <div class="thankyou-wrap heading text-center">
      <img image-error-detection [component]="'no-module-available.component.html'"
      src="./assets/images/icons/sourcepasslogo-icon.png" class="img-fluid" alt="icon" />
      <h3>No permission.</h3>
      <p>Please be guided that you don't have any module permission available.</p>
    </div>
  </div>
</div>
<nav-footer [isPublic]="true"></nav-footer>
