import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[mailHighlight]'
})
export class MailHighlightDirective {

  isClicked = false;
  constructor(private elRef: ElementRef, private renderer: Renderer2) {
   }

  @HostListener('click')
  onClick(){
    this.isClicked = !this.isClicked;
    if(this.isClicked){
      this.renderer.addClass(this.elRef.nativeElement, 'mail-highlight');
    }else{
      this.renderer.removeClass(this.elRef.nativeElement, 'mail-highlight');
    }
  }

}
