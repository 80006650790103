<input
	[ngModel]="value"
	(ngModelChange)="onModelChange($event)"
	[matDatepicker]="datepicker"
	[min]="min"
	[max]="max"
	class="font-b1 font-h-normal"
	type="datetime"
	tabindex="-1"
/>

<input
	[formControl]="maskControl"
	[placeholder]="placeholder"
	(blur)="onBlur()"
	(keydown)="onKeyDown($event)"
	class="mask-input font-b1 font-h-normal"
	type="text"
	#maskRef
/>

<mat-icon svgIcon="calendar-date"></mat-icon>
<mat-icon svgIcon="alert-triangle"></mat-icon>

<mat-datepicker
	[calendarHeaderComponent]="customHeader"
	panelClass="q-date-picker"
></mat-datepicker>
