import { ElementRef } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF, { RGBAData, jsPDFOptions } from 'jspdf';

export class PDF {
	public get doc() {
		return this._doc;
	}

	public get pageWidth() {
		return this._pageWidth;
	}

	public get pageHeight() {
		return this._pageHeight;
	}

	public get alias() {
		return this._alias;
	}

	public set alias(num: number) {
		this._alias = num;
	}

	public completePDF(pdfName: string) {
		this.doc.save(`${pdfName}.pdf`);
	}

	public addImage(
		imgData:
			| string
			| HTMLImageElement
			| HTMLCanvasElement
			| Uint8Array
			| RGBAData,
		format: string,
		x: number | 'center',
		y: number,
		width: number,
		height: number
	) {
		if (x === 'center') {
			x = (this.pageWidth - width) / 2;
		}
		this.doc.addImage(
			imgData,
			format,
			x,
			y,
			width,
			height,
			'id' + this.alias,
			'FAST'
		);
		this.alias++;
	}

	public async loadImage(element: ElementRef): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const canvas = await html2canvas(element.nativeElement);
				const imgData = canvas.toDataURL('image/png');
				resolve(imgData);
			} catch (error) {
				console.error('Error generating PDF:', error);
				reject(error);
				// Handle the error gracefully, e.g., show an error message to the user
			}
		});
	}

	public addPage() {
		this.doc.addPage(this.format, this.orientation);
	}

	constructor(
		private orientation: jsPDFOptions['orientation'] = 'portrait',
		private format: string | [number, number] = 'letter',
		private unit: jsPDFOptions['unit'] = 'px'
	) {
		this.createDoc();
	}

	private createDoc() {
		this.doc = new jsPDF({
			orientation: this.orientation,
			format: this.format,
			unit: this.unit,
			compress: true,
		});
		this._pageWidth = this._doc.internal.pageSize.getWidth();
		this._pageHeight = this._doc.internal.pageSize.getHeight();
	}

	private _doc: jsPDF;
	private _pageWidth: number;
	private _pageHeight: number;
	private _alias: number;
	private set doc(doc: jsPDF) {
		this._doc = doc;
	}
}
