<div class="et-container">
	<div class="heading-container">
		<div class="title-heading">
			<h3>User Logins</h3>
		</div>
		<div class="action-heading">
			<div class="search-bar">
				<search-input
					size="small"
					class="font-btn"
					placeholder="Search Users"
					#searchTextbox
				></search-input>
			</div>

			<app-tertiary-button
				btnText="Collapse Table"
				iconName="expand-01"
				[isIconLeft]="false"
			></app-tertiary-button>
		</div>
	</div>
	<div class="table-container">
		<div class="table-container">
			<div class="table-wrapper scroll-v2">
				<ng-container *ngIf="data.templateData!['reportType'] === 'summary'">
					<!-- <user-login-dynamic-table></user-login-dynamic-table> -->
				</ng-container>

				<!-- <ng-template #details>
					<user-login-dynamic-table></user-login-dynamic-table>
				</ng-template> -->
			</div>
		</div>
	</div>
</div>
