import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { select, Store } from '@ngrx/store';
import { combineLatest, take } from 'rxjs';
import { configUpdateAction } from './store/configuration.action';
import { darkModeColorSelector, sideBarColorSelector } from './store/configuration.selector';


@Directive({
  selector: '[spThemeMode]'
})
export class ThemeModeDirective{
  configurator: Element;
  @Input() spThemeMode: string = '';
  flag: boolean = true;
  constructor(
    public elRef: ElementRef,
    private store: Store<AppStateInterface>,
    private utilitiesService: UtilitiesService,
    ) {

    }

    @HostListener('click') changeMode(){
      let body = document.body;
      // to dark
     if(this.spThemeMode === 'light'){
       this.setLightColors();
      this.store.dispatch(configUpdateAction({darkMode: false}))
      document.getElementById('spDarkBtn')!.classList.remove('active');
      document.getElementById('spLightBtn')!.classList.add('active');
      body.classList.remove('dark-version');
      body.setAttribute('data-color','defaultTheme');
     }else
     if(this.spThemeMode === 'dark'){
      this.setDarkColors();
      this.store.dispatch(configUpdateAction({darkMode: true}));
      document.getElementById('spDarkBtn')!.classList.add('active');
      document.getElementById('spLightBtn')!.classList.remove('active');
      // this.elRef.nativeElement.classList.add('active');
      // this.elRef.nativeElement.previousSibling.classList.remove('active');
      body.classList.add('dark-version');
      body.setAttribute('data-color','darkTheme');
     }
    }

    setLightColors(){
      this.store.pipe(
        //select(sideBarTempColorSelector),take(1)).subscribe(
        select(sideBarColorSelector),take(1)).subscribe(
          (tempColor: string) =>{
            this.store.dispatch(configUpdateAction({sideBarColor: tempColor}));
            document.documentElement.style.setProperty('--dark-color-base',tempColor as string);
            let bgLightColor = this.utilitiesService.lighten(tempColor);
            document.documentElement.style.setProperty('--dark-color-bg',bgLightColor as string);
          }
        )
    }

    setDarkColors(){
      combineLatest([
        this.store.pipe(
          select(sideBarColorSelector),take(1)),
        this.store.pipe(
          select(darkModeColorSelector),take(1))
      ]).subscribe(([sideBarColor, darkModeColor]:[string, string])=>{
        if(this.flag){
          this.flag = false;
        }else{
          this.store.dispatch(configUpdateAction({sideBarTempColor: sideBarColor}));
        }
        let bgDarkColor = this.utilitiesService.smartColorAlgorithm(darkModeColor);
        let tertiaryDarkColor = this.utilitiesService.smartColorAlgorithm(darkModeColor);
        let primaryDarkModeColor = this.utilitiesService.checkAndConvertLight(darkModeColor);
        document.documentElement.style.setProperty('--dark-color-base',primaryDarkModeColor as string);
        document.documentElement.style.setProperty('--dark-color-bg',bgDarkColor as string);
        document.documentElement.style.setProperty('--dark-color-tertiary',tertiaryDarkColor  as string);
        this.store.dispatch(configUpdateAction({sideBarColor: bgDarkColor as string}));
      })
    }
}
@Directive({
  selector: '[spConfigurator]'
})
export class ConfiguratorDirective {
  configurator: Element;
  constructor(public elRef: ElementRef, private renderer: Renderer2) {

    }

  @HostListener('click') toggleOpen(){
    this.configurator = document.getElementById('configurator') as unknown as Element;
    if(this.configurator.classList.contains('show')){
      this.renderer.removeClass(this.configurator, 'show')
    }else{
      // settimout is used so that ClickOutSideConfiguratorDirective will work.
      // this is just a workaround. feel free to refactor it if you have a better solution.
      setTimeout(() => {
        this.renderer.addClass(this.configurator, 'show');
      }, 500);
    }
  }

}


@Directive({
  selector: '[spSideBarColor]'
})
export class SideBarColorDirective{
  @Input() spSideBarColor: "primary" | "info" | "warning" | "success" | "danger" | "dark";
  constructor(public elRef: ElementRef, private store: Store<AppStateInterface>){

  }

  @HostListener('click') toggleOpen(){
    this.store.dispatch(configUpdateAction({sideBarColor: this.spSideBarColor}))
    let parent = this.elRef.nativeElement.parentElement.children;
    let color = this.spSideBarColor;
    let a = this.elRef.nativeElement;

    for (let i = 0; i < parent.length; i++) {
      parent[i].classList.remove('active');
    }

    if (!a.classList.contains('active')) {
      a.classList.add('active');
    } else {
      a.classList.remove('active');
    }

    let sidebar = document.querySelector('.sidenav')!;
    sidebar.setAttribute("data-color", color);

    if (document.querySelector('#sidenavCard')) {
      let sidenavCard = document.querySelector('#sidenavCard+.btn')!;
      let sidenavCardClasses = ['btn', 'btn-sm', 'w-100', 'mb-0', 'bg-gradient-' + color];
      sidenavCard.removeAttribute('class');
      sidenavCard.classList.add(...sidenavCardClasses);
    }
  }
}

@Directive({
  selector: '[spNavType]'
})
export class NavTypeDirective{
    @Input() spNavType: "bg-white" | "bg-default";

    constructor(public elRef: ElementRef, private store: Store<AppStateInterface>){
    }

    @HostListener('click') toggleOpen(){
      this.store.dispatch(configUpdateAction({sideNavType: this.spNavType}))
      let  a = this.elRef.nativeElement;
      let parent = this.elRef.nativeElement.parentElement.children;
      let color = this.spNavType;
      let body = document.body;
      let bodyWhite = document.querySelector("body:not(.dark-version)");
      let bodyDark = body.classList.contains('dark-version');

      let colors = [];

      for (let i = 0; i < parent.length; i++) {
        parent[i].classList.remove('active');
        colors.push(parent[i].getAttribute('data-class'));
      }

      if (!a.classList.contains('active')) {
        a.classList.add('active');
      } else {
        a.classList.remove('active');
      }

      let sidebar = document.querySelector('.sidenav')!;

      for (let i = 0; i < colors.length; i++) {
        sidebar.classList.remove(colors[i]);
      }

      sidebar.classList.add(color);

      if (color == 'bg-white') {
        let textWhites = document.querySelectorAll('.sidenav .text-white');
        for (let i = 0; i < textWhites.length; i++) {
          textWhites[i].classList.remove('text-white');
          textWhites[i].classList.add('text-dark');
        }
      } else {
        let textDarks = document.querySelectorAll('.sidenav .text-dark');
        for (let i = 0; i < textDarks.length; i++) {
          textDarks[i].classList.add('text-white');
          textDarks[i].classList.remove('text-dark');
        }
      }

      if (color == 'bg-default' && bodyDark) {
        let textDarks = document.querySelectorAll('.navbar-brand .text-dark');
        for (let i = 0; i < textDarks.length; i++) {
          textDarks[i].classList.add('text-white');
          textDarks[i].classList.remove('text-dark');
        }
      }

      if ((color == 'bg-white') && bodyWhite) {
        let navbarBrand = document.querySelector('.navbar-brand-img') as HTMLImageElement;
        let navbarBrandImg = navbarBrand.src;

        if (navbarBrandImg.includes('logo-ct.png')) {
          let navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
          navbarBrand.src = navbarBrandImgNew;
        }
      } else {
        let navbarBrand = document.querySelector('.navbar-brand-img')! as HTMLImageElement;
        let navbarBrandImg = navbarBrand.src;
        if (navbarBrandImg.includes('logo-ct-dark.png')) {
          let navbarBrandImgNew = navbarBrandImg.replace("logo-ct-dark", "logo-ct");
          navbarBrand.src = navbarBrandImgNew;
        }
      }

      if (color == 'bg-white' && bodyDark) {
        let navbarBrand = document.querySelector('.navbar-brand-img')! as HTMLImageElement;
        let navbarBrandImg = navbarBrand.src;

        if (navbarBrandImg.includes('logo-ct.png')) {
          let navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
          navbarBrand.src = navbarBrandImgNew;
        }
      }
    }
}


@Directive({
  selector: '[spNavBarFixed]'
})
export class NavBarFixedDirective{
  constructor(public elRef: ElementRef, private store: Store<AppStateInterface>){
  }
  @HostListener('click') onClick(){
    let classes = ['position-sticky', 'bg-white', 'left-auto', 'top-2', 'z-index-sticky'];
    const navbar = document.getElementById('navbarBlur')!;

    if (!this.elRef.nativeElement.getAttribute("checked")) {
      this.store.dispatch(configUpdateAction({navbarFixed: true}))
      toggleNavLinksColor('blur');
      navbar.classList.add(...classes);
      navbar.setAttribute('data-scroll', 'true');
      navbarBlurOnScroll('navbarBlur');
      this.elRef.nativeElement.setAttribute("checked", "true");
    } else {
      this.store.dispatch(configUpdateAction({navbarFixed: false}))
      toggleNavLinksColor('transparent');
      navbar.classList.remove(...classes);
      navbar.setAttribute('data-scroll', 'false');
      navbarBlurOnScroll('navbarBlur');
      this.elRef.nativeElement.removeAttribute("checked");
    }
  }
}

@Directive({
  selector: '[spNavBarMinimize]'
})
export class NavBarMinimizeDirective{
  constructor(public elRef: ElementRef, private store: Store<AppStateInterface>){
  }
  @HostListener('click') onClick(){
    let sidenavShow = document.getElementsByClassName('g-sidenav-show')[0]!;

    if (!this.elRef.nativeElement.getAttribute("checked")) {
      this.store.dispatch(configUpdateAction({sideNavMini: true}))
      sidenavShow.classList.remove('g-sidenav-pinned');
      sidenavShow.classList.add('g-sidenav-hidden');
      this.elRef.nativeElement.setAttribute("checked", "true");
    } else {
      this.store.dispatch(configUpdateAction({sideNavMini: false}))
      sidenavShow.classList.remove('g-sidenav-hidden');
      sidenavShow.classList.add('g-sidenav-pinned');
      this.elRef.nativeElement.removeAttribute("checked");
    }
  }
}


@Directive({
  selector: '[spDarkMode]'
})
export class DarkModeDirective{
  constructor(public elRef: ElementRef, private store: Store<AppStateInterface>){
  }
  @HostListener('click') onClick(){
    const body = document.getElementsByTagName('body')[0];
    const hr = document.querySelectorAll('div:not(.sidenav) > hr');
    // const sidebar = document.querySelector('.sidenav')!;
    // const sidebarWhite = document.querySelectorAll('.sidenav.bg-white');
    const hr_card = document.querySelectorAll('div:not(.bg-gradient-dark) hr');
    const text_btn = document.querySelectorAll('button:not(.btn) > .text-dark');
    const text_span = document.querySelectorAll('span.text-dark, .breadcrumb .text-dark');
    const text_span_white = document.querySelectorAll('span.text-white');
    const text_strong = document.querySelectorAll('strong.text-dark');
    const text_strong_white = document.querySelectorAll('strong.text-white');
    const text_nav_link = document.querySelectorAll('a.nav-link.text-dark');
    const secondary = document.querySelectorAll('.text-secondary');
    const bg_gray_100 = document.querySelectorAll('.bg-gray-100');
    const bg_gray_600 = document.querySelectorAll('.bg-gray-600');
    const btn_text_dark = document.querySelectorAll('.btn.btn-link.text-dark, .btn .ni.text-dark');
    const btn_text_white = document.querySelectorAll('.btn.btn-link.text-white, .btn .ni.text-white');
    const card_border = document.querySelectorAll('.card.border');
    const card_border_dark = document.querySelectorAll('.card.border.border-dark');
    const svg = document.querySelectorAll('g');
    const navbarBrand: HTMLImageElement = document.querySelector('.navbar-brand-img')!;
    const navbarBrandImg = navbarBrand.src;
    const navLinks = document.querySelectorAll('.navbar-main .nav-link, .navbar-main .breadcrumb-item, .navbar-main .breadcrumb-item a, .navbar-main h6');
    const cardNavLinksIcons = document.querySelectorAll('.card .nav .nav-link i');
    const cardNavSpan = document.querySelectorAll('.card .nav .nav-link span');


    if (!this.elRef.nativeElement.getAttribute("checked")) {
      this.store.dispatch(configUpdateAction({darkMode: true}))
      body.classList.add('dark-version');
      if (navbarBrandImg.includes('logo-ct-dark.png')) {
        var navbarBrandImgNew = navbarBrandImg.replace("logo-ct-dark", "logo-ct");
        navbarBrand.src = navbarBrandImgNew;
      }
      for (let i = 0; i < cardNavLinksIcons.length; i++) {
        if (cardNavLinksIcons[i].classList.contains('text-dark')) {
          cardNavLinksIcons[i].classList.remove('text-dark');
          cardNavLinksIcons[i].classList.add('text-white');
        }
      }
      for (let i = 0; i < cardNavSpan.length; i++) {
        if (cardNavSpan[i].classList.contains('text-sm')) {
          cardNavSpan[i].classList.add('text-white');
        }
      }
      for (let i = 0; i < hr.length; i++) {
        if (hr[i].classList.contains('dark')) {
          hr[i].classList.remove('dark');
          hr[i].classList.add('light');
        }
      }
      for (let i = 0; i < hr_card.length; i++) {
        if (hr_card[i].classList.contains('dark')) {
          hr_card[i].classList.remove('dark');
          hr_card[i].classList.add('light');
        }
      }
      for (let i = 0; i < text_btn.length; i++) {
        if (text_btn[i].classList.contains('text-dark')) {
          text_btn[i].classList.remove('text-dark');
          text_btn[i].classList.add('text-white');
        }
      }
      for (let i = 0; i < text_span.length; i++) {
        if (text_span[i].classList.contains('text-dark')) {
          text_span[i].classList.remove('text-dark');
          text_span[i].classList.add('text-white');
        }
      }
      for (let i = 0; i < text_strong.length; i++) {
        if (text_strong[i].classList.contains('text-dark')) {
          text_strong[i].classList.remove('text-dark');
          text_strong[i].classList.add('text-white');
        }
      }
      for (let i = 0; i < text_nav_link.length; i++) {
        if (text_nav_link[i].classList.contains('text-dark')) {
          text_nav_link[i].classList.remove('text-dark');
          text_nav_link[i].classList.add('text-white');
        }
      }
      for (let i = 0; i < secondary.length; i++) {
        if (secondary[i].classList.contains('text-secondary')) {
          secondary[i].classList.remove('text-secondary');
          secondary[i].classList.add('text-white');
          secondary[i].classList.add('opacity-8');
        }
      }
      for (let i = 0; i < bg_gray_100.length; i++) {
        if (bg_gray_100[i].classList.contains('bg-gray-100')) {
          bg_gray_100[i].classList.remove('bg-gray-100');
          bg_gray_100[i].classList.add('bg-gray-600');
        }
      }
      for (let i = 0; i < btn_text_dark.length; i++) {
        btn_text_dark[i].classList.remove('text-dark');
        btn_text_dark[i].classList.add('text-white');
      }
      // for (let i = 0; i < sidebarWhite.length; i++) {
      //   sidebarWhite[i].classList.remove('bg-white');
      // }
      for (let i = 0; i < svg.length; i++) {
        if (svg[i].hasAttribute('fill')) {
          svg[i].setAttribute('fill', '#fff');
        }
      }
      for (let i = 0; i < card_border.length; i++) {
        card_border[i].classList.add('border-dark');
      }
      this.elRef.nativeElement.setAttribute("checked", "true");
    } else {
      this.store.dispatch(configUpdateAction({darkMode: false}))
      body.classList.remove('dark-version');
      // sidebar.classList.add('bg-white');
      if (navbarBrandImg.includes('logo-ct.png')) {
        let navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
        navbarBrand.src = navbarBrandImgNew;
      }
      for (let i = 0; i < navLinks.length; i++) {
        if (navLinks[i].classList.contains('text-dark')) {
          navLinks[i].classList.add('text-white');
          navLinks[i].classList.remove('text-dark');
        }
      }
      for (let i = 0; i < cardNavLinksIcons.length; i++) {
        if (cardNavLinksIcons[i].classList.contains('text-white')) {
          cardNavLinksIcons[i].classList.remove('text-white');
          cardNavLinksIcons[i].classList.add('text-dark');
        }
      }
      for (let i = 0; i < cardNavSpan.length; i++) {
        if (cardNavSpan[i].classList.contains('text-white')) {
          cardNavSpan[i].classList.remove('text-white');
        }
      }
      for (let i = 0; i < hr.length; i++) {
        if (hr[i].classList.contains('light')) {
          hr[i].classList.add('dark');
          hr[i].classList.remove('light');
        }
      }
      for (let i = 0; i < hr_card.length; i++) {
        if (hr_card[i].classList.contains('light')) {
          hr_card[i].classList.add('dark');
          hr_card[i].classList.remove('light');
        }
      }
      for (let i = 0; i < text_btn.length; i++) {
        if (text_btn[i].classList.contains('text-white')) {
          text_btn[i].classList.remove('text-white');
          text_btn[i].classList.add('text-dark');
        }
      }
      for (let i = 0; i < text_span_white.length; i++) {
        if (text_span_white[i].classList.contains('text-white') && !text_span_white[i].closest('.sidenav') && !text_span_white[i].closest('.card.bg-gradient-dark')) {
          text_span_white[i].classList.remove('text-white');
          text_span_white[i].classList.add('text-dark');
        }
      }
      for (let i = 0; i < text_strong_white.length; i++) {
        if (text_strong_white[i].classList.contains('text-white')) {
          text_strong_white[i].classList.remove('text-white');
          text_strong_white[i].classList.add('text-dark');
        }
      }
      for (let i = 0; i < secondary.length; i++) {
        if (secondary[i].classList.contains('text-white')) {
          secondary[i].classList.remove('text-white');
          secondary[i].classList.remove('opacity-8');
          secondary[i].classList.add('text-dark');
        }
      }
      for (let i = 0; i < bg_gray_600.length; i++) {
        if (bg_gray_600[i].classList.contains('bg-gray-600')) {
          bg_gray_600[i].classList.remove('bg-gray-600');
          bg_gray_600[i].classList.add('bg-gray-100');
        }
      }
      for (let i = 0; i < svg.length; i++) {
        if (svg[i].hasAttribute('fill')) {
          svg[i].setAttribute('fill', '#252f40');
        }
      }
      for (let i = 0; i < btn_text_white.length; i++) {
        if (!btn_text_white[i].closest('.card.bg-gradient-dark')) {
          btn_text_white[i].classList.remove('text-white');
          btn_text_white[i].classList.add('text-dark');
        }
      }
      for (let i = 0; i < card_border_dark.length; i++) {
        card_border_dark[i].classList.remove('border-dark');
      }
      this.elRef.nativeElement.removeAttribute("checked");
    }
  }
}




const toggleNavLinksColor = (type: string): void => {
  let navLinks = document.querySelectorAll('.navbar-main .nav-link, .navbar-main .breadcrumb-item, .navbar-main .breadcrumb-item a, .navbar-main h6')
  let navLinksToggler = document.querySelectorAll('.navbar-main .sidenav-toggler-line')

  if (type === "blur") {
    navLinks.forEach(element => {
      element.classList.remove('text-white')
    });

    navLinksToggler.forEach(element => {
      element.classList.add('bg-dark')
      element.classList.remove('bg-white')
    });
  } else if (type === "transparent") {
    navLinks.forEach(element => {
      element.classList.add('text-white')
    });

    navLinksToggler.forEach(element => {
      element.classList.remove('bg-dark')
      element.classList.add('bg-white')
    });
  }
}

const navbarBlurOnScroll = (id: string) =>{
  const navbar = document.getElementById(id)!;
  let navbarScrollActive = navbar ? navbar.getAttribute("data-scroll") : false;
  let scrollDistance = 5;
  let classes = ['bg-white', 'left-auto', 'position-sticky'];
  let toggleClasses = ['shadow-none'];

  if (navbarScrollActive == 'true') {
    window.onscroll = debounce(function() {
      if (window.scrollY > scrollDistance) {
        blurNavbar();
      } else {
        transparentNavbar();
      }
    }, 10);
  } else {
    window.onscroll = debounce(function() {
      transparentNavbar();
    }, 10);
  }

  var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

  if (isWindows) {
    var content = document.querySelector('.main-content')!;
    if (navbarScrollActive == 'true') {
      content.addEventListener('ps-scroll-y', debounce(function() {
        if (content.scrollTop > scrollDistance) {
         blurNavbar();
        } else {
          transparentNavbar();
        }
      }, 10));
    } else {
      content.addEventListener('ps-scroll-y', debounce(function() {
        transparentNavbar();
      }, 10));
    }
  }

  function blurNavbar() {
    navbar.classList.add(...classes)
    navbar.classList.remove(...toggleClasses)

    toggleNavLinksColor('blur');
  }

  function transparentNavbar() {
    navbar.classList.remove(...classes)
    navbar.classList.add(...toggleClasses)

    toggleNavLinksColor('transparent');
  }
}


function debounce(func: Function, wait: number, immediate? : any) {
  let timeout: any;
  return function(this: unknown) {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
