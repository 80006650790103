import { TableMessages } from '@app/shared/constants';
import {
	Component,
	ChangeDetectorRef,
	AfterViewInit,
	Input,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable, catchError, BehaviorSubject } from 'rxjs';

import { DashboardService } from '../../../../dashboard.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';

import {
	SeeMore,
	TableDynamicColumns,
	TicketWidget,
	WidgetDynamicTable2,
} from '@app/shared/interfaces/dashboard.interface';

import { initialStateITickets } from '@app/modules/service-and-support/pages/ticket/store/tickets.reducer';

import { ticketsUpdateAction } from '@app/modules/service-and-support/pages/ticket/store/tickets.actions';

@Component({
	selector: 'app-tickets-widget',
	templateUrl: './tickets-widget.component.html',
	styleUrls: ['./tickets-widget.component.scss'],
})
export class TicketsWidgetComponent implements AfterViewInit {
	@Input() category: string; // Value can be 'Open' or 'VIP'

	openTickets$: Observable<TicketWidget[] | null>;
	vipTickets$: Observable<TicketWidget[] | null>;
	ticketData: any[] = [];
	message = {
		noRecord: TableMessages.EmptyTable,
	};

	source: any[] = [];
	noRecords: string = TableMessages.EmptyTable;
	columns: TableDynamicColumns[] = [];
	displayedColumns: string[] = [];
	seeMore: SeeMore;
	rowClickable = true;

	dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

	rowClick: (data: any) => void;
	noClick: () => void;

	constructor(
		private _cd: ChangeDetectorRef,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _dashboardService: DashboardService,
		private _ticketService: TicketService
	) {
		_dashboardService.initOpenTicket();
		_dashboardService.initVIPTicket();

		this.openTickets$ = this._dashboardService.openTicket$;
		this.vipTickets$ = this._dashboardService.vipTicket$;
	}

	get isOpenTicket(): boolean {
		return this.category.toLowerCase() === 'open';
	}

	ngAfterViewInit(): void {
		this.getTickets(this.category);
	}

	// This if for open and vip tickets
	getTickets(category: string) {
		let data$: Observable<any>;

		if (this.isOpenTicket) {
			data$ = this.openTickets$;
		} else {
			data$ = this.vipTickets$;
		}

		data$.pipe(catchError((v) => [])).subscribe((v) => {
			this._createDataTable(v);
		});
	}

	rowClickFn = (data: any) => {
		const { id } = data;
		this._router.navigateByUrl('/service-and-support/tickets/view/' + id);
	};

	getDataTable(): WidgetDynamicTable2 {
		return {
			source: this.source,
			columns: this.columns,
			rowClick: this.rowClick,
			noClick: this.noClick,
			noRecordMessage: this.noRecords,
			displayedColumns: this.displayedColumns,
			seeMore: this.seeMore,
			rowClickable: this.rowClickable,
		};
	}

	goToDetail(id: number) {
		this._router.navigate(['/service-and-support/tickets/view/' + id], {
			relativeTo: this._activatedRoute,
		});
	}

	goToTicket() {
		this._ticketService.filter.ticketStatusIds = '128';
		this._router.navigateByUrl('/service-and-support/tickets');
	}

	private _createDataTable(ticket: any) {
		this.source = ticket;
		this.columns = this._getColumns();
		this.displayedColumns = this._getDisplayedColumns();
		this.seeMore = this._getSeeMore();
		this.rowClick = this.rowClickFn;
		this.dataTable$.next(this.getDataTable());
		this._cd.detectChanges();
	}

	private _getColumns() {
		return [
			{
				text: 'Ticket ID',
				matColumnDef: 'id',
			},
			{
				text: 'Title',
				matColumnDef: 'title',
				isOpenTicket: this.isOpenTicket,
			},
			{
				text: 'Status',
				matColumnDef: 'statusName',
			},
			{
				text: 'Last Updated',
				matColumnDef: 'lastUpdated',
			},
		];
	}

	private _getDisplayedColumns() {
		return ['id', 'title', 'statusName', 'lastUpdated'];
	}

	private _getSeeMore() {
		const state = { ...initialStateITickets, ...{ statusIds: [] } };
		return {
			link: '/service-and-support/tickets',
			state: ticketsUpdateAction(state),
		};
	}
}
export interface Tickets {
	Id: number;
	Title: string;
	TicketBoardId: number;
}
