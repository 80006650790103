import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { SearchInputComponent } from './search-input.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [SearchInputComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		OverlayModule,
    MatIconModule,
	],
	exports: [SearchInputComponent],
})
export class SearchInputModule {}
