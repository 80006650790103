import { IListEmailTemplate } from './list-email-template.interface';
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
	EMAIL_TEMPLATE_UPDATE = '[Email Templates] Uodate',
	EMAIL_TEMPLATE_UPDATE_SUCCESS = '[Email Templates] Update Success',
}
export const emailTemplateUpdateAction = createAction(
	ActionTypes.EMAIL_TEMPLATE_UPDATE,
	props<Partial<IListEmailTemplate>>()
);
export const emailTemplateUpdateSuccessAction = createAction(
	ActionTypes.EMAIL_TEMPLATE_UPDATE_SUCCESS
);
