import { ToastMessageService } from './../../../../shared/services/toast-message.service';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	inject,
} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { ModalTooltipComponent } from '../../modal-utils/tooltip/tooltip.component';
import { NewTableSharedModule } from '@app/shared/new-table-shared/new-table-shared.module';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { TotalTicketsFilter } from '@app/shared/interfaces/total-tickets.interface';
import { DashboardReportService } from '@app/modules/dashboard/pages/dashboard/dashboard-report.service';
import { SharedMessagingModule } from '@app/shared/components/messaging/shared-messaging.module';
import { ClientMessageService } from '@app/modules/client-message/client-message.service';
import { catchError, Subscription } from 'rxjs';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { ITicket } from '@app/shared/components/messaging/view-ticket/ticket.interface';
import { PdfTicketComponentModal } from '../pdf-ticket/pdf-ticket.component';
import { NotificationMessages } from '@app/shared/constants';
import { DashboardReportTab } from '@app/modules/dashboard/pages/dashboard/dashboard-report.enum';

@Component({
	selector: 'app-user-ticket',
	standalone: true,
	imports: [SharedModule,
		ModalTooltipComponent,
		NewTableSharedModule,
		SharedMessagingModule,
    PdfTicketComponentModal],
	templateUrl: './user-ticket.component.html',
	styleUrls: ['./user-ticket.component.scss'],
})
export class UserTicketComponent implements OnInit {
	@Input() data: IDataModal;
  DashboardReportTab = DashboardReportTab;
	searchFilters: any;
	dataModalService = inject(DataModalService);
	dashboardReport = inject(DashboardReportService);
  toastMessageService = inject(ToastMessageService);

	currentState: string = 'ticketList';
	_cd = inject(ChangeDetectorRef);
	_ticketService = inject(TicketService);
	message = {
		m1: 'Total number of service-related tickets submitted by your team or opened on your behalf totaled on a monthly basis.',
		m2: '',
	};

	ticket: any;
  ticketList: any[];
  subscription: Subscription = new Subscription()
  pdfData: {pdfUrl: string, pdfId: number};

	constructor() {}

	ngOnInit(): void {
   this.subscription.add( this.dashboardReport.tableData$.subscribe((data)=>this.ticketList = data))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
		this.dashboardReport.resetTotalTickets();
	}

  onEmitState(state: string){
    this.currentState = state;
  }

	fetchNewData() {
		const filters: IPageState = this._getFilters();

		this.searchFilters = filters;

	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.fetchNewData();
			this._cd.detectChanges();
		}, 0);
	}

  onEmitPDFData(evt: any){
    this.pdfData = evt;
  }

  download(){
    this.dashboardReport.downloadTicket('',{month:this.data.templateData!['month'],isVIP: this.data.templateData!['isVIP'],exportType: 1}).subscribe({
      next: (res: any) => {
        const link = document.createElement('a');
        link.href = res.data;
        link.setAttribute('download', 'ServiceTable');
        document.body.appendChild(link);
        link.click();


      },
      error: () => {
        this.toastMessageService.showErrorMessage(
          NotificationMessages.FailedToGenerateFile
        );
      },
      complete: () => {
        this.toastMessageService.showSuccessMessage(
          NotificationMessages.Export
        );
      },
    })
  }

	private _getFilters(): TotalTicketsFilter {
    return this.data.templateData!['filters']
		// return {
		// 	page: 1,
		// 	pageSize: 10,
		// 	order: 'desc',
		// 	column: 'ticketNo',
		// 	query: '',
		// 	month: this.data.templateData!['month'],
		// 	isVIP: this.data.templateData!['isVIP'],
		// };
	}

	capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}

	openLink(row: { [prop: string]: any }) {
		this.currentState = 'ticketDetails';
		this._cd.detectChanges();
    this.ticket = null;
		this._ticketService
			.getTicket(row['ticketId'])
			.subscribe((data) => {this.ticket = data});
		// call ticket
	}



	goBack() {
		this.currentState = 'ticketList';
		this._cd.detectChanges();
	}
}
