import { StoreModule } from '@ngrx/store';
import { TrimPipeModule } from '@app/shared/pipes/trim.pipe.module';
import { KbTopicTabComponent } from './../kb-topic-tab/kb-topic-tab.component';
import { KbTopicAddEditComponent } from './../kb-topic-tab/kb-topic-add-edit/kb-topic-add-edit.component';
import { KbTopicListComponent } from '@app/modules/knowledge-base/pages/kb-topic-list/kb-topic-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditTrailModule } from '@app/shared/components/audit-trail/audit-trail.module';
import { SharedModule } from 'app/shared/shared.module';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CompanyPermissionsTableModule } from '@app/shared/components/company-permissions-table/company-permission-table.module';
import { PermissionContainerModule } from '@app/shared/components/permissions/pages/container/container.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [
    KbTopicListComponent,
    KbTopicAddEditComponent,
    KbTopicTabComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormTooltipModule,
    AuditTrailModule,
    MatTabsModule,
    RouterModule,
    CompanyPermissionsTableModule,
    PermissionContainerModule,
    MatSlideToggleModule,
    TrimPipeModule,
    MatFormFieldModule,

  ],
  exports: [
    KbTopicListComponent,
    KbTopicAddEditComponent,
    KbTopicTabComponent
  ]
})
export class KbTopicListModule {}
