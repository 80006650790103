<table class="table table-flush no-record">
  <tr class="mat-row">
    <td class="mat-cell is-loading" colspan="8" style="text-align: center">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path d="M24 4.5V9.5M24 36V44M11.5 24H4.5M42.5 24H39.5M36.9142 36.9142L35.5 35.5M37.3284 10.8316L34.5 13.66M9.84315 38.1569L15.5 32.5M10.2574 10.4174L14.5 14.66" stroke="#08354F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0'
          to='360'
          dur='2'
          repeatCount='indefinite'>
        </animateTransform>
      </svg>
      <div class="loading-card">
        {{text}}
      </div>
    </td>
  </tr>
</table>
