import { Component, OnInit, EventEmitter, Output, AfterViewInit, ViewChild, ElementRef, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ColorService } from '@app/shared/services/color.service';

@Component({
  selector: 'app-dark-light-theme',
  templateUrl: './dark-light-theme.component.html',
  styleUrls: ['./dark-light-theme.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkLightThemeComponent implements OnInit {
  @ViewChild('light', { static: false }) light!: ElementRef;
  // @ViewChild('dark', { static: false }) dark!: ElementRef;


  form: FormGroup = this.createFormGroup();
  @Input() hasDefault: boolean = true;
  @Input() hasPreview: boolean = true;


  constructor(
    private _fb: FormBuilder,
    private _colorService: ColorService,
  ){
    //this.form = this.createFormGroup();
  }

  ngOnInit() {
  }




  createFormGroup() {
    const group = this._fb.group({
      lightTheme: ['#000000'],
      darkTheme: ['#000000'],

      isDefault: [false],
      isDarkMode: [false],
    });
    //if (!this.isEditBrandingEnabled) group.disable();
    return group;
  }

  previewTheme(theme: 'lightTheme' | 'darkTheme') {
    this._colorService.setSideBarColor(
      this.form.controls[theme].value,
      theme === 'darkTheme' ? true : false
    );
  }

  /**setters and getters */
  get isDefault(){
    return this.form.get('isDefault') as FormControl;
  }


  get isDarkMode(){
    return this.form.get('isDarkMode') as FormControl;
  }



}
