import { IQuotes } from './quotes.interface';
import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  QUOTES_UPDATE = '[Quotes] Update',
  QUOTES_UPDATE_SUCCESS = '[Quotes] Update Success',

}

export const quotesUpdateAction =
createAction(
  ActionTypes.QUOTES_UPDATE,
  props<Partial<IQuotes>>()
)

export const quotesUpdateSuccessAction =
createAction(
  ActionTypes.QUOTES_UPDATE_SUCCESS
)
