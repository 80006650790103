import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
} from '@angular/router';

import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { UserService } from '../services/user.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { MaintenanceService } from '../services/maintenance.service';
import { PwaService } from '@app/services/pwa.service';

@Injectable({
	providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {
	constructor(
		private _userService: UserService,
		private _navigationService: NavigationService,
		private _maintenance: MaintenanceService,
		private _update: PwaService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | boolean {
		if (this._userService.isAuthenticated) {
			if (localStorage.getItem('first-login'))
				return this._checkMaintenance(state);
			else if (state.url == '/quest_under_maintenance')
				return this._checkMaintenance(state);
			else if (state.url == '/mandatory_update')
				return this._update.hasMandatoryUpdate;

			this._navigationService.smartNavigate();
			return false;
		} else if (localStorage.getItem('accessToken')) {
			return this._userService.reconnect().pipe(
				switchMap((res) => {
					if (res.isFirstLogin) return this._checkMaintenance(state);

					this._navigationService.smartNavigate();
					return of(false);
				}),
				catchError((error) => this._checkMaintenance(state))
			);
		} else {
			return true;
		}
	}

	private _checkMaintenance(state: RouterStateSnapshot) {
		return this._maintenance.isAppInMaintenance().pipe(
			map((res) => {
				if (res && state.url != '/quest_under_maintenance')
					this._navigationService.navigateToMaintenance();
				else if (!res) return !res;
				return res;
			})
		);
	}
}
