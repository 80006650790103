<div class="time-input-cont">
	<input
		class="time-input q-f-1 font-b1 font-h-normal"
		type="text"
		placeholder="12:00 PM"
		(blur)="onBlur()"
		[formControl]="internalControl"
		maxlength="8"
	/>
	<mat-icon svgIcon="clock"></mat-icon>
	<mat-icon svgIcon="alert-triangle"></mat-icon>
</div>

<div class="time-picker-wrapper d-none">
	<div id="am-pm" class="am-pm-cont">
		<div
			class="am-pm q-pointer font-b1"
			(click)="onClickPeriod($event)"
			data-value="AM"
		>
			<mat-icon svgIcon="sun-setting-02"></mat-icon>
			AM
		</div>
		<div
			class="am-pm q-pointer font-b1 selected"
			(click)="onClickPeriod($event)"
			data-value="PM"
		>
			<mat-icon svgIcon="moon-01"></mat-icon>
			PM
		</div>
	</div>

	<div class="time-slot-wrapper">
		<div id="time-slot" class="time-slot-cont scroll-v2">
			<div
				*ngFor="let slot of timeSlots"
				class="time-slot q-pointer font-b1"
				(click)="onClickTime($event)"
				(keydown.enter)="onClickTime($event)"
				[attr.data-value]="slot"
				tabindex="0"
			>
				{{ slot }}
			</div>
		</div>
	</div>
</div>
