<div class="application-ticket-list">
  <ng-container *ngIf="!isStillLoading; then main; else loading">
  </ng-container>
</div>

<ng-template #noRecord>
  <p class="no-record">
    To add applications to this quick launch card,<br/>
    go to the Applications menu option and select from
    <a routerLink="/company/applications"><strong>there</strong></a>
  </p>
</ng-template>

<ng-template #loading>
  <widget-loading></widget-loading>
</ng-template>

<ng-template #main>
  <ul class="nav nav-tabs" *ngIf="appData.length > 0; else noRecord">
    <li class="nav-item" *ngFor="let app of appData">
      <a href="javascript:void(0)"
        (click)="launchApp(app.url)"
      >
        <img
          image-error-detection
          [component]="'application-widget.component.html'"
          [src]="app.iconURL"
          class="img-fluid"
          alt="icon"
        />
        <p class="text-sm">{{app.name}}</p>
      </a>
    </li>
  </ul>
</ng-template>