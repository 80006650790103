import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipContentV2Directive } from './tooltip-content-v2.directive';
import { TooltipContentV2Component } from './tooltip-content-v2.component';

@NgModule({
  declarations: [
    TooltipContentV2Directive,
    TooltipContentV2Component
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TooltipContentV2Directive
  ]
})
export class TooltipContentV2Module { }
