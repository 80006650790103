import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";
import { Iconfig } from "../configuration.interface";

export const configSelector = (state: AppStateInterface): Iconfig => state.config;

export const sideBarColorSelector =
createSelector(configSelector,(state: Iconfig)=> state.sideBarColor);

export const sideBarTempColorSelector =
createSelector(configSelector,(state: Iconfig)=> state.sideBarTempColor);

export const darkModeSelector =
createSelector(configSelector,(state: Iconfig)=> state.darkMode);

export const darkModeColorSelector =
createSelector(configSelector,(state: Iconfig)=> state.darkModeColor);

export const navbarFixedSelector =
createSelector(configSelector,(state: Iconfig)=> state.navbarFixed);

export const sideNavMiniSelector =
createSelector(configSelector,(state: Iconfig)=> state.sideNavMini);

export const sideNavTypeSelector =
createSelector(configSelector,(state: Iconfig)=> state.sideNavType);
