import { CheckboxService } from './checkbox.service';
import { Directive, Input, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[multiSelection]'
})
export class ShiftClickDirective {
  /**
   * [selectedItem] - Selected item (id) for each instance of array
   */
  @Input('selectedItem') item: number;
  /**
   * [itemSelections] - Based array to be used for selecting a range of selected item
   */
  @Input('itemSelections') items: number[];
  /**
   * Value could be an array of number or just a single number
   * For emitting a single/multiple items selected
   */
  @Output() rangeSelected: EventEmitter<{value:number[] | number, isChecked: boolean }> = new EventEmitter<{value:number[] | number, isChecked: boolean }>();

  constructor(private _checkboxService: CheckboxService) {}

  /**
   * Host listener for click (check) event
   * @param event - parameter to be used on checking the current state and shift key value
   */
  @HostListener('click', ['$event'])
  onItemClick(event: MouseEvent) {
    const currentState = (event.target as HTMLInputElement).checked;
    if (event.shiftKey && this._checkboxService.lastChecked !== null) {
     const range = this._generateRange(this._checkboxService.lastChecked, this.item);
     this.rangeSelected.emit({value: range, isChecked: currentState});
    } else {
      this.emitSelection(this.item, currentState);
    }
    this._checkboxService.lastChecked = this.item;

  }
  /**
   * Function for emitting single item
   * @param item
   * @param isChecked
   */
  private emitSelection(item: number, isChecked: boolean) {
    this.rangeSelected.emit({value: item, isChecked: isChecked});
  }
  /**
   * Function for generating min/max range on selected items
   */
  private _generateRange(startItem: number, endItem: number): number[] {
    const startIndex = this._findIndex(startItem);
    const endIndex = this._findIndex(endItem);
    const minIndex = Math.min(startIndex, endIndex);
    const maxIndex = Math.max(startIndex, endIndex);
    const range: number[] = [];
    // If the selection was started from the last index to first index
    if (minIndex > maxIndex) {
      for (let i = maxIndex; i <= minIndex; i++) {
        range.push(this.items[i]);
      }
    } else {
      for (let i = minIndex; i <= maxIndex; i++) {
        range.push(this.items[i]);
      }
    }
    return range;
  }

  private _findIndex(item: number): number {
    return this.items.findIndex(value => value === item);
  }
}
