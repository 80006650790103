<section class="tab-content__section" data-tab-section="resend">
  <div class="row">
      <form class="row2">
          <div class="form-field">
              <div class="input-label">
                  <div class="col-md-1">
                      <label>Send By:</label>
                  </div>
                  <div class="col-md-4">
                      <select-input
                        [options]="sendBy"
                        [optionKeys]="{ value: 'value', label: 'label' }"
                        [formControl]="sendByFc"
                        [inputClass]="'select-input2'"
                        style="height: 40px;"
                      >
                      </select-input>
                  </div>
              </div>
              <div class="input-label">
                  <div class="col-md-1">
                      <label>{{contact.contact}}</label>
                  </div>
                  <div class="col-md-4">
                    <input type="string" [formControl]="userContact" readonly>
                  </div>
              </div>
          </div>
      </form>
  </div>
  <div class="see-btn">
      <a href="javascript:;" class="btn send-btn" (click)="sendVerification()">Send</a>
  </div>
</section>
