import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'widget-loading',
  templateUrl: './widget-loading.component.html',
  styleUrls: ['./widget-loading.component.scss']
})
export class WidgetLoadingComponent implements OnInit {
  @Input() text='Loading Dashboard Card...';

  constructor() { }

  ngOnInit() {
  }

}
