import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { createAction, props } from "@ngrx/store";
import { IInvoices } from '../invoices.interface';


export enum ActionTypes{
  USER_INVOICES_UPDATE = '[UserInvoices] Update',
  USER_INVOICES_UPDATE_SUCCESS = '[UserInvoices] Update Success',

}

export const userInvoicesUpdateAction =
createAction(
  ActionTypes.USER_INVOICES_UPDATE,
  props<Partial<IInvoices>>()
)

export const userInvoicesUpdateSuccessAction =
createAction(
  ActionTypes.USER_INVOICES_UPDATE_SUCCESS
)
