import { Directive, HostListener, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[clickOutSideConfigurator]',
})
export class ClickOutSideConfiguratorDirective {
  constructor(
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const configurator = this._document.getElementById('configurator');
    const configuratorSideBar =
      targetElement.querySelector('.configurator-sidebar') ||
      targetElement.closest('.configurator-sidebar');

    if (configuratorSideBar) {
      return;
    }

    if (configurator?.classList.contains('show')) {
      this._renderer.removeClass(configurator, 'show');
    }
  }
}
