import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTooltipModule } from './../../../form-tooltip/form-tooltip.module';
import { MultiSelectInput2Module } from '@app/shared/components/form-input/multi-select-input/multi-select-input.module';
import { FormsModule } from '@angular/forms';
import { CompanyPermissionsTableModule } from '@app/shared/components/company-permissions-table/company-permission-table.module';
import { PermissionsMainModule } from '../main/permissions-main.module';

import { ContainerComponent } from '@app/shared/components/permissions/pages/container/container.component';

@NgModule({
  declarations: [ContainerComponent],
  imports: [
    CommonModule,
    MultiSelectInput2Module,
    CompanyPermissionsTableModule,
    PermissionsMainModule,
    FormsModule,
    FormTooltipModule,
  ],
  exports: [ContainerComponent],
})
export class PermissionContainerModule {}
