<ng-container [ngSwitch]="name">
  <div *ngSwitchCase="'switch-on'">
    <svg 
      class="q-pointer"
      xmlns="http://www.w3.org/2000/svg" width="65" height="32" viewBox="0 0 65 32" fill="none">
      <g clip-path="url(#clip0_1849_16316)">
        <rect x="0.5" width="64" height="32" rx="16" fill="#53B6ED"/>
        <g filter="url(#filter0_d_1849_16316)">
          <circle cx="48.5" cy="16" r="12" fill="#FCFCFC"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_1849_16316" x="32.5" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1849_16316"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1849_16316" result="shape"/>
        </filter>
        <clipPath id="clip0_1849_16316">
          <rect x="0.5" width="64" height="32" rx="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <div *ngSwitchCase="'switch-off'">
    <svg 
      class="q-pointer"
      xmlns="http://www.w3.org/2000/svg" width="65" height="32" viewBox="0 0 65 32" fill="none">
      <g clip-path="url(#clip0_1060_18308)">
        <rect x="0.5" width="64" height="32" rx="16" fill="#AFB0B3"/>
        <g filter="url(#filter0_d_1060_18308)">
          <circle cx="16.5" cy="16" r="12" fill="#FCFCFC"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_1060_18308" x="0.5" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1060_18308"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1060_18308" result="shape"/>
        </filter>
        <clipPath id="clip0_1060_18308">
          <rect x="0.5" width="64" height="32" rx="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <div *ngSwitchCase="'check'">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <rect y="0.5" width="20" height="20" rx="3" fill="#53B6ED"/>
      <path d="M5 10.5L8.33333 14.5L15 6.5" stroke="#FCFCFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div *ngSwitchCase="'uncheck'">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <rect x="1" y="1.5" width="18" height="18" rx="2" stroke="#AFB0B3" stroke-width="2"/>
    </svg>
  </div>
  <div *ngSwitchCase="'lock'">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M4.5 14.8C4.5 13.1198 4.5 12.2798 4.82698 11.638C5.1146 11.0735 5.57354 10.6146 6.13803 10.327C6.77976 10 7.61984 10 9.3 10H15.7C17.3802 10 18.2202 10 18.862 10.327C19.4265 10.6146 19.8854 11.0735 20.173 11.638C20.5 12.2798 20.5 13.1198 20.5 14.8V16.2C20.5 17.8802 20.5 18.7202 20.173 19.362C19.8854 19.9265 19.4265 20.3854 18.862 20.673C18.2202 21 17.3802 21 15.7 21H9.3C7.61984 21 6.77976 21 6.13803 20.673C5.57354 20.3854 5.1146 19.9265 4.82698 19.362C4.5 18.7202 4.5 17.8802 4.5 16.2V14.8Z" fill="#AFB0B3"/>
      <path d="M17.5 10V8C17.5 5.23858 15.2614 3 12.5 3C9.73858 3 7.5 5.23858 7.5 8V10M12.5 14.5V16.5M9.3 21H15.7C17.3802 21 18.2202 21 18.862 20.673C19.4265 20.3854 19.8854 19.9265 20.173 19.362C20.5 18.7202 20.5 17.8802 20.5 16.2V14.8C20.5 13.1198 20.5 12.2798 20.173 11.638C19.8854 11.0735 19.4265 10.6146 18.862 10.327C18.2202 10 17.3802 10 15.7 10H9.3C7.61984 10 6.77976 10 6.13803 10.327C5.57354 10.6146 5.1146 11.0735 4.82698 11.638C4.5 12.2798 4.5 13.1198 4.5 14.8V16.2C4.5 17.8802 4.5 18.7202 4.82698 19.362C5.1146 19.9265 5.57354 20.3854 6.13803 20.673C6.77976 21 7.61984 21 9.3 21Z" stroke="#08354F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div *ngSwitchCase="'unlock'">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M7.5 10V8C7.5 5.23858 9.73858 3 12.5 3C14.5503 3 16.3124 4.2341 17.084 6M12.5 14.5V16.5M9.3 21H15.7C17.3802 21 18.2202 21 18.862 20.673C19.4265 20.3854 19.8854 19.9265 20.173 19.362C20.5 18.7202 20.5 17.8802 20.5 16.2V14.8C20.5 13.1198 20.5 12.2798 20.173 11.638C19.8854 11.0735 19.4265 10.6146 18.862 10.327C18.2202 10 17.3802 10 15.7 10H9.3C7.61984 10 6.77976 10 6.13803 10.327C5.57354 10.6146 5.1146 11.0735 4.82698 11.638C4.5 12.2798 4.5 13.1198 4.5 14.8V16.2C4.5 17.8802 4.5 18.7202 4.82698 19.362C5.1146 19.9265 5.57354 20.3854 6.13803 20.673C6.77976 21 7.61984 21 9.3 21Z" stroke="#08354F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div *ngSwitchCase="'x'">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17 7L7 17M7 7L17 17" stroke="#AFB0B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</ng-container>