import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';

import * as moment from 'moment';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	merge,
	Subscription,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SortDirection } from '@angular/material/sort';

import { QuickFilterService } from './../../../../shared/components/quick-filter/quick-filter.service';
import { QuotesOrdersService } from '../../quotes-and-orders.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UserService } from '@services/user.service';

import { isValueEmpty } from '@app/shared/utilities/helper';

import {
	ApplicationType,
	FilterUrl,
	NotificationMessages,
	TableSignalType,
	Themes,
} from '@app/shared/constants';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { InputSelectType } from '@app/shared/components/parent-child-input-selector/parent-child-input-selector.component';
import { IOrders } from './store/orders.interface';
import { IQuickFilter } from '@app/shared/new-table-shared/new-table-shared.interface';
import { ParentChildAccessType } from '@app/shared/interfaces/companies.interface';
import { QuickFilter } from './../../../../shared/interfaces/order.interface';

import { ordersSelector } from './store/orders.selector';
import { TableReloadSignalService } from '@app/shared/services/table-reload-signal.service';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;
	@ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
	@ViewChild('searchTextbox') searchTextbox: ElementRef;

	dateFilterLabel = { start: 'Start Date:', end: 'End Date:' };
	clearDates = false;

	filterUrl: string = 'sort15';
	form = new FormGroup({
		statusIds: new FormControl<number[]>(this._ordersService.orderStatusIds),
		companyId: new FormControl(this._userService.companyId),
		queryStartDate: new FormControl(''),
		queryEndDate: new FormControl('')
	});
	search = new FormControl();
	childCompanyControl = new FormControl<InputSelectType>({});
	totalItems = 0;
	orderStatus: any[] = [];
	childCompanies: any[] = [];
	qFilterUrl = FilterUrl;

	userId =
		this._userService.user !== null && this._userService.user.roleId === 3
			? this._userService.user.id
			: 0;
	companyId =
		this._userService.user !== null && this._userService.user.roleId !== 1
			? this._userService.user.companyId
			: 0;

	resetVal = false;

	searchFilters: IOrders;
	quickFilter: IQuickFilter;

	column: string;
	order: SortDirection;
	page: number;
	pageSize: number;

	private _subscription = new Subscription();

	constructor(
		public spinner: SpinnerService,
		private _cd: ChangeDetectorRef,
		private _ordersService: QuotesOrdersService,
		private _quickFilterService: QuickFilterService,
		private _store: Store<AppStateInterface>,
		private _themeService: ThemeService,
		private _toastMessageService: ToastMessageService,
		private _userService: UserService,
		private _tableReloadSignalService: TableReloadSignalService
	) {}

	get applicationType() {
		return ApplicationType;
	}

	get isFilterOn(): boolean {
		const statusIds = this.form.get('statusIds')?.value;
		const hasStatus = !!(
			statusIds &&
			statusIds?.length > 0 &&
			statusIds[0] !== 0
		);
		const hasDateStart =
			!isValueEmpty(this.form.controls.queryStartDate.value) &&
			moment(this.form.controls.queryStartDate.value).isValid();
		const hasDateEnd =
			!isValueEmpty(this.form.controls.queryEndDate.value) &&
			moment(this.form.controls.queryEndDate.value).isValid();

		return hasStatus || hasDateStart || hasDateEnd;
	}

	ngOnInit() {
		this._subscription.add(
			this._themeService.themeType.pipe().subscribe((theme) => {
				if (theme === Themes.light) {
					this.filterUrl = 'sort15';
				} else if (theme === Themes.dark) {
					this.filterUrl = 'sort';
				}
			})
		);

		setTimeout(() => {
			console.log(this.searchFilters);
		}, 3000);

		this._initTableReloadFromSignaIR();
	}

	async ngAfterViewInit() {
		this.spinner.start();
		await this.#getDropdowns();
		if (!this._userService.isSpAdmin) await this.#getChildCompanies();
		this.spinner.stop();
		this._loadPreviousFilters();
		this.#eventListeners();
		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	export(exportType: ApplicationType) {
		const options: IOrders = this._getFilters();

		if (this.totalItems === 0) {
			this._toastMessageService.showErrorMessage(
				NotificationMessages.NoRecordFound
			);
		} else {
			this.spinner.start();

			this._ordersService.exportOrders(options, exportType).subscribe({
				next: (resp) => {
					const link = document.createElement('a');
					link.href = resp;
					link.setAttribute('download', 'Quotes');
					document.body.appendChild(link);
					link.click();
				},
				error: () => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
				complete: () => {
					this._toastMessageService.showSuccessMessage(
						NotificationMessages.Export
					);
				},
			});
		}
	}

	setQuickFilter(event: QuickFilter[]) {
		const statusIds = this.form.get('statusIds')?.value as Number[];
		this.form
			.get('statusIds')
			?.setValue(this._quickFilterService.mapStatusIds(statusIds, event));
		this.fetchNewData();
	}

	setStartDate(startDate: string) {
		if (startDate.toLowerCase().includes('invalid')) startDate = '';
		this.fetchNewData();
	}

	setEndDate(endDate: string) {
		if (endDate.toLowerCase().includes('invalid')) endDate = '';
		this.fetchNewData();
	}

	childCompanyChanged(value: InputSelectType) {
		const val = value.isParentSelector
			? this._userService.companyId
			: value.companySelected?.id ?? this._userService.companyId;

		if (this.page == 1) this.form.controls.companyId.setValue(val);
		else {
			this.form.controls.companyId.setValue(val, { emitEvent: false });
		}
	}

	fetchNewData() {
		const filters: IOrders = this._getFilters();

		this.searchFilters = filters;
		console.log(this.searchFilters);
	}

	onEmitTotalItems(totalItems: number) {
		this.totalItems = totalItems;
	}

	#eventListeners() {
		const inputEvent = this.search.valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged()
		);

		const enterEvent = fromEvent(
			this.searchTextbox.nativeElement as HTMLInputElement,
			'keyup'
		).pipe(
			filter((e) => (e as KeyboardEvent).keyCode === 13),
			map((e) => (e.target as HTMLInputElement).value)
		);

		this._subscription.add(
			merge(inputEvent, enterEvent)
				.pipe(
					distinctUntilChanged(),
					switchMap(async (query) => {
						this.page = 1;
						this.fetchNewData();

						return query;
					})
				)
				.subscribe()
		);

		this._subscription.add(
			this.clearFilter.clearAll.subscribe(() => {
				const setEmpty = (field: any) => {
					if (field instanceof FormControl) {
						if (field.value instanceof Array) {
							field.setValue([]);
						} else {
							field.setValue('');
						}
					}
				};

				setEmpty(this.form.controls.queryStartDate);
				setEmpty(this.form.controls.queryEndDate);
				this.form.get('statusIds')?.setValue([]);
				this.clearDates = true;
				this.resetVal = true; // trigger the reset
				setTimeout(() => {
					this.resetVal = false; // set back to false after you trigger reset
				}, 500);

				this.fetchNewData();
			})
		);

		this._subscription.add(
			this.form.valueChanges.subscribe((val) => {
				this.searchFilters = this._getFilters();
				this.quickFilter = {
					statusIds: this.form.get('statusIds')?.value as number[],
					onSelectIds: this.setQuickFilter.bind(this),
					url: this.qFilterUrl.ORDERS,
				};
			})
		);

		this._subscription.add(
			this._ordersService.isLoading$
				.pipe(
					tap((isLoading) => {
						if (isLoading) {
							this.search.disable();
						} else {
							if (this.search.disabled) {
								this.search.enable();
							}
							(this.searchTextbox.nativeElement as HTMLInputElement).focus();
						}
					})
				)
				.subscribe()
		);
	}

	#getDropdowns() {
		return new Promise((resolve) => {
			this._ordersService.getOrderStatusDropdown().subscribe((res: any) => {
				this.orderStatus = res.map(
					(resp: { id: number; name: string | number }) => ({
						value: resp.id,
						display: resp.name,
						selected: false,
					})
				);
				resolve(res);
			});
		});
	}

	#getChildCompanies() {
		return new Promise((resolve) => {
			this._ordersService
				.getChildCompanies(ParentChildAccessType.Order)
				.subscribe((res) => {
					if (res.length) this.childCompanies = res;
					resolve(res);
				});
		});
	}

	#isEmpty = (val: any, ret: any = undefined) => {
		if (val) return val;
		else return ret != undefined ? ret : '';
	};

	private _loadPreviousFilters() {
		this._store.pipe(select(ordersSelector), take(1)).subscribe((filter) => {
			this.search.patchValue(filter.query);
			this.form.get('statusIds')?.patchValue(filter.statusId!);
			this.form.get('companyId')?.patchValue(filter.companyId ?? 0);
			this.childCompanyControl.setValue({
				isParentSelector:
					!filter.companyId || filter.companyId == this._userService.companyId,
				companySelected: {
					id: filter.companyId ?? 0,
					name: this.childCompanies.find((c) => c.id == filter.companyId)?.name,
					addressLine1: '',
				},
			});
			this.form.get('queryStartDate')?.patchValue(filter.startDate || '');
			this.form.get('queryEndDate')?.patchValue(filter.endDate || '');
			this.page = filter.page;
			this.pageSize = filter.pageSize;
			this.column = filter.column;
			this.order = filter.order;

			this.searchFilters = this._getFilters();
			this.quickFilter = {
				statusIds: this.form.get('statusIds')?.value as number[],
				onSelectIds: this.setQuickFilter.bind(this),
				url: this.qFilterUrl.ORDERS,
			};
		});
	}

	private _getFilters(): IOrders {
		return {
			page: this.page,
			pageSize: this.pageSize,
			order: this.order,
			column: this.column,
			query: this.search.value!.trim(),
			statusId: (this.form.get('statusIds')?.value as number[]).filter(
				(x) => x !== undefined && x > 0
			),
			startDate: this.form.controls['queryStartDate'].value || '',
			endDate: this.form.controls['queryEndDate'].value || '',
			userId: this.userId,
			companyId: this.#isEmpty(this.form.get('companyId')?.value, 0),
		};
	}

	private _initTableReloadFromSignaIR() {
		this._tableReloadSignalService.tableReloadFromSignaIR(TableSignalType.ORDER)
		.subscribe({ 
			next: () => {
				this.fetchNewData();
				this.#getDropdowns();
				this.newTable.onFetchQuickFilter();
			} 
		});
	}
}
