import { createAction, props } from "@ngrx/store";
import { ITopBar } from "../top-bar.interface";


export enum ActionTypes{
  TOPBAR_UPDATE = '[TopBar] Update',
  TOPBAR_UPDATE_SUCCESS = '[TopBar] Update Success',

  TOPBAR_SET = '[TopBar] Set',
}

export const topBarUpdateAction =
createAction(
  ActionTypes.TOPBAR_UPDATE,
  props<Partial<ITopBar>>()
)

export const topBarUpdateSuccessAction =
createAction(
  ActionTypes.TOPBAR_UPDATE_SUCCESS
)

