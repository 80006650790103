import { Component, OnInit } from '@angular/core';
import { MatCalendarHeader } from '@angular/material/datepicker';

@Component({
	selector: 'calendar-header',
	templateUrl: './calendar-header.component.html',
	styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent
	extends MatCalendarHeader<any>
	implements OnInit
{
	mode: PickerMode;
	modes = PickerMode;
	months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	years: number[] = [];

	get monthLabel() {
		return this.months[this.activeMonth];
	}

	get activeMonth() {
		return (this.calendar.activeDate as Date).getMonth();
	}

	get activeYear() {
		return (this.calendar.activeDate as Date).getFullYear();
	}

	ngOnInit(): void {
		this._modifyPicker();
		this._setYears();
	}

	previous() {
		this.previousClicked();
		this._modifyPicker();
	}

	next() {
		this.nextClicked();
		this._modifyPicker();
	}

	setMonth(month: number) {
		const active = new Date(this.calendar.activeDate);
		active.setMonth(month);
		this.calendar.activeDate = active;
		this._modifyPicker();
	}

	setYear(year: number) {
		const active = new Date(this.calendar.activeDate);
		active.setFullYear(year);
		this.calendar.activeDate = active;
		this._modifyPicker();
	}

	setMode(mode: PickerMode) {
		this.mode = mode;
		if (mode != PickerMode.Day) {
			const active = document.querySelector('.q-date-month-year .active-item');
			if (active) active.scrollIntoView({ block: 'center' });
		}
	}

	onScrollYear(event: any) {
		const h: number = event.target.offsetHeight,
			sh: number = event.target.scrollHeight,
			st: number = event.target.scrollTop;

		if (sh - h <= st) this._setYears();
	}

	private _modifyPicker() {
		try {
			setTimeout(() => {
				const active = new Date(this.calendar.activeDate);
				active.setDate(0);
				const prev = active.getDate();

				const body: HTMLElement =
					this.calendar.monthView._matCalendarBody['_elementRef'].nativeElement;
				const rows = body.children;

				if (rows[0].children.length == 1) body.removeChild(rows[0]);

				const first = rows[0].children[0];
				if (first.classList.contains('mat-calendar-body-label')) {
					rows[0].removeChild(first);
					const l = 7 - rows[0].children.length;
					for (let i = 0; i < l; i++) {
						let td = document.createElement('td');
						td.innerText = `${prev - i}`;
						td.className = 'mat-calendar-body-cell-container past-future';
						rows[0].prepend(td);
					}
				}

				const last = rows[rows.length - 1];
				if (last.children.length < 7) {
					const l = 7 - last.children.length;
					for (let i = 0; i < l; i++) {
						let td = document.createElement('td');
						td.innerText = `${i + 1}`;
						td.className = 'mat-calendar-body-cell-container past-future';
						last.append(td);
					}
				}
			});
		} catch (e) {}
	}

	private _setYears() {
		let start = this.years[this.years.length - 1];
		let step = 10;

		if (!this.years.length) {
			start = 1979;
			step = this.activeYear + 2 - start;
		}

		let years: number[] = [];
		for (let i = 1; i < step; i++) {
			years.push(start + i);
		}
		this.years.push(...years);
	}
}

enum PickerMode {
	Day,
	Month,
	Year,
}
