<div class="invoice-detail">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body q-d-grid q-g-16">
					<ng-container *ngTemplateOutlet="cardHeader"></ng-container>
					<ng-container *ngTemplateOutlet="invoiceHeader"></ng-container>
				</div>
			</div>

			<div class="card pt-4">
				<div class="card-body px-0 pb-0 pt-0">
					<ng-container *ngTemplateOutlet="invoiceTbl"></ng-container>
				</div>
			</div>
		</div>

		<div class="col-12">
			<app-invoice-activity
				[invoiceId]="invoiceId">
			</app-invoice-activity>
		</div>
	</div>
</div>

<!-- cadrHeader Template-->
<ng-template #cardHeader>
	<div class="card-header-2 q-pb-0 q-ai-center">
		<h3 class="q-mr-auto">
			<ng-container
				*ngIf="invoice?.transactionType === transactionType.invoice">
				Invoice Details
			</ng-container>

			<ng-container
				*ngIf="invoice?.transactionType === transactionType.creditMemo">
				Credit Memo Details
			</ng-container>

			<ng-container
				*ngIf="invoice?.transactionType === transactionType.payment">
				Payment Details
			</ng-container>
		</h3>

		<search-input
      #searchTextbox
			[formControl]="queryString"
			placeholder="Search Invoices"
			size="small"
		></search-input>

		<ng-container *ngIf="isStripeEnabled && isPaymentInvoiceEnabled">
			<a
				*ngIf="isPaymentEnabled && invoice?.canPayInvoice"
				href="javascript:void(0)"
				class="btn-md btn-success"
				(click)="payInvoice()">
				<app-figma-icon-img
					[iconName]="'check'"
					[hasSpaceOnRight]="true">
				</app-figma-icon-img>
				Pay Invoice
			</a>
		</ng-container>

		<ng-container *ngIf="isCloseButtonHidden">
			<app-secondary-button
					[isBackButton]="true"
					(click)="onClose()"
					[removeMsAuto]="true"
			></app-secondary-button>
		</ng-container>

		<a href="javascript:void(0)"
			class="q-pointer q-d-flex q-ai-center"
			[matMenuTriggerFor]="actionButtonsRef">
			<app-figma-icon-img
				[iconName]="'dots-vertical'"
				[width]="24"
				[height]="24"
				[isForButton]="true"
				[hasSpaceOnRight]="false">
			</app-figma-icon-img>
		</a>

		<mat-menu #actionButtonsRef="matMenu" class="v2-menu-action-button">
			<ng-container *ngIf="isDownloadEnabled">
				<button mat-menu-item (click)="downloadInvoice(invoiceId)">
					<div class="menu-item-group">
						<div class="menu-item-icon">
							<mat-icon svgIcon="download-02" class="menu-item-svg"></mat-icon>
						</div>
						<div class="menu-item-name font-b3">Download Invoice</div>
					</div>
				</button>
			</ng-container>

			<button mat-menu-item (click)="downloadInvoiceReceipt(invoiceId)">
				<div class="menu-item-group">
					<div class="menu-item-icon">
						<mat-icon svgIcon="receipt-check" class="menu-item-svg"></mat-icon>
					</div>
					<div class="menu-item-name font-b3">Download Receipts</div>
				</div>
			</button>

			<ng-container *ngIf="isInquireEnabled">
				<button 
					mat-menu-item 
					routerLink="/service-and-support/new-ticket/{{invoiceInquiryFormId}}/create"
					[queryParams]="{ u: formUsageData }">
					
					<div class="menu-item-group">
						<div class="menu-item-icon">
							<mat-icon svgIcon="message-question-circle" class="menu-item-svg"></mat-icon>
						</div>
						<span class="menu-item-name font-b3">Submit Inquiry</span>
					</div>
				</button>
			</ng-container>
		</mat-menu>
	</div>
</ng-template>

<!--Header Template-->
<ng-template #invoiceHeader>
	<ng-container *ngIf="invoice as header">
		<div class="invoice-header-details q-d-flex q-f-column q-ai-start q-g-4 q-ai-stretch">
			<div class="invoice-header-details_group q-d-flex q-ai-start q-ac-start q-as-stretch">
				<div class="invoice-header-details_group-column q-d-flex q-f-column q-jc-end q-ai-start q-g-4 q-f-1-0-0">
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Status:</div>
						<div class="invoice-header-details_value font-b1 q-d-flex q-jc-start">
							<app-badge
								[label]="header.status"
								[type]="header.color"
								[fontColor]="header.fontColor">
							</app-badge>
						</div>
					</div>
					
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Invoice #:</div>
						<div class="invoice-header-details_value font-b1">{{ header.invoiceNumber }}</div>
					</div>

					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Order #:</div>
						<div class="invoice-header-details_value font-b1" [class.visibility-hidden]="header.orderId === 0">{{ header.orderId }}</div>
					</div>
				</div>

				<div class="invoice-header-details_group-column q-d-flex q-f-column q-jc-end q-ai-start q-g-4 q-f-1-0-0">
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Invoice Date:</div>
						<div class="invoice-header-details_value font-b1">{{ header.invoiceDate | date }}</div>
					</div>
					
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Due Date:</div>
						<div class="invoice-header-details_value font-b1">{{ header.dueDate | date }}</div>
					</div>

					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Terms:</div>
						<div class="invoice-header-details_value font-b1">{{ header.term }}</div>
					</div>
				</div>

				<div class="invoice-header-details_group-column q-d-flex q-f-column q-jc-end q-ai-start q-g-4 q-f-1-0-0">
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Bill Amount:</div>
						<div class="invoice-header-details_value font-b1">{{ header.billAmount | currency }}</div>
					</div>
					
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Balance Due:</div>
						<div class="invoice-header-details_value font-b1">{{ header.balance | currency }}</div>
					</div>
				</div>
			</div>

			<div class="invoice-header-details_group q-d-flex q-ai-start q-ac-start q-as-stretch">
				<div class="invoice-header-details_group-column q-d-flex q-f-column q-jc-end q-ai-start q-g-4 q-f-1-0-0">
					<div class="invoice-header-details_group-label q-d-flex q-ai-center q-as-stretch">
						<div class="invoice-header-details_label font-b1">Description:</div>
						<div class="invoice-header-details_value font-b1">{{ header.description }}</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<!-- Invoice Table-->
<ng-template #invoiceTbl>
	<ng-container *ngIf="invoiceId > 0">
		<div class="table-container" [hidden]="
			form.value.id === undefined ||
			form.value.id === null ||
			form.value.id === 0">

			<div class="table-wrapper scroll-v2">
				<table
					mat-table
					[dataSource]="dataSource"
					matSort
					class="table table-flush dataTable-table q-mb-0"
					matSortActive="id"
					matSortDirection="asc"
					matSortDisableClear="true"
				>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
						<td mat-cell *matCellDef="let row" class="text-xs">
							{{ row.id }}
						</td>
					</ng-container>
	
					<ng-container matColumnDef="identifier">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Product ID
						</th>
						<td class="text-xs" mat-cell *matCellDef="let row">
							{{ row.identifier }}
						</td>
					</ng-container>
	
					<ng-container matColumnDef="description">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Description
						</th>
						<td mat-cell *matCellDef="let row" class="text-xs">
							{{ row.description }}
						</td>
					</ng-container>
	
					<ng-container matColumnDef="invoiceDate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Invoice Date
						</th>
						<td mat-cell *matCellDef="let row" class="text-xs">
							{{ row.invoiceDate | customDate }}
						</td>
					</ng-container>
	
					<ng-container matColumnDef="quantity">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>QTY</th>
						<td mat-cell *matCellDef="let row" class="text-xs">
							{{ row.quantity }}
						</td>
					</ng-container>
	
					<ng-container matColumnDef="unitPrice">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Unit Price
						</th>
						<td mat-cell *matCellDef="let row" class="text-xs">
							{{ row.unitPrice | currency }}
						</td>
					</ng-container>
	
					<ng-container matColumnDef="total">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
						<td mat-cell *matCellDef="let row" class="text-xs">
							{{ row.total | currency }}
						</td>
					</ng-container>
	
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	
					<!-- Row shown when there is no matching data. -->
					<tr class="mat-row" *matNoDataRow>
						<td
							class="mat-cell"
							[attr.colspan]="displayedColumns.length"
							style="text-align: center !important"
						>
							{{ message.noRecord }}
						</td>
					</tr>
				</table>
			</div>
		</div>
	
		<div class="table-paginator-container q-mt-24" [hidden]="!hasData">
			<div class="paginator-group">
				<table-paginator
					[totalItems]="totalItems"
					[pageSizes]="pageSizes"
					(pageChange)="getInvoiceProductList('page', $event)"
				></table-paginator>
			</div>
	
			<div class="item-per-page-group">
				<app-item-per-page
					(sizeChange)="getInvoiceProductList('size', $event)"
					[totalItems]="totalItems"
				></app-item-per-page>
			</div>
		</div>
	</ng-container>
</ng-template>
