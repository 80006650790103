import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { TableMessages } from '@app/shared/constants';
import { TableDynamicColumns } from '@app/shared/interfaces/dashboard.interface';

@Component({
  selector: 'activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent implements OnInit, OnChanges {
  // ViewChild
  //@ViewChild(MatSort, {static: false}) sort: MatSort;

  // Input / Output
  @Input() source: any[] | null  = [];
  @Input() columns: TableDynamicColumns[] | null= [];
  @Input() displayedColumns:string[] | null = [];
  @Input() rowClick:Function = (row:any)=>void(0);
  dataSource: MatTableDataSource<any>
  noRecords:string = TableMessages.EmptyTable;
  @Input() defaultSort: string;
  @Output() sort = new EventEmitter<Sort>()

  constructor(private cd: ChangeDetectorRef) {
  }

  // lifecycle
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['source']) {
      if (this.source) {
        this.dataSource = new MatTableDataSource(this.source);
        //this.dataSource.sort = this.sort;
      }
      if (this.columns && !this.defaultSort) {
        this.defaultSort = this.columns[0]?.matColumnDef
      }
      this.cd.markForCheck();
    }
  }

  // public method
  sortData(data:Sort){
    //console.log('sortData', data);
    this.sort.emit(data)
  }

}


