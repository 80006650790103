import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-pdf-ticket',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pdf-ticket.component.html',
  styleUrls: ['./pdf-ticket.component.scss']
})
export class PdfTicketComponentModal implements OnInit, OnChanges {
  pdfSrc: any;
  ticketNumber: any;
  @Input() pdfData: {pdfUrl: string, pdfId: number};
  @Output() state = new EventEmitter<string>();

  constructor(
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['pdfData'] && !changes['pdfData'].firstChange){
      this.initQueryParam()
    }
  }


  initQueryParam() {
    this.pdfSrc = this.domSanitize( this.pdfData.pdfUrl as string + '#zoom=150');
    this.ticketNumber = this.pdfData.pdfId;
  }

  domSanitize(value: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  onClickClose() {
    this.state.emit('ticketDetails')
  }


}
