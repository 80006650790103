import { Injectable } from '@angular/core';

import { BehaviorSubject, finalize, Observable, of, ReplaySubject, tap } from 'rxjs';

import { IFontFamily, IFontTemplateFilter, IFontTemplateList, IWeightStyle } from './fonts-template-settings.interface';

@Injectable({
  providedIn: 'root'
})

export class FontsTemplateSettingsService {
  fontTemplateFilters: IFontTemplateFilter;

  private _fontTemplateData = new ReplaySubject<any[]>(1);
	fontTemplateData$ = this._fontTemplateData.asObservable();

	private _fontTemplateTotalItems = new ReplaySubject<number>(1);
	fontTemplateTotalItems$ = this._fontTemplateTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

  set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

  getFontTemplateTable(options: IFontTemplateFilter): Observable<IFontTemplateList> {
    this.fontTemplateFilters = options;

    const param: any = this.getValidParameters({
			Page: options.page,
			PageSize: options.pageSize,
			Column: options.column,
			Order: options.order
		});

    this.isLoading = true;
		this._fontTemplateData.next([]);
		this._fontTemplateTotalItems.next(0);

    return of({
          "data": [
              {
                  "name": "Heading",
                  "weightName": "Black",
                  "weightValue": 700,
                  "size": "29px",
                  "color": "#08354F",
                  "preview": "Metropolis"
              },
              {
                  "name": "Heading",
                  "weightName": "Extra Bold",
                  "weightValue": 700,
                  "size": "19px",
                  "color": "#5C5D60",
                  "preview": "Metropolis"
              }
          ],
          "totalCount": 2,
          "pageSize": 1,
          "currentPage": 1
      })
			.pipe(
				tap((res: any) => {
					this._fontTemplateData.next(res.data);
					this._fontTemplateTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
  }

  getStyleWeight(): Observable<IWeightStyle[]> {
    return of(
      [
        {
          id: 1,
          display: 'Thin (Hairline)',
          value: 100
        },
        {
          id: 2,
          display: 'Extra Light (Ultra Light)',
          value: 200
        },
        {
          id: 3,
          display: 'Light',
          value: 300
        },
        {
          id: 4,
          display: 'Normal (Regular)',
          value: 400
        },
        {
          id: 5,
          display: 'Medium',
          value: 500
        },
        {
          id: 6,
          display: 'Semi Bold (Demi Bold)',
          value: 600
        },
        {
          id: 7,
          display: 'Bold',
          value: 700
        },
        {
          id: 8,
          display: 'Extra Bold (Ultra Bold)',
          value: 800
        },
        {
          id: 9,
          display: 'Black (Heavy)',
          value: 900
        }
      ]
    )
  }

  getFontFamilyList(): Observable<IFontFamily[]> {
    return of(
      [
        {
          display: 'Metropolis',
          value: 'Metropolis'
        }
      ]
    );
  }

  getValidParameters(param: any) {
		return Object.fromEntries(
			Object.entries(param).filter(
				([key, value]: any) => !['', null, undefined].includes(value)
			)
		);
	}
}
