import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit, OnChanges {

  @Input() name: string = '';

  constructor() { }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
