<!-- V2 Add bank account -->
<div class="card">
  <div class="card-header pb-0">
    <div class="d-lg-flex">
      <div class="invoice-width">
        <div class="bank-form_title font-h3">Add Bank Account</div>
      </div>
			
      <div class="ms-auto my-auto mt-lg-0 mt-4 invoice-details-page">
        <div class="ms-auto my-auto">
					<app-secondary-button
						[isBackButton]="true"
						(click)="back()"
					></app-secondary-button>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body p-3">
		<form class="bank-form q-d-flex q-ai-start q-f-column q-g-24" [formGroup]="bankForm" (ngSubmit)="onClickSubmit()">
			<div class="bank-form_container q-d-flex q-ai-start q-as-stretch">
				<div class="bank-form_group-left q-d-flex q-f-column q-ai-start q-g-24">
					<div class="bank-form_form-group q-d-flex q-f-column q-ai-start q-g-8">
						<h4>Bank Account Information</h4>
					</div>

					<div class="bank-form_form-group form-affective-by-popover account-holder-type q-d-flex q-f-column q-ai-start q-g-8">
						<div class="bank-form_form-label font-b1">Account Holder Type</div>
						<select-input
							[hasClear]="false"
							[options]="accntHolderTypeOpt"
							[optionKeys]="{ value: 'val', label: 'label' }"
							inputClass="form-control"
							formControlName="accountHolderType"
							class="input-class">
						</select-input>
					</div>
					
					<div class="bank-form_form-group form-affective-by-popover q-d-flex q-f-column q-ai-start q-g-8">
						<div class="bank-form_form-label font-b1">Name on Account</div>
						<input
							type="text"
							id="account-holder-name"
							class="form-control bank-form"
							placeholder="John Hancock"
							onfocus="focused(this)"
							onfocusout="defocused(this)"
							autocomplete="off"
							formControlName="accountHolderName"/>
					</div>

					<div class="q-d-grid q-g-8">
						<div class="bank-form_form-group form-affective-by-popover q-d-flex q-f-column q-ai-start q-g-8">
							<div class="bank-form_form-label font-b1">Email</div>
							<input
								type="text"
								id="email-account"
								class="form-control bank-form"
								placeholder="john@example.com"
								onfocus="focused(this)"
								onfocusout="defocused(this)"
								autocomplete="off"
								formControlName="email"/>
						</div>
					</div>
				</div>
				
				<div class="bank-form_group-right q-d-flex q-f-column q-jc-center q-g-24 q-mt-40">
					<div class="bank-form_form-group form-affective-by-popover q-d-flex q-f-column q-ai-start q-g-8">
						<div class="bank-form_form-label font-b1">Routing Number</div>
						<input
							type="text"
							id="routing-number"
							class="form-control bank-form"
							placeholder="000000000"
							formControlName="routingNumber"
							size="9"
							maxLength="9"
							(keypress)="returnAsNumber($event)"
							autocomplete="off"/>
					</div>

					<div class="bank-form_form-group form-affective-by-popover q-d-flex q-f-column q-ai-start q-g-8">
						<div class="bank-form_form-label font-b1">Account Number</div>
						
						<div class="bank-account-form q-w-100">
							<input
								type="text"
								id="account-number"
								class="form-control bank-form account-num"
								placeholder="00000000000"
								formControlName="accountNumber"
								size="17"
								maxLength="17"
								(keypress)="returnAsNumber($event)"
								autocomplete="off"/>
						</div>
					</div>

					<div class="bank-form_form-group form-affective-by-popover q-d-flex q-f-column q-ai-start q-g-8">
						<div class="bank-form_form-label font-b1">Confirm Account Number</div>

						<div class="bank-account-form q-w-100">
							<input
								type="text"
								id="confirm-account-number"
								class="form-control bank-form account-num"
								placeholder="00000000000"
								formControlName="confirmAccountNumber"
								size="17"
								maxLength="17"
								(keypress)="returnAsNumber($event)"
								autocomplete="off"/>
						</div>
					</div>

					<div class="bank-form_form-group q-d-flex q-f-column q-ai-start q-g-8">
						<div class="account-need-help d-flex">
							<div class="account-need-help-info d-flex w-100">
								<span class="d-flex mx-3">
									<app-figma-icon-img
										class="q-help"
										[iconName]="'help-circle'"
										[hasSpaceOnRight]="false">
									</app-figma-icon-img>
								</span>

								<div class="account-need-help-info-content font-b1">
									Need help finding the correct numbers?
								</div>
							</div>

							<div class="account-info-popup">
								<div class="account-info-popup-inner">
									<div class="account-info-title">
										<h4>Look at your business check</h4>
									</div>

									<div class="business-form-area">
										<div class="business-form-block">
											<div class="company-address-name d-flex">
												<div class="company-address-text">
													<p>Name of Company</p>
													<p>Address, City, State</p>
												</div>

												<div class="company-address-field d-flex">
													<div class="company-field">
														<input
															type="text"
															name="company-name"/>
													</div>

													<div class="address-field">
														<input
															type="text"
															name="address-name"/>
													</div>
												</div>
											</div>

											<div class="company-pay-area d-flex">
												<div class="company-pay-text">
													<p>Pay to</p>
													<input type="text" name="pay" />
													<input type="text" name="pay" />
												</div>

												<div class="company-pay-num">
													<div class="company-pay-num-text d-flex">
														<p>$</p>
														<input type="text" name="pay" />
													</div>
													<p>Dollars</p>
												</div>
											</div>

											<div class="company-acc-num-area d-flex">
												<div class="company-acc-num-field">
													<input type="text" name="acc-num" />
												</div>
												<div class="company-acc-num-field">
													<input type="text" name="acc-num" />
												</div>
											</div>

											<div class="acc-num-suggest-area">
												<ul>
													<li>
														<p>0000000000</p>
														<span>Routing Number</span>
													</li>
													<li>
														<p>0000000000</p>
														<span>Account Number</span>
													</li>
													<li>
														<p>000</p>
														<span>Check Number</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="account-info-title">
										<h4>If you don’t have a physical check</h4>
									</div>
									<div class="account-info-con">
										<p>
											You can find your routing &amp; account number
											in a few other places:
										</p>
										<ul>
											<li>Your Bank’s Online Account</li>
											<li>Monthly Bank Statements</li>
											<li>
												A Bank Representative, either Over the Phone
												or In-Person
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="bank-form_authorize-form form-affective-by-popover">
				<bank-authorize-form (onEmitAccept)="onEmitAcceptUpdate($event)"></bank-authorize-form>
			</div>

			<div class="bank-form_save-button q-d-flex q-w-100 q-jc-end q-ai-end q-g-16">
				<button
					type="submit"
					class="submit-btn btn-2 btn-2-primary"
					[disabled]="!isAccept">

					<span class="d-flex">
						<app-figma-icon-img
							[iconName]="'check-circle'"
							[isForButton]="false"
							[hasSpaceOnRight]="false">
						</app-figma-icon-img>
					</span>
					Save Account
				</button>
			</div>
		</form>
  </div>
</div>

