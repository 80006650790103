import { ElementRef, EventEmitter, Input, NgModule, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { DynamicTableComponent } from './dynamic-table.component';






@NgModule({
  imports: [
    CommonModule,
   SharedModule
  ],
  exports:[
    DynamicTableComponent
  ],
  declarations: [
    DynamicTableComponent
  ]
})
export class DynamicTableModule {

}
