import { TableMessages, PaginationConstants } from '@app/shared/constants';
import { PaginatorComponent } from './../../../../../../shared/components/paginator/paginator.component';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { BehaviorSubject, catchError, filter, Observable, tap } from 'rxjs';
import { TableDynamicColumns, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';

@Component({
  selector: 'app-on-site-ticket',
  templateUrl: './on-site-ticket.component.html',
  styleUrls: ['./on-site-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnSiteTicketComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

/*   displayedColumns = [
    'ticketId',
    'title',
    'personName',
    'dateStart',
    'dateEnd',
  ]; */
  dataSource$:Observable<OnSiteTicket[] | null>;//: MatTableDataSource<Observable <any>>;
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;
  message = {
    noRecord: TableMessages.EmptyTable,
  };

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: (data: any) => void;
  noClick: ()=>{}
  seeMoreLink = '';

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dashboardService: DashboardService,
    private _router: Router,
    private _ticketService: TicketService,
    private _cd: ChangeDetectorRef,
  ){
    //this.#getOnsiteTicket();
    this._dashboardService.initOnSite()
  }

  ngOnInit(): void {
    this.#initProps()
  }
  ngAfterViewInit(): void {
  }

  #initProps() {
    /* const data$ = this._dashboardService
      .getOnSiteTickets() // 1,5,'ticketId','ASC'
      .pipe(tap(value=>{this.totalItems=value.length; this._cd.markForCheck()}))
    this.dataSource$ = this._dashboardService.getOnSiteTickets()
      .pipe(catchError(v=>[]));*/
    this.dataSource$ = this._dashboardService.onsite$.pipe(filter(v=>v!=null))
    this.dataSource$.subscribe(v=>{
      this.#createDataTable(v)
    })
  }

  rowClickFn = (data: any) => {
		const { ticketId } = data;
    this._router.navigateByUrl(`/service-and-support/tickets/view/${ticketId}`);
	};

  #createDataTable(onsite:OnSiteTicket[] | null){
    this.source = onsite as OnSiteTicket[]
    this.columns = this.#getColumns()
    this.displayedColumns = this.#getDisplayedColumns()
		this.rowClick = this.rowClickFn;
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
  }

  #getColumns():any[]{
    return [{
        text: 'Ticket ID',
        matColumnDef: 'ticketId',
      },
      {
        text: 'Title',
        matColumnDef:'title',
      },
      {
        text: "Person's Name",
        matColumnDef:'personName',
      },
      {
        text: 'Start Date',
        matColumnDef:'dateStart',
      },
      {
        text: 'End Date',
        matColumnDef:'dateEnd',
      },
    ]
  }

  #getDisplayedColumns(){
    return ['ticketId', 'title', 'personName', 'dateStart', 'dateEnd']
  }

  getDataTable(): WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      seeMoreLink: this.seeMoreLink,
      displayedColumns: this.displayedColumns,
			rowClickable: true,
    }
  }

  goToTicket(){
    //Scheduled - Onsite
    this._ticketService.filter.ticketStatusIds = '720';
    this._router.navigateByUrl('/service-and-support/tickets');
  }

  goToDetails(ticketId:number){
    this._router.navigateByUrl(`/service-and-support/tickets/view/${ticketId}`);
  }
}
export interface OnSiteTicket {
  ticketId: number,
  title: string,
  personName: string,
  dateStart: any,
  dateEnd: any
}
