/* Angular Libraries */ 
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

/* Third Party Libraries */ 
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { FinanceService } from '@app/modules/finance/finance.service';

/* Interfaces */
import {
	ActivityGroup,
	InvoiceActivity,
} from '@app/shared/interfaces/invoice.interface';

@Component({
	selector: 'app-invoice-activity',
	templateUrl: './invoice-activity.component.html',
	styleUrls: ['./invoice-activity.component.scss'],
})
export class InvoiceActivityComponent implements OnInit, OnDestroy {
  /* Input/Output */
  @Input() invoiceId: number;

  /* Properties */
  activityList: ActivityGroup[] = [];

	private _$unsubscribe: Subject<void> = new Subject<void>();

  /* Constructor */
	constructor(
		private _financeSerivce: FinanceService,
		private _spinner: SpinnerService
	) {}

  /* Methods */
	ngOnInit(): void {
		this._initActivityList();
	}

	badgeStatus(statusName: string) {
		let color = {
			label: statusName.toLocaleUpperCase(),
			type: '',
			fontColor: '',
		};

		switch (statusName.toLocaleUpperCase()) {
			case 'NEW':
				color.type = '#C4E6F9';
				color.fontColor = '#08354F';
				break;

			case 'OUTSTANDING':
				color.type = '#F7DFC7';
				color.fontColor = '#8A3F08';
				break;

			case 'PAID':
				color.type = '#CAF4DB';
				color.fontColor = '#0D6630';
				break;

			case 'OVERDUE':
				color.type = '#F8D1CD';
				color.fontColor = '#6D1008';
				break;

			case 'PENDING':
				color.type = '#FAF2CE';
				color.fontColor = '#705C03';
		}

		return color;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}

	private _initActivityList() {
		this._spinner.start();

		this._financeSerivce
			.getActivity(this.invoiceId)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: InvoiceActivity[]) => {
					this.activityList = this._groupByDate(result)
					this._spinner.stop();
				},
				error: () => {
					this._spinner.stop();
				},
			});
	}

	private _groupByDate(data: InvoiceActivity[]): any {
		const groups = data.reduce((groups: any, game: any) => {
			const date = game.activityDate.split('T')[0];

			if (!groups[date]) {
				groups[date] = [];
			}

			groups[date].push(game);
			return groups;
		}, {});

		return Object.keys(groups).map((date) => {
			return {
				date,
				groupList: groups[date],
			};
		}).sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date));;
	}
}
