import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'app-secondary-button',
	templateUrl: './secondary-button.component.html',
	styleUrls: ['./secondary-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryButtonComponent implements OnInit {
	@Input() isBackButton: boolean;
	@Input() link: string;
	@Input() removeMsAuto = false;
  @Input() backBtn = false;

	constructor() {}

	ngOnInit(): void {}
}
