<app-widget-container>
  <ng-container *ngIf="systemRoleId < 3; else doughnut">
    <ng-container *ngTemplateOutlet="dataTableTpl"></ng-container>
  </ng-container>
</app-widget-container>


<ng-template #dataTableTpl>
  <div>
    <responsive-table
      [table$]="dataTable$"
    ></responsive-table>
  </div>
  <!-- <ng-container *ngIf="tableDataSource as data">
    <div class="table-responsive">
      <div>
          <table
            class="table table-flush"
            mat-table
            [dataSource]="data.data"
          >
            <ng-container *ngFor="let column of data.columns">
              <ng-container [matColumnDef]="column.matColumnDef">
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  mat-header-cell *matHeaderCellDef [ngStyle]="style.th">
                    {{column.text}}
                </th>
                <td 
                  class="text-xs font-weight-normal" mat-cell *matCellDef="let row"
                  [ngStyle]="style.td"
                >
                  {{row[column.matColumnDef]}}
                </td>
              </ng-container>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="data.displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: data.displayedColumns"
              class="pointer"
              (click)="handleClick()"
            ></tr>
      
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" style="text-align: center;">
                {{ data.noRecordMessage }}
              </td>
            </tr>
          </table>
      </div>
    </div>
  </ng-container> -->
</ng-template>

<ng-template #doughnut>
  <div style="padding:0px 24px;">
    <ng-container *ngIf="pieDataSource?.dataSource; else noRecord">
      <dx-pie-chart
        id="doughnut"
        [type]="pieDataSource.type"
        [title]="pieDataSource.title"
        [dataSource]="pieDataSource.dataSource"
      >
        <dxi-series argumentField="allocation" valueField="val">
          <dxo-label [visible]="true" [format]="GBFormat">
            <dxo-connector [visible]="true"></dxo-connector>
          </dxo-label>
        </dxi-series>
    
        <dxo-legend
          [margin]="10"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        ></dxo-legend>
        <dxo-tooltip
          [enabled]="true"
          [format]="GBFormat"
        >
        </dxo-tooltip>
      </dx-pie-chart>
    </ng-container>
  </div>
</ng-template>

<ng-template #noRecord>
  <span class="font-weight-normal text-xs no-records">No records found.</span> 
</ng-template>