<div class="card">
	<!-- Card header -->
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="card-header pb-0">
			<div class="d-lg-flex">
				<div>
					<h5 class="mb-0">Theme Customization</h5>
				</div>
			</div>
			<div class="categories-form">
				<app-dark-light-theme></app-dark-light-theme>
				<div class="submit-buttons">
					<button
						class="btn submit-btn btn-gradient-primary addnewapp-btn btn-sm imp-disabled-op"
						type="submit"
						[disabled]="spinner.spinner | async"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</form>
</div>

<app-audit-trail
	[url]="historyUrl"
	history_category="default"
	[id]="userId"
></app-audit-trail>
