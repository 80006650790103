import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableOptions } from '@app/shared/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  groupsSearchText: string = '';
  subGroupId: number | null;
  tab = 0;

  constructor(private _http: HttpClient) { }
  clearSearch() {
    this.groupsSearchText = '';
  }

  getGroups(
    option: any,
    companyId: number | null,
    isCustom: boolean | undefined
  ) {
    const custom = isCustom !== undefined ? `&isCustom=${isCustom}` : '';
    const company =
      companyId !== null && companyId !== undefined ? `&companyId=${companyId}` : '';
    let apiUrl = `${environment.apiBaseUrl}Groups?Page=${option.page}&pageSize=${option.pageSize}&column=${option.column}&order=${option.order}&searchText=${option.query}${company}${custom}`;
    return this._http.get<any>(apiUrl);
  }
  getGroupsByCompanyId(companyId: number | null) {
    return this._http.get<any>(`${environment.apiBaseUrl}Groups/Company/${companyId}`);
  }
  getGroupById(groupId: number) {
    return this._http.get<any>(`${environment.apiBaseUrl}Groups/${groupId}`);
  }
  getGroupForBreadcrumb(groupId: number) {
    return this._http.get<any>(`${environment.apiBaseUrl}Groups/GetGroupForBreadcrumb/${groupId}`);
  }
  saveGroup(body: any) {
    return this._http.post(`${environment.apiBaseUrl}Groups`, body)
  }
  updateGroup(body: any) {
    return this._http.put(`${environment.apiBaseUrl}Groups/${body.id}`, body)
  }
  deleteGroup(id: number) {
    return this._http.delete(
      `${environment.apiBaseUrl}Groups/${id}`
    );
  }

  getGroupUsers(p: IPageState, groupId :any, companyId: any) {
    return this._http.get(
      `${environment.apiBaseUrl}Groups/UserList?Page=${p.page}&pageSize=${p.pageSize}&column=${p.column ?? ''}&order=${p.order ?? ''}&companyId=${companyId}&groupId=${groupId}&searchText=${p.query ??''}`
    );
  }

  removeGroupUser(companyId:number, groupId : number, id : number){
    return this._http.delete(
      `${environment.apiBaseUrl}Groups/RemoveUser/${companyId}/${groupId}/${id}`
    );
  }

}
