import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InvoiceStatementComponent } from './pages/invoice-statement/invoice-statement.component';
import { InvoiceDetailComponent } from './pages/invoice-detail/invoice-detail.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { PdfViewerComponent } from './pages/pdf-viewer/pdf-viewer/pdf-viewer.component';
import { CardDetailsComponent } from './pages/card-details/card-details.component';
import { PaymentProcessComponent } from './pages/payment-process/payment-process.component';
import { AddCardDetailsComponent } from './pages/add-card-details/add-card-details.component';
import { AddUsBankAccountComponent } from './pages/add-us-bank-account/add-us-bank-account.component';
import { VerifyBankAccountComponent } from './pages/verify-bank-account/verify-bank-account.component';
import { EditBankDetailsComponent } from './pages/edit-bank-details/edit-bank-details.component';
import { ViewBalanceDetailsComponent } from './pages/view-balance-details/view-balance-details.component';
import { ViewPdfInvoiceStatementComponent } from './pages/view-pdf-invoice-statement/view-pdf-invoice-statement.component';
import { PaymentSecurityComponent } from './pages/payment-security/payment-security.component';
import { PaymentConfirmComponent } from './pages/payment-confirm/payment-confirm.component';

import { UserTypes, Permission } from '@app/shared/constants';
import { PermissionGuard } from '@guards/permission.guard';
import { IncompleteThreeSecureVerificationGuard } from './pages/payment-security/incomplete-three-d-secure-verification/incomplete-three-d-secure-verification.guard';

export const invoiceRoutes: Routes = [
  {
    path: '',
    data: {
      userTypes: [UserTypes.ClientAdmin, UserTypes.User, UserTypes.SourcepassAdmin],
    },
    children: [
      { 
        path: '', component: InvoicesComponent,
      },
      {
        path: 'invoice-detail/:id',
        canActivate: [PermissionGuard],
        data: {
          title: 'Invoice Detail',
          permissions: [
            Permission.UserViewInvoiceDetails,
          ],
        },
        children: [
          {
            path: '',
            component: InvoiceDetailComponent
          },
          {
            path: 'view-pdf/:id',
            component: PdfViewerComponent,
            data: {
              title: 'PDF Viewer',
            },
          }
        ]
      },
      {
        path: 'detail/:id',
        component: InvoiceDetailComponent,
        canActivate: [PermissionGuard],
        data: {
          title: 'Invoice Detail',
          permissions: [
            Permission.UserViewInvoiceDetails,
          ],
        },
      },
      {
        path: 'credit-memo-detail/:id',
        component: InvoiceDetailComponent,
        canActivate: [PermissionGuard],
        data: {
          title: 'Credit Memo Detail',
          permissions: [
            Permission.UserViewInvoiceDetails,
          ],
        },
      },
      {
        path: 'payment-detail/:id',
        component: InvoiceDetailComponent,
        canActivate: [PermissionGuard],
        data: {
          title: 'Payment Detail',
          permissions: [
            Permission.UserViewInvoiceDetails,
          ],
        },
      },
      {
        path: 'view-pdf/:id',
        component: PdfViewerComponent,
        data: {
          title: 'PDF Viewer',
        },
      },
      {
        path: 'invoice-statement',
        data: {
          title: 'Invoice Statement',
        },
        children: [
          {
            path: '',
            component: InvoiceStatementComponent
          },
          {
            path: 'view-pdf',
            component: ViewPdfInvoiceStatementComponent,
            data: {
              title: 'PDF Viewer'
            }
          }
        ]
      },
      {
        path: 'add-card-details',
        component: AddCardDetailsComponent,
        data: {
          title: 'Add Card Details',
        }
      },
      {
        path: 'edit-card-details/:id',
        component: CardDetailsComponent,
        data: {
          title: 'Edit Card Details',
        }
      },
      {
        path: 'edit-bank-details/:id',
        component: EditBankDetailsComponent,
        data: {
          title: 'Edit Bank Details',
        }
      },
      {
        path: 'payment-process',
        data: {
          title: 'Payment',
        },
        children: [
          {
            path: '',
            component: PaymentProcessComponent,
          },
          {
            path: 'payment-security',
            component: PaymentSecurityComponent,
            canDeactivate: [IncompleteThreeSecureVerificationGuard],
            data: {
              title: 'Review & Verification'
            }
          },
          {
            path: 'payment-confirm',
            component: PaymentConfirmComponent,
            data: {
              title: 'Confirmation'
            }
          }
        ]
      },
      {
        path: 'add-us-bank-account',
        component: AddUsBankAccountComponent,
        data: {
          title: 'Add US Bank Account',
        }
      },
      {
        path: 'verify-bank-account',
        component: VerifyBankAccountComponent,
        data: {
          title: 'Verify Bank Account',
        }
      },
      {
        path: 'view-balance-details',
        component: ViewBalanceDetailsComponent,
        data: {
          title: 'View Balance Details',
        }
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(invoiceRoutes)],
  exports: [RouterModule],
})
export class FinanceRoutingModule {}
