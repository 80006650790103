/* Angular Libraries */
import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { NotificationService } from '../../services/notification.service';
import { SmsQuestMobileUpdateModalService } from './sms-quest-mobile-update-modal.service';

/* Interfaces */
import { IForm, SmsQuestMobileUpdate } from '@app/shared/interfaces/sms-quest-mobile-update.interface';
import { DeviceType } from '@app/shared/constants/global-enum';


@Component({
	selector: 'sms-quest-mobile-update-modal',
	templateUrl: './sms-quest-mobile-update-modal.component.html',
	styleUrls: ['./sms-quest-mobile-update-modal.component.scss'],
})

export class SmsQuestMobileUpdateModalComponent implements OnInit, OnDestroy {
  /* Input/Ouput */
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();

  /* Properties */
  mobileType: FormControl = new FormControl<number>(1);
	modalForm: FormGroup;
	deviceType = DeviceType;
  private _$unsubscribe: Subject<void> = new Subject<void>();

  /* Constructor */
	constructor(
		private _fb: FormBuilder,
		private _smsQuestMobileUpdateModalService: SmsQuestMobileUpdateModalService,
		private _notifier: NotificationService
	) {}

  /* Methods */
	ngOnInit(): void {
		this._initModalForm();
	}

	closeModal() {
    this.onCloseModal.emit();
  }

	signUp() {
		const body: SmsQuestMobileUpdate = {
			phoneNumber: this.modalForm.controls['phoneNumber'].value ? `${1}${this.modalForm.controls['phoneNumber'].value}` : '',
			typeOfDevice: this.modalForm.controls['typeOfDevice'].value,
		};

		this._smsQuestMobileUpdateModalService
			.mobileOptInSignUp(body)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: () => {
					this._notifier.notify('Text Updates Confirmed', { duration: 8, panelClass: 'success' });
        	this.closeModal();
				}
			});
	}

	skip() {
		this._smsQuestMobileUpdateModalService
			.mobileOptInSkip()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe((res) => this.closeModal());
	}

	remindMe() {
		this._smsQuestMobileUpdateModalService
			.mobileOptInRemindMe()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe((res) => this.closeModal());
	}

	returnAsNumber(event: any) {
		return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}



	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}

  private _initModalForm() {
		const form: IForm<SmsQuestMobileUpdate> = {
			phoneNumber: [''],
			typeOfDevice: [DeviceType.android],
		};

		this.modalForm = this._fb.group(form);
	}
}
