<section class="client-tab">
  <div class="tab-title">
      <h1>Quest Pod</h1>
  </div>

  <ul class="tabs" data-id="tabs-1">
      <li class="tab" [ngClass]="{'tab--active': (view==='verify')}" (click)="switchTab('verify')"> <!--tab--active-->
          Verify Client Pin
      </li>
      <li class="tab" (click)="switchTab('sendBy')" [ngClass]="{'tab--active': (view==='sendBy')}">
          Resend Client Pin
      </li>
  </ul>

  <div class="tabs-content" data-id="tabs-1">
    <ng-container *ngIf="view == 'verify'">
      <app-verify-pin 
        (sendClientPin)="verifyPin($event)" 
        [verificationStatus$]="isVerificationSuccess$"
      ></app-verify-pin>
    </ng-container>
    <ng-container *ngIf="view == 'sendBy'">
      <app-resend-pin></app-resend-pin>
    </ng-container>
  </div>
</section>

<!-- <div class="loading-shade" *ngIf="spinnerService.spinner | async">
  <mat-spinner></mat-spinner>
</div> -->
