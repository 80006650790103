<div class="ai-summary-instruction q-d-flex q-f-column q-ai-center q-g-24">
  <div class="ai-summary-instruction_top-details q-d-flex q-f-column q-ai-start q-g-24 q-as-stretch">
    <div class="ai-summary-instruction_header q-d-flex q-ai-center q-g-16 q-f-1-0-0 q-as-stretch">
      <mat-icon svgIcon="stars-01" style="width: 40px; height: 40px;"></mat-icon>
      
      <h1>Our approach to AI</h1>

      <a href="javascript:void(0)" (click)="closeModal()">
        <app-figma-icon-img
          class="q-pointer"
          [iconName]="'x-close'"
          [hasSpaceOnRight]="false">
        </app-figma-icon-img>
      </a>
    </div>

    <div class="border-line-gray"></div>

    <div class="ai-summary-instruction_group q-d-flex q-f-column q-ai-start q-g-8 q-as-stretch">
      <div class="ai-summary-instruction_title">
        <h3>Secured Privacy</h3>
      </div>

      <div class="ai-summary-instruction_description font-b1">
        Sourcepass only uses this information within the context of the workplace. We don’t share your private information to any outside third-parties for other AI classification. Take a look at our <a href="https://www.sourcepass.com/legal/privacy-policy" class="blue-text" target="_blank">Privacy Policy</a>. Your data is secure at Sourcepass and we take that seriously given our SOC 2 Compliance certification - a trusted auditing procedure that evaluates security, availability, processing integrity, confidentiality and privacy of data management. 
      </div>
    </div>

    <div class="ai-summary-instruction_group q-d-flex q-f-column q-ai-start q-g-8 q-as-stretch">
      <div class="ai-summary-instruction_title">
        <h3>Always Optional</h3>
      </div>

      <div class="ai-summary-instruction_description font-b1">
        The focus of implementing AI tools will always be to optimize your experience within Quest when you decide to use it. The information being utilized is only what is provided on your service & support requests for the purpose of summarizing the issue more efficiently, so you can get back to the work that matters. It’s entirely optional, never required. 
      </div>
    </div>

    <div class="ai-summary-instruction_group q-d-flex q-f-column q-ai-start q-g-8 q-as-stretch">
      <div class="ai-summary-instruction_title">
        <h3>Future Plans</h3>
      </div>

      <div class="ai-summary-instruction_description font-b1">
        Our vision of building a Large Language Modal is to propel IT solutions into the future. We want a best-in-class AI ecosystem, therefore we plan to scale this experience seamlessly with new features down the road. Stay tuned! 
      </div>
    </div>
  </div>
</div>