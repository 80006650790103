import { LocalFigmaIconImgModule } from '@app/shared/local-figma-icon-img/local-figma-icon-img.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CheckboxInputComponent } from './checkbox-input.component';

@NgModule({
	declarations: [CheckboxInputComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
    LocalFigmaIconImgModule
	],
	exports: [CheckboxInputComponent],
})
export class CheckboxInputModule {}
