import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "@app/core/services/user.service";
import { AccountService } from "@app/modules/account/account.service";
import { BehaviorSubject, forkJoin, map, Observable, of } from "rxjs";
import { User } from "../interfaces/user.interface";
import { CurrentLevel, OptionsAddEditModeModel, OptionsInterface, OptionsModel } from "../functions/options";
import { UserTypes } from '../constants';


@Injectable()
export class GlobalPermissionsService{

  private optionsSource = new BehaviorSubject<OptionsModel | OptionsAddEditModeModel | null>(null);
  options$ = this.optionsSource.asObservable();
  ObsArr: [Observable<any>,keyof OptionsModel][] = [];


  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _accountService: AccountService,
    private _sessionStorage: SessionStorageService
    ) { }









  public setData(data: OptionsInterface, type: 'list' | 'add/edit'): void {
    let options;
    if(type =='list'){
       options = new OptionsModel();
    }else if(type === 'add/edit'){
       options = new OptionsAddEditModeModel();
    }else{
      options = new OptionsModel();
    }

    // set sync properties
    options.setMainOptions(data);
    // set async properties
    // navigation end
    // get params
    this.setAsyncProperties(options);
    this.setObs(options);
    // get routing
    //end

  }

  // companyID from user or get dynamically from user

  private setAsyncProperties(options: OptionsModel | OptionsAddEditModeModel){
    let userDataParam: number = 0;
    let getUserData$: Observable<number>;
    if(options.roleId === UserTypes.SourcepassAdmin){
      if(options.levelId === CurrentLevel.GLOBAL){
        options.companyId = 0;
        // companyId
      }else
      if(options.levelId === CurrentLevel.COMPANY){
        options.companyId = Number(this._route.snapshot.paramMap.get('companyId'))
        // companyId
      }else
      if(options.levelId === CurrentLevel.CONTACT){
        // options.companyId = Number(this._route.snapshot.paramMap.get('companyId'))
        options.userId = Number(atob(this._route.snapshot.params['userId']));
        userDataParam = options.userId;
        getUserData$ = this._accountService.getUserData(userDataParam).pipe(map((data: User)=>{return data.companyId}));
        // to get
        // getUserData$.subscribe(console.log)
        this.ObsArr.push([getUserData$,'companyId']);
        // companyId and userId
      }else
      if (options.levelId === CurrentLevel.GROUP) {
        options.groupId = Number(this._route.snapshot.params['groupId']);
        options.companyId = Number(this._sessionStorage.getStorage('companyId'));
      }
    }else
    if(options.roleId === UserTypes.ClientAdmin){
      if(options.levelId === CurrentLevel.COMPANY){
        options.companyId = this._userService.user!.companyId;
        // companyId
      }else
      if(options.levelId === CurrentLevel.CONTACT){
         // options.companyId = Number(this._route.snapshot.paramMap.get('companyId'))
         options.userId = Number(atob(this._route.snapshot.params['userId']));
         userDataParam = options.userId;
         getUserData$ = this._accountService.getUserData(userDataParam).pipe(map((data: User)=>{return data.companyId}));
         // to get
         // getUserData$.subscribe(console.log)
         this.ObsArr.push([getUserData$,'companyId']);
         // companyId and userId
      }else
      if (options.levelId === CurrentLevel.GROUP) {
        options.groupId = Number(this._route.snapshot.params['groupId']);
        options.companyId = Number(this._sessionStorage.getStorage('companyId'));
      }
    }else
    if(options.roleId === UserTypes.User){
      if(options.levelId === CurrentLevel.CONTACT){
        options.companyId = this._userService.user!.companyId;
        options.userId = this._userService.user!.id;
      }
    }
  }

  private setObs(options: OptionsModel | OptionsAddEditModeModel){
    let obsArray: Observable<any>[] = [];
    if(this.ObsArr){
      obsArray = this.ObsArr.map((obs)=>obs[0])
    }else{
      obsArray.push(of(true))
    }

    if(obsArray.length && obsArray.length > 0){
      forkJoin(obsArray).subscribe((response)=>{
        response.forEach((result,index)=>{
          //@ts-ignore
          options[this.ObsArr[index][1]]= result;
        })
        this.optionsSource.next(options)
      })
    }else{
      this.optionsSource.next(options)
    }


  }
}
