<div class="card">
	<div class="card-header pb-0">
		<div class="d-lg-flex">
			<div class="invoice-width">
				<h6 class="mb-0">Cash Balance Details</h6>
			</div>
			<div class="ms-auto my-auto mt-lg-0 mt-4 invoice-details-page">
				<!-- <div class="ms-auto my-auto">
					<a
						class="btn-2 btn-2-secondary export mb-0 mt-sm-0 mt-1"
						[routerLink]="/invoices"
					>
						Back
					</a>
				</div> -->
			</div>
		</div>
	</div>

	<div class="card-body p-3">
		<div class="row">
			<div class="col">
				<div class="cash-balance-title d-flex align-items-center">
					<app-figma-icon-img
						[iconName]="'bank'"
						[isForButton]="true"
					></app-figma-icon-img>
					<h6 class="mb-0 d-inline-block">Cash Balance</h6>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<h6>
					$0.00 Cash Balance for
					<span class="text-primary">TEST Customer 2</span>
				</h6>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col">
				<h6>Bank Transfer Funding Instructions</h6>
				<p>
					To accept a bank transfer payment from this customer, you can ask them
					to send money using these account details.
				</p>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col-md-6">
				<div class="card card-body border card-plain border-radius-lg">
					<div class="cash-balance-title d-flex align-items-center">
						<app-figma-icon-img
							[iconName]="'bank'"
							[isForButton]="true"
						></app-figma-icon-img>
						<h6 class="mb-0 d-inline-block">US ACH, DOMESTIC WIRE</h6>
					</div>

					<div class="mt-4">
						<div class="row mb-2">
							<div class="col-md-6">Bank</div>
							<div class="col-md-6 d-flex align-items-center">
								<span>US Test Bank</span>&nbsp;&nbsp;
								<app-figma-icon-img
									[iconName]="'file-04'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
									[hasSpaceOnLeft]="true"
								></app-figma-icon-img>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-md-6">Account Number</div>
							<div class="col-md-6 d-flex align-items-center">
								<span>1119935749470172</span>&nbsp;&nbsp;
								<app-figma-icon-img
									[iconName]="'file-04'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
									[hasSpaceOnLeft]="true"
								></app-figma-icon-img>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-md-6">Routing Number</div>
							<div class="col-md-6 d-flex align-items-center">
								<span>999999999</span>&nbsp;&nbsp;
								<app-figma-icon-img
									[iconName]="'file-04'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
									[hasSpaceOnLeft]="true"
								></app-figma-icon-img>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-6">
				<div class="card card-body border card-plain border-radius-lg">
					<div class="cash-balance-title d-flex align-items-center">
						<app-figma-icon-img
							[iconName]="'bank'"
							[isForButton]="true"
						></app-figma-icon-img>
						<h6 class="mb-0 d-inline-block">SWIFT</h6>
					</div>

					<div class="mt-4">
						<div class="row mb-2">
							<div class="col-md-6">Bank</div>
							<div class="col-md-6 d-flex align-items-center">
								<span>US Test Bank</span>&nbsp;&nbsp;
								<app-figma-icon-img
									[iconName]="'file-04'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
									[hasSpaceOnLeft]="true"
								></app-figma-icon-img>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-md-6">Account Number</div>
							<div class="col-md-6 d-flex align-items-center">
								<span>1119935749470172</span>&nbsp;&nbsp;
								<app-figma-icon-img
									[iconName]="'file-04'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
									[hasSpaceOnLeft]="true"
								></app-figma-icon-img>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-md-6">SWIFT Code</div>
							<div class="col-md-6 d-flex align-items-center">
								<span>TESTUS99</span>&nbsp;&nbsp;
								<app-figma-icon-img
									[iconName]="'file-04'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
									[hasSpaceOnLeft]="true"
								></app-figma-icon-img>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
