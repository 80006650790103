/* Angular Libraries */ 
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/* Third Party Libraries */ 
import { BehaviorSubject, Observable, of } from 'rxjs';

/* Services */
import { UserService } from '@app/core/services/user.service';

/* Interfaces */
import { MobileLaunch, MobileUpdateAction, MobileUpdateData } from '@app/shared/interfaces/sms-quest-mobile-update.interface';
import { DeviceType } from '@app/shared/constants/global-enum';

/* Environment */
import { environment } from 'environments/environment'; 

type AllowedValues = DeviceType.ios | DeviceType.android | DeviceType.all | null;

@Injectable({
  providedIn: 'root'
})
export class MobileLaunchModalService {
  isShowMobileDownloadScreen$: Observable<boolean> = of(false);
  hasMobileRegistered: boolean = false;
  id: number;
  typeOfDevice?: any = null;

  private _isModalDisplayed = new BehaviorSubject<boolean>(false);
  isMobileModalDisplayed$ = this._isModalDisplayed.asObservable();

  constructor(
    private _http: HttpClient,
    private _userService: UserService
  ) { }

  getNewMobileReleases(): Observable<MobileUpdateData> {
		return this._http.get<MobileUpdateData>(`${environment.apiBaseUrl}MobileNewsReleases`);
	}

  postNewMobileRelease(body: MobileUpdateAction) {
    return this._http.post(`${environment.apiBaseUrl}MobileNewsReleases`, body);
  }

  initNewMobileLaunch() {
    if (this._userService.isUser) {
      this.getNewMobileReleases()
      .subscribe({
        next: (result) => {
          if (result && result.typeOfDevice) {
            this.typeOfDevice = result.typeOfDevice;
            this.hasMobileRegistered = this.typeOfDevice === DeviceType.ios || this.typeOfDevice === DeviceType.android || this.typeOfDevice === DeviceType.all;
            this.id = result.id;
            
          } else {
            this.hasMobileRegistered = false;
          }
          const show = result !== null && this.hasMobileRegistered;
          this._isModalDisplayed.next(show)
          this.isShowMobileDownloadScreen$ = of(show);
        }
      });
    }
  }

  mobileOptInSignUp(body: MobileLaunch) {
		return this._http.post(`${environment.apiBaseUrl}MobileOptIn`, body);
	}

  modalClosed() {
    this._isModalDisplayed.next(false);
  }
}
