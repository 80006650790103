import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'quest-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
	standalone: true,
})
export class LoadingComponent implements OnInit {
	@Input() height: number = 300;
	constructor() {}

	ngOnInit(): void {}
}
