import { IKnowledgeBase } from '../kb-topic.interface';
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";




export const companyKbTopicsSelector = (state: AppStateInterface): IKnowledgeBase => state.companyKbTopics;

export const pageSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.page);

export const pageSizeSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.pageSize);

export const orderSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.order);

export const columnSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.column);

export const querySelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.query);

export const categoryIdSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.categoryId);

export const dateStartSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.dateStart);

export const dateEndSelector =
createSelector(companyKbTopicsSelector, (state: IKnowledgeBase)=>state.dateEnd);
