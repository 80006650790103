import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { CompanyActivityGraphService } from './company-activity-graph.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CompanyReportUsage } from '../../core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store } from '@ngrx/store';

@Component({
  selector: 'company-activity-graph',
  templateUrl: './company-activity-graph.component.html',
  styleUrls: ['./company-activity-graph.component.scss'],
})
export class CompanyActivityGraphComponent implements OnInit {
  // injection
  public companyActivity = inject(CompanyActivityGraphService)
  private _router = inject(Router)
  private _route = inject(ActivatedRoute);
  private _cd = inject(ChangeDetectorRef);

  // Input / Output
  @Input() type: 'top' | 'bottom' = 'top';
  @Input() usage: 'company' | 'daily' = 'company'
  // props
  dataSource$: Observable<CompanyReportUsage[]>
  graphType: 'bar' | 'spline' = 'bar';
  // lifecycle
  ngOnInit() {
    if(this.usage === 'company'){
      if(this.type === 'top'){
        this.dataSource$ = this.companyActivity.companyReportTop5$;
      }else{
        this.dataSource$ = this.companyActivity.companyReportLeast5$;
      }
    }else{
      this.dataSource$ = this.companyActivity.dailyActivity$
      this.graphType = 'spline';
    }
  }

  customizeTooltip = (info: any) => {
    if(info.points.length > 1){
      return ({
        html: `
        <div class="tooltip-container">
          <div class='tooltip-header'>${info.argumentText}</div>
          <div class='tooltip-body'>
            <div class='series'>
              <span class='top-series-name'>${info.points[0].seriesName}:</span>
              <span class='top-series-value'>${info.points[0].valueText}</span>
            </div>
            <div class='series'>
              <span class='bottom-series-name'>${info.points[1].seriesName}:</span>
              <span class='bottom-series-value'>${info.points[1].valueText}</span>
            </div>
          </div>
        </div>`
      })
    }

    return {html:''}
  };

  onChartClick(e:any){
    const {companyId} = e.target.data;
    let {fromDate, toDate}  = e.target.data;
    fromDate = (fromDate as string).split('T')[0]
    toDate = (toDate as string).split('T')[0]
    this._router.navigate([companyId], { 
      relativeTo: this._route,
      queryParams: {fromDate,toDate}
    });
  }

  onPointHoverChanged(e: any) {
    if(e.target.isHovered()) {
        e.element.style.cursor = "pointer"
    } else {
        e.element.style.cursor = "auto"
    }
  }

}
