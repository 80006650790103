import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsoleLogsService } from '@app/shared/services/console-logs.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  // injection
  private _consoleLog = inject(ConsoleLogsService);
  private _router = inject(Router);

  constructor(/* private logger: LoggerService */) {
    super();
  }

  override handleError(error:any) {
    let errorMessage = '';
    if('stack' in error) errorMessage = error.stack;
    else if('message' in error) errorMessage = error.message;
    else errorMessage = error

    const errorToSend = this._consoleLog.composeErrorLog(errorMessage)
    this._consoleLog.sendError(errorToSend)?.subscribe()
    super.handleError(error);
  }
}
