import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { BootstrapTooltipModule } from '@app/shared/directives/bootstrap/bootstrap-tooltip.module';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FigmaIconImgModule } from './../../figma-icon-img/figma-icon-img.module';

import { QuickFilterComponent } from './quick-filter.component';

@NgModule({
	declarations: [QuickFilterComponent],
	imports: [
		BootstrapTooltipModule,
		CommonModule,
		DragScrollModule,
		FigmaIconImgModule,
		FormsModule,
		ReactiveFormsModule,
	],
	exports: [QuickFilterComponent],
})
export class QuickFilterModule {}
