<input 
  #inputSelect
  type="text" 
  spellcheck="false"
  [class]="inputClass"
  [placeholder]="placeholder"
  [readonly]="readonly"

  [matAutocomplete]="auto"
  [formControl]="control"

  (click)="clear()"
  (blur)="onTouched()"
>

<mat-autocomplete 
  class="virtual-scroll"
  #auto="matAutocomplete"
  (optionSelected)="setValue($event.option.value)"
  [displayWith]="displayFn"
>
  <ng-container *ngFor="let option of options$ | async; let i=index">
    <mat-option [value]="''" *ngIf="hasClear && i === 0">
      Clear Select
    </mat-option>
    
    <mat-option 
      [value]="option"
    >
      {{option.label}}
    </mat-option>
  </ng-container>
</mat-autocomplete>