import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import {
	BehaviorSubject,
	finalize,
	Observable,
	ReplaySubject,
	tap,
} from 'rxjs';

import { toFormData } from 'app/shared/utilities/helper';

import { environment } from 'environments/environment';

import { HttpRoutes, ApplicationType, UserTypes } from '@app/shared/constants';

import {
	Application,
	ApplicationCategory,
	IApplicationCategory,
} from '@interfaces/application.interface';
import { IApplication } from './pages/application-list/store/sp-admin/application.interface';
import {
	IApplicationCatalogDropdownResponse,
	IDataList,
	IItems,
} from './pages/application-catalog/store/application-catalog.interface';
import { GenericApiResponse } from '@app/shared/interfaces/generic-api-response';
import { TableOptions } from '@interfaces/table.interface';

@Injectable({
	providedIn: 'root',
})
export class ApplicationsService {
	searchQuery = '';
	searchCategoryText: string = '';
	searchApplicationText: string = '';
	customApplicationText: string = '';
	searchApplicationDropdown = 0;
	tab = 0;
	contactsTab = 0;
	application_isCustom: boolean | undefined;

	globalCategoryFilters: IApplicationCategory;
	companyCategoryFilters: IApplicationCategory;
	userCategoryFilters: IApplicationCategory;

	private _globalCategoryData = new ReplaySubject<any[]>(1);
	globalCategoryData$ = this._globalCategoryData.asObservable();

	private _globalCategoryTotalItems = new ReplaySubject<number>(1);
	globalCategoryTotalItems$ = this._globalCategoryTotalItems.asObservable();

	private _companyCategoryData = new ReplaySubject<any[]>(1);
	companyCategoryData$ = this._companyCategoryData.asObservable();

	private _companyCategoryTotalItems = new ReplaySubject<number>(1);
	companyCategoryTotalItems$ = this._companyCategoryTotalItems.asObservable();

	private _userCategoryData = new ReplaySubject<any[]>(1);
	userCategoryData$ = this._userCategoryData.asObservable();

	private _userCategoryTotalItems = new ReplaySubject<number>(1);
	userCategoryTotalItems$ = this._userCategoryTotalItems.asObservable();

	globalApplicationFilters: IApplication;
	companyGlobalApplicationFilters: IApplication | undefined;
	companyApplicationFilters: IApplication | undefined;
	userApplicationFilters: IApplication;

	private _globalApplicationData = new ReplaySubject<any[]>(1);
	globalApplicationData$ = this._globalApplicationData.asObservable();

	private _globalApplicationTotalItems = new ReplaySubject<number>(1);
	globalApplicationTotalItems$ =
		this._globalApplicationTotalItems.asObservable();

	private _companyGlobalApplicationData = new ReplaySubject<any[]>(1);
	companyGlobalApplicationData$ =
		this._companyGlobalApplicationData.asObservable();

	private _companyGlobalApplicationTotalItems = new ReplaySubject<number>(1);
	companyGlobalApplicationTotalItems$ =
		this._companyGlobalApplicationTotalItems.asObservable();

	private _companyApplicationData = new ReplaySubject<any[]>(1);
	companyApplicationData$ = this._companyApplicationData.asObservable();

	private _companyApplicationTotalItems = new ReplaySubject<number>(1);
	companyApplicationTotalItems$ =
		this._companyApplicationTotalItems.asObservable();

	private _userApplicationData = new ReplaySubject<any[]>(1);
	userApplicationData$ = this._userApplicationData.asObservable();

	private _userApplicationTotalItems = new ReplaySubject<number>(1);
	userApplicationTotalItems$ = this._userApplicationTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	private _isCompanyLoading = new BehaviorSubject(false);
	isCompanyLoading$ = this._isCompanyLoading.asObservable();

	allowToFetchNewData = false;

	constructor(private _http: HttpClient) {}

	set isCompanyLoading(isLoading: boolean) {
		this._isCompanyLoading.next(isLoading);
	}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	clearSearch() {
		this.searchCategoryText = '';
		this.searchApplicationText = '';
		this.customApplicationText = '';
	}

	setSearchText(searchText: string) {
		this.searchApplicationText = searchText;
	}

	exportApplicationCatalog(
		params: Params,
		exportType: ApplicationType
	): Observable<Object> {
		params['exportType'] = exportType;

		if (params['categoryId']) {
			params['categoryId'] = params['categoryId'].join(',');
		}

		return this._http.get(
			`${environment.apiBaseUrl}ApplicationCatalog/Export`,
			{
				params,
				responseType: 'text',
			}
		);
	}

	getApplicationCatalog(params: Params): Observable<IDataList> {
		if (params['categoryId']) {
			params['categoryId'] = params['categoryId'].join(',');
		}

		return this._http.get<IDataList>(
			`${environment.apiBaseUrl}ApplicationCatalog`,
			{ params }
		);
	}

	getApplicationCatalogDropdown(
		search: string
	): Observable<IApplicationCatalogDropdownResponse> {
		return this._http.get<IApplicationCatalogDropdownResponse>(
			`${environment.apiBaseUrl}ApplicationCatalog/DropDown?search=${search}`
		);
	}

	getApplicationCatalogDetails(id: number): Observable<IItems> {
		return this._http.get<IItems>(
			`${environment.apiBaseUrl}ApplicationCatalog/${id}`
		);
	}

	addApplicationFromCatalog(payload: Params): Observable<Object> {
		return this._http.post<Object>(
			`${environment.apiBaseUrl}Application/AddApplicationFromCatalog`,
			payload
		);
	}

	addApplicationCatalog(payload: IItems): Observable<GenericApiResponse> {
		const body = toFormData(payload);
		return this._http.post<GenericApiResponse>(
			`${environment.apiBaseUrl}ApplicationCatalog`,
			body
		);
	}

	deleteApplicationCatalog(id: number): Observable<GenericApiResponse> {
		return this._http.delete<GenericApiResponse>(
			`${environment.apiBaseUrl}ApplicationCatalog/${id}`
		);
	}

	updateApplicationCatalog(
		id: number,
		payload: IItems
	): Observable<GenericApiResponse> {
		const body = toFormData(payload);
		return this._http.put<GenericApiResponse>(
			`${environment.apiBaseUrl}ApplicationCatalog/${id}`,
			body
		);
	}

	getCategoyOption() {
		return this._http.get(
			`${environment.apiBaseUrl}Application/UserCategoryDropdownList`
		);
	}

	getUserApplication(search: string, categoryId: string) {
		return this._http.get(
			`${environment.apiBaseUrl}Application/UserApplicationList?search=${search}&categoryId=${categoryId}`
		);
	}

	getQuickAccess(userId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Application/UserQuickAccessList?userId=${userId}`
		);
	}

	saveQuickAccess(applicationId: number) {
		return this._http.post(
			`${environment.apiBaseUrl}Application/AddQuickAccess/${applicationId}`,
			{}
		);
	}

	deleteQuickAccess(applicationId: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}Application/DeleteQuickAccess/${applicationId}`
		);
	}

	getApplicationList(
		options: IApplication,
		application_isCustom?: boolean,
		groupId?: number | null
	) {
		let hasCategoryId = '';

		if (options.categoryId !== undefined) {
			hasCategoryId = `&categoryId=${options.categoryId.join(',')}`;
		}

		const pageSize =
			options.pageSize !== undefined ? `&PageSize=${options.pageSize}` : '';
		const searchText =
			options.query !== undefined ? `&searchText=${options.query}` : '';
		const companyId =
			options.companyId !== null && options.companyId !== undefined
				? `&companyId=${options.companyId}`
				: '';
		const userId =
			options.userId !== null && options.userId !== undefined
				? `&userId=${options.userId}`
				: '';
		const column =
			options.column !== undefined ? `&column=${options.column}` : '';
		const page = options.page !== undefined ? `Page=${options.page}` : '';
		const isCustom =
			options.isCustom !== undefined ? `&isCustom=${options.isCustom}` : '';
		const group = groupId ? `&groupId=${groupId}` : '';

		this.saveCurrentFilter(options, application_isCustom);
		this.clearApplicationData(options, application_isCustom);

		return this._http
			.get<{ data: Application[]; totalCount: number }>(
				`${environment.apiBaseUrl}${
					HttpRoutes.Application
				}?${page}${pageSize}${searchText}${companyId}${column}${userId}&Order=${
					options.order ?? ''
				}${hasCategoryId}${isCustom}${group}`
			)
			.pipe(
				tap((res) => {
					this.setApplicationData(options, application_isCustom, res);
				}),
				finalize(() => {
					if (
						(options.userRole === UserTypes.SourcepassAdmin ||
							options.userRole === UserTypes.ClientAdmin) &&
						options.companyId &&
						application_isCustom
					) {
						this.isCompanyLoading = false;
					} else {
						this.isLoading = false;
					}
				})
			);
	}

	setApplicationData(
		options: IApplication,
		application_isCustom: boolean | undefined,
		res: { data: Application[]; totalCount: number }
	) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				if (application_isCustom) {
					this._companyApplicationData.next(res.data);
					this._companyApplicationTotalItems.next(res.totalCount);
				} else {
					this._companyGlobalApplicationData.next(res.data);
					this._companyGlobalApplicationTotalItems.next(res.totalCount);
				}
			} else {
				this._globalApplicationData.next(res.data);
				this._globalApplicationTotalItems.next(res.totalCount);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			if (application_isCustom) {
				this._companyApplicationData.next(res.data);
				this._companyApplicationTotalItems.next(res.totalCount);
			} else {
				this._companyGlobalApplicationData.next(res.data);
				this._companyGlobalApplicationTotalItems.next(res.totalCount);
			}
		} else if (options.userRole === UserTypes.User) {
			this._userApplicationData.next(res.data);
			this._userApplicationTotalItems.next(res.totalCount);
		}
	}

	saveCurrentFilter(options: IApplication, application_isCustom?: boolean) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				if (application_isCustom) {
					this.companyApplicationFilters = options;
				} else {
					this.companyGlobalApplicationFilters = options;
				}
			} else {
				this.globalApplicationFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			if (application_isCustom) {
				this.companyApplicationFilters = options;
			} else {
				this.companyGlobalApplicationFilters = options;
			}
		} else if (options.userRole === UserTypes.User) {
			this.userApplicationFilters = options;
		}
	}

	clearApplicationFilters(
		isOnCompanyPage: boolean,
		application_isCustom: boolean
	) {
		if (isOnCompanyPage) {
			if (application_isCustom) {
				this.companyApplicationFilters = undefined;
			} else {
				this.companyGlobalApplicationFilters = undefined;
			}
		}
	}

	clearApplicationData(options: IApplication, application_isCustom?: boolean) {
		if (
			(options.userRole === UserTypes.SourcepassAdmin ||
				options.userRole === UserTypes.ClientAdmin) &&
			options.companyId &&
			application_isCustom
		) {
			this.isCompanyLoading = true;
		} else {
			this.isLoading = true;
		}

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				if (application_isCustom) {
					this._companyApplicationData.next([]);
					this._companyApplicationTotalItems.next(0);
				} else {
					this._companyGlobalApplicationData.next([]);
					this._companyGlobalApplicationTotalItems.next(0);
				}
			} else {
				this._globalApplicationData.next([]);
				this._globalApplicationTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			if (application_isCustom) {
				this._companyApplicationData.next([]);
				this._companyApplicationTotalItems.next(0);
			} else {
				this._companyGlobalApplicationData.next([]);
				this._companyGlobalApplicationTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.User) {
			this._userApplicationData.next([]);
			this._userApplicationTotalItems.next(0);
		}
	}

	exportApplication(
		options: TableOptions,
		groupId?: number | null,
		exportType?: number
	) {
		const isVisible =
			options.isVisible !== undefined ? `&isVisible=${options.isVisible}` : '';
		const hasCategoryId =
			options.categoryId !== undefined
				? `&categoryId=${options.categoryId}`
				: '';
		const pageSize =
			options.pageSize !== undefined ? `&PageSize=${options.pageSize}` : '';
		const searchText =
			options.query !== undefined ? `&searchText=${options.query}` : '';
		const userRole =
			options.userRole !== undefined ? `&userRole=${options.userRole}` : '';
		const companyId =
			options.companyId !== null && options.companyId !== undefined
				? `&companyId=${options.companyId}`
				: '';
		const userId =
			options.userId !== null && options.userId !== undefined
				? `&userId=${options.userId}`
				: '';
		const column =
			options.column !== undefined ? `&column=${options.column}` : '';
		const page = options.page !== undefined ? `Page=${options.page}` : '';
		const isCustom =
			options.isCustom !== undefined ? `&isCustom=${options.isCustom}` : '';
		const group = groupId !== null ? `&groupId=${groupId}` : '';

		return this._http.get(
			`${
				environment.apiBaseUrl
			}Application/Export?${page}${pageSize}${searchText}${userRole}${companyId}${column}${userId}&Order=${
				options.sort ?? ''
			}${isVisible}${hasCategoryId}${isCustom}${group}&exportType=${exportType}`,
			{ responseType: 'text' }
		);
	}

	getCategoryDetails(id: number): Observable<ApplicationCategory> {
		return this._http.get<ApplicationCategory>(
			`${environment.apiBaseUrl}ApplicationCategory/${id}`
		);
	}

	addApplication(options: any, app: any) {
		const newBody = this._createFormData(app);
		return this._http.post(
			`${environment.apiBaseUrl}${HttpRoutes.Application}?roleId=${options.userRole}`,
			newBody
		);
	}

	getApplicationDetails(id: number): Observable<Application> {
		return this._http.get<Application>(
			`${environment.apiBaseUrl}Application/${id}`
		);
	}

	updateApplication(options: any, app: Application) {
		const body = toFormData(app);
		const companyId = options.companyId
			? '&companyId=' + options.companyId
			: '';
		const userId = options.userId ? '&userId=' + options.userId : '';

		return this._http.put(
			`${environment.apiBaseUrl}${HttpRoutes.Application}/${options.id}?roleId=${options.userRole}${companyId}${userId}`,
			body
		);
	}

	deleteApplication(id: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}${HttpRoutes.Application}/${id}`
		);
	}

	getApplicationDropdownList() {
		return this._http.get<ApplicationCategory[]>(
			`${environment.apiBaseUrl}${HttpRoutes.Application}/Dropdownlist?searchText=`
		);
	}

	addCategory(body: any) {
		body.name = body.name.trim();
		return this._http.post(
			`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}`,
			body
		);
	}

	updateCategory(body: any) {
		body.name = body.name.trim();
		return this._http.put(
			`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}/${body.id}`,
			body
		);
	}

	deleteCategory(id: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}/${id}`
		);
	}

	getCategoryList(
		options: IApplicationCategory
	): Observable<ApplicationCategory[]> {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this.companyCategoryFilters = options;
			} else {
				this.globalCategoryFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this.companyCategoryFilters = options;
		} else if (options.userRole === UserTypes.User) {
			this.userCategoryFilters = options;
		}

		this.clearApplicationCategory(options);

		return this._http
			.get<ApplicationCategory[]>(
				`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}?Page=${
					options.page
				}&PageSize=${options.pageSize}&Order=${options.order}&searchText=${
					options.query ?? ''
				}&column=${options.column}${
					options.companyId ? '&companyId=' + options.companyId : ''
				}`
			)
			.pipe(
				tap((res: any) => {
					if (options.userRole === UserTypes.SourcepassAdmin) {
						if (options.companyId) {
							this._companyCategoryData.next(res.data);
							this._companyCategoryTotalItems.next(res.totalCount);
						} else {
							this._globalCategoryData.next(res.data);
							this._globalCategoryTotalItems.next(res.totalCount);
						}
					} else if (options.userRole === UserTypes.ClientAdmin) {
						this._companyCategoryData.next(res.data);
						this._companyCategoryTotalItems.next(res.totalCount);
					} else if (options.userRole === UserTypes.User) {
						this._userCategoryData.next(res.data);
						this._userCategoryTotalItems.next(res.totalCount);
					}
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	clearApplicationCategory(options: IApplicationCategory) {
		this.isLoading = true;

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this._companyCategoryData.next([]);
				this._companyCategoryTotalItems.next(0);
			} else {
				this._globalCategoryData.next([]);
				this._globalCategoryTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companyCategoryData.next([]);
			this._companyCategoryTotalItems.next(0);
		} else if (options.userRole === UserTypes.User) {
			this._userCategoryData.next([]);
			this._userCategoryTotalItems.next(0);
		}
	}

	getCategoryDropdownList(companyId: any = 0, userId: any = 0) {
		return this._http.get(
			`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}/Dropdownlist?companyId=${companyId}&userId=${userId}`
		);
	}

	getGroupAppCategoryList(groupId: number) {
		return this._http.get<ApplicationCategory[]>(
			`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}/Group/${groupId}`
		);
	}

	getApplicationHistory(
		applicationId: number,
		pageNumber: number,
		pageSize: number
	) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}${HttpRoutes.Application}/history/${applicationId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}

	getApplicationCategoryHistory(
		applicationCategoryId: number,
		pageNumber: number,
		pageSize: number
	) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}${HttpRoutes.ApplicationCategory}/history/${applicationCategoryId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}

	private _createFormData(app: any) {
		const fd = new FormData();
		const file = new Blob([app.file], { type: 'image/png' });

		fd.append('file', file, app.file.name);
		fd.append('id', app.id);
		fd.append('applicationCategoryId', app.applicationCategoryId);
		fd.append('name', app.name);
		fd.append('url', app.url);
		fd.append('description', app.description);
		if (app.companyId) {
			fd.append('companyId', app.companyId);
		}

		if (app.userId) {
			fd.append('userId', app.userId);
		}

		return fd;
	}
}
