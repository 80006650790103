import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { DynamicTableSettings } from '@app/modules/dashboard/widget-dynamic-table/widget-dynamic-table.component';
import { TableMessages } from '@app/shared/constants';
import { AcronisBackupStatus, AcronisBackupStatusItems, TableDynamicColumns, WidgetDynamicTable } from '@app/shared/interfaces/dashboard.interface';
import { BehaviorSubject, filter, map, Observable, of, Subject, Subscription } from 'rxjs';
import { WidgetDynamicTable2 } from '../../../../../../shared/interfaces/dashboard.interface';

@Component({
  selector: 'app-acronis-backup-status',
  templateUrl: './acronis-backup-status.component.html',
  styleUrls: ['./acronis-backup-status.component.scss']
})
export class AcronisBackupStatusComponent implements OnInit, OnDestroy{

  //dataTable:WidgetDynamicTable;
  #acronis$: Observable<AcronisBackupStatus | null>;
/*   usageSettings:DynamicTableSettings = { // need to improve this settings
    ngStyle:{
      td:{'textAlign': 'left !important'},
      th:{'textAlign': 'left !important'},
      row: {},
    },
  } */
  #subscription = new Subscription();

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: ()=>{}
  noClick: ()=>{}
  seeMoreLink = '';

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dashboard:DashboardService,
    private _cd: ChangeDetectorRef,
  ){
    this._dashboard.initAcronisBackupStatus();
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  ngOnInit() {
    this.#initProperties();
  }

  #initProperties(){
    this.#acronis$ = this._dashboard.acronicBackupStatus$;
    this.#subscription.add(
      this.#acronis$
        .pipe(
          filter(v=>v!=null),
          map(v=>{
            let data:AcronisBackupStatusItems[] = [];
            v?.items.map(w=>{
              data.push({...w, ...{
                lastSuccessTime: this.#toDate(w.lastSuccessTime),
                lastRunTime: this.#toDate(w.lastRunTime),
                nextRunTime: this.#toDate(w.nextRunTime),
                status: w.status.toUpperCase(),
              }})
            });
            v!.items = data;
            return v;
          }),
        )
        .subscribe(v=>{
          this.#setData(v);
        })
    )
  }

  #setData(acronis:AcronisBackupStatus | null){
    //this.dataTable = this.#createAcronisBackupStatus(acronis!.items)
    this.#createAcronisBackupStatus(acronis!.items)
    //this.usageSettings = this.#getStatusSettings();
  }

  #createAcronisBackupStatus(acronis:AcronisBackupStatusItems[]){
    this.source = acronis
    this.columns = this.#getBackupStatusColumns()
    this.displayedColumns = this.#getStatusDisplayedColumns()
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
/*     return {
      data: acronis,
      columns: this.#getBackupStatusColumns(),
      rowClick: ()=>{},
      noClick: ()=>{},
      noRecordMessage: TableMessages.EmptyTable,
      seeMoreLink: null,
      displayedColumns: this.#getStatusDisplayedColumns()
    } */
  }


  #getBackupStatusColumns():any[]{
    return [{
        text: 'Device Name',
        matColumnDef: 'deviceName',
      },
      {
        text: 'Protection Status',
        matColumnDef:'status',
        class:{
          th: ['center-text'],
          td: ['center-text']
        },
      },
      {
        text: 'Last successful backup',
        matColumnDef:'lastSuccessTime',
      },
      {
        text: 'Next backup',
        matColumnDef:'nextRunTime',
        class:{
          th: ['center-text'],
          td: ['center-text']
        }
      },
    ]
  }

  #getStatusDisplayedColumns(){
    return ['deviceName', 'status', 'lastSuccessTime', 'nextRunTime'];
  }

  #getStatusSettings(){
    return {
      ngStyle:{
        td:{'textAlign': 'center'},
        row: {},
      },
      ngClass:{
        th: 'center-th',
      }
    }
  }

  #toDate = (toChange:Date | string)=> new Date(toChange).toLocaleString()

  getDataTable(): WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      seeMoreLink: this.seeMoreLink,
      displayedColumns: this.displayedColumns
    }
  }
}
