<app-modal
  [withHeader]="false"
  [show]="show"
  [withDefaultFooterBtn]="true"
  [cancelBtnText]="config.cancel"
  [saveBtnText]="config.accept"
  (close)="close.emit()"
  (save)="save.emit()"
>
  <ng-template #modalBody>
    <div class="e-container">
      <div class="icon-container">
        <i [style.color]="config.iconColor" [class]="config.iconName"></i>
      </div>
      <div class="message-container">
        <h3>{{config.header}}</h3>
        <p [innerHTML]="config.message"></p>
      </div>
    </div>
  </ng-template>
</app-modal>