import { Component, OnDestroy, OnInit } from '@angular/core';

import { UserService } from '@app/core/services/user.service';
import { NotificationsClienthubService } from '@app/shared/services/notifications-clienthub.service';
import { MaintenanceService } from '@app/core/services/maintenance.service';

import { Store } from '@ngrx/store';
import { logoutAction } from '@app/core/store/user/user.action';

@Component({
	selector: 'nav-dashboard-layout',
	templateUrl: './dashboard-layout.component.html',
	styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
	impersonating = false;

	constructor(
		public maintenance: MaintenanceService,
		private _userService: UserService,
		private _hub: NotificationsClienthubService,
		private _store: Store
	) {}

	ngOnInit(): void {
		this._hub.disconnect();
		this._hub.connect();

		this._userService.userChange$.subscribe({
			next: (res) => {
				this.impersonating = !!this._userService.user?.impersonatingBy;
			},
		});
	}

	ngOnDestroy(): void {
		this._hub.disconnect();
		this._store.dispatch(logoutAction());
	}
}
