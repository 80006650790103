import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable(
  {providedIn: 'root'}
)
export class ThemeService{
  private themeTypeSource = new BehaviorSubject<string>('light');
  themeType = this.themeTypeSource.asObservable();

  changeThemeType(theme: string){
    this.themeTypeSource.next(theme);
  }
}
