import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DataModalService, IDataModal } from '../data-modal/data-modal.service';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class InterceptorService {
	constructor(
		private dataModalService: DataModalService,
		private userService: UserService
	) {}

	exceptionHandler(error: HttpErrorResponse) {}
	hasBaseUrl(url: string) {
		return url.includes(environment.apiBaseUrl);
	}
	removeBase(url: string) {
		return url.replace(environment.apiBaseUrl, '').split('?')[0];
	}

	successInterfaceChecker<T>(obj: T): obj is T {
		return (
			obj &&
			typeof obj === 'object' &&
			'message' in obj &&
			'statusCode' in obj &&
			'title' in obj
		);
	}

	listInterfaceChecker<T>(obj: T): obj is T {
		return (
			obj &&
			typeof obj === 'object' &&
			'count' in obj &&
			'items' in obj &&
			'page' in obj &&
			'pageSize' in obj
		);
	}

	modifyResponse(response: HttpResponse<any>): HttpResponse<any> {
		return response.clone({
			body: response.body.data,
		});
	}

	mapList(response: HttpResponse<any>): HttpResponse<any> {
		return response.clone({
			body: {
				currentPage: response.body.page,
				data: response.body.items,
				pageSize: response.body.pageSize,
				totalCount: response.body.count,
			},
		});
	}

	errorMessageHandler(error: IDataModal) {
		const data: IDataModal = {
			...error,
			hasTicket: true,
			type: 'danger',
			title: 'Error',
		};
		this.dataModalService.showModal(data);
	}

	genericErrorHandler() {
		this.userService.logoutNoApi();
	}
}
