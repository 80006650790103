<!-- <p *ngFor="let activity of monthlyActivity.monthlyUsage$ | async">
  {{ activity.monthYear }} - Users: {{ activity.uniqueUsers}}; Activity: {{ activity.usersActivity }}
</p>
 -->
<dx-chart
  id="chart"
  [dataSource]="_monthlyActivity.monthlyUsage$ | async"
  palette="Harmony Light"
  (onPointClick)="onChartClick($event)"
  (onPointHoverChanged)="onPointHoverChanged($event)"
>
  <dxi-value-axis name="uniqueUser" position="left">
  </dxi-value-axis>
  <dxi-value-axis name="acvivities" position="right">
  </dxi-value-axis>

  <dxi-series
    type="bar"
    valueField="uniqueUsers"
    name="Unique Users"
    axis="uniqueUser"
    class="bars"
    color="#EFAD7B"
  ></dxi-series>
  <dxi-series
    type="spline"
    valueField="usersActivity"
    name="Activity"
    axis="acvivities"
    color="#A07CF1"
    class="bars"
  >
  </dxi-series>

  <dxo-common-series-settings
    argumentField="monthYear"
  >
  </dxo-common-series-settings>
  <dxo-tooltip
    [enabled]="true" [shared]="true"
    [customizeTooltip]="customizeTooltip"
  >
  </dxo-tooltip>
  <dxo-legend
    [margin]="10"
    horizontalAlignment="center"
    verticalAlignment="bottom"
  ></dxo-legend>
</dx-chart>
