import { ReportApiService } from '@app/modules/portal-usage/pages/dashboard-report/services/report-api.service';
import { CdkTableModule } from '@angular/cdk/table';
import { Component, Input, OnInit, inject } from '@angular/core';
import { IDataModal } from '../../data-modal.service';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingComponent } from '@app/modules/portal-usage/pages/dashboard-report/components/loading/loading.component';
import { NoDataComponent } from '@app/modules/portal-usage/pages/dashboard-report/components/no-data/no-data.component';
import { NgIf } from '@angular/common';

export interface ISatScoreTable {
	name: string;
	comment: string;
	rating: string;
}

const satScores: ISatScoreTable[] = [
	{
		name: 'John',
		comment:
			'Encountered technical issues while accessing service and support module',
		rating: 'Excellent',
	},
	{
		name: 'Alice',
		comment: 'Needed assistance with submitting tickets due to interface lag',
		rating: 'Good',
	},
	{
		name: 'Emma',
		comment: 'Smooth experience while accessing invoices module',
		rating: 'Fair',
	},
	{
		name: 'Michael',
		comment: 'Dashboard performs well despite intermittent issues',
		rating: 'Excellent',
	},
	{
		name: 'Sophia',
		comment: 'Was able to find the information required in knowledge base',
		rating: 'Excellent',
	},
];

@Component({
	selector: 'app-satisfaction-score',
	templateUrl: './satisfaction-score.component.html',
	styleUrls: ['./satisfaction-score.component.scss'],
	standalone: true,
	imports: [CdkTableModule, LoadingComponent, NoDataComponent, NgIf],
})
export class SatisfactionScoreComponent implements OnInit {
	@Input() data: IDataModal;

	dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = ['name', 'comment', 'rating'];
	reportApiService = inject(ReportApiService);
	isLoading: boolean = true;

	ngOnInit(): void {
		// this.dataSource = new MatTableDataSource(satScores);
		this.reportApiService.getSatisfactionScoreDetails().subscribe((data) => {
			this.isLoading = false;
			if (data === null) return;
			this.dataSource = new MatTableDataSource(data.data);
		});
	}
}
