import { IPageState } from "@app/shared/interfaces/page-state.interface";
import { createAction, props } from "@ngrx/store";



export enum ActionTypes{
  SUPPORT_CATEGORIES_UPDATE = '[GlobalSupportCategories] Update',
  SUPPORT_CATEGORIES_UPDATE_SUCCESS = '[GlobalSupportCategories] Update Success',
}

export const globalSupportCategoriesUpdateAction =
createAction(
  ActionTypes.SUPPORT_CATEGORIES_UPDATE,
  props<Partial<IPageState>>()
)

export const globalSupportCategoriesUpdateActionSuccess =
createAction(
  ActionTypes.SUPPORT_CATEGORIES_UPDATE_SUCCESS
)

