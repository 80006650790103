/* Angular Libraries */
import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { NotificationService } from '@app/core/services/notification.service';
import { MobileLaunchModalService } from './mobile-launch-modal.service';
import { SmsQuestMobileUpdateModalService } from '../sms-quest-mobile-update-modal/sms-quest-mobile-update-modal.service';

/* Interfaces */
import { IForm, MobileLaunch, MobileUpdateAction } from '@app/shared/interfaces/sms-quest-mobile-update.interface';
import { DeviceType } from '@app/shared/constants/global-enum';
import { MobileUpdateType } from '@app/shared/constants/mobile-update.enum';

declare var QRious: any;

@Component({
  selector: 'mobile-launch-modal',
  templateUrl: './mobile-launch-modal.component.html',
  styleUrls: ['./mobile-launch-modal.component.scss']
})
export class MobileLaunchModalComponent implements OnInit, AfterViewInit {
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();

  modalForm: FormGroup;
  deviceType = DeviceType;
  private _$unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public _smsQuestMobileUpdateModalService: SmsQuestMobileUpdateModalService,
    public mobileLaunchService: MobileLaunchModalService,
    private _fb: FormBuilder,
    private _notifier: NotificationService
  ) { }

  get baseUrl() {
    return document.location.origin;
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngAfterViewInit(): void {
    this._initQRcodeGenerator();
  }

  signUp() {
    const requestBody: MobileLaunch = {
			phoneNumber: this.modalForm.controls['phoneNumber'].value ? `${1}${this.modalForm.controls['phoneNumber'].value}` : '',
      typeOfDevice: this.modalForm.controls['typeOfDevice'].value
    };

    this.mobileLaunchService.mobileOptInSignUp(requestBody)
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next: (res) => {
					this._notifier.notify('Text Updates Confirmed.', { duration: 8, panelClass: 'success' });
        	this.mobileLaunchService.initNewMobileLaunch();
          this._closeModal();
				}
      });
  }

  onClickSkip() {
    if (this.mobileLaunchService.hasMobileRegistered) {
      const requestBody: MobileUpdateAction = { id: this.mobileLaunchService.id, action: MobileUpdateType.skip };

      this.mobileLaunchService.postNewMobileRelease(requestBody)
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next: () => this.mobileLaunchService.initNewMobileLaunch()
      });

    } else {
      this._smsQuestMobileUpdateModalService
			.mobileOptInSkip()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe((res) => this._closeModal());
    }
  }

  private _initQRcodeGenerator() {
    new QRious({ 
      element: document.getElementById('qrcode-display'), 
      value: `${this.baseUrl}/mobile-qr-code`,
      size: 120
    });
  }

  private _closeModal() {
    this.onCloseModal.emit();
    this.mobileLaunchService.modalClosed();
  }

  private _initForm() {
    const form: IForm<MobileLaunch> = {
      phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
      typeOfDevice: [DeviceType.android],
    };

    this.modalForm = this._fb.group(form);
  }

  ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
