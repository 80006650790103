import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import {
	OptionsModel,
	OptionsAddEditModeModel,
	OptionsInterface,
	GlobalAccess,
	CurrentLevel,
} from '@app/shared/functions/options';

import { UserService } from '@app/core/services/user.service';
import { GlobalPermissionsService } from '@app/shared/services/base-application.service';

import { UserTypes } from '@app/shared/constants';

@Component({
	selector: 'app-base-application-catalog',
	templateUrl: './base-application-catalog.component.html',
	providers: [GlobalPermissionsService],
})
export class BaseApplicationCatalogComponent implements OnInit {
	roleId: UserTypes;
	options$: Observable<OptionsModel | OptionsAddEditModeModel | null>;

	constructor(
		private _globalPermissionService: GlobalPermissionsService,
		private _userService: UserService
	) {}

	ngOnInit() {
		const data: OptionsInterface = {
			levelId: this.assignLevelId(),
			roleId: this._userService.userRole,
			moduleId: GlobalAccess.Application,
		};

		this.roleId = this._userService.user!.roleId;
		this.options$ = this._globalPermissionService.options$;
		this._globalPermissionService.setData(data, 'list');
	}

	assignLevelId() {
		if (this._userService.isSpAdmin) {
			return CurrentLevel.GLOBAL;
		} else if (this._userService.isClientAdmin) {
			return CurrentLevel.COMPANY;
		} else return CurrentLevel.CONTACT;
	}
}
