import {
	Component,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
	inject,
} from '@angular/core';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { SharedModule } from '@app/shared/shared.module';
import {
	OwlDateTimeModule,
	OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';

@Component({
	selector: 'app-priorize-vulnerabilty',
	templateUrl: './priorize-vulnerabilty.component.html',
	styleUrls: [
		'./priorize-vulnerabilty.component.scss',
		'../../data-modal.component.scss',
	],
	standalone: true,
	imports: [
		SharedModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		FormTooltipModule,
	],
})
export class PriorizeVulnerabiltyComponent implements OnInit {
	@Input() data: IDataModal;
	dataModalService = inject(DataModalService);
	form: FormGroup;
	publishTime = moment(new Date()).format('HH:mm');
	ngOnInit(): void {
		this.form = new FormGroup({
			publishDateTime: new FormControl(''),
			publishDate: new FormControl(null),
			publishTime: new FormControl(''),
		});
	}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}

	refreshDateRangeFieldValidation(current: AbstractControl) {
		let c = this.form.get('publishDate');
		if (c && current != c) c.updateValueAndValidity();
		c = this.form.get('publishTime');
		if (c && current != c) c.updateValueAndValidity();
		c = this.form.get('specificEndDate');
		if (c && current != c) c.updateValueAndValidity();
		c = this.form.get('specificEndTime');
		if (c && current != c) c.updateValueAndValidity();
	}

	publishTimeChange(v: any) {
		const { value } = v;
		if (moment(value).isValid())
			this.publishTime = moment(value).format('HH:mm');
	}
}
