import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { createAction, props } from "@ngrx/store";


export enum ActionTypes{
  COMPANY_KB_CATEGORIES_UPDATE = '[CompanyKBCategory] Update',
  COMPANY_KB_CATEGORIES_UPDATE_SUCCESS = '[CompanyKBCategory] Update Success',

}

export const companyKbCategoryUpdateAction =
createAction(
  ActionTypes.COMPANY_KB_CATEGORIES_UPDATE,
  props<Partial<IPageState>>()
)

export const companyKbCategoryUpdateSuccessAction =
createAction(
  ActionTypes.COMPANY_KB_CATEGORIES_UPDATE_SUCCESS
)
