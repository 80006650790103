<div class="modal-body">
  <div class="ErrorModule-content">
    <p class="font-size-15">Sourcepass manages your device patching on your preferred patching schedule. Prioritizing vulnerabilities is not required, and this will be patched according to schedule.</p>
    <p class="font-size-15">If you would like to escalate this patch for immediate patching, please be aware that your service could be impacted. Please choose the date and time in which you would like this patch to be implemented.</p>
    <div class="form-group-2 q-d-flex flex-row" style="gap: 56px">
			<div class="publish-time">
				<div>
					<label class="message-label">Date</label>
					<app-date-picker
						inputClass="form-control-2"
						name="date"
						formControlName="publishDate"
						(changeDate)="
							refreshDateRangeFieldValidation(form.get('publishDate')!)
						"
						errorTooltip
						[etControl]="form.get('publishDate')!"
						[etIsTouched]="form.get('publishDate')!.touched"
						[etMessage]="[{ error: 'required', message: 'Date is required' }]"
						[etShow]="
							form.get('publishDate')!.touched &&
							!!form.get('publishDate')!.errors
						"
					>
					</app-date-picker>
				</div>

				<div class="time">
					<label class="message-label">Time</label>
          <mat-icon svgIcon="clock"></mat-icon>
					<input
						class="form-control-2"
						formControlName="publishTime"
						name="time"
						errorTooltip
						(change)="
							refreshDateRangeFieldValidation(form.get('publishTime')!)
						"
						[etControl]="form.get('publishTime')!"
						[etIsTouched]="form.get('publishTime')!.touched"
						[etMessage]="[{ error: 'required', message: 'Time is required' }]"
						[etShow]="
							form.get('publishTime')!.touched &&
							!!form.get('publishTime')!.errors
						"
						[owlDateTime]="dt2"
						[owlDateTimeTrigger]="dt2"
						placeholder="__:__ __"
						(dateTimeChange)="publishTimeChange($event)"
					/>
					<span [owlDateTimeTrigger]="dt2" class="time-field__icon"></span>
					<owl-date-time
						#dt2
						pickerType="timer"
						backdropClass="bd-class"
						panelClass="panel-class"
						[hour12Timer]="true"
					></owl-date-time>

				</div>
			</div>

		</div>
  </div>
</div>
<div class="modal-footer q-g-16" [class.flex-start]="!data.options && data.altMessage">
  <p style="font-size:12px !important;"><span style="font-weight: bold;">Please Note:</span> Patching requests must be submitted at least 24 hours in advance.</p>
  <app-tertiary-button
    *ngIf="data.options && data.options.noText"
    [btnText]="'Cancel'"
    (click)="closeModal(false)"
  >
  </app-tertiary-button>

  <button
    class="imp-disabled-op"
    *ngIf="data.options && data.options.yesText"
    type="button"
    [class.btn-2-primary]="
      data.type == 'success' ||
      data.type == 'info' ||
      data.type == 'warn'
    "
    [class.btn-2-destructive]="data.type == 'danger'"
    class="btn-2"
    (click)="closeModal(true)"
  >
    Confirm
  </button>
</div>
