import { createAction, props } from '@ngrx/store';

import { IApplication } from '../../application.interface';

export enum ActionTypes {
	GLOBAL_APPLICATION_UPDATE = '[Global Application List] Update',
	GLOBAL_APPLICATION_UPDATE_SUCCESS = '[Global Application List] Update Success',
}

export const globalApplicationUpdateAction = createAction(
	ActionTypes.GLOBAL_APPLICATION_UPDATE,
	props<Partial<IApplication>>()
);

export const globalApplicationUpdateSuccessAction = createAction(
	ActionTypes.GLOBAL_APPLICATION_UPDATE_SUCCESS
);
