import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';

import { filter, map, Observable, shareReplay, startWith } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { SideBarService } from '../side-bar.service';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';

import { configSelector } from '../../configuration/store/configuration.selector';

@Component({
	selector: '[app-side-bar-accordion]',
	templateUrl: './side-bar-accordion.component.html',
	styleUrls: ['./side-bar-accordion.component.scss'],
})
export class SideBarAccordionComponent implements OnInit, OnChanges {
	@Input() sideBarData: RoleMenuInterface;
	@Input() index: number;

	isActive: boolean = false;

	sideBarColor$: Observable<string>;
	bgImage$: Observable<string>;
	bg$: Observable<string | null>;

	constructor(
		public _sideBarService: SideBarService,
		private _sessionStorageService: SessionStorageService,
		private router: Router,
		private store: Store<AppStateInterface>,
		private _ticket: TicketService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				startWith(this.router)
			)
			.subscribe(() => {
				this.isActive = this.checkRouterActive();
			});
	}

	ngOnInit() {
		this.sideBarColor$ = this.store.pipe(
			select(configSelector),
			map((data) => data.sideBarColor),
			shareReplay()
		);

		this.bgImage$ = this.sideBarColor$.pipe(map((data) => data));

		this.bg$ = this.sideBarColor$.pipe(map((data) => data));
	}

	getBGImage() {
		const color = localStorage.getItem('selected-sidebar-color');
		return color && this.isActive ? 'none' : '';
	}

	getBG() {
		return this.isActive
			? localStorage.getItem('selected-sidebar-color')
			: null;
	}

	checkRouterActive() {
		if (!this.sideBarData.url) return false;
		if (!(this.sideBarData.url === this.router.url.split('/')[1])) return false;
		return true;
	}

	setQueryParams(id: number): Params | null {
		if (id !== 203) {
			return null;
		} else {
			return { u: this._ticket.getUsageId() };
		}
	}

	clearGlobalSearch() {
		const searchText = this._sessionStorageService.getStorage('global-search');

		if (searchText) {
			this._sessionStorageService.removeStorageItem('global-search');
		}
	}

	getUrl(url: string): string {
		return `${url}/company-types`;
	}
}
