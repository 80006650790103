<company-view 
	*ngIf="hasChildren"
	(onUpdatedCompanyId)="onUpdatedCompanyId()">
</company-view>

<div *ngIf="isShowInvoice" class="card-2 q-mb-24">
	<!-- [Start]::Invoice Header -->
	<ng-container *ngIf="isManagePaymentMethodEnabled && isUserManagePaymentMethodEnabled">
		<app-payment-method
			[billingContact]="widgetData.billingContacts"
			[isManagePaymentMethodEnabled]="isManagePaymentMethodEnabled && isUserManagePaymentMethodEnabled"
			(updateInvoiceAuditTrail)="updateAuditTrail()">
		</app-payment-method>
	</ng-container>

	<mat-tab-group
		[(selectedIndex)]="tabIndex"
		(selectedIndexChange)="onChangeTab($event)"
		class="q-monorail-tab"
		disableRipple
	>
		<mat-tab label="Invoices">
			<app-invoice-table
				*ngIf="loadedTab.includes(0)"
				[isStripeEnabled]="isStripeEnabled"
				[isPaymentEnabled]="isPaymentEnabled"
				[isManagePaymentMethodEnabled]="isManagePaymentMethodEnabled">
			</app-invoice-table>
		</mat-tab>

		<mat-tab label="Credits">
			<credits-table 
				*ngIf="loadedTab.includes(1)"
				[availableCredits]="widgetData.unappliedCredits"
				[unappliedPayments]="widgetData.unappliedPayments">
			</credits-table>
		</mat-tab>

		<mat-tab label="Payment History">
			<payments-table 
				*ngIf="loadedTab.includes(2)">
			</payments-table>
		</mat-tab>

		<mat-tab label="Account Activity">
			<account-activity 
				*ngIf="loadedTab.includes(3)">
			</account-activity>
		</mat-tab>

		<mat-tab disabled>
			<ng-template matTabLabel>
				<ng-container *ngTemplateOutlet="viewBalanceLabel"></ng-container>
			</ng-template>
		</mat-tab>
	</mat-tab-group>

	<!-- [End]::Invoice Header -->
</div>

<app-audit-trail
	*ngIf="isShowInvoice"
	[url]="historyUrl.INVOICES"
	[id]="'All'"
	[history_category]="'invoice'"
	[isCustomizeContent]="true"
>
	<ng-template #customizeContent let-option let-i="index">
		<strong>
			{{ option.firstName }} {{ option.lastName }}
		</strong>

		<ng-container [ngSwitch]="option.status">
			<ng-container *ngSwitchCase="'toggled'">
				turned {{ option.last4 == 'Yes' ? 'on' : 'off' }} <strong>{{ option.type }}</strong>
			</ng-container>
		
			<ng-container *ngSwitchDefault>
				{{ option.status ?? 'updated' }} payment method - <strong>{{ option.type }} **** {{ option.last4 }}</strong>
			</ng-container>
		</ng-container>
	</ng-template>
</app-audit-trail>

<ng-template #viewBalanceLabel>
	<div id="selected-amount" class="tab-pages_container_amount q-d-flex q-ai-center q-jc-between q-g-24 q-p-16 q-mr-5">
		<div class="tab-pages_container_amount_label font-btn">Selected Amount</div>
		<div class="tab-pages_container_amount_value font-h4">{{ totalSelectedAmount | currency }}</div>
	</div>

	<div id="outstanding-balance" class="tab-pages_container_amount q-d-flex q-ai-center q-jc-between q-g-24 q-p-16">
		<div class="tab-pages_container_amount_label font-btn">Balance Due</div>
		<div class="tab-pages_container_amount_value font-h4">{{ widgetData.outstandingBalance | currency }}</div>
	</div>
</ng-template>
