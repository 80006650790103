import { BehaviorSubject } from 'rxjs';
import { ElementRef, Injectable } from "@angular/core";
import { UserService } from "@app/core/services/user.service";
import * as Color from 'color';
import { take } from "rxjs";
import { User } from "../interfaces/user.interface";
import { IAttachments } from '../components/messaging/view-ticket/ticket.interface';
import { SessionStorageService } from './session-storage.service';
@Injectable(
  {providedIn: 'root'}
)
export class UtilitiesService{
  private chatBotSource = new BehaviorSubject<boolean>(false);
  currentChatBot = this.chatBotSource.asObservable();


  constructor(private _sessionStorage: SessionStorageService){}



  changeColor(colorHex: string, intensity: number = 0.2){
    if(colorHex && colorHex !== 'string') {
      const color = Color(colorHex);
      if(color.isDark()){
        return color.lighten(intensity).hex();
      }else if(color.isLight()){
        return color.darken(intensity).hex();
      }
    }
    return '#000';
  }

  smartColorAlgorithm(colorHex: string){
    if(colorHex && colorHex !== 'string') {
      const color = Color(colorHex);
      if(color.isDark()){
        let colorRGB = color.rgb().array();
        colorRGB = [colorRGB[0] + 64,colorRGB[1] + 64,colorRGB[2] + 64]
        return Color(colorRGB).hex();
        // lighten color by 1
      }else if(color.isLight()){
        let colorRGB = color.rgb().array();
        colorRGB = [colorRGB[0] - 64,colorRGB[1] - 64,colorRGB[2] - 64]
        return Color(colorRGB).hex();
        // darken color by 1
      }
    }
    return '#000';
  }

  checkAndConvertLight(colorHex: string){
    if(colorHex && colorHex !== 'string') {
      const color = Color(colorHex);
      if(color.isDark()){
       return color
      }else if(color.isLight()){
        let colorRGB = color.rgb().array();
        colorRGB = [colorRGB[0] - 32,colorRGB[1] - 32,colorRGB[2] - 32]
        return Color(colorRGB).hex();
      }
    }
    return '#000';
  }

  lighten(colorHex: string){
    if(colorHex && colorHex !== 'string') {
      const color = Color(colorHex);
      if(color.isDark()){
        return color.lighten(0.4).hex();
      }else{
        return color.lighten(0.2).hex();
      }

    }
    return '#000';
  }

  darken(colorHex: string | undefined){
    if(colorHex && colorHex !== 'string') {
      const color = Color(colorHex);
      if(color.isLight()){
        return color.darken(0.5).hex();
      }else{
        return color.lighten(0.2).hex();
      }
    }
    return '#000';
  }

  light(colorHex: string) {
    if(colorHex && colorHex !== 'string') {
      const color = Color(colorHex);
      return color.lighten(0.7).hex();
    }
    return '#fff'
  }




    compareRemove(arrA: number[], arrB: number[]) {
    return arrA.filter((item) => !arrB.includes(item));
  };

    compareAdd (arrA: number[], arrB: number[]) {
    return arrB.filter((item) => !arrA.includes(item));
  };

  getInitials(fullName: string){
    if(fullName === null){
      return 'UU';
    }
    let names = fullName.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  isImageFile(str: string){
    return(str.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null);
  }

  parseImage(str: string){
    let imgUrl = str.substring(str.indexOf('(img)')+5)
    return imgUrl;
  }
   parseTitle(str: string){
   if(!str.includes('(c-'))return null;
   let id = str.substring(str.indexOf('(c-')+3,str.indexOf(')'));
   return id;
  }
  parseName(str: string): string {
    if(!str.includes('['))return '';
    let name = str.substring(str.indexOf('[')+1,str.indexOf(']'));
    return name;
  }

  checkName(attachment: IAttachments){
    if(attachment.name) return attachment.name;
    let id = this.parseTitle(attachment.title)
    if(id) return id;
    else return null;
  }

  transFormToLink(str: string): string{
    return str.toLowerCase().replace(' ', '-');
  }

  toggleChatBot(value: boolean) {
    this.chatBotSource.next(value);
    this._sessionStorage.setStorage('isChatBotOpen', value.toString());
  }

  mapUserName(id: number, userTable: User[]): string{
    let userData = userTable.find(user=> user.id === id)
    if(userData){
      return userData.firstName + ' ' + userData.lastName
    }else{
      return 'Unknown User'
    }
  }

  mapUserColor(id: number, userColor: {id: number, color: string}[]): string{
    let userData = userColor.find(obj=> obj.id === id)
    if(userData){
      return userData.color;
    }else{
      return '#000000';
    }
  }

  /**
   * might be temporary.
   * it would be better if we include the type or color status from BE
   * value:  primary, dark, success, danger, info, warning
   */
  getQuoteStatusBadgeType(status: string): string {
    let value = 'primary';

    switch (status) {
      case 'Did not Purchase':
        value = 'danger';
        break;
      case 'Lost':
        value = 'success';
        break;
      case 'New':
        value = 'primary';
        break;
      case 'No decision':
        value = 'danger';
        break;
      case 'Open':
        value = 'info';
        break;
      case 'Opportunity Approved':
        value = 'primary';
        break;
      case 'Ready to Order':
        value = 'primary';
        break;
      case 'Request Sales Rep Update':
        value = 'danger';
        break;
      case 'Reseller':
        value = 'primary';
        break;
      case 'Won':
        value = 'info';
        break;
    }

    return value;
  }

  /**
   * might be temporary.
   * it would be better if we include the type or color status from BE
   * value:  primary, dark, success, danger, info, warning
   */
  getOrderStatusBadgeType(status: string): string {
    let value = 'primary';

    switch (status) {
      case 'Backordered':
        value = 'warning';
        break;
      case 'completed check 11t':
        value = 'dark';
        break;
      case 'Delivered':
        value = 'success';
        break;
      case 'Open':
        value = 'danger';
        break;
      case 'ordered':
        value = 'info';
        break;
      case 'Track Order':
        value = 'primary';
        break;
    }

    return value;
  }

  /**
   * might be temporary.
   * it would be better if we include the type or color status from BE
   * value:  primary, dark, success, danger, info, warning
   */
  getInvoiceStatusBadgeType(status: string): string {
    let value = 'primary';

    switch (status) {
      case 'New':
        value = 'primary';
      case 'Sent':
        value = 'info';
        break;
      case 'Closed':
        value = 'success';
        break;
    }

    return value;
  }

  capitalize(text: string): string {
    return text.replace(/\b\w/g , function(m){ return m.toUpperCase(); } );
  }
}
