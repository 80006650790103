import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'no-profile-avatar',
	templateUrl: './no-profile-avatar.component.html',
	styleUrls: ['./no-profile-avatar.component.scss'],
})
export class NoProfileAvatarComponent implements OnInit, OnChanges {
	@Input() initials = '';
	@Input() ctr = 1;
	@Input() size: 'sm' | 'md' = 'md';

	cssClass = 'avatar-1';
	private _maxCtr = 7;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['ctr']) {
			let ctr = changes['ctr'].currentValue;
			while (ctr > this._maxCtr) {
				ctr = ctr - 7;
			}
			this.cssClass = `avatar-${ctr}`;
		}
	}

	ngOnInit() {}
}
