import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { NotificationMessages } from '@app/shared/constants';
import { ComponentWidget, KnowBe4Training } from '@app/shared/interfaces/dashboard.interface';
import { finalize, Observable } from 'rxjs';
import type { EChartsOption } from 'echarts';
import { SpinnerService } from '@app/core/services/spinner.service';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'widget-kb4training',
  templateUrl: './kb4training-widget.component.html',
  styleUrls: ['./kb4training-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Kb4trainingWidgetComponent implements OnInit {
  @Input() data: ComponentWidget;

  isAdminOrManager: boolean;
  kb4Trainings$: Observable<KnowBe4Training | null>;

  isMedium = false;
  maxItem = 0;
  options: EChartsOption;

  kb4Link = 'https://training.knowbe4.com/'

  constructor(
    private _dashboard: DashboardService,
    private _spinner: SpinnerService,
    private _notifier: NotificationService,
  ) {
    this._dashboard.initKb4Trainings();
  }

  ngOnInit(){
    this.isAdminOrManager = this._dashboard.systemRoleId < 3 || this._dashboard.isKB4Manager;
    this.kb4Trainings$ = this._dashboard.kb4Trainings$ as any;

    this.isMedium = parseInt(this.data.width) >= 600;
    if(!this.isMedium && this.data.height) {
      let max = 1;
      if(this.isAdminOrManager)
        max = Math.floor((parseInt(this.data.height) - 199.5) / 28);
      else max = Math.floor((parseInt(this.data.height) - 284.5) / 23.5);
      this.maxItem = max > 0 ? max : 1;
    }
  }

  sendReminder(userId?: number) {
    this._spinner.start();
    this._dashboard.sendReminderKB4Trainings(userId)
    .pipe(finalize(() => this._spinner.stop()))
    .subscribe({
      error: error => {
        this._notifier.notifyError(
          NotificationMessages.unable('send reminder'),
          NotificationMessages.Try
        )
      }
    });
  }

  statusClass(status: string) {
    return status.toLowerCase().replace(' ','');
  }

  percentageClass(percentage: number) {
    return percentage <= 40 ? 'p-40' :
    percentage <= 60 ? 'p-60' :
    percentage <= 80 ? 'p-80' : ''
  }

  chartOptions(percentage: number) {
    const v1 = percentage > 100 ? 100 : percentage,
    v2 = 100 - v1;
    
    return this.options = {
      silent: true,
      color: [
        '#53B6ED',
        '#DFE0E2',
      ],
      series: [
        {
          type: 'pie',
          radius: ['64%', '100%'],
          label: {
            show: false,
          },
          data: [
            { value: v1 },
            { value: v2 },
          ]
        }
      ]
    };
  }

  ngOnDestroy(): void {
  }
}
