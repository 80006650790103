import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class CustomDateAdapter extends NativeDateAdapter {

    // override format(date: Date, displayFormat: Object): string {

    //     if (displayFormat === 'input') {

    //         const day = date.getDate();
    //         const month = date.getMonth() + 1;
    //         const year = date.getFullYear();

    //         return `${day}-${month}-${year}`;
    //     }

    //     return date.toDateString();
    // }


    override parse(value: any): Date | null {
        const date = moment(value, 'DD/MM/YYYY');
        return date.isValid() ? date.toDate() : null;
    }
}