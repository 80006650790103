<mat-drawer-container 
        [ngClass]="{'backdrop-transparent': config?.backdropTransparent}" 
        [hasBackdrop]="config?.hasBackdrop === undefined ? true : config?.hasBackdrop" >
        
    <mat-drawer #drawer [position]="config?.position??'end'"  >
         <ng-template #componentTemplate></ng-template>
    </mat-drawer>
    <div style="width: 100%;height: 100%;">
        <ng-content></ng-content>
    </div>
</mat-drawer-container>