import { TicketService } from './../../../service-and-support/ticket.service';
import { SupportService } from './../../../service-and-support/support.service';
import { clone } from '@app/shared/utilities/helper';
import { UserTicketResponse, TicketRequest } from './../../../../shared/components/messaging/view-ticket/ticket.interface';
import { SupportConstants, FormAnswerType, NotificationMessages} from '@app/shared/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../../../../core/services/user.service';
import { ToastMessageService } from './../../../../shared/services/toast-message.service';
import { ContactSupportService } from './../../contact-support.service';
import { SpinnerService } from '@services/spinner.service';
import { CustomForm } from './../../../../shared/interfaces/custom-form.interface';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DashboardDrawerService } from '@app/shared/services/dashboard-drawer-service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { userDataSelector } from '@app/core/store/user/user.selector';
import { User } from '@app/shared/interfaces/user.interface';
import { SrFormComponent } from '@app/shared/components/sr-form/sr-form.component';
@Component({
  selector: 'app-form-contact-support',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy{
  private srForm: SrFormComponent;
  @ViewChild(SrFormComponent, {static: false}) set content(content: SrFormComponent) {
     if(content) { // initially setter gets called with undefined
          this.srForm = content;
     }
  }

  formData: CustomForm;
  form = new FormGroup<any>({});
  companyId: number | undefined;
  impersonating = false;
  emailValidateMessage: string;
  @ViewChild('relatedTagsInput') relatedTagsInput: ElementRef<HTMLInputElement>;
  relatedTags: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  relatedTag = new FormControl('', Validators.required);
  relatedTagList: Observable<any[]>;
  allRelatedTags: string[] = []; //['Microsoft Office 365', 'Email', 'Book'];
  user: User;
  isScroll = false;

  constructor(
    // Utilities
    private _spinner: SpinnerService,
    private _cd: ChangeDetectorRef,
    private _toastMessageService: ToastMessageService,
    // API
    private _contactSupportService: ContactSupportService,
    private _userService: UserService,
    private _supportService: SupportService,
    private _ticketService: TicketService,
    public drawer: DashboardDrawerService,
    private _companiesService: CompaniesService,
    private store: Store<AppStateInterface>
  ) {

  }

  ngAfterViewInit(): void {
    //this.drawer.openContactSupport = true;
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.store.pipe(
      select(userDataSelector)
    ).subscribe((user)=>{
      this.user = user;
      this.getForm();
    })
  }

  scrollEvent(){
    this.isScroll = true;
    setTimeout(()=>{
      this.isScroll = false;
    }, 500)

  }

  getForm(){
    if (this.user.roleId === 1) {
      this.companyId = Number(this._userService.user?.companyId);
    }

    this.impersonating = !!this._userService.user?.impersonatingBy;
    if (this.user.roleId !== 3) {
      this._getForm(6);
    } else {
      this._getForm(5);
    }

    this._companiesService.getUsersDropdownByCompanyId(this.user.companyId).subscribe((tag: any)=>{
      this.allRelatedTags = tag.filter((user: any)=> user.email !== null);
      this.relatedTagList = this.relatedTag.valueChanges.pipe(
        startWith(null),
        map((t: string | null)=> t ? this._filterTag(t) : this.sliceTags(t))
      )

    })
  }
  sliceTags(t: any): any {
    const filteredTags = this.allRelatedTags.filter((tags: any) => {
      return this.relatedTags.findIndex(x => x === tags.email) === -1;
    });
    return filteredTags;
  }
  private _filterTag(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allRelatedTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  }

  private _getForm(usageId: number) {
    this._spinner.start();
    this._contactSupportService.usageForm(usageId).subscribe({
      next: (res) => {
        this.formData = res;
        this._cd.markForCheck();
        this._spinner.stop();
      },
      error: (error) => {
        this._spinner.stop();
        this._toastMessageService.showErrorMessage(error);
      },
    });
  }

  submit() {

    if(!this.form.valid) {
      this.form.markAllAsTouched();
      this.srForm.form = this.form;
      this.srForm.validateForm();
    } else {
      const companyId = this.companyId ?? this.user.companyId;
      const flatQuestions = this._supportService.toFlatQuestions(
        clone(this.formData.questions)
      );
      const request: any = {
        Id: this.formData.id,
        Summary: this.formData.title,
        Items: { srUserResponses: [] },
        Files: [],
        ccEmails: []
      };

      if (this.companyId) {
        request.CompanyId = this.companyId;
      }

      for (const [key, value] of Object.entries(this.form.value)) {
        const questionId = parseInt(key.slice(1));
        const question = flatQuestions.find((q) => q.id == questionId)!;
        const isMultiple = SupportConstants.FORM_ANSWER_TYPES.find(
          (f) => f.id == question.answerTypeId
        )?.isMultiple;
        const answers: UserTicketResponse = {
          questionId: questionId,
          userTicketAnswer: [],
        };
        let answer: string[] = [value as string];

        if (
          question.answerTypeId == FormAnswerType.File ||
          question.answerTypeId == FormAnswerType.Image
        ) {
          const files =
            value instanceof FileList || value instanceof Array
              ? value
              : [value as File];

          for (let i = 0; i < files.length; i++) {
            request.Files.push(files[i]);
          }
        } else {
          if (isMultiple) {
            answer =
              value instanceof Array
                ? question.answerList
                    .filter((a: any) => value.includes(a.id))
                    .map((a: any) => a.label)
                : [question.answerList.find((a: any) => a.id == value)?.label];
          }

          answer.forEach((a) =>
            answers.userTicketAnswer.push({
              answerTypeId: question.answerTypeId,
              answerValue: a,
            })
          );
          if (question.answerTypeId === FormAnswerType.EmailDropdown && answer[0]) request.ccEmails = answer[0].split(',');

          request.Items.srUserResponses.push(answers);
        }
      }
      this._createTicket(request);
      //this._sendEmailNotif(request);
    }
  }

  private _createTicket(request: TicketRequest) {
    this._spinner.start();
    this._ticketService.addTicket(request, true).subscribe({
      next: () => {
        this.form.reset();
        this._spinner.stop();
        this.openContactSupport = !this.openContactSupport
      },
      error: (error) => {
        this._spinner.stop();
        this._toastMessageService.showErrorMessage(error);
      },
    });
  }

  get openContactSupport(){
    return this.drawer.openContactSupport;
  }

  set openContactSupport(isset:boolean){
    this.drawer.openContactSupport = isset;

  }


  private _sendEmailNotif(request: TicketRequest) {
    this._contactSupportService.sendFeedBackEmailNotif(request).subscribe({
      next: () => {this._spinner.stop();},
      error: (error) => {
        this._spinner.stop();
        this._toastMessageService.showErrorMessage(error);
      },
    })
  }

  remove(tag: string): void {
    const index = this.relatedTags.indexOf(tag);

    if (index >= 0) {
      this.relatedTags.splice(index, 1);
    }

    if (this.relatedTags.length === 0) {
      //this.hasError = true;
    }
    this.emailError = this.validateEmail(tag);
  }
  emailError: boolean = false;
  add(event: MatChipInputEvent): void {

    const value = (event.value || '').trim();
    const existingValue = this.allRelatedTags.filter((x: any) => x.email === value);
    const existingSelection = this.relatedTags.filter((x: any) => x === value);

    // Add our tag
    if (value) {
      if (!this.validateEmail(value)) {
        if (existingValue.length === 0 && existingSelection.length === 0) {
          this.relatedTags.push(value);
          this.emailError = false;
          // Clear the input value
          event.chipInput!.clear();
          this.relatedTag.setValue(null);
        } else {
          this.emailValidateMessage = 'This tag already selected or already available on selection';
          this.emailError = true;
        }
      } else {
        this.emailValidateMessage = 'Invalid email';
        this.emailError = true;
      }
    } else {
      // Clear the input value
      event.chipInput!.clear();
    }
  }
  spaceEvent(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      const inputElement = event.target as HTMLInputElement;
      const value = (inputElement.value || '').trim();
      const existingValue = this.allRelatedTags.filter((x: any) => x.email === value);
      const existingSelection = this.relatedTags.filter((x: any) => x === value);

      // Add our tag
      if (value) {
        if (!this.validateEmail(value)) {
          if (existingValue.length === 0 && existingSelection.length === 0) {
            this.relatedTags.push(value);
            this.emailError = false;
            // Clear the input value
            inputElement.value = '';
            this.relatedTag.setValue(null);
          } else {
            this.emailValidateMessage = 'This tag already selected or already available on selection';
            this.emailError = true;
          }
        } else {
          this.emailValidateMessage = 'Invalid email';
          this.emailError = true;
        }
      } else {
        // Clear the input value
        inputElement.value = '';
      }
    }
  }
  checkEmptyValue(event: any) {
    const value = event.target.value.trim();
    if (value === '') {
      this.emailError = false;
      this.form.get('RelatedTags')?.setErrors(null);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    const tag = event.option.viewValue;
    this.relatedTags.push(tag);
    this.relatedTagsInput.nativeElement.value = '';
    this.relatedTag.setValue(null);
    this.filterTagList(tag);
  }
  filterTagList(tag: any) {
    return this.relatedTagList.pipe(
      map(tags => tags.filter(x=> x.email !== tag))
    )
  }

  validateEmail(email: any){
    const emailToValidate = [email];
    return !emailToValidate.every(email=> email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
   }
}
