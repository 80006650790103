<div>
  <responsive-table
    [table$]="dataTable$"
  ></responsive-table>
</div>

<ng-template #dropdown let-row="row" let-columns="columns" let-clickedIssueId="clickedIssueId">
  <div [@detailExpand]="clickedIssueId == row.issueId? 'expanded': 'collapsed'"
    class="element-detail"
  >
    <ng-container *ngFor="let column of columns">
      <div class="in-line">
        <label class="">{{column.text}}</label> <p class="">{{row[column.column]}}</p>
      </div>
    </ng-container>
  </div>
</ng-template>
