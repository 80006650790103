import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OneDriveUsage, SeeMore, TableDynamicColumns, WidgetDynamicTable, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { UserTypes, TableMessages} from '@app/shared/constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-one-drive-usage',
  templateUrl: './one-drive-usage-stat.component.html',
  styleUrls: ['./one-drive-usage-stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneDriveUsageStatComponent implements OnInit, OnDestroy {
  //@ViewChild(WidgetDynamicTableComponent) table: WidgetDynamicTableComponent;

  #dataSource:WidgetDynamicTable | DxPieChartSettings;
  usage: OneDriveUsage[];
  systemRoleId: UserTypes;
  #oneDrive$: Observable<OneDriveUsage[] | null>
  usageSettings = { // need to improve this settings
    td:{'textAlign': 'left !important'},
    th:{'textAlign': 'left !important'},
    row: {},
  }

  #subscription = new Subscription();

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: ()=>{}
  noClick: ()=>{}

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dashboard:DashboardService,
    private _cd:ChangeDetectorRef,
  ){
    _dashboard.initOneDrive();
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.#initProperties();
  }

  #initProperties(){
    this.systemRoleId = this._dashboard.systemRoleId;
    this.#oneDrive$ = this._dashboard.onedrive$;
    this._cd.markForCheck();
    this.#subscription.add(
      this.#oneDrive$.subscribe(v=>{
        this.#setData(v);
        //this.table.data = this.dataTable;
        //this._cd.markForCheck();
      })
    )
  }


  #setData(one:OneDriveUsage[] | null){
    if(one){
      if(this.systemRoleId < 3){
       /*  this.#dataSource = this.#createOneDriveUserData(one as OneDriveUsage[])
        this.usageSettings = this.#getOneDriveUserSettings(); */
        this.#createOneDriveUserData(one);

      }else{
        /* this.dataSource = this.#createOneDriveUsageData(one as OneDriveUsage[])
        this.usageSettings = this.#getOneDriveUsageSettings(); */
        this.#dataSource = this.#createOneDriveUsageData(one as OneDriveUsage[])
      }
      this._cd.markForCheck();
    }
  }

  #createOneDriveUserData(one: OneDriveUsage[]){
    this.source = one;
    this.columns = this.#getUserColumns()
    this.displayedColumns = this.#getUserDisplayedColumns()
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
    /* return {
      data: one,
      columns: this.#getUserColumns(),
      rowClick: ()=>{},
      noClick: ()=>{},
      noRecordMessage: TableMessages.EmptyTable,
      seeMoreLink: null,
      displayedColumns: this.#getUserDisplayedColumns()
    } */
  }


  #createOneDriveUsageData(one: OneDriveUsage[]){
    return {
      type:'doughnut',
      title: 'One Drive Usage',
      dataSource: this._dashboard.transformOneDriveUsageData(one),
      argumentField: 'allocation',
    }
  }

  #getOneDriveUserSettings(){
    return {
      td:{'textAlign': 'center'},
      th:{'textAlign': 'center'},
      row: {},
    }
  }

  #getOneDriveUsageSettings(){
    return {
      td:{'textAlign': 'left !important'},
      th:{'textAlign': 'left !important'},
      row: {},
    }
  }

  #getUserColumns():any[]{
    return [{
        text: 'Name',
        matColumnDef: 'fullName'
      },
      {
        text: 'File Count',
        matColumnDef:'fileCount',
      },
      {
        text: 'Storage Used [GB]',
        matColumnDef:'storageUsed',
      },
      {
        text: 'Allocated Storage [GB]',
        matColumnDef:'allocatedStorage',
      },
    ]
  }

  #getUsageColumns():any[]{
    return [{
        text: 'Usage Details',
        matColumnDef: 'data'
      },
    ]
  }

  #getUserDisplayedColumns(){
    return ['fullName', 'fileCount', 'storageUsed', 'allocatedStorage'];
  }

  #getUsageDisplayedColumns(){
    return ['data'];
  }

  #formatUsage(usage:OneDriveUsage){
    if(!usage) return [];
    return [
      {data: `File Count: ${usage.fileCount}`},
      {data: `Storage Used: ${usage.storageUsed}`},
      {data: `Storage Allocated: ${usage.allocatedStorage}`},
    ]
  }

  handleClick(){
    const data = this.#dataSource as WidgetDynamicTable;
    if(data?.rowClick != null){
      return data.rowClick
    }else{
      return data.noClick
    }
  }

  getDataTable(): WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      displayedColumns: this.displayedColumns,
    }
  }

  GBFormat(val:number){
    return `${val} GB`;
  }

  get style(){
    return this.usageSettings;
  }

  get tableDataSource(){
    return this.#dataSource as WidgetDynamicTable;
  }

  get pieDataSource(){
    return this.#dataSource as DxPieChartSettings;
  }

}


interface DxPieChartSettings{
  type:string,
  title: string,
  dataSource:any,
  argumentField: string,
}
