<div
	#multiSelect
	class="multi-select-input-2 q-d-flex q-f-column"
	[class.with-selected]="selectedItems.length > 0"
	matAutocompleteOrigin
	#autoOrigin="matAutocompleteOrigin"
>

	<div
		class="input-wrapper q-d-flex q-ai-center q-g-8"
		(click)="openPanel(); searchInput.focus()"
	>
		<mat-icon *ngIf="!hideIcon" svgIcon="search-sm"></mat-icon>

		<input
			#searchInput
			[formControl]="searchControl"
			[placeholder]="placeholder"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matAutocompleteConnectedTo]="autoOrigin"
			(blur)="onTouched()"
			(input)="resetScroll()"
			class="font-b1 font-h-normal q-f-1"
		/>

		<mat-icon svgIcon="alert-triangle"></mat-icon>
		<mat-icon svgIcon="chevron-down"></mat-icon>
		<mat-icon svgIcon="chevron-up"></mat-icon> 
	</div>

	<mat-chip-list
		[selectable]="true"
		[disabled]="disabled"
		#chipList
		cdkOverlayOrigin
		#chipsOrigin="cdkOverlayOrigin"
		matAutocompleteOrigin
		tabindex="-1"
	>
		<div class="chip-list-wrapper q-d-flex q-f-wrap q-g-8 q-m-4">
			<ng-container *ngFor="let item of selectedPreview; let i = index">
				<mat-chip
					*ngIf="i < 4"
					(removed)="toggleSelection(item)"
					(click)="optionClicked($event, item, true)"
					class="q-d-flex q-ai-center q-g-8 font-btn"
					disableRipple
				>
					{{ item.display }}
					<mat-icon matChipRemove svgIcon="x-close"></mat-icon>
				</mat-chip>
			</ng-container>
			
			<mat-chip *ngIf="selectedItems.length > 4" (click)="openSelectedPanel()" class="font-btn" disableRipple> ... </mat-chip>
		</div>
	</mat-chip-list>
</div>

<mat-autocomplete #auto="matAutocomplete" (closed)="resetScroll()" class="multi-select-2-options-panel">
	<mat-option *ngIf="allowSelectAll" class="multi-option">
		<div class="cb-wrapper q-g-24 select-all" (click)="selectAll($event)">
			<checkbox-input [checked]="isAllSelected" style="pointer-events: none;"></checkbox-input>
			<span class="font-btn font-h-normal">{{ selectAllName }}</span>
		</div>
	</mat-option>

	<cdk-virtual-scroll-viewport
		[ngStyle]="{
			'height.px': 40 * ((filteredItems | async)?.length ?? 0),
			'max-height.px': allowSelectAll ? 200 : 240
		}"
		itemSize="10"
		class="q-scroll"
		qScroll
	>
		<mat-option *cdkVirtualFor="let item of filteredItems | async">
			<div class="cb-wrapper q-g-24" (click)="optionClicked($event, item)">
				<checkbox-input [checked]="item.selected" style="pointer-events: none;"></checkbox-input>
				<span class="font-btn font-h-normal">{{ item.display }}</span>
			</div>
		</mat-option>
	</cdk-virtual-scroll-viewport>
</mat-autocomplete>

<ng-template
	cdkConnectedOverlay
	cdkConnectedOverlayHasBackdrop="true"
	(backdropClick)="closeSelectedPanel()"
	cdkConnectedOverlayBackdropClass="transparent"
	[cdkConnectedOverlayOrigin]="chipsOrigin"
	[cdkConnectedOverlayOpen]="selectedPanelOpen && selectedItems.length > -1"
>
	<div class="multi-select-2-overlay scroll-v2 q-shadow-1">
		<div class="q-d-flex q-f-wrap q-g-8 q-p-16">
			<mat-chip
				[disabled]="disabled"
				(click)="optionClicked($event, item, true)"
				*ngFor="let item of selectedItems"
				(removed)="toggleSelection(item)"
				class="q-d-flex q-ai-center q-g-8 font-btn"
				disableRipple
			>
				{{ item.display }}
				<mat-icon matChipRemove svgIcon="x-close"></mat-icon>
			</mat-chip>
		</div>
	</div>
</ng-template>
