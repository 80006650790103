<div class="main-contents spdmin-main">
  <h3>Authentication</h3>

  <!-- <div class="pro-conts">
    <h5>2FA Settings</h5>
    <p>2FA is currently {{is2faEnabled? 'enabled' : 'disabled'}}.</p>
    <button
    class="btn"
    [class]="{'blue-btn': !is2faEnabled, 'black-btn': is2faEnabled}"
    (click)="check2FA();">{{!is2faEnabled ? 'Enable' : 'Disable'}} 2FA</button>
  </div> -->

</div>
