import { createAction, props } from '@ngrx/store';

import { IApplication } from '../../application.interface';

export enum ActionTypes {
	CONTACTS_COMPANY_APPLICATION_UPDATE = '[Contacts Company Application List] Update',
	CONTACTS_COMPANY_APPLICATION_UPDATE_SUCCESS = '[Contacts Company Application List] Update Success',
}

export const contactsCompanyApplicationUpdateAction = createAction(
	ActionTypes.CONTACTS_COMPANY_APPLICATION_UPDATE,
	props<Partial<IApplication>>()
);

export const contactsCompanyApplicationUpdateSuccessAction = createAction(
	ActionTypes.CONTACTS_COMPANY_APPLICATION_UPDATE_SUCCESS
);
