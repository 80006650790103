import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
//import { GlobalPermissionsService } from '@app/shared/services/base-application.service';
import { KbPermissionService } from '@app/shared/services/base-kb.service';

import {  Observable } from 'rxjs';

//import { CurrentLevel, GlobalAccess, OptionsInterface, OptionsModel } from '../../functions/options';
import { CurrentLevel, GlobalAccess, OptionsAddEditModeModel, OptionsInterface, OptionsModel } from '../../functions/options-copy';


@Component({
  selector: 'app-contacts-kb-topics',
  templateUrl: './contacts-kb.component.html',
  styles: [''],
  providers:[KbPermissionService]
})
export class ContactsKnowledgeBaseComponent implements OnInit {
  options$: Observable<OptionsModel | OptionsAddEditModeModel | null>;
  constructor(private _globalPermissionService: KbPermissionService,private _userService: UserService){

  };
  ngOnInit(): void {
    this.options$ = this._globalPermissionService.options$;
    const data: OptionsInterface = {
      levelId: CurrentLevel.CONTACT,
      roleId: this._userService.userRole,
      moduleId: GlobalAccess.KBTopics
    }
    this._globalPermissionService.setData(data, 'list');
  };

}
