import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionParamGuard } from '@app/core/guards/permission-param.guard';

import { EditGlobalSettingsComponent } from './page/edit-global-settings/edit-global-settings.component';
import { GlobalSettingsComponent } from './page/global-settings/global-settings.component';

const routes: Routes = [
  {
    path: ':module',
    data: {
      title: '_globalSettingsName',
      hasUrl: true,
      param: 'module',
    },
    canActivate: [PermissionParamGuard],
    children: [
      {
        path: '',
        component: GlobalSettingsComponent,
      },
      {
        path: 'edit',
        component: EditGlobalSettingsComponent,
        data: {
          title: 'Manage',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GlobalSettingsRoutingModule {}
