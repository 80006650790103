import { IKnowledgeBase } from '../kb-topic.interface';
import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  GROUP_KB_TOPICS_UPDATE = '[GroupKbTopics] Update',
  GROUP_KB_TOPICS_UPDATE_SUCCESS = '[GroupKbTopics] Update Success',

}

export const groupKbTopicsUpdateAction =
createAction(
  ActionTypes.GROUP_KB_TOPICS_UPDATE,
  props<Partial<IKnowledgeBase>>()
)

export const groupKbTopicsUpdateSuccessAction =
createAction(
  ActionTypes.GROUP_KB_TOPICS_UPDATE_SUCCESS
)
