import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent implements OnInit, OnChanges {
  // inject
  private _cd = inject(ChangeDetectorRef)

  @Input() id: string;
  @Input('tabTitle') title = '';
  @Input() isActive = false;
  @Input() row: any;
  @Input() tabType: number = 1;

  constructor() {}

  get isDisplayed() {
    return this.isActive || this.id;
  }

  setIsActive(active:boolean){
    this.isActive = active;
    this._cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log('changes', changes)
    if (changes['row']) {
      this.id = changes['row'].currentValue.id;
    }
  }

  ngOnInit(): void {}
}
