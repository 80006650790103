<div class="phone-input_input-text">
  <span class="phone-input_input-text_pre-fix">
    <app-figma-icon-img
      [iconName]="'phone'"
      [width]="24"
      [height]="24"
      [hasSpaceOnRight]="false"
      [isForButton]="true">
    </app-figma-icon-img>
  </span>

  <input
    #phoneNumberRef
    type="text"
    [prefix]="prefix"
    [mask]="maskFormat"
    class="form-control"
    [placeholder]="placeholder"
    [(ngModel)]="phoneValue"
    size="20"
    maxLength="20"
  />
</div>