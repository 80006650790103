import { on } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import { PaginationConstants } from '@app/shared/constants';
import { IKnowledgeBase } from '../kb-topic.interface';
import { groupKbTopicsUpdateAction, groupKbTopicsUpdateSuccessAction } from './kb-topic.group.actions';


const initialState: IKnowledgeBase = {
  dateStart: '',
  dateEnd: '',
  page: 1,
  pageSize: PaginationConstants.pageSize,
  order: 'asc',
  column: 'category',
  query: '',
  categoryId: [],
  totalItems: 0,
}

export const kbTopicsReducer =
createReducer(
  initialState,
  on(groupKbTopicsUpdateAction, (state, action): IKnowledgeBase =>({
    ...state,
    ...action
  })),
  on(groupKbTopicsUpdateSuccessAction, (state): IKnowledgeBase => ({
    ...state
  }))
)
