import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Agreement } from '@app/shared/interfaces/agreement.interface';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  searchAgreementText: string = '';
  statusIds: string[] = [];
  typeIds: number[] = [];

  constructor(private _http: HttpClient) {}

  clearFilters() {
    this.searchAgreementText = '';
    this.statusIds = [];
    this.typeIds = [];
  }

  getAgreementList(
    page: number,
    pageSize: number,
    search = '',
    column = '',
    order = '',
    companyId: number,
    startDate: string,
    endDate: string,
    statusIds?: string[] | null,
    typeIds?: number[] | null
  ) {

    const param = {
      Page: page,
      PageSize: pageSize,
      Search: search,
      Column: column,
      Order: order,
      CompanyId: companyId,
      DateStartStart: startDate,
      DateStartEnd: endDate,
    } as any;

    if (
      statusIds !== undefined &&
      statusIds !== null &&
      statusIds?.length > 0
    ) {
      param.StatusIds = statusIds;
    }
    if (typeIds !== undefined && typeIds !== null && typeIds?.length > 0) {
      param.TypeIds = typeIds;
    }
    if (param.DateStartStart === 'Invalid date') {
      param.DateStartStart = '';
    }
    if (param.DateStartEnd === 'Invalid date') {
      param.DateStartEnd = '';
    }

    return this._http.get(
      `${environment.apiBaseUrl}companies/GetAgreementList`,
      { params: param }
    ) as Observable<Agreement[]>;
  }

  getAgreementDetails(id: number) {
    return this._http.get(
      `${environment.apiBaseUrl}Companies/GetAgreementById/${id}`
    );
  }

  getAgreementProductList(
    page: number,
    pageSize: number,
    column: string,
    order: string,
    agreementId: number
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}Companies/GetAgreementAdditionList?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&agreementId=${agreementId}`
    );
  }

  getAgreementInvoiceList(
    page: number,
    pageSize: number,
    column: string,
    order: string,
    agreementId: number,
    companyId: number
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}Invoices?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&agreementId=${agreementId}&companyId=${companyId}`
    );
  }

  printInvoice(id: number) {
    const url = `${environment.apiBaseUrl}CW_Invoices/PrintInvoice/${id}`;
    return this._http.get(url, { responseType: 'blob', observe: 'response' });
  }
}
