<nav class="v2-topbar">
	<div
		class="v2-topbar_container q-d-flex q-jc-between q-ai-center q-as-stretch"
	>
		<div class="v2-topbar_bar q-d-flex q-w-100 q-f-column q-ai-start q-g-8">
			<div class="v2-topbar_breadcrumbs q-d-flex q-ai-center q-g-16">
				<div
					class="v2-topbar_breadcrumbs-container q-d-flex q-jc-between q-ai-center q-as-stretch"
				>
					<div class="q-d-flex q-pr-16 q-ai-center q-g-8">
						<nav nav-breadcrumb aria-label="breadcrumb"></nav>
					</div>
				</div>
			</div>
		</div>

		<div class="v2-topbar_search-and-toolbar q-d-flex q-ai-center q-g-16">
			<div
				class="v2-topbar_search"
				[style.display]="isOpenSearchForm ? 'block' : ''"
			>
				<div class="v2-topbar_search-form">
					<global-search *ngIf="isUserRole"></global-search>
				</div>
			</div>

			<div class="v2-topbar_toolbar q-d-flex q-ai-center q-g-16">
				<ul
					class="v2-topbar_navbar-nav q-d-flex q-ai-center q-g-16 q-mb-0 q-pl-0"
				>
					<li
						class="dropdown q-d-flex q-p-4 q-ai-center q-g-10"
						*ngIf="
							isImpersonateEnabled && userService.user?.impersonatingBy === null
						"
					>
						<div class="user-popup-link">
							<a (click)="onImpersonateClick()">
								<app-figma-icon-img
									[iconName]="'user-right-01'"
									[hasSpaceOnRight]="false"
								>
								</app-figma-icon-img>
							</a>
						</div>
					</li>

					<li
						class="v2-topbar_search-icon q-d-flex q-p-4 q-ai-center q-g-10"
						*ngIf="!isOpenSearchForm"
					>
						<div class="position-relative">
							<a
								href="javascript:void(0);"
								class="nav-link text-white p-0"
								(click)="globalSearchComponent.isSearchOpened = true"
							>
								<app-figma-icon-img
									[iconName]="'search-sm'"
									[hasSpaceOnRight]="false"
									class="cursor-pointer"
								>
								</app-figma-icon-img>
							</a>
						</div>
					</li>

					<li class="dropdown q-d-flex q-p-4 q-ai-center q-g-10">
						<a
							href="javascript:void(0);"
							(click)="getBellNotifications()"
							class="nav-link text-white p-0"
							id="dropdownMenuButton"
							data-bs-toggle="dropdown"
							aria-expanded="true"
						>
							<app-figma-icon-img
								[iconName]="'bell-03'"
								[hasSpaceOnRight]="false"
								class="cursor-pointer"
							>
							</app-figma-icon-img>

							<span class="notif-icon" *ngIf="msg_notifications?.countBell">{{
								msg_notifications?.countBell
							}}</span>
						</a>

						<div
							class="dropdown-menu notifications-2 q-shadow-1"
							aria-labelledby="dropdownMenuButton"
							data-bs-popper="static"
						>
							<div class="notifications-2-header">
								<h3 class="q-mr-auto">Notifications</h3>

								<button
									class="btn-2 btn-2-link"
									(click)="clearAllNotification($event)"
									[disabled]="!bellNotifications || !bellNotifications.length"
								>
									Clear All
								</button>
							</div>

							<div class="notifications-2-body scroll-v2">
								<ng-container
									*ngIf="
										bellNotifications && bellNotifications.length;
										else noNotif
									"
								>
									<div
										class="notifications-2-item"
										*ngFor="let n of bellNotifications; let i = index"
										(click)="open(n, i)"
									>
										<p class="font-b1 font-w-500">{{ n.title }}</p>
										<span class="font-b3 font-w-400">{{ n.message }}</span>
									</div>
								</ng-container>

								<ng-template #noNotif>
									<div class="notifications-2-item empty">
										<p class="font-b1 font-w-500">No notifications</p>
									</div>
								</ng-template>
							</div>
						</div>
					</li>

					<li
						class="dropdown q-d-flex q-p-4 q-ai-center q-g-10"
						*ngIf="userService.isUser && isMailEnabled"
					>
						<a (click)="onMessageClick()" class="nav-link text-white p-0">
							<app-figma-icon-img
								[iconName]="'mail-01'"
								[hasSpaceOnRight]="false"
								class="cursor-pointer"
							>
							</app-figma-icon-img>

							<span class="notif-icon" *ngIf="msg_notifications?.countMessages">
								{{ msg_notifications?.countMessages }}
							</span>
						</a>
					</li>

					<li class="q-d-flex q-p-4 q-ai-center q-g-10">
						<a
							href="javascript:void(0);"
							class="nav-link text-white p-0"
							spConfigurator
						>
							<app-figma-icon-img
								[iconName]="'settings-02'"
								[hasSpaceOnRight]="false"
								class="fixed-plugin-button-nav cursor-pointer"
							>
							</app-figma-icon-img>
						</a>
					</li>

					<li class="dropdown q-d-flex q-p-4 q-ai-center q-g-10">
						<a
							id="dropdownMenuButton1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							href="javascript:void(0);"
						>
							<img
								image-error-detection
								[component]="'top-bar.component.html'"
								*ngIf="img"
								[src]="img"
								class="sp-img-fluid rounded-circle"
								alt="icon"
							/>
						</a>

						<ul
							class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
							aria-labelledby="dropdownMenuButton1"
						>
							<li class="mb-2">
								<a
									class="dropdown-item border-radius-md"
									[routerLink]="['/my-account/profile']"
									activityTracker
									[activityAction]="permission.EditProfile"
								>
									<div class="d-flex py-1">
										<div class="my-auto">
											<app-figma-icon-img
												[iconName]="'user-01'"
												[isForButton]="true"
											>
											</app-figma-icon-img>
										</div>

										<div
											class="d-flex flex-column justify-content-center"
											style="margin-top: 1px"
										>
											<p class="text-xs text-secondary mb-0">View Profile</p>
										</div>
									</div>
								</a>
							</li>

							<li class="mb-2">
								<a
									class="dropdown-item border-radius-md"
									(click)="userService.logout(); idleService.stopTracking()"
								>
									<div class="d-flex py-1">
										<div class="my-auto">
											<app-figma-icon-img
												[iconName]="'power-01'"
												[isForButton]="true"
											>
											</app-figma-icon-img>
										</div>

										<div
											class="d-flex flex-column justify-content-center"
											style="margin-top: 1px"
										>
											<p class="text-xs text-secondary mb-0">Logout</p>
										</div>
									</div>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>

<sp-messages-menu></sp-messages-menu>
<sp-impersonate-menu
	*ngIf="(impersonateOptions$ | async)?.isOpen"
></sp-impersonate-menu>
