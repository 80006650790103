import {
	IPermissionData,
	FONT_STYLE,
	PaginationConstants,
	NotificationMessages,
} from '@app/shared/constants';
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectorRef,
	EventEmitter,
	Output,
	OnDestroy,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
	KnowledgeBase,
	keyValuePair,
} from '@interfaces/knowledge-base.interface';

import { NotificationService } from '@services/notification.service';
import { SpinnerService } from '@services/spinner.service';
import { KnowledgeBaseService } from '../../../knowledge-base.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-webpage';
import lory from 'grapesjs-lory-slider';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '@app/core/services/user.service';
import { FormValidators } from '@app/shared/utilities/form-validators';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { NameId } from '@app/shared/interfaces/support.interface';
import { SupportService } from '@app/modules/service-and-support/support.service';

@Component({
	selector: 'app-kb-topic-add-edit',
	templateUrl: './kb-topic-add-edit.component.html',
	styleUrls: ['./kb-topic-add-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KbTopicAddEditComponent implements OnInit, OnDestroy {
	@ViewChild('relatedTagsInput') relatedTagsInput: ElementRef<HTMLInputElement>;
	form = this._fb.group({
		Id: [0],
		CompanyId: [0],
		CreatedBy: [1],
		KBCategoryId: ['', Validators.required],
		Title: [
			'',
			[
				Validators.required,
				Validators.maxLength(100),
				Validators.pattern(/^[0-9A-Za-z\s]+$/),
			],
		],
		Description: ['', Validators.required],
		PrimaryTag: ['', [Validators.required, Validators.maxLength(100)]],
		RelatedTags: [{}],
		CategoryIds: this._fb.array<FormGroup>([]),
		SupportRequest: [[]],
		RelatedTopics: [[]],
		RelatedLinks: this._fb.array([this._fb.control('', FormValidators.url)]),
		Files: [{}],
		LevelId: [0],
		UserId: [0],
	});
	hasSubmitted: boolean = false;
	descriptionEditor: any;
	categories: any[] = [];
	supportRequestForms: keyValuePair[];
	relatedTopics: keyValuePair[];

	relatedTag = new FormControl('', Validators.required);
	separatorKeysCodes: number[] = [ENTER, COMMA];
	relatedTagList: Observable<any[]>;
	relatedTags: string[] = []; // if you have pre-selected
	allRelatedTags: string[] = []; //['Microsoft Office 365', 'Email', 'Book'];

	filesrc: any[] = [];
	fileName: string[] = [];

	supportRequestOptions: any[] = []; //[1, 2, 3];
	relatedTopicsOptions: any[] = []; //[1, 2, 3];

	selectedSupportRequestOptions: any[] = [];
	selectedRelatedTopicsOptions: any[] = [];

	ctr = 0;
	isDataPopulated = false;

	hasError = false;

	topicId: number;
	errorTopic = '';
	isClientAdmin: boolean = false;
	relatedTagMessage = false;
	stateAction: string = '';
	stateStorage: any;
	levelId: number;
	userId: number | undefined;
	companyId = 0;
	@Output() sendStateAction = new EventEmitter<string>();
	@Output() sendSavedId = new EventEmitter<number>();
	@Output() sendPermissionData = new EventEmitter<IPermissionData>();
	@Output() sendTopicName = new EventEmitter<string>();
	@Output() gotoPermissionSettings = new EventEmitter<number>();
	@Output() emitGeneralSettingsName = new EventEmitter<string>();
	@Output() sendFormData = new EventEmitter<any>();

	subCategories: NameId[][] = [];
	mainCategories: NameId[] = [];
	filteredMainCategories: NameId[][] = [];
	private destroy$ = new Subject();

	private _temporaryPageSize = PaginationConstants.pageSize;

	constructor(
		public spinner: SpinnerService,
		private _kbService: KnowledgeBaseService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _notifier: NotificationService,
		private _fb: FormBuilder,
		private _cd: ChangeDetectorRef,
		private _userService: UserService,
		private _breadcrumbsService: BreadcrumbService,
		private _route: ActivatedRoute,
		private _supportService: SupportService
	) {
		if (
			this._userService.user?.roleId !== 1 &&
			this._userService.user?.companyId
		) {
			this.form.controls.CompanyId.setValue(this._userService.user?.companyId);
		}

		if (this._userService.user?.roleId === 2) {
			this.isClientAdmin = true;
		}

		if (this._route.snapshot.params['companyId']) {
			this.levelId = 2;
			this.companyId = Number(this._route.snapshot.params['companyId']);
		} else if (this._route.snapshot.params['userId']) {
			this.levelId = 3;
			this.userId = this._route.snapshot.params['userId'];
		} else {
			if (this._userService.user?.roleId === 1) {
				this.levelId = 1;
			} else if (this._userService.user?.roleId === 2) {
				this.levelId = 2;
				this.companyId = this._userService.user?.companyId;
			} else {
				this.levelId = 3;
				this.userId = this._userService.user?.id;
			}
		}

		this._activatedRoute.queryParams.subscribe((param) => {
			const id = param['topic'] ? window.atob(param['topic']) : undefined;
			if (id) {
				this.stateAction = 'edit';
				this.topicId = Number(id);
				if (this._router.getCurrentNavigation()?.extras?.state) {
					this.stateStorage = this._router.getCurrentNavigation()?.extras
						?.state as KnowledgeBase;
				} else {
					this._kbService.getTopicById(Number(id)).subscribe((resp: any) => {
						this._breadcrumbsService.updateBreadCrumbsText(
							'_kbTopicName',
							resp.title
						);
						this.stateStorage = resp as KnowledgeBase;
						this.sendTopicName.emit(this.stateStorage.title);
						this._setFieldValues();
						this.isDataPopulated = true;
						this._cd.markForCheck();
					});
				}
			} else {
				this.stateAction = 'add';
			}
		});

		if (this._activatedRoute.snapshot.params['companyId']) {
			this.form
				.get('CompanyId')
				?.patchValue(this._activatedRoute.snapshot.params['companyId']);
		}
	}

	get header() {
		return this._activatedRoute.snapshot.data['title'];
	}

	ngOnInit() {
		this.spinner.start();

		this._kbService
			.getCategory(null, this.companyId)
			.subscribe((category: any) => {
				category.data.forEach((cat: any) => {
					cat.name = cat.name.trim();
				});
				this.categories = category.data;
				++this.ctr;
			});

		this._kbService.getRelatedTopic(this.companyId).subscribe((topic: any) => {
			this.relatedTopicsOptions = topic.map(
				(resp: { id: number; name: string | number }) => ({
					value: resp.id,
					display: resp.name,
				})
			);
			++this.ctr;
		});

		this._kbService.getRelatedTag(this.companyId).subscribe((tag: any) => {
			this.allRelatedTags = tag;
			this.relatedTagList = this.relatedTag.valueChanges.pipe(
				startWith(null),
				map((t: string | null) => (t ? this._filterTag(t) : this.sliceTags(t)))
			);

			++this.ctr;
		});

		// Update: API Error 500
		this._kbService.getSupportRequest().subscribe((request: any) => {
			this.supportRequestOptions = request.data.map(
				(resp: { id: number; name: string | number }) => ({
					value: resp.id,
					display: resp.name,
					selected: false,
				})
			);
			this.supportRequestOptions = this.supportRequestOptions.filter((x) => {
				return x['display'];
			});
			++this.ctr;
		});

		this._supportService.getMainCategories().subscribe((res) => {
			this.mainCategories = res.sort((a, b) => a.id - b.id);
			this.filterMainCategories();

			this._supportService.categoriesDropdown(1).subscribe((res) => {
				this.mainCategories.forEach((mc) => {
					this.subCategories[mc.id] = res.data.filter((sc) =>
						sc.supportCategoryIds.includes(mc.id)
					);
				});
				++this.ctr;
			});
		});

		this.form?.valueChanges.subscribe(() => {
			this.sendFormData.emit(this.form);
		});

		this.addRemoveSupportCategory(0);
	}

	sliceTags(t: any): any {
		const filteredTags = this.allRelatedTags.filter((tags: any) => {
			return this.relatedTags.findIndex((x) => x === tags.name) === -1;
		});
		return filteredTags;
	}

	ngAfterContentChecked(): void {
		if (this.ctr === 5) {
			// Update: changed from 4 to 3 (commented out getSupportRequest subscription)
			// Update: changed from 4 to 5 (added sr form main and sub categories)
			this.spinner.stop();
			this.ctr = 0;
			this._cd.markForCheck();
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.sendStateAction.emit(this.stateAction);
			this.sendSavedId.emit(this.topicId);
			if (this.stateStorage) {
				this.sendTopicName.emit(this.stateStorage.title);
			}
			this.renderEditor();
		});
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		// Add our tag
		const existingValue = this.allRelatedTags.filter(
			(x: any) => x.name === value
		);
		const existingSelection = this.relatedTags.filter((x: any) => x === value);
		if (value) {
			if (existingValue.length === 0 && existingSelection.length === 0) {
				this.relatedTags.push(value);
				this.relatedTagMessage = false;
				// Clear the input value
				event.chipInput!.clear();
			} else {
				this.relatedTagMessage = true;
			}
		} else {
			// Clear the input value
			event.chipInput!.clear();
		}
	}

	checkEmptyValue(event: any) {
		const value = event.target.value.trim();
		if (value === '') {
			this.relatedTagMessage = false;
			this.form.get('RelatedTags')?.setErrors(null);
		}
	}

	remove(tag: string): void {
		const index = this.relatedTags.indexOf(tag);

		if (index >= 0) {
			this.relatedTags.splice(index, 1);
		}

		if (this.relatedTags.length === 0) {
			//this.hasError = true;
		}
		this.relatedTag.setValue(null);
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const tag = event.option.viewValue;
		this.relatedTags.push(tag);
		this.relatedTagsInput.nativeElement.value = '';
		this.relatedTag.setValue(null);
		this.relatedTagList.pipe(
			map((tags) => {
				return tags.filter((x) => x.name !== tag);
			})
		);
	}

	onSubmit() {
		this.hasSubmitted = true;
		this._addDescValue();
		if (this.form.valid) {
			this.spinner.start();
			this._setFormValues();
			const app = this.form.value as KnowledgeBase;
			app.CategoryIds = app.CategoryIds.filter((c) => c.mainCategoryId);
			app.RelatedLinks = app.RelatedLinks.filter((link) => {
				return link && link !== 'null';
			});
			app.LevelId = this.levelId;
			if (this.levelId === 3) {
				app.UserId = window.atob(this.userId!?.toString());
			}
			app.userRole = this._userService.userRole;
			if (app.Id === null || app.Id === 0) {
				this._kbService.addKb(app).subscribe({
					next: (resp) => {
						this._resetKB(app);
						this.spinner.stop();
						const respData = resp as KBResponse;
						this.form.get('Id')?.setValue(respData.id);
						this._router.navigate(['../'], {
							relativeTo: this._activatedRoute,
						});
						this.hasSubmitted = false;
					},
					error: (error) => {
						this.spinner.stop();
						this._showErrorBadge(error);
					},
				});
			} else {
				this._kbService.updateKb(app).subscribe({
					next: () => {
						this._resetKB(app);
						this.spinner.stop();
						if (this.stateAction === 'edit') {
							this._router.navigate(['../'], {
								relativeTo: this._activatedRoute,
							});
						} else {
							this.gotoPermissionSettings.emit(1);
						}
					},
					error: (error) => {
						this.spinner.stop();
						this._showErrorBadge(error);
					},
				});
			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	private _showErrorBadge(error: any) {
		this.formValidate(error);
		this.spinner.stop();
	}

	formValidate(error: HttpErrorResponse) {
		this.form.get('Title')?.setErrors({ duplicateName: true });
		this.errorTopic = error.error?.Name?.toString() || error.error;
		window.scroll({
			top: 0,
			left: 0,
		});
	}
	onValidChange() {
		if (this.form.get('Title')?.errors) {
			if (this.form.get('Title')?.errors?.['maxlength']) {
				this.errorTopic = 'Maximum of 100 characters only';
			} else if (this.form.get('Title')?.errors?.['pattern']) {
				this.errorTopic = 'Title only allows alphanumeric characters';
			}
		} //
	}
	resetError() {
		this.errorTopic = '';
		if (this.errorTopic === '') this.hasSubmitted = false;
	}

	/**
	 * Method for rendering GrapesJS Editor
	 */
	renderEditor() {
		this.descriptionEditor = grapesjs.init({
			container: '#gjs',
			fromElement: true,
			height: '700px',
			width: 'auto',
			storageManager: false,
			protectedCss: '',
			avoidInlineStyle: true,
			forceClass: true,
			panels: { defaults: [] },
			allowScripts: 1,
			plugins: [plugin, lory],
			pluginsOpts: {
				[plugin]: {
					navbarOpts: false,
					countdownOpts: false,
					formsOpts: false,
				},
				[lory]: {
					sliderBlock: { label: 'Slider' },
					sliderProps: { name: 'My Slider', draggable: true },
				},
			},
			assetManager: {
				upload: 'localhost',
				uploadFile: (e: any) => {
					const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
					if (files[0].size <= 600000) {
						if (FileReader && files && files.length) {
							var fr = new FileReader();
							fr.onload = () => {
								this.descriptionEditor.AssetManager.add({
									src: fr.result,
									name: files[0].name,
								});
							};
							fr.readAsDataURL(files[0]);
						}
					} else {
						this._notifier.notifyError(
							'File Size Exceeds Limit',
							'File size cannot exceed 10MB'
						);
					}
				},
			},
			canvas: {
				styles: [
					'https://cdn.jsdelivr.net/npm/segoe-fonts@1.0.1/segoe-fonts.min.css',
				],
			},
		});
		this.descriptionEditor.on('component:update', (component: any) => {
			if (component.get('type') === 'text') {
				component.set('content', '');
			}
		});
		this.descriptionEditor.on('component:add', (component: any) => {
			console.log(component, 'component');
			if (component.get('type') === 'lory-slide') {
				// component.parent().parent().parent().parent().append(`<h1>Hello World</h1>`)
				component
					.parent()
					.parent()
					.parent()
					.setStyle({ width: '100%', position: 'relative', margin: '0 auto' });
				component.parent().parent().parent().addClass('.parent-lory');
				component.setStyle({
					display: 'inline-block',
					position: 'relative',
					color: '#000000',
					width: '880px',
					'margin-right': '10px',
					'vertical-align': 'top',
					'min-height': '130px',
					'white-space': 'normal',
					'background-color': '#fcfcfc',
				});
			}
		});
		this.customGrapesConfig();
	}

	customGrapesConfig() {
		// Remove duplicate style manager sectors
		const desiredModels =
			this.descriptionEditor.StyleManager.getSectors().models.filter(
				(value: any, idx: any, self: any) => {
					return (
						idx ===
						self.findIndex(
							(t: any) => t.id === value.id && t.name === value.name
						)
					);
				}
			);
		this.descriptionEditor.on('load', () => {
			this.addFont();
			this.gjsAddType('custom-textarea', 'TEXTAREA', 'textarea');
			this.gjsAddType('custom-button', 'BUTTON', 'button');
			this.addCustomTemplate('custom-template1', 'Custom Template 1', 1);
			this.addCustomTemplate('custom-template2', 'Custom Template 2', 2);
			this.addCustomTemplate('custom-template3', 'Custom Template 3', 3);
			const REMOVE_BLOCK = ['link', 'map', 'quote'];
			REMOVE_BLOCK.forEach((block) =>
				this.descriptionEditor.BlockManager.remove(block)
			);
		});

		// This is for adding custom prefix for all classnames (to avoid overwriting some generic class name)
		const prefix = 'gjs-';
		this.descriptionEditor.on('selector:add', (selector: any) => {
			const name = selector.get('name');
			if (
				selector.get('type') ===
					this.descriptionEditor.SelectorManager.Selector.TYPE_CLASS &&
				name.indexOf(prefix) !== 0
			) {
				selector.set('name', prefix + name);
			}
		});
		this.descriptionEditor.StyleManager.getSectors().models = desiredModels;
	}

	gjsAddType(typename: string, tagname: string, name: string) {
		this.descriptionEditor.Components.addType(typename, {
			isComponent: (el: any) => el.tagName == tagname,
			model: {
				defaults: {
					tagName: name,
					attributes: {
						name: name,
					},
				},
			},
		});
	}

	addCustomTemplate(blockname: string, labelName: string, num?: number) {
		this.descriptionEditor.BlockManager.add(blockname, {
			label: `<h2><i class="template-size"></i></h2> <br> ${labelName}`,
			content: {
				draggable: true,
				attributes: { title: 'text' },
				components: [
					{
						tagName: 'div',
						components: `<h1> This is a sample template ${num} </h1>
            <textarea rows="10" cols="50"></textarea>
            `,
					},
					'<style>h1 { color: red }<style>',
				],
			},
		});
	}

	addFont() {
		const prop = this.descriptionEditor.StyleManager.getProperty(
			'typography',
			'font-family'
		);
		const fonts = FONT_STYLE;
		prop.set('options', fonts);
	}

	fileAdded(files: File[]) {
		this._setFileSource(files);
	}

	removeFile(i: number) {
		this.fileName.splice(i, 1);
		this.filesrc.splice(i, 1);
	}

	uploadedFile(fileList: File) {
		this._setFileSource(fileList as unknown as File[]);
	}

	get relatedLinks() {
		return this.form.get('RelatedLinks') as FormArray;
	}

	addRemoveLinks(e: any, i: number) {
		if (e.target.parentElement.dataset.iconname === 'plus') {
			this.relatedLinks.push(this._fb.control('', FormValidators.url));
		} else if (e.target.parentElement.dataset.iconname === 'minus') {
			this.relatedLinks.removeAt(i);
		}
	}

	addRemoveSupportCategory(
		i: number,
		data?: { mainCategoryId: number; subCategoryIds: number[] }
	) {
		if (i > 0) {
			this.form.controls.CategoryIds.removeAt(i);
			this.filteredMainCategories.splice(i, 1);
			this.filterMainCategories();
		} else {
			const categoryIds = this.form.controls.CategoryIds;
			if (
				categoryIds.controls.length >= this.mainCategories.length &&
				this.mainCategories.length
			)
				return;

			let categories = this._fb.group({
				mainCategoryId: [data?.mainCategoryId ?? null],
				subCategoryIds: [data?.subCategoryIds ?? null],
			});
			categoryIds.push(categories);

			this.filteredMainCategories[categoryIds.controls.length - 1] =
				this.mainCategories.filter(
					(mc) =>
						!categoryIds.value.find(
							(c) =>
								categories.controls.mainCategoryId.value != mc.id &&
								c.mainCategoryId == mc.id
						)
				);

			categories.controls.mainCategoryId.valueChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe((v) => {
					this.filterMainCategories();
					categories.controls.subCategoryIds.setValue([]);
					if (v)
						categories.controls.subCategoryIds.addValidators(
							Validators.required
						);
					else
						categories.controls.subCategoryIds.removeValidators(
							Validators.required
						);
					categories.controls.subCategoryIds.updateValueAndValidity();
				});
		}
	}

	filterMainCategories() {
		setTimeout(() => {
			const cids = this.form.controls.CategoryIds;
			cids.controls.forEach((cid, i) => {
				this.filteredMainCategories[i] = this.mainCategories.filter((mc) => {
					return (
						cids.value.findIndex(
							(c) =>
								c.mainCategoryId == mc.id && cid.value.mainCategoryId != mc.id
						) == -1
					);
				});
			});
		}, 100);
	}

	/**
	 * Render html and css from the editor (For Edit Topic)
	 */
	private _loadHtmlCss() {
		const COMPONENT = JSON.parse(this.stateStorage.description);
		this.descriptionEditor.setComponents(COMPONENT.html);
		this.descriptionEditor.setStyle(COMPONENT.css);
	}
	private _addDescValue() {
		const COMPONENT = JSON.stringify(this.descriptionEditor.getHtml());
		const CSS = JSON.stringify(this.descriptionEditor.getCss());
		const DESCRIPTION = `{"html": ${COMPONENT}, "css": ${CSS}}`;
		this.form.controls.Description.setValue(DESCRIPTION);
	}

	private _setFormValues() {
		this.form.patchValue({
			Files: this.filesrc,
			RelatedTags: this.relatedTags as keyValuePair,
		});
	}

	private _setFileSource(fileList: File[]) {
		if (fileList) {
			if (fileList instanceof FileList) {
				for (const file of fileList) {
					const _file = file;
					this._setFileData(_file);
				}
			} else {
				const _file = fileList as unknown as File;
				this._setFileData(_file);
			}
		}
	}

	private _setFileData(file: File) {
		if (this.fileName.indexOf(file.name) === -1) {
			this.fileName.push(file.name);
			this.filesrc.push(file);
		}
	}

	private _filterTag(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.allRelatedTags.filter((tag) =>
			tag.toLowerCase().includes(filterValue)
		);
	}

	private _setFieldValues() {
		this.form = this._fb.group({
			Id: [this.stateStorage.id],
			CompanyId: [
				this._userService.user?.roleId !== 1 &&
				this._userService.user?.companyId
					? this._userService.user?.companyId
					: 0,
			],
			CreatedBy: [this.stateStorage?.CreatedBy],
			KBCategoryId: [this.stateStorage.kbCategoryId, Validators.required],
			Title: [this.stateStorage.title, Validators.required],
			Description: [this.stateStorage.description, Validators.required],
			PrimaryTag: [this.stateStorage.primaryTag, Validators.required],
			RelatedTags: [{}, Validators.required],
			CategoryIds: this._fb.array<FormGroup>([]),
			SupportRequest: [
				this.stateStorage.supportRequest.map(
					(req: any) => req.supportRequestId
				),
			],
			RelatedTopics: [
				this.stateStorage.relatedTopics.map((req: any) => req.relatedKBTopicId),
			],
			RelatedLinks: this._fb.array([
				this._fb.control(
					this.stateStorage.relatedLinks[0]?.link,
					FormValidators.url
				),
			]),
			Files: [{}],
			LevelId: [this.levelId],
			UserId: [0],
		});

		this.selectedSupportRequestOptions = [
			this.stateStorage.supportRequest.map((req: any) => req.supportRequestId),
		];

		this.selectedRelatedTopicsOptions = [
			this.stateStorage.relatedTopics.map((req: any) => req.relatedKBTopicId),
		];

		this.relatedTags = this.stateStorage.relatedTags.map(
			(req: any) => req.name
		);
		this.filesrc = this.stateStorage.media;

		const kbCategoryIds = this.stateStorage.kbCategoryIds;
		if (kbCategoryIds?.length) {
			kbCategoryIds.forEach((c: any) => {
				this.addRemoveSupportCategory(0, c);
			});
		} else this.addRemoveSupportCategory(0);

		const relatedLinks = this.stateStorage.relatedLinks;

		if (relatedLinks && relatedLinks.length > 0) {
			for (let index = 0; index < relatedLinks.length; index++) {
				if (index !== 0) {
					const item = relatedLinks[index];
					this.relatedLinks.push(this._fb.control(item.link));
				}
			}
		}
		this._loadHtmlCss();

		this.form?.valueChanges.subscribe(() => {
			this.sendFormData.emit(this.form);
		});
		this.sendFormData.emit(this.form);
	}

	ngOnDestroy() {
		this.destroy$.next(1);
		this.destroy$.complete();
	}

	private _resetKB(options: any) {
		const kb_isCustom = JSON.parse(localStorage.getItem('kb_isCustom')!);
		this._kbService.allowToFetchNewData = true;
		this._kbService.clearKBFilters(this.companyId ? true : false, kb_isCustom);
		this._kbService.clearKBData(options, kb_isCustom);
		localStorage.removeItem('kb_isCustom');
	}
}

export interface KBResponse {
	id: number;
	title: string;
}
