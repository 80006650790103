import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IListEmailTemplate } from '../store/list-email-template/list-email-template.interface';
import {
	ElementRef,
	Injectable,
	Renderer2,
	RendererFactory2,
	inject,
} from '@angular/core';

import { ReplaySubject, BehaviorSubject, map, tap, finalize, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { EmailTemplateGlobalStyleSelector } from '../store/add-email-template/add-email-template.selector';
import { EmailTemplateTextBlock } from '../store';
import { KeysToCSS, KeysToCSSException } from '../email-template.model';

@Injectable({
	providedIn: 'root',
})
export class EmailTemplateParentService {
	emailTemplateFilters: IListEmailTemplate;

	protected _renderer!: Renderer2;
	private rendererFactory = inject(RendererFactory2);
	protected _store = inject(Store);

	private _emailTemplateData = new ReplaySubject<any[]>(1);
	emailTemplateData$ = this._emailTemplateData.asObservable();

	private _emailTemplateTotalItems = new ReplaySubject<number>(1);
	emailTemplateTotalItems$ = this._emailTemplateTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	private _http = inject(HttpClient);

	protected _textBlockGlobalStyle$ = this._store.select(
		EmailTemplateGlobalStyleSelector.textBlock
	);

	constructor() {
		this._renderer = this.rendererFactory.createRenderer(null, null);
	}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	getEmailTemplates(options: IListEmailTemplate) {
		this.emailTemplateFilters = options;

		let param = {
			Page: options.page,
			PageSize: options.pageSize,
			SearchText: options.query,
			Column: options.column,
			Order: options.order,
		} as any;

		this.isLoading = true;
		this._emailTemplateData.next([]);
		this._emailTemplateTotalItems.next(0);

		return this._http
			.get<any>(`${environment.apiBaseUrl}emailTemplate`, { params: param })
			.pipe(
				tap((res: any) => {
					this._emailTemplateData.next(res.data);
					this._emailTemplateTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getVariables() {
		return this._http.get<any>(
			`${environment.apiBaseUrl}Maintenance/GetMenuMaintenance`
		);
	}

	protected applyDefaultStyle(elem: ElementRef) {
		this._renderer.setAttribute(elem, 'contenteditable', '');
		this._renderer.setStyle(elem, 'padding', '8px 0 0 0');
		//this._renderer.setStyle(elem, 'color', '#08354F');
		this._renderer.setStyle(elem, 'display', 'inline-flex');
		this._renderer.setStyle(elem, 'width', '100%');
		this._renderer.setStyle(elem, 'justify-content', 'center');
		this._renderer.setStyle(elem, 'align-items', 'center');
		this._renderer.setStyle(elem, 'line-height', 'initial');
		this._renderer.setStyle(elem, 'margin', '0');

		return elem;
	}

	keysToCSS: any = KeysToCSS;

	protected styleConverter(style: EmailTemplateTextBlock | any) {
		let converted: { styleName: string; value: string }[] = [];
		let specialCase: { styleName: string; value: string }[] = [];
		let keysToCSS = this.keysToCSS;
		const key = 'backgroundColor';
		keysToCSS[key];
		for (const key in style) {
			if (key in keysToCSS) {
				converted.push({
					styleName: keysToCSS[key],
					value: style[key],
				});
			} else {
				let exception = KeysToCSSException.find((v) => key === v);
				if (exception) {
					specialCase.push({
						styleName: key,
						value: style[key],
					});
				}
			}
		}
		return { converted, specialCase };
	}

	protected switchSpecialCase(styleName: string, value: boolean, elem: any) {
		switch (styleName) {
			case 'isBold':
				this.isBold(elem, value);
				break;
			case 'isItalic':
				this.isItalic(elem, value);
				break;
			case 'isUnderline':
			case 'isStrikeThrough':
				//this.isStrikeThrough(elem, value);
				this.isUnderlineStrikeThrough(elem, styleName, value);
				break;
		}
	}

	protected isBold(elem: any, value: boolean) {
		value
			? this._renderer.setStyle(elem, 'font-weight', 'bold')
			: this._renderer.setStyle(elem, 'font-weight', 'normal');
	}

	protected isItalic(elem: any, value: boolean) {
		value
			? this._renderer.setStyle(elem, 'font-style', 'italic')
			: this._renderer.setStyle(elem, 'font-style', 'normal');
	}

	protected isUnderline(elem: any, value: boolean) {
		value
			? this._renderer.setStyle(elem, 'text-decoration', 'underline')
			: this._renderer.setStyle(elem, 'text-decoration', 'none');
	}

	protected isStrikeThrough(elem: any, value: boolean) {
		value
			? this._renderer.setStyle(elem, 'text-decoration', 'line-through')
			: this._renderer.setStyle(elem, 'text-decoration', 'none');
	}

	protected isUnderlineStrikeThrough(
		elem: any,
		styleName: string,
		value: boolean
	) {
		if (styleName === 'isUnderline') {
			if (value) {
				const isStrikeThrough = (elem.getAttribute('style') as string).includes(
					'line-through'
				);
				isStrikeThrough
					? this._renderer.setStyle(
							elem,
							'text-decoration',
							'underline line-through'
					  )
					: this._renderer.setStyle(elem, 'text-decoration', 'underline');
			}
		} else {
			if (value) {
				const isUnderline = (elem.getAttribute('style') as string).includes(
					'underline'
				);
				isUnderline
					? this._renderer.setStyle(
							elem,
							'text-decoration',
							'underline line-through'
					  )
					: this._renderer.setStyle(elem, 'text-decoration', 'line-through');
			}
		}
	}

	protected _applySpecialCaseGlobal(
		elem: any,
		style: { styleName: string; value: boolean }[]
	) {
		style.forEach((v) => {
			this.switchSpecialCase(v.styleName, v.value, elem);
		});
	}

	protected _applyGlobalDefault(
		elem: any,
		style: { styleName: string; value: string }[]
	) {
		style.forEach((v) => this._renderer.setStyle(elem, v.styleName, v.value));
	}

	protected _applyGlobal(name: string) {
		let apply: any = undefined;

		this._store
			.select(EmailTemplateGlobalStyleSelector.textBlock)
			.pipe(take(1))
			.subscribe((global) => {
				const styles = global.find((style) => style.name === name);
				if (styles) {
					apply = this.styleConverter(styles);
				}
			});

		return apply;
	}
}
