import { createAction, props } from '@ngrx/store';

import { ICompanies } from './companies.interface';

export enum ActionTypes {
	COMPANIES_UPDATE = '[Companies] Update',
	COMPANIES_UPDATE_SUCCESS = '[Companies] Update Success',
}

export const companiesUpdateAction = createAction(
	ActionTypes.COMPANIES_UPDATE,
	props<Partial<ICompanies>>()
);

export const companiesUpdateSuccessAction = createAction(
	ActionTypes.COMPANIES_UPDATE_SUCCESS
);
