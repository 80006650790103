import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertedDatePipe } from './converted-date.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ConvertedDatePipe],
  exports:[
    ConvertedDatePipe,
  ]
})
export class ConvertedDateModule { }
