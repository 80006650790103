import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { FileFormat } from '@app/shared/constants';

import { IFigmaIcon } from '@app/shared/interfaces/generic.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class FigmaDemoService {
	figmaIconNames: string[] = [];
	figmaIcon: any[] = [];
	figmaIconURL: Record<string, string> = {};

	private readonly _baseUrl = 'https://api.figma.com';
	private readonly _figmaToken =
		'figd_brjxd3Qx9WM0ewJ7qS61GVMRc18auD9-FoOBAvId'; // no expiration token for demo.
	private readonly _contentType = 'application/json';
	private _token = 'hYvDhQ1eyqFmTlPDUK6FAb'; // when approved, need to put this in the environment file

	constructor(private _http: HttpClient, private _sanitizer: DomSanitizer) {}

	initializeFigmaIcon(): Promise<boolean> {
		return new Promise((resolve) => {
			this._getFigmaFiles().subscribe({
				next: async (figma: any) => {
					let temp: string[] = [];
					let figmaIconURL: Record<string, string> = {};
					const document = figma.document;
					const timer = (ms: number | undefined) =>
						new Promise((res) => setTimeout(res, ms));
					const components = figma.components;
					const figmaComponentCount = Object.keys(components).length;
					const figmaComponentNames: string[] = [];

					for (const key in components) {
						if (Object.prototype.hasOwnProperty.call(components, key)) {
							const component = components[key];
							figmaComponentNames.push(component.name);
						}
					}

					this._saveFigmaComponent(figmaComponentNames, figmaComponentCount);

					for (const doc of document.children) {
						for (const canvas of doc.children) {
							for (const frame of canvas.children) {
								for (const subFrame of frame.children) {
									if (subFrame.type.toLowerCase() === 'instance') {
										if (temp.length <= 300) {
											this._collateFigmaDef(subFrame, temp);
										} else {
											this._getFigmaImage(temp.toString()).subscribe({
												next: (e: any) => {
													figmaIconURL = { ...figmaIconURL, ...e.images };
												},
												error: (e) => {
													resolve(false);
													console.log(e);
												},
											});

											await timer(1500); // delay

											temp = [];
											this._collateFigmaDef(subFrame, temp);
										}
									}
								}
							}
						}
					}

					if (temp.length > 0) {
						this._getFigmaImage(temp.toString()).subscribe({
							next: (e: any) => {
								temp = [];
								figmaIconURL = { ...figmaIconURL, ...e.images };
								this._setFigmaIconURL(figmaIconURL);
								resolve(true);
							},
							error: (e) => {
								resolve(false);
								console.log(e);
							},
							complete: () => {
								this._saveFigmaDefToLocalStorage();
							},
						});
					} else {
						this._setFigmaIconURL(figmaIconURL);
						this._saveFigmaDefToLocalStorage();
						resolve(true);
					}
				},
				error: (e) => {
					resolve(false);
					console.log(e);
				},
			});
		});
	}

	getFigmaValue(key: string) {
		return JSON.parse(localStorage.getItem(key)!);
	}

	getSrc(figmaIconId: string): string {
		const figmaIconURL = this.getFigmaValue('figmaIconURL');
		let src = null;

		if (figmaIconURL && figmaIconId) {
			src = figmaIconURL[figmaIconId];
		}

		return src;
	}

	getSrcByNameSafeResourceUrl(iconName: string): SafeResourceUrl {
		const { icon, figmaIconURL } = this._initFigma(iconName);
		let sanitizedSrc = this._sanitizer.bypassSecurityTrustResourceUrl('');

		if (icon && icon?.id) {
			const src = figmaIconURL[icon.id];
			sanitizedSrc = this._sanitizer.bypassSecurityTrustResourceUrl(src);
		}

		return sanitizedSrc;
	}

	getNewFigmaId(iconName: string) {
		const figmaIcon: any[] = this.getFigmaValue('figmaIcon');
		const icon = figmaIcon?.filter((icon) => icon.name === iconName)[0];
		return icon?.id || '';
	}

	getFigmaIcons(): Observable<IFigmaIcon> {
		return this._http.get<IFigmaIcon>(`${environment.apiBaseUrl}Icons`);
	}

	getSrcByName(iconName: string): string {
		const { icon, figmaIconURL } = this._initFigma(iconName);
		return icon?.id ? figmaIconURL[icon.id] : '';
	}

	deleteFigmaLocalStorage() {
		const keys = [
			'figmaIconNames',
			'figmaComponentCount',
			'figmaIcon',
			'figmaComponentNames',
			'figmaIconURL',
			'figmaIconCount',
		];

		keys.forEach((key) => {
			localStorage.removeItem(key);
		});
	}

	private _initFigma(iconName: string) {
		const figmaIcon: any[] = this.getFigmaValue('figmaIcon');
		const figmaIconURL = this.getFigmaValue('figmaIconURL');
		const icon = figmaIcon?.filter((icon) => icon.name === iconName)[0] || null;
		return { icon, figmaIconURL };
	}

	private _getFigmaFiles(): Observable<Object> {
		const headers = new HttpHeaders({
			'Content-Type': `${this._contentType}`,
			'X-Figma-Token': `${this._figmaToken}`,
		});
		headers.append('Access-Control-Allow-Origin', '*');
		const options = { headers };
		return this._http.get(`${this._baseUrl}/v1/files/${this._token}`, options);
	}

	private _getFigmaImage(
		ids: string,
		format: FileFormat = FileFormat.SVG
	): Observable<Object> {
		const headers = new HttpHeaders({
			'Content-Type': `${this._contentType}`,
			'X-Figma-Token': `${this._figmaToken}`,
		});
		headers.append('Access-Control-Allow-Origin', '*');
		const options = { headers };
		return this._http.get(
			`${this._baseUrl}/v1/images/${this._token}?ids=${ids}&format=${format}`,
			options
		);
	}

	private _collateFigmaDef(subFrame: any, temp: string[]) {
		const id = subFrame.id;
		const name = subFrame.name;

		const isNameExisting =
			this.figmaIcon.map((icon) => icon.name).indexOf(subFrame.name) !== -1;

		if (!isNameExisting) {
			this.figmaIconNames.push(name);
			this.figmaIcon.push({ id, name });
			temp.push(id);
		}
	}

	private _setFigmaIconURL(figmaIconURL: Record<string, string>) {
		this.figmaIconURL = figmaIconURL;
	}

	private _saveFigmaDefToLocalStorage() {
		localStorage.setItem(
			'figmaIconCount',
			String(Object.keys(this.figmaIconURL).length)
		);
		localStorage.setItem('figmaIcon', JSON.stringify(this.figmaIcon));
		localStorage.setItem('figmaIconNames', JSON.stringify(this.figmaIconNames));
		localStorage.setItem('figmaIconURL', JSON.stringify(this.figmaIconURL));
	}

	private _saveFigmaComponent(
		figmaComponentNames: string[],
		figmaComponentCount: number
	) {
		localStorage.setItem(
			'figmaComponentNames',
			String(figmaComponentNames.sort())
		);
		localStorage.setItem('figmaComponentCount', String(figmaComponentCount));
	}
}
