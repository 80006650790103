import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationTableComponent } from './company-permissions-table.component';
import { SharedModule } from '@app/shared/shared.module';





@NgModule({
  imports: [
    CommonModule,
   SharedModule
  ],
  exports:[
    ApplicationTableComponent
  ],
  declarations: [
    ApplicationTableComponent
  ]
})
export class CompanyPermissionsTableModule { }
