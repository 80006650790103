export const MAIN_COLOR = '#06bbf2';

export const colors = [
  '#e53935',
  '#c0ca33',
  '#8e24aa',
  '#00897b',
  '#3949ab',
  '#757575',
  '#ff6d00',
  '#ffd600',
  '#00bfa5'
]
