import { FormControl } from "@angular/forms";

/**
 * @deprecated
 * Use FormValidators.fileTypes instead
 */
export function requiredFileTypes(types: string[]) {
    return function (control: FormControl) {
      const file = control.value;
      if (file instanceof File) {
        const nameArray = file.name.split('.');
        const extension = nameArray[nameArray.length - 1].toLowerCase();
        
        if (!types.includes(extension)) {
          return { fileType: true };
        }
        
        return null;
      }
  
      return null;
    };
}