import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterChevronComponent } from './filter-chevron.component';

@NgModule({
	declarations: [FilterChevronComponent],
	imports: [CommonModule],
	exports: [FilterChevronComponent],
})
export class FilterChevronModule {}
