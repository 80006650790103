import { IFontTemplateFilter } from "../fonts-template-settings.interface";

import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  FONTS_TEMPLATE_SETTINGS_UPDATE = '[Fonts] Update',
  FONTS_TEMPLATE_SETTINGS_UPDATE_SUCCESS = '[Fonts] Update Success',
}
export const fontsTemplateSettingsUpdateAction =
createAction(
  ActionTypes.FONTS_TEMPLATE_SETTINGS_UPDATE,
  props<Partial<IFontTemplateFilter>>()
)
export const fontsTemplateSettingsUpdateSuccessAction =
createAction(
  ActionTypes.FONTS_TEMPLATE_SETTINGS_UPDATE_SUCCESS
)