import { UserProfileService } from './../../../modules/contacts/pages/profile/new-page/user-profile.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { SideBarService } from '../side-bar/side-bar.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';
import { Subject, BehaviorSubject, Subscription, take, filter } from 'rxjs';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store, select } from '@ngrx/store';
import { sidebarSelector } from '../side-bar/store/sidebar.selector';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit{
  @Input() menuId: number;
  subMenu: RoleMenuInterface[];
  activeMenu$ = new BehaviorSubject<number>(0);

  constructor(
    private _sideBarService: SideBarService,
    private _contactsService: ContactsService,
    private store: Store<AppStateInterface>
  ) {}

  ngOnInit(): void {
    // console.log(this._sideBarService.contents)
    this.store.pipe(
      select(sidebarSelector),
      filter((sidebar)=> sidebar.flatMenuAll !== null),
      take(1),
    ).subscribe((sidebar)=>{
      if(!sidebar.flatMenuAll) return;
      //console.log('sidebar 1', sidebar)
      this.subMenu = sidebar.flatMenuAll.find(
        (menu) => menu.id === this.menuId
      )?.subMenus!
      
      //subMenu.push(dummyData())
      //this.subMenu = subMenu;
      console.log('sidebar', this.subMenu)
    })
  }

  setParentMenu(id:number){
    this.activeMenu$.next(id);
  }

  setActiveMenu(id: number){
    this.activeMenu$.next(id);
  }
}

function dummyData():RoleMenuInterface{
  return{
    actions: [
      {
        actionId: 2,
        id: 154,
        isActive: true,
        //isAllowed: false,
        //isView: false,
        name: "Manage Agreement Types Access",
        url: ''
      }
    ],
    color: '',
    icon: '',
    figmaIconId: "23:3906",
    id: 101,
    isAdmin: true,
    isCustomRoute: true,
    isNavigatable: false,
    name: "Groups",
    order: 25,
    roleId: 1,
    subMenus: [],
    url: "contact-setting/notifications"
  }
}