import { createAction, props } from '@ngrx/store';
import { IJWT } from './jwt.interface';

export enum ActionTypes {
	JWT_UPDATE = '[JWT] Update',
	JWT_UPDATE_SUCCESS = '[JWT] Update Success',

	JWT_REMOVE = '[JWT] Remove',
	JWT_REMOVE_SUCCESS = '[JWT] Remove Success',
	TOPBAR_SET = '[TopBar] Set',

	JWT_WO_UPDATE = '[JWT] Update Without Effect',
}

export const jwtUpdateAction = createAction(
	ActionTypes.JWT_UPDATE,
	props<IJWT>()
);

export const jwtUpdateWoEffectAction = createAction(
	ActionTypes.JWT_WO_UPDATE,
	props<IJWT>()
);

export const jwtUpdateSuccessAction = createAction(
	ActionTypes.JWT_UPDATE_SUCCESS
);

export const jwtRemoveAction = createAction(ActionTypes.JWT_REMOVE);

export const jwtRemoveSuccessAction = createAction(
	ActionTypes.JWT_REMOVE_SUCCESS
);
