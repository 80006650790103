import { createSelector } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { AppStateInterface } from '@app/core/store/app-state.interface';

export const userAppCategoryListSelector = (
	state: AppStateInterface
): IPageState => state.userAppCategoryList;

export const pageSelector = createSelector(
	userAppCategoryListSelector,
	(state: IPageState) => state.page
);

export const pageSizeSelector = createSelector(
	userAppCategoryListSelector,
	(state: IPageState) => state.pageSize
);

export const orderSelector = createSelector(
	userAppCategoryListSelector,
	(state: IPageState) => state.order
);

export const columnSelector = createSelector(
	userAppCategoryListSelector,
	(state: IPageState) => state.column
);

export const querySelector = createSelector(
	userAppCategoryListSelector,
	(state: IPageState) => state.query
);
