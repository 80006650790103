import { Pipe, PipeTransform } from '@angular/core';
import { UserTypeArray, UserTypes } from '../constants';

@Pipe({
  name: 'mapUserRoleId'
})
export class MapUserRoleIdPipe implements PipeTransform {

  transform(value: UserTypes): unknown {
    if(!value){
      return null;
    }
    return UserTypeArray.find(user=>user.id === value)?.name
  }

}
