<div class="carbon-copy" *ngIf="hasUser">
  <span *ngIf="isShowOthers" class="carbon-copy__fullnames">
    {{summarizedUserEmails}}<span *ngIf="others">, and&nbsp;</span> <u
      [tooltipContentV2]="{ innerHtml: userAndExernalContactTooltip }"
      [tooltipPosition]="'top'"
      [textAlign]="'center'"

      class="q-pointer"
    >{{others}}</u>

    <!-- title="{{hiddenUsersTooltip}}" -->
  </span>

  <div class="carbon-copy__user-images">
    <div class="click-container q-pointer" (click)="openModal()"></div>
    <ng-container *ngFor="let image of usersFirstFourImages">
      <ng-container *ngIf="image.hasImage">
        <ng-container *ngTemplateOutlet="profile; context: {$implicit:image}"></ng-container>
      </ng-container>
      <ng-container *ngIf="!image.hasImage && !image.isAnonymous">
        <ng-container *ngTemplateOutlet="noProfile; context: {$implicit:image.initials, ctr:image.noProfileCtr}"></ng-container>
      </ng-container>
      <ng-container *ngIf="!image.hasImage && image.isAnonymous">
        <ng-container *ngTemplateOutlet="guest"></ng-container>
      </ng-container>
    </ng-container>

    <div class="hidden-users" *ngIf="noOfHiddenUsersImage > 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" fill="#C4E6F9" stroke="white" stroke-width="2"/>
      </svg>

      <span class="hidden-users__label"
        [class.single-digit]="noOfHiddenUsersImage < 10"
        [class.double-digit]="noOfHiddenUsersImage > 9"
        [class.triple-digit]="noOfHiddenUsersImage > 99"
      >+{{noOfHiddenUsersImage}}</span>
    </div>
  </div>
</div>


<ng-template #profile let-user>
  <img 
    [src]="user.imagePath" 
    [alt]="user.fullName"
  >
</ng-template>

<ng-template #guest>
  <div class="guest">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="20" cy="20" r="19" fill="#DFE0E2" stroke="#FCFCFC" stroke-width="2"/>
    </svg>
    <mat-icon svgIcon="user-circle"></mat-icon>
  </div>
</ng-template>

<ng-template #noProfile let-initials let-ctr="ctr">
  <no-profile-avatar
    [initials]="initials | uppercase"
    [ctr]="ctr"
  ></no-profile-avatar>
</ng-template>