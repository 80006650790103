import { Injectable } from '@angular/core';

import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
} from '@microsoft/signalr';
import { from, Subject, Subscription, timer } from 'rxjs';

import { environment } from 'environments/environment';

import { UserService } from '@app/core/services/user.service';

import { INewComment } from '../new-table-shared/new-table-shared.interface';
import { TableSignalType } from '../constants/global-enum';

@Injectable({
	providedIn: 'root',
})
export class NotificationsClienthubService {
	public bannerNotificationReceived = new Subject<any>();
	public messageNotificationReceived = new Subject<any>();
	public smsMobileNotificationReceived = new Subject<any>();
	public newTicketCommentNotificationReceived = new Subject<INewComment>();
	public maintenanceNotificationReceived = new Subject<any>();
	public maintenanceBannerNotificationReceived = new Subject<any>();
	public tableReloadNotificationReceived = new Subject<any>();

	_hub?: HubConnection;
	_reconnectWhenClosed: boolean = true;
	_reconnect_interval: number = 30000; //30 seconds
	_reconnectSub?: Subscription;

	constructor(private userService: UserService) {}

	connect() {
		let currentUser = this.userService.user;
		const sessionId = !currentUser ? null : this.userService.sessionId;

		if (this._hub && this._hub.state != HubConnectionState.Disconnected) return;

		this._reconnectWhenClosed = true;
		this._hub = new HubConnectionBuilder()
			.withUrl(
				currentUser
					? `${environment.apiNotificationHub}?userId=${currentUser?.id}&roleId=${currentUser?.roleId}&sessionId=${sessionId}`
					: environment.apiNotificationHub
			)
			.withAutomaticReconnect()
			.build();

		if (this._hub) {
			from(this._hub.start()).subscribe({
				next: (s) => {
					this.configActiveConnection();
				},
				error: (err) => {
					this.Reconnect();
				},
			});
		}
	}

	private configActiveConnection() {
		//stop when connected
		this.stopReconnect();

		//reconnect when closed
		this._reconnectWhenClosed = true;
		this._hub!.onclose((s) => {
			if (this._reconnectWhenClosed) this.Reconnect();
		});

		this._hub!.on('BannerNotification', (s) =>
			this.bannerNotificationReceived.next(s)
		);
		this._hub!.on('MessageNotification', (s) =>
			this.messageNotificationReceived.next(s)
		);
		this._hub!.on('MobileOptInNotification', (s) =>
			this.smsMobileNotificationReceived.next(s)
		);
		this._hub!.on('UpdateTicketNotification', (notification: INewComment) =>
			this.newTicketCommentNotificationReceived.next(notification)
		);
		this._hub!.on('MaintenanceNotification', (s) =>
			this.maintenanceNotificationReceived.next(s)
		);
		this._hub!.on('MaintenanceBannerNotification', (s) =>
			this.maintenanceBannerNotificationReceived.next(s)
		);
		this._hub!.on('InvoiceStatusNotification', (s) => 
			this.tableReloadNotificationReceived.next({ 
				data: s, 
				type: TableSignalType.INVOICE 
			})
		);
		this._hub!.on('OrderStatusNotification', (s) => 
			this.tableReloadNotificationReceived.next({ 
				data: s, 
				type: TableSignalType.ORDER
			 })
		);
		this._hub!.on('QuoteStatusNotification', (s) => 
			this.tableReloadNotificationReceived.next({ 
				data: s, 
				type: TableSignalType.QUOTE
			 })
		);
		this._hub!.on('TicketStatusNotification', (s) => 
			this.tableReloadNotificationReceived.next({ 
				data: s, 
				type: TableSignalType.TICKET
			})
		);
	}

	private Reconnect() {
		if (this._reconnectSub) this._reconnectSub.unsubscribe();
		this._reconnectSub = timer(this._reconnect_interval).subscribe((s) => {
			if (this._reconnectWhenClosed) this.connect();
		});
	}

	private stopReconnect() {
		if (this._reconnectSub) this._reconnectSub.unsubscribe();
		this._reconnectSub = undefined;
	}

	disconnect() {
		this._reconnectWhenClosed = false;
		this.stopReconnect();
		if (this._hub && this._hub.state != HubConnectionState.Disconnected) {
			this._hub.stop();
		}
		this._hub = undefined;
	}
}
