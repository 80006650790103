import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-ticket-pdf',
  templateUrl: './view-ticket-pdf.component.html',
  styleUrls: ['./view-ticket-pdf.component.scss']
})

export class ViewTicketPdfComponent implements OnInit, OnDestroy {
  private _$unsubscribe: Subject<void> = new Subject<void>();
  pdfSrc: any;
  ticketNumber: any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _router: Router
  ) {}

  ngOnInit() {
    this.initQueryParam();
  }

  initQueryParam() {
    this._activatedRoute.queryParamMap
    .pipe(takeUntil(this._$unsubscribe), take(1))
    .subscribe({
      next: params => {
        this.pdfSrc = this.domSanitize(params.get('pdfUrl') as string + '#zoom=150');
        this.ticketNumber = params.get('id') as string;
      }
    });
  }

  domSanitize(value: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  onClickClose() {
    this._router.navigate([`/service-and-support/tickets/view/${this.ticketNumber}`]);
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
