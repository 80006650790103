/* Angular Libraries */ 
import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/* Third Party Libraries */ 
import { BehaviorSubject, Observable, of } from 'rxjs';

/* Services */
import { UserService } from '@app/core/services/user.service';

/* Interfaces */
import { 
	AutopayPaymentMethod, 
	BankInformation, 
	CardAndBankMethod, 
	CardInformation, 
	CreditsData, 
	InvoiceSummary, 
	PaymentBreakdown, 
	PaymentIntentStatus, 
	PaymentMethod, 
	RequestPayment, 
	States, 
	StripePublishable 
} from '../interfaces/invoice.interface';
import { UserTypes } from '../constants/global-enum';

/* Environment */ 
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class StripeService {
	private _selectedInvoice: BehaviorSubject<Array<InvoiceSummary>> = new BehaviorSubject<Array<InvoiceSummary>>([]);
	public selectedInvoiceRef = this._selectedInvoice.asObservable();

	private _openTermsCondition: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public openTermsCondition = this._openTermsCondition.asObservable();

	constructor(
		private _http: HttpClient, 
		private _userService: UserService
	) {}

	get isAdmin() {
		return this._userService.userRole === UserTypes.SourcepassAdmin;
	}

	get currentCompanyId() {
		return this._userService.companyId;
	}

	get selectedCompanyId() {
		return JSON.parse(localStorage.getItem(this.isAdmin ? 'companyAdmin' : 'companyUser') as any)?.companyId;
	}

	get companyIdType() {
		return this.isAdmin ? this.selectedCompanyId : this.currentCompanyId === this.selectedCompanyId ? null : this.selectedCompanyId;
	}

	setSelectedInvoice(selectedInvoice: Array<InvoiceSummary>) {
		this._selectedInvoice.next(selectedInvoice);
	}

	openTermsAndCondition() {
		this._openTermsCondition.next(true);
	}

	getStripePublishableKey(): Observable<StripePublishable> {
		return this._http.get<StripePublishable>(
			`${environment.apiBaseUrl}AzureKeyVault/GetStripePublishableKey`
		);
	}

	requestPayment(body: Params): Observable<RequestPayment> {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});

		return this._http.post<RequestPayment>(
			`${environment.apiBaseUrl}Invoices/RequestPayment`,
			body,
			{ params: param }
		);
	}

	requestPaymentIntent(body: Params) {
		return this._http.post(
			`${environment.apiBaseUrl}Invoices/RequestPaymentIntent`,
			body
		);
	}

	getPaymentMethodList(): Observable<PaymentMethod> {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.get<PaymentMethod>(
			`${environment.apiBaseUrl}Stripe_Customer/GetPaymentMethodList`,
			{ params: param }
		);
	}

	getStripeCards(id: string): Observable<CardInformation> {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.get<CardInformation>(`${environment.apiBaseUrl}Stripe_Cards/${id}`, { params: param });
	}

	addStripeCards(body: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.post(`${environment.apiBaseUrl}Stripe_Cards`, body, { params: param });
	}

	updateStripeCards(body: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.put(
			`${environment.apiBaseUrl}Stripe_Cards/${body.id}`,
			body,
			{ params: param }
		);
	}

	deleteStripeCards(params: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});

		return this._http.delete(
			`${environment.apiBaseUrl}Stripe_Cards/${params.id}`,
			{ params: param }
		);
	}

	getStripeBankds(id: string): Observable<BankInformation> {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.get<BankInformation>(`${environment.apiBaseUrl}Stripe_Banks/${id}`, { params: param });
	}

	addStripeBanks(body: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.post(`${environment.apiBaseUrl}Stripe_Banks`, body, { params: param });
	}

	updateStripeBanks(params: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.put(
			`${environment.apiBaseUrl}Stripe_Banks/${params.id}`,
			params,
			{ params: param }
		);
	}

	deleteStripeBanks(params: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});

		return this._http.delete(
			`${environment.apiBaseUrl}Stripe_Banks/${params.id}`,
			{ params: param }
		);
	}

	verifyBankAccount(body: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});

    return this._http.post(
      `${environment.apiBaseUrl}Stripe_Banks/VerifyBankAccount`, body, { params: param }
    );
  }

	setDefaultPaymentMethod(body: any) {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});

		return this._http.put(
			`${environment.apiBaseUrl}Stripe_Customer/SetDefaultPaymentMethod`,
			body,
			{ params: param }
		);
	}

	getCreditMemoList(
		page: number,
		pageSize: number,
		column: string,
		order: string,
		searchText: string,
	): Observable<CreditsData> {
		const param: any = this.getValidParameters({
			Page: page,
			PageSize: pageSize,
			Column: column,
			Order: order.toUpperCase(),
			Search: searchText,
			companyId: this.companyIdType
		});

		return this._http.get<CreditsData>(
			`${environment.apiBaseUrl}Invoices/GetCreditMemoByCompany`,
			{ params: param }
		);
	}

	getPendingPayment(ids: any) {
		const body = { ids: ids };
		return this._http.post(
			`${environment.apiBaseUrl}Invoices/GetPendingPaymentAmount`,
			body
		);
	}

	getStatesForUnitedStates(): Observable<States> {
		return of([
			{
				name: 'Alabama',
				abbreviation: 'AL',
			},
			{
				name: 'Alaska',
				abbreviation: 'AK',
			},
			{
				name: 'American Samoa',
				abbreviation: 'AS',
			},
			{
				name: 'Arizona',
				abbreviation: 'AZ',
			},
			{
				name: 'Arkansas',
				abbreviation: 'AR',
			},
			{
				name: 'California',
				abbreviation: 'CA',
			},
			{
				name: 'Colorado',
				abbreviation: 'CO',
			},
			{
				name: 'Connecticut',
				abbreviation: 'CT',
			},
			{
				name: 'Delaware',
				abbreviation: 'DE',
			},
			{
				name: 'District Of Columbia',
				abbreviation: 'DC',
			},
			{
				name: 'Federated States Of Micronesia',
				abbreviation: 'FM',
			},
			{
				name: 'Florida',
				abbreviation: 'FL',
			},
			{
				name: 'Georgia',
				abbreviation: 'GA',
			},
			{
				name: 'Guam',
				abbreviation: 'GU',
			},
			{
				name: 'Hawaii',
				abbreviation: 'HI',
			},
			{
				name: 'Idaho',
				abbreviation: 'ID',
			},
			{
				name: 'Illinois',
				abbreviation: 'IL',
			},
			{
				name: 'Indiana',
				abbreviation: 'IN',
			},
			{
				name: 'Iowa',
				abbreviation: 'IA',
			},
			{
				name: 'Kansas',
				abbreviation: 'KS',
			},
			{
				name: 'Kentucky',
				abbreviation: 'KY',
			},
			{
				name: 'Louisiana',
				abbreviation: 'LA',
			},
			{
				name: 'Maine',
				abbreviation: 'ME',
			},
			{
				name: 'Marshall Islands',
				abbreviation: 'MH',
			},
			{
				name: 'Maryland',
				abbreviation: 'MD',
			},
			{
				name: 'Massachusetts',
				abbreviation: 'MA',
			},
			{
				name: 'Michigan',
				abbreviation: 'MI',
			},
			{
				name: 'Minnesota',
				abbreviation: 'MN',
			},
			{
				name: 'Mississippi',
				abbreviation: 'MS',
			},
			{
				name: 'Missouri',
				abbreviation: 'MO',
			},
			{
				name: 'Montana',
				abbreviation: 'MT',
			},
			{
				name: 'Nebraska',
				abbreviation: 'NE',
			},
			{
				name: 'Nevada',
				abbreviation: 'NV',
			},
			{
				name: 'New Hampshire',
				abbreviation: 'NH',
			},
			{
				name: 'New Jersey',
				abbreviation: 'NJ',
			},
			{
				name: 'New Mexico',
				abbreviation: 'NM',
			},
			{
				name: 'New York',
				abbreviation: 'NY',
			},
			{
				name: 'North Carolina',
				abbreviation: 'NC',
			},
			{
				name: 'North Dakota',
				abbreviation: 'ND',
			},
			{
				name: 'Northern Mariana Islands',
				abbreviation: 'MP',
			},
			{
				name: 'Ohio',
				abbreviation: 'OH',
			},
			{
				name: 'Oklahoma',
				abbreviation: 'OK',
			},
			{
				name: 'Oregon',
				abbreviation: 'OR',
			},
			{
				name: 'Palau',
				abbreviation: 'PW',
			},
			{
				name: 'Pennsylvania',
				abbreviation: 'PA',
			},
			{
				name: 'Puerto Rico',
				abbreviation: 'PR',
			},
			{
				name: 'Rhode Island',
				abbreviation: 'RI',
			},
			{
				name: 'South Carolina',
				abbreviation: 'SC',
			},
			{
				name: 'South Dakota',
				abbreviation: 'SD',
			},
			{
				name: 'Tennessee',
				abbreviation: 'TN',
			},
			{
				name: 'Texas',
				abbreviation: 'TX',
			},
			{
				name: 'Utah',
				abbreviation: 'UT',
			},
			{
				name: 'Vermont',
				abbreviation: 'VT',
			},
			{
				name: 'Virgin Islands',
				abbreviation: 'VI',
			},
			{
				name: 'Virginia',
				abbreviation: 'VA',
			},
			{
				name: 'Washington',
				abbreviation: 'WA',
			},
			{
				name: 'West Virginia',
				abbreviation: 'WV',
			},
			{
				name: 'Wisconsin',
				abbreviation: 'WI',
			},
			{
				name: 'Wyoming',
				abbreviation: 'WY',
			},
		]);
	}

	getPaymentIntentRedirectUrl(paymentIntentId: string) {
		return this._http.get(`${environment.apiBaseUrl}Invoices/GetPaymentIntentRedirectUrl/${paymentIntentId}`);
	}

	getPaymentIntentStatus(paymentIntentId: string): Observable<PaymentIntentStatus> {
		return this._http.get<PaymentIntentStatus>(`${environment.apiBaseUrl}Invoices/GetPaymentIntentStatus/${paymentIntentId}`);
	}

	cancelRequiresActionPaymentIntent(paymentIntentId: string) {
		return this._http.post(`${environment.apiBaseUrl}Invoices/CancelRequiresActionPaymentIntent/${paymentIntentId}`, {});
	}

	cancelAllRequiresAction() {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.post(`${environment.apiBaseUrl}Invoices/CancelAllRequiresAction`, { params: param });
	}

	SetAutopayPaymentMethod(body: AutopayPaymentMethod) {
		const payload: AutopayPaymentMethod = {
			paymentMethodId: body.paymentMethodId,
			isAutopay: body.isAutopay
		};

		const param: any = this.getValidParameters({ companyId: this.companyIdType});
		return this._http.post(`${environment.apiBaseUrl}Invoices/SetAutopayPaymentMethod`, payload, { params: param });
	}

	getAutopayPaymentMethodDropdown(): Observable<Array<CardAndBankMethod>> {
		const param: any = this.getValidParameters({ companyId: this.companyIdType});

		return this._http.get<Array<CardAndBankMethod>>(
			`${environment.apiBaseUrl}Invoices/GetAutopayPaymentMethodDropdown`,
			{ params: param }
		);
	}

	getValidParameters(param: any) {
		return Object.fromEntries(Object.entries(param).filter(([key, value]: any) => !['', null, undefined].includes(value)));
	}
}
