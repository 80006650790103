import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[customSelect]'
})
export class CustomSelectDirective {

  @HostBinding('class.active') isOpen = false;
  constructor(public elRef: ElementRef, private renderer: Renderer2) {  }

  @Input('pages') pages: number | undefined = undefined;


  @HostListener('click') toggleOpen(){
    this.isOpen = !this.isOpen;
    if(this.isOpen === false){
      this.renderer.setStyle(this.elRef.nativeElement.nextSibling, 'display', 'none')
    }else if(this.isOpen === true && (this.pages == undefined || this.pages > 1)){
      this.renderer.setStyle(this.elRef.nativeElement.nextSibling, 'display', 'block')
      const divContainer = this.elRef.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement as HTMLElement;
      divContainer.scrollTop = divContainer.scrollHeight;//(0, divCont2.scrollHeight);
    }else{
      this.isOpen = !this.isOpen;
    }
  }
}