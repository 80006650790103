import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FigmaIconImgModule } from './../../../figma-icon-img/figma-icon-img.module';

import { ModalComponent } from './modal.component';

@NgModule({
	declarations: [ModalComponent],
	imports: [CommonModule, FigmaIconImgModule],
	exports: [ModalComponent],
})
export class ModalModule {}
