import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSort, SortDirection } from '@angular/material/sort';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';

import { Order, OrderInquiry } from '@app/shared/interfaces/order.interface';

import { SpinnerService } from '@app/core/services/spinner.service';
import { QuotesOrdersService } from '../../quotes-and-orders.service';
import { NotificationService } from '@app/core/services/notification.service';

import * as moment from 'moment';
import { Location } from '@angular/common';
import { NotificationMessages } from '@app/shared/constants';

@Component({
  selector: 'app-orders-inquiry',
  templateUrl: './orders-inquiry.component.html',
  styleUrls: ['./orders-inquiry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersInquiryComponent implements AfterViewInit, OnInit {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileUploadInput') fileUploadInput!: ElementRef;

  form = new FormGroup({
    id: new FormControl(''),
    salesOrder: new FormControl(''),
    customerPO: new FormControl(''),
    description: new FormControl(''),
    orderDate: new FormControl(''),
    subtotal: new FormControl(''),
    total: new FormControl(''),
    taxTotal: new FormControl(''),
    status: new FormControl(''),
  });

  formNote = new FormGroup({
    note: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
    files: this._fb.array([])
  });

  id: number;

  constructor(
    public spinner: SpinnerService,
    private _cd: ChangeDetectorRef,
    private _quotesOrdersService: QuotesOrdersService,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _notifService: NotificationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private location: Location,
  ) {
    this.id = Number(this._activatedRoute.snapshot.paramMap.get('id'));
    this._activatedRoute.queryParams.subscribe((param) => {
      if (this._activatedRoute.parent) {
        this._activatedRoute.snapshot.data['title'] = param['name'];
      }
    });
  }

  ngOnInit() {
    this.spinner.start();
  }

  ngAfterViewInit() {
    this._getOrderDetails();
  }

  formatDate(val: string) {
    return val ? moment(val).format('MMM DD, YYYY') : '';
  }

  private _getOrderDetails() {
    this._quotesOrdersService.getOrderDetails(this.id).subscribe({
      next: (resp: Order) => {
        this._setData(resp);
        this.spinner.stop();
      },
      error: (error) => {
        this.spinner.stop();
      },
    });
  }

  private _setData(resp: any) {
    this.form = this._fb.group({
      id: [resp.id],
      salesOrder: [resp.salesOrder],
      customerPO: [resp.customerPO],
      description: [resp.description],
      orderDate: [this.formatDate(resp.orderDate)],
      subtotal: [resp.subtotal],
      total: [resp.total],
      taxTotal: [resp.taxTotal],
      status: [resp.status],
    });

    this._cd.markForCheck();
  }
  
  onGoBack() {
    this.location.back();
  }

  submit(){
    if(this.formNote.invalid) {
      this.formNote.markAllAsTouched();
      return;
    }

    this.spinner.start();

    const id = this.form.value.id;
    const form = this.formNote.value;
    this._quotesOrdersService.submitInquiry(id, form as OrderInquiry).subscribe({
      next: res => {
        this.spinner.reset(),
        this._notifService.notify(NotificationMessages.OrderInquirySuccessSubmitted, {duration: 5, panelClass: 'success'}),
        this.location.back()
      },
      error: error => {
        this.spinner.stop(),
        this._notifService.notify(NotificationMessages.OrderInquiryFailedSubmit, {duration: 8, panelClass: 'error'})
      }
    })
  }

  get fileFormArray(): FormArray {
    return this.formNote.get('files') as FormArray;
  }

  onAddFile(evt: any){
    this.fileAdded(evt.target.files);
  }

  fileAdded(files: any) {
    const fileForm = this.fileFormArray;
    Array.from(files).forEach(file => {
      fileForm.push(this._fb.control(file))
    });
  }

  removeFile(index: number){
    const fileForm = this.fileFormArray;
    fileForm.removeAt(index);
  }
}
