import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject, Subject } from 'rxjs';

import { NotificationService } from '@app/core/services/notification.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';

import { NotificationMessages } from '@app/shared/constants';

@Component({
	selector: 'sms-verification-modal',
	templateUrl: './sms-verification-modal.component.html',
	styleUrls: ['./sms-verification-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsVerificationModalComponent implements OnInit, OnDestroy {
	code = new FormControl('', [Validators.required, Validators.minLength(6)]);
	phone: string;
	isVerifying$ = new Subject<boolean>();
	isSedingCode$ = new BehaviorSubject<boolean>(true);
	isInvalidCode$ = new BehaviorSubject<boolean>(false);
	private _userCodeId: number;

	private _inputs: any[];
	private _destroy$ = new Subject();

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: SmsVerifModalData,
		private _dialogRef: MatDialogRef<SmsVerificationModalComponent>,
		private _notifier: NotificationService,
		private _contactsService: ContactsService
	) {}

	ngOnInit(): void {
		const ps = this._data.phone?.toString();
		if (ps) this.phone = ps.length > 4 ? ps.substring(ps.length - 4) : ps;
		this.sendCode();
	}

	ngOnDestroy(): void {
		this._destroy$.next(null);
		this._destroy$.complete();
	}

	verify() {
		if (this.code.invalid) return;

		this.isVerifying$.next(true);
		this._contactsService
			.verifyPhoneNumber(this.code.value!, this._userCodeId, this._data.userId)
			.subscribe({
				next: (res) => {
					this._dialogRef.close(true);
					this._notifier.notifySuccess('Mobile number verified');
				},
				error: (err) => {
					this.isVerifying$.next(false);
					if (err.status === 409) {
						// Already verified
						this._dialogRef.close(true);
						this._notifier.notifySuccess('Mobile number verified');
					} else if (err.status === 400) {
						this.isInvalidCode$.next(true);
						this._clearCode();
					} else
						this._notifier.notifyError(
							NotificationMessages.Unknown,
							NotificationMessages.Try
						);
				},
			});
	}

	sendCode() {
		this.isSedingCode$.next(true);
		this._contactsService
			.sendVerificationCode(this._data.phone, this._data.userId)
			.subscribe({
				next: (res) => {
					this._userCodeId = res;
					this.isSedingCode$.next(false);
					this.isInvalidCode$.next(false);
					this._clearCode();
				},
				error: (err) => {
					if (err.status === 409) {
						// Already verified
						this._dialogRef.close(true);
					} else {
						this._dialogRef.close();
						if (err.status != 400)
							this._notifier.notifyError(
								NotificationMessages.Unknown,
								NotificationMessages.RefreshTry
							);
					}
				},
			});
	}

	codeChange(index: number, event: any) {
		if (!this._inputs)
			this._inputs = Array.from(document.querySelectorAll('input.code-input'));
		if (this.isInvalidCode$.getValue()) this.isInvalidCode$.next(false);

		const value: string = event.target.value;
		if (event.data && value.length <= 1) this._inputs[index + 1]?.focus();
		else if (event.keyCode === 8) this._inputs[index - 1]?.focus();
		else if (value.length > 1) {
			value.split('').forEach((v) => {
				const input = this._inputs[index];
				if (input) (input.value = v), this._inputs[index + 1]?.focus();
				index++;
			});
		}
		this.code.setValue(this._inputs.map((i) => i.value).join(''));
	}

	private _clearCode() {
		if (!this._inputs) return;
		this._inputs.forEach((i) => (i.value = ''));
		this._inputs[0]?.focus();
		this.code.setValue('');
		setTimeout(() => this._inputs[0]?.focus(), 100);
	}
}

interface SmsVerifModalData {
	userId?: number;
	phone?: number;
}
