import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SpinnerService } from '@app/core/services/spinner.service';
import { FinanceService } from '@app/modules/finance/finance.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements OnInit {
  pdfSrc: string;
  invoiceNumber: string;

  private _id: number;

  constructor(
    public spinner: SpinnerService,
    private _activatedRoute: ActivatedRoute,
    private _financeServce: FinanceService,
    private _toastMessageService: ToastMessageService,
    private _cd: ChangeDetectorRef
  ) {
    this._id = Number(this._activatedRoute.snapshot.paramMap.get('id'));
  }

  get isInvoiceType(): boolean {
    return this._activatedRoute.snapshot.queryParamMap.get('isInvoiceType') === 'true';
  }

  async ngOnInit() {
    if (this._id) {
      this.spinner.start();
      await new Promise((resolve) => {
        this._financeServce.getInvoiceById(this._id).subscribe((resp: any) => {
          this.invoiceNumber = resp.invoiceNumber;
          resolve(true);
        });
      });

      let pdf = this._financeServce.pdf$.getValue() 
      if(pdf){
        this.pdfSrc = pdf;
        this._cd.markForCheck();
        this.spinner.stop();
      }else{
        this._financeServce.downloadInvoice2(this._id)
          .pipe(finalize(() => this.spinner.stop()))
          .subscribe({
            next: (data) => {
              /* const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/pdf' })
              ); */
              this.pdfSrc = data;
              this._cd.markForCheck();
            },
            error: (error) => {
              this._toastMessageService.showErrorMessage(error);
            },
          });
      }

    }
  }


}
