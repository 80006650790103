import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { UserTypes } from '@app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class UserTypeGuard implements CanActivate {
  constructor(private _userService: UserService, private navigationService: NavigationService){}
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const requiredUserTypes = route.data['userTypes'],
    roleId = this._userService.userRole,
    systemRoleId = this._userService.user?.systemRoleId,
    switchRole = roleId != systemRoleId ? systemRoleId : UserTypes.User;

    if(requiredUserTypes.includes(roleId)) return true;
    // auto switch role
    else if(requiredUserTypes.includes(switchRole)) {
      if(!sessionStorage.getItem('switchingRole')) {
        sessionStorage.setItem('switchingRole', 'true');
        await this._userService.switchUserRole(false);
        sessionStorage.removeItem('switchingRole');
          
        if(this._userService.userRole === switchRole) return true;
        else {
          this.navigationService.smartNavigate();
          return false;
        }
      }
      else return true;
    }
    else {
      this.navigationService.smartNavigate();
      return false;
    }
  }

}
