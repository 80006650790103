<div class="q-d-flex q-f-column q-g-16" [class.q-op-0]="isSedingCode$ | async">
	<div class="q-d-flex q-g-16 q-ai-center">
		<mat-icon svgIcon="phone" class="q-mr-4"></mat-icon>
		<h3 class="q-f-1 ellipsis">Enter Verification Code</h3>
		<mat-icon
			svgIcon="x-close"
			class="q-pointer q-as-baseline"
			mat-dialog-close
		></mat-icon>
	</div>

	<div
		class="q-modal-content q-scroll qs-floating q-d-flex q-f-column q-g-32"
		qScroll
	>
		<p class="font-b1">
			A six-digit code was sent to your mobile phone{{
				phone ? ', ending in ****' + phone : ''
			}}. If you don’t see the code after a few seconds, resend the code below
			to issue a new one. This code will expire in 5 minutes.
		</p>

		<div class="form-group-2">
			<label class="q-d-flex q-f-wrap q-ai-center q-cg-16 font-b1 font-w-600">
				Six-Digit Verification Code
				<span
					*ngIf="isInvalidCode$ | async"
					class="q-d-flex q-ai-center q-g-8 font-b3"
				>
					<mat-icon svgIcon="alert-triangle"></mat-icon>
					Please try the code again
				</span>
			</label>
			<div class="q-d-flex q-g-16 q-f-wrap q-p-8 q-pr-0 q-pl-0">
				<input
					*ngFor="let n of [0, 1, 2, 3, 4, 5]"
					(input)="codeChange(n, $event)"
					(keyup.backspace)="codeChange(n, $event)"
					(keyup.enter)="verify()"
					[class.invalid]="isInvalidCode$ | async"
					[disabled]="isVerifying$ | async"
					class="code-input font-h1"
					placeholder="-"
					type="number"
					name="code"
					required
				/>
			</div>
		</div>
	</div>

	<div class="actions-wrapper q-pt-16">
		<button
			class="btn-2 btn-2-tertiary"
			(click)="sendCode()"
			[disabled]="(isSedingCode$ | async) || (isVerifying$ | async)"
			role="button"
		>
			Resend Code
		</button>
		<button
			class="btn-2 btn-2-primary"
			(click)="verify()"
			[disabled]="code.invalid || (isVerifying$ | async)"
		>
			{{ (isVerifying$ | async) ? '. . . Verifying' : 'Verify' }}
		</button>
	</div>
</div>

<div
	*ngIf="isSedingCode$ | async"
	class="sending-state q-d-flex q-f-column q-ai-center q-g-32"
>
	<mat-icon svgIcon="loading-01" class="loading-v2"></mat-icon>
	<h3>Sending Verification Code...</h3>
</div>
