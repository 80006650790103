import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[typing-not-allowed]',
})
export class TypingNotAllowedDirective {
	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		event.preventDefault();
	}
}
