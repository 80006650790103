
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})

export class AISummaryService {
  private _initTicketDetail: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
	public initTicketDetail = this._initTicketDetail.asObservable();

  public setTicketId(ticketId: number | null) {
		this._initTicketDetail.next(ticketId);
	}

  public getFullDate(d: any | null = null) {
		return (d ? new Date(d) : new Date()).toLocaleDateString();
	}
}
