<div class="checkbox-list-container" *ngIf="options">
  <ul class="select-all" *ngIf="filteredOptions.length > 1">
    <li (click)="allChecked = !allChecked;selectAll();$event.stopPropagation()">
      <input type="checkbox"  [(ngModel)]="allChecked" (ngModelChange)="selectAll()">Select All
    </li>
  </ul>
  <ul class="list-items">
    <li *ngFor="let option of filteredOptions; let i = index" (click)="setOptionValue(i)">
      <input type="checkbox"  [(ngModel)]="option.checked" (ngModelChange)="filterOptions(i)">{{option.name | trim: 40}}
    </li>
  </ul>
</div>
