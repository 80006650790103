import { formatDate } from '@angular/common';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export function toFormData<T>(formValue: T) {
	const formData = new FormData();

	for (const key of Object.keys(formValue)) {
		let value = (formValue as any)[key];

		if (value && (value[0] instanceof File || value instanceof File)) {
			formData.append(key, value);
			continue;
		}

		if (value && typeof value === 'object' && !(value[0] instanceof File)) {
			for (let index = 0; index < value.length; index++) {
				const arrValue = value[index];
				const isNumberArray = value.every((val: any) => {
					return typeof val === 'number';
				});

				if (isNumberArray) {
					formData.append(key, arrValue);
					continue;
				}

				for (const property in arrValue) {
					if (Object.prototype.hasOwnProperty.call(arrValue, property)) {
						const val = arrValue[property];
						formData.append(`${key}[${index}][${property}]`, val);
					}
				}
			}

			continue;
		}

		formData.append(key, value);
	}

	return formData;
}

/**
 * Filter unique value in an array.
 * @example Array.filter(unique)
 */
export function unique<T>(value: T, index: number, self: T[]) {
	return self.indexOf(value) === index;
}

/**Deep clone object*/
export function clone<T>(object: T): T {
	return JSON.parse(JSON.stringify(object));
}

/**
 * Format query parameters.
 * @example {id: 1, name: 'Sourcepass', categoryId: [1,2,3]}
 * result: '?id=1&name=Sourcepass&categoryId=1,2,3'
 */
export function formatQueryParams(object: Object): string {
	let query = '?';
	Object.entries(object).forEach(([key, value], i) => {
		if (
			value !== undefined &&
			value !== null &&
			value !== '' &&
			(!Array.isArray(value) || value.length)
		)
			query += `${i != 0 ? '&' : ''}${key}=${value}`;
	});
	return query;
}

/**
 * Format query parameters.
 * @example {id: 1, name: 'Sourcepass', categoryId: [1,2,3]}
 * result: '?id=1&name=Sourcepass&categoryId=1&categoryId=2&categoryId=3'
 */
export function toQueryParams(object: Object): string {
	let query = '?';
	Object.entries(object).forEach(([key, value], i) => {
		if (
			value !== undefined &&
			value !== null &&
			value !== '' &&
			!Array.isArray(value)
		)
			query += `${i != 0 ? '&' : ''}${key}=${value}`;
		else if (Array.isArray(value) && value.length)
			value.forEach((v) => (query += `${i != 0 ? '&' : ''}${key}=${v}`));
	});
	return query;
}

export function startsWithHttp(url: string) {
	if (!url) return false;

	var pattern = /^((http|https):\/\/)/;
	return pattern.test(url);
}

export function formatNote(note: string) {
	//Replace html
	note = note.replace(/\</g, '&lt;');
	note = note.replace(/\>/g, '&gt;');

	// Trademark
	const tm = '<sup>TM</sup>';
	(note = note.replace(/\™/g, tm)),
		(note = note.replace(/\&trade;/g, tm)),
		(note = note.replace(/\&#8482;/g, tm));

	// Bold
	const boldRegex = /\*\*([^*\n]*(?:\*(?!\*)[^*\n]*)*)\*\*/g;
	const bold = '<b>$1</b>';
	note = note.replace(boldRegex, bold);

	// Italic
	const italicRegex = /\_([^_\n]*(?:\*(?!\*)[^_\n]*)*)\_/g;
	const italic = '<em>$1</em>';
	note = note.replace(italicRegex, italic);

	// Code
	// const codeRegex = /\`([^`\n]*(?:\*(?!\*)[^`\n]*)*)\`/g;
	// const code = '<code class="font-b2">$1</code>';
	// note = note.replace(codeRegex, code);

	const lineBreaks = note.split('\n');
	const h1 = '# ';
	const h2 = '## ';
	const bullet = '- ';

	lineBreaks.forEach((line, i) => {
		if (line.trim().startsWith(h1))
			lineBreaks[i] =
				line.replace(h1, '<h5 class="font-b1 font-w-600">') + '</h5>';
		else if (line.trim().startsWith(h2))
			lineBreaks[i] = line.replace(h2, '<h5 class="font-b2">') + '</h5>';
		else if (line.trim().startsWith(bullet))
			lineBreaks[i] = line.replace(bullet, '<li class="font-b1">') + '</li>';
		else
			lineBreaks[i] =
				'<p class="font-b1">' + (line.trim().length ? line : '&nbsp;') + '</p>';
	});

	return lineBreaks.join('\n');
}

/**
 * Converts date to SQL UTC date
 */
export function toUTCSQLDate(date: Date) {
	return date.toISOString().slice(0, 19).replace('T', ' ');
}

/**
 * Converts SQL UTC date string to Date
 */
export function sqlUTCStringToDate(date: string) {
	return new Date(date.replace('T', ' ') + ' UTC');
}

/**
 * Converts date to SQL Locale date
 */
export function toSQLDate(date: Date | string, withTime = true) {
	return formatDate(date, withTime ? 'y-MM-dd HH:mm:ss' : 'y-MM-dd', 'en-US');
}

/**
 * Converts date to SQL Locale time
 */
export function toSQLTime(date: Date | string) {
	return formatDate(date, 'HH:mm:ss', 'en-US');
}

/**
 * Converts enum to NameId
 */
export function enumToDropdown(e: any) {
	const nameId = [];
	for (let s in e) {
		if (isNaN(Number(s))) nameId.push({ name: s, id: Number(e[s]) });
	}
	return nameId;
}

export function isValueEmpty(value: any): boolean {
	let isValueEmpty = false;

	if (value === '' || value === null || value === undefined) {
		isValueEmpty = true;
	}

	return isValueEmpty;
}

export function nonZeroValidator(
	control: AbstractControl
): ValidationErrors | null {
	return control.value !== 0 ? null : { nonZero: true };
}
