import { Component, Input } from '@angular/core';
import { CardLogo } from '@app/shared/constants/card-logo';

@Component({
  selector: 'app-card-logos',
  templateUrl: './card-logos.component.html',
  styleUrls: ['./card-logos.component.scss']
})
export class CardLogosComponent {
  @Input() public branName: any = '';
  @Input() public isCard = true;
  public cardLogo: any = CardLogo;
}
