/* Angular Libraries */
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';

/* Services */
import { UserService } from '@app/core/services/user.service';

/* Interfaces */
import { UserTypes } from '@app/shared/constants/global-enum';

/* Environment */
import { environment } from 'environments/environment';
@Injectable({
	providedIn: 'root',
})
export class IntegrationService {
  menu = [
		{
			name: 'Company Types',
			isActive: true,
		},
		{
			name: 'Company Statuses',
			isActive: false,
		},
		{
			name: 'Configuration Types',
			isActive: false,
		},
		{
			name: 'Configuration Statuses',
			isActive: false,
		},
	];

	constructor(private _http: HttpClient, private _userService: UserService) {}

	get isAdmin() {
		return this._userService.userRole === UserTypes.SourcepassAdmin;
	}

	get currentCompanyId() {
		return this._userService.companyId;
	}

	get selectedCompanyId() {
		return JSON.parse(
			localStorage.getItem(this.isAdmin ? 'companyAdmin' : 'companyUser') as any
		)?.companyId;
	}

	get companyIdType() {
		return this.isAdmin
			? this.selectedCompanyId
			: this.currentCompanyId === this.selectedCompanyId
			? null
			: this.selectedCompanyId;
	}

	getRewstURL() {
		return this._http.get(
			`${environment.apiBaseUrl}REWST_DataLinks/GetDefault`
		);
	}

	getRewstGraph() {
		return this._http.get(
			`${environment.apiBaseUrl}REWST_DataLinks/GetJSONData`
		);
	}

	saveRewstURL(payload: Params) {
		return this._http.post(
			`${environment.apiBaseUrl}REWST_DataLinks?link=${payload['link']}`,
			payload
		);
	}

	getIntegrationStatus(id: number) {
		return this._http.get(
			`${environment.apiBaseUrl}IntegrationSettings/Status/${id}`
		);
	}

	getStripePaymentStatus() {
		const param: any = this.getValidParameters({
			companyId: this.companyIdType,
		});

		return this._http.get(
			`${environment.apiBaseUrl}Companies/StripePaymentStatus`,
			{ params: param }
		);
	}

	updateIntegrationStatus(id: number, isEnabled: boolean) {
		const body = { id, isEnabled };
		return this._http.post(
			`${environment.apiBaseUrl}IntegrationSettings/UpdateStatus`,
			body
		);
	}

	getValidParameters(param: any) {
		return Object.fromEntries(
			Object.entries(param).filter(
				([key, value]: any) => !['', null, undefined].includes(value)
			)
		);
	}

	getCommunitcationQueue() {
		return this._http.get(
			`${environment.apiBaseUrl}Companies/CompanyType/CommunicationQueue`
		);
	}

	getSyncSettiing() {
		return this._http.get(
			`${environment.apiBaseUrl}Companies/CompanyType/SyncSetting`
		);
	}

	updateSyncSetting(payload: Params) {
		return this._http.put(
			`${environment.apiBaseUrl}Companies/CompanyType/SyncSetting`,
			{ companyTypeDetails: payload }
		);
	}

	updateCommnunicationQueue(payload: Params) {
		return this._http.put(
			`${environment.apiBaseUrl}Companies/CompanyType/CommunicationQueue`,
			{ companyTypeDetails: payload }
		);
	}
}
