import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationSettingsComponent } from './notification-settings.component';
import { SwitchInputModule } from '../form-input/switch-input/switch-input.module';
import { FormsModule } from '@angular/forms';
import { CheckboxInputModule } from '../form-input/checkbox-input/checkbox-input.module';
import { MatIconModule } from '@angular/material/icon';
import { BootstrapTooltipModule } from '@app/shared/directives/bootstrap/bootstrap-tooltip.module';
import { TooltipContentV2Module } from '@app/shared/directives/tooltip-content-v2/tooltip-content-v2.module';

@NgModule({
  declarations: [
    NotificationSettingsComponent
  ],
  imports: [
    CommonModule,
    CheckboxInputModule,
    SwitchInputModule,
    FormsModule,
    MatIconModule,
    BootstrapTooltipModule,
    TooltipContentV2Module
  ],
  exports: [NotificationSettingsComponent]
})
export class NotificationSettingsModule { }
