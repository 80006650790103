import { UserService } from './../../../../core/services/user.service';
import { BaseUserComponent } from './../../user/base-user.component';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompaniesService } from '@app/modules/companies/companies.service';

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styleUrls: ['./impersonate-modal.component.scss'],
})
export class ImpersonateModalComponent extends BaseUserComponent {
  companies: any[] = [];
  users: any[] = [];
  companyLogoSrc: string = '';
  selectedCompanyId: number = 0;
  selectedUserId: number = 0;

  constructor(
    userServce: UserService,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private dialogRef: MatDialogRef<ImpersonateModalComponent>,
    private _companiesService: CompaniesService
  ) {
    super(userServce);
    this._companiesService.getCompanyDropdown().subscribe((res: any) => {
      if (res.length > 0) {
        this.companies = res;
        if(this.data) {
          this.selectedCompanyId = this.data;
          this.companyChange(this.selectedCompanyId);
        }
      }
    });
  }

  ngAfterViewInit() {
    this._setIdentity();
  }

  companyChange(id: number) {
    if(this.data && id != this.data) return;
    // this.selectedCompanyId = Number(event.target.value);
    // this.selectedCompanyId = id;
    this.selectedUserId = 0;
    if (this.selectedCompanyId) {
      this._companiesService
        .getUsersDropdownByCompanyId(this.selectedCompanyId)
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.users = res.filter(
              (user: { id: number }) => user.id !== this.userId
            );
          } else {
            this.users = [];
          }
        });
      this._companiesService
        .getCompanyLogo(this.selectedCompanyId)
        .subscribe((res: any) => {
          this.companyLogoSrc = res.logo;
        });
    } else {
      this.users = [];
      this.companyLogoSrc = '';
    }
  }

  userChange(id: number) {
    // this.selectedUserId = Number(event.target.value);
    this.selectedUserId = id;
  }

  onLogin() {
    this.dialogRef.close({
      data: {
        companyId: this.selectedCompanyId,
        userId: this.selectedUserId,
        userName: this.users.find((obj) => obj.id === this.selectedUserId).name,
        companyName: this.companies.find(
          (obj) => obj.id === this.selectedCompanyId
        ).name,
      },
    });
  }
}
