
<div clickOutSideConfigurator class="min-height-300 bg-primary position-absolute w-100" style="z-index: -2;"></div>
<aside nav-side-bar id="sidenav-main"></aside>
<main class="main-content position-relative border-radius-lg " [class.impersonating]="impersonating">
  <nav-top-bar></nav-top-bar>
  <div class="container-fluid py-4">
    <banner-message-widget></banner-message-widget>
    
    <router-outlet
			*ngIf="
				!(maintenance.isUndeMaintenance$ | async)!.isInMaintenance ||
					(maintenance.isUndeMaintenance$ | async)!.parentId;
				else um
			"
		></router-outlet>
		<ng-template #um><under-maintenance></under-maintenance></ng-template>

    <nav-footer></nav-footer>
  </div>
</main>
<div class="fixed-plugin ps" spConfiguration id="configurator"></div>
