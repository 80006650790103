import { IButtonsTemplateFilter } from "../buttons-template-settings.interface";

import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  BUTTONS_TEMPLATE_SETTINGS_UPDATE = '[Buttons] Update',
  BUTTONS_TEMPLATE_SETTINGS_UPDATE_SUCCESS = '[Buttons] Update Success',
}
export const buttonsTemplateSettingsUpdateAction =
createAction(
  ActionTypes.BUTTONS_TEMPLATE_SETTINGS_UPDATE,
  props<Partial<IButtonsTemplateFilter>>()
)
export const buttonsTemplateSettingsUpdateSuccessAction =
createAction(
  ActionTypes.BUTTONS_TEMPLATE_SETTINGS_UPDATE_SUCCESS
)