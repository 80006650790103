/* Angular Libraries */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';

/* Interfaces */
import { NotificationMessages, UserTypes } from '@app/shared/constants';
import { BankInformation } from '@app/shared/interfaces/invoice.interface';

@Component({
	selector: 'app-edit-bank-details',
	templateUrl: './edit-bank-details.component.html',
	styleUrls: ['./edit-bank-details.component.scss'],
})
export class EditBankDetailsComponent implements OnInit, OnDestroy {
	/* Properties */
	bankForm: FormGroup;
	bankId: any;
	accountHolderList: string[] = ['individual', 'company'];
	bankDetails: BankInformation;

	/* Private Properties */
	private _$unsubscribe: Subject<void> = new Subject<void>();

	get isAdmin() {
		return this._stripeService.isAdmin;
	}

	/* Constructor */
	constructor(
		private fb: FormBuilder,
		private _route: ActivatedRoute,
		private _stripeService: StripeService,
		private _notifier: NotificationService,
		private _spinner: SpinnerService,
		private _toastMessageService: ToastMessageService,
		private _router: Router
	) {}

	/* Methods */
	ngOnInit(): void {
		this.bankId = atob(this._route.snapshot.params['id']);
		this.initForm();
		this.getBankDetails();
	}

	initForm() {
		this.bankForm = this.fb.group({
			accountHolderType: ['', Validators.required],
			accountHolderName: ['', Validators.required],
			email: [''],
		});
	}

	getBankDetails() {
		this._spinner.start();

		this._stripeService
			.getStripeBankds(this.bankId)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: BankInformation) => {
					this.bankDetails = result;
					this.bankForm.controls['accountHolderType'].setValue(
						result.accountHolderType
					);
					this.bankForm.controls['accountHolderName'].setValue(
						result.accountHolderName
					);
					this.bankForm.controls['email'].setValue(result.email);
					this._spinner.stop();
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err.errors);
				},
			});
	}

	updateBankAccount() {
		this.bankForm.markAllAsTouched();

		const params = {
			id: this.bankId,
			accountHolderName: this.bankForm.value.accountHolderName,
			accountHolderType: this.bankForm.value.accountHolderType,
			email: this.bankForm.value.email ? this.bankForm.value.email : null,
		};
		
		if (this.bankForm.controls['accountHolderName'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
				return;
		}

		this._spinner.start();

		this._stripeService
			.updateStripeBanks(params)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result) => {
					this._spinner.stop();
					this._notifier.notifySuccess(NotificationMessages.save('Bank Account'), 'You can use this account for future transactions');
					this._router.navigate([
						`/${
							this.isAdmin ? 'billing-orders' : 'billing-and-orders'
						}/invoices`,
					]);
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err.errors);
				},
			});
	}

	returnAsNumber(event: any) {
		return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
