import { Component, Input, OnInit } from '@angular/core';
import { BillingContacts } from '@app/shared/interfaces/finance.interface';

@Component({
  selector: 'app-billing-contact',
  templateUrl: './billing-contact.component.html',
  styleUrls: ['./billing-contact.component.scss']
})
export class BillingContactComponent implements OnInit {
  @Input() billingContact: BillingContacts[];

  constructor() { }

  ngOnInit(): void {
  }

}
