import {
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { UtilitiesService } from '@app/shared/services/utilities.service';
import { QuickFilterService } from './quick-filter.service';

import { QuickFilterType } from '@app/shared/constants/quick-filter-type.enum';

import { QuickFilter } from '@app/shared/interfaces/order.interface';
import { UserService } from '@app/core/services/user.service';
import { UserTypes } from '@app/shared/constants';
import { forkJoin, of } from 'rxjs';

@Component({
	selector: 'app-quick-filter',
	templateUrl: './quick-filter.component.html',
	styleUrls: ['./quick-filter.component.scss'],
})
export class QuickFilterComponent implements OnInit, OnChanges {
	@Input() inputValue: string;
	@Input() url: string;
	@Input() selectedType?: string = QuickFilterType.multiple;
	@Input() showLabel: boolean = true;
	@Input() set statusIds(ids: number[]) {
		this._statusIds = ids;
		this.setSingleSelectForm();
	}
	@Input() quickFilterOptions: any = [];
	@Input() isShowHelpButton: boolean = true;
	@Output() onSelectIds = new EventEmitter<QuickFilter[]>();

	selectedStatus: number[] = [];
	quickFilterData: QuickFilter[] = [];
	selectedSingleFilter: FormControl = new FormControl(null);
	quickFilterType = QuickFilterType;

	private _statusIds: number[];

	constructor(
		private _quickFilter: QuickFilterService,
		private _utilityService: UtilitiesService,
		private _userService: UserService,
		private _cd: ChangeDetectorRef
	) {}

	get statusIds(): number[] {
		return this._statusIds;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes['statusIds'] &&
			this.selectedType === QuickFilterType.multiple
		) {
			const currentValue: any[] = changes['statusIds'].currentValue;
			const previousValue: any[] = changes['statusIds'].previousValue;

			if (
				currentValue &&
				previousValue &&
				previousValue.length !== currentValue.length
			) {
				const event = { target: { checked: true } };
				currentValue.forEach((x) => {
					this.setQuickFilter(x, event);
				});
			}
			if (
				currentValue &&
				previousValue &&
				previousValue.length > currentValue.length
			) {
				const filteredVal = this.quickFilterData.filter(
					(x) => !currentValue.includes(x.id)
				);
				const event = { target: { checked: false } };
				filteredVal.forEach((x) => {
					this.setQuickFilter(x.id, event);
				});
			}
		}
	}

	ngOnInit(): void {
		this.getQuickFilter();
	}

	getQuickFilter() {
		forkJoin(
			!this.quickFilterOptions?.length
				? this._quickFilter.getquickFilters(this.url)
				: of(this.quickFilterOptions)
		).subscribe({
			next: ([res]) => {
				this.quickFilterData = res.map((resp: QuickFilter) => ({
					id: resp.id,
					name: resp.name,
					selected: false,
					color: resp.color || resp.color !== null ? resp.color : '#00b9f2',
					fontColor:
						resp.fontColor || resp.fontColor !== null
							? resp.fontColor
							: '#08354F',
					borderColor:
						resp.borderColor || resp.borderColor !== null
							? resp.borderColor
							: this._utilityService.darken('#00b9f2'),
				}));
				if (this.statusIds && this.selectedType === QuickFilterType.multiple) {
					if (this.statusIds.length > 0) {
						const event = { target: { checked: true } };
						this.statusIds.forEach((x) => {
							this.setQuickFilter(x, event);
						});
					}
				}

				this._cd.detectChanges();
			},
		});
	}

	checkQuickFilter(id: number) {
		return this.selectedStatus.includes(id);
	}

	saveQuickFilter(id: number, isChecked: any) {
		const event = { target: { checked: !isChecked } };
		this.setQuickFilter(id, event);
		this.onSelectIds.emit(this.quickFilterData);
	}

	setQuickFilter(id: number, event: any) {
		if (event.target.checked === true) {
			this.selectedStatus.push(id);
		} else {
			this.selectedStatus = this.selectedStatus.filter((x) => x !== id);
		}

		this.quickFilterData.forEach((x) => {
			if (this.selectedStatus.includes(x.id)) {
				x.selected = true;
			} else {
				x.selected = false;
			}
		});
	}

	getStyle(filterData: QuickFilter) {
		let style = {};

		if (this.selectedType === QuickFilterType.single) {
			if (filterData.id === this.selectedSingleFilter.value) {
				style = {
					'background-color': filterData.color,
					color: filterData.fontColor,
					'border-color': filterData.borderColor
						? filterData.borderColor
						: this._utilityService.darken(filterData.color),
				};
			} else {
				style = '';
			}
		} else {
			if (this.selectedStatus.includes(filterData.id)) {
				style = {
					'background-color': filterData.color,
					color: filterData.fontColor,
					'border-color': filterData.borderColor
						? filterData.borderColor
						: this._utilityService.darken(filterData.color),
				};
			}
		}

		return style;
	}

	onSingleSelected(filterData: QuickFilter, isClicked: boolean = false) {
		if (
			isClicked &&
			this.selectedSingleFilter.value &&
			this.selectedSingleFilter.value === filterData.id
		) {
			setTimeout(() => this.selectedSingleFilter.setValue(null), 0);
		}

		setTimeout(() => {
			this.quickFilterData.filter((x) => (x.selected = false));
			const index = this.quickFilterData.findIndex(
				(x) => this.selectedSingleFilter.value === x.id
			);

			if (index >= 0) {
				this.quickFilterData[index].selected = true;
			}

			this.onSelectIds.emit(this.quickFilterData);
		}, 0);
	}

	setSingleSelectForm() {
		setTimeout(() => {
			if (this.selectedType === QuickFilterType.single) {
				this.selectedSingleFilter.setValue(this.statusIds[0]);
			}
		}, 0);
	}

	getWidth(quickFilterContainer: HTMLElement, quickFilterLabel: HTMLElement) {
		const reduceWidth = quickFilterLabel.clientWidth > 100 ? 5 : 130;
		const quickFilterContainerWidth = quickFilterContainer.clientWidth;
		const width = quickFilterContainerWidth - reduceWidth;
		return `${width}px`;
	}
}
