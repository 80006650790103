import { ToastMessageService } from './../../../../shared/services/toast-message.service';
import { SpinnerService } from '@services/spinner.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientAdminPermissionComponent } from './../../../companies/pages/client-admin-list/modal/client-admin-permission/client-admin-permission.component';
import { UserService } from '@app/core/services/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CwpodVerificationService } from '@app/modules/public/pages/cwpod-verification/cwpod-verification.service';
import { UnauthorizedAccessComponent } from '@app/modules/companies/pages/client-admin-list/modal/unauthorized-access/unauthorized-access.component';

@Component({
  selector: 'app-cw-user-client-admin',
  templateUrl: './cw-user-client-admin.component.html',
  styleUrls: ['./cw-user-client-admin.component.scss'],
  providers: [CwpodVerificationService]
})
export class CwUserClientAdminComponent implements OnInit, AfterViewInit {
  companyId: any;
  userId: any;
  isClientAdmin: boolean;
  constructor(
    public dialog: MatDialog,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _companiesService: CompaniesService,
    private spinner: SpinnerService,
    private _toastMessageService: ToastMessageService,
    private _cwpodVerification: CwpodVerificationService,
    private _router: Router,
  ) {
    this.companyId = this._userService.user?.companyId;
    this.userId = this._activatedRoute.snapshot.params['userId'];


  }

  ngOnInit(): void {
    // this._cwpodVerification.isAuthorized('contact').subscribe({
    //   next:v=>{
    //     this.checkRole();
    //   },
    //   error:e=>{
    //     //this.openUnauthorizedDialog()
    //     this.spinner.stop()
    //     this._router.navigateByUrl('/coming-soon')
    //   }
    // })
  }

  ngAfterViewInit(): void {
    this.checkRole();
  }
  checkRole() {
    this.spinner.start()
    this._userService.getConnectWiseUser(this.userId).subscribe({
      next: (data) => {
        this.isClientAdmin = data.roleId !== 2 ? false : true;
      },
      complete: ()=>{
        this.spinner.stop()
      }, error: (err) => {
        this.spinner.stop()
      }
    })
  }
  openPermissionDialog() {
    this.dialog.open(ClientAdminPermissionComponent, {
      width: '600px',
      height: '336.5px',
      data: {
        companyId: this.companyId,
        userId: this.userId
      }
    })
  }

  openUnauthorizedDialog(){
    this.dialog.open(UnauthorizedAccessComponent, {
      width: '380px',
      height: '240px',
      data: {
        message: 'Unauthorized access to permission settings'
      },
      disableClose: true,
    })
  }

  setAsClientAdmin() {
    this.spinner.start();
    const isCAdmin = this.isClientAdmin === true ? 2 : 3;
    this._companiesService.updateRole(this.userId, isCAdmin).subscribe({
      next: () => {
        this.spinner.stop();
        this._toastMessageService.showSuccessMessage(
          'User successfully set as client admin.'
        );
      }, error: (err) => {
        this.spinner.stop();
      }
    })
  }
}
