import { TicketRequest } from './../../shared/components/messaging/view-ticket/ticket.interface';
import { environment } from './../../../environments/environment';
import { CustomForm } from './../../shared/interfaces/custom-form.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ConsoleLogsService } from '@app/shared/services/console-logs.service';
import { BrowserInfoService } from '@app/shared/services/browser-info.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ContactSupportService {

  constructor(
    private _http: HttpClient,
    private _consoleLogs: ConsoleLogsService,
    private _browser: BrowserInfoService,
    private _router: Router
  ) { }

  usageForm(usageId: number) {
    return this._http.get<CustomForm>(`${environment.apiBaseUrl}SRForm/GetFormByFormUsageId/` + usageId);
  }

  sendFeedBackEmailNotif(ticket: TicketRequest) {
    const page = this._router.url;
    const screenSize = this._browser.screenSize;
    //const browserDetail = this._browser.browserDetail();

    const pre = {...ticket, ...this._browser.browserDetail(), ...{page, screenSize, ConsoleLog:this._consoleLogs.errorStringify}};
    const body = this.newTicketFormData(pre);
    return this._http.post(`${environment.apiBaseUrl}SRForm/SendFeedBackEmailNotification`, body);
  }

  private newTicketFormData<T>(ticket: T) {
    const fd = new FormData();
    for (const [key, value] of Object.entries(ticket)) {
      if(value instanceof Array) {
        value.forEach((v) => fd.append(key, v));
      }
      else if(value instanceof Object) fd.append(key, JSON.stringify(value));
      else fd.append(key, value as any);
    }
    return fd;
  }
}
