import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DarkLightThemeComponent } from '@app/shared/components/dark-light-theme/dark-light-theme.component';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { AccountService } from '../../../account.service';
import { UserService } from '../../../../../core/services/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProfileBranding } from '@app/shared/interfaces/user.interface';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { NotificationMessages, HistoryUrl } from '@app/shared/constants';
import { ColorService } from '@app/shared/services/color.service';
import { AuditTrailComponent } from '@app/shared/components/audit-trail/audit-trail.component';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit, AfterViewInit {

  @ViewChild(DarkLightThemeComponent) theme:DarkLightThemeComponent;
  @ViewChild(AuditTrailComponent) auditTrail: AuditTrailComponent;
  userId: number;
  form:FormGroup;

  hasSubmitted = false;
  branding$:Observable<ProfileBranding | null>
  historyUrl = HistoryUrl.USERS;

  //#dominantColorList:Array<string> = [];
  //#logoColorList:Array<string> = [];

  constructor(
    public spinner:SpinnerService,
    private _account:AccountService,
    private _user:UserService,
    private _fb: FormBuilder,
    private _message: ToastMessageService,
    private _color: ColorService,
    private _cd: ChangeDetectorRef,
  ){
    _account.testing = false;
    this.userId = _user.userId;
    this.form = this.#createFormGroup();
  }


  ngOnInit(): void {
    this._account.initBranding(this.userId);
    this.branding$ = this._account.profileBranding$
  }

  ngAfterViewInit(): void {
    this.#initForm()
  }

  onSubmit(){
    if (this.form.valid){
      this.spinner.start();
      this.hasSubmitted = true;

      this.#prepareForm();
      let app = this.form.value;
      this._account.updateBrandingById(this.userId, app)
        .subscribe(this.#getResponse(this.#nextUpdate))
    }
    //this._cd.markForCheck();
  }

  #createFormGroup() {
    const group = this._fb.group({
      userId: [this.userId],
      logoColors: '',
      dominantColors: '',
      lightThemeColor: [''],
      darkThemeColor: [''],
      isDefault: [false],
      isDarkMode: [false],
      logoColorList:[''],
      dominantColorList:[''],
    });

    //if (!this.isEditBrandingEnabled) group.disable();

    return group;
  }

  #initForm(){
    this.branding$.subscribe(app=>{
      if(!app) return;
      this.form.patchValue({...app});

      this.theme.form.patchValue({
        lightTheme: app?.lightThemeColor ? `${app?.lightThemeColor}` : '#000000',
        darkTheme: app?.darkThemeColor ? `${app?.darkThemeColor}` : '#000000',
        isDefault: app?.isDefault ? app.isDefault : false,
        isDarkMode: app?.isDarkMode ? app.isDarkMode : false,
      });
      //this.#dominantColorList = [...app.dominantColorList, ...this._color.dominantColor];
      //console.log('dominant', this._color.dominantColor);
      //this.#logoColorList = this._color.getLogoColors()
    })
  }

  #getResponse(next:(v:any)=>void){
    return {
      next,
      error: this.#error,
      complete: this.#complete,
    }
  }

  #nextUpdate = (v:any)=>{
    this._account.refreshBranding();
    this.auditTrailRefresh();
    this._message.showSuccessMessage(
      NotificationMessages.UserThemeSuccessUpdated
    );
    this.spinner.stop();
  }



  #error = (v:any)=>{
    this._message.showErrorMessage(v);
    this.spinner.stop();
  }

  #complete = ()=>{this.spinner.stop()}

  #prepareForm() {
    const dominantColors = [...this._color.dominantColor];
    const dark = this.theme.form.get('darkTheme')?.value;
    const light = this.theme.form.get('lightTheme')?.value;

    if(dominantColors.indexOf(dark) === -1){
      dominantColors.push(dark);
    }
    if(dominantColors.indexOf(light) === -1){
      dominantColors.push(light);
    }

    this._color.setDominantColor(dominantColors);

    this.form.patchValue({
      darkThemeColor: this.theme.form.get('darkTheme')!.value.substring(0, 7),
      lightThemeColor: this.theme.form.get('lightTheme')!.value.substring(0, 7),
      isDefault: this.theme.form.get('isDefault')!.value,
      isDarkMode: this.theme.form.get('isDarkMode')!.value,
      dominantColorList: dominantColors,
    });

/*     const darkTheme = this.theme.form.get('darkTheme')!.value;
    const lightTheme = this.theme.form.get('lightTheme')!.value;
    const dominant = this.form.get('dominantColors').value
    this.dominantColors = [...this.logoColors];

    if (this.logoColors.indexOf(darkTheme) === -1) {
      this.dominantColors.push(darkTheme);
    }

    if (this.logoColors.indexOf(lightTheme) === -1) {
      this.dominantColors.push(lightTheme);
    }

    this._colorService.setDominantColor(this.dominantColors);

    return {
      ...app,
      faviconFile: app.favicon as File,
      logoFile: app.logo as File,
      favicon: this.iconSrc.includes('base64') ? '' : this.iconSrc,
      logo: this.logoSrc.includes('base64') ? '' : this.logoSrc,
      userId: this.user.id,
      logoColors: this.logoColors.join(','),
      dominantColors: this.dominantColors.join(','),
      isDefault: false,
      isDarkMode: false,
    }; */
  }

  auditTrailRefresh() {
    if (this.auditTrail) this.auditTrail.refresh();
  }
}
