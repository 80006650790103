export const DEFAULT_COLOR = '#00baf2'

export interface IThemeSettings{
  "userId": number,
  "darkThemeColor": string,
  "lightThemeColor": string,
  "companyDarkThemeColor": string,
  "companyLightThemeColor": string,
  "isDarkMode": boolean,
  "selectedColor": string,
  "lightColorsList": string[],
  "darkColorsList": string[],
}

export const DefaultBranding = {
  userId: 0,
  logoColors: '#00baf2',
  dominantColors: '#00baf2',
  darkThemeColor: '#00baf2',
  lightThemeColor: '#00baf2',
  isDefault: true,
  isDarkMode: false,
  logoColorList: [],
  dominantColorList: [],
  companyDarkThemeColor: '#00baf2',
  companyIsDarkMode: false,
  companyIsDefault:false,
  companyLightThemeColor:'#00baf2',
  selectedColor: '#00baf2',
  lightColorsList: [],
  darkColorsList: []
}
