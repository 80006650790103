import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Inject,
	inject,
	Input,
	OnDestroy,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

import * as moment from 'moment';
import {
	MatCalendar,
	MatDatepicker,
	MatDatepickerInput,
} from '@angular/material/datepicker';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MatDateFormats,
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

class CustomDateAdapter extends MomentDateAdapter {
	override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
		return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	}
}

export const MATERIAL_DATEPICKER_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'DD/MMM/YYYY',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'custom-calendar-header',
	template: `
		<div class="custom-calendar-header">
			<button type="button" mat-icon-button (click)="previousClicked('month')">
				<mat-icon svgIcon="chevron-left"></mat-icon>
			</button>
			<span class="custom-calendar-label">{{ periodLabel }}</span>
			<button type="button" mat-icon-button (click)="nextClicked('month')">
				<mat-icon svgIcon="chevron-right"></mat-icon>
			</button>
		</div>
	`,
})
export class CustomCalendarHeaderComponent {
	@Input() datepicker: MatDatepicker<any>;

	private _destroyed = new Subject<void>();

	constructor(
		private _calendar: MatCalendar<any>,
		private _cd: ChangeDetectorRef,
		private _dateAdapter: DateAdapter<any>,
		@Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats
	) {
		_calendar.stateChanges
			.pipe(takeUntil(this._destroyed))
			.subscribe(() => this._cd.markForCheck());
	}

	get periodLabel() {
		return this._toTitleCase(
			this._dateAdapter.format(
				this._calendar.activeDate,
				this._dateFormats.display.monthYearA11yLabel
			)
		);
	}

	previousClicked(mode: 'month' | 'year') {
		this._calendar.activeDate =
			mode === 'month'
				? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
				: this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
	}

	nextClicked(mode: 'month' | 'year') {
		this._calendar.activeDate =
			mode === 'month'
				? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
				: this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
	}

	private _toTitleCase(str: string) {
		return str.replace(/\w\S*/g, (txt: string) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}
}

@Component({
	selector: 'date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatePickerComponent),
			multi: true,
		},
		{
			provide: DateAdapter,
			useClass: CustomDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},

		{ provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
	],
})
export class DatePickerComponent
	implements AfterViewInit, OnDestroy, ControlValueAccessor
{
	@ViewChild(MatDatepickerInput) datePicker: MatDatepickerInput<Date>;
	@Output() emitDate = new EventEmitter<string>();

	customHeader = CustomCalendarHeaderComponent;
	internalControl = new FormControl();

	private _onChange = (value: any) => {};
	private _onTouch = () => {};
	private _subscription = new Subscription();

	private _el = inject(ElementRef);
	private _renderer = inject(Renderer2);

	get isValid() {
		return this.internalControl.valid;
	}

	get isInvalid(): boolean {
		let isInvalid = this._checkValidity();

		return isInvalid;
	}

	ngAfterViewInit() {
		const input = this._el.nativeElement.querySelector('.mat-input-element');
		this._renderer.removeClass(input, 'mat-input-element');
		this._renderer.setAttribute(input, 'placeholder', 'MM/DD/YYYY');
		this._subscription.add(
			this.datePicker.dateChange.subscribe((input) => {
				let val = 'invalid-date';

				if (moment(input.value).isValid()) {
					const date = moment(input.value).format('yyyy-MM-DD');
					val = date;

					this.internalControl.setErrors(null);
				} else {
					if (this.isInvalid) return;
					this.internalControl.setErrors({ invalid: true });
				}

				this._onChange(val);
				this.emitDate.emit(val);
			})
		);
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	writeValue(value: any) {
		if (value) {
			this.internalControl.setValue(value);
		}
	}

	registerOnChange(fn: any) {
		this._onChange = fn;
		this._subscription.add(this.internalControl.valueChanges.subscribe(fn));
	}

	registerOnTouched(fn: any) {
		this._onTouch = fn;
	}

	onFocus(el: HTMLElement) {
		setTimeout(() => {
			el.focus();
		}, 100);
	}

	private _checkValidity(): boolean {
		const input = this._el.nativeElement.querySelector(
			'.mat-datepicker-input'
		) as HTMLHtmlElement;
		let isInvalid = false;

		if (input.classList.contains('is-invalid')) {
			isInvalid = true;
			this.internalControl.markAsTouched();
			this._onTouch();
		}
		return isInvalid;
	}
}
