<mat-form-field>
	<div
		class="date-picker-cont"
		[ngClass]="{
			'invalid-date': !isValid || isInvalid
		}"
		(click)="picker.open(); onFocus(datepicker)"
	>
		<input
			matInput
			[matDatepicker]="picker"
			class="date-picker"
			[formControl]="internalControl"
			#datepicker
		/>
		<mat-icon *ngIf="isValid && !isInvalid" svgIcon="calendar-date"></mat-icon>
		<mat-icon *ngIf="!isValid || isInvalid" svgIcon="alert-triangle"></mat-icon>
	</div>

	<mat-datepicker
		#picker
		[calendarHeaderComponent]="customHeader"
	></mat-datepicker>
</mat-form-field>
