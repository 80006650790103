import { createAction, props } from '@ngrx/store';
import { IAgreements } from './agreements.interface';

export enum ActionTypes {
  AGREEMENTS_UPDATE = '[Agreements] Update',
  AGREEMENTS_UPDATE_SUCCESS = '[Agreements] Update Success',
}

export const agreementsUpdateAction = createAction(
  ActionTypes.AGREEMENTS_UPDATE,
  props<Partial<IAgreements>>()
);

export const agreementsUpdateSuccessAction = createAction(
  ActionTypes.AGREEMENTS_UPDATE_SUCCESS
);
