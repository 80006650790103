import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewOrderComponent } from './view-order.component';
import { QuotesAndOrdersRoutingModule } from '@app/modules/quotes-and-orders/quotes-and-orders-routing.module';
import { TrimPipeModule } from '@app/shared/pipes/trim.pipe.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormTooltipModule } from '../../form-tooltip/form-tooltip.module';
import { BootstrapTooltipModule } from '@app/shared/directives/bootstrap/bootstrap-tooltip.module';
import { BadgeModule } from '../../badges/badge/badge.module';



@NgModule({
  declarations: [ViewOrderComponent],
  imports: [
    FormTooltipModule,
    CommonModule,
    QuotesAndOrdersRoutingModule,
    SharedModule,
    TrimPipeModule
  ],
  exports:[
    ViewOrderComponent,
    BadgeModule,
    BootstrapTooltipModule
  ]
})
export class ViewOrderModule { }
