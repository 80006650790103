import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { AcronisBackupAllocation } from '@app/shared/interfaces/dashboard.interface';
import { filter, Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-acronis-backup-allocation',
  templateUrl: './acronis-backup-allocation.component.html',
  styleUrls: ['./acronis-backup-allocation.component.scss']
})
export class AcronisBackupAllocationComponent implements OnInit, OnDestroy {

  backupAllocation$:Observable<AcronisBackupAllocation[] | null>
  isStillLoading = true;
  subs = new Subscription()
  
  constructor(
    private _dashboard:DashboardService,
    private _cd: ChangeDetectorRef,
  ){
    _dashboard.initAcronisBackupAllocation();
  }

  ngOnInit() {
    this.#initProperties();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  #initProperties(){
    this.backupAllocation$ = this._dashboard.acronicBackupAllocation$
      .pipe(
        filter(v=>v!=null),
        tap(v=>{
          this.isStillLoading=false;
          this._cd.detectChanges();
        })
      );

    this.subs.add(this.backupAllocation$.subscribe());
  }

  getStatus(result:string){
    if(result.toLowerCase() === 'no backup'){
      return {'color':'red'};
    }
    return {'color':'green'};
  }

  isShow(allocation:string){
    if(
      allocation.toLowerCase() === 'local backup'
      || allocation.toLowerCase() === 'cloud backup'
    ){
      return true;
    }
    return false;
  }
}
