<div class="q-d-flex q-ai-center q-jc-between q-g-8 q-pb-4">
	<button class="q-d-flex q-ai-center q-jc-center" (click)="previous()">
		<mat-icon svgIcon="chevron-left"></mat-icon>
	</button>

	<div class="q-d-flex q-g-8">
		<div
			[matMenuTriggerFor]="monthMenu"
			(click)="setMode(modes.Month)"
			[class.active-mode]="mode == modes.Month"
			class="month-picker q-br-8 font-b1 q-pointer"
		>
			{{ monthLabel }}
		</div>
		<div
			[matMenuTriggerFor]="yearMenu"
			(click)="setMode(modes.Year)"
			[class.active-mode]="mode == modes.Year"
			class="month-picker q-br-8 font-b1 q-pointer"
		>
			{{ activeYear }}
		</div>
	</div>

	<button class="q-d-flex q-ai-center q-jc-center" (click)="next()">
		<mat-icon svgIcon="chevron-right"></mat-icon>
	</button>
</div>

<mat-menu
	#monthMenu="matMenu"
	(closed)="setMode(modes.Day)"
	class="q-date-month-year"
>
	<div class="mat-menu-content-wrapper scroll-v2">
		<div
			*ngFor="let month of months; let i = index"
			(click)="setMonth(i)"
			class="month-year-item font-b1 q-pointer"
			[class.active-item]="i == activeMonth"
		>
			{{ month }}
		</div>
	</div>
</mat-menu>

<mat-menu
	#yearMenu="matMenu"
	(closed)="setMode(modes.Day)"
	class="q-date-month-year"
>
	<div
		class="mat-menu-content-wrapper scroll-v2"
		(scroll)="onScrollYear($event)"
	>
		<div
			*ngFor="let year of years; let i = index"
			(click)="setYear(year)"
			class="month-year-item font-b1 q-pointer"
			[class.active-item]="year == activeYear"
		>
			{{ year }}
		</div>
	</div>
</mat-menu>
