import { UserService } from "@app/core/services/user.service";
import { take, tap } from "rxjs";


export class BaseUserComponent{
  public roleId: number;
  protected userId: number;
  public companyId: number;
  protected is2faEnabled: boolean;
  constructor(public _userService: UserService){}

  protected _setIdentity() {
    this.roleId = this._userService.user!.roleId;
    this.userId = this._userService.user!.id;
    this.companyId = this._userService.user!.companyId;
    this.is2faEnabled = !!this._userService.user!.twoFactorEnabled;
  }

  protected refreshUser(){
    // this._userService.refreshUser().pipe(take(1), tap(()=>{ this._setIdentity();})).subscribe()

  }


}
