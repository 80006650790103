import { createAction, props } from '@ngrx/store';

import { IContacts } from '../contact/contacts.interface';

export enum ActionTypes {
	USER_CONTACTS_UPDATE = '[User Contacts] Update',
	USER_CONTACTS_UPDATE_SUCCESS = '[User Contacts] Update Success',
}

export const userContactsUpdateAction = createAction(
	ActionTypes.USER_CONTACTS_UPDATE,
	props<Partial<IContacts>>()
);

export const userContactsUpdateSuccessAction = createAction(
	ActionTypes.USER_CONTACTS_UPDATE_SUCCESS
);
