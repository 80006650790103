<select-input
	[options]="childCompanyList"
	[optionKeys]="{ value: 'id', label: 'name' }"
	inputClass="form-control"
	[formControl]="selectInputAsync"
	[placeholder]="placeholder"
	class="input-class"
	(onBlur)="onBlur()"
	(onChange)="onChange($event)">
</select-input>
