import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';

import { ColumnSliderComponent } from './column-slider.component';

@NgModule({
	imports: [CommonModule, FigmaIconImgModule],
	declarations: [ColumnSliderComponent],
	exports: [ColumnSliderComponent],
})
export class ColumnSliderModule {}
