import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrossCheckComponent } from './cross-check.component';

import { LocalFigmaIconImgModule } from '@app/shared/local-figma-icon-img/local-figma-icon-img.module';

@NgModule({
	imports: [CommonModule, LocalFigmaIconImgModule],
	declarations: [CrossCheckComponent],
	exports: [CrossCheckComponent],
})
export class CrossCheckModule {}
