import { createAction, props } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';

export enum ActionTypes {
	USER_APP_CATEGORY_LIST_UPDATE = '[User Application Category List] Update',
	USER_APP_CATEGORY_LIST_UPDATE_SUCCESS = '[User Application Category List] Update Success',
}

export const userAppCategoryListUpdateAction = createAction(
	ActionTypes.USER_APP_CATEGORY_LIST_UPDATE,
	props<Partial<IPageState>>()
);

export const userAppCategoryListUpdateSuccessAction = createAction(
	ActionTypes.USER_APP_CATEGORY_LIST_UPDATE_SUCCESS
);
