<div class="container-modal">
	<div [style.display]="currentState === 'ticketList' ? 'block' : 'none'">
		<div class="heading">
			<div class="item-1">
				<h4>
					{{ this.data.templateData!['title'] }}
					&nbsp;
					<span class="tooltip-container">
						<img [src]="'../../../../../../../assets/icons/info-circle.svg'" />
						<modal-tooltip
							[bgColor]="'#293855'"
							[message]="
								this.data.templateData!['message']
									? this.data.templateData!['message']
									: message
							"
						></modal-tooltip>
					</span>
				</h4>
			</div>
			<div class="item-2">
				<button
					type="button"
					class="close"
					data-bs-dismiss="modal"
					aria-label="Close"
					(click)="closeModal(false)"
				>
					<span aria-hidden="true">
						<app-figma-icon-img
							[iconName]="'x'"
							[hasSpaceOnRight]="false"
						></app-figma-icon-img>
					</span>
				</button>
			</div>
		</div>
		<div class="heading-2">
			<div class="item-1">
				<h3
					*ngIf="
						dashboardReport.dbModal === DashboardReportTab.SatisfactionScore
					"
				>
					Last 30 Days
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.TotalTickets">
					{{ capitalizeFirstLetter(this.data.templateData!['month']) }}
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.UserTicket">
					{{ capitalizeFirstLetter(this.data.templateData!['user'].userName) }}
					- Last 30 Days
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.DeviceTicket">
					{{
						capitalizeFirstLetter(this.data.templateData!['device'].deviceName)
					}}
					- Last 30 Days
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.SiteTicket">
					{{ capitalizeFirstLetter(this.data.templateData!['site'].siteName) }}
					- Last 30 Days
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.ServiceTimes">
					{{ capitalizeFirstLetter(this.data.templateData!['label']) }}
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.Onboarding">
					{{
						this.data.templateData!['month']
							? capitalizeFirstLetter(this.data.templateData!['month'])
							: ''
					}}
				</h3>
				<h3
					*ngIf="dashboardReport.dbModal === DashboardReportTab.ServiceTicket"
				>
					{{ capitalizeFirstLetter(this.data.templateData!['categoryName']) }} -
					Last 30 Days
				</h3>
				<h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.TimeSpent">
					{{ capitalizeFirstLetter(this.data.templateData!['month']) }}
					<span class="com-badge font-b3 font-w-600 font-h-normal warning">
						{{ this.data.templateData!['filters'].statusName }}
					</span>
				</h3>
				<h3
					*ngIf="dashboardReport.dbModal === DashboardReportTab.RequestSource"
				>
					Last 30 Days -
					{{ capitalizeFirstLetter(this.data.templateData!['source']) }}
				</h3>
			</div>
			<div class="item-2">
				<button class="btn-dashboard" (click)="download()">
					<app-figma-icon-img
						[iconName]="'download-02'"
						[width]="20"
						[height]="20"
						[hasSpaceOnRight]="false"
						[isForButton]="false"
					>
					</app-figma-icon-img>
					Download
				</button>
			</div>
		</div>

		<div class="modal-content">
			<app-new-table-shared
				[searchFilters]="searchFilters"
				[loadingText]="'Fetching Tickets...'"
				(emitRow)="openLink($event)"
			></app-new-table-shared>
		</div>
	</div>

	<div
		class="card"
		[style.display]="currentState === 'ticketDetails' ? 'block' : 'none'"
	>
		<div class="card-header-2 q-p-24 q-pb-0">
			<h3 class="q-mr-auto">Ticket Details</h3>

			<app-secondary-button
				[isBackButton]="true"
				(click)="goBack()"
			></app-secondary-button>
		</div>

		<app-view-ticket
			[ticket]="ticket"
			[hasMessageInput]="false"
			[navData]="navData"
			[hasUrl]="false"
			(state)="onEmitState($event)"
			(pdfData)="onEmitPDFData($event)"
			(onNextPrev)="nextPrevTicket($event)"
		>
		</app-view-ticket>
	</div>

	<div
		class="card"
		[style.display]="currentState === 'ticketPrint' ? 'block' : 'none'"
	>
		<app-pdf-ticket (state)="onEmitState($event)" [pdfData]="pdfData">
		</app-pdf-ticket>
	</div>
</div>
