import { Injectable } from "@angular/core";

@Injectable(
  {providedIn: 'root'}
)
export class LocalStorageService{

  getStorageObject<T>(key: string): T{
    return JSON.parse(localStorage.getItem(key)!);
  }

  setStorageObject<T>(key: string, obj: T){
    const data = JSON.stringify(obj);
    localStorage.setItem(key, data);
  }

  removeStorageObject(key: string){
    localStorage.removeItem(key);
  }

  clearStorage(){
    localStorage.clear();
  }

  emitMessageTabs(){
    window.postMessage('urlUpdated', '*');
  }

  windowMessageListener(){
    window.addEventListener('message', (event) => {
      if (event.data === 'urlUpdated') {
        // Perform actions to refresh the tab
        // For example:
        location.reload();
      }
    });
  }
}
