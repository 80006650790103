import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { finalize, Subscription } from 'rxjs';

import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { SpinnerService } from './../../../../../core/services/spinner.service';

import { NotificationMessages } from '@app/shared/constants';

import { FileFormat } from './../../../../constants/global-enum';

import { IFakeTicket, TicketContacts } from '../ticket.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { ViewTicketService } from '../view-ticket.service';
import { userId } from '../../../../../modules/portal-usage/core/store/portal-usage.selector';
import { getInitials } from '@app/shared/functions/user-initials.functions';

@Component({
	selector: 'view-ticket-reply',
	templateUrl: './reply.component.html',
	styleUrls: ['./reply.component.scss'],
})
export class ReplyComponent
	implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
	@ViewChild('textArea') textArea: ElementRef;
	@ViewChild('fileUploadInput') fileUploadInput!: ElementRef;
	@Input() isClosed: boolean;
	@Input() ticketId: number;
	@Input() isDragEnter = false;
	@Output() hasSubmitted = new EventEmitter<any>();
	@Output() fakeTicketEmitter = new EventEmitter<IFakeTicket>();

	user: User | null;
	replyForm: FormGroup;
	submitSub: Subscription;
	impersonating: boolean;

	pageIndex: string = '';
	isSubmitting: boolean = false;
	fakeData: IFakeTicket;
	isReply: boolean = true;

	hasFileError = false;
	isRemoveFileClicked = false;
	fileErrorMessage =
		'File type not supported - Try uploading a BMP, CSV, DOCX, JPG, MP4, PDF, PNG, TXT or XLSX';
	draggedFileCount: number;
	isOpenModal = false;
	contacts: TicketContacts[] = [];
	isShowOthers: boolean = false;

	constructor(
		public _utilitiesService: UtilitiesService,
		private _fb: FormBuilder,
		private _location: Location,
		private _notifier: NotificationService,
		private _userService: UserService,
		private _ticketService: TicketService,
		private _spinner: SpinnerService,
		private _viewTicket: ViewTicketService
	) {
		this.impersonating = this._userService.user?.impersonatingBy != null;
	}

	get fileFormArray(): FormArray {
		return this.replyForm.get('files') as FormArray;
	}

	ngOnInit(): void {
		this.user = this._userService.user;
		this.initForm();
		this.getContacts();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isClosed']) {
			if (changes['isClosed'].currentValue) {
				this.isReply = false;
			} else {
				this.isReply = true;
				this.isClosed = false;
			}
		}
	}

	ngAfterViewInit() {
		this.textArea.nativeElement.focus();
	}

	initForm() {
		this.replyForm = this._fb.group({
			comment: this._fb.nonNullable.control('', {
				validators: [Validators.maxLength(4000)],
			}),
			files: this._fb.array([]),
		});
	}

	getContacts() {
		this.isShowOthers = false;
		this._ticketService.getContacts(this.ticketId).subscribe((v) => {
			this._viewTicket.reCheckAvatar(this._getAvatarFields(v));
			this.contacts = v.map((x) => {
				const { firstName, lastName, email, id } = x;
				let avatar: any | undefined = undefined,
					noProfileCtr = 0;
				if (!x.imagePath) {
					avatar = this._viewTicket.addAvatar({
						firstName,
						lastName,
						email,
						userId: id,
						noProfileCtr: 0,
						comingFrom: 'reply',
					});
					noProfileCtr = avatar.noProfileCtr;
				}
				return {
					...x,
					//fullName: x.fullName ? x.fullName : x.email,
					hasImage: x.imagePath ? true : false,
					isAnonymous: !x.fullName,
					noProfileCtr: noProfileCtr,
					initials: !x.imagePath
						? getInitials({ firstName, lastName, email })
						: '',
				};
			});
			this.isShowOthers = true;
		});
	}

	private _getAvatarFields(avatar: TicketContacts[]) {
		return avatar.map((v) => {
			const { firstName, lastName, email, id } = v,
				comingFrom: 'reply' | 'comment' = 'reply';
			return {
				firstName,
				lastName,
				email,
				userId: id,
				noProfileCtr: 0,
				comingFrom,
			};
		});
	}

	getFileType(filename: string) {
		const start = filename.indexOf('.') + 1;
		const end = filename.length;
		return filename.substring(start, end).toUpperCase();
	}

	getFileSize(byte: number): string {
		return (byte / 1000 / 1000).toFixed(2);
	}

	getIcon(filename: string): string {
		const fileType = this.getFileType(filename).toLowerCase();
		let iconName = 'image-01';

		switch (fileType) {
			case FileFormat.DOC:
			case FileFormat.DOCX:
			case FileFormat.PDF:
			case FileFormat.CSV:
			case FileFormat.TXT:
			case FileFormat.XLSX:
			case FileFormat.XLS:
				iconName = 'file-05';
				break;

			case FileFormat.MP4:
				iconName = 'play-square';
				break;
		}

		return iconName;
	}

	onDrop() {
		this.isDragEnter = false;
	}

	onDragEnd() {
		this.isDragEnter = false;
	}

	onDragEnter(e: DragEvent) {
		this.hasFileError = false;
		this.draggedFileCount = e.dataTransfer!.items.length;
	}

	onAddFile(evt: any) {
		this.fileAdded(evt.target.files);
	}

	addDeleteContact(response: any) {
		this.getContacts();
	}

	fileAdded(files: any) {
		const fileList = files?.clipboardData?.files || files;

		for (const file of fileList) {
			const fileType = this.getFileType(file.name).toLowerCase();
			let hasError = true;
			let ctr = this.fileFormArray.controls.filter(
				(file) => file.value.name === 'image.png'
			).length;

			if (file.name === 'image.png') {
				file.name2 = `screenshot_${this.pad(ctr + 1)}.png`;
			}

			switch (fileType) {
				case FileFormat.BMP:
				case FileFormat.CSV:
				case FileFormat.DOC:
				case FileFormat.DOCX:
				case FileFormat.JPG:
				case FileFormat.JPEG:
				case FileFormat.MP4:
				case FileFormat.PDF:
				case FileFormat.PNG:
				case FileFormat.TXT:
				case FileFormat.XLSX:
				case FileFormat.XLS:
					hasError = false;
					break;
			}

			if (hasError) {
				this.hasFileError = true;

				if (this.fileFormArray.length > 0) {
					this._notifier.notifyError(
						'File Type Not Supported',
						'Try uploading a BMP, CSV, DOCX, JPG, MP4, PDF, PNG, TXT or XLSX'
					);
				}

				break;
			} else {
				this.hasFileError = false;
			}
		}

		if (!this.hasFileError) {
			const fileForm = this.replyForm.get('files') as FormArray;
			Array.from(fileList).forEach((file) => {
				fileForm.push(this._fb.control(file));
			});
		}
	}

	pad(num: number): string {
		return num < 10 ? '0' + num.toString() : num.toString();
	}

	removeFile(index: number) {
		this.isRemoveFileClicked = true;
		this.hasFileError = false;
		const fileForm = this.replyForm.get('files') as FormArray;
		fileForm.removeAt(index);
	}

	uploadFiles() {
		if (!this.isRemoveFileClicked) {
			this.fileUploadInput.nativeElement.click();
		} else {
			this.isRemoveFileClicked = false;
		}
	}

	onSubmit(buttonType: 'reply' | 'close') {
		if (this.impersonating) return;
		// disable form
		// emit initial data
		if (!this.replyForm.value.comment && this.isClosed) {
			this._notifier.notify('Explain why you are reopening the ticket', {
				duration: 5,
				location: 'bottom-right',
				panelClass: 'default',
				hasRefresh: false,
			});
			return;
		}

		// check buttonType
		console.log(this.isClosed);

		if (buttonType === 'reply') this.isReply = true;
		if (buttonType === 'close') this.isReply = false;
		const data: IFakeTicket = {
			Id: 0,
			TicketId: this.ticketId,
			UserId: this.user!.id,
			Note: this.replyForm.value.comment,
			Files: this.replyForm.value.files,
		};
		console.log(data);
		if (this.isReply) {
			if (!data.Note && !data.Files?.length) return;
			if (data.Note && this.replyForm.get('comment')?.invalid) return;
		}
		this.fakeTicketEmitter.emit(data);
		this.replyForm.reset();
		this.fileFormArray.clear();
		this.fileUploadInput.nativeElement.value = '';
		this.isSubmitting = true;

		if (!this.isClosed) {
			this.submitSub = this._ticketService
				.addTicketNote(data, this.isReply)
				.pipe(finalize(() => this._spinner.reset()))
				.subscribe({
					next: () => {
						this._notifier.notifySuccess(
							this.isReply ? 'Message Sent' : 'Ticket Closed'
						);
						this.addTicketActions();
						if (!this.isReply) this.back();
						this.hasSubmitted.emit();
					},
					error: () => {
						this.addTicketActions();
						this._notifier.notifyError(NotificationMessages.ErrorOccured);
					},
				});
		} else this.reopenTicket(data);
	}

	reopenTicket(data: IFakeTicket) {
		this._ticketService.reopenTicket(data).subscribe({
			next: (res) => {
				this.hasSubmitted.emit();
				this._notifier.notifySuccess('Ticket Reopened');
			},
			error: (error) => {
				this._notifier.notifyError(
					NotificationMessages.unable('Reopen Ticket'),
					NotificationMessages.Try
				);
			},
		});
	}

	addTicketActions() {
		this.isSubmitting = false;
	}

	openAddManageUsersModal() {}

	ngOnDestroy(): void {
		if (this.submitSub) this.submitSub.unsubscribe();
	}

	back() {
		this._location.back();
	}

	updateReply(replyValue: boolean) {
		this.isReply = replyValue;
		const areaControl = this.replyForm.get('comment');
		if (areaControl) {
			if (!replyValue) {
				// Replace with your actual condition
				areaControl.clearValidators();
			} else {
				areaControl.setValidators([Validators.required]); // Add validators back
			}

			areaControl.updateValueAndValidity(); // Update validation state
		}
	}
}
