/* Angular Libraries */
import {
	Component,
	EventEmitter,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

/* Third Party Libraries */
import { finalize, map, Observable, startWith, Subject, takeUntil } from 'rxjs';

/* Services */
import { StripeService } from '@app/shared/services/stripe.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';

/* Interfaces */
import {
	AutopayPaymentMethod,
	CardAndBankMethod,
} from '@app/shared/interfaces/invoice.interface';
import { NotificationMessages } from '@app/shared/constants/global-constants';
import { HistoryUrl } from '@app/shared/constants/global-enum';
import { PaymentMethodType } from '@app/shared/constants/transaction-type.enum';

@Component({
	selector: 'app-manage-autopay-modal',
	templateUrl: './manage-autopay-modal.component.html',
	styleUrls: ['./manage-autopay-modal.component.scss'],
})
export class ManageAutopayModalComponent implements OnInit {
	/* ViewChild */
	// @ViewChild(AuditTrailComponent) auditTrailComponent: AuditTrailComponent;
	@Output() onManageAutopayStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

	/* Properties */
	isOpenModal: boolean = false;
	isChecked: boolean = false;
	paymentMethodForm: FormControl<any> = new FormControl<any>(null);
	paymentMethodList: Array<CardAndBankMethod> = [];
	filteredOptions: Observable<any[]>;
	historyUrl = HistoryUrl;
	paymentMethodType = PaymentMethodType;
	page: 'main' | 'terms' = 'main';
	isLoading: boolean = false;

	private _$unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor(
		private _stripeService: StripeService,
		private _notifier: NotificationService,
		private _spinner: SpinnerService
	) {}

	get currentCompanyId() {
		return this._stripeService.currentCompanyId;
	}

	/* Methods */
	ngOnInit() {
		this._initPaymentMethodList(true);
	}

	displayFn(method: any): string {
		return method && method.name ? `${method.name} **** ${method.last4}` : '';
	}

	clickOpen() {
		this._initPaymentMethodList(true);
		this._initFilteredOptions();

		this.isOpenModal = true;
		// document.body.classList.add('modal-backdrop-container');
	}

	closeModal() {
		this.isOpenModal = false;
		this.isChecked = false;
		this.paymentMethodForm.setValue(null);
		// document.body.classList.remove('modal-backdrop-container');
	}

	onClickUnenroll() {
		this._submit(false);
	}

	onClickEnroll() {
		this._submit(true);
	}

	onSelectionChange() {
		this.isChecked = false;
	}

	private _submit(isAutopay: boolean) {
		this._spinner.start();

		const payload: AutopayPaymentMethod = {
			paymentMethodId: this.paymentMethodForm.value.paymentMethodId,
			isAutopay: isAutopay,
		};

		this._stripeService
			.SetAutopayPaymentMethod(payload)
			.pipe(
				takeUntil(this._$unsubscribe),
				finalize(() => this._spinner.stop())
			)
			.subscribe({
				next: () => {
					const message = isAutopay ? 'Autopay Enrolled' : 'Autopay Unenrolled';
					this._notifier.notifySuccess(message);
					this._initPaymentMethodList();
				},
			});
	}

	private _filter(name: string): any[] {
		const filterValue = name.toLowerCase();
		return this.paymentMethodList.filter((option) =>
			option.name.toLowerCase().includes(filterValue)
		);
	}

	private _initFilteredOptions() {
		this.filteredOptions = this.paymentMethodForm.valueChanges.pipe(
			startWith(''),
			map((value) => {
				const name = typeof value === 'string' ? value : value?.name;
				return name
					? this._filter(name as string)
					: this.paymentMethodList.slice();
			})
		);
	}

	private _initPaymentMethodList(isInitalLoad: boolean = false) {
		this.isLoading = true;

		this._stripeService
			.getAutopayPaymentMethodDropdown()
			.pipe(
				takeUntil(this._$unsubscribe),
				finalize(() => {
					this.isChecked = false;
					this.isLoading = false;
				})
			)
			.subscribe({
				next: (result: Array<CardAndBankMethod>) => {
					this.paymentMethodList = result;

					const hasMethodEnrolledForAutopay = this.paymentMethodList.some(method => method.isForAutopay);

					if (hasMethodEnrolledForAutopay) {
						this.paymentMethodForm.setValue(this.paymentMethodList.find(method => method.isForAutopay));
						this.paymentMethodForm.disable();
					} else {
						this.paymentMethodForm.setValue(null);
						this.paymentMethodForm.enable();
					}

					this.onManageAutopayStatus.emit(hasMethodEnrolledForAutopay);

					if (!isInitalLoad) {
						this.closeModal();
					}
				},
			});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
