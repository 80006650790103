import { environment } from './../../../environments/environment.local';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService {

  constructor(private _http: HttpClient) { }

  getTermsOfUse() {
    return this._http.get(`${environment.apiBaseUrl}TermsOfUse`);
  }
  updateTermsOfUse(data: any) {
    return this._http.put(`${environment.apiBaseUrl}TermsOfUse/${data.id}`, data)
  }
}
