import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { map, Observable, tap } from 'rxjs';

import {
  IGroup,
  IGroupRequest,
  IPermissionsCheckNew,
  MenuActionPermissionParam,
  MenuAndActionPermissionParam,
  MenuOverrideParam,
  MenuPermissionParam,
} from '@app/shared/interfaces/groups.interface';
import {
  IPermission,
  IPermissionData,
  IPermissionUser,
  IPermissionUserKb,
  KBPermission,
  MessagePermission,
  IPermissionUserMessage,
} from '@app/shared/constants';


@Injectable({
  providedIn: 'root',
})
export class GroupsAndPermissionsService {
  isConnectWise = false;

  constructor(
    private _http: HttpClient,
  ) {}

  dynamicUserPermissionList({
    permissionType,
    search,
    page,
    pageSize,
    column,
    order,
    id,
  }: {
    permissionType: string;
    search: string;
    page: number;
    pageSize: number;
    column: string;
    order: string;
    id?: number;
  }) {
    const searchD = search !== undefined ? `search=${search}` : '';
    const pageD = page !== undefined ? `&Page=${page}` : '';
    const pageSizeD = pageSize !== undefined ? `&PageSize=${pageSize}` : '';
    const columnD = column !== undefined ? `&column=${column}` : '';
    const orderD = order !== undefined ? `&order=${order}` : '';
    const userId = id ? `/${id}` : '';
    return this._http.get(
      `${environment.apiBaseUrl}${permissionType}/User${userId}?${searchD}${pageSizeD}${columnD}${orderD}${pageD}`
    );
  }

  updateUserByCompanySPAdmin(companyId: number, body: any) {
    return this._http.post<IPermissionUser>(
      `${environment.apiBaseUrl}Permissions/UpdateUsers/${companyId}`,
      body
    );
  }

  updateUserByCompanyClientAdmin(body: any) {
    return this._http.post<IPermissionUser>(
      `${environment.apiBaseUrl}Permissions/UpdateUsers`,
      body
    );
  }
  getUsersByPermissionType(
    permissionModuleId: number,
    permissionTypeId: number,
    companyId: number
  ) {
    return this._http.get<IPermissionUser>(
      `${environment.apiBaseUrl}Permissions/Users/${permissionModuleId}/${permissionTypeId}/${companyId}`
    );
  }

  getUsersByPermissionTypeClient(
    permissionModuleId: number,
    permissionTypeId: number
  ) {
    return this._http.get<IPermissionUser>(
      `${environment.apiBaseUrl}Permissions/Users/${permissionModuleId}/${permissionTypeId}`
    );
  }

  addCompaniesByPermissionType(body: IPermission) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/AddCompanies`,
      body
    );
  }

  updateCompaniesByPermissionType(body: any) {
    return this._http.put(
      `${environment.apiBaseUrl}Permissions/UpdateCompanies`,
      body
    );
  }
  getCompanyList({
    permissionModuleId,
    permissionTypeId,
    search,
    page,
    pageSize,
    column,
    order,
  }: {
    permissionModuleId: number;
    permissionTypeId: number;
    search: string;
    page: number;
    pageSize: number;
    column: string;
    order: string;
  }) {
    let params = '';

    if (permissionModuleId) {
      params += `PermissionModule=${permissionModuleId}`;
    }

    if (permissionTypeId) {
      params += `&Id=${permissionTypeId}`;
    }

    if (search) {
      params += `&search=${search}`;
    }

    if (page) {
      params += `&Page=${page}`;
    }

    if (pageSize) {
      params += `&PageSize=${pageSize}`;
    }

    if (column) {
      params += `&column=${column}`;
    }

    if (order) {
      params += `&order=${order}`;
    }

    return this._http.get(
      `${environment.apiBaseUrl}Permissions/CompanyList?${params}`
    );
  }

  getCompaniesByPermmissionModule(
    permissionModuleId: number,
    permissionTypeId: number
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/Companies/${permissionModuleId}/${permissionTypeId}`
    );
  }

  postCompanyApplicationPermission(companyId: number, body: IPermissionUser) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/UpdateCompanyApplicationPermission/${companyId}`,
      body
    );
  }
  getUsersByApplication(appId: number, companyId: number) {
    return this._http.get<IPermissionUser>(
      `${environment.apiBaseUrl}Permissions/UsersByApplication/${appId}/${companyId}`
    );
  }

  getApplicationCompanyList({
    appId,
    search,
    page,
    pageSize,
    column,
    order,
  }: {
    appId: number;
    search: string;
    page: number;
    pageSize: number;
    column: string;
    order: string;
  }) {
    const appIdD = appId !== undefined ? `ApplicationId=${appId}` : '';
    const searchD = search !== undefined ? `&search=${search}` : '';
    const pageD = page !== undefined ? `Page=${page}` : '';
    const pageSizeD = pageSize !== undefined ? `&PageSize=${pageSize}` : '';
    const columnD = column !== undefined ? `&column=${column}` : '';
    const orderD = order !== undefined ? `&order=${order}` : '';
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/ApplicationCompanyList?${appIdD}${searchD}${pageSizeD}${columnD}${orderD}${pageD}`
    );
  }

  getCompaniesByApplication(appId: number) {
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/CompaniesByApplication/${appId}`
    );
  }

  updateCompaniesByApplication(body: any) {
    return this._http.put(
      `${environment.apiBaseUrl}Permissions/UpdateApplicationPermission`,
      body
    );
  }

  addCompaniesByApplication(body: IPermission) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/AddApplicationPermission`,
      body
    );
  }

  getGroupsDropdown(id: number): Observable<IGroup[]> {
    let companyId = `companyId=${id}`;
    return this._http
      .get<{ data: IGroup[] }>(
        `${environment.apiBaseUrl}Groups/DropdownList?${companyId}`
      )
      .pipe(map((group) => group.data));
  }
  postApplicationPermission(body: IPermissionData) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/ApplicationPermission`,
      body
    );
  }
  getApplicationPermission(
    companyId: number,
    applicationId: number
  ): Observable<IPermissionData> {
    return this._http.get<IPermissionData>(
      `${environment.apiBaseUrl}Permissions/ApplicationPermission/${companyId}/${applicationId}`
    );
  }

  getCompanyGroup(id: string | number): Observable<IGroup[]> {
    return this._http.get<IGroup[]>(
      `${environment.apiBaseUrl}Groups/Company/${id}`
    );
  }

  deleteGroup(id: number): Observable<any> {
    return this._http.delete(`${environment.apiBaseUrl}Groups/${id}`);
  }
  
  getUserTypeRoleMenu(id: number): Observable<any> {
    return this._http.get(`${environment.apiBaseUrl}MenuPermissions/GetUserMenu`);
  }

  getUserPermission(id: number | null): Observable<any> {
    return this._http.get(
      `${environment.apiBaseUrl}MenuUserPermissions/User/${id}`
    );
  }

  updateUserPermission(body: IPermissionsCheckNew): Observable<any> {
    return this._http.put(
      `${environment.apiBaseUrl}MenuUserPermissions/Update`,
      body
    );
  }

  getGroupHistory(groupId: number, pageNumber: number, pageSize: number) {
    return this._http.get<History[]>(
      `${environment.apiBaseUrl}Groups/history/${groupId}?page=${pageNumber}&pageSize=${pageSize}`
    );
  }

  getCompaniesByKbTopic(topicId: number) {
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/CompaniesByKBTopic/${topicId}`
    );
  }

  addCompaniesByKbTopic(body: KBPermission) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/AddKBTopicPermission`,
      body
    );
  }

  updateCompaniesByKbTopic(body: KBPermission) {
    return this._http.put(
      `${environment.apiBaseUrl}Permissions/UpdateKBTopicPermission`,
      body
    );
  }

  getKbTopicCompanyList({
    topicId,
    search,
    page,
    pageSize,
    column,
    order,
  }: {
    topicId: number;
    search: string;
    page: number;
    pageSize: number;
    column: string;
    order: string;
  }) {
    const topicIdD = topicId !== undefined ? `KBTopicId=${topicId}` : '';
    const searchD = search !== undefined ? `&search=${search}` : '';
    const pageD = page !== undefined ? `Page=${page}` : '';
    const pageSizeD = pageSize !== undefined ? `&PageSize=${pageSize}` : '';
    const columnD = column !== undefined ? `&column=${column}` : '';
    const orderD = order !== undefined ? `&order=${order}` : '';
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/KBTopicCompanyList?${topicIdD}${searchD}${pageSizeD}${columnD}${orderD}${pageD}`
    );
  }

  getUsersByKbTopic(topicId: number, companyId: number) {
    return this._http.get<IPermissionUserKb>(
      `${environment.apiBaseUrl}Permissions/UsersByKBTopic/${topicId}/${companyId}`
    );
  }

  postCompanyKbTopicPermission(companyId: number, body: IPermissionUserKb) {
    return this._http.post<{ kbTopicId: number; userIds: [] }>(
      `${environment.apiBaseUrl}Permissions/UpdateCompanyKBTopicPermission/${companyId}`,
      body
    );
  }

  getCompaniesByMessage(messageId: number) {
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/CompaniesByMessage/${messageId}`
    );
  }

  addCompaniesByMessage(body: MessagePermission) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/AddMessagePermission`,
      body
    );
  }

  updateCompaniesByMessage(body: MessagePermission) {
    return this._http.put(
      `${environment.apiBaseUrl}Permissions/UpdateMessagePermission`,
      body
    );
  }

  getMessageCompanyList({
    messageId,
    search,
    page,
    pageSize,
    column,
    order,
  }: {
    messageId: number;
    search: string;
    page: number;
    pageSize: number;
    column: string;
    order: string;
  }) {
    const messageIdD = messageId !== undefined ? `MessageId=${messageId}` : '';
    const searchD = search !== undefined ? `&search=${search}` : '';
    const pageD = page !== undefined ? `Page=${page}` : '';
    const pageSizeD = pageSize !== undefined ? `&PageSize=${pageSize}` : '';
    const columnD = column !== undefined ? `&column=${column}` : '';
    const orderD = order !== undefined ? `&order=${order}` : '';
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/MessageCompanyList?${messageIdD}${searchD}${pageSizeD}${columnD}${orderD}${pageD}`
    );
  }

  getUsersByMessage(messageId: number, companyId: number) {
    return this._http.get<IPermissionUserMessage>(
      `${environment.apiBaseUrl}Permissions/UsersByMessage/${messageId}/${companyId}`
    );
  }

  postCompanyMessagePermission(
    companyId: number,
    body: IPermissionUserMessage
  ) {
    return this._http.post<{ messageId: number; userIds: [] }>(
      `${environment.apiBaseUrl}Permissions/UpdateCompanyMessagePermission/${companyId}`,
      body
    );
  }
  getUsersByGroups(groupId: number, companyId: number) {
    return this._http.get<any>(
      `${environment.apiBaseUrl}Groups/GetUserGroup?groupId=${groupId}&companyId=${companyId}`
    );
  }
  saveUserGroups(data: any) {
    return this._http.post<any>(
      `${environment.apiBaseUrl}Groups/UpdateUsers`,
      data
    );
  }

  getRoleList(): Observable<any> {
    return this._http.get(`${environment.apiBaseUrl}MenuPermissions/GetRoleList`);
  }

  getMenuPermissionsList(roleId: number, companyId: number, groupId: number, userId: number): Observable<any> {
    let obs:Observable<any>

    if(this.isConnectWise)
      obs = this._http.get(`${environment.apiBaseUrl}CW_Pods/MenuList?roleId=${roleId}&companyId=${companyId}&groupId=${groupId}&userId=${userId}`);
    else
      obs = this._http.get(`${environment.apiBaseUrl}MenuPermissions/MenuList?roleId=${roleId}&companyId=${companyId}&groupId=${groupId}&userId=${userId}`);

    return obs
  }

  getMenuPermissionsListAccess(roleId: number, companyId: number, groupId: number, userId: number): Observable<any> {
    return this._http.get(`${environment.apiBaseUrl}MenuPermissions/MenuAccessList?roleId=${roleId}&companyId=${companyId}&groupId=${groupId}&userId=${userId}`);
  }

  getOverrideFlag(companyId: number, userId: number | null, groupId?: number | null): Observable<any>{
    return this._http.get(`${environment.apiBaseUrl}MenuPermissions/GetOverride?companyId=${companyId}&groupId=${groupId}&userId=${userId}`);
  }

  setMenuPermission(param: MenuPermissionParam){
    const body: {[key: string]: any} = {
      roleId: param.roleId,
      companyId: param.companyId,
      isAllowed: param.isAllowed,
      menuIds: param.menuIds
    }
    if(param.userId) body['userId'] = param.userId;
    if(param.groupId) body['groupId'] = param.groupId;
    return this._http.post(`${environment.apiBaseUrl}MenuPermissions/SetMenuAccess`,body)
  }

  setMenuActionPermission(param: MenuActionPermissionParam){
    const body: {[key: string]: any} = {
      roleId: param.roleId,
      companyId: param.companyId,
      isAllowed: param.isAllowed,
      menuActionIds: param.menuActionIds
    }
    if(param.userId) body['userId'] = param.userId;
    if(param.groupId) body['groupId'] = param.groupId;

    if(this.isConnectWise)
      return this._http.post(`${environment.apiBaseUrl}CW_Pods/SetMenuActionAccess`,body)
    else
      return this._http.post(`${environment.apiBaseUrl}MenuPermissions/SetMenuActionAccess`,body)
  }
  setMenuAndActionPermission(param: MenuAndActionPermissionParam){
    if(this.isConnectWise)
      return this._http.post(`${environment.apiBaseUrl}CW_Pods/SetMenuAccess`,param)
    else
      return this._http.post(`${environment.apiBaseUrl}MenuPermissions/SetMenuAccess`,param)
  }

  setOverrideFlag(param: MenuOverrideParam){
    const body: {[key: string]: any} = {
      companyId: param.companyId,
      isChanged: param.isChanged
    }
    if(param.userId) body['userId'] = param.userId;
    if(param.groupId) body['groupId'] = param.groupId;
    return this._http.post(`${environment.apiBaseUrl}MenuPermissions/SetOverride`,body)
  }
}
