import { CommonModule, formatDate } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { OrdinalPipe } from '@app/shared/pipes/ordinal.pipe';

import { MaintenanceAlertDisplay } from '@app/modules/system/interfaces/maintenance.interface';

@Component({
	selector: 'maintenance-banner',
	templateUrl: './maintenance-banner.component.html',
	styleUrls: ['./maintenance-banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [OrdinalPipe],
	standalone: true,
	imports: [MatIconModule, CommonModule],
})
export class MaintenanceBannerComponent implements OnChanges {
	@Input('data') alert: MaintenanceAlertDisplay;

	fromDate = '';
	toDate = '';

	private _ordinal = inject(OrdinalPipe);
	private _cd = inject(ChangeDetectorRef);

	ngOnChanges(): void {
		this._setDisplayDate();
	}

	private _setDisplayDate() {
		const day = this._ordinal.transform(
			new Date(this.alert.outageFrom).getDate()
		);
		this.fromDate = formatDate(
			this.alert.outageFrom,
			`EEEE, MMMM '${day}' 'at' h:mm a`,
			'en-US'
		);

		if (
			new Date(this.alert.outageFrom).toDateString() ==
			new Date(this.alert.outageTo).toDateString()
		)
			this.toDate = formatDate(this.alert.outageTo, 'h:mm a', 'en-US');
		else {
			const day = this._ordinal.transform(
				new Date(this.alert.outageTo).getDate()
			);
			this.toDate = formatDate(
				this.alert.outageTo,
				`EEEE, MMMM '${day}' 'at' h:mm a`,
				'en-US'
			);
		}
		this._cd.detectChanges();
	}
}
