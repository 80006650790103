import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'app-date-time-divider',
	templateUrl: './date-time-divider.component.html',
	styleUrls: ['./date-time-divider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeDividerComponent implements OnInit {
	@Input() dateTime: string | null;

	constructor() {}

	ngOnInit() {}

	displayDate(): string {
		return this.dateTime ? this.dateTime.split(',')[0].toString() : '';
	}

	displayTime(): string {
		return this.dateTime ? this.dateTime.split(',')[1].toString() : '';
	}
}
