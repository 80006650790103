import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { UserService } from '@app/core/services/user.service';

import { AppStateInterface } from '@app/core/store/app-state.interface';

import { Store } from '@ngrx/store';
import { StripeService } from '@app/shared/services/stripe.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'nav-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() isPublic = false;

  isOpenModal: boolean = false;
  htmlWithCss: any;
  termsTitle: string;

  private _$unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public userService: UserService,
    private store: Store<AppStateInterface>,
    private _localStorage: LocalStorageService,
    private _stripeService: StripeService
  ) {}

  ngOnInit(): void {
    if(!this.isPublic) this._initEvent();
    this._initTermsConditionEvent();
  }

  getTermsData() {
    const termsOfUse = this._localStorage.getStorageObject('terms-of-use') as any;
    const content = JSON.parse(termsOfUse.content);
    const css = content.css;
	  const html = content.html;
		this.htmlWithCss = `<style>${css}</style>${html}`;
    this.termsTitle = termsOfUse.title;
  }
  getUser(): string {
    const user = this.userService.user;
    let role, fullName, companyName;

    if(user?.impersonatingBy){
      role = mapUserRole(user.impersonatingBy.systemRoleId);
      fullName = user.impersonatingBy.fullName;
      companyName = user.impersonatingBy.companyName
      return this.construct(fullName, role, companyName)
    }else{
      role = mapUserRole(user?.systemRoleId!);
      fullName = user?.fullName!;
      return this.construct(fullName, role, user!.companyName)
    }
  }
  // getImpersonate(){
  //   const user = this.userService.user;
  //   let role, fullName, companyName, message;

  //   if(user?.impersonatingBy){
  //     role = mapUserRole(user?.systemRoleId!);
  //     fullName = user?.fullName!;
  //     message = this.construct(fullName, role, user!.companyName)
  //   }
  //   return message;
  // }

  construct(fullName: string, role: string,  company: string){
    return `${fullName} / ${role} / ${company}`;
  }

  openTermsConditionsModal(isOpen: boolean) {
    this.getTermsData();
    this.isOpenModal = isOpen;
    isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible';
  }

  scrollMaxValue() {
    const body = document.body;
    const html = document.documentElement;
  
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
  
    const windowHeight = window.innerHeight;
  
    return documentHeight - windowHeight;
  }

  private _initTermsConditionEvent() {
    this._stripeService.openTermsCondition
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (isOpen) => {
        this.openTermsConditionsModal(isOpen);
      }
    });
  }

  private _initEvent() {
    window.onscroll = () => this._updatedFooterAndImpersonteAlertBarUI();
    window.addEventListener('resize', (event) => this._updatedFooterAndImpersonteAlertBarUI());
  }

  private _updatedFooterAndImpersonteAlertBarUI() {
    const doc = document as any;

    const body = doc.querySelector("body")!;
    if(window.pageYOffset >= 1) body.classList.add("scrolled");
    else body.classList.remove("scrolled");
    
    /* [Start]::For footer section */
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let w = '';
    if (width <= 991) w = '-32px';
    if (width <= 417) w = '-55px';
    if (width <= 298) w = '-82px';
    if (width <= 212) w = '-108px';

    if (width <= 991) {
      if (doc.body.scrollTop >= this.scrollMaxValue() || doc.documentElement.scrollTop >= this.scrollMaxValue()) {
        doc.getElementById("footer-section").style.bottom = '0';
        doc.getElementById("sidenav-main").classList.add('add-margin-bottom');

      } else {
        doc.getElementById("footer-section").style.bottom = w;
        doc.getElementById("sidenav-main").classList.remove('add-margin-bottom');
      }
    } else {
      doc.getElementById("footer-section").style.bottom = "0";
    }
    /* [End]::For footer section */
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
const mapUserRole = (roleId: number) =>{
  if(roleId === 1) return 'Sourcepass Admin';
  if(roleId === 2) return 'Client Admin';
  if(roleId === 3) return 'User';
  else return 'Unknown Role'
}
