import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import {
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	merge,
	Subscription,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SortDirection } from '@angular/material/sort';

import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';

import { ApplicationsService } from '../../applications.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { SpinnerService } from '@services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UserService } from '@app/core/services/user.service';

import {
	NotifOperation,
	NotifType,
	Permission,
} from '@app/shared/constants/global-constants';

import { UserTypes } from '@app/shared/constants/global-enum';

import {
	ApplicationCategory,
	IApplicationCategory,
} from '@interfaces/application.interface';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IPageState } from '@app/shared/interfaces/page-state.interface';

import { appCategoryListSelector } from './store/sp-admin/app-category.selector';
import { companyAppCategoryListSelector } from './store/sp-admin/company/company-app-category.selector';
import { userAppCategoryListSelector } from './store/user/user-app-category.selector';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit
{
	@ViewChild('searchTextbox') searchTextbox: ElementRef;

	roleId = this._userService.user?.roleId;

	companyId: string | null;
	isManageEnabled = this._userService.hasPermission([
		Permission.CompanyAdminApplicationCategoryAddEdit,
		Permission.SpAdminApplicationCategoryAddEdit,
		Permission.UserAddEditApplicationCategory,
	]);
	isViewable = this._userService.hasPermission([
		Permission.SpAdminApplicationCategoryView,
		Permission.CompanyAdminApplicationCategoryView,
	]);

	searchFilters: IPageState;
	search = new FormControl();
	column: string;
	order: SortDirection;
	page: number;
	pageSize: number;

	private _routerSubs: Subscription;
	private _subscription = new Subscription();

	constructor(
		public applicationsService: ApplicationsService,
		public spinner: SpinnerService,
		public override _companiesService: CompaniesService,
		public override _route: ActivatedRoute,
		private _cd: ChangeDetectorRef,
		private _router: Router,
		private _store: Store<AppStateInterface>,
		private _breadcrumbService: BreadcrumbService,
		private _dataModalService: DataModalService,
		private _toastMessageService: ToastMessageService,
		private _userService: UserService
	) {
		super(_companiesService, _route);

		this.getCompanyIdFromParams();
		this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(
			this._companiesService.subCompanyId!
		);

		if (this._route.snapshot.paramMap.get('companyId')) {
			if (this._userService.user?.roleId === 1) {
				this.companyId = this._route?.snapshot?.paramMap.get('companyId');
			}
		}

		this._routerSubs = this._router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				const url = event.url;
				if (
					!(
						url.includes('/application/categories') ||
						url.includes('/applications/manage-categories') ||
						url.includes('/global-search')
					)
				) {
					this.applicationsService.clearSearch();
					this._routerSubs.unsubscribe();
				}
			}
		});
	}

	ngOnInit() {
		this.getCompanyIdFromParams();
	}

	ngAfterViewInit() {
		this._loadPreviousFilters();
		this._searchSubscription();
		this._cd.detectChanges();
	}

	onDelete(app: ApplicationCategory) {
		const data = this._dataModalService.getDeleteModel(
			'Application Category',
			app.name.trim()
		);

		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					this.applicationsService.deleteCategory(app.id).subscribe({
						next: () => {
							this.applicationsService.allowToFetchNewData = true;
							this.fetchNewData();
							this._cd.detectChanges();
						},
						error: () => {
							this.spinner.stop();
						},
						complete: () => {
							this._toastMessageService.showSuccessMessage(
								this._toTitleCase(
									`${NotifOperation.DELETED} ${NotifType.SUCCESS}`
								)
							);
						},
					});
				}
			},
		});
	}

	fetchNewData() {
		if (!this.isViewable) return;

		const filters: IPageState = this._getFilters();
		this.searchFilters = filters;
		this._cd.detectChanges();
	}

	private _loadPreviousFilters() {
		let selector = appCategoryListSelector;

		if (this.companyId) {
			selector = companyAppCategoryListSelector;
		} else if (this.roleId === UserTypes.User) {
			selector = userAppCategoryListSelector;
		}

		this._store.pipe(select(selector), take(1)).subscribe((filter) => {
			this.search.patchValue(filter.query);
			this.page = filter.page;
			this.pageSize = filter.pageSize;
			this.column = filter.column;
			this.order = filter.order;
			this.fetchNewData();
		});
	}

	private _searchSubscription() {
		const inputEvent = this.search.valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged()
		);

		const enterEvent = fromEvent(
			this.searchTextbox.nativeElement as HTMLInputElement,
			'keyup'
		).pipe(
			filter((e) => (e as KeyboardEvent).keyCode === 13),
			map((e) => (e.target as HTMLInputElement).value)
		);

		this._subscription.add(
			merge(inputEvent, enterEvent)
				.pipe(
					distinctUntilChanged(),
					switchMap(async (query) => {
						this.page = 1;
						this.fetchNewData();

						return query;
					})
				)
				.subscribe()
		);

		this._subscription.add(
			this.applicationsService.isLoading$
				.pipe(
					tap((isLoading) => {
						if (isLoading) {
							this.search.disable();
						} else {
							if (this.search.disabled) {
								this.search.enable();
							}
							(this.searchTextbox.nativeElement as HTMLInputElement).focus();
						}
					})
				)
				.subscribe()
		);
	}

	private _getFilters(): IApplicationCategory {
		const filter: IApplicationCategory = {
			page: this.page,
			pageSize: this.pageSize,
			order: this.order,
			column: this.column,
			query: this.search.value.trim(),
			userRole: this._userService.userRole,
		};

		if (this.companyId) {
			filter.companyId = Number(this.companyId);
		} else if (this._userService.userRole === UserTypes.ClientAdmin) {
			filter.companyId = this._userService.companyId;
		}

		return filter;
	}

	private _toTitleCase(str: string) {
		return str.replace(/\w\S*/g, (txt: string) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}
}
