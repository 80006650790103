import { TableMessages } from '@app/shared/constants';
import { Router } from '@angular/router';
import { DashboardService } from './../../../../dashboard.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ApplicationWidget } from '@app/shared/interfaces/dashboard.interface';
import { delay, Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-application-widget',
  templateUrl: './application-widget.component.html',
  styleUrls: ['./application-widget.component.scss']
})
export class ApplicationWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  appData: any[] = [];
  application$: Observable<ApplicationWidget[] | null>;
  /* message = {
    noRecord: TableMessages.EmptyTable,
  }; */

  displayedColumns = ['name'];

  isStillLoading = true;
  subs = new Subscription()

  constructor(
    private _spinner: SpinnerService,
    private _dashboardService: DashboardService,
    private _cd: ChangeDetectorRef,
    private _router: Router,
  ){
    _dashboardService.initApplicationWidget();
    this.application$ = this._dashboardService.application$;
  }
  
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.getApplications();
    this._cd.detectChanges();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
  
  getApplications() {
    this._spinner.start();
    this.application$.pipe(
      delay(500),
      tap(v=>{
        this.isStillLoading = false;
        this._cd.detectChanges();
      })
    ).subscribe({
      next: (data: ApplicationWidget[] | null) => {
        this.appData = data!;
        this._spinner.stop();
        this._cd.markForCheck();
        // console.log('Return Data:', this.appData);
      }
    })
  }

  /**
   * [{"id":99,"name":"chrome102 global","categoryId":"1"}]
   */
  goToApplication() {
    this._router.navigate(['../company/applications'])
  }

  launchApp(url:string){
    const aTag = document.createElement('a');

    aTag.href = this._checkHttp(url);
    aTag.target = '_blank';
    aTag.click();
  }

  private _checkHttp = (url: string) => {
    if (!url.includes('https://') && !url.includes('http://')) {
      return 'https://' + url;
    }
    return url;
  };
}
