import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Input() filterDisable: boolean;
  @Input() placeholder: string = 'Filter';
  @Output() filterKey: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  filter(event: any){
   this.filterKey.emit(event.target.value as string)
  }

}
