import { CommonModule } from '@angular/common';
import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'modal-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class ModalTooltipComponent implements OnInit, OnChanges {
	@Input() bgColor: string = '#BFC0C1';
	@Input() message: { m1: string; m2: string; m3?: string[] };
	@Input() isChart: boolean = false;
	@Input() className: string = '';
	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['message'] && !changes['message'].isFirstChange())
			this.update(changes);
	}

	update(changes: SimpleChanges) {
		this.message = changes['message'] ? changes['message'].currentValue : '';
	}
}
