import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { AccountService } from '@app/modules/account/account.service';
import { BaseUserComponent } from '@app/shared/components/user/base-user.component';
import { NotificationMessages } from '@app/shared/constants';
import { catchError, map, Observable, shareReplay, take, tap, throwError } from 'rxjs';
import { twoFAResponse } from './two-factor-auth.interface';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent extends BaseUserComponent implements OnInit {
  TODO: "DELETE COMPONENT"
  twoFAQR$: Observable<string>;
  secretCode$: Observable<string>;

  isCodeShown: boolean = false;
  constructor(
    private _accountService: AccountService,
    private _notifier: NotificationService,
    private _spinner: SpinnerService,
    public override _userService: UserService,
    private _router: Router) {
      super(_userService);
     }

  ngOnInit(): void {
    this.getTwoFA()
  }

  getTwoFA(){
    const http$ =  this._accountService
    .twoFactorAuthenticationEnable().pipe(
      shareReplay()
    );

    this.twoFAQR$ = http$
    .pipe(
      map((data: twoFAResponse)=>data.qrCodeUrl));

    this.secretCode$ = http$
    .pipe(map((data: twoFAResponse)=>data.key));
  }

  copy(){
    this._notifier.notify(NotificationMessages.CopiedClipboard, {duration: 5, panelClass: 'success'});
  }

  save(val: HTMLInputElement){
    this._spinner.start();
    this._accountService.post2FAEnable({code: val.value}).pipe(take(1),
    catchError((error: HttpErrorResponse) => {
      this._spinner.stop();
      this._notifier.notify(NotificationMessages.invalid('Code'),{duration: 5, panelClass: 'error'})
      return throwError(() => error);
    }),
    tap((data)=>{
      this._spinner.stop();
      this._notifier.notify(NotificationMessages.TwoFactorAuthSuccessEnabled,{duration: 5, panelClass: 'success'})
        this._router.navigate(['my-account/authentication']);
    })
    ).subscribe()
  }
}
