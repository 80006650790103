import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectInputModule } from '../select-input/select-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ChildCompanyDropdownComponent } from './child-company-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    SelectInputModule,
    ReactiveFormsModule,
  ],
  declarations: [ChildCompanyDropdownComponent],
  exports:[ChildCompanyDropdownComponent]
})
export class ChildCompanyDropdownModule { }
