/* Angular Libraries */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/* Third Party Libraries */
import { Subject } from 'rxjs';

/* Services */
import { StripeService } from '@app/shared/services/stripe.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { FinanceService } from '../../finance.service';

/* Functions */

/* Interfaces */
import { PaymentBreakdown } from '@app/shared/interfaces/invoice.interface';
import { PaymentNote } from '@app/shared/constants/transaction-type.enum';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss']
})

export class PaymentConfirmComponent implements OnInit, OnDestroy {
  /* Properties */
  recordPayment: Array<PaymentBreakdown>;
  receiptPrintId: number;
  isAllTransactionSuccess: boolean = false;
  isAllTransactionFailed: boolean = false;
  hasPaymentMethod: boolean = false;
  isCard: boolean;
  paymentNote = PaymentNote;

  isProcessing = false;

  /* Private Properties */
  private _$unsubscribe: Subject<void> = new Subject<void>();

  get isAdmin() {
    return this._stripeService.isAdmin;
  }

  /* Constructor */
  constructor(
    private _router: Router,
    private _stripeService: StripeService,
    private _spinner: SpinnerService,
    private _financeService: FinanceService
  ) { }

  /* Methods */
  ngOnInit(): void {
    this._initTransactionList();
  }

  // clickPrint() {
  //   window.print();
  // }

  totalAmount(inv: PaymentBreakdown) {
    return inv.amount + inv?.creditMemoTotalAmount + inv?.unappliedPaymentTotalAmount;
  }

  private _initTransactionList() {
      this.recordPayment = JSON.parse(localStorage.getItem('stripePaymentRecord') as any)?.data;
      this.receiptPrintId = JSON.parse(localStorage.getItem('stripePaymentRecord') as any)?.receiptPrintId;

      if (!this.recordPayment || !this.recordPayment.length) {
        this._router.navigate([`/${this.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices`]);

      } else {
        this.isAllTransactionSuccess = this.recordPayment.every(data => data.isSucceeded || data.isProcessing);
        this.isAllTransactionFailed = this.recordPayment.every(data => !data.isRequiresAction && !data.isProcessing && !data.isSucceeded);
        this.hasPaymentMethod = this.recordPayment.some(data => data.cardBrand || data.bankName);

        if (this.hasPaymentMethod) {
          this.isCard = this.recordPayment.some(data => data.cardBrand);
        }
      }
  }

  downloadReceipt(transactionId: string | null = null) {
    this.isProcessing = false;

    if (this.receiptPrintId) {
			this._spinner.start();
      let transactionIdList = transactionId ? [transactionId] : this.recordPayment.map(x => x.transactionNumber).map((x: any) => x === null ? x = this.receiptPrintId : x);

			this._financeService.downloadReceiptByTransaction(transactionIdList).subscribe({
				next: (data: any) => {
          const link = document.createElement('a');
          link.href = data;
          link.setAttribute('download', `Invoice Receipt ${this.receiptPrintId}.pdf`);
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          this._spinner.stop();
				},
				error: (error) => {
					this._spinner.stop();
          this.isProcessing = false;
				},
        complete: () => {
          this.isProcessing = false;
        }
			});
		}
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
