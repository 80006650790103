import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SwitchInputComponent } from './switch-input.component';

@NgModule({
	declarations: [SwitchInputComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
	],
	exports: [SwitchInputComponent],
})
export class SwitchInputModule {}
