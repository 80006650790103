import { BehaviorSubject, finalize, map, of, ReplaySubject, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';

/* New Codes */
import { InvoiceParameters } from '@app/shared/interfaces/finance.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/core/services/user.service';
import {
	AccountActivity,
	AccountActivityList,
	CreditTableList,
	IAccountActivity,
	ICredits,
	IInvoice,
	IPayments,
	PaymentTableList,
} from '@app/shared/interfaces/invoice.interface';
import { UserTypes } from '@app/shared/constants/global-enum';

@Injectable({
	providedIn: 'root',
})
export class InvoiceService {
	lastChecked: number | null;
	selectedIds: number[] = [];

	/* New Codes */
	accountActivityFilters: IAccountActivity;

	private _accountActivityData = new ReplaySubject<any[]>(1);
	accountActivityData$ = this._accountActivityData.asObservable();

	private _accountActivityTotalItems = new ReplaySubject<number>(1);
	accountActivityTotalItems$ = this._accountActivityTotalItems.asObservable();

	paymentsFilters: IPayments;

	private _paymentsData = new ReplaySubject<any[]>(1);
	paymentsData$ = this._paymentsData.asObservable();

	private _paymentsTotalItems = new ReplaySubject<number>(1);
	paymentsTotalItems$ = this._paymentsTotalItems.asObservable();

	creditsFilters: ICredits;

	private _creditsData = new ReplaySubject<any[]>(1);
	creditsData$ = this._creditsData.asObservable();

	private _creditsItems = new ReplaySubject<number>(1);
	creditsItems$ = this._creditsItems.asObservable();

	invoiceFilters: IInvoice;

	private _invoiceData = new ReplaySubject<any[]>(1);
	invoiceData$ = this._invoiceData.asObservable();

	private _invoiceItems = new ReplaySubject<number>(1);
	invoiceItems$ = this._invoiceItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	/* Setters and getters */
	get isAdmin() {
		return this._userService.userRole === UserTypes.SourcepassAdmin;
	}

	get currentCompanyId() {
		return this._userService.companyId;
	}

	get selectedCompanyId() {
		return JSON.parse(
			localStorage.getItem(this.isAdmin ? 'companyAdmin' : 'companyUser') as any
		)?.companyId;
	}

	get companyIdType() {
		return this.isAdmin
			? this.selectedCompanyId
			: this.currentCompanyId === this.selectedCompanyId
			? null
			: this.selectedCompanyId;
	}

	constructor(private _http: HttpClient, private _userService: UserService) {}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	getValidParameters(param: any) {
		return Object.fromEntries(
			Object.entries(param).filter(
				([key, value]: any) => !['', null, undefined].includes(value)
			)
		);
	}

	getAccountActivity(
		options: IAccountActivity
	): Observable<AccountActivityList> {
		this.accountActivityFilters = options;

		const param: any = this.getValidParameters({
			Page: options.page,
			PageSize: options.pageSize,
			Column: options.column,
			Order: options.order,
			Search: options.searchText,
			ActivityStartDate: options.activityStartDate,
			ActivityEndDate: options.activityEndDate,
			Status: options.status,
			companyId: this.companyIdType,
		});

		this.isLoading = true;
		this._accountActivityData.next([]);
		this._accountActivityTotalItems.next(0);

		return this._http
			.get(`${environment.apiBaseUrl}Invoices/AccountActivities`, {
				params: param,
			})
			.pipe(
				tap((res: any) => {
					this._accountActivityData.next(
						res.data.map((acc: AccountActivity) => {
							return {
								initiatedBy: acc.initiatedBy,
								activityDate: acc.activityDate,
								activityTime: acc.activityDate,
								activity: acc.activity,
								status: acc.status,
								transactionId: acc.transactionId,
							};
						})
					);
					this._accountActivityTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getPaymentsTable(options: IPayments): Observable<PaymentTableList> {
		this.paymentsFilters = options;

		const param: any = this.getValidParameters({
			Page: options.page,
			PageSize: options.pageSize,
			Column: options.column,
			Order: options.order,
			PaymentStartDate: options.paymentStartDate,
			PaymentEndDate: options.paymentEndDate,
			Search: options.searchText,
			companyId: this.companyIdType,
		});

		this.isLoading = true;
		this._paymentsData.next([]);
		this._paymentsTotalItems.next(0);

		return this._http
			.get<PaymentTableList>(`${environment.apiBaseUrl}Invoices/Payments`, {
				params: param,
			})
			.pipe(
				tap((res: any) => {
					this._paymentsData.next(res.data);
					this._paymentsTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getCreditTable(options: ICredits): Observable<CreditTableList> {
		this.creditsFilters = options;

		const param: any = this.getValidParameters({
			Page: options.page,
			PageSize: options.pageSize,
			Column: options.column,
			Order: options.order,
			InvoiceStartDate: options.invoiceStartDate,
			InvoiceEndDate: options.invoiceEndDate,
			TransactionType: options.transactionType,
			companyId: this.companyIdType,
			Search: options.searchText,
		});

		this.isLoading = true;
		this._creditsData.next([]);
		this._creditsItems.next(0);

		return this._http
			.get<CreditTableList>(`${environment.apiBaseUrl}Invoices/Credits`, {
				params: param,
			})
			.pipe(
				tap((res: any) => {
					this._creditsData.next(res.data);
					this._creditsItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getInvoices<TResult = Object>(options: InvoiceParameters) {
		this.invoiceFilters = options;

		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			InvoiceStartDate: options.startDate ? options.startDate : '',
			InvoiceEndDate: options.endDate ? options.endDate : '',
			DueStartDate: options.dueStartDate ? options.dueStartDate : '',
			DueEndDate: options.dueEndDate ? options.dueEndDate : '',
			CompanyIds: options.companyIds,
			HaveBalance: options.haveBalance,
			companyId: options.companyId,
		} as any;

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0 &&
			options.statusIds[0] !== 0 &&
			options.statusIds[0] !== null
		) {
			param.StatusIds = options.statusIds;
		}

		if (
			options.typeIds !== undefined &&
			options.typeIds !== null &&
			options.typeIds?.length > 0
		) {
			param.TypeIds = options.typeIds;
		}

		if (options.companyIds !== undefined && options.companyIds !== null) {
			param.CompanyIds = options.companyIds;
		}

		if (options.companyId !== undefined && options.companyId !== null) {
			param.companyId = options.companyId;
		} else {
			delete param.companyId;
		}

		if (options.isAutopay === true || options.isAutopay === false) {
			param.isAutopay = options.isAutopay;
		}

		if (
			options.transactionType === 'CustCred' ||
			options.transactionType === 'CustInvc' ||
			options.transactionType === 'CustPymt'
		) {
			param.transactionType = options.transactionType;
		}

		this.isLoading = true;
		this._invoiceData.next([]);
		this._invoiceItems.next(0);

		return this._http
			.get<TResult>(`${environment.apiBaseUrl}Invoices/Invoices`, {
				params: param,
			})
			.pipe(
				tap((res: any) => {
					this._invoiceData.next(res.data);
					this._invoiceItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	get totalSelectedAmount() {
		return this.selectedIds
			.map((e: any) => Number(e.balance))
			.reduce((a: any, b: any) => a + b, 0);
	}

	getSelectedId(data: AnalyserOptions, isChecked: boolean): Observable<any[]> {
		const selectedIds = this.collateIds(data, isChecked);
		return of(selectedIds);
	}

	collateIds(value: any, isChecked: boolean) {
		let selectedIds = this.selectedIds;
		if (Array.isArray(value)) {
			if (isChecked === true) {
				const filteredEventValue = value.filter((x) => {
					return !selectedIds.some((y: any) => y.id === x.id);
				});
				filteredEventValue.forEach((x) => {
					selectedIds.push(x);
				});
			} else {
				const selected = value as any[];
				selectedIds = selectedIds.filter((x: any) => {
					return selected.findIndex((val) => val.id === x.id) === -1;
				});
			}
		} else {
			if (isChecked === true) {
				selectedIds.push(value);
			} else {
				const id = selectedIds.findIndex((e: any) => {
					return e.id === value.id;
				});
				selectedIds.splice(id, 1);
			}
		}
		return selectedIds;
	}

	clearSelectedIds() {
		this.selectedIds = [];
	}
}
