import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	NavigationEnd,
	Router,
} from '@angular/router';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { CompanyService } from '@app/modules/company/company.service';
import { KnowledgeBaseService } from '@app/modules/knowledge-base/knowledge-base.service';
import { select, Store } from '@ngrx/store';
import { filter, Observable, Subscription, switchMap } from 'rxjs';
import { TbreadcrumbsText } from './breadcrumb.constants';
import { BreadcrumbService } from './breadcrumb.service';
import { NOURL } from './no-url.constants';
import { navbarFixedSelector } from '../configuration/store/configuration.selector';

@Component({
	selector: '[nav-breadcrumb]',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnDestroy {
	appName = 'Quest';
	paths: Path[];
	slashPaths: string[];
	relativePath: ActivatedRoute;
	navSubscription: Subscription;
	breadcrumbText: null | TbreadcrumbsText;
	lastPath: string;
	pathNoURL = JSON.parse(JSON.stringify(NOURL));
	reports = [
		'reports',
		'portal-usage',
		'company-report/:year/:month',
		':companyId',
		':userId',
	];

	companySub: Subscription;
	navbarFixed$: Observable<boolean>;
	constructor(
		private _router: Router,
		private _route: ActivatedRoute,
		private _title: Title,
		public _companyService: CompanyService,
		public _kBService: KnowledgeBaseService,
		public _companiesService: CompaniesService,
		private _breadcrumbService: BreadcrumbService,
		private store: Store<AppStateInterface>
	) {
		this.navbarFixed$ = this.store.pipe(select(navbarFixedSelector));
		this.navSubscription = this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				switchMap(() => this._breadcrumbService.breadcrumbsText)
			)
			.subscribe((breadcrumbsText) => {
				this.breadcrumbText = breadcrumbsText;
				this.paths = this.getPath(this._route.snapshot);
				this.slashPaths = this.getSlashPaths(this.paths);
				this.getFinalPath();
				this.relativePath = this.getRelativePath(this._route);
				this.lastPath = this.paths[this.paths.length - 1].path;
				// this.manipulatePath();
				if (this.paths?.length)
					this._title.setTitle(
						this.paths[this.paths.length - 1].title + ' - ' + this.appName
					);
			});
	}

	getFinalPath() {
		this.paths.forEach((path, index) => {
			// if (path.route === 'companies' && this.paths.length > index + 1) {
			// 	this.paths[index + 1].path = 'Has-URL';
			// 	this.slashPaths[index + 1] = `/companies/contact-setting/profile/${
			// 		this.breadcrumbText!['_companyId']
			// 	}`;
			// }
			if (
				path.route === 'contacts' &&
				index === 0 &&
				this.paths.length > index + 1
			) {
				this.paths[index + 1].path = 'Has-URL';
				let urlPathContacts = `/contacts/${this.breadcrumbText!['_contactId']}/profile`;

				this.slashPaths[index + 1] = urlPathContacts;
			} else if (path.route === 'contacts' && index > 1) {
				this.paths[index].path = 'Has-URL';
				let urlPathCompany = `/companies/contact-setting/directory/${
					this.breadcrumbText!['_companyId']
				}/contacts/contact-setting/profile/${
					this.breadcrumbText!['_contactId']
				}`;
				this.slashPaths[index] = urlPathCompany;
			} else if (path.route === 'groups/:companyId' && index > 1) {
				this.paths[index].path = 'Has-URL';
				let urlPathCompany = `/companies/contact-setting/groups/${
					this.breadcrumbText!['_companyId']
				}`;
				this.slashPaths[index] = urlPathCompany;
			} else if (path.route === 'groups/:companyId' && index > 1) {
				this.paths[index].path = 'Has-URL';
				let urlPathCompany = `/companies/contact-setting/groups/${
					this.breadcrumbText!['_companyId']
				}`;
				this.slashPaths[index] = urlPathCompany;
			} else if (this.reports.includes(path.route)) {
				this.paths[index].path = path.route;
				const locpath = window.location.pathname;
				const portalPath = '/reports/portal-usage';
				if (path.route.includes('reports')) {
					this.slashPaths[index] = '/reports';
				} else if (path.route.includes('portal-usage')) {
					this.slashPaths[index] = portalPath;
				} else if (path.route.includes('company-report')) {
					let i = locpath.indexOf('company-report');
					let companyReportPath = locpath
						.substring(i)
						.split('/')
						.slice(0, 3)
						.join('/');
					this.slashPaths[index] = portalPath + '/' + companyReportPath;
				} else if (path.route.includes('companyId')) {
					this.slashPaths[index] = '../';
				}
			}
		});
	}

	getPath(snapshot: ActivatedRouteSnapshot) {
		let path: Path[] = [];
		if (
			(!!snapshot.routeConfig!.path ||
				(snapshot.routeConfig &&
					snapshot.routeConfig.data &&
					snapshot.routeConfig!.data!['customRoute'])) &&
			!!snapshot.routeConfig!.data?.['title']
		) {
			if (this.pathNoURL.includes(snapshot.routeConfig?.path) || snapshot.data['hasUrl'] === false) {
				path.push({
					title: snapshot.data?.['title'],
					path: 'No-URL',
					route: snapshot.routeConfig!.path!,
				});
				// console.log('routeConfig has path hat includes in path no url')
			} else if (
				snapshot.data['title'] &&
				snapshot.data['title'].startsWith('_')
			) {
				if (snapshot.data['hasUrl'] && snapshot.data['hasUrl'] === true) {
					// console.log('routeConfig has url')
					path.push({
						title: this.breadcrumbText![
							snapshot.data['title'] as keyof TbreadcrumbsText
						] as string,
						path: snapshot.routeConfig!.path!,
						route: snapshot.routeConfig!.path!,
						queryParams: snapshot.queryParams,
					});
				} else {
					// console.log('routeConfig has no url')
					path.push({
						title: this.breadcrumbText![
							snapshot.data['title'] as keyof TbreadcrumbsText
						] as string,
						path: 'No-URL',
						route: snapshot.routeConfig!.path!,
						allowRedirect: !!snapshot.data['allowRedirect'],
					});
				}
			} else if (!!snapshot.routeConfig!.path) {
				// console.log('routeConfig has path')
				path.push({
					title: snapshot.data?.['title'],
					path: snapshot.routeConfig!.path,
					queryParams: snapshot.queryParams,
					route: snapshot.routeConfig!.path!,
				}); // JP: Added query params so it will keep the some features based on qparams value
			}
		}
		if (snapshot.firstChild)
			path = path.concat(this.getPath(snapshot.firstChild));
		return path;
	}

	getRelativePath(route: ActivatedRoute): ActivatedRoute {
		return route.firstChild ? this.getRelativePath(route.firstChild) : route;
	}

	getSlashPaths(paths: Path[]) {
		let slashPaths: string[] = [];
		for (let i = paths.length - 1; i >= 0; i--) {
			if (paths[i + 1]?.route) {
				slashPaths[i] = '../'.repeat(paths[i + 1]?.route.split('/').length);
			} else {
				slashPaths[i] = '';
			}
			if (i < paths.length - 1) {
				slashPaths[i] += slashPaths[i + 1];
			}
		}
		return slashPaths;
	}

	ngOnDestroy(): void {
		if (!this.navSubscription.closed) this.navSubscription.unsubscribe();
		if (this.companySub) this.companySub.unsubscribe();
		this._title.setTitle(this.appName);
	}
}

interface Path {
	title: string;
	path: string;
	queryParams?: any;
	absolutePath?: string;
	route: string;
	allowRedirect?: boolean;
}
