import { LocalFigmaIconImgModule } from './../../local-figma-icon-img/local-figma-icon-img.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule, LocalFigmaIconImgModule
  ],
  declarations: [],
  exports: []
})
export class OnlineOfflineModule { }
