import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-tertiary-button',
	templateUrl: './tertiary-button.component.html',
	styleUrls: ['./tertiary-button.component.scss'],
})
export class TertiaryButtonComponent implements OnInit {
	@Input() btnText: string;
	@Input() iconName: string;
	@Input() isProcessing = false;
	@Input() isFilterToggle = false;
	@Input() isFilterOn = false;
	@Input() isTab = false;
	@Input() isActiveTabOrButton: boolean;
	@Input() disabled = false;
	@Input() isIconLeft = true;
	@Input() isSelected: boolean = false;

	constructor(private _cd: ChangeDetectorRef) {}

	ngOnInit(): void {}

	marginBottom(isCollapsed: boolean): string {
		return isCollapsed ? '0' : '0.5px';
	}

	onUpdateChevronIcon() {
		this._cd.detectChanges();
	}
}
