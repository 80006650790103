import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTrackerDirective } from './activity-tracker.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [	
      ActivityTrackerDirective
   ],
  exports: [
    ActivityTrackerDirective
  ]
})
export class ActivityTrackerModule { }
