import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { FigmaIconImgModule } from './../../figma-icon-img/figma-icon-img.module';

import { SmoothScrollDirective } from '@app/shared/navigation/dashboard-layout/smooth-scroll.directive';

import { SelectInputComponent } from './select-input.component';
import { SelectInputAsyncComponent } from './select-input-async/select-input-async.component';

@NgModule({
	declarations: [SelectInputComponent, SelectInputAsyncComponent],
	imports: [
		CommonModule,
		MatSelectModule,
		ScrollingModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		FigmaIconImgModule,
		SmoothScrollDirective,
	],
	exports: [SelectInputComponent, SelectInputAsyncComponent],
})
export class SelectInputModule {}
