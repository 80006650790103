import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { DynamicTableSettings } from '@app/modules/dashboard/widget-dynamic-table/widget-dynamic-table.component';
import { TableMessages, UserTypes } from '@app/shared/constants';
import { MailboxUsage, TableDynamicColumns, WidgetDynamicTable, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-mailbox-usage',
  templateUrl: './mailbox-usage.component.html',
  styleUrls: ['./mailbox-usage.component.scss']
})
export class MailboxUsageComponent implements OnInit, OnDestroy {

  dataSource:WidgetDynamicTable | LinearGauge;
  systemRoleId: UserTypes;
  #mailbox$: Observable<MailboxUsage[] | null>
  usageSettings:DynamicTableSettings = { // need to improve this settings
    ngStyle:{
      td:{'textAlign': 'left !important'},
      th:{'textAlign': 'left !important'},
      row: {},
    },
  }

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: ()=>{}
  noClick: ()=>{}
  seeMoreLink = '';

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  #subscription = new Subscription();

  constructor(
    private _dashboard:DashboardService,
    private _cd:ChangeDetectorRef,
  ){
    _dashboard.initMailboxUsage();
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  ngOnInit() {
    this.#initProperties();
  }

  #initProperties(){
    this.systemRoleId = this._dashboard.systemRoleId;
    this.#mailbox$ = this._dashboard.mailboxUsage$;
    this.#subscription.add(
      this.#mailbox$.subscribe(v=>{
        this.#setData(v);
      })
    );
  }

  #setData(mailbox:MailboxUsage[] | null){
    if(mailbox){
      if(this.systemRoleId < 3){
        this.#createMailboxUsersData(mailbox)
      }else{
        this.dataSource = this.#createMailboxUsageData(mailbox)
      }
    }
  }

  #createMailboxUsersData(mailbox: MailboxUsage[]){
      this.source =  mailbox
      this.columns = this.#getColumns()
      this.displayedColumns = this.#getDisplayedColumns()

      this.dataTable$.next(this.getDataTable())
  }

  #createMailboxUsageData(one: MailboxUsage[]):LinearGauge{
    let allocated = 0, used = 0;
    if(one.length > 0){
      allocated = toNumbers(one[0].allocatedStorage)
      used = toNumbers(one[0].storageUsed)
    }
    return {
      startValue: 0,
      endValue: allocated,
      value: used,
      title: '365 Mailbox Usage Stats',
      range1:getHalf(allocated),
      range2:getHalf(allocated, 'second'),
    }
  }

  #getColumns():any[]{
    return [{
        text: 'Name',
        matColumnDef: 'fullName'
      },
      {
        text: 'Item Count',
        matColumnDef:'itemCount',
      },
      {
        text: 'Storage Used [GB]',
        matColumnDef:'storageUsed',
      },
      {
        text: 'Allocated Storage [GB]',
        matColumnDef:'allocatedStorage',
      },
    ]
  }

  #getDisplayedColumns(){
    return ['fullName', 'itemCount', 'storageUsed', 'allocatedStorage'];
  }

  get dataTable(){
    return this.dataSource as WidgetDynamicTable;
  }

  get usage(){
    return this.dataSource as LinearGauge
  }

  formatUsage(val:number){
    return `${val} GB`
  }

  getOverValue(start:number, end:number){
    return `${start}GB / ${end}GB`
  }

  usageVsRange(usage:number, range:number):{isRange:boolean, range1:number}{
    if(usage < range)
      return {
        isRange: true,
        range1: range
      }
    else
      return {
        isRange: true,
        range1: usage
      };
  }

  getCustomTicks(endRange:number, val: number){
    return [...getFourth(endRange), ...[val]]
  }

  getDataTable():WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      seeMoreLink: this.seeMoreLink,
      displayedColumns: this.displayedColumns
    }
  }

}

interface LinearGauge{
  startValue: number,
  endValue: number,
  value: number,
  title: string,
  range1?: number,
  range2?: number,
}

function toNumbers(usage:string){
  return parseInt(usage.toLowerCase().replace(' gb', ''));
}

function getHalf(val:number, indicator:'first' | 'second'='first'){
  if(indicator == 'first'){
    return Math.round(val / 2)
  }else{
    return val
  }
}

function getFourth(val:number){
  const first = val / 4
  return [first, first*2, first*3, val]
}
