import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { HttpError } from '@microsoft/signalr';
import { CwpodVerificationService } from '../cwpod-verification.service';

@Component({
  selector: 'app-resend-pin',
  templateUrl: './resend-pin.component.html',
  styleUrls: ['./resend-pin.component.scss']
})
export class ResendPinComponent implements OnInit{

  contact:{contact:string, type:string} = {
    contact: ContactLabel.sms,
    type:'sms'
  }

  sendBy: any[] = [
    {'value':'sms', 'label': 'SMS'},
    {'value':'email', 'label': 'Email'},
  ];

  sendByFc = new FormControl(SendBy.sms);
  userContact = new FormControl('');
  ticketId: number;
  selectValues = {
    email: '',
    sms: '',
  }

  constructor(
    private _podVerification: CwpodVerificationService,
    private _route: ActivatedRoute,
    private _toastMessageService: ToastMessageService,
    public _spinnerService: SpinnerService,
  ){
    this.ticketId = this._route.snapshot.params['id'];
  }

  ngOnInit() {
    this.sendByFc.valueChanges.subscribe(
      (value) => {
        if(value == SendBy.sms){
          this.contact.type = 'sms';
          this._setContactLabel();
          this.userContact.patchValue(this.selectValues.sms)
        }else if (value == SendBy.email){
          this.contact.type = 'email';
          this._setContactLabel();
          this.userContact.patchValue(this.selectValues.email)
        }
      }
    );

    this._podVerification.getUserContacts(this.ticketId, this.contact.type).subscribe(
      {
        next:
          (v)=>{
            if(v.hasOwnProperty('email')){
              let x = v as {email:string}
              this.selectValues.email = x.email;
            }else if(v.hasOwnProperty('sms')){
              let x = v as {sms:string}
              this.selectValues.sms = x.sms;
            }
          },
        complete:()=> this._setContactLabel()
      }
    )
  }

  private _setContactLabel(){
    if(this.contact.type == 'sms'){
      this.contact.contact = ContactLabel.sms;
      this.userContact.patchValue(this.selectValues.sms)
    }else{
      this.contact.contact = ContactLabel.email;
      this.userContact.patchValue(this.selectValues.email)
    }
  }

  sendVerification(){
    console.warn('contact', this.contact.contact);
    console.warn('type', this.contact.type);
    console.warn('ticketId', this.ticketId);
    this._spinnerService.start();
    this._podVerification.sendUserValidation(this.ticketId, this.contact.type).subscribe(
      {
        next: (resp)=>{
          this._spinnerService.stop();
        },
        error:(err:HttpError)=>{
          this._spinnerService.stop();
          this._toastMessageService.showErrorMessage(err);
        }
      }
    )
  }
}
enum SendBy {
  'sms' = 'sms',
  'email' = 'email'
}

enum ContactLabel {
  'sms' = 'User Phone:',
  'email' = 'User Email:',
}
