// import { createSelector } from '@ngrx/store';
// import { IListEmailTemplate } from './add-email-template.interface';
// import { AppStateInterface } from '@app/core/store/app-state.interface';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { EmailTemplateGlobalStyle } from '..';
import { createSelector } from '@ngrx/store';

// export const emailTemplateSelector = (
// 	state: AppStateInterface
// ): IListEmailTemplate => state.emailTemplate;

// export const pageSelector = createSelector(
// 	emailTemplateSelector,
// 	(state: IListEmailTemplate) => state.page
// );

// export const pageSizeSelector = createSelector(
// 	emailTemplateSelector,
// 	(state: IListEmailTemplate) => state.pageSize
// );

// export const orderSelector = createSelector(
// 	emailTemplateSelector,
// 	(state: IListEmailTemplate) => state.order
// );

// export const columnSelector = createSelector(
// 	emailTemplateSelector,
// 	(state: IListEmailTemplate) => state.query
// );

export const emailTemplateSelector2 = (
	state: AppStateInterface
): EmailTemplateGlobalStyle => state.emailTemplate2;

const textBlock = createSelector(
	emailTemplateSelector2,
	(state: EmailTemplateGlobalStyle) => state.textBlock
);

const buttonBlock = createSelector(
	emailTemplateSelector2,
	(state: EmailTemplateGlobalStyle) => state.buttonBlock
);

const container = createSelector(
	emailTemplateSelector2,
	(state: EmailTemplateGlobalStyle) => state.container
);

export const EmailTemplateGlobalStyleSelector = {
	textBlock,
	buttonBlock,
	container,
};
