import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { debounceTime, Observable, Subject } from 'rxjs';

@Directive({
  selector: '[fileDragDropFile]'
})
export class DragDropFileDirective implements OnInit{

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
  @HostBinding('class.fileover') fileOver = false;
  private lastEvtSource: Subject<string> = new Subject();
  lastEvt = this.lastEvtSource.asObservable();
  @Output() fileDropped = new EventEmitter<any>();
  @Input() isCustom: boolean = false;
  @Input() stopPropagating: boolean = true;
  private _timeout: any;


  ngOnInit(): void {
    this.lastEvt.pipe(
      debounceTime(250)
    ).subscribe((data)=>{
      if(data === 'over'){
        this.addCustomizations();
      }else
      if(data === 'leave' || data === 'drop'){
        this.removeCustomizations();
      }
    })
  }

  // Dragover listener
  @HostListener('dragenter', ['$event']) onDragEnter(event: Event) {
    this.addCustomizations();
  }
  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    // set class to draggable-container
    // console.log('overt')
    event.preventDefault();
    if(this.stopPropagating){
      event.stopPropagation();
    }
    clearTimeout(this._timeout);
    this.lastEvtSource.next('over')
    // this.addCustomizations();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: Event) {
    // console.log('leave')
    event.preventDefault();
    if(this.stopPropagating){
      event.stopPropagation();
    }
    // this.removeCustomizations();
    this.lastEvtSource.next('leave')
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => { this.fileOver = false; }, 1000);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(event: any) {
    // console.log('drop')
    event.preventDefault();
    if(this.stopPropagating){
      event.stopPropagation();
    }
    this.lastEvtSource.next('drop')
    // this.removeCustomizations();
    this.fileOver = false;
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }


  }

  addCustomizations(){
    if(this.isCustom){
      if(!this.elRef.nativeElement.classList.contains('on-file-hover')){
        this.renderer.addClass(this.elRef.nativeElement,'on-file-hover')
      }
    }
  }

  removeCustomizations(){
    if(this.isCustom){
      if(this.elRef.nativeElement.classList.contains('on-file-hover')){
        this.renderer.removeClass(this.elRef.nativeElement,'on-file-hover')
      }
    }
  }


}
