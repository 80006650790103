import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})
export class DragDropDirective {

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
  @HostBinding('class.fileover') fileOver = false;
  @Output() fileDropped = new EventEmitter<any>();
  @Input() isCustom: boolean = false;
  @Input() stopPropagating: boolean = true;
  private _timeout: any;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    // set class to draggable-container
    // console.log('over')
    event.preventDefault();
    if(this.stopPropagating){
      event.stopPropagation();
    }
    clearTimeout(this._timeout);
    this.addCustomizations();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: Event) {
    // console.log('leave')
    event.preventDefault();
    if(this.stopPropagating){
      event.stopPropagation();
    }
    this.removeCustomizations();
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => { this.fileOver = false; }, 1000);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(event: any) {
    // console.log('drop')
    event.preventDefault();
    if(this.stopPropagating){
      event.stopPropagation();
    }
    this.removeCustomizations();
    this.fileOver = false;
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }


  }

  addCustomizations(){
    if(this.isCustom){
      if(!this.elRef.nativeElement.classList.contains('on-file-hover')){
        this.renderer.addClass(this.elRef.nativeElement,'on-file-hover')
      }
    }
  }

  removeCustomizations(){
    if(this.isCustom){
      if(this.elRef.nativeElement.classList.contains('on-file-hover')){
        this.renderer.removeClass(this.elRef.nativeElement,'on-file-hover')
      }
    }
  }


}
