import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { CompaniesComponent } from './companies/companies.component';
import { GroupsComponent } from './groups/groups.component';
import { UsersComponent } from './users/users.component';
import { SelectInputModule } from '@app/shared/components/select-input/select-input.module';
import { MultiSelectInputModule } from '@app/shared/components/multi-select-input/multi-select-input.module';
import { CheckboxListModule } from '../checkbox-list/checkbox-list.module';
import { FilterModule } from '../filter/filter.module';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';





@NgModule({
  declarations: [
    MainComponent,
    CompaniesComponent,
    GroupsComponent,
    UsersComponent,
  ],
  imports: [
    CommonModule,
    SelectInputModule,
    MultiSelectInputModule,
    CheckboxListModule,
    FilterModule,
    FormsModule
  ],
  exports:[
    MainComponent,
    CompaniesComponent,
    GroupsComponent,
    UsersComponent
  ]
})
export class PermissionsMainModule { }
