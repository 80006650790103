import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationMessages } from '@app/shared/constants';
import { Global, Pages } from '@app/shared/constants';

import { SpinnerService } from '@app/core/services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { GlobalSettingsService } from '../../global-settings.service';
import { GlobalSettingsType } from '../global-settings/global-setting.constant';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-edit-global-settings',
  templateUrl: './edit-global-settings.component.html',
  styleUrls: ['./edit-global-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGlobalSettingsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  TODO: "DELETE COMPONENT"
  form = new FormGroup({
    data: new FormControl<number[]>([], Validators.required),
  });
  genericOption: number[] = [];

  moduleId: GlobalSettingsType;
  notification: string;

  constructor(
    public spinner: SpinnerService,
    private _cd: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _toastMessageService: ToastMessageService,
    private _globalSettings: GlobalSettingsService,
    private _breadcrumbService: BreadcrumbService
  ) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._activatedRoute.params.subscribe((param: any) => {
      this.moduleId = GlobalSettingsType[
        param.module
      ] as unknown as GlobalSettingsType;
      this._breadcrumbService.updateGlobalSettingsType(this.moduleId);
    });
  }

  get headerName(): string {
    return `${this._getPageText('UI')}`;
  }

  get labelName() {
    return `${this._getPageText('UI')}`;
  }

  ngOnInit() {
    this.spinner.start();
  }

  async ngAfterViewInit() {
    await this._getDropdownOptions();
  }

  ngOnDestroy() {
    this.spinner.stop();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const payload = {
      permissionModule: this.moduleId,
      ids: this.form.get('data')?.value,
    };

    this.spinner.start();
    this._globalSettings.updateData(payload).subscribe({
      error: (error) => {
        this._toastMessageService.showErrorMessage(error);
      },
      complete: () => {
        this._toastMessageService.showSuccessMessage(this.notification);
        this._router.navigate(['../'], { relativeTo: this._activeRoute });
      },
    });
  }

  private _setData(dataWithAccess: any) {
    this.form = this._fb.group({
      data: [dataWithAccess.id, Validators.required],
    });
  }

  private _getDropdownOptions() {
    return new Promise((resolve) => {
      this._globalSettings
        .getGlobalDropdown(this.moduleId)
        .subscribe((resp: any) => {
          const id = resp
            .filter((data: { hasAccess: boolean }) => data.hasAccess)
            .map((data: { id: number }) => data.id);

          this.form.get('data')?.setValue(id);
          this.genericOption = resp;
          this._cd.markForCheck();
          this.spinner.stop();
          resolve(resp);
        });
    });
  }

  private _getPageText(flag?: string): string {
    let label = '';

    switch (Number(this.moduleId)) {
      case Global.APPLICATIONS:
        label = NotificationMessages.ApplicationSuccessDeleted;
        this.notification = NotificationMessages.ApplicationSuccessUpdated;

        if (flag === 'UI') {
          label = Pages.APPLICATIONS;
        }
        break;

      case Global.KNOWLEDGE_BASE:
        label = NotificationMessages.KnowledgeBaseSuccessDeleted;
        this.notification = NotificationMessages.KnowledgeBaseSuccessUpdated;

        if (flag === 'UI') {
          label = Pages.KNOWLEDGE_BASE;
        }
        break;

      case Global.SERVICE_AND_REQUEST:
        label = NotificationMessages.RequestFormDeleted;
        this.notification = NotificationMessages.ServiceAndRequestUpdated;

        if (flag === 'UI') {
          label = Pages.SERVICE_AND_REQUEST;
        }
        break;

      default:
        break;
    }

    return label;
  }
}
