import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { TicketContacts } from '../messaging/view-ticket/ticket.interface';
import { getInitials } from '@app/shared/functions/user-initials.functions';

@Component({
	selector: 'carbon-copy',
	templateUrl: './carbon-copy.component.html',
	styleUrls: ['./carbon-copy.component.scss'],
})
export class CarbonCopyComponent implements OnInit, OnChanges {
	@Input() users: TicketContacts[] = []; // users with id, full name and images
	@Input() isShowOthers: boolean = false;
	@Output() onClickModal: EventEmitter<void> = new EventEmitter<void>();

	// props
	private _defaultProfileImg = 'assets/images/profile-pic/profilePic.png';
	guestImage = {
		url: '',
		alt: '',
	};
	userAndExernalContactTooltip = ''; //this._userAndExernalContactTooltip();
	usersFirstFourImages: any;
	private _noProfileCtr = 0;

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['users']) {
			this.userAndExernalContactTooltip = this._userAndExernalContactTooltip();
			this.usersFirstFourImages = this._usersFirstFourImages();
		}
	}

	get summarizedUserEmails(): string {
		// names and 4 others // to add, tooltip for 'others'
		let users = this.users,
			emails = '';
		if (users.length <= 0) return '';
		if (users.length > 0)
			emails = users
				.slice(0, users.length > 1 ? 2 : 1)
				.map((v) => (v.fullName ? v.fullName : v.email))
				.join(', ');
		return emails;
	}

	get others() {
		let others = '',
			users = this.users;
		if (this._isThereHiddenUser) {
			let hidden = users.length - 2;
			others = `${hidden} other`;
			others += hidden > 1 ? 's' : '';
		}
		return others;
	}

	get hiddenUsersTooltip() {
		let hidden = '';
		if (this._isThereHiddenUser) {
			hidden = this.users
				.slice(2)
				.map((v) => (v.fullName ? v.fullName : v.email))
				.join(', ');
		}
		return hidden;
	}

	get hiddenUsersPlusTooltip() {
		let hidden = '';
		if (this._isThereHiddenUser && this.users.length > 4) {
			hidden = this.users
				.slice(4)
				.map((v) => (v.fullName ? v.fullName : v.email))
				.join(', ');
		}
		return hidden;
	}

	private _usersFirstFourImages() {
		this._noProfileCtr = 0;
		return this.users.slice(0, 4); /* .map((v) => {
			const { firstName, lastName, email } = v;
			return {
				imagePath: v.imagePath ?? this._defaultProfileImg,
				fullName: v.fullName ? v.fullName : v.email,
				hasImage: v.imagePath ? true : false,
				isAnonymous: !v.fullName,
				initials: getInitials({ firstName, lastName, email }),
				noProfileCtr: v.imagePath ? 0 : ++this._noProfileCtr,
			};
		}); */
	}

	get noOfHiddenUsersImage() {
		return this.users.length - this.usersFirstFourImages.length;
	}

	private get _isThereHiddenUser() {
		return this.users.length > 2;
	}

	ellipseUserImages(): string[] {
		// user images upto 4 // to add, tooltip
		return [];
	}

	hiddenUsers(): number {
		// +2 circle if more than 4 images // to add, tooltip
		return 0;
	}

	get hasUser() {
		return this.users.length > 0;
	}

	private _userAndExernalContactTooltip() {
		let userList = [...this.users];
		let internalUserTemplate = '';
		let externalUserTemplate = '';

		userList.splice(2).forEach((u) => {
			if (u.isInternalUser) {
				internalUserTemplate += `<div>${u.fullName}</div>`;
			} else {
				externalUserTemplate += `<div>${u.email}</div>`;
			}
		});

		return `
      <div class="user-contact-tooltip q-d-flex q-f-column q-jc-center q-ai-center q-g-4 q-center">
        ${
					internalUserTemplate !== ''
						? `<div class="user-contact-tooltip_user-contact font-b3">${internalUserTemplate}</div>`
						: ``
				}
        ${
					externalUserTemplate !== '' && internalUserTemplate !== ''
						? `<div class="user-contact-tooltip_hr"></div>`
						: ''
				}
        ${
					externalUserTemplate !== ''
						? `<div class="user-contact-tooltip_external-contact font-b3">${externalUserTemplate}</div>`
						: ``
				}
      </div>
    `;
	}

	openModal() {
		this.onClickModal.emit();
	}
}

interface UsersCarbon {
	id: number;
	name: string;
	image: string;
}

function dummyUsers() {
	return [
		{
			id: 1,
			name: 'Pepper Potts',
			image: 'img',
		},
		{
			id: 2,
			name: 'Stephen Strange',
			image: '',
		},
		{
			id: 3,
			name: 'Wanda Maximoff',
			image: '',
		},
		{
			id: 4,
			name: 'Maria Hill',
			image: '',
		},
		{
			id: 5,
			name: 'Steve Rogers',
			image: '',
		},
		{
			id: 6,
			name: 'Peter Parker',
			image: '',
		},
	];
}
