import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { HttpError } from '@microsoft/signalr';
import { Observable, of, timeout } from 'rxjs';
import { CWPodMode, CwpodVerificationService, CWPodVerificationState } from './cwpod-verification.service';

@Component({
  selector: 'app-cwpod-verification',
  templateUrl: './cwpod-verification.component.html',
  styleUrls: ['./cwpod-verification.component.scss'],
  providers: [CwpodVerificationService]
})
export class CwpodVerificationComponent implements OnInit {
  ticketId: number
  isVerificationSuccess$:Observable<boolean>;
  contact = {
    contact: '',
    type: 'number'
  };
  view:CWPodMode = 'verify';
  CWPodState: Observable<CWPodVerificationState>;

  constructor(
    private _route: ActivatedRoute,
    private _podVerification: CwpodVerificationService,
    private _toastMessageService: ToastMessageService,
    public spinnerService: SpinnerService,
    private _cd:ChangeDetectorRef,
    private _router: Router,
  ){
    this.ticketId = this._route.snapshot.params['id'];
    this.CWPodState = this._podVerification.state;
    this.CWPodState.subscribe(v=>{
      this.view = v.mode;
      this._cd.markForCheck();
    });
    // spinnerService.start()
    // _podVerification.isAuthorized('ticket').subscribe(
    //   {
    //     next:(v)=>{spinnerService.stop()},
    //     error:(e)=>{
    //       this._router.navigateByUrl('/coming-soon');
    //       spinnerService.stop();
    //     },
    //     complete:()=>{spinnerService.stop()}
    //   }
    // )

  }

  ngOnInit() {
  }

  verifyPin(pin:string){
    this.spinnerService.start();

    this._podVerification.validateClientPin(this.ticketId, pin).subscribe(
      {
        next: (resp)=>{
          this.spinnerService.stop();
          this.isVerificationSuccess$ = of(true);
          this._podVerification.setVerificationState('success');
        },
        error: (error:HttpError)=>{
          this.spinnerService.stop();
          this._toastMessageService.showErrorMessage(error);
          this.isVerificationSuccess$ = of(false);
          this._podVerification.setVerificationState('error');
        }
      }
    )
  }

  switchTab(view:CWPodMode){
    //this.view = view;
    this._podVerification.setMode(view);
  }

}
