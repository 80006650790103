import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormUsageService } from '@app/modules/form-usage/form-usage.service';
import { FormUsage } from '@app/shared/constants/global-enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sms-confirmation-modal',
  templateUrl: './sms-confirmation-modal.component.html',
  styleUrls: ['./sms-confirmation-modal.component.scss']
})
export class SmsConfirmationModalComponent implements OnDestroy {
  private _$unsubscribe: Subject<void> = new Subject<void>(); 

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _dialogRef: MatDialogRef<SmsConfirmationModalComponent>,
    private _formUsageService: FormUsageService,
    private _router: Router
  ) { }

  onSubmitGeneralItRequest() {
    if (this._data.alternativeFormId) {
      this._router.navigateByUrl(`/service-and-support/new-ticket`, { skipLocationChange: true }).then(() => {
        this._router.navigate([`/service-and-support/new-ticket/${this._data.alternativeFormId}/create`]);
        this._dialogRef.close(false);
      });
      
    } else {
      this._formUsageService
      .getFormUsageByUsage(FormUsage.GeneralItTicket)
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next: (res: any) => {
          this._router.navigateByUrl(`/service-and-support/new-ticket`, { skipLocationChange: true }).then(() => {
            this._router.navigate([`/service-and-support/new-ticket/${res[0]?.srFormId}/create`]);
            this._dialogRef.close(false);
          });
        },
      });
    }
  }

  onClickRedirectToSmsVerification() {
    this._dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
