import { createAction, props } from '@ngrx/store';

import { IApplication } from './application.interface';

export enum ActionTypes {
	APPLICATION_UPDATE = '[Application List] Update',
	APPLICATION_UPDATE_SUCCESS = '[Application List] Update Success',
}

export const applicationUpdateAction = createAction(
	ActionTypes.APPLICATION_UPDATE,
	props<Partial<IApplication>>()
);

export const applicationUpdateSuccessAction = createAction(
	ActionTypes.APPLICATION_UPDATE_SUCCESS
);
