<div class="radio-input" [class.disabled-radio-input]="isDisabled" (click)="radioButtonControl.click(); onTouched()">
  <input
    #radioButtonControl
    type="radio"
    [id]="id"
    [name]="name"
    [(ngModel)]="radioValue"
    [value]="value"
    [disabled]="isDisabled"
    hidden/>

  <div class="radio-input_icon">
    <ng-container *ngIf="radioButtonControl.checked; else unselectRadioIcon">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="9" stroke="#53B6ED" stroke-width="2"/>
        <circle cx="10" cy="10" r="5" fill="#53B6ED" stroke="#53B6ED" stroke-width="2"/>
      </svg>
    </ng-container>
  </div>

  <ng-template #unselectRadioIcon>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9" stroke="#AFB0B3" stroke-width="2"/>
    </svg>
  </ng-template>

  <label class="radio-input_label font-b1">{{ label }}</label>
</div>




