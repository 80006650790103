<div class="card h-100">
	<div class="card-header p-0">
		<div class="d-lg-flex">
			<div class="px-4 pt-4">
				<h3 class="notif-title font-h3">Set Notifications</h3>
				<span class="notif-description">Choose what you’d like to be notified about - whether by notification on Quest or via email</span>
			</div>
		</div>
	</div>
	<div class="main-contents spdmin-profile">
		<div class="categories-form">
			<app-notification-settings 
				[userId]="userId"
				(onReloadAuditTrail)="auditTrailRefresh()">
			</app-notification-settings>
		</div>
	</div>
</div>