import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { apiUrl, GlobalAccess } from "../functions/options";
import { SetAccessFlag } from "../interfaces/access-settings/set-access-flag.interface";
import { SetOverrideFlag } from "../interfaces/access-settings/set-override-flag.interface";

class GlobalSettingsEndPoints{
  public static readonly GlobalPermissions = 'GlobalPermissions'
  public static readonly Access_Set = 'Access_Set';
  public static readonly Access_OverrideFlag = 'Override';
  public static readonly Access_AccessFlag = 'Access';


}

@Injectable({
  providedIn: "root"
})
export class AccessSettingsService{

  constructor(private http: HttpClient){}


  updateFlag({levelAccess,moduleId,appId, isAllowed, companyId, userId, groupId}:
    {levelAccess: string,moduleId: number,appId: number,isAllowed: boolean, companyId: number | null, userId: number | null, groupId?: number | null}){
    const body: SetAccessFlag = {
      moduleId: moduleId,
      id: appId,
      isAllowed: isAllowed,
    }
    if(companyId) body['companyId'] = companyId
    if(userId) body['userId'] = userId
    if(groupId) body['groupId'] = groupId
    return this.http.post(`${environment.apiBaseUrl}${GlobalSettingsEndPoints.GlobalPermissions}/set${levelAccess}${GlobalSettingsEndPoints.Access_AccessFlag}`,body)
  }


  getOverrideFlag(levelFlag: string, moduleId: number, companyId: number, userId: number | null, groupId?: number | null): Observable<SetOverrideFlag>{
    let id;
    if(levelFlag === 'Company' || levelFlag === 'Group'){
      id = companyId
    }else if(levelFlag === 'User'){
      id = userId
    }
    return this.http.get<SetOverrideFlag>(`${environment.apiBaseUrl}${GlobalSettingsEndPoints.GlobalPermissions}/get${levelFlag}${GlobalSettingsEndPoints.Access_OverrideFlag}/${moduleId}/${id}${groupId ? '/' + groupId : ''}`)
  }


  setOverrideFlag(levelFlag: apiUrl, moduleId: GlobalAccess, companyId: number, isChanged: boolean, userId: number | null, groupId?: number | null){
    const body: {[key: string]: any} = {
      moduleId: moduleId,
      companyId: companyId,
      isChanged: isChanged
    }
    if(userId) body['userId'] = userId;
    if(groupId) body['groupId'] = groupId;
    return this.http.post(`${environment.apiBaseUrl}${GlobalSettingsEndPoints.GlobalPermissions}/set${levelFlag}${GlobalSettingsEndPoints.Access_OverrideFlag}`,body)
  }

  setAccessFlag(
    levelFlag: apiUrl,
    moduleId: GlobalAccess,
    id: number,
    isAllowed: boolean,
    companyId: number,
    userId: number,
    groupId?: number
  ): Observable<SetAccessFlag> {
    const payload: SetAccessFlag = {
      moduleId,
      id,
      isAllowed
    };

    if(levelFlag === apiUrl.COMPANY) {
      payload.companyId = companyId;
    } else if(levelFlag === apiUrl.CONTACT) {
      payload.companyId = companyId;
      payload.userId = userId;
    } else if(levelFlag === apiUrl.GROUP) {
      payload.companyId = companyId;
      payload.groupId = groupId;
    }

    const url = `${environment.apiBaseUrl}${GlobalSettingsEndPoints.GlobalPermissions}/set${levelFlag}${GlobalSettingsEndPoints.Access_AccessFlag}`;

    return this.http.post<SetAccessFlag>(url, payload);
  }

}
