<ng-container *ngIf="!isStillLoading; then main; else loading;"></ng-container>


<ng-template #main>
  <div class="table-responsive"> <!--applist-table table-striped cat-apps widget-table-->
    <div>
      <app-column-slider
      [(displayedColumns)]="displayedColumns"
      ></app-column-slider>
      <table
        class="table table-flush"
        mat-table
        [dataSource]="kbData"
      >
        <tbody>
          <!-- Category -->
          <ng-container matColumnDef="categoryName">
            <th class="text-secondary text-xxs font-weight-bolder opacity-7"
              mat-header-cell *matHeaderCellDef>Category</th>
            <td class="text-xs font-weight-normal" mat-cell *matCellDef="let row">
              {{row.categoryName | uppercase}}
            </td>
          </ng-container>
  
          <!-- Topic -->
          <ng-container matColumnDef="title">
            <th class="text-secondary text-xxs font-weight-bolder opacity-7"
              mat-header-cell *matHeaderCellDef>Title</th>
            <td class="text-xs font-weight-normal" mat-cell *matCellDef="let row">
              {{row.title}}
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="pointer"
            (click)="goToTopic(row.id, row.categoryId, row.title, row.categoryName)"
          ></tr>
  
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" style="text-align: center;">
              {{ message.noRecord }}
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="kbData.length > 0" class="see-btn q-d-flex q-jc-center">
        <a class="btn-2 btn-2-link"
          routerLink="/company/knowledge-base">See More</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <widget-loading></widget-loading>
</ng-template>