import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';
import { MultiSelectInput2Component } from './multi-select-input.component';
import { CheckboxInputModule } from '../checkbox-input/checkbox-input.module';
import { SmoothScrollDirective } from '@app/shared/navigation/dashboard-layout/smooth-scroll.directive';

@NgModule({
	declarations: [MultiSelectInput2Component],
	imports: [
		CommonModule,
		MatSelectModule,
		ScrollingModule,
		CheckboxInputModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatChipsModule,
		OverlayModule,
		FigmaIconImgModule,
		MatIconModule,
		SmoothScrollDirective,
	],
	exports: [MultiSelectInput2Component],
})
export class MultiSelectInput2Module {}