<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-lg-flex">
          <div>
            <h5 class="mb-0">PDF Viewer</h5>
          </div>
        </div>

        <div class="ms-auto my-auto mt-lg-0 mt-4">
          <div class="ms-auto my-auto">
            <a
              href="javascript:void(0)"
              (click)="onClickClose()"
              class="btn btn-gradient-primary  addnewapp-btn btn-sm export mb-0 mt-sm-0 mt-">
              Close
            </a>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pb-0 pt-0">
        <div>
          <div class="pdf-container">
            <object 
              [data]="pdfSrc" 
              type="application/pdf" 
              width="100%" 
              height="700px">
            </object>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
