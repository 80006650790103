<ng-template #startDateTpl>
  <input matInput [matDatepicker]="dp1" 
        [formControl]="formControl.startDateFc" name="startDate" 
        #startDate class="form-control"
        [ngClass]="{'d-inline': (template=='default'), 'mainsearch':(template=='double-li')}"
        [placeholder]="placeholder"
        errorTooltip
        etMessage="This component cannot be empty"
        [etShow]="tooltip.startDate"
        (focusout)="setStartDateOnFocusout()"
  >
  <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
  <mat-datepicker #dp1></mat-datepicker>
</ng-template>

<ng-template #endDateTpl>
  <input matInput [matDatepicker]="dp2" 
        [formControl]="formControl.endDateFc" name="endDate" 
        #endDate class="form-control"
        [ngClass]="{'d-inline': (template=='default'), 'mainsearch':(template=='double-li')}"
        [matDatepickerFilter]="minDate"
        [placeholder]="placeholder"
        errorTooltip
        etMessage="This component cannot be empty"
        [etShow]="tooltip.endDate"
        (focusout)="setEndDateOnFocusout()"
  >
  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
  <mat-datepicker #dp2></mat-datepicker>
</ng-template>