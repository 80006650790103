import { User, UserFirstLogin } from "@app/shared/interfaces/user.interface";
import { Action, ActionReducer, MetaReducer, createAction, props } from "@ngrx/store";



export enum ActionTypes{
  USER_UPDATE = '[USER] Update',
  USER_UPDATE_SUCCESS = '[USER] Update Success',

  USER_FIRST_LOGIN_UPDATE = '[USER] First Login Update',
  USER_FIRST_LOGIN_UPDATE_SUCCESS = '[USER] First Login Update Success',

  USER_REMOVE = '[USER] Remove',
  USER_REMOVE_SUCCESS = '[USER] Remove Success',

  JWT_DECODE_UPDATE = '[JWTDECODE] Update',
  JWT_DECODE_UPDATE_SUCCESS = '[JWTDECODE] Update Success',

  JWT_DECODE_REMOVE = '[JWTDECODE] Remove',
  JWT_DECODE_REMOVE_SUCCESS = '[JWTDECODE] Remove Success',

  USER_URL_UPDATE = '[USERURL] Update',
  USER_URL_UPDATE_SUCCESS = '[USERURL] Update Success',

  ADMIN_URL_UPDATE = '[ADMINURL] Update',
  ADMIN_URL_UPDATE_SUCCESS = '[ADMINURL] Update Success',

  USER_LOGOUT = '[USER] Logout'
}

export const userUpdateAction =
createAction(
  ActionTypes.USER_UPDATE,
  props<User>()
)

export const userUpdateSuccessAction =
createAction(
  ActionTypes.USER_UPDATE_SUCCESS
)

export const userFirstLoginAction =
createAction(
  ActionTypes.USER_FIRST_LOGIN_UPDATE,
  props<UserFirstLogin>()
)

export const userFirstLoginSuccessAction =
createAction(
  ActionTypes.USER_FIRST_LOGIN_UPDATE_SUCCESS
)

export const jwtDecodeUpdateAction =
createAction(
  ActionTypes.JWT_DECODE_UPDATE,
  props<{[key: string]: string}>()
)

export const jwtDecodeUpdateSuccessAction =
createAction(
  ActionTypes.JWT_DECODE_UPDATE_SUCCESS
)

export const userUrlUpdateAction =
createAction(
  ActionTypes.USER_URL_UPDATE,
  props<{url:string}>()
)

export const userUrlUpdateSuccessAction =
createAction(
  ActionTypes.USER_URL_UPDATE_SUCCESS,
)

export const adminUrlUpdateAction =
createAction(
  ActionTypes.ADMIN_URL_UPDATE,
  props<{url:string}>()
)

export const adminUrlUpdateSuccessAction =
createAction(
  ActionTypes.ADMIN_URL_UPDATE_SUCCESS,
)

export const userRemoveAction =
createAction(
  ActionTypes.USER_REMOVE,
)

export const userRemoveSuccessAction =
createAction(
  ActionTypes.USER_REMOVE_SUCCESS,
)

export const jwtDecodeRemoveAction =
createAction(
  ActionTypes.JWT_DECODE_REMOVE,
)

export const jwtDecodeRemoveSuccessAction =
createAction(
  ActionTypes.JWT_DECODE_REMOVE_SUCCESS,
)

export const logoutAction = 
createAction(
  ActionTypes.USER_LOGOUT
);

function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    if(action.type === ActionTypes.USER_LOGOUT){state = {};}
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [metaReducer];