import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {
  @ContentChild('modalHeader') modalHeader: TemplateRef<unknown>;
  @ContentChild('modalBody') modalBody: TemplateRef<unknown>;
  @ContentChild('modalFooter') modalFooter: TemplateRef<unknown>;

  @Input() show = false;
  @Input() withHeader = true;
  @Input() withDefaultFooterBtn = false;
  @Input() headerTitle = '';
  @Input() modalClass = '';
  @Input() modalSize = ''; // modal-sm, modal-lg, modal-xl
  @Input() cancelBtnText = 'Cancel';
  @Input() saveBtnText = 'Save';
  @Input() disableCancel = false;
  @Input() disableSave = false;

  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  id = `genericModal${new Date().getTime() / 1000}`;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { show } = changes;

    if(show.currentValue !== show.previousValue) {
      if(show.currentValue) {
        setTimeout(() => {
          const genericModal = document.getElementById(this.id);
          if(genericModal) {
            genericModal.style.display = 'block';
            genericModal.className = 'modal fade show'; 
    
            // create backdrop
            const div = document.createElement('div');
            div.setAttribute('class', 'modal-backdrop fade show');
            div.setAttribute('id', 'modal-backdrop');
            document.body.appendChild(div);
          }
        });
      } else {
        this.onClose();
      }
    }
  }

  onRemoveModal(): void {
    const genericModal = document.getElementById(this.id);
    if(genericModal) {
      genericModal.style.display = 'none';
      genericModal.className = 'modal fade';
    }
    const element = document.getElementById('modal-backdrop');
    if(element) {
      element.remove();
    }
  }

  onClose(): void {
    this.onRemoveModal();
    this.close.emit();
  }

  onSave(): void {
    this.onRemoveModal();
    this.save.emit();
  }

}
