import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { debounce, debounceTime, distinctUntilChanged, fromEvent, timer } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: ['YYYY/MM/DD', 'MM/DD/YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'M D YYYY'],
  },
  display: {
    dateInput: 'MMM D, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
// add values here if you have your own template
type DateFilterTemplate = 'default' | 'double-li';

@Component({
  selector: 'app-datepicker-moment',
  templateUrl: './datepicker-moment.component.html',
  styleUrls: ['./datepicker-moment.component.scss'],
  providers:[
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DatepickerMomentComponent implements OnInit {
  // Main property - to be used for testing
  startDate = '';
  endDate = '';
  // Input - for defining templates
  @Input() template:DateFilterTemplate = 'default';
  // ElementRef
  @ViewChild('startDate') startDateEl: ElementRef;
  @ViewChild('endDate') endDateEl: ElementRef;
  // Form Control
  formControl = {
    startDateFc: new FormControl(moment(undefined)),
    endDateFc: new FormControl(moment(undefined))
  }
  placeholder="MM/DD/YYYY";
  /**Private Properties*/
  // trigger for ngDoCheck
  private _triggerEndDate = false;
  // Format
  private _dateFormat = 'MM/DD/YYYY';
  //private _dateFormat2 = 'MM/DD/YYYY';
  private _dateRegex = [/[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}/, /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/, /[A-Za-z]{3}\s[0-9]{1,2},\s[0-9]{4}/]
  // Own Distinct version for DistinctUntilChanged
  private _distinct = {
    start: '',
    end: ''
  }
  // Error notification
  private _isDefault = {
    start: true,
    end: true
  }
  // for tooltip error
  tooltip = {
    startDate: false,
    endDate: false
  }
  /* startDateEmpty = false;
  endDateEmpty=false; */
  private _timeout = 2000;
  // output
  @Output() startDateEmitter = new EventEmitter<string>();
  @Output() endDateEmitter = new EventEmitter<string>();

  constructor() { }

  ngDoCheck(): void {
    if(this._triggerEndDate){
      this.formControl.endDateFc?.patchValue(this.formControl.startDateFc.value);
      this._distinct.end = this.formControl.startDateFc.value?.format(this._dateFormat) as string;
      this._triggerEndDate = false;
    }
  }
  //remove this, if not used
  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }

  ngAfterViewInit (): void {
    // trick to make it appear that the value disappear // start date
    fromEvent(this.startDateEl.nativeElement,'focus').subscribe(
      (e)=>{ this._isDefault.start?this.startDateEl.nativeElement.value = '': '';}
    )
    // end date
    fromEvent(this.endDateEl.nativeElement,'focus').subscribe(
      (e)=>{ this._isDefault.end?this.endDateEl.nativeElement.value = '': '';}
    )

    this.formControl.startDateFc.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (p,c)=>this.startDateEl.nativeElement.value as string===this._distinct.start
        )
      )
      .subscribe(this.startDateFn);

    this.formControl.endDateFc.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (p,c)=>this.endDateEl.nativeElement.value as string===this._distinct.end
        )
      )
      .subscribe(this.endDateFn);
  }

  private startDateFn = (v:moment.Moment | null)=>{
    const formattedDate = (v as moment.Moment)?.format(this._dateFormat); //get formatted
    const startDateElVal = this.startDateEl.nativeElement.value as string; //raw
    const length = startDateElVal.length;
    const defaultDate =  moment(undefined).format(this._dateFormat);

    if(this._isDefault.start && formattedDate == defaultDate){
      return;
    }
    if((!formattedDate && length < 10) && length != 0){
      return; // filters less than 10 character input
    }else{
      if(
        (startDateElVal.match(this._dateRegex[0])
        || startDateElVal.match(this._dateRegex[1])
        || startDateElVal.match(this._dateRegex[2]))
        && formattedDate
      ){
        this.startDate = formattedDate;
        this._distinct.start = this.startDate;
        this._isDefault.start = false;
        // if start date > end date
        if(v && this.formControl.endDateFc?.value && v>this.formControl.endDateFc?.value){
          this.formControl.endDateFc?.patchValue(v as moment.Moment);
        }
        if(!this.tooltip.startDate) {this.startDateEmitter.emit(formattedDate)}
        else{timer(this._timeout).subscribe(v=>this.tooltip.startDate = false)}
      }else if (startDateElVal == ''){
        this.tooltip.startDate = true;
        this.formControl.startDateFc.patchValue(moment(new Date(this._distinct.start)));
      }
    }
  }

  private endDateFn = (v:moment.Moment | null)=>{
    const formattedDate = v?.format(this._dateFormat);
    const endDate = this.endDateEl.nativeElement.value as string
    const length = endDate.length;
    if((!formattedDate && length < 10) && length != 0){
      return;  // filters less than 10 character input
    }else{
      if(
        (endDate.match(this._dateRegex[0])
        || endDate.match(this._dateRegex[1])
        || endDate.match(this._dateRegex[2]))
        && formattedDate
      ){
        if(
          this.formControl.startDateFc.value && v
          && v < this.formControl.startDateFc.value && !this._isDefault.start
        ){
          this._triggerEndDate = true;
          return;
        }else{
          this.endDate = formattedDate;
          this._distinct.end = this.endDate;
          this._isDefault.end = false;
          if(!this.tooltip.endDate) {this.endDateEmitter.emit(formattedDate)}
          else{timer(this._timeout).subscribe(v=>this.tooltip.endDate = false)}
        }
      }else if(endDate == ''){
        this.tooltip.endDate = true;
        this.formControl.endDateFc.patchValue(moment(new Date(this._distinct.end)));
      }
    }
 }

  setStartDateOnFocusout(){
    const startDateMoment = this.formControl.startDateFc.value;
    const startDateFormatted = startDateMoment?.format(this._dateFormat)

    if(this.formControl.startDateFc.value == null){
      this.formControl.startDateFc.patchValue(moment(new Date(this._distinct.start)))
    }else if(startDateFormatted && this.startDate != startDateFormatted){
      if(
          startDateMoment && this.formControl.endDateFc?.value
          && startDateMoment>this.formControl.endDateFc?.value
        ){
          this.formControl.endDateFc?.patchValue(startDateMoment as moment.Moment);
      }
      this.startDate = startDateFormatted;
      this.startDateEmitter.emit(this.startDate);
      this._distinct.start = this.startDate;
      this._isDefault.start = false;
    }
  }

 setEndDateOnFocusout(){
    const endDateMoment = this.formControl.endDateFc.value;
    const endDateFormatted = endDateMoment?.format(this._dateFormat)

    if(this.formControl.endDateFc.value == null){
      this.formControl.endDateFc.patchValue(moment(new Date(this._distinct.end)))
    }else if(endDateFormatted && this.endDate != endDateFormatted){
      if(
          this.formControl.startDateFc.value  && endDateMoment
          && this.formControl.startDateFc.value > endDateMoment
          && !this._isDefault.start
        ){
        this._triggerEndDate = true;
        return;
      }
      this.endDate = endDateFormatted;
      this.endDateEmitter.emit(this.endDate);
      this._distinct.end = this.endDate;
      this._isDefault.end = false;
    }
 }

  minDate = (d:Date | null):boolean=>{
    const calendar = (d || new Date());
    const startDate = this.formControl.startDateFc.value;
    if(this.formControl.startDateFc.value?.format(this._dateFormat) == 'Invalid date' || this.formControl.startDateFc.value == null)
      return true;
    if(this._isDefault.start) return true;

    return (moment(calendar).isAfter(startDate) || moment(calendar).isSame(startDate))
      || false;
  }
}
