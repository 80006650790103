<mat-date-range-input
	[formGroup]="formGroup"
	[rangePicker]="datepicker"
	[min]="min"
	[max]="max"
>
	<input
		matStartDate
		(dateChange)="onModelChange($event.value, 0)"
		[formControlName]="startControlName"
		tabindex="-1"
	/>
	<input
		matEndDate
		(dateChange)="onModelChange($event.value, 1)"
		[formControlName]="endControlName"
		tabindex="-1"
	/>
</mat-date-range-input>

<input
	[formControl]="startMaskControl"
	[placeholder]="startPlaceholder"
	(blur)="onBlur()"
	(keydown)="onKeyDown($event, 0)"
	class="mask-input font-b1 font-h-normal"
	type="text"
	#maskRef
/>

<div class="range-divider"></div>

<input
	[formControl]="endMaskControl"
	[placeholder]="endPlaceholder"
	(blur)="onBlur()"
	(keydown)="onKeyDown($event, 1)"
	class="mask-input font-b1 font-h-normal"
	type="text"
	#maskRef2
/>

<mat-icon svgIcon="alert-triangle"></mat-icon>

<mat-date-range-picker
	[calendarHeaderComponent]="customHeader"
	panelClass="q-date-picker"
></mat-date-range-picker>
