import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ai-summary-instruction',
  templateUrl: './ai-summary-instruction.component.html',
  styleUrls: ['./ai-summary-instruction.component.scss']
})
export class AiSummaryInstructionComponent implements OnInit {

  constructor(
		private _dialogRef: MatDialogRef<AiSummaryInstructionComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this._dialogRef.close();
  }
}
