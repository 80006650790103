import { MatTabsModule } from '@angular/material/tabs';
import { KbTopicListModule } from './../../../modules/knowledge-base/pages/kb-topic-list/kb-topic-list.module';
import { GlobalKBAddEditComponent } from './global-kb-add-edit.component';
import { GlobalKnowledgeBaseComponent } from './global-kb.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';




@NgModule({
  declarations: [
    GlobalKBAddEditComponent,
    GlobalKnowledgeBaseComponent

  ],
  imports: [
    CommonModule,
    KbTopicListModule,
    MatTabsModule
  ],

})
export class GlobalKnowledgeBaseModule {

}
