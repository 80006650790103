import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputModule } from '@app/shared/components/form-input/search-input/search-input.module';
import { SharedModule } from '@app/shared/shared.module';
import { IDataModal } from '../../data-modal.service';

@Component({
	selector: 'app-expand-table',
	standalone: true,
	imports: [CommonModule, SearchInputModule, SharedModule],
	templateUrl: './expand-table.component.html',
	styleUrls: ['./expand-table.component.scss'],
})
export class ExpandTableComponent implements OnInit {
	@Input() data: IDataModal;

	ngOnInit(): void {}
}
