<div class="security-score-container"> <!--applist-table table-striped-->
  <ng-container *ngIf="!isStillLoading; then main; else loading"></ng-container>
</div>

<ng-template #main>
  <div>
    <dx-chart id="msScore" [dataSource]="scoreHistory"
      [hidden]="!(mode==='chart')"
    >
      <dxo-title [text]="title">
        <dxo-subtitle [text]="subtitle"></dxo-subtitle>
      </dxo-title>

      <dxi-series
        [argumentField]="'scoreDate'"
        [valueField]="'secureScore'"
        [name]="'Overall Score'"
        type="spline"
      >
        <dxo-aggregation [enabled]="true"></dxo-aggregation>
      </dxi-series>
      <dxo-argument-axis>
        <dxo-label
          overlappingBehavior="rotate"
          [rotationAngle]="-90"
        ></dxo-label>
      </dxo-argument-axis>
      <!--<dxi-value-axis [wholeRange]="[10,90]"></dxi-value-axis>-->
      <!--<dxo-legend [visible]="true"></dxo-legend>-->
      <dxo-tooltip
        [enabled]="true"
        [shared]="true"
      ></dxo-tooltip>
    </dx-chart>
    <ng-container *ngIf="mode==='table'">
      <dx-button text="Back"
        (onClick)="showChart()"
        style="margin-bottom: 5px;"
      ></dx-button>
    </ng-container>
    <dx-data-grid
      id="dataGrid"
      [dataSource]="categoryTable"
      [rowAlternationEnabled]="true"
      [showBorders]="true"
      [hidden]="!(mode==='table')"
      height="360"
    >
      <dxi-column dataField="rank" dataType="number"></dxi-column>
      <dxi-column dataField="service" dataType="string"></dxi-column>
      <dxi-column dataField="actionUrl" dataType="string" width="200"></dxi-column>
      <dxi-column dataField="currentScore" dataType="number"></dxi-column>
      <dxi-column dataField="maxScore" dataType="number"></dxi-column>
      <dxi-column caption="Secure Score" format="percent" [calculateCellValue]="getPercentValue"></dxi-column>
      <dxo-paging [pageSize]="10"> </dxo-paging>
    </dx-data-grid>
  </div>
  <div class="score-categories-container">
    <div class="item" *ngFor="let category of scoreCategory" (click)="loadDatatable(category.id)">
      <div class="category-label">
        <span class="category-name">{{category.name}}</span>
        <span class="category-percentage">{{checkNaN(category.secureScore) | number:'1.2-2'}}%</span>
      </div>
      <mat-progress-bar
        class="example-margin"
        [color]="'blue'"
        [mode]="'determinate'"
        [value]="category.secureScore"
      >
      </mat-progress-bar>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <widget-loading></widget-loading>
</ng-template>