import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'app-filter-chevron',
	templateUrl: './filter-chevron.component.html',
	styleUrls: ['./filter-chevron.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterChevronComponent implements OnChanges {
	@Input() el: HTMLElement;
	@Input() isCollapsed: boolean;
	@Input() hasSpaceOnRight = false;
	@Input() hasSpaceOnLeft = true;

	private readonly _chevronDown = 'assets/images/icons/chevron-down-icon.svg';
	private readonly _chevronUp = 'assets/images/icons/chevron-up-icon.svg';

	get filterSrc(): string {
		return this.isCollapsed ? this._chevronDown : this._chevronUp;
	}

	get marginBottom(): string {
		return this.isCollapsed ? '0' : '0.5px';
	}

	ngOnChanges(changes: SimpleChanges) {
		this.filterSrc;
	}
}
