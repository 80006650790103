<div class="sat-container">
  <div class="border-container red" [class.red-clicked]="chosen===1" (click)="updateChosen(1)">
    <img src="/assets/icons/worse.png" alt="">
    <p class="red-text">Very Unsatisfied</p>
  </div>
  <div class="border-container orange" [class.orange-clicked]="chosen===2" (click)="updateChosen(2)">
    <img src="/assets/icons/bad.png" alt="">
    <p class="orange-text">Unsatisfied</p>
  </div>
  <div class="border-container yellow" [class.yellow-clicked]="chosen===3" (click)="updateChosen(3)">
    <img src="/assets/icons/neutral.png" alt="">
    <p class="yellow-text">Neutral</p>
  </div>
  <div class="border-container light-green" [class.light-green-clicked]="chosen===4" (click)="updateChosen(4)">
    <img src="/assets/icons/good.png" alt="">
    <p class="light-green-text">Satisfied</p>
  </div>
  <div class="border-container green" [class.green-clicked]="chosen===5" (click)="updateChosen(5)">
    <img src="/assets/icons/better.png" alt="">
    <p class="green-text">Very Satisfied</p>
  </div>
</div>
<div class="modal-footer q-g-16">
    <button
    class="btn-dashboard"
    type="button"

    class="btn-2"
    (click)="closeModal(true)"
  >
    Cancel
  </button>

  <button
    class="btn-2 btn-2-primary"
    type="button"
    (click)="onSubmit()"
    [disabled]="chosen === 0"
  >
    Submit
  </button>
</div>
