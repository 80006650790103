import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FigmaIconImgModule } from './../../../figma-icon-img/figma-icon-img.module';
import { ModalModule } from '../modal/modal.module';

import { AttachmentPreviewModalComponent } from './attachment-preview-modal.component';

@NgModule({
	declarations: [AttachmentPreviewModalComponent],
	imports: [CommonModule, ModalModule, FigmaIconImgModule],
	exports: [AttachmentPreviewModalComponent],
})
export class AttachmentPreviewModalModule {}
