import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserInfoService {
  private _navigator: Navigator2 = navigator as any;

  constructor(){}

  get name(){return this._detectBrowserName();}

  get type(){return this._navigator.userAgentData.mobile? 'mobile': 'pc/mac'}

  get userAgent(){return this._navigator.userAgent}

  get version(){return this._detectBrowserVersion()}

  get platform(){return this._navigator.platform}

  get screenSize(){
    return `height: ${window.innerHeight}; width: ${window.innerWidth};`;
  }

  get resolution(){
    return `${window.screen.width * window.devicePixelRatio} x ${window.screen.height * window.devicePixelRatio}`
  }

  get browserType() {
    const userAgent = this._navigator.userAgent;

    const test = (regexp: RegExp) => {
      return regexp.test(userAgent);
    };
  
  
    if (test(/opr\//i)) {
      return 'Opera';
    } else if (test(/edg/i)) {
      return `Microsoft Edge v ${userAgent.split('Edg/')[1].split('.')[0]}`;
    } else if (test(/chrome|chromium|crios/i)) {
      return `Google Chrome v ${userAgent.split('Chrome/')[1].split('.')[0]}`;
    } else if (test(/firefox|fxios/i)) {
      return `Mozilla Firefox v ${userAgent.split('Firefox/')[1].split('.')[0]}`;
    } else if (test(/safari/i)) {
      return `Apple Safari v ${userAgent.split('Safari/')[1].split('.')[0]}`;
    } else if (test(/trident/i)) {
      return 'Microsoft Internet Explorer v version unknown';
    } else if (test(/ucbrowser/i)) {
      return 'UC Browser v version unknown';
    } else if (test(/samsungbrowser/i)) {
      return 'Samsung Browser v version unknown';
    } else {
      return 'Unknown browser';
    }
  }

  private _detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private _detectBrowserVersion(){
      var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }
      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
  }

  browserDetail(): BrowserDetail{
    return {
      'BrowserDetail.Name': this.name,
      'BrowserDetail.Type': this.type,
      'BrowserDetail.BrowserAgent': this.userAgent,
      'BrowserDetail.Version': this.version,
      'BrowserDetail.Platform': this.platform,
    }
  }

  getBrowserType(){return this.browserType.split(' v ')[0]}
  getBrowserVersion(){return this.browserType.split(' v ')[1]}
}


interface Navigator2 extends Navigator{
  userAgentData:
    {
      brands: Array<NavigatorBrands>,
      mobile: boolean,
      platform: string
    }
}

interface NavigatorBrands {
  brand: string;
  version: string;
}

interface BrowserDetail{
  'BrowserDetail.Name': string,
  'BrowserDetail.Type': string,
  'BrowserDetail.BrowserAgent': string,
  'BrowserDetail.Version': string,
  'BrowserDetail.Platform': string,
}
