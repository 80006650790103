import { IGenericInterface } from './../../shared/interfaces/generic.interface';
import { Injectable, inject } from '@angular/core';
import {
	DashboardCard,
	DashboardCategoryDropdown,
	QueryString,
	ResponseDataDashboardCard,
} from '@app/shared/interfaces/dashboard.interface';
import {
	BehaviorSubject,
	finalize,
	Observable,
	ReplaySubject,
	tap,
} from 'rxjs';
import { environment } from 'environments/environment';
import { DashboardMainService } from '@app/shared/services/dashboard-main.service';
import { toFormData } from '@app/shared/utilities/helper';
import { SpinnerService } from '@app/core/services/spinner.service';

export type DataList = 'list' | 'edit';

@Injectable({
	providedIn: 'root',
})
export class DashboardCardService extends DashboardMainService {
	dashboardSetupFilters: IGenericInterface;

	private _dashboardSetupData = new ReplaySubject<any[]>(1);
	dashboardSetupData$ = this._dashboardSetupData.asObservable();

	private _dashboardSetupTotalItems = new ReplaySubject<number>(1);
	dashboardSetupTotalItems$ = this._dashboardSetupTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	allowToFetchNewData = false;

	#spinner = inject(SpinnerService);

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	state = {
		action: {
			mode: '', //add or edit
			label: '', // header label
			backLink: '', // this is actually cancel link
		},
		search: {
			text: '',
		},
		id: 0,
	};

	getStateAction() {
		return this.state.action.mode;
	}

	setStateToAdd() {
		this.state.action.mode = 'add';
		this.state.action.label = 'Add New Dashboard Card';
		this.state.action.backLink = '../';
	}

	setStateToEdit() {
		this.state.action.mode = 'edit';
		this.state.action.label = 'Edit Dashboard Card';
		this.state.action.backLink = '../../';
	}

	get headerLabel() {
		return this.state.action.label;
	}

	get backLink() {
		return this.state.action.backLink;
	}

	constructor() {
		super();
	}

	getList(options: IGenericInterface) {
		const query = this._getQueryString(options);

		this.saveCurrentDashboardSetupFilter(options);
		this.clearDashboardSetupData();

		return this.http
			.get<ResponseDataDashboardCard>(
				`${environment.apiBaseUrl}DashboardCard${query}`
			)
			.pipe(
				tap((res) => {
					this.setDashboardSetupData(res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	setDashboardSetupData(res: any) {
		this._dashboardSetupData.next(res.data);
		this._dashboardSetupTotalItems.next(res.totalCount);
	}

	saveCurrentDashboardSetupFilter(options: IGenericInterface) {
		this.dashboardSetupFilters = options;
	}

	clearDashboardSetupData() {
		this.isLoading = true;
		this._dashboardSetupData.next([]);
		this._dashboardSetupTotalItems.next(0);
	}

	private _getQueryString(options: QueryString): string {
		let query = '?';
		const and = '&';
		query += options.query ? `Search=${options.query}` : `Search=`;
		query += and;
		query += options.page ? `Page=${options.page}` : `Page=`;
		query += and;
		query += options.pageSize ? `PageSize=${options.pageSize}` : `PageSize=`;
		query += and;
		query += options.column ? `Column=${options.column}` : `Column=`;
		query += and;
		query += options.order ? `Order=${options.order}` : `Order=`;

		return query;
	}

	getCard(id: number): Observable<DashboardCard> {
		return this.http.get<DashboardCard>(
			`${environment.apiBaseUrl}DashboardCard/${id}`
		);
	}

	getWidgets() /* :WidgetCommonProperty[] */ {}

	getRolePermissionDropdown() /* :{id:number, name:string}[] */ {}

	deleteCard(id: number) {
		return this.http.delete(`${environment.apiBaseUrl}DashboardCard/${id}`);
	}

	getCategoryDropdown(): Observable<DashboardCategoryDropdown[]> {
		return this.http.get<DashboardCategoryDropdown[]>(
			`${environment.apiBaseUrl}DashboardCardCategory/DropDown`
		);
	}

	getWidgetsDropdown() {
		return this.http.get(
			`${environment.apiBaseUrl}DashboardCard/GetWidgetsDropDown`
		);
	}

	updateCard(body: any): Observable<DashboardCard> {
		const data = this.setNullToEmpty(body);
		const toSubmit = toFormData(data);
		return this.http.put<DashboardCard>(
			`${environment.apiBaseUrl}DashboardCard/${body.id}`,
			toSubmit
		);
	}

	addCard(body: any) {
		const data = toFormData(body);
		return this.http.post(`${environment.apiBaseUrl}DashboardCard/`, data);
	}

	deleteImage() {
		const cardId = this.state.id;
		this.#spinner.start();
		return this.http
			.delete<DashboardCard>(
				`${environment.apiBaseUrl}DashboardCard/DeleteIcon/${cardId}`
			)
			.pipe(finalize(() => this.#spinner.stop()));
	}
}
