import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	inject,
} from '@angular/core';

import { FigmaDemoService } from './figma-demo.service';

@Component({
	selector: 'app-figma-demo',
	templateUrl: './figma-demo.component.html',
	styleUrls: ['./figma-demo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FigmaDemoComponent implements OnInit {
	private _figmaDemoService = inject(FigmaDemoService);

	ngOnInit() {
		// 	this._figmaDemoService.getFigmaFiles().subscribe({
		// 		next: (e) => {
		// 			console.log(e);
		// 		},
		//     error: (e) => {
		// 			console.log(e);
		// 		},
		// 	});
	}
}
