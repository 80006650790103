import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { finalize } from 'rxjs';

import { AuditTrailComponent } from '@app/shared/components/audit-trail/audit-trail.component';

import { requiredFileTypes } from '@app/shared/utilities/required-file-types';

import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { KnowledgeBaseService } from '../../knowledge-base.service';

import { HistoryUrl, NotificationMessages, Permission } from '@app/shared/constants';


import { KBBot } from '@app/shared/interfaces/knowledge-base.interface';
import { User } from '@app/shared/interfaces/user.interface';

@Component({
	selector: 'app-bot-add-edit',
	templateUrl: './bot-add-edit.component.html',
	styleUrls: ['./bot-add-edit.component.scss'],
})
export class BotAddEditComponent implements OnInit {
	@ViewChild(AuditTrailComponent) auditTrail: AuditTrailComponent;

	data: any;
	form: FormGroup;
	imgSrc: any;
	hasSubmitted: boolean = false;
	resultC: any[] = [];
	user: User = this._userService.user!;
	chatBotId = 0;
	isAuditTrailEnabled: boolean;

	isEditEnabled = this._userService.hasPermission([
		Permission.SpAdminChatbotUpdate,
		Permission.CompanyAdminKbTopicAddEdit,
	]);

	previewModal: {
		show: boolean;
		data: string | null;
		type: 'image' | 'video' | null;
	} = {
		show: false,
		data: null,
		type: 'image',
	};

	historyUrl = HistoryUrl;
	botNameError = '';

	constructor(
		public spinner: SpinnerService,
		private _fb: FormBuilder,
		private _kbService: KnowledgeBaseService,
		private _notifier: NotificationService,
		private _userService: UserService,
		private _dataModalService: DataModalService
	) {}

	ngOnInit() {
		this.spinner.start();
		this.form = this.createFormGroup();
		this._kbService.getKBBotList().subscribe((res: any) => {
			if (res.data.length > 0) {
				this.chatBotId = res.data[0].id;
				this._kbService
					.getKBBotById(res.data[0].id)
					.pipe(finalize(() => this.spinner.reset()))
					.subscribe((response: any) => {
						this.data = response;
						this.initForm(response);
					});
			}
			this.resultC = [
				{ id: 1, name: '1' },
				{ id: 2, name: '2' },
				{ id: 3, name: '3' },
			];
		});

		this.isAuditTrailEnabled =
			this._userService.hasPermission(Permission.CompanyAdminAuditTrailKnowledgeBase) ||
			this._userService.isSpAdmin;
	}

	createFormGroup() {
		let imgValidators: any[] = [
			requiredFileTypes(['png', 'jpg', 'jpeg', 'webp']),
		];
		const group = this._fb.group({
			id: [0],
			name: ['', [Validators.required, Validators.maxLength(100)]],
			resultCount: [null, Validators.required],
			greetings: ['', [Validators.required, Validators.maxLength(150)]],
			image: ['', imgValidators],
			initialResults: ['', [Validators.required, Validators.maxLength(150)]],
			additionalResults: ['', [Validators.required, Validators.maxLength(150)]],
			invalid: ['', [Validators.required, Validators.maxLength(150)]],
		});

		if (!this.isEditEnabled) group.disable();

		return group;
	}

	initForm(app?: KBBot) {
		this.form.patchValue({
			...app,
		});
		this.imgSrc = app?.image !== 'null' ? (app?.image as string) : '';
	}

	fileChange(file: any) {
		const isValidImageFile = file && file['type'].split('/')[0] === 'image';
		if (isValidImageFile) {
			let fileReader = new FileReader();
			fileReader.onload = (event: any) => {
				this.form.controls['image'].setValue(
					file instanceof FileList ? file[0] : file
				);
				this.imgSrc = event.target.result;
			};
			if (file instanceof FileList) {
				fileReader.readAsDataURL(file[0]);
			} else {
				fileReader.readAsDataURL(file);
			}
		}
	}

	deleteAttachment() {
		const data = this._dataModalService.getProfilePicModel(
			'Image',
			'This action will permanently delete the image.'
		);

		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					if (this.form.valid) {
						this.form.get('image')?.setValue('');
						this.imgSrc = '';
						this._kbService.deleteImage(this.form.get('id')?.value).subscribe();
					} else {
						this._notifier.notifyError('Review Error', 'Complete the required fields');
					}
				}
			},
		});
	}

	appendValues(app: any) {
		return {
			...app,
			file: app.image as File,
			image: this.imgSrc?.includes('base64') ? '' : this.imgSrc,
		};
	}

	setErrors(error: HttpErrorResponse) {
		this.form.controls['name'].setErrors({ specialCharacter: true });
		this.botNameError = error.error?.Name?.toString() || error.error;
	}

	resetError() {
		this.botNameError = '';
	}

	onSubmit() {
		if (!this.isEditEnabled) return;

		this.hasSubmitted = true;
		if (this.form.valid) {
			this.spinner.start();
			let app = this.form.value;
			app = this.appendValues(app);
			if (app.id == 0) {
				app.createdBy = this.user.id;
				this._kbService
					.addKBBot(app)
					.pipe(finalize(() => this.spinner.stop()))
					.subscribe({
						next: (response) => {
							this.auditTrailRefresh();
							this.form.controls['image'].setValue('');
						},
						error: (error) => {
							this.setErrors(error);
						},
					});
			} else {
				app.modifiedBy = this.user.id;
				this._kbService
					.updateKBBot(app)
					.pipe(finalize(() => this.spinner.stop()))
					.subscribe({
						next: (result) => {
							this.auditTrailRefresh();
							this.form.controls['image'].setValue('');
						},
						error: (error) => {
							this.setErrors(error);
						},
					});
			}
		} else {
			if (this.form.get('name')?.errors?.['required']) {
				this.botNameError = 'Name is required';
			} else if (this.form.get('name')?.errors?.['maxlength']) {
				this.botNameError = 'Maximum of 100 characters only';
			}
		}
	}

	auditTrailRefresh() {
		if (this.auditTrail) this.auditTrail.refresh();
	}

	onPreviewModal() {
		this.previewModal = {
			data: this.imgSrc ? this.imgSrc : this.data?.image,
			show: true,
			type: 'image',
		};
	}

	onClosePreviewModal(): void {
		this.previewModal = {
			data: null,
			show: false,
			type: 'image',
		};
	}
}
