import { createReducer, on } from "@ngrx/store"
import { Iconfig } from "../configuration.interface"
import { configUpdateAction, configUpdateSuccessAction } from "./configuration.action"

const initialState: Iconfig = {
  sideBarColor: '#00baf2',
  darkModeColor: '#00baf2',
  sideBarTempColor: '#00baf2',
  sideNavType: 'bg-white',
  navbarFixed: false,
  sideNavMini: false,
  darkMode: false,
  isDarkMode: false,
  isDefault: false
}

export const configReducer =
createReducer(
  initialState,
  on(configUpdateAction, (state,action): Iconfig => ({
    ...state,
    ...action
  })),
  on(configUpdateSuccessAction, (state): Iconfig => ({
    ...state,
  })),
)
