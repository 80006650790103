import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  private isMinimized: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  isMinimized$ = this.isMinimized.asObservable()
  constructor() { }

  isMinimize(value: boolean): void {
    this.isMinimized.next(value);
  }
}
