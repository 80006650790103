<ng-container>
  <table
    class="table table-flush"
    mat-table
    [dataSource]="dataSource"
    matSort
    [matSortActive]="defaultSort"
    [matSortDirection]="'desc'"
    matSortDisableClear="true"
    (matSortChange)="sortData($event)"
    style="width: -webkit-fill-available !important;"
  >
    <ng-container *ngFor="let column of columns">
      <ng-container [matColumnDef]="column.matColumnDef">
        <th class="text-xxs font-weight-bolder opacity-7"
          mat-header-cell *matHeaderCellDef mat-sort-header
          [ngStyle]="column.style?.th"
          [ngClass]="column.class?.th"
        >
        <!-- style="width:{{column.width}}px !important" -->
            {{column.text}}
        </th>
        <td
          class="text-xs font-weight-normal" mat-cell *matCellDef="let row"
          [ngStyle]="column.style?.td"
          [ngClass]="column.class?.td"
        >
          {{row[column.matColumnDef]}}
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="c-pointer"
      (click)="rowClick(row)"
    ></tr>
  </table>
</ng-container>
