import { createAction, props } from "@ngrx/store";
import { Iconfig } from "../configuration.interface";


export enum ActionTypes{
  CONFIG_UPDATE = '[Config] Update',
  CONFIG_UPDATE_SUCCESS = '[Cofnig] Update Success',

  CONFIG_SET = '[Config] Set',
}

export const configUpdateAction =
createAction(
  ActionTypes.CONFIG_UPDATE,
  props<Partial<Iconfig>>()
)

export const configUpdateSuccessAction =
createAction(
  ActionTypes.CONFIG_UPDATE_SUCCESS
)

