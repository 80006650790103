import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ViewTicketService {
	// props
	private _avatar = new BehaviorSubject<AvatarFields[]>([]);

	constructor() {}

	addAvatar(avatar: AvatarFields) {
		const avat = this._avatar.value;
		const findings = avat.find((v) => v.userId === avatar.userId);
		if (findings) {
			return findings;
		} else {
			const toAdd = { ...avatar, noProfileCtr: avat.length + 1 };
			avat.push(toAdd);
			this._avatar.next(avat);
			return toAdd;
		}
	}

	getAvatar(userId: number) {
		const avatar = this._avatar.value;
		if (avatar.length > 0) return avatar.find((v) => v.userId === userId);
		else return undefined;
	}

	reCheckAvatar(avatars: AvatarFields[]) {
		// this will recheck if avatars still exist or already deleted / removed users from cc
		let filtered = this._avatar.value
			.filter((v) => {
				if (v.comingFrom === 'comment')
					return true; // since comment is not changing unlike cc users
				else {
					if (avatars.find((x) => x.userId === v.userId)) return true;
					else return false;
				}
			})
			.map((v, i) => ({ ...v, noProfileCtr: i + 1 }));

		this._avatar.next(filtered);
	}
}

interface AvatarFields {
	userId: number;
	firstName: string;
	lastName: string;
	noProfileCtr: number;
	comingFrom: 'comment' | 'reply';
	email?: string;
	fullName?: string;
}
