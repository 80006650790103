import { Component } from '@angular/core';
import { OptimizeQuestModalService } from './optimize-quest-modal.service';

@Component({
  selector: 'app-optimize-quest-modal',
  templateUrl: './optimize-quest-modal.component.html',
  styleUrls: ['./optimize-quest-modal.component.scss']
})
export class OptimizeQuestModalComponent {

  constructor(
    private _optimizeQuestModalService: OptimizeQuestModalService
  ) { }

  close() {
    this._optimizeQuestModalService.closeModal();
  }

  openApp() {
    return this._optimizeQuestModalService.openApp();
  }
}
