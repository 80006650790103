import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { TableMessages } from '@app/shared/constants';
import { CategoryScore, CategoryScoreList, GraphApi, ScoreHistory } from '@app/shared/interfaces/dashboard.interface';
import { catchError, map, Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-graph-api-widget',
  templateUrl: './graph-api-widget.component.html',
  styleUrls: ['./graph-api-widget.component.scss']
})
export class GraphApiWidgetComponent implements OnInit, OnDestroy {

  graphApi$:Observable<GraphApi | null>;
  dataSource:Observable<any[]>; // change this when there's data structure
  //totalItems = 0;
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  graphApi:GraphApi;
  score = {
    currentScore: 0,
    secureScore: 0,
    maxScore: 0,
  };
  scoreHistory:ScoreHistory[];
  scoreCategory:CategoryScore[];
  scoreId: string;
  categoryTable: CategoryScoreList[];
  mode: 'chart' | 'table' = 'chart';
  
  isStillLoading = true;
  subs = new Subscription()

  constructor(
    private _dashboardService: DashboardService,
    private _cd : ChangeDetectorRef,
  ){
    _dashboardService.initGraphApi();
    this.graphApi$ = this._dashboardService.graphApi$;
    ///this._dashboardService.setGraphAPI();    
    //this.getData();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
/**
 currentScore: number, 
    id: string,
    maxScore: number,
    scoreDate: Date | string,
    secureScore: number,
 */
  ngOnInit(){
    this.graphApi$
      .pipe(
        tap(v=>{
          this.isStillLoading = false;
          this._cd.detectChanges()
        }),
        map(v=>{
          let history = v!.securityScoreHistory, newHistory:any[];
          newHistory = history.map(v=>{
            v.scoreDate = new Date(v.scoreDate).toLocaleDateString();
            return v;
          });
          return {...v, ...newHistory}
        })
      )
      .subscribe(v=>{
        //this.graphApi = v;
        this._setProperties(v as any);
        this._setFields();
        this._cd.markForCheck();
      })
  }

  private _setFields(){
    this.score.currentScore = this.graphApi.currentScore;
    this.score.secureScore = this.graphApi.secureScore;
    this.score.maxScore = this.graphApi.maxScore;
  }

  private _setProperties(v:GraphApi){
    this.graphApi = v;
    this.scoreHistory = v.securityScoreHistory;
    this.scoreCategory = v.controlCategoryScore;
    this.scoreId = v.id;
  }

  loadDatatable(categoryId: number){
    // go to data table of the clicked category
    this._getCategoryData(categoryId);
  }

  _getCategoryData(categoryId:number){
    this._dashboardService.getGraphApiCategory(categoryId, this.scoreId) // set the ids
      .subscribe(v=>{
        this.categoryTable = v;
        this.mode = 'table';
        this._cd.markForCheck();
      })
  }

  showChart(){
    this.mode = 'chart';
  }

  get title(){
    return `<strong>Security Score: ${this.score.secureScore.toFixed(2)}</strong>`;
  }

  get subtitle(){
    return `
      ${this.score.currentScore}/${this.score.maxScore} points Achieved
      Microsoft Secure Score is a representation of your
      organization's security posture, and your opportunity to improve it.
    `;
  }

  getData(){
    const data$ = this._dashboardService
      .getGraphAPIWidget()
      //.getKB4Widget()
      .pipe(
        tap(value=>{
          //this.totalItems=value.length;
        }),
        catchError(err=>[])
      )

    //data$.subscribe(v=>{console.log('v:', v)})
    this.dataSource = data$;
  }

  getPercentValue(data:any){
    return data.secureScore / 100;
  }

  checkNaN(num:number){
    if(Number.isNaN(num) || num as any === 'NaN') return 0
    else return num
  }

}
