import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserService } from '@app/core/services/user.service';
import { KnowledgeBaseService } from '@app/modules/knowledge-base/knowledge-base.service';
import { KbPermissionService } from '@app/shared/services/base-kb.service';

import { Observable } from 'rxjs';

//borrowing option from application - need to refactor later on
import {
  CurrentLevel,
  GlobalAccess,
  OptionsAddEditModeModel,
  OptionsInterface,
  OptionsModel,
} from '../../functions/options-copy';

@Component({
  selector: 'app-contacts-kb-topics',
  templateUrl: './companies-kb.component.html',
  styles: [''],
  providers: [KbPermissionService],
})
export class CompaniesKnowledgeBaseComponent implements OnInit {
  options$: Observable<OptionsModel | OptionsAddEditModeModel | null>;

  constructor(
    public kbService: KnowledgeBaseService,
    private _globalPermissionService: KbPermissionService,
    private _userService: UserService
  ) {}
  ngOnInit(): void {
    this.options$ = this._globalPermissionService.options$;
    const data: OptionsInterface = {
      levelId: CurrentLevel.COMPANY,
      roleId: this._userService.userRole,
      moduleId: GlobalAccess.KBTopics,
    };
    this._globalPermissionService.setData(data, 'list');
  }

  setIndex(e: MatTabChangeEvent) {
    this.kbService.tab = e.index;
  }
}
