<div class="quick-filters-area">
	<div #quickFilterContainer class="quick-filters-inner-area d-flex">
		<div
			*ngIf="showLabel"
			#quickFilterLabel
			class="quick-filters-title quick-filters-btn"
			[class.q-jc-center]="!isShowHelpButton"
		>
			<h6>Quick Filters</h6>
			<app-figma-icon-img
				*ngIf="isShowHelpButton"
				[iconName]="'help-circle'"
				[hasSpaceOnRight]="false"
				data-bs-toggle="tooltip"
				[isQuickFilter]="true"
				class="q-help"
			></app-figma-icon-img>
		</div>
		<div class="quick-filters-nav">
			<div class="quick-filters-btns">
				<ul>
					<!--[style.width]="getWidth(quickFilterContainer, quickFilterLabel)"-->
					<drag-scroll
						class="d-flex"
						[scrollbar-hidden]="false"
						[ngClass]="{ 'hide-scrollbar': false }"
						[snap-disabled]="true"
					>
						<!--
						[scrollbar-hidden]="false"
						[ngClass]="{ 'hide-scrollbar': true }" -->
						<li *ngFor="let qFilter of quickFilterData; let i = index">
							<ng-container *ngIf="selectedType === quickFilterType.single">
								<input
									[id]="'single-' + i"
									type="radio"
									name="single"
									[formControl]="selectedSingleFilter"
									[value]="qFilter.id"
									(change)="onSingleSelected(qFilter)"
									hidden
								/>

								<label
									[for]="'single-' + i"
									class="btn-badge btn-badge-primary quick-filter-text"
									[ngStyle]="getStyle(qFilter)"
									(click)="onSingleSelected(qFilter, true)"
								>
									{{ qFilter.name }}
								</label>
							</ng-container>

							<ng-container *ngIf="selectedType === quickFilterType.multiple">
								<input
									type="checkbox"
									name="Open-Invoices"
									[checked]="checkQuickFilter(qFilter.id)"
									(change)="saveQuickFilter(qFilter.id, qFilter.selected)"
									drag-scroll-item
								/>

								<a
									href="javascript:void(0)"
									[ngStyle]="getStyle(qFilter)"
									class="btn-badge btn-badge-primary quick-filter-text"
								>
									{{ qFilter.name }}
								</a>
							</ng-container>
						</li>
					</drag-scroll>
				</ul>
			</div>
		</div>
	</div>
</div>
