import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BadgeComponent } from './badge.component';

import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';
import { LocalFigmaIconImgModule } from '@app/shared/local-figma-icon-img/local-figma-icon-img.module';

@NgModule({
	declarations: [BadgeComponent],
	imports: [CommonModule, FigmaIconImgModule, LocalFigmaIconImgModule],
	exports: [BadgeComponent],
})
export class BadgeModule {}
