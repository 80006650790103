import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { createReducer, on } from '@ngrx/store';
import {
	feedbackUrlUpdateAction,
	feedbackUrlUpdateSuccessAction,
	sidebarRemoveAction,
	sidebarRemoveSuccessAction,
	sidebarUpdateAction,
	sidebarUpdateSuccessAction,
} from './sidebar.action';

export interface ISidebar {
	sidebarData: RoleMenuInterface[] | null;
	flatMenu: any[] | null;
	flatMenuAll: any[] | null;
	feedbackUrl: string;
}

const initialState: ISidebar = {
	sidebarData: null,
	flatMenu: null,
	flatMenuAll: null,
	feedbackUrl: '',
};

export const sidebarReducer = createReducer(
	initialState,
	on(
		sidebarUpdateAction,
		(state, action): ISidebar => ({
			...state,
			sidebarData: action.sidebarData,
			flatMenu: flattenMenu(action.sidebarData, true),
			flatMenuAll: flattenMenu(action.sidebarData, false),
		})
	),
	on(
		sidebarUpdateSuccessAction,
		(state): ISidebar => ({
			...state,
		})
	),
	on(
		sidebarRemoveAction,
		(state): ISidebar => ({
			...state,
			...initialState,
		})
	),
	on(
		sidebarRemoveSuccessAction,
		(state): ISidebar => ({
			...state,
		})
	),
	on(
		feedbackUrlUpdateAction,
		(state, action): ISidebar => ({
			...state,
			feedbackUrl: action.feedbackUrl,
		})
	),
	on(
		feedbackUrlUpdateSuccessAction,
		(state): ISidebar => ({
			...state,
		})
	)
);

export function flattenMenu(
	sidebarData: RoleMenuInterface[] | null,
	isNavigatable: boolean = false
) {
	if (!sidebarData) return [];

	const flattened: Partial<RoleMenuInterface>[] = [];

	function flatten(node: RoleMenuInterface) {
		flattened.push({
			id: node.id,
			subMenus: node.subMenus,
			name: node.name,
			url: node.url,
			isAdmin: node.isAdmin,
			isCustomRoute: node.isCustomRoute,
			isNavigatable: node.isNavigatable,
		});

		if (node.subMenus) {
			for (const child of node.subMenus) {
				flatten(child);
			}
		}
	}

	for (const node of sidebarData) {
		flatten(node);
	}

	if (isNavigatable) return flattened.filter((data) => data.isNavigatable);
	else return flattened;
}

//menu has submenus => not included
// menu has submenus but
