 <div class="row mt-4">
    <div class="col-lg-12 mt-lg-0 mt-4">
      <div class="card">
        <div class="card-header pb-0">
          <div class="d-lg-flex">
            <div>
              <h5 class="mb-0">{{ stateAction === "edit" ? "Update" : "Add New" }} Topic</h5>
            </div>
            <app-secondary-button
              [isBackButton]="true"
              [link]="'../'"
            ></app-secondary-button>
          </div>
        </div>
        <app-kb-topic-add-edit
          (sendStateAction)="getStateAction($event)"
          (sendPermissionData)="getTopicPermissionData($event)"
          (sendSavedId)="getSavedId($event)"
          (sendTopicName)="getTopicName($event)"
          (gotoPermissionSettings)="selectPermissionTabEvent($event)"
          (emitGeneralSettingsName)="getGenSettingName($event)"
          (sendFormData)="getFormData($event)"
        >
        </app-kb-topic-add-edit>
      </div>
    </div>
  </div>
  <app-audit-trail *ngIf="isAuditTrailEnabled && stateAction === 'edit'" [id]="savedId" [url]="historyUrl.KNOWLEDGE_BASE"></app-audit-trail>
