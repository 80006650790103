import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { SetupService } from '@app/modules/setup/setup.service';
import { SrBoard } from '@app/shared/interfaces/sr-boards/sr-board.interface';
import { TicketStatus } from '@app/shared/interfaces/ticket-status/ticket-status.interface';

interface SaveEmitter {
  board: number;
  status: number;
}

@Component({
  selector: 'app-feedback-generate-ticket-modal',
  templateUrl: './feedback-generate-ticket-modal.component.html',
  styleUrls: ['./feedback-generate-ticket-modal.component.scss']
})
export class FeedbackGenerateTicketModalComponent implements OnInit {
  @Input() show = false;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<SaveEmitter> = new EventEmitter<SaveEmitter>();

  board: number | null;
  status: number | null;
  boards: SrBoard[] = [];
  isGettingBoards = true;
  ticketStatus: TicketStatus[] = [];
  isGettingTicketStatus = true;
  impersonating = false;
  constructor(
    private _setupService: SetupService,
    private _userService: UserService,
    private _ticketService: TicketService,
  ) {
    this.impersonating = !!this._userService.user?.impersonatingBy;
   }

  ngOnInit(): void {
    this.onGetBoards();
  }

  onGetBoards(): void {
    try {
      const roleId = this._userService.user!.roleId;
      const userId =  this._userService.user!.id;
      const companyId = this._userService.user!.companyId;

      this._setupService.getBoards(
        1,
        1000,
        'name',
        'asc',
        '',
        roleId,
        userId,
        companyId,
      ).subscribe(res => {
        this.boards = [...res.data];
        this.isGettingBoards = false;
      });
    } catch (err) {

    }
  }

  onGetTicketStatus(): void {
    try {
      this.isGettingTicketStatus = true;
      this.ticketStatus = [];
      this.status = null;
      this._ticketService.getStatus(this.board)
        .subscribe(res => {
          this.ticketStatus = [...res];
          this.isGettingTicketStatus = false;
        });
    } catch (err) {

    }
  }

  onResetData(): void {
    this.board = null;
    this.status = null;
  }

  onClose(): void {
    this.onResetData();
    this.close.emit();
  }

  onSave() {
    if(this.board && this.status) {
      this.save.emit({
        board: this.board,
        status: this.status,
      });
      this.onResetData();
    }
  }
}
