import { FigmaIconImgModule } from './../../figma-icon-img/figma-icon-img.module';
import { LocalFigmaIconImgModule } from '@app/shared/local-figma-icon-img/local-figma-icon-img.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLogosComponent } from './card-logos.component';

@NgModule({
  declarations: [
    CardLogosComponent
  ],
  imports: [
    CommonModule,
    LocalFigmaIconImgModule,
    FigmaIconImgModule
  ],
  exports: [
    CardLogosComponent
  ],
})
export class CardLogosModule { }
