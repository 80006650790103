import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersActivityGraphComponent } from './users-activity-graph.component';
import { DxChartModule } from 'devextreme-angular';
import { UsersActivityGraphService } from './users-activity-graph.service';

@NgModule({
  imports: [
    CommonModule,
    DxChartModule,
  ],
  declarations: [UsersActivityGraphComponent],
  exports: [UsersActivityGraphComponent],
  providers: [UsersActivityGraphService]
})
export class UsersActivityGraphModule { }
