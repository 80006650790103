<div class="chat-bot" [hidden]="isMinimized === true">
	<div class="bot-container">
		<div class="bot-header d-flex" *ngIf="botData">
			<img
				image-error-detection
				[component]="'chat-bot.component.html'"
				class="bot-img"
				[src]="botData?.image"
				alt="chat-bot"
			/>
			<div class="bot-info">
				<h6>{{ botData?.name }}</h6>
				<small class="status">Online</small>
			</div>

			<app-figma-icon-img
				[iconName]="'minus'"
				[isForButton]="true"
				(click)="minMaxWindow(true)"
				class="ms-auto cursor-pointer"
			></app-figma-icon-img>

			<app-figma-icon-img
				[iconName]="'x'"
				[isForButton]="true"
				[hasSpaceOnRight]="false"
				(click)="closeChat()"
				class="cursor-pointer"
			></app-figma-icon-img>
		</div>

		<div class="help-box">
			<h6>Need more help?</h6>
			<a [routerLink]="[newTicketUrl]" class="can-btn ml-4">
				Submit a support ticket
			</a>
		</div>
		<div class="chat-message">
			<div class="webchat-container" #botWindow></div>
		</div>
	</div>
</div>

<div
	(click)="minMaxWindow(false)"
	class="chat-bot-minimized"
	*ngIf="isMinimized === true"
>
	<div class="close-window">
		<app-local-figma-icon-img
			[filename]="'x-white'"
			[hasSpaceOnRight]="false"
			class="bin"
			style="border-radius: 1rem"
		></app-local-figma-icon-img>
	</div>

	<div>
		<img
			image-error-detection
			[component]="'chat-bot.component.html'"
			class="img-round"
			[src]="botData?.image"
			alt="chat-bot"
		/>
	</div>
</div>
