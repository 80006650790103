<div id="popup1" class="popup-container" [class]="{'popup-active': true}">
  <div class="popup-content">
    <a (click)="onImpersonateClick()" class="close popup-close">&times;</a>
    <h5>Select a user to impersonate</h5>
    <div class="row">
      <div class="col-md-2">
          <label class="text-xs">Company</label>
      </div>
      <div class="col-md-10 mrb-15" style="padding-right: 0px;">
        <select-input
          [options]="companies"
          [optionKeys]="{value:'id', label:'name'}"
          (onChange)="companyChange($event)"
          placeholder="Select Company"
          [disabled]="!!data"
          [(ngModel)]="selectedCompanyId"
          [ngModelOptions]="{standalone: true}"
          inputClass="form-control col-sm-8">
        </select-input>
      </div>
      <div class="col-md-2">
        <label class="text-xs">User</label>
      </div>
      <div class="col-md-10 mrb-15" style="padding-right: 0px;">
        <select-input
        [disabled]="!selectedCompanyId"
        [options]="users"
        [optionKeys]="{value:'id', label:'name'}"
        (onChange)="userChange($event)"
        placeholder="Select User"
        inputClass="form-control col-sm-8">
      </select-input>
      </div>

      <div class="popup-login">
        <button (click)="onLogin()" class="btn-2 btn-2-primary" [disabled]="selectedCompanyId === 0 || selectedUserId === 0">Login</button>
      </div>
    </div>
  </div>
</div>
