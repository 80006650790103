import { formatDate } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
	DateAdapter,
	MAT_DATE_LOCALE,
	NativeDateAdapter,
} from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

import { debounceTime, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { toSQLDate } from '@app/shared/utilities/helper';
import { MaskPipe } from 'ngx-mask';

import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';

export class CustomDateAdapter extends NativeDateAdapter {
	override getDayOfWeekNames(): string[] {
		return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	}
}

@Component({
	selector: 'date-range-input',
	templateUrl: './date-range-input.component.html',
	styleUrls: ['./date-range-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateRangeInputComponent,
			multi: true,
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
		{
			provide: DateAdapter,
			useClass: CustomDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		MaskPipe,
	],
})
export class DateRangeInputComponent
	implements OnInit, OnChanges, ControlValueAccessor
{
	@ViewChild(MatDateRangePicker) datepicker: MatDateRangePicker<any>;
	@ViewChild('maskRef') private _maskRef: ElementRef;
	@ViewChild('maskRef2') private _maskRef2: ElementRef;

	@Input() startControlName: string;
	@Input() endControlName: string;
	@Input() startPlaceholder = 'MM/DD/YYYY';
	@Input() endPlaceholder = 'MM/DD/YYYY';
	@Input() value: any; //Formgroup Write Value workaround
	/** Converts date value to string */
	@Input() isString = false;
	@Input() min: string | Date;
	@Input() max: string | Date;
	@Output() dateChange = new EventEmitter<any>();
	@Output() blur = new EventEmitter<any>();

	@HostBinding('class.disabled') @Input() disabled = false;
	@HostBinding('class.focused') focused: boolean = false;

	formGroup = new FormGroup({} as any);
	startMaskControl = new FormControl('');
	endMaskControl = new FormControl('');
	customHeader = CalendarHeaderComponent;

	private _onTouched: any = () => {};
	private _destroy$ = new Subject();

	private _mask = inject(MaskPipe);

	ngOnInit(): void {
		this.formGroup.addControl(
			this.startControlName,
			new FormControl<Date | null>(null)
		);
		this.formGroup.addControl(
			this.endControlName,
			new FormControl<Date | null>(null)
		);

		this.startMaskControl.valueChanges
			.pipe(
				takeUntil(this._destroy$),
				map((v) => this._maskDate(v!, DateRangeTarget.Start))
			)
			.subscribe((v) => this.onMaskChange(v, DateRangeTarget.Start));
		this.endMaskControl.valueChanges
			.pipe(
				takeUntil(this._destroy$),
				map((v) => this._maskDate(v!, DateRangeTarget.End))
			)
			.subscribe((v) => this.onMaskChange(v, DateRangeTarget.End));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value'] && this.value) this.writeValue(this.value);
		if (changes['min'] && this.min) {
			const min = new Date(this.min);
			min.setDate(min.getDate() + 1);
			this.min = min;
		}
		if (changes['max'] && this.max) {
			const max = new Date(this.max);
			max.setDate(max.getDate() + 1);
			this.max = max;
		}
	}

	@HostListener('click', ['$event'])
	onClick(e: any) {
		this.datepicker.restoreFocus = true;
		this.datepicker.open();
		this.focused = true;
		if (
			this.datepicker['_focusedElementBeforeOpen'] !=
				this._maskRef.nativeElement &&
			this.datepicker['_focusedElementBeforeOpen'] !=
				this._maskRef2.nativeElement
		)
			this.datepicker['_focusedElementBeforeOpen'] =
				this._maskRef.nativeElement;
		this._focusInput();
	}

	onModelChange(value: any, target: DateRangeTarget) {
		let maskControl = this.startMaskControl;
		if (target == DateRangeTarget.End) {
			if (value) this._delayClose();
			maskControl = this.endMaskControl;
		} else {
			this.datepicker['_focusedElementBeforeOpen'] =
				this._maskRef2.nativeElement;
			this._focusInput();
		}

		maskControl.setValue(
			value ? formatDate(value, 'MM/dd/YYYY', 'en-US') : '',
			{ emitEvent: false }
		);
	}

	onMaskChange(value: any, target: DateRangeTarget) {
		const valueControl =
			this.formGroup.controls[
				target == DateRangeTarget.Start
					? this.startControlName
					: this.endControlName
			];

		if (value) {
			const date = new Date(value);
			if (
				isNaN(date.getDate()) ||
				(target == DateRangeTarget.End &&
					this.formGroup.value[this.startControlName] > date)
			) {
				valueControl.setValue(null);
				(target == DateRangeTarget.Start
					? this.startMaskControl
					: this.endMaskControl
				).setErrors({ mask: true });
			} else valueControl.setValue(date);
			if (target == DateRangeTarget.End && this.datepicker.opened)
				this.datepicker.close();
		} else if (valueControl.value) valueControl.setValue(null);
	}

	writeValue(value: any) {
		this.formGroup.patchValue(value, { emitEvent: false });
		const val = this.formGroup.value;

		this.startMaskControl.setValue(
			val[this.startControlName]
				? formatDate(value[this.startControlName], 'MM/dd/YYYY', 'en-US')
				: '',
			{ emitEvent: false }
		);

		this.endMaskControl.setValue(
			val[this.endControlName]
				? formatDate(value[this.endControlName], 'MM/dd/YYYY', 'en-US')
				: '',
			{ emitEvent: false }
		);
	}

	registerOnChange(fn: Function) {
		this.formGroup.valueChanges
			.pipe(
				takeUntil(this._destroy$),
				debounceTime(100),
				filter((v) => !this.disabled),
				map((v) => {
					if (this.isString) {
						if (v[this.startControlName])
							v[this.startControlName] = toSQLDate(
								v[this.startControlName],
								false
							);
						if (v[this.endControlName])
							v[this.endControlName] = toSQLDate(v[this.endControlName], false);
					}
					return v;
				}),
				tap((v) => this.dateChange.emit(v))
			)
			.subscribe((v) => fn(v));
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	onBlur() {
		if (!this.datepicker.opened) {
			this.focused = false;
			this._onTouched();
			this.blur.emit();
		}
	}

	onKeyDown(event: KeyboardEvent, target: DateRangeTarget) {
		if (
			event.key != 'Tab' ||
			(!event.shiftKey && target == DateRangeTarget.Start) ||
			(event.shiftKey && target == DateRangeTarget.End)
		)
			return;
		this.datepicker.restoreFocus = false;
		this.datepicker.close();
		this.onBlur();
	}

	ngOnDestroy(): void {
		this._destroy$.next(null);
		this._destroy$.complete();
	}

	moveCaret() {
		this.datepicker['_focusedElementBeforeOpen'].setSelectionRange(10, 10);
	}

	private _focusInput() {
		setTimeout(
			() => this.datepicker['_focusedElementBeforeOpen']?.focus(),
			500
		);
	}

	private _maskDate(date: string, target: DateRangeTarget) {
		const caret = (
			target == DateRangeTarget.Start ? this._maskRef : this._maskRef2
		).nativeElement.selectionStart;
		const maskControl =
			target == DateRangeTarget.Start
				? this.startMaskControl
				: this.endMaskControl;

		if (
			caret == undefined ||
			date.length == caret ||
			date.replace(/\//g, '').length >= 8
		) {
			const month = date.split('/')[0];
			if ((month.length == 2 || date.includes('/')) && parseInt(month) <= 0)
				date = '0';
			if (
				(month.length == 1 && (date.includes('/') || parseInt(month) > 1)) ||
				(month.length == 2 && parseInt(month) > 12)
			)
				date = '0' + date;

			const dArray = date.split('/');
			let day = dArray[1];
			if (day) {
				if ((day.length == 2 || dArray[2] != undefined) && parseInt(day) <= 0)
					date = date.substring(0, 4);
				else if (
					(day.length == 1 && (dArray[2] != undefined || parseInt(day) > 3)) ||
					(day.length == 2 && parseInt(day) > 31)
				)
					(dArray[1] = '0' + day), (date = dArray.join('/'));
			}

			date = this._mask.transform(date, 'M0/d0/0000');

			if (maskControl.value != date)
				maskControl.setValue(date, { emitEvent: false });
		}

		if (date.length && date.length != 10) maskControl.setErrors({ mask: true });
		else maskControl.setErrors(null);

		return date.length == 10 ? date : null;
	}

	private _delayClose() {
		this.datepicker['_opened'] = false;
		this.datepicker.opened = false;
		this.datepicker.restoreFocus = false;
		setTimeout(() => {
			this.datepicker['_opened'] = true;
			this.datepicker.opened = true;
			this.datepicker.close();
			this.onBlur();
		}, 500);
	}
}

enum DateRangeTarget {
	Start,
	End,
}
