import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { createAction, props } from "@ngrx/store";


export enum ActionTypes{
  GLOBAL_KB_CATEGORIES_UPDATE = '[GlobalKBCategory] Update',
  GLOBAL_KB_CATEGORIES_UPDATE_SUCCESS = '[GlobalKBCategory] Update Success',

}

export const globalKbCategoryUpdateAction =
createAction(
  ActionTypes.GLOBAL_KB_CATEGORIES_UPDATE,
  props<Partial<IPageState>>()
)

export const globalKbCategoryUpdateSuccessAction =
createAction(
  ActionTypes.GLOBAL_KB_CATEGORIES_UPDATE_SUCCESS
)
