<div class="planned-update-main-popup">
  <div class="planned-popup-overlay planned-popup-overlay-img"></div>
  <div class="planned-popup-inner-area">
    <div class="planned-popup-content-area">
      <div class="planned-popup-row">
        <div class="planned-popup-left-area">
          <div class="planned-popup-logo">
            <a href="javascript:void(0)">
              <img src="./assets/images/under-construction/Sourcepass_Black_Logo.png">
            </a>
          </div>
        </div>
        <div class="planned-popup-right-area">
          <div class="planned-popup-content">
            <div class="planned-tool-icon">
              <app-figma-icon-img
									[iconName]="'tool-01'"
									[hasSpaceOnRight]="false"
                  [height]="85"
                  [width]="85"
								></app-figma-icon-img>
            </div>
            <div class="planned-popup-con">
              <h3>Active Update Underway</h3>
              <p>Quest is currently being updated with new features to improve the experience.</p>
              <p>This should only take a few more minutes, so check back later once it’s complete.</p>
              <p>Thank you!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
