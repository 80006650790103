import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserLoginFilter } from '@app/shared/interfaces/portal-usage.interface';
import { environment } from 'environments/environment';
import { BehaviorSubject, ReplaySubject, finalize, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PortalUsageApiService {
	private _userLogintableData = new ReplaySubject<any[]>(1);
	userLogintableData$ = this._userLogintableData.asObservable();

	private _userLogintableItems = new ReplaySubject<number>(1);
	userLogintableItems$ = this._userLogintableItems.asObservable();

	private _userLoginisLoading = new BehaviorSubject(false);
	userLoginisLoading$ = this._userLoginisLoading.asObservable();

	set userLoginisLoading(isLoading: boolean) {
		this._userLoginisLoading.next(isLoading);
	}

	http = inject(HttpClient);

	getUserLoginReport(options: UserLoginFilter) {
		{
			this.userLoginisLoading = true;
			this._userLogintableData.next([]);
			this._userLogintableItems.next(0);
			return this.http
				.get(
					`${environment.apiBaseUrl}UserLoginReport/GetDailyActiveUsersDetails`,
					{
						params: { ...options },
					}
				)
				.pipe(
					tap((res: any) => {
						console.log(res);
						this._userLogintableData.next(res.data);
						this._userLogintableItems.next(res.totalCount);
					}),
					finalize(() => {
						this.userLoginisLoading = false;
					})
				);
		}
	}
}
