<div class="card-2 q-mb-24">
	<div class="card-header-2">
		<h3 class="q-mr-auto">Invoice Activity</h3>
	</div>

	<div class="invoice-activity q-d-flex q-f-column q-ai-start q-g-24 q-as-stretch">
		<ng-container *ngIf="activityList && activityList.length">
			<ng-container *ngFor="let activity of activityList">
				<!-- [Start]::Invoice activity group -->
				<div class="invoice-activity_group q-d-flex q-ai-start q-g-16 q-as-stretch">
					<div class="invoice-activity_date q-d-flex q-f-column q-ai-center">
						<div class="invoice-activity_day font-h4">
							{{ activity.date | date : 'd' }}
						</div>
						<div class="invoice-activity_month-year font-b3 q-center">
							{{ activity.date | date : 'MMM' | uppercase }}
							{{ activity.date | date : 'YYYY' }}
						</div>
					</div>

					<div class="invoice-activity_log q-w-100">
						<ng-container *ngIf="activity.groupList && activity.groupList.length">
							<ng-container *ngFor="let log of activity.groupList">
								<!-- [Start]::Invoice activity box -->
								<div class="invoice-activity_box q-d-flex q-p-16 q-jc-between q-ai-center q-as-stretch">
									<div class="q-d-flex q-ai-center q-g-16">
										<div class="invoice-activity_profile-pic">
											<img
												image-error-detection
												class="avatar avatar-sm"
												[component]="'invoice-activity.component.html'"
												[src]="
													log.imagePath !== null && log.imagePath !== ''
														? log.imagePath
														: 'assets/images/profile-pic/profilePic3.png'
												"
											/>
										</div>

										<div class="q-d-flex q-f-column q-ai-start q-g-8">
											<div class="invoice-activity_content font-b1">
												<b *ngIf="log.initiatedBy">{{ log.initiatedBy }}&nbsp;</b>{{ log.activity }}
											</div>
											<div class="invoice-activity_time font-b3">
												{{ log.activityDate | date : 'shortTime' }}
											</div>
										</div>
									</div>

									<div class="invoice-activity_status q-d-flex q-jc-center q-ai-start q-g-10">
										<ng-container *ngIf="log.status && log.status.length">
											<ng-container *ngFor="let statusName of log.status">
												<app-badge
													[label]="badgeStatus(statusName).label"
													[type]="badgeStatus(statusName).type"
													[fontColor]="badgeStatus(statusName).fontColor">
												</app-badge>
											</ng-container>
										</ng-container>
									</div>
								</div>
								<!-- [End]::Invoice activity box -->
							</ng-container>
						</ng-container>
					</div>
				</div>
				<!-- [End]::Invoice activity group -->
			</ng-container>
		</ng-container>
	</div>
</div>
