import {
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { ConsoleLogsService } from '@app/shared/services/console-logs.service';

import * as moment from 'moment';

@Directive({
	selector: '[image-error-detection]',
})
export class ImageErrorDetectionDirective {
	@Input() component: string;
	@Output() error = new EventEmitter();

	constructor(
		private el: ElementRef,
		private _consoleLogService: ConsoleLogsService
	) {
		const img = this.el.nativeElement as HTMLImageElement;
		img.addEventListener('error', (v) => {
			this.error.emit(v);
			this.catchError(v); // .bind(this);
		});
	}

	catchError(e: ErrorEvent) {
		const img = e.target as HTMLImageElement;
		const errorLog = {
			error: `GET ${img.src} 404 (Not Found)`,
			file: this.component,
			dateTime: moment().format(),
		};

		this._consoleLogService.sendError([errorLog])?.subscribe();
	}
}
