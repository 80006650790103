/* Angular Libraries */ 
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/* Third Party Libraries */ 
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

/* Interfaces */
import { SmsQuestMobileUpdate } from '@app/shared/interfaces/sms-quest-mobile-update.interface';

/* Environment */ 
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})

export class SmsQuestMobileUpdateModalService {
	isShowQuestMobileUpdate$: Observable<boolean> = of(false);

	private _mobileOptInNotification = new BehaviorSubject<boolean>(false);
	mobileOptInNotification = this._mobileOptInNotification.asObservable();

	constructor(private _http: HttpClient) {}

	public startEmitMobileOptInNotification(isLoginSuccess: boolean) {
		this._mobileOptInNotification.next(isLoginSuccess);
	}

	getFullDate(d: any | null = null) {
		// return (d ? new Date(d) : new Date()).toLocaleDateString();
		return (d ? new Date(d) : new Date()).toLocaleString();
	}

	setInMinutes(min: number) {
		let oldDate = new Date ();
		let newDate = new Date ( oldDate );

		return newDate.setMinutes ( new Date().getMinutes() + min );
	}

	mobileOptInStatus(): Observable<SmsQuestMobileUpdate> {
		return this._http.get<SmsQuestMobileUpdate>(
			`${environment.apiBaseUrl}MobileOptIn/Status`
		);
	}

	mobileOptInSkip() {
		return this._http.post(`${environment.apiBaseUrl}MobileOptIn/skip`, {});
	}

	mobileOptInSignUp(body: SmsQuestMobileUpdate) {
		return this._http.post(`${environment.apiBaseUrl}MobileOptIn`, body);
	}

	mobileOptInRemindMe() {
		return this._http.post(`${environment.apiBaseUrl}MobileOptIn/RemindMe`, {});
	}
}
