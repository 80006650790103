import { Injectable } from '@angular/core';
import { MonthlyActivityGraphModule } from './monthly-activity-graph.module';
import { PortalUsageStore } from '../../core';
import { Store } from '@ngrx/store';
import { fetchMonthlyUsageAction, updateYearMonthAction } from '../../core/store';
import * as select from '../../core/store/portal-usage.selector'
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { map } from 'rxjs';

@Injectable()
export class MonthlyActivityGraphService {

  constructor(private _store: Store<AppStateInterface>) { }

/*   fetchData(){
    this._store.dispatch(fetchMonthlyUsageAction())
  } */

  monthlyUsage$ = this._store.select(select.monthlyUsage2)
  startEndDate$ = this._store.select(select.startEndDate)

/*   setYearMonth(year:number, month:number){
    this._store.dispatch(updateYearMonthAction({year, month}))
  } */
}
