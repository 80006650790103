import { createReducer, on } from '@ngrx/store';

import { PaginationConstants } from '@app/shared/constants';

import { IPageState } from '@app/shared/interfaces/page-state.interface';

import {
	userAppCategoryListUpdateAction,
	userAppCategoryListUpdateSuccessAction,
} from './user-app-category.actions';

const initialState: IPageState = {
	page: 1,
	pageSize: PaginationConstants.pageSize,
	order: 'asc',
	column: 'name',
	query: '',
	totalItems: 0,
};

export const userAppCategoryListReducer = createReducer(
	initialState,
	on(
		userAppCategoryListUpdateAction,
		(state, action): IPageState => ({
			...state,
			...action,
		})
	),
	on(
		userAppCategoryListUpdateSuccessAction,
		(state): IPageState => ({
			...state,
		})
	)
);
