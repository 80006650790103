import { GroupsService } from './../../../modules/groups/groups.service';
import { Injectable } from "@angular/core";
import { AccountService } from "@app/modules/account/account.service";
import { CompaniesService } from "@app/modules/companies/companies.service";
import { GlobalSettingsType } from "@app/modules/global-settings/page/global-settings/global-setting.constant";
import { BehaviorSubject, take } from "rxjs";
import { BreadcrumbsFields, TbreadcrumbsText } from "./breadcrumb.constants";



@Injectable({
  providedIn: 'root'
})

export class BreadcrumbService{
  constructor(
    private _companiesService: CompaniesService,
    private _accountService: AccountService,
    private _groupsService: GroupsService
  ){}
  private breadcrumbsTextSource = new BehaviorSubject<TbreadcrumbsText>({
    _supportCategoryName: '',
    _updateRequestFormName: '',
    _formUsageName: '',
    _ticketBoardName: '',
    _applicationCategoryName: '',
    _applicationName: '',
    _kbCategoryName: 'Category',
    _kbTopicName: 'Topic',
    _quoteCategoryName: '',
    _agreementTypeName: '',
    _companyName: 'Company',
    _agreementName: '',
    _invoiceName: '',
    _messageName: '',
    _userName: 'User',
    _permissionTypeName: '',
    _userAppCategoryName: '',
    _userAppName: '',
    _globalSettingsName: '',
    _dashboardCardName: '',
    _orderStatusName: '',
    _groupName: '',
    _feedbackName: '',
    _companyId: 0,
    _contactId: 0,
    _groupId: 0,
    _ticketStatusName:'',
    _quoteStatusName: '',
    _invoiceStatusName: '',
    _applicationNameCatalog: '',
    _portalUsageName: '',
    _auditTrailReportUser: '',
    _auditTrailReportModule: '',
    _ssMainCategory: 'Categories',
    _ssSubCategory: 'Forms',
    _rpRoleName: 'Role',
    _territory: '',
    _connectWiseSelectedMenu: ''
  });
  breadcrumbsText = this.breadcrumbsTextSource.asObservable();

  public get breadCrumbsValue(){
    return this.breadcrumbsTextSource.value;
  }
  updateBreadCrumbsText(field: BreadcrumbsFields, value: string | number) {
    let data: TbreadcrumbsText = {
      ...this.breadcrumbsTextSource.value,
      [field]: value
    };
    this.breadcrumbsTextSource.next(data);
  }

   getCompanyByIdAndUpdateBreadcrumb(companyId: number) {
    if(companyId){
      this._companiesService
      .getCompanyForBreadcrumb(companyId)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.updateBreadCrumbsText('_companyName', response.name);
      });
    }
  }

  getUserByIdAndUpdateBreadcrumb(userId: number) {
    if(userId){
      this._accountService.getUserData(userId)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.updateBreadCrumbsText('_userName', (response.firstName ? response.firstName : '') + ' ' + (response.lastName !== null ? response.lastName : ''));
      });
    }
  }

  getGroupByIdAndUpdateBreadcrumb(groupId: number, companyId: number | null) {
    this._groupsService
      .getGroupsByCompanyId(companyId)
      .subscribe({
        next: (resp) => {
          const group = resp.find((obj: any) => {
            return obj.id === groupId;
          });
          this.updateBreadCrumbsText('_groupName', group?.name || '');
        }
      })
  }

  getGlobalGroupByIdAndUpdateBreadcrumb(){

  }

  updateGlobalSettingsType(module: GlobalSettingsType){
    this.updateBreadCrumbsText('_globalSettingsName', globalSetting.find(obj=>obj.id === module)!.name);
  }
}

const globalSetting = [
  {id: 1, name: 'Applications'},
  {id: 2, name: 'Knowledge Base Topics'},
  {id: 4, name: 'Forms'},
]
