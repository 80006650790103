import { createAction, props } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';

export enum ActionTypes {
	APP_CATEGORY_LIST_UPDATE = '[Application Category List] Update',
	APP_CATEGORY_LIST_UPDATE_SUCCESS = '[Application Category List] Update Success',
}

export const appCategoryListUpdateAction = createAction(
	ActionTypes.APP_CATEGORY_LIST_UPDATE,
	props<Partial<IPageState>>()
);

export const appCategoryListUpdateSuccessAction = createAction(
	ActionTypes.APP_CATEGORY_LIST_UPDATE_SUCCESS
);
