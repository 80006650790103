import { on, createReducer } from '@ngrx/store';

import { PaginationConstants } from '@app/shared/constants/pagination-options.constants';

import { IOrders } from './orders.interface';

import {
	ordersUpdateAction,
	ordersUpdateSuccessAction,
} from './orders.actions';

export const ordersInitialState: IOrders = {
	startDate: '',
	endDate: '',
	page: 1,
	pageSize: PaginationConstants.pageSize,
	order: 'desc',
	column: 'orderDate',
	query: '',
	statusId: [],
	totalItems: 0,
};

export const ordersReducer = createReducer(
	ordersInitialState,
	on(
		ordersUpdateAction,
		(state, action): IOrders => ({
			...state,
			...action,
		})
	),
	on(
		ordersUpdateSuccessAction,
		(state): IOrders => ({
			...state,
		})
	)
);
