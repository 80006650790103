import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	ViewChild,
	inject,
} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { ModalTooltipComponent } from '../../modal-utils/tooltip/tooltip.component';
import { NewTableSharedModule } from '@app/shared/new-table-shared/new-table-shared.module';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { TotalTicketsFilter } from '@app/shared/interfaces/total-tickets.interface';
import { DashboardReportService } from '@app/modules/dashboard/pages/dashboard/dashboard-report.service';
@Component({
  selector: 'app-device-ticket',
  standalone: true,
  imports: [SharedModule, ModalTooltipComponent, NewTableSharedModule],
  templateUrl: './device-ticket.component.html',
  styleUrls: ['./device-ticket.component.scss']
})
export class DeviceTicketComponent implements OnInit {
  @Input() data: IDataModal;
	searchFilters: any;
	dataModalService = inject(DataModalService);
	dashboardReport = inject(DashboardReportService);
	_cd = inject(ChangeDetectorRef);
	message = {
		m1: 'Total number of service-related tickets submitted by your team or opened on your behalf totaled on a monthly basis.',
		m2: '',
	};
	constructor() {}

	ngOnInit(): void {}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
		this.dashboardReport.resetTotalTickets();
	}

	fetchNewData() {
		const filters: IPageState = this._getFilters();

		this.searchFilters = filters;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.fetchNewData();
			this._cd.detectChanges();
		}, 0);
	}

	private _getFilters(): TotalTicketsFilter {
		return {
			page: 1,
			pageSize: 10,
			order: 'desc',
			column: '',
			query: '',
			month: this.data.templateData!['month'],
			isVIP: this.data.templateData!['isVIP'],
		};
	}

	capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}
}
