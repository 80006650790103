/* Angular Libraries */
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	inject,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

/* Third Party Libraries */
import { Subject, finalize, takeUntil } from 'rxjs';

/* Services */
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';

/* Functions */
import { ManageAutopayModalComponent } from '../manage-autopay-modal/manage-autopay-modal.component';

/* Interfaces */
import { PaymentMethod } from '@app/shared/interfaces/invoice.interface';
import { NotificationMessages } from '@app/shared/constants';
import { BillingContacts } from '@app/shared/interfaces/finance.interface';

@Component({
	selector: 'app-payment-method',
	templateUrl: './payment-method.component.html',
	styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
	// ViewChild
  @ViewChild(ManageAutopayModalComponent) manageAutopayModalComponent: ManageAutopayModalComponent;

	// injection
	private _dataModal = inject(DataModalService);

	// Input / Output
	@Input() billingContact: BillingContacts[];
	@Input() isManagePaymentMethodEnabled: boolean;
	@Output() updateInvoiceAuditTrail: EventEmitter<void> =
		new EventEmitter<void>();

	// props
	paymentOption: PaymentMethod = { card: [], bankAccount: [] };
	hasMethodEnrolledForAutopay: boolean = false;
	private _$unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private _spinner: SpinnerService,
		private _cd: ChangeDetectorRef,
		private _stripeService: StripeService,
		private _toastMessageService: ToastMessageService,
		private _notifier: NotificationService,
		private _router: Router
	) {}

	ngOnInit(): void {
		this._getPaymentMethod();
	}

	btoaVal(id: string) {
		return btoa(id.toString());
	}

	getLast4(data: any) {
		let val = '';
		if (data.last4) {
			val = data.last4;
		}

		return val;
	}

	redirectedToVerifyBankPage(id: string) {
		this._router.navigate(
			[
				`${
					this._stripeService.isAdmin ? 'billing-orders' : 'billing-and-orders'
				}/invoices/verify-bank-account`,
			],
			{ queryParams: { bankAccountId: id } }
		);
	}

	deleteBank(payment: any) {
		const item = `'${payment.name} **${payment.last4}'`;
		const data = this._dataModal.getDeleteModel2('Bank', item);
		this._dataModal.showModal(data).subscribe((v) => {
			if (v) this._deletePayment('Bank', payment);
		});
	}

	deleteCard(payment: any) {
		const item = `'${payment.name} **${payment.last4}'`;
		const data = this._dataModal.getDeleteModel2('Card', item);
		this._dataModal.showModal(data).subscribe((v) => {
			if (v) this._deletePayment('Card', payment);
		});
	}

	private _deletePayment(type: 'Card' | 'Bank', payment: any) {
		let delete$ = this._stripeService.deleteStripeCards(payment);
		if (type.toLowerCase() === 'bank')
			delete$ = this._stripeService.deleteStripeBanks(payment);

		this._spinner.start();
		delete$.pipe(finalize(() => this._spinner.stop())).subscribe({
			next: (result) => {
				this._notifier.notifySuccess(NotificationMessages.delete(type == 'Card' ? type : 'Bank Account'));
				this._getPaymentMethod();
				this.updateInvoiceAuditTrail.emit();
			},
			error: (err) => {
				this._toastMessageService.showErrorMessage(err);
			},
		});
	}

	clickDefaultPaymentMethod(id: string) {
		this._spinner.start();

		const body = {
			paymentMethodId: id,
		};

		this._stripeService
			.setDefaultPaymentMethod(body)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result) => {
					this._notifier.notifySuccess(NotificationMessages.save('Default Payment Method'));
					this._getPaymentMethod();
					this._spinner.stop();
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err);
				},
			});
	}

	onClickManageAutopay() {
    this.manageAutopayModalComponent.clickOpen();
  }

	onManageAutopayStatus(hasMethodEnrolledForAutopay: boolean) {
		this.hasMethodEnrolledForAutopay = hasMethodEnrolledForAutopay;
	}

	private _getPaymentMethod() {
		this._spinner.start();

		this._stripeService
			.getPaymentMethodList()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: PaymentMethod) => {
					this.paymentOption = {
						card: result.card,
						bankAccount: result.bankAccount,
					};

					this._spinner.stop();
					this._cd.detectChanges();
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err);
				},
			});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
