import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";
import * as portalInterface from "../portal-usage.interface";


export const portalUsageSelector = (state: AppStateInterface): portalInterface.PortalUsageStore => state.portalUsage;

export const monthlyUsage =
createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.monthlyReport);

export const monthlyUsage2 =
createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>{
  let monthlyReport = state.monthlyReport;
  try{
    monthlyReport = monthlyReport.map(v=>({...v, ...{monthYear: `${v.monthName} ${v.year}`}}))
  }catch(e){}
  return monthlyReport;
});

export const companyReport = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReport);
export const companyReportFilters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReportFilters);
export const companyReportListDetails = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReportListDetails);

export const companyReportTop5 = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReportTop5);
export const companyReportTop5Filters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReportTop5Filters);

export const companyReportLeast5 = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReportLeast5);
export const companyReportLeast5Filters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReportLeast5Filters);

// remove these topCompany and bottomCompany
export const topCompany = 
  createSelector(companyReport, (companyReport:portalInterface.CompanyReportUsage[])=>{
    let report = companyReport;
    try{report = companyReport.slice(0,5)}catch(e){}
    return report
  });
  //createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyReport);
export const bottomCompany = 
createSelector(companyReport, (companyReport:portalInterface.CompanyReportUsage[])=>{
  let report = companyReport;
  try{report = companyReport.slice(-5)}catch(e){}
  return report
});

export const yearSelect = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.year);
export const monthSelect = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.month);
export const yearAndMonth = createSelector(yearSelect, monthSelect, (year, month)=>({year, month}))
export const companyId = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyId);
export const companyData = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyData);
export const yearMonthCompanyId = createSelector(yearSelect, monthSelect, companyId, (year, month, companyId)=>({year, month, companyId}))
export const top15UsersActivity = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>{
  let userActivity = state.usersActivity;
  try{userActivity = state.usersActivity.slice(0,15)}catch(e){}
  return userActivity
});

export const usersActivity = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.usersActivity);
export const userActivityFilters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userActivityFilters);
export const userActivityDetails = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userActivityDetails);

export const usersActivityGraph = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.usersActivityGraph);
export const usersActivityGraphFilters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.usersActivityGraphFilters);

export const currentMonthlyUsage = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>({
    currentMonthActivity: state.currentMonthActivity,
    currentMonthUsers: state.currentMonthUsers,
  })
);
export const pastWeeklyUsage = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>({
    pastWeekActivity: state.pastWeekActivity,
    pastWeekUsers: state.pastWeekUsers,
  })
);

export const userId = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userId);
export const userDetails = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userDetails);
export const userActivityHistory = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userActivityHistory);
export const userActivityHistoryFilters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userActivityHistoryFilters);
export const userActivityHistoryDetails = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userActivityHistoryDetails);
export const userIdYearMonth = createSelector(userId, yearSelect, monthSelect, (userId, year, month)=>({userId, year, month}))


export const dailyUsage = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.dailyReport);
export const todayActivity = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.todayActivity);
export const todayUsers = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.todayUsers);

export const dailyUsageFormat = createSelector(
  dailyUsage, todayActivity, todayUsers, 
  (dailyUsage, todayActivity, todayUsers)=>({
    dailyUsage, todayActivity, todayUsers
  }))

export const loginReportSummary = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.loginReportSummary);
export const loginReportSummaryList = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.loginReportSummaryList);
export const loginReportDetails = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.loginReportDetails);
export const loginReportDetailsList = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.loginReportDetailsList);

export const companyDropdown = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.companyDropdown);
export const usersDropdown = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.usersDropdown);
export const DAUType = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.DAUType);
export const userLoginFilters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userLoginFilters);
export const userLoginFiltersDetails = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.userLoginFiltersDetails);

export const fromDate = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.fromDate);
export const toDate = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.toDate);
export const startEndDate = createSelector(fromDate, toDate, (start, end)=>({fromDate:start, toDate:end}))
export const startEndDateCompanyId = createSelector(fromDate, toDate, companyId, (from, to, id)=>({fromDate: from, toDate: to, companyId: id}))
export const startEndDateUserId = createSelector(fromDate, toDate, userId, (from, to, id)=>({fromDate: from, toDate: to, userId: id}))

export const exportFilters = createSelector(portalUsageSelector, (state: portalInterface.PortalUsageStore)=>state.exportFilters);