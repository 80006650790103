import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalV2Component } from './modal-v2.component';

@NgModule({
  declarations: [ModalV2Component],
  imports: [
    CommonModule
  ],
  exports: [
    ModalV2Component
  ]
})
export class ModalV2Module { }
