import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import './polyfills';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initGainsightPxScript } from 'global';

/* Init gainsight px tag key */
initGainsightPxScript(environment.tagKey, true);

if (environment.production) {
	enableProdMode();
	// window.console.log = () => { }
	// window.console.warn = () => { } //Need to see log for testing in CW Pod. I'll uncomment after testing.
	//window.console.error = () => { } // This is being handled in ConsoleLogsService
	window.console.time = () => {};
	window.console.timeEnd = () => {};
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(() => {})
	.catch((err) => console.error(err));
