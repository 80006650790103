import { HeaderSort } from './../../interfaces/knowledge-base.interface';
import { FinanceService } from '@app/modules/finance/finance.service';
import { PaginatorComponent } from './../paginator/paginator.component';
import { MatSort } from '@angular/material/sort';
import { PaginationConstants, TableMessages } from '@app/shared/constants';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-invoice-summary-details',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailComponent implements OnInit, AfterViewInit {
  @Input() invoiceId = 0;
  @Input() invoices: any[] = [];
  @ViewChild(MatSort) sort: MatSort;

/*   form = new FormGroup({
    id: new FormControl(0),
    invoiceNumber: new FormControl(null),
    description: new FormControl(''),
    orderId: new FormControl(0)
  }); */
  displayedColumns = [
    'id',
    'identifier',
    'description',
    'quantity',
    'unitPrice',
    'total'
  ];
  //dataSource: MatTableDataSource<any>;
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;
  order = 'asc';
  column = 'name';
  message={
    noRecord: TableMessages.EmptyTable
  }
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  _datasource: MatTableDataSource<any>;

  constructor(
    private _invoiceService: FinanceService,
    private _cd: ChangeDetectorRef
  )
  {
    const id = Number(this.invoiceId);
    this.invoiceId = id;
  }

  ngOnInit(): void {
/*     this._invoiceService.getInvoiceById(this.invoiceId).subscribe((response: any) => {
      this.form.patchValue({
        id: response.id,
        invoiceNumber: response.invoiceNumber,
        description: response.description,
        orderId: response.orderId
      });
    }); */
  }

  ngAfterViewInit(): void {
/*     this.paginator.size = PaginationConstants.pageSize;
    this.getInvoiceProductList(); */
    this._datasource = new MatTableDataSource(this.invoices);
    this._datasource.sort = this.sort;
  }

  get dataSource(){
    return this._datasource;
  }

  sortData(e: HeaderSort) {
    this.order = e.direction.toUpperCase();
    this.column = e.active;
/*     this.getInvoiceProductList(); */
  }
/*   getInvoiceProductList(page?: number) {
    if (this.invoiceId !== undefined && this.invoiceId !== null && this.invoiceId > 0) {
      this.paginator.page = page || 1;
      this._invoiceService.getInvoiceDetails({
        page: this.paginator.page,
        pageSize: this.paginator.size,
        search: this._invoiceService.invoiceSearchtext,
        sort: this.column,
        order: this.order}, this.invoiceId).subscribe({
        next: (data: any) => {
          this.totalItems = data.totalCount;
          this.dataSource = new MatTableDataSource(data.data);
          this._cd.detectChanges();
        },
        error: error => {
        }
      })
    }
  } */

  getIsInvoices(invoice:any[]){
    if(invoice.length > 0){
      return true;
    }
    else return false;
  }

}
