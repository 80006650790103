import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';

import { ExportComponent } from './export.component';

import { ExportService } from './export.service';

@NgModule({
	imports: [CommonModule, SharedModule],
	declarations: [ExportComponent],
	providers: [ExportService],
	exports: [ExportComponent],
})
export class ExportModule {}
