<div class="modal-body">
  <div class="ErrorModule-content">
    <p class="heading">Enable MFA for all users</p>
    <p class="font-size-15">If you would like to move forward with this recommendation, select a date you’d like to get this project scheduled. We’ll then follow up with you to kick off this project.</p>
    <div class="form-group-2 q-d-flex flex-row" style="gap: 56px">
			<div class="publish-time">
				<div [formGroup]="form">
					<label class="message-label">Start Date</label>
					<app-date-picker
						inputClass="form-control-2"
						name="date"
						formControlName="startDate"
						(changeDate)="
							refreshDateRangeFieldValidation(form.get('startDate')!)
						"
						errorTooltip
						[etControl]="form.get('startDate')!"
						[etIsTouched]="form.get('startDate')!.touched"
						[etMessage]="[{ error: 'required', message: 'Date is required' }]"
						[etShow]="
							form.get('startDate')!.touched &&
							!!form.get('startDate')!.errors
						"
					>
					</app-date-picker>
				</div>
			</div>

		</div>
  </div>
</div>
<div class="modal-footer q-g-16" [class.flex-start]="!data.options && data.altMessage">
  <p style="font-size:12px !important;"><span style="font-weight: bold;">Please Note:</span> The start date can be changed at a later time when discussing the details of the project with your Project Manager.</p>

  <button
    class="imp-disabled-op"
    type="button"
    [class.btn-2-primary]="
      data.type == 'success' ||
      data.type == 'info' ||
      data.type == 'warn'
    "
    [class.btn-2-destructive]="data.type == 'danger'"
    class="btn-2"
    (click)="closeModal(true)"
  >
    Submit
  </button>
</div>
