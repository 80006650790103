import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import {
	adminUrlUpdateAction,
	userUrlUpdateAction,
} from '@app/core/store/user/user.action';
import { roleIdSelector } from '@app/core/store/user/user.selector';
import { select, Store } from '@ngrx/store';
import { filter, take, catchError, of, first, switchMap, map } from 'rxjs';
import { flatMenuSelector } from '../navigation/side-bar/store/sidebar.selector';
import { SideBarService } from '../navigation/side-bar/side-bar.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(
		private router: Router,
		private store: Store<AppStateInterface>,
		private sidebarService: SideBarService,
		private localStorageService: LocalStorageService
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// Execute your function here
				this.store
					.pipe(
						select(roleIdSelector),
						catchError((e) => of(null)),
						filter((roleId) => roleId !== null),
						take(1)
					)
					.subscribe((roleId) => {
						this.checkRoute(roleId as any);
					});
			}
		});
	}

	updateStorageAndNavigate(newURL?: string) {
		if (newURL) this.localStorageService.setStorageObject('next', newURL);
		this.router.navigateByUrl('/');
	}

	smartNavigate() {
		return new Promise<boolean>((resolve) => {
			this.sidebarService.flatMenu$
			.pipe(take(1))
			.subscribe((menu) => {
				resolve(true);
				setTimeout(() => { // Added small delay so animations could finish first before navigating
					if (!menu || !menu[0]) this.router.navigate(['page-forbidden'], {replaceUrl: true});
					else this.router.navigate([menu[0].url]);
				}, 100);
			});
		});
	}

	smartUrl() {
		return this.sidebarService.flatMenu$.pipe(
			take(1),
			map((menu) => {
				if (!menu || !menu[0]) return 'page-forbidden';
				return menu[0].url;
			})
		);
	}

	navigateToForbiddenPage() {
		this.router.navigate(['page-forbidden'], {replaceUrl: true});
		return false;
	}
	navigateToDefault() {
		this.router.navigate(['/']);
	}

	navigateToMaintenance() {
		this.router.navigate(['/quest_under_maintenance'], {
			skipLocationChange: true,
		});
	}

	private checkRoute(roleId: number) {
		let action;
		switch (roleId) {
			case 1:
			case 2:
				action = adminUrlUpdateAction;
				break;
			case 3:
			default:
				action = userUrlUpdateAction;
		}
		if (!this.router.url.includes('/verify-email') && this.router.url != '/page-forbidden') {
			this.store.dispatch(action({ url: this.router.url as string }));
		}
	}
}
