import { GroupsService } from './../../../../modules/groups/groups.service';
import { Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';
import { MenuPermissionConstant } from '@app/shared/constants';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { SideBarService } from '../../side-bar/side-bar.service';

@Component({
	selector: '[app-sub-menu-item]',
	templateUrl: './sub-menu-item.component.html',
	styleUrls: ['./sub-menu-item.component.scss'],
})
export class SubMenuItemComponent implements OnInit {
	@Input() sideMenuData: RoleMenuInterface;
	@Input() menuId: number;
	id: number | string;
	constructor(
		public _sideBarService: SideBarService,
		public _companiesService: CompaniesService,
		public _contactsService: ContactsService,
		public _groupsService: GroupsService
	) {}

	ngOnInit(): void {
		// if(this.menuId === MenuPermissionEnum.CONTACTS_SPADMIN || this.menuId === MenuPermissionEnum.CONTACTS_CLIENTADMIN){
		if (
			this.menuId === MenuPermissionConstant.Contacts[1] ||
			this.menuId === MenuPermissionConstant.Contacts[2] ||
			this.menuId ===
				MenuPermissionConstant.Companies.subMenus!['contactSettings']![
					'subMenus'
				]!['contacts']![1]
		) {
			this.id = this._contactsService.subUserId!;
		} else if (this.menuId === MenuPermissionConstant.Companies[1]) {
			// if(this.menuId === MenuPermissionEnum.COMPANIES_SPADMIN){
			this.id = Number(this._companiesService.subCompanyId!);
		} else if (
			this.menuId === MenuPermissionConstant.Groups[1] ||
			this.menuId === MenuPermissionConstant.Groups[2]
		) {
			// if(this.menuId === MenuPermissionEnum.COMPANIES_SPADMIN){
			this.id = Number(this._groupsService.subGroupId!);
		}
	}

	navigate(url: string) {}
}
