import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QuickFilter } from './../../interfaces/order.interface';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { UserTypes } from '@app/shared/constants/global-enum';
import { UserService } from '@app/core/services/user.service';
import { FinanceService } from '@app/modules/finance/finance.service';

@Injectable({
  providedIn: 'root'
})
export class QuickFilterService {

  constructor(private _http: HttpClient, private _userService: UserService, private _financeService: FinanceService) { }

  get isAdmin() {
		return this._userService.userRole === UserTypes.SourcepassAdmin;
	}

	get currentCompanyId() {
		return this._userService.companyId;
	}

	get selectedCompanyId() {
		return JSON.parse(localStorage.getItem(this.isAdmin ? 'companyAdmin' : 'companyUser') as any)?.companyId;
	}

  getquickFilters(url: string): Observable<QuickFilter[]> {
    const param: any = this.getValidParameters({ companyId: this._financeService.companyIdType});
    return this._http.get<QuickFilter[]>(`${environment.apiBaseUrl}${url}/GetQuickFilterStatus`, { params: param });
  }

  mapStatusIds(formStatus: Number[], filterData: QuickFilter[]){
    let statusIds = formStatus;
    for (let index = 0; index < filterData.length; index++) {
      if (!statusIds.includes(filterData[index].id)) {
        if (filterData[index].selected === true) {
          statusIds = [...statusIds, filterData[index].id]
        }
      } else {
        if (filterData[index].selected === false) {
          const i = statusIds.indexOf(filterData[index].id);
          // statusIds.splice(i, 1);
          statusIds = statusIds.filter(x => x !== filterData[index].id)
        }
      }

    }
    return statusIds as number[];
  }

  getValidParameters(param: any) {
		return Object.fromEntries(Object.entries(param).filter(([key, value]: any) => !['', null, undefined].includes(value)));
	}
}
