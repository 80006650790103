import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

import {
	BehaviorSubject,
	finalize,
	Observable,
	ReplaySubject,
	Subject,
	tap,
} from 'rxjs';

import { toFormData } from '@app/shared/utilities/helper';

import { ApplicationType, IDropdown, UserTypes } from '@app/shared/constants';

import {
	ChildCompany,
	CompanyBranding,
	ParentChildAccess,
	ParentChildAccessType,
	ParentChildLink,
} from '@app/shared/interfaces/companies.interface';
import { ICompanies } from './pages/company-list/store/companies.interface';
import { IContacts } from './pages/company-directory/store/contact/contacts.interface';
import { NameId } from '@app/shared/interfaces/support.interface';
import { ResponseResult } from '@app/shared/interfaces/general/response/response-result.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CompaniesService {
	bcCompanyName: Observable<string | null>;
	isCompanyMenuShow: Observable<boolean>;

	searchCompanyText: string = '';
	searchContactText: string = '';

	searchBoardText: string = '';
	orgChartEmployeeId: number | undefined;

	activePage: string = '';

	searchOrgChart = '';
	level = 0;

	statusId?: number[] | null = [];
	typeId?: number[] | null = [];
	territoryId?: number[] | null = [];

	companyGroupId?: number[] | null = [];
	companyApplicationId?: number[] | null = [];
	companyDepartmentId?: number[] | null = [];
	companySiteId?: number[] | null = [];
	subCompanyId: number | null;

	companyIds?: number[] | null = [];

	isConnectWise = false;

	globalDirectoryFilters: IContacts;
	companyDirectoryFilters: IContacts;
	userDirectoryFilters: IContacts;
	companyFilters: ICompanies;

	public branding: {
		lightThemeColor: string;
		darkThemeColor: string;
	} = {
		lightThemeColor: '#f8f8f8',
		darkThemeColor: '#222222',
	};

	private _currentCompany$ = new BehaviorSubject<any>(null);

	private _companyData = new ReplaySubject<any[]>(1);
	companyData$ = this._companyData.asObservable();

	private _companyTotalItems = new ReplaySubject<number>(1);
	companyTotalItems$ = this._companyTotalItems.asObservable();

	private _globalDirectoryData = new ReplaySubject<any[]>(1);
	globalDirectoryData$ = this._globalDirectoryData.asObservable();

	private _globalDirectoryTotalItems = new ReplaySubject<number>(1);
	globalDirectoryTotalItems$ = this._globalDirectoryTotalItems.asObservable();

	private _companyDirectoryData = new ReplaySubject<any[]>(1);
	companyDirectoryData$ = this._companyDirectoryData.asObservable();

	private _companyDirectoryTotalItems = new ReplaySubject<number>(1);
	companyDirectoryTotalItems$ = this._companyDirectoryTotalItems.asObservable();

	private _userDirectoryData = new ReplaySubject<any[]>(1);
	userDirectoryData$ = this._userDirectoryData.asObservable();

	private _userDirectoryTotalItems = new ReplaySubject<number>(1);
	userDirectoryTotalItems$ = this._userDirectoryTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	private bcCompanyNameSource = new Subject<string | null>();
	private companyMenuSource = new BehaviorSubject<boolean>(true);
	private _companyBrandingChange = new Subject<any>();
	companyBrandingChange = this._companyBrandingChange.asObservable();

	constructor(private _http: HttpClient) {
		this.bcCompanyName = this.bcCompanyNameSource.asObservable();
		this.isCompanyMenuShow = this.companyMenuSource.asObservable();
	}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	initCurrentCompany(companyId: number) {
		this.getCompanyById(companyId).subscribe((v) =>
			this._currentCompany$.next(v)
		);
	}

	getIntegrationData(intId: number, companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}IntegrationType/GetById?integrationType=${intId}&companyId=${companyId}`
		);
	}

	updateIntegrationData(body: any) {
		return this._http.post(
			`${environment.apiBaseUrl}IntegrationType/AddOrUpdate`,
			body
		);
	}

	toggleCompanyMenu(bool: boolean) {
		this.companyMenuSource.next(bool);
	}

	changeCompanyName(name: string) {
		this.bcCompanyNameSource.next(name);
	}

	clearBoardFilters() {
		this.searchBoardText = '';
	}

	clearCompanyFilters() {
		this.searchCompanyText = '';
		this.statusId = [];
		this.typeId = [];
		this.territoryId = [];
	}

	clearCompanyDirectoryFilters() {
		this.searchContactText = '';
		this.companyApplicationId = [];
		this.companyDepartmentId = [];
		this.companyGroupId = [];
		this.companySiteId = [];
		this.companyIds = [];
	}

	clearOrgChartFilter() {
		this.level = 0;
		this.searchOrgChart = '';
		this.orgChartEmployeeId = undefined;
	}

	setSearchText(searchText: string, flag?: string) {
		if (flag === 'user') {
			this.searchContactText = searchText;
		} else {
			this.searchCompanyText = searchText;
		}
	}

	getBoards(id: number) {
		return this._http.get(`${environment.apiBaseUrl}SRBoard/Company/${id}`);
	}

	getCompanies(options: ICompanies) {
		var param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
		} as any;

		if (
			options.typeIds !== undefined &&
			options.typeIds !== null &&
			options.typeIds?.length > 0
		) {
			param.TypeId = options.typeIds;
		}

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			param.StatusId = options.statusIds;
		}

		if (
			options.territoryIds !== undefined &&
			options.territoryIds !== null &&
			options.territoryIds?.length > 0
		) {
			param.TerritoryId = options.territoryIds;
		}

		this.companyFilters = options;
		this.clearCompanyData();

		return this._http
			.get<any>(`${environment.apiBaseUrl}companies`, {
				params: param,
			})
			.pipe(
				tap((res) => {
					this.setCompanyData(res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	clearCompanyData() {
		this.isLoading = true;
		this._companyData.next([]);
		this._companyTotalItems.next(0);
	}

	setCompanyData(res: any) {
		this._companyData.next(res.data);
		this._companyTotalItems.next(res.totalCount);
	}

	getCompanyStatusDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetCompanyStatusDropdown`
		);
	}

	getCompanyTypesDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetCompanyTypesDropdown`
		);
	}

	getCompanyTerritoryDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetCompanyTerritoryDropdown`
		);
	}

	getCompanyAgreementStatusDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetAgreementStatusDropdown`
		);
	}

	getCompanyAgreementTypeDropdown(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetCompanyAgreementTypesDropdown/${companyId}`
		);
	}

	deleteCompany(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}companies/${id}`);
	}

	getCompanyById(id: number) {
		return this._http.get(`${environment.apiBaseUrl}companies/${id}`);
	}

	getCompanyForBreadcrumb(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetCompanyForBreadcrumb?companyId=${companyId}`
		);
	}

	getSitesDropdown(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}sites/GetDropdown?companyId=${companyId}`
		);
	}

	getDropdownByCompanyIds(companyId: number) {
		return this._http.post(
			`${environment.apiBaseUrl}Sites/GetDropdownByCompanyIds`,
			[companyId]
		);
	}

	getCompanyBranding(companyId: number) {
		return this._http.get<CompanyBranding>(
			`${environment.apiBaseUrl}companies/GetBrandingById/${companyId}`
		);
	}

	updateCompanyBranding(app: any) {
		const body = toFormData(app);

		body.append('faviconFile', app.faviconFile);
		body.append('logoFile', app.logoFile);

		return this._http
			.put(
				`${environment.apiBaseUrl}companies/UpdateBranding/${app.companyId}`,
				body
			)
			.pipe(tap({ next: (res) => this._companyBrandingChange.next(res) }));
	}

	getCompanyBySiteId(siteId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetSiteById/${siteId}`
		);
	}

	setCompanyBranding(lightThemeColor: string, darkThemeColor: string) {
		this.branding.lightThemeColor = lightThemeColor;
		this.branding.darkThemeColor = darkThemeColor;
	}

	getCompanyDropdown(): Observable<IDropdown[]> {
		return this._http.get<IDropdown[]>(
			`${environment.apiBaseUrl}companies/GetDropdown`
		);
	}

	getCompanyLogo(id: number) {
		return this._http.get(
			`${environment.apiBaseUrl}companies/GetCompanyLogo/${id}`
		);
	}

	getUsersDropdownByCompanyId(
		companyId: number,
		search?: string
	): Observable<any[]> {
		if (search) {
			return this._http.get<any[]>(
				`${environment.apiBaseUrl}users/GetDropdownByCompanyId/${companyId}?searchText=${search}`
			);
		} else {
			return this._http.get<any[]>(
				`${environment.apiBaseUrl}users/GetDropdownByCompanyId/${companyId}`
			);
		}
	}

	getUsersDropdownForOrgChart(companyId: number): Observable<any[]> {
		return this._http.get<any[]>(
			`${environment.apiBaseUrl}users/GetDropdownForOrgChart/${companyId}`
		);
	}

	getContactListByCompanyId(options: IContacts) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (!options.isGlobal) {
				this.companyDirectoryFilters = options;
			} else {
				this.globalDirectoryFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this.companyDirectoryFilters = options;
		} else if (options.userRole === UserTypes.User) {
			this.userDirectoryFilters = options;
		}

		this.isLoading = true;

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (!options.isGlobal) {
				this._companyDirectoryData.next([]);
				this._companyDirectoryTotalItems.next(0);
			} else {
				this._globalDirectoryData.next([]);
				this._globalDirectoryTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companyDirectoryData.next([]);
			this._companyDirectoryTotalItems.next(0);
		} else if (options.userRole === UserTypes.User) {
			this._userDirectoryData.next([]);
			this._userDirectoryTotalItems.next(0);
		}

		return this._http
			.post<ResponseResult<any[]>>(
				`${environment.apiBaseUrl}Companies/GetContactList`,
				options
			)
			.pipe(
				tap((res: any) => {
					if (options.userRole === UserTypes.SourcepassAdmin) {
						if (!options.isGlobal) {
							this._companyDirectoryData.next(res.data);
							this._companyDirectoryTotalItems.next(res.totalCount);
						} else {
							this._globalDirectoryData.next(res.data);
							this._globalDirectoryTotalItems.next(res.totalCount);
						}
					} else if (options.userRole === UserTypes.ClientAdmin) {
						this._companyDirectoryData.next(res.data);
						this._companyDirectoryTotalItems.next(res.totalCount);
					} else if (options.userRole === UserTypes.User) {
						this._userDirectoryData.next(res.data);
						this._userDirectoryTotalItems.next(res.totalCount);
					}
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getGroupsDropdown(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}groups/DropDownList?companyId=${companyId}`
		);
	}

	getApplicationsDropdown(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Application/DropDownList?companyId=${companyId}`
		);
	}

	getDepartmentsDropdown(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Companies/GetCompanyDepartmentDropdown?companyId=${companyId}`
		);
	}

	getOrgChartByUserId(
		companyId: number,
		userId: any,
		level: number | null,
		viewUpperLevel = false
	) {
		let route = `${environment.apiBaseUrl}Companies/GetOrgChart?companyId=${companyId}&viewUpperLevel=${viewUpperLevel}`;

		if (userId) {
			route += `&userId=${userId}`;
		}

		if (level) {
			route += `&level=${level}`;
		}

		return this._http.get(route);
	}

	getUserDropDown(searchText?: string) {
		return this._http.get(
			`${environment.apiBaseUrl}Users/GetDropdown?search=${searchText || ''}`
		);
	}

	updateReportsTo(id: number, payload: Params) {
		return this._http.put(
			`${environment.apiBaseUrl}Companies/ReportsToUpdate/${id}`,
			payload
		);
	}

	getBrandingHistory(companyId: number, pageNumber: number, pageSize: number) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}Companies/${companyId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}

	getLevelOptions(
		companyId: number,
		userId: number | null = null,
		viewUpperLevel = false
	) {
		if (userId) {
			return this._http.get(
				`${environment.apiBaseUrl}Companies/GetOrgChartDropdown?companyId=${companyId}&userId=${userId}&viewUpperLevel=${viewUpperLevel}`
			);
		} else {
			return this._http.get(
				`${environment.apiBaseUrl}Companies/GetOrgChartDropdown?companyId=${companyId}&viewUpperLevel=${viewUpperLevel}`
			);
		}
	}

	getBoardList(
		page: number,
		pageSize: number,
		column: string,
		order: string,
		searchText: string,
		companyId: number
	) {
		const apiUrl = `${environment.apiBaseUrl}SRBoard/GetListByCompanyId/${companyId}?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&searchText=${searchText}`;
		return this._http.get(apiUrl);
	}

	setBoardByDefault(boardId: number, companyId: number) {
		return this._http.get(`${environment.apiBaseUrl}?companyId=${companyId}`);
	}

	updateBoard(payload: Params) {
		return this._http.post(
			`${environment.apiBaseUrl}SRBoard/AddOrUpdateBatch`,
			payload
		);
	}

	getBoardDropdown() {
		return this._http.get(`${environment.apiBaseUrl}SRBoard/GetDropdownList`);
	}

	deleteBoard(boardId: number, companyId: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}SRBoard/${boardId}/${companyId}`
		);
	}

	getBoardDetails(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}SRBoard/GetCompanyBoardDetail/${companyId}`
		);
	}

	updateDefaultBoards(boardId: number, companyId: number) {
		return this._http.put(
			`${environment.apiBaseUrl}SRBoard/UpdateDefaultBoardForCompany?boardId=${boardId}&companyId=${companyId}`,
			{}
		);
	}

	getAgreementTypeList(
		page: number,
		pageSize: number,
		column: string,
		order: string,
		searchText: string,
		companyId: number
	) {
		let apiUrl = `${environment.apiBaseUrl}Companies/GetAgreementTypesList?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&search=${searchText}&companyId=${companyId}`;
		return this._http.get(apiUrl);
	}

	deleteAgreementType(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}AgreementType/${id}`);
	}

	updateAgreementType(payload: Params) {
		return this._http.post(
			`${environment.apiBaseUrl}AgreementType/AddBatch`,
			payload
		);
	}

	getAgreementTypeDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}Companies/GetAgreementTypesDropdown`
		);
	}

	getAgreementTypeDetails(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Companies/GetCompanyAgreementTypesDropdown/${companyId}`
		);
	}

	getUserDataAccessList(
		permissionTypeId: number,
		userId: number,
		page: number,
		pageSize: number,
		column: string,
		order: string,
		searchText: string
	) {
		let apiUrl = `${environment.apiBaseUrl}Permissions/UserDataAccessList?PermissionTypeId=${permissionTypeId}&UserId=${userId}&Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&search=${searchText}`;
		return this._http.get(apiUrl);
	}

	deleteUserDataAccess(payload: Params) {
		return this._http.post(
			`${environment.apiBaseUrl}Permissions/UserDataAccessDelete`,
			payload
		);
	}

	updateUserDataAccess(payload: Params) {
		return this._http.post(
			`${environment.apiBaseUrl}Permissions/UserDataAccessSave`,
			payload
		);
	}

	getDropdownOptions(permissionTypeId: number, userId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Permissions/CompanyDataUserAccess/${permissionTypeId}/${userId}`
		);
	}

	getCompanyAdmin(companyId: any, options: any) {
		let obs: Observable<any>;

		if (this.isConnectWise)
			obs = this._http.get(
				`${environment.apiBaseUrl}CW_Pods/GetCompanyAdmin/${companyId}?Page=${
					options.page
				}&PageSize=${options.pageSize}&Column=${options.column}&Order=${
					options.order
				}${options.search ? '&Search=' + options.search : ''}`
			);
		else
			obs = this._http.get(
				`${environment.apiBaseUrl}Users/GetCompanyAdmin/${companyId}?Page=${
					options.page
				}&PageSize=${options.pageSize}&Column=${options.column}&Order=${
					options.order
				}${options.search ? '&Search=' + options.search : ''}`
			);

		return obs;
	}
	updateRole(id: any, roleId: any) {
		const body = {
			userId: id,
			roleId: roleId,
		};
		if (this.isConnectWise)
			return this._http.put(
				`${environment.apiBaseUrl}CW_Pods/UpdateRole/${id}`,
				body
			);
		else
			return this._http.put(
				`${environment.apiBaseUrl}Users/UpdateRole/${id}`,
				body
			);
	}
	getOverride(companyId: any, userId: any) {
		let obs: Observable<any>;

		if (this.isConnectWise)
			obs = this._http.get(
				`${environment.apiBaseUrl}CW_Pods/GetOverride?CompanyId=${companyId}&userId=${userId}`
			);
		else
			obs = this._http.get(
				`${environment.apiBaseUrl}MenuPermissions/GetOverride?CompanyId=${companyId}&userId=${userId}`
			);

		return obs;
	}
	setOverride(data: any) {
		return this._http.post(
			`${environment.apiBaseUrl}MenuPermissions/SetOverride`,
			data
		);
	}

	export(options: ICompanies, exportType: ApplicationType) {
		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			exportType,
		} as any;

		if (
			options.typeIds !== undefined &&
			options.typeIds !== null &&
			options.typeIds?.length > 0
		) {
			param.TypeId = options.typeIds;
		}

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			param.StatusId = options.statusIds;
		}

		if (
			options.territoryIds !== undefined &&
			options.territoryIds !== null &&
			options.territoryIds?.length > 0
		) {
			param.TerritoryId = options.territoryIds;
		}

		return this._http.get(
			`${environment.apiBaseUrl}Companies/ExportCompanies`,
			{
				params: param,
				responseType: 'text',
			}
		);
	}

	exportContacts(
		options: IContacts,
		exportType: ApplicationType,
		companyId: number
	) {
		options.roleId = options.roleIds?.toString();
		let body = { ...options, ...{ exportType, companyId } } as any;

		if (companyId > 0) body.companyIds = [companyId];
		const params = new HttpParams({ fromObject: body });

		return this._http.get(`${environment.apiBaseUrl}Companies/ExportContacts`, {
			params,
			responseType: 'text',
		});
	}

	exportContacts2(
		options: IContacts,
		exportType: ApplicationType,
		companyId: number
	) {
		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			CompanyId: companyId,
			FirstLetter: options.firstLetter,
			exportType,
		} as any;

		if (
			options.companyIds !== undefined &&
			options.companyIds !== null &&
			options.companyIds?.length > 0
		) {
			param.CompanyIds = options.companyIds;
		}

		if (
			options.groupId !== undefined &&
			options.groupId !== null &&
			options.groupId?.length > 0
		) {
			param.GroupId = options.groupId;
		}

		if (
			options.applicationId !== undefined &&
			options.applicationId !== null &&
			options.applicationId?.length > 0
		) {
			param.ApplicationId = options.applicationId;
		}

		if (
			options.departmentId !== undefined &&
			options.departmentId !== null &&
			options.departmentId?.length > 0
		) {
			param.DepartmentId = options.departmentId;
		}

		if (
			options.siteId !== undefined &&
			options.siteId !== null &&
			options.siteId?.length > 0
		) {
			param.SiteId = options.siteId;
		}

		if (companyId > 0) param.companyIds = [companyId];

		return this._http.get(`${environment.apiBaseUrl}Companies/ExportContacts`, {
			params: param,
			responseType: 'text',
		});
	}

	getCompanyDomain(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}Companies/CompanyDomains?id=${companyId}`
		);
	}
	saveCompanyDomain(companyId: number, domains: string) {
		const body = {
			companyId,
			domains,
		};
		return this._http.post(
			`${environment.apiBaseUrl}Companies/UpdateCompanyDomainIdentifier`,
			body
		);
	}

	// Parent Child Company
	getAvailableCompanies(query?: string, page?: number, pageSize?: number) {
		return this._http.get<ResponseResult<NameId[]>>(`
		${environment.apiBaseUrl}ParentChildCompany/GetAvailableCompanies?
			${query ? 'SearchText=' + query + '&' : ''}
			${page ? 'Page=' + page + '&' : ''}
			${pageSize ? 'PageSize=' + pageSize + '&' : ''}
		`);
	}

	getCurrentChildren(companyId: number) {
		return this._http.get<ChildCompany[]>(
			`${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren/${companyId}`
		);
	}

	getDirectoryChildCompanies() {
		const url = `${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren?access=${ParentChildAccessType.Directory}`;
		return this._http.get<NameId[]>(url);
	}

	updateCompanyChildren(companyId: number, childIds: number[]) {
		return this._http.put(
			`${environment.apiBaseUrl}ParentChildCompany/${companyId}`,
			{ childIds }
		);
	}

	setParentCompanyAccess(data: ParentChildAccess) {
		return this._http.put(
			`${environment.apiBaseUrl}ParentChildCompany/SetChildrenAccess/${data.id}`,
			data
		);
	}

	getParentChildLink(companyId: number) {
		return this._http.get<ParentChildLink>(
			`${environment.apiBaseUrl}ParentChildCompany/GetParentChildLink/${companyId}`
		);
	}
	// End Parent Child Company

	validateCustomDomain(customDomain: string) {
		return this._http.post<boolean>(
			`${environment.apiBaseUrl}Companies/ValidateCustomDomain`,
			{ customDomain }
		);
	}

	// getters
	get currentCompany$() {
		return this._currentCompany$.asObservable();
	}
}
