import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

// add values here if you have your own template
type DateFilterTemplate = 'default' | 'double-li' | 'row-inline';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements AfterViewInit, OnChanges {
  // Main property - to be used for testing
  startDate: string = '';
  endDate: string = '';
  // Date Labels
  @Input() dateLabels : {start:string, end:string}= {
    start: 'Start Date',
    end: 'End Date'
  }
  // Input - for defining templates
  @Input() template:DateFilterTemplate = 'default';
  @Input() clearDates = false;
  // ElementRef
  @ViewChild('startDate') startDateEl: ElementRef;
  @ViewChild('endDate') endDateEl: ElementRef;
  // output
  @Output() startDateEmitter = new EventEmitter<string>();
  @Output() endDateEmitter = new EventEmitter<string>();

  startDateFc = new FormControl();
  endDateFc = new FormControl();

  private _distinct = {
    start: '',
    end: '',
  }

  startVal: string;
  constructor(private _renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.clearDates) {
      this.startDateFc.setValue('');
      this.endDateFc.setValue('');
    }
  }

  ngAfterViewInit (): void {
  }

  emitStartDate(){
    const data = this.startDateEl.nativeElement.value

    const startDate = new Date(data);
    if(
      startDate.toString() == 'Invalid Date'
      && data != this._distinct.start
    ){
      this.startDateEmitter.emit('');
      this._distinct.start = '';
    }
  }

  emitEndDate(){
    const data = this.endDateEl.nativeElement.value
    const endDate = new Date(data);
    if(
      endDate.toString() == 'Invalid Date'
      && data != this._distinct.end
    ){
      this.endDateEmitter.emit('');
      this._distinct.end = '';
    }
  }

  onChangeStartDate(data: string | Date): void {
    const formattedStartDate = moment(data).format('yyyy-MM-DD');
    if (!data && formattedStartDate != this.startDate) {
      this.startDateEmitter.emit('');
      this.startDate = '';
    } else {
      const startDate = new Date(data);
      const endDate = new Date(this.endDate);

      if(startDate.getFullYear() > 1900){
        this.startDateEmitter.emit(formattedStartDate);
        this.startDate = formattedStartDate;
      }

      if (startDate > endDate) {
        this.endDateEmitter.emit(formattedStartDate);
        this.endDate = formattedStartDate;
      }
    }
  }

  onChangeEndDate(data: string | Date): void {
    const formattedEndDate = moment(data).format('yyyy-MM-DD');
    if(!data && formattedEndDate != this.endDate){
      this.endDateEmitter.emit('');
      this.endDate = '';
    } else {
      const endDate = new Date(data);
      const startDate = new Date(this.startDate)

      if(startDate > endDate){
        this.endDate = this.startDate;
      }else if(endDate.getFullYear() > 1900){
        this.endDateEmitter.emit(formattedEndDate);
        this.endDate = formattedEndDate;
      }
    }
  }

  get getDateClass(): string {
    let value = 'form-control';

    if(this.template == 'default') {
      value += ' d-inline'
    } else if(this.template == 'double-li') {
      value += ' mainsearch'
    }

    return value;
  }
}
