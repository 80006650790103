import { Injectable } from '@angular/core';
import { DefaultCreditAmount } from '@app/shared/constants/transaction-type.enum';

@Injectable({
	providedIn: 'root',
})

export class PaymentProcessService {
	selectedCreditItems: any = [];
	isCalculationsDone: boolean = false;

	checkAllItems(cred: any, isCheckedAll: boolean) {
		if (cred.isChecked) {
			this.checkItems(cred);

		} else {
			if (!isCheckedAll) {
				this.uncheckItems(cred);
			}
		}
	}

	checkItems(cred: any) {
		this.selectedCreditItems.push({
			id: cred.id,
			toApply: cred.toApply,
			isChecked: true,
			transactionType: cred.transactionType
		});
	}

	uncheckItems(cred: any) {
		let index = this.selectedCreditItems.findIndex((selectedCred: any) => cred.id === selectedCred.id);
		this.selectedCreditItems.splice(index, 1);
	}

	creditInputCalculation(
		row: any,
		isSelectCreditRecord: boolean = false,
		pendingPayment: any
	) {
		const totalItems = this.selectedCreditItems
			.filter((inv: any) => row.id !== inv.id)
			.map((e: any) => Number(e.toApply))
			.reduce((a: any, b: any) => a + b, 0);

		const remainingAmount = (
			pendingPayment.invoice > totalItems
				? pendingPayment.invoice - totalItems
				: totalItems - pendingPayment.invoice
		).toFixed(2);

		if (this.isCalculationsDone) {
			row.isChecked = false;
		}

		if (isSelectCreditRecord) {
			if (row.isChecked) {
				if (row.amount <= remainingAmount) {
					row.toApply = row.amount;

				} else {
					if (remainingAmount !== DefaultCreditAmount.creditAmount) {
						row.toApply = remainingAmount;
						this.isCalculationsDone = true;

					} else {
						row.toApply = DefaultCreditAmount.creditAmount;
						row.isChecked = false;
					}
				}
			} else {
				row.toApply = DefaultCreditAmount.creditAmount;
			}

		} else {
			row.isChecked = !['', null, '0', '0.', '0.0', '0.00', 0, 0.00].includes(row.toApply);

			const creditInputTotal = this.selectedCreditItems
				.map((e: any) => Number(e.toApply))
				.reduce((a: any, b: any) => a + b, 0);

			const remainingAmount =	(
					pendingPayment.invoice > totalItems
						? pendingPayment.invoice - totalItems
						: totalItems - pendingPayment.invoice
				).toFixed(2);

			if (creditInputTotal <= pendingPayment.invoice) {
				if (row.toApply > row.amount) {
					row.toApply = row.amount;

				} else {
					if (
						row.toApply <= remainingAmount ||
						Number(remainingAmount) === 0
					) {
						row.toApply = row.toApply;

					} else {
						if (row.toApply <= remainingAmount || row.toApply.length) {
							row.toApply = row.toApply;
						} else {
							row.toApply = remainingAmount;
						}
					}
				} 

			} else {
				row.toApply = remainingAmount;
			}

			const hasExistCreditId = this.selectedCreditItems.some((inv: any) => row.id === inv.id);
      
			let cIndex = this.selectedCreditItems.findIndex((c: any) => row.id === c.id);

			if (row && !hasExistCreditId && row.isChecked) {
				this.selectedCreditItems.push({
					id: row.id,
					toApply: row.toApply,
					isChecked: row.isChecked,
					transactionType: row.transactionType
				});
				
			} else {
				if (row.isChecked) {
					this.selectedCreditItems[cIndex].toApply = Number(row.toApply);
				}
			}

			if (!row.isChecked && this.selectedCreditItems[cIndex]) {
				this.selectedCreditItems.splice(cIndex, 1);
			}
		}
	}

	getCreditsTotalAmount(transactionType: string) {
		return this.selectedCreditItems
					.filter((inv: any) => inv.transactionType === transactionType && inv.isChecked)
					.map((e: any) => Number(e.toApply))
					.reduce((a: any, b: any) => a + b, 0);
	}
}
