import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarbonCopyComponent } from './carbon-copy.component';
import { BootstrapTooltipModule } from '@app/shared/directives/bootstrap/bootstrap-tooltip.module';
import {MatIconModule} from '@angular/material/icon';
import { NoProfileAvatarModule } from '../no-profile-avatar/no-profile-avatar.module';
import { TooltipContentV2Module } from '@app/shared/directives/tooltip-content-v2/tooltip-content-v2.module';

@NgModule({
  imports: [
    CommonModule,
    BootstrapTooltipModule,
    MatIconModule,
    NoProfileAvatarModule,
    TooltipContentV2Module
  ],
  declarations: [CarbonCopyComponent],
  exports: [CarbonCopyComponent]
})
export class CarbonCopyModule { }
