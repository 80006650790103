import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { take } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { environment } from 'environments/environment';

import { AppStateInterface } from '@app/core/store/app-state.interface';

import { impersonateSelector, messageSelector } from './store/top-bar.selector';

import { topBarUpdateAction } from './store/top-bar.action';

@Injectable({
	providedIn: 'root',
})
export class TopBarService {
	constructor(
		private store: Store<AppStateInterface>,
		private _http: HttpClient
	) {}

	onMessageClick() {
		this.messageClicked();
	}

	onImpersonateClick() {
		this.impersonateClicked();
	}

	clearAllNotification() {
		return this._http.get(
			`${environment.apiBaseUrl}UserMessages/ClearAllBellNotifications`
		);
	}

	private messageClicked() {
		this.store.pipe(select(messageSelector), take(1)).subscribe((data) => {
			this.store.dispatch(
				topBarUpdateAction({ message: { isOpen: !data.isOpen } })
			);
		});
	}

	private impersonateClicked() {
		this.store.pipe(select(impersonateSelector), take(1)).subscribe((data) => {
			this.store.dispatch(
				topBarUpdateAction({ impersonate: { isOpen: !data.isOpen } })
			);
		});
	}
}
