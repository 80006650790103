import { IKnowledgeBase } from '../kb-topic.interface';
import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  COMPANY_KB_TOPICS_UPDATE = '[CompanyKbTopics] Update',
  COMPANY_GLOBAL_KB_TOPICS_UPDATE_SUCCESS = '[CompanyKbTopics] Update Success',

}

export const companyKbTopicsUpdateAction =
createAction(
  ActionTypes.COMPANY_KB_TOPICS_UPDATE,
  props<Partial<IKnowledgeBase>>()
)

export const companyKbTopicsUpdateSuccessAction =
createAction(
  ActionTypes.COMPANY_GLOBAL_KB_TOPICS_UPDATE_SUCCESS
)
