import { createReducer, on } from "@ngrx/store"
import { ITopBar } from "../top-bar.interface"
import { topBarUpdateAction, topBarUpdateSuccessAction } from "./top-bar.action"

const initialState: ITopBar = {
  message:{
    isOpen: false
  },
  impersonate:{
    isOpen: false
  },
  sidebar:{
    isOpen: true
  }
}

export const topBarReducer =
createReducer(
  initialState,
  on(topBarUpdateAction, (state,action): ITopBar => ({
    ...state,
    ...action
  })),
  on(topBarUpdateSuccessAction, (state): ITopBar => ({
    ...state,
  })),
)
