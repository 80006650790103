import { MatTabsModule } from '@angular/material/tabs';
import { CompaniesKBAddEditComponent } from './companies-kb-add-edit.component';
import { CompaniesKnowledgeBaseComponent } from './companies-kb.component';
import { KbTopicListModule } from './../../../modules/knowledge-base/pages/kb-topic-list/kb-topic-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';




@NgModule({
  declarations: [
    CompaniesKnowledgeBaseComponent,
    CompaniesKBAddEditComponent
  ],
  imports: [
    CommonModule,
    KbTopicListModule,
    MatTabsModule
  ],

})
export class CompaniesKnowledgeBaseModule {

}
