import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ChangeDetectorRef,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	Validators,
	FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { tap, finalize } from 'rxjs';

import { BaseUserComponent } from '@app/shared/components/user/base-user.component';

import { SpinnerService } from './../../../../../core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { ImageService } from '@app/shared/services/image.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { ApplicationsService } from '@app/modules/applications/applications.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';

import { HistoryUrl, PageAction } from '@app/shared/constants';

import { IItems } from '../store/application-catalog.interface';
import { ApplicationCategory } from '@app/shared/interfaces/application.interface';

import { FormValidators } from '@app/shared/utilities/form-validators';

@Component({
	selector: 'app-app-catalog-add-edit',
	templateUrl: './app-catalog-add-edit.component.html',
	styleUrls: ['./app-catalog-add-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCatalogAddEditComponent
	extends BaseUserComponent
	implements OnInit
{
	form = new FormGroup({
		id: new FormControl(0),
		name: new FormControl('', [
			Validators.required,
			Validators.max(100),
			Validators.pattern(/^[a-zA-Z0-9_ ]*$/),
		]),
		iconURL: new FormControl(''),
		description: new FormControl('', [
			Validators.required,
			Validators.max(500),
		]),
		url: new FormControl('', [
			Validators.required,
			FormValidators.url,
			Validators.max(250),
		]),
		categoryId: new FormControl<number | null>(null, Validators.required),
		categoryName: new FormControl(''),
		file: new FormControl<File | null>(null, [
			Validators.required,
			FormValidators.fileTypes(['png', 'jpg', 'jpeg', 'webp']),
		]),
	});

	stateAction: string;

	iconSrc: string;
	categories: ApplicationCategory[] = [];

	paramId: number;
  historyUrl = HistoryUrl.APPLICATION_CATALOG;

	constructor(
		userService: UserService,
		public spinner: SpinnerService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _fb: FormBuilder,
		private _cd: ChangeDetectorRef,
		private _breadcrumbService: BreadcrumbService,
		private _applicationService: ApplicationsService,
		private _imageService: ImageService,
    private _dataModalService: DataModalService
	) {
		super(userService);
		this.paramId = Number(this._activatedRoute.snapshot.paramMap.get('id'));

		if (this.paramId) {
			this.stateAction = PageAction.EDIT;
		} else {
			this.stateAction = PageAction.ADD;
		}
	}

	ngOnInit() {
		this.spinner.start();
		this._setIdentity();
		this._getCategoryList();
	}

	ngAfterViewInit() {
		if (this.stateAction === PageAction.EDIT) {
			this._getApplicationDetails();
		}
	}

	deleteIcon() {
		const data = this._dataModalService.getProfilePicModel(
			'Icon',
			'This action will temporarily remove the Icon.'
		);

		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					this.iconSrc = '';
					this.form.get('file')?.setValue(null);
					this._cd.detectChanges();
				}
			},
		});
	}

	fileChange(file: File) {
		const isValidImageFile = this._imageService.isValidImageFile(file);

		if (isValidImageFile) {
			let fileReader = new FileReader();

			fileReader.onload = (event: any) => {
				const _file = file instanceof FileList ? file[0] : file;
				this.form.get('file')!.setValue(_file);
				this.iconSrc = event.target.result;
				this._cd.detectChanges();
			};

			if (file instanceof FileList) {
				fileReader.readAsDataURL(file[0]);
			} else {
				fileReader.readAsDataURL(file);
			}
		}
	}

	onSubmit() {
		if (this.form.valid) {
			this.spinner.start();

			if (this.stateAction === PageAction.ADD) {
				this._applicationService
					.addApplicationCatalog(this.form.getRawValue() as IItems)
					.subscribe({
						next: () => {
							this.spinner.stop();
							this._router.navigate(['../'], {
								relativeTo: this._activatedRoute,
							});
						},
						error: (error) => {
							this._showError(error);
						},
					});
			} else {
				this._applicationService
					.updateApplicationCatalog(
						this.paramId,
						this.form.getRawValue() as IItems
					)
					.subscribe({
						next: () => {
							this.spinner.stop();
							this._router.navigate(['../../'], {
								relativeTo: this._activatedRoute,
							});
						},
						error: (error) => {
							this._showError(error);
						},
					});
			}
		} else {
			this.form.markAllAsTouched();
		}
	}

	private _showError(error: any) {
		let message;
		const e = error?.errors || error.error.errors;

		if (e) {
			for (const err of e) {
				if (err.fieldName.toLowerCase() === 'name') {
					message = err.message.toString();

					if (message.includes('must not be empty')) {
						this.form.get('name')?.setErrors({ required: true });
					} else if (message.includes('exist')) {
						this.form.get('name')?.setErrors({ duplicateName: true });
					}

					this._cd.markForCheck();
				}
			}
		}

		this._cd.markForCheck();
		this.spinner.stop();
	}

	private _getApplicationDetails() {
		this._applicationService
			.getApplicationCatalogDetails(this.paramId)
			.pipe(
				tap((resp: IItems) => {
					this._breadcrumbService.updateBreadCrumbsText(
						'_applicationNameCatalog',
						resp.name
					);
				}),
        finalize(() => this.spinner.stop())
			)
			.subscribe({
				next: (resp: IItems) => {
					const data = resp;
					this.form = this._fb.group({
						id: [data.id],
						name: [
							data.name,
							[
								Validators.required,
								Validators.max(100),
								Validators.pattern(/^[a-zA-Z0-9_ ]*$/),
							],
						],
						iconURL: [data.iconURL],
						url: [
							data.url,
							[Validators.required, FormValidators.url, Validators.max(250)],
						],
						description: [
							data.description,
							[Validators.required, Validators.max(500)],
						],
						categoryId: [data.categoryId, Validators.required],
						categoryName: [data.categoryName],
						file: [
							data.file,
							FormValidators.fileTypes(['png', 'jpg', 'jpeg', 'webp']),
						],
					});

					this.iconSrc = data.iconURL;
				},
				error: (error) => {
					this._showError(error);
				},
			});
	}

	private _getCategoryList() {
		const _userId = this._activatedRoute.snapshot.params['userId']
			? Number(atob(this._activatedRoute.snapshot.params['userId']))
			: 0;
		const _companyId = this._activatedRoute.snapshot.paramMap.get('companyId')
			? Number(this._activatedRoute.snapshot.paramMap.get('companyId'))
			: this.companyId;

		this._applicationService
			.getCategoryDropdownList(_companyId, _userId)
			.pipe(
				tap((resp: any) => {
					this.categories = resp;
					this._cd.detectChanges();

					if (this.stateAction === PageAction.ADD) {
						this.spinner.stop();
					}
				})
			)
			.subscribe();
	}
}
