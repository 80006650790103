import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { createAction, props } from "@ngrx/store";


export enum ActionTypes{
  DASHBOARD_CARD_UPDATE = '[DashboardCard] Update',
  DASHBOARD_CARD_UPDATE_SUCCESS = '[DashboardCard] Update Success',

}

export const dashboardCardUpdateAction =
createAction(
  ActionTypes.DASHBOARD_CARD_UPDATE,
  props<Partial<IPageState>>()
)

export const dashboardCardUpdateSuccessAction =
createAction(
  ActionTypes.DASHBOARD_CARD_UPDATE_SUCCESS
)
