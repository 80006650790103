export interface Message {
    id: number;
    createdDate: string;
    isPinned: boolean;
    isRead: boolean;
    isSaved: boolean;
    title: string;
    message: string;
    readDate : string;
    categoryIcon?: string;
    categoryId?:number;
    categoryColor?: string;
    hasPrevious?: boolean;
    hasNext?: boolean;
    previousId?:number;
    nextId?:number;
    ticketId?:number;
    refId?:number;
    broadcastWebsiteLink?:string;
    _broadcastWebsiteLinkModified?:string;
    userId: number;
    name: string;
    imagePath: string;
}

export interface MessageCategory {
    id: number;
    name: string;
    iconName: string;
    color: string;
    messageCount: number;
    link?: string
}
