export interface KnowledgeBase {
	Id: number;
	CompanyId: number | null;
	CreatedBy: string | null;
	KBCategoryId: string;
	Title: string;
	Description: string;
	PrimaryTag: string;
	RelatedTags: keyValuePair;
	CategoryIds: { mainCategoryId: number; subCategoryId: number }[];
	SupportRequest: keyValuePair;
	RelatedTopics: keyValuePair;
	RelatedLinks: string[];
	Files?: any;
	LevelId: any;
	UserId?: any;
	userRole?: number;
}

export class KnowledgeBaseClass {
	constructor(public Id: number, public Name: string) {}
	// Id: number | null;
	// CompanyId: string | null;
	// CreatedBy: string | null;
	// KBCategoryId: string;
	// Title: string;
	// Description: string;
	// PrimaryTag: string;
	// RelatedTags: RelatedTag | null;
	// SupportRequest: SupportRequest | null;
	// RelatedTopics: RelatedTopic | null;
	// RelatedLinks: RelatedLink | null;
	// Files: any;
}

export interface TopicDetails {
	categories: string[];
	title: string;
	description: string;
	tags: string[];
}

export interface Tags {
	primary: string;
	related: string;
}

export interface RelatedDetails {
	supportRequestTeam: string[];
	relatedTopics: string[];
	relatedLinks: string[];
}

export interface GenericType {
	name: string;
	id: number;
}

export interface SupportRequest {
	Id: number;
	SupportRequestId: number;
}

export interface RelatedTopic {
	Id: number;
	RelatedKBTopicId: number;
}

export interface RelatedLink {
	Id: number;
	Link: string;
}

export interface RelatedTag {
	Id: number;
	Name: string;
}

export interface keyValuePair<T = any> {
	[key: string]: T;
}

export type KBCategory = {
	id: number;
	name: string;
	iconName?: string;
	color?: string;
	userId?: number;
	figmaIconId?: string;
};

export interface CategoryList {
	id: number;
	name: string;
	topicsCount?: number;
	iconName: string;
	color: string;
	companyId?: number;
	userId?: number;
	figmaIconId?: string;
}

export interface LatestTopic {
	id: number;
	title: string;
	description: string;
	categoryId: number;
}

export interface LatestTopic2 extends LatestTopic {
	color: string;
	iconName: string;
	figmaIconId?: string;
}

export interface TrendingTopic {
	id: number;
	title: string;
	description: string;
	categoryId: number;
	iconName: string;
	color: string;
	figmaIconId?: string;
}

export interface RecentlyReviewed {
	id: number;
	title: string;
	description: string;
	categoryId: number;
	iconName: string;
	color: string;
	figmaIconId?: string;
}

export interface KBTopTopic
	extends LatestTopic2,
		TrendingTopic,
		RecentlyReviewed {}

export type KBBot = {
	id: number;
	name: string;
	resultCount: number;
	greetings: string;
	initialResults: string;
	additionalResults: string;
	invalid: string;
	image: any;
};

export interface TopicsByCategory {
	id: number;
	title: string;
	description: string;
}

export interface HeaderSort {
	active: string;
	direction: string;
}

export interface TopicList {
	id: number;
	title: string;
	description: string;
	iconName?: string;
	color?: string;
}

export interface KBWidget {
	id: number;
	title: string;
	categoryId: number;
	categoryName: string;
}

export interface KBDetails {
	description: string;
	dislikes: number;
	id: number;
	isDisliked: boolean;
	isLiked: boolean;
	isQuickAccess: boolean;
	kbCategories: any[];
	kbCategoryId: number;
	kbCategoryIds: any[];
	levelId: number;
	likes: number;
	media: any[];
	primaryTag: string;
	relatedLinks: any[];
	relatedTags: any[];
	relatedTopics: any[];
	supportRequest: any[];
	title: string;
	userId: string;
}
