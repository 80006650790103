import { IKnowledgeBase } from '../kb-topic.interface';
import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  GLOBAL_KB_TOPICS_UPDATE = '[GlobalKbTopics] Update',
  GLOBAL_KB_TOPICS_UPDATE_SUCCESS = '[GlobalKbTopics] Update Success',

}

export const globalKbTopicsUpdateAction =
createAction(
  ActionTypes.GLOBAL_KB_TOPICS_UPDATE,
  props<Partial<IKnowledgeBase>>()
)

export const globalKbTopicsUpdateSuccessAction =
createAction(
  ActionTypes.GLOBAL_KB_TOPICS_UPDATE_SUCCESS
)
