<div class="security-score-container">
  <div>
    <ng-container *ngIf="systemRoleId < 3; else pieChart">
      <ng-container *ngTemplateOutlet="usersList"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #pieChart>
  <ng-container *ngIf="msTeams && msTeams.length > 0; else noRecord">
    <dx-pie-chart
      id="pie"
      [title]="title"
      palette="bright"
      [dataSource]="msTeams"
    >
      <dxi-series argumentField="activity" valueField="count">
        <dxo-label [visible]="true">
          <dxo-connector [visible]="true" [width]="1"></dxo-connector>
        </dxo-label>
      </dxi-series>
      <dxo-legend
          [margin]="10"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        ></dxo-legend>
    </dx-pie-chart>
  </ng-container>
</ng-template>

<ng-template #usersList>
  <div>
    <responsive-table
      [table$]="dataTable$"
    ></responsive-table>
  </div>
</ng-template>

<ng-template #noRecord>
  <div class="no-record">
    <span class="font-weight-normal text-xs no-records">No records found.</span> 
  </div>
</ng-template>