<div class="card-2 q-p-0">
	<div class="card-header-2">
		<h3 class="q-mr-auto">Payment Method</h3>

		<div class="payment-method_action-header">
			<ng-container
				*ngIf="
					isManagePaymentMethodEnabled &&
					!collapseEl.classList.contains('collapsed')
				"
			>
				<a
					href="javascript:void(0)"
					class="btn-2 btn-2-primary"
					routerLink="./add-card-details"
				>
					<app-figma-icon-img
						[iconName]="'plus'"
						[hasSpaceOnRight]="false"
						[isForButton]="true"
					>
					</app-figma-icon-img>

					Add Card
				</a>

				<a
					href="javascript:void(0)"
					class="btn-2 btn-2-primary"
					routerLink="./add-us-bank-account"
				>
					<app-figma-icon-img
						[iconName]="'plus'"
						[hasSpaceOnRight]="false"
						[isForButton]="true"
					>
					</app-figma-icon-img>

					Add Bank Account
				</a>
			</ng-container>

			<a
				href="#method"
				class="accordion-btn-pos btn-2 btn-2-link collapsed"
				data-bs-toggle="collapse"
				aria-controls="method"
				role="button"
				aria-expanded="false"
				#collapseEl
			>
				{{ collapseEl.classList.contains('collapsed') ? 'Expand' : 'Collapse' }}
				<mat-icon
					[svgIcon]="collapseEl.classList.contains('collapsed') ? 'chevron-down' : 'chevron-up'"
				></mat-icon>
			</a>
		</div>
	</div>

		<!-- Card / Bank Method Section -->
		<section id="method" class="q-w-100 collapse">
			<div class="row-badge">
				<ng-container *ngIf="paymentOption.card.length > 0">
					<div class="col-badge" *ngFor="let payment of paymentOption.card">
						<div class="payment-method-badge">
							<div class="badge-absolute">
								<div *ngIf="payment.name" class="badge-name font-b3">
									{{ payment.name }}
								</div>
								&nbsp;&nbsp;&nbsp;
								<div
									*ngIf="payment.isDefaultPayment"
									class="badge-default font-b3"
								>
									Default
								</div>
							</div>

							<div class="badge-label-flex">
								<div class="badge-logo">
									<app-card-logos [branName]="payment.name"></app-card-logos>
								</div>

								<div class="badge-last4 font-b1">
									****&nbsp;&nbsp;&nbsp;{{ getLast4(payment) }}
								</div>

								<div class="badge-action">
									<ng-container *ngIf="isManagePaymentMethodEnabled">
										<div
											class="badge-three-dots-btn"
											[matMenuTriggerFor]="cardAction"
										>
											<div class="three-dots-svg">
												<app-figma-icon-img
													[iconName]="'dots-vertical'"
													[isForButton]="true"
													[hasSpaceOnRight]="false"
												>
												</app-figma-icon-img>
											</div>
										</div>
									</ng-container>
								</div>

								<mat-menu #cardAction="matMenu">
									<ng-container *ngIf="isManagePaymentMethodEnabled">
										<button
											mat-menu-item
											(click)="clickDefaultPaymentMethod(payment.id)"
										>
											<span>Set As Default</span>
										</button>

										<button
											mat-menu-item
											routerLink="./edit-card-details/{{ btoaVal(payment.id) }}"
										>
											<span>Edit Card</span>
										</button>

										<button mat-menu-item (click)="deleteCard(payment)">
											<span>Delete Card</span>
										</button>
									</ng-container>
								</mat-menu>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="paymentOption.bankAccount.length > 0">
					<div
						class="col-badge"
						*ngFor="let payment of paymentOption.bankAccount"
					>
						<div class="payment-method-badge">
							<div class="badge-absolute">
								<div *ngIf="payment.name" class="badge-name font-b3">
									{{ payment.name }}
								</div>
								&nbsp;&nbsp;&nbsp;
								<div
									*ngIf="payment.isDefaultPayment"
									class="badge-default font-b3"
								>
									Default
								</div>
							</div>

							<div class="badge-label-flex">
								<div class="badge-logo">
									<span class="bank-logo">
										<app-figma-icon-img
											[iconName]="'bank'"
											[isForButton]="true"
											[hasSpaceOnRight]="false"
										>
										</app-figma-icon-img>
									</span>
								</div>

								<div class="badge-last4 font-b1">
									****&nbsp;&nbsp;&nbsp;{{ getLast4(payment) }}
								</div>

								<div class="badge-action">
									<div class="badge-status-con">
										<button
											type="button"
											class="btn-2 btn-2-destructive"
											*ngIf="payment.status === 'new'"
											(click)="redirectedToVerifyBankPage(payment.id)"
										>
											<app-local-figma-icon-img
												[filename]="'alert-triangle-red'"
												[width]="20"
												[height]="20"
												[hasSpaceOnRight]="false"
											>
											</app-local-figma-icon-img>
											Verify
										</button>

										<app-badge
											*ngIf="payment.status === 'verified'"
											[label]="'Verified'"
											[type]="'#d7f7c2'"
											[fontColor]="'#006908'"
										>
										</app-badge>

										<app-badge
											*ngIf="payment.status === 'verification_failed'"
											[label]="'Verify Failed'"
											[type]="'#f8d1cd'"
											[fontColor]="'#6d1008'"
										>
										</app-badge>
									</div>

									<ng-container *ngIf="isManagePaymentMethodEnabled">
										<div
											class="badge-three-dots-btn"
											[matMenuTriggerFor]="bankAction"
										>
											<div class="three-dots-svg">
												<app-figma-icon-img
													[iconName]="'dots-vertical'"
													[isForButton]="true"
													[hasSpaceOnRight]="false"
												>
												</app-figma-icon-img>
											</div>
										</div>
									</ng-container>
								</div>

								<mat-menu #bankAction="matMenu">
									<ng-container *ngIf="isManagePaymentMethodEnabled">
										<button
											*ngIf="payment.status === 'verified'"
											mat-menu-item
											(click)="clickDefaultPaymentMethod(payment.id)"
										>
											<span>Set As Default</span>
										</button>

										<button
											mat-menu-item
											routerLink="./edit-bank-details/{{ btoaVal(payment.id) }}"
										>
											<span>Edit Bank</span>
										</button>

										<button mat-menu-item (click)="deleteBank(payment)">
											<span>Delete Bank</span>
										</button>
									</ng-container>
								</mat-menu>
							</div>
						</div>
					</div>
				</ng-container>
			</div>

			<div class="payment-method_group q-d-flex q-jc-center q-ai-center q-g-24 q-as-stretch">
				<!-- Manage Autopay Section -->
				<section id="manage-autopay" class="manage-autopay-container">
					<div class="manage-autopay-pos-left font-b2">
						Make Paying Simple. Never Miss A Payment.
					</div>

					<div class="action-form">
						<div
							*ngIf="hasMethodEnrolledForAutopay"
							class="manage-autopay-status-on font-b3"
						>
							ON
						</div>

						<app-tertiary-button
							[btnText]="'Manage Autopay'"
							(click)="onClickManageAutopay()"
							[iconName]="'refresh-cw-05'"
						>
						</app-tertiary-button>
					</div>
				</section>

				<app-billing-contact
					*ngIf="billingContact.length"
					[billingContact]="billingContact"
				>
				</app-billing-contact>
			</div>
		</section>
</div>

<!-- Manage Autopay Modal -->
<app-manage-autopay-modal
	(onManageAutopayStatus)="onManageAutopayStatus($event)"
>
</app-manage-autopay-modal>
