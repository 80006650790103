<div class="card-body">
  <div class="elevate-container">
    <div class="flex-container">
      <div class="flex-item">
        <h5>{{ moduleTitle }}</h5>
      </div>
      <div class="flex-item app-name">
        <h5 class="font-weight-bold">{{ appName }}</h5>
      </div>
    </div>
  </div>

  <ng-container *ngIf="_userService.isSpAdmin">
    <div class="elevate-container">
      <div class="flex-container">
        <div class="flex-item">
          <label><strong>Companies with Access</strong></label>
        </div>
        <div class="flex-item flex-width-long">
          <multi-select-input-2
            errorTooltip
            etMessage="Company is required"
            [etShow]="hasError && hasSubmitted"
            [(ngModel)]="selectedCompanies"
            (ngModelChange)="emitCompanyAcccess($event)"
            [options]="companies"
            [optionKeys]="{ value: 'id', label: 'name' }"
            [autoClosePerSelect]="true"
            class="q-w-100"
            placeholder="Type to search for companies."
          >
          </multi-select-input-2>
        </div>
        <div class="flex-item">
          <button
            type="button"
            class="btn-2 btn-2-primary ml-40"
            (click)="saveCompaniesByPermissionType()"
            #saveButton
            [disabled]="isSaveCompanyDisabled"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="permissionCompanyId">
    <div class="elevate-container">
      <app-permissions-main
        [hasUserLevelPermission]="selectAll"
        [isEnabledInputCompany]="false"
        [selectedCompany]="permissionCompanyId!"
        [permissionData]="permissionData"
        [showButtons]="true"
        [isSaveUsersDisabled]="isSaveUsersDisabled"
        (uIds)="receiveuIds($event)"
        (sendSave)="receiveSave($event)"
        (sendCancel)="receiveCancel($event)"
      >
      </app-permissions-main>
    </div>
  </ng-container>

  <!-- sendCompanyId for edit -->
  <!-- sendDeleteId for delete -->
  <!-- sendProps for reload Data -->
  <!-- tableData for sending data to table -->
  <ng-container *ngIf="_userService.isSpAdmin">
    <company-permissions-table
      (sendCompanyId)="receiveCompanyId($event)"
      (sendhasUserLevelPermission)="receiveHasUserLevelPermission($event)"
      (sendDeleteId)="receiveDeleteCompanyId($event)"
      (sendProps)="receiveProps($event)"
      [tableData]="tableData"
      [isActionButtonsDisabled]="isActionButtonsDisabled"
    ></company-permissions-table>
  </ng-container>
</div>
