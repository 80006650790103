import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { AccountService } from '@app/modules/account/account.service';
import { YesNoModalService } from '@app/shared/components/modal/yes-no-modal/yes-no-modal.service';
import { BaseUserComponent } from '@app/shared/components/user/base-user.component';
import { NotificationMessages } from '@app/shared/constants';
import { catchError, take, tap, throwError } from 'rxjs';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent extends BaseUserComponent implements OnInit {
  TODO: "DELETE COMPONENT"
  constructor(
      public override _userService: UserService,
      private _yesNoModalService: YesNoModalService,
      private _router: Router,
      private _route: ActivatedRoute,
      private _accountService: AccountService,
      private _notifier: NotificationService,
      private _spinner: SpinnerService
     ) {
    super(_userService);
   }

  ngOnInit(): void {
    this.refreshUser();
  }

  check2FA(){
    if(this.is2faEnabled){
      this.openModal()
    }else{
      this._router.navigate(['./2fa'],{relativeTo: this._route})
    }
  }

  openModal(){
    const confirmDialog =   this._yesNoModalService.confirmDialog({iconName: '',
      iconColor: 'red',
      header: 'Warning',
      message: 'Are you sure you want to disable 2FA for your login?',
      cancel: 'No',
      accept: 'Yes'});
    confirmDialog.afterClosed().subscribe((result) => {
      if (result?.event === 'yes') {
        this._accountService.post2FADisable().pipe(
          take(1),
        catchError((error)=>{
          this._notifier.notify(NotificationMessages.SomethingWentWrong,{duration: 5, panelClass: 'error'})
          return throwError(()=>error)
        }),
        tap(()=>{
          this._notifier.notify(NotificationMessages.TwoFactorAuthSuccessDisabled,{duration: 5, panelClass: 'success'});
          this.is2faEnabled = false;
        }))
        .subscribe()
      } else if (result.event === 'no') {
      }
    })
  }


}
