import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TypingNotAllowedDirective } from './typing-not-allowed.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [TypingNotAllowedDirective],
	exports: [TypingNotAllowedDirective],
})
export class TypingNotAllowedModule {}
