<modal-v2 [hasBorder]="false">
	<ng-template #modalV2Header>
		<div class="optimize-quest-modal_header q-d-flex q-f-column q-ai-center q-g-8">
      <div class="optimize-quest-modal_title">Optimize Quest</div>
      <div class="optimize-quest-modal_description font-b1">
        To get the best possible experience, use our mobile app.
      </div>
    </div>
	</ng-template>

	<ng-template #modalV2Body>
		<div class="optimize-quest-modal_body q-d-flex q-f-column q-ai-start q-g-16">
      <div class="optimize-quest-modal_group q-d-flex q-ai-start q-g-8">
        <app-figma-icon-img
					[iconName]="'activity'"
					[hasSpaceOnRight]="false"
					[isForButton]="false">
        </app-figma-icon-img>

        <div class="optimize-quest-modal_text font-b1">Faster Performance</div>
      </div>

      <div class="optimize-quest-modal_group q-d-flex q-ai-start q-g-8">
        <app-figma-icon-img
					[iconName]="'stars-02'"
					[hasSpaceOnRight]="false"
					[isForButton]="false">
        </app-figma-icon-img>

        <div class="optimize-quest-modal_text font-b1">Leverage Your Phone’s Features</div>
      </div>

      <div class="optimize-quest-modal_group q-d-flex q-ai-start q-g-8">
        <app-figma-icon-img
					[iconName]="'arrows-right'"
					[hasSpaceOnRight]="false"
					[isForButton]="false">
        </app-figma-icon-img>

        <div class="optimize-quest-modal_text font-b1">Designed for “On-The-Go” Use</div>
      </div>
    </div>
	</ng-template>

	<ng-template #modalV2Footer>
    <div class="optimize-quest-modal_footer q-w-100 q-d-grid q-g-16">
      <button 
        type="submit" 
        class="btn-2 btn-2-primary q-w-100" 
        (click)="openApp()">
        Use the App
      </button>

      <button 
        type="submit" 
        class="btn-2 btn-2-tertiary q-w-100" 
        (click)="close()">
        Continue in Browser
      </button>
    </div>
	</ng-template>
</modal-v2>
