<div class="container-fluid py-4">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="add-bank-account-main">
					<div class="card-header pb-0">
						<div class="d-lg-flex">
							<div>
								<h5>Edit Credit/Debit Card</h5>
							</div>

              <app-secondary-button
                [isBackButton]="true"
                [link]="
									isAdmin
										? '/billing-orders/invoices'
										: '/billing-and-orders/invoices'
								"
              ></app-secondary-button>
						</div>
					</div>

					<div class="card-body px-0 pb-0 pt-0">
						<div class="add-bank-account-area" *ngIf="cardDetails">
							<form [formGroup]="cardForm" class="add-bank-account-form">
								<div class="form-group">
									<div class="row">
										<div class="col-lg-5 col-md-6 me-auto">
											<h6>Card Information</h6>
											<div class="row">
												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label>Card Name</label>

														<div class="d-flex align-items-center">
															<ng-container *ngIf="cardDetails.brand">
																<div class="d-contents">
																	<div class="w-10 me-3 mb-0">
																		<app-card-logos
																			[branName]="cardDetails.brand">
																		</app-card-logos>
																	</div>
																</div>
																<div>
																	<div class="card-no font-b1" style="margin-bottom: 0 !important">
																		****&nbsp;&nbsp;&nbsp;{{ cardDetails.last4 }}
																	</div>
																</div>
															</ng-container>
														</div>
													</div>
												</div>

												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label class="required">Cardholder's Name</label>

														<input
															type="text"
															id="cardholder-name"
															class="form-control"
															formControlName="name"
															placeholder="John Hancock"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"
															errorTooltip
															[etControl]="cardForm.get('name')!"
															[etIsTouched]="cardForm.get('name')!.touched"
															[etShow]="
																cardForm.get('name')!.touched &&
																!!cardForm.get('name')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.CardHolderNameRequired
																}
															]"/>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label for="expiry-month" class="required">Expiry Month</label>

														<select
															id="expiry-month"
															class="form-control"
															formControlName="expMonth"
															errorTooltip
															[etControl]="cardForm.get('expMonth')!"
															[etIsTouched]="cardForm.get('expMonth')!.touched"
															[etShow]="
																cardForm.get('expMonth')!.touched &&
																!!cardForm.get('expMonth')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.InvalidExpiryMonthYear
																}
															]">

															<option disabled selected value>
																- Select Month -
															</option>
															<ng-container *ngFor="let month of monthList">
																<option [value]="month">{{ month }}</option>
															</ng-container>
														</select>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label for="expiry-year" class="required">Expiry Year</label>

														<select
															id="expiry-year"
															class="form-control"
															formControlName="expYear"
															errorTooltip
															[etControl]="cardForm.get('expYear')!"
															[etIsTouched]="cardForm.get('expYear')!.touched"
															[etShow]="
																cardForm.get('expYear')!.touched &&
																!!cardForm.get('expYear')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.InvalidExpiryMonthYear
																}
															]">

															<option disabled selected value>
																- Select Year -
															</option>
															<ng-container *ngFor="let year of yearList">
																<option [value]="year">{{ year }}</option>
															</ng-container>
														</select>
													</div>
												</div>
											</div>
										</div>

										<div class="col-lg-5 col-md-6 me-auto">
											<h6>Billing Address</h6>

											<div class="row">
												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label for="street-1" class="required">Address Line 1</label>

														<input
															type="text"
															id="street-1"
															class="form-control"
															formControlName="addressLine1"
															placeholder="1776 Liberty Lane"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"
															errorTooltip
															[etControl]="cardForm.get('addressLine1')!"
															[etIsTouched]="cardForm.get('addressLine1')!.touched"
															[etShow]="
																cardForm.get('addressLine1')!.touched &&
																!!cardForm.get('addressLine1')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.IncorrectCardAddress
																}
															]"/>
													</div>
												</div>
												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label for="street-2">
															Address Line 2
															<span>(Optional)</span>
														</label>

														<input
															type="text"
															id="street-2"
															class="form-control"
															formControlName="addressLine2"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															placeholder="Suite 12"
															autocomplete="off"/>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label for="city" class="required">City</label>

														<input
															type="text"
															id="city"
															class="form-control"
															formControlName="addressCity"
															placeholder="Boston"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"
															errorTooltip
															[etControl]="cardForm.get('addressCity')!"
															[etIsTouched]="cardForm.get('addressCity')!.touched"
															[etShow]="
																cardForm.get('addressCity')!.touched &&
																!!cardForm.get('addressCity')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.IncorrectCardCity
																}
															]"/>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label for="state" class="required">State</label>
														<select
															id="state"
															class="form-control"
															formControlName="addressState"
															autocomplete="off"
															errorTooltip
															[etControl]="cardForm.get('addressState')!"
															[etIsTouched]="cardForm.get('addressState')!.touched"
															[etShow]="
																cardForm.get('addressState')!.touched &&
																!!cardForm.get('addressState')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.IncorrectCardState
																}
															]">

															<option value="" selected>
																- Select State -
															</option>
															<ng-container *ngFor="let state of stateList">
																<option [value]="state.abbreviation">
																	{{ state.name }} ({{ state.abbreviation }})
																</option>
															</ng-container>
														</select>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label for="zipcode" class="required">Zipcode</label>

														<input
															type="zipcode"
															id="state"
															class="form-control"
															formControlName="addressZip"
															(keypress)="returnAsNumber($event)"
															errorTooltip
															[etControl]="cardForm.get('addressZip')!"
															[etIsTouched]="cardForm.get('addressZip')!.touched"
															[etShow]="
																cardForm.get('addressZip')!.touched &&
																!!cardForm.get('addressZip')!.errors
															"
															[etMessage]="[
																{
																	error: 'required',
																	message: cardValidationMessages.InvalidPostalCode
																},
																{
																	error: 'minlength',
																	message: cardValidationMessages.InvalidPostalCode
																}
															]"
														/>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label for="country" class="required">
															Country
														</label>

														<input
															type="text"
															id="country"
															class="form-control"
															formControlName="addressCountry"
															autocomplete="off"
															readonly/>
													</div>
												</div>
											</div>
										</div>

										<div class="col-lg-10 col-md-12 mx-auto">
											<ul class="list-unstyled d-flex d-inline-flex mt-5 w-100 justify-content-end">
												<li>
													<a
														href="javascript:void(0);"
														class="submit-btn btn-2 btn-2-primary"
														(click)="save()">

														<span class="d-flex">
															<app-figma-icon-img
																[iconName]="'check-circle'"
																[isForButton]="true"
																[hasSpaceOnRight]="false">
															</app-figma-icon-img>
														</span>
														Update Card
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
