<!-- [Begin]:: QUEST V2 design -->
<div
	*ngIf="recordPayment && recordPayment.length"
	id="payment-confirm-container"
>
	<div class="card">
		<div class="card-header w-100 pb-0">
			<div class="d-lg-flex">
				<h6 class="mb-0">Payment Confirmation</h6>

				<div class="ms-auto my-auto mt-lg-0 mt-4">
					<div *ngIf="!isAllTransactionSuccess" class="ms-auto my-auto">
						<a
							href="javascript:void(0)"
							class="btn-2 btn-2-primary"
							[routerLink]="
								isAdmin
									? '/billing-orders/invoices'
									: '/billing-and-orders/invoices'
							"
						>
							Return to Invoices
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="card-body px-0 pb-0 pt-0">
			<div class="card-gap">
				<div class="payment-message-container" *ngIf="isAllTransactionSuccess">
					<div class="payment-message-sub">
						<div class="payment-message-sub2">
							<div class="payment-message-sub3">
								<div class="nice-job-msg">You’re all set!</div>
								<div class="invoice-payment-msg">
									All invoice payments have been successfully paid
								</div>
							</div>

							<ng-container *ngIf="hasPaymentMethod">
								<div class="payment-message-note">
									<b>Please Note:</b>
									{{ isCard ? paymentNote.cardNote : paymentNote.bankNote }}
								</div>
							</ng-container>
						</div>

						<div class="d-flex justify-content-center mt-3">
              <app-tertiary-button
                [btnText]="'Download All Receipts'"
                (click)="downloadReceipt()"
                [iconName]="'download-02'"
                [isProcessing]="isProcessing"
              >
              </app-tertiary-button>

							&nbsp;&nbsp;

							<a
								href="javascript:void(0)"
								class="btn-2 btn-2-primary"
								[routerLink]="
									isAdmin
										? '/billing-orders/invoices'
										: '/billing-and-orders/invoices'
								"
							>
								Return to Invoices
							</a>
						</div>
					</div>
				</div>

				<div
					*ngIf="!isAllTransactionSuccess && !isAllTransactionFailed"
					class="payment-note"
				>
					<span>
						<b>Please Note:</b>
						{{ isCard ? paymentNote.cardNote : paymentNote.bankNote }}
					</span>
				</div>

				<div
					*ngIf="recordPayment && recordPayment.length"
					class="payment-security-gray-box"
				>
					<ng-container *ngFor="let inv of recordPayment; let i = index">
						<div class="payment-verification-cards">
							<div
								*ngIf="inv.isSucceeded || inv.isProcessing"
								class="success-info-container"
							>
								<div class="success-icon">
									<app-local-figma-icon-img
										[filename]="'check-circle-green'"
										[width]="48"
										[height]="48"
									></app-local-figma-icon-img>
								</div>

								<div class="success-info-details">
									<div class="success-head">Payment Successful</div>
									<div class="success-sub-head">
										Transaction Date: {{ inv.processDate | date }}
									</div>
								</div>
							</div>

							<div
								*ngIf="!inv.isSucceeded && !inv.isProcessing"
								class="failed-info-container"
							>
								<div class="failed-icon">
									<app-local-figma-icon-img
										[filename]="'alert-triangle-red'"
										[width]="48"
										[height]="48"
									></app-local-figma-icon-img>
								</div>

								<div class="failed-info-details">
									<div class="failed-head">Payment Transaction Failed</div>
									<div class="failed-sub-head">{{ inv.message }}</div>
									<div class="failed-sub-head">
										Transaction Date: {{ inv.processDate | date }}
									</div>
								</div>
							</div>

							<div class="invoice-info">
								<div class="invoice-info-left">
									<div class="invoice-no">
										Invoice # {{ inv.invoiceNumber }}
									</div>
									<!-- <div class="order-id">Order ID: 2351</div> -->
									<div class="invoice-date">{{ inv.invoiceDate | date }}</div>
								</div>

								<div class="invoice-info-right">
									<!-- Status requires action -->
									<ng-container
										*ngIf="inv.isRequiresAction && !inv.isSucceeded"
									>
										<app-badge
											[label]="'REQUIRES ACTION'"
											[type]="'#ebeef1'"
											[fontColor]="'#545969'"
										>
										</app-badge>
									</ng-container>

									<!-- Status processing -->
									<ng-container *ngIf="inv.isProcessing || inv.isSucceeded">
										<app-badge
											[label]="'PROCESSING'"
											[type]="'#faf2ce'"
											[fontColor]="'#705c03'"
										>
										</app-badge>
									</ng-container>

									<!-- Status Failed -->
									<ng-container
										*ngIf="
											!inv.isRequiresAction &&
											!inv.isProcessing &&
											!inv.isSucceeded
										"
									>
										<app-badge
											[label]="'PAYMENT TRANSACTION FAILED'"
											[type]="'#f8d1cd'"
											[fontColor]="'#6d1008'"
										>
										</app-badge>
									</ng-container>
								</div>
							</div>

							<ng-container *ngIf="!inv.isSucceeded && !inv.isProcessing">
								<div class="invoice-label-parent-container">
									<div class="invoice-label-container">
										<div class="invoice-label">Payment Amount</div>
										<div class="invoice-amount">
											{{ inv.amount | currency }}
										</div>
									</div>

									<div class="invoice-label-container">
										<div class="invoice-label">Applying Credits</div>
										<div class="invoice-amount">
											{{ inv.creditMemoTotalAmount | currency }}
										</div>
									</div>

									<div class="invoice-label-container">
										<div class="invoice-label">Applying Existing Payments</div>
										<div class="invoice-amount">
											{{ inv.unappliedPaymentTotalAmount | currency }}
										</div>
									</div>

									<div
										*ngIf="inv.transactionNumber"
										class="invoice-info-footer"
									>
										<div class="transaction-label">
											Transaction ID: {{ inv.transactionNumber }}
										</div>
									</div>
								</div>
							</ng-container>

							<ng-container *ngIf="inv.isSucceeded || inv.isProcessing">
								<div class="invoice-label-parent-container">
									<div class="invoice-label-container">
										<div class="invoice-label">Credits Applied</div>
										<div class="invoice-amount">
											{{
												inv.creditMemoTotalAmount +
													inv.unappliedPaymentTotalAmount | currency
											}}
										</div>
									</div>

									<!-- [Begin]:: Payment type card | bank -->
									<ng-container *ngIf="hasPaymentMethod">
										<div class="invoice-label-container">
											<div class="invoice-label card-logo-container">
												<div class="card-logo">
													<ng-container *ngIf="inv.cardBrand">
														<app-card-logos [branName]="inv.cardBrand">
														</app-card-logos>
													</ng-container>

													<ng-container *ngIf="inv.bankName">
														<app-figma-icon-img
															[iconName]="'bank'"
															[hasSpaceOnRight]="false"
														></app-figma-icon-img>
													</ng-container>
												</div>

												<div class="card-logo-label">
													<span *ngIf="inv.bankName"
														>{{ inv.bankName }}&nbsp;&nbsp;</span
													>
													<span>****{{ inv.last4 }}</span>
												</div>
											</div>
											<div class="invoice-amount">
												{{ inv.amount | currency }}
											</div>
										</div>
									</ng-container>
									<!-- [End]:: Payment type card | bank -->

									<div class="divider-style"></div>

									<div class="invoice-label-container">
										<div class="invoice-label">Total</div>
										<div class="invoice-amount">
											{{ totalAmount(inv) | currency }}
										</div>
									</div>
								</div>

								<div class="invoice-info-footer">
									<div class="transaction-label">
										<ng-container *ngIf="inv.transactionNumber">
											Transaction ID: {{ inv.transactionNumber }}
										</ng-container>
									</div>

									<app-tertiary-button
										[btnText]="'Download Receipt'"
										(click)="downloadReceipt(inv.transactionNumber)"
										[iconName]="'download-02'"
										[isProcessing]="isProcessing"
									>
									</app-tertiary-button>
								</div>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- [End]:: QUEST V2 design -->
