<ng-container *ngIf="getIsInvoices(invoices)">
  <div class="invoice-id">
    <h1>{{ invoiceId }}</h1>
  </div>
  <div class="card-body px-0 pb-0 pt-0">
    <div class="table-responsive directory-table">
      <div
        class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
      >
        <div class="dataTable-container">
          <app-column-slider
            [(displayedColumns)]="displayedColumns"
          ></app-column-slider>
          <table
            class="table table-flush dataTable-table"
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="id"
            matSortDirection="asc"
            matSortDisableClear="true"
            >
            <!-- (matSortChange)="sortData($event)" -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let row" class="text-xs">{{ row.id }}</td>
            </ng-container>
  
            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Product Id
              </th>
              <td class="visiblity-icon text-xs" mat-cell *matCellDef="let row">
                {{ row.identifier }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Description
              </th>
              <td mat-cell *matCellDef="let row" class="text-xs">
                {{ row.description }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
              <td mat-cell *matCellDef="let row" class="text-xs">
                {{ row.quantity }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="unitPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Unit Price
              </th>
              <td mat-cell *matCellDef="let row" class="text-xs">
                {{ row.unitPrice | currency }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
              <td mat-cell *matCellDef="let row" class="text-xs">
                {{ row.total | currency }}
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6"  style="text-align: center !important;">
                {{ message.noRecord }}
              </td>
            </tr>
          </table>
        </div>
      </div>
<!--         <table-paginator
        [totalItems]="totalItems"
        [pageSizes]="pageSizes"
        (pageChange)="getInvoiceProductList($event)"
      ></table-paginator> -->
    </div>
  </div>
</ng-container>
