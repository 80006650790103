import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { filter, Observable, shareReplay } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';

import { DrawerContainerComponent } from '@app/shared/components/drawer-container/drawer-container.component';

import { UserNotificationService } from '@app/shared/services/user-notification.service';
import { UserService } from '@app/core/services/user.service';
import { TopBarService } from '../top-bar.service';
import { ClientMessageService } from '@app/modules/client-message/client-message.service';

import { UserMessageCategoryEnum } from '@app/shared/constants';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { UserMessageCategory, UserMessagePendingNotificationDetail } from '@app/shared/interfaces/usermessage.interface';

import { messageSelector } from '../store/top-bar.selector';

@Component({
	selector: 'sp-messages-menu',
	templateUrl: './messages-menu.component.html',
	styleUrls: ['./messages-menu.component.scss'],
})
export class MessagesMenuComponent implements OnInit {
	@ViewChild(MatProgressSpinner) _spinner: MatProgressSpinner;
	@Input() drawerContainer: DrawerContainerComponent;

	messages: UserMessagePendingNotificationDetail[];
	loading = true;
	impersonating: boolean;
	messageCategories?: UserMessageCategory[];

	messageOptions$: Observable<{ isOpen: boolean }>;

	constructor(
		private _store: Store<AppStateInterface>,
		private _topBarService: TopBarService,
		private _usernotification: UserNotificationService,
		private _messageService: ClientMessageService,
		private _router: Router,
		private _userService: UserService
	) {}

	ngOnInit(): void {
		this.messageOptions$ = this._store.pipe(
			select(messageSelector),
			shareReplay()
		);

		let messageOptionsSub = this.messageOptions$
			.pipe(filter((data) => data.isOpen === true))
			.subscribe(() => {
				this.impersonating = this._userService.user?.impersonatingBy != null;

				if (!this.impersonating) {
					this.loading = true;

					if(!this.messageCategories) {
						this._messageService.getCategories().subscribe(res => {
							if(res) this.messageCategories = res;
						})
					}

					this._usernotification.getMessageNotifications().subscribe({
						next: (s: any) => {
							this.loading = false;
							s.forEach((s: any) => this.elapsedTime(s));
							this.messages = s;
						},
						error: () => {
							this.loading = false;
						},
					});
				}
			});
	}

	onMessageClick() {
		this._topBarService.onMessageClick();

		setTimeout(() => {
			this.messages = [];
		}, 1000);
	}

	close(message: UserMessagePendingNotificationDetail, index: number) {
		this.clearNotification(message.id);
		this.messages.splice(index, 1);
	}

	messageListTrackBy(index: number, item: any) {
		return item.id;
	}

	open(message: UserMessagePendingNotificationDetail, index: number) {
		switch (message.categoryId) {
			case UserMessageCategoryEnum.Ticket:
				this._router
					.navigateByUrl('/message', { skipLocationChange: true })
					.then(() =>
						this._router.navigate(['/message/tickets/' + message.id], {
							queryParams: { categoryId: message.categoryId },
						})
					);
				break;

			case UserMessageCategoryEnum.Banner:
				this._router
					.navigateByUrl('/message', { skipLocationChange: true })
					.then(() =>
						this._router.navigate(['/message/banner/' + message.id], {
							queryParams: { categoryId: message.categoryId },
						})
					);
				break;

			case UserMessageCategoryEnum.BroadCast:
				this._router
					.navigateByUrl('/message', { skipLocationChange: true })
					.then(() =>
						this._router.navigate(['/message/broadcast/' + message.id], {
							queryParams: { categoryId: message.categoryId },
						})
					);
				break;

			case UserMessageCategoryEnum.Order:
				this._router
					.navigateByUrl('/billing-and-orders', { skipLocationChange: true })
					.then(() =>
						this._router.navigate([
							'/billing-and-orders/orders/' + message.refId,
						])
					);
				break;
		}

		this.clearNotification(message.id);
		this.messages.splice(index, 1);
		this.closeHostDrawer();
	}

	closeHostDrawer() {
		if (this.drawerContainer) this.drawerContainer.close();
		this._topBarService.onMessageClick();
	}

	elapsedTime(message: UserMessagePendingNotificationDetail) {
		message._elapsedtime = moment(message.createdDate).fromNow();
	}

	clearNotification(id: number) {
		this._usernotification.clearNotificationById(id);
	}

	clearAllNotifications() {
		if(this.impersonating) return;
		this.messages = [];
		this._usernotification.clearAllMessageNotifications();
	}
}
