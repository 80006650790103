import { NgModule } from '@angular/core';
import { ClickOutSideConfiguratorDirective } from '@app/shared/directives/click-out-side-configurator.directive';
import { ConfiguratorDirective, DarkModeDirective, SideBarColorDirective, ThemeModeDirective } from './configurator.directive';



@NgModule({
  imports:[],
  declarations:[
    ClickOutSideConfiguratorDirective,
    DarkModeDirective,
    ConfiguratorDirective,
    SideBarColorDirective,
    ThemeModeDirective,
  ],
  exports:[
    ClickOutSideConfiguratorDirective,
    DarkModeDirective,
    ConfiguratorDirective,
    SideBarColorDirective,
    ThemeModeDirective,
  ]
})
export class ConfiguratorModule{

}
