
<div class="card-2 q-p-0 q-pt-24 q-br-0">
	<!-- [Start]::Invoice Header -->
		<div class="card-header-2">
			<h3 class="q-mr-auto">Invoices</h3>

				<search-input
					[formControl]="queryString"
					(keyup)="queryChange(500)"
					(keyup.enter)="queryChange()"
					placeholder="Search Invoices"
					size="small"
				></search-input>

				<app-tertiary-button
					[isFilterOn]="isFilterOn"
					[isFilterToggle]="true"
					(click)="detectChanges()"
				></app-tertiary-button>

				<ng-container *ngIf="isStripeEnabled && isPaymentInvoiceEnabled">
					<span [tooltipContentV2]="{ message: 'Select an invoice below to enable this button' }">
						<a
							*ngIf="isPaymentEnabled"
							href="javascript:void(0)"
							class="btn-2 btn-2-success"
							[class.disabled]="!proceedToPayEnabled"
							(click)="payInvoice()"
						>
							<app-figma-icon-img
								[iconName]="'currency-dollar'"
								[isForButton]="true"
								[hasSpaceOnRight]="false"
							>
							</app-figma-icon-img>
							Proceed to Pay
						</a>
					</span>
				</ng-container>

				<a
					href="javascript:void(0)"
					class="q-pointer q-d-flex q-ai-center"
					[matMenuTriggerFor]="actionButtonsRef"
				>
					<app-figma-icon-img
						[iconName]="'dots-vertical'"
						[width]="24"
						[height]="24"
						[isForButton]="true"
						[hasSpaceOnRight]="false"
					>
					</app-figma-icon-img>
				</a>

				<mat-menu #actionButtonsRef="matMenu" class="v2-menu-action-button">
					<button
						mat-menu-item
						href="#generate-statement"
						data-bs-toggle="collapse"
						aria-controls="generate-statement"
						aria-expanded="false"
					>
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="file-05" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Generate Statement</div>
						</div>
					</button>

					<button
						mat-menu-item
						(click)="export(applicationType.CSV)"
						[disabled]="!isDownloadEnabled">
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Export As CSV</div>
						</div>
					</button>

					<button
						mat-menu-item
						(click)="export(applicationType.EXCEL)"
						[disabled]="!isDownloadEnabled">
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Export As XLSX</div>
						</div>
					</button>

					<button mat-menu-item disabled>
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="download-02" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Download Invoice</div>
						</div>
					</button>

					<button 
						mat-menu-item 
						[disabled]="!selectedIds.length"
						(click)="downloadInvoiceReceipt()"> 

						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="receipt-check" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Download Receipts</div>
						</div>
					</button>

					<button mat-menu-item disabled>
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="repay" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">View Arrangement</div>
						</div>
					</button>

					<button mat-menu-item disabled>
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="message-question-circle" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Submit Inquiry</div>
						</div>
					</button>
				</mat-menu>
		</div>
	<!-- [End]::Invoice Header -->

	<!-- [Start]::Generate Statement Section -->
	<section id="generate-statement" class="collapse q-w-100">
		<div class="card-body">
			<div class="top-infos" id="targets">
				<div class="directs-list">
					<ul class="list-unstyled d-flex d-inline-flex m-0">
						<li>
							<div>
								<span class="text-space"></span>
								<span id="selectedId"></span>
							</div>
						</li>

						<li>
							<div>
								<span class="text-space"></span>
								<span id="selectedId1"></span>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="directry-searchbar">
				<div class="row q-d-flex q-g-24">
					<div class="col-md-3">
						<div>
							<app-date-filter
								(startDateEmitter)="setGenerateStartDate($event)"
								(endDateEmitter)="setGenerateEndDate($event)"
								[dateLabels]="dateFilterLabel"
							></app-date-filter>
						</div>
					</div>

					<div class="col-md-3 q-filter">
						<div class="q-filter-single">
							<div class="q-filter-padding">
								<a
									href="javascript:void(0)"
									class="btn-badge btn-badge-primary"
									[class.selected-filter]="!invoiceStatementType.value"
									(click)="qFilterAll.click()"
								>
									All
								</a>

								<input
									type="radio"
									#qFilterAll
									name="qFilter"
									[formControl]="invoiceStatementType"
									[value]="false"
									hidden
								/>
							</div>

							<div class="q-filter-padding">
								<a
									href="javascript:void(0)"
									class="btn-badge btn-badge-primary"
									[class.selected-filter]="invoiceStatementType.value"
									(click)="qFilterOpen.click()"
								>
									Open
								</a>

								<input
									type="radio"
									#qFilterOpen
									name="qFilter"
									[formControl]="invoiceStatementType"
									[value]="true"
									hidden
								/>
							</div>

							<div class="q-filter-padding">
								<button
									(click)="onGenerateStatement()"
									class="btn-2 btn-2-primary export mb-0"
								>
									Generate
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- [End]::Generate Statement Section -->

	<!-- [Start]::Filter Section -->
	<section id="demo" class="collapse q-w-100">
		<div [formGroup]="form" class="table-filter-2 tf-col-4">
			<div class="filter-item">
				<label>Start Date</label>
				<date-input
					[formControl]="queryStartDate"
					max="{{ queryEndDate.value | date : 'yyyy-MM-dd' }}"
					[isString]="true"
				></date-input>
			</div>

			<div class="filter-item">
				<label>End Date</label>
				<date-input
					[formControl]="queryEndDate"
					min="{{ queryStartDate.value | date : 'yyyy-MM-dd' }}"
					[isString]="true"
				></date-input>
			</div>

			<div class="filter-item">
				<label>Due Date Start</label>
				<date-input
					[formControl]="queryStartDateDue"
					max="{{ queryEndDateDue.value | date : 'yyyy-MM-dd' }}"
					[isString]="true"
				>
				</date-input>
			</div>

			<div class="filter-item">
				<label>Due Date End</label>
				<date-input
					[formControl]="queryEndDateDue"
					min="{{ queryStartDateDue.value | date : 'yyyy-MM-dd' }}"
					[isString]="true"
				>
				</date-input>
			</div>

			<div class="filter-item">
				<label>Status</label>
				<select-input
					[options]="invoiceStatus"
					[optionKeys]="{ value: 'value', label: 'display' }"
					inputClass="form-control"
					formControlName="statusId"
					placeholder="Select Status"
					class="input-class"
				>
				</select-input>
			</div>

			<div class="filter-item">
				<label>Autopay</label>
				<select-input
					[options]="autopayOption"
					[optionKeys]="{ value: 'val', label: 'label' }"
					inputClass="form-control"
					formControlName="isAutopay"
					placeholder="Autopay"
					class="input-class"
				>
				</select-input>
			</div>

			<div class="filter-item filter-actions">
				<app-clear-filter></app-clear-filter>
			</div>
		</div>
	</section>
	<!-- [End]::Filter Section -->

	<!-- [Start]::Invoice Table -->
	<section id="invoice-table" class="filter-table-wrap q-w-100">
		<app-new-table-shared
			loadingText="Fetching Invoices..."
			[searchFilters]="searchFilters"
			[quickFilter]="quickFilter"
			(emitTotalItems)="onEmitTotalItems($event)"
		>
			<!-- For checkbox header -->
			<ng-template #customizeFirstHeaderColumnRef let-option let-i="index">
				<div
					class="d-flex align-items-center justify-content-center"
					[hidden]="!(isStripeEnabled && isPaymentInvoiceEnabled)"
				>
					<label for="check-all-record" class="q-pointer">
						<app-local-figma-icon-img
							[filename]="
								isCheckedAll() ? 'checkbox-active' : 'checkbox-in-active'
							"
							[width]="20"
							[height]="20"
							[hasSpaceOnRight]="false"
						>
						</app-local-figma-icon-img>
					</label>

					<input
						id="check-all-record"
						class="form-check-input"
						type="checkbox"
						[checked]="isCheckedAll()"
						(change)="checkAllInvoice($event)"
						hidden
						[disabled]="!isStripeEnabled || !isPaymentInvoiceEnabled"
					/>
				</div>
			</ng-template>

			<!-- For checkbox table data -->
			<ng-template #customizeFirstColumnTableDataRef let-option let-i="index">
				<div
					class="d-flex align-items-center q-jc-center"
					[hidden]="!(isStripeEnabled && isPaymentInvoiceEnabled)"
				>
					<label
						[for]="'check-record-' + option.id"
						class="q-pointer"
					>
						<app-local-figma-icon-img
							[filename]="isSelected(option.id) ? 'checkbox-active' : 'checkbox-in-active'"
							[width]="20"
							[height]="20"
							[hasSpaceOnRight]="false"
						>
						</app-local-figma-icon-img>
					</label>

					<input
						[id]="'check-record-' + option.id"
						class="form-check-input"
						type="checkbox"
						multiSelection
						[selectedItem]="option"
						[itemSelections]="checkBoxItems"
						(rangeSelected)="onRangeSelected($event)"
						[checked]="isSelected(option.id)"
						hidden
					/>
				</div>
			</ng-template>

			<!-- For action column -->
			<ng-template #customizeActionColumnRef let-option let-i="index">
				<div
					class="q-d-flex q-ai-center q-jc-start gap-3"
					style="max-width: 144px"
				>
					<ng-container *ngIf="option.type !== 'Payment'">
						<a
							*ngIf="isDownloadEnabled"
							href="javascript:void(0)"
							class="edit visiblity-icon"
							[tooltipContentV2]="{ message: 'Download' }"
    					[tooltipPosition]="'top'"
							mat-raised-button
							(click)="getPrintInvoice(option, 'viewPdf')"
						>
							<app-figma-icon-img
								[iconName]="'download-02'"
								[width]="24"
								[height]="24"
								[hasSpaceOnRight]="false"
								[isForButton]="false"
							>
							</app-figma-icon-img>
						</a>

						<a
							*ngIf="isDownloadEnabled"
							href="javascript:void(0)"
							class="edit visiblity-icon"
							[tooltipContentV2]="{ message: 'Print' }"
    					[tooltipPosition]="'top'"
							(click)="getPrintInvoice(option)"
							mat-raised-button
						>
							<app-figma-icon-img
								[iconName]="'printer'"
								[width]="24"
								[height]="24"
								[hasSpaceOnRight]="false"
								[isForButton]="false"
							>
							</app-figma-icon-img>
						</a>
					</ng-container>

					<a
						href="javascript:void(0)"
						mat-raised-button
						[tooltipContentV2]="{ message: 'Notify Bill To Contact' }"
    				[tooltipPosition]="'top'"
					>
						<app-figma-icon-img
							[iconName]="'send-01'"
							[width]="24"
							[height]="24"
							[hasSpaceOnRight]="false"
							[isForButton]="false"
						>
						</app-figma-icon-img>
					</a>
				</div>
			</ng-template>
		</app-new-table-shared>
	</section>
	<!-- [End]::Invoice Table -->
</div>
