import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CustomDatePipeModule } from '../pipes/custom-date.pipe.module';

import { EmbedFigmaComponent } from '../embed-figma/embed-figma.component';
import { FigmaIconImgComponent } from './figma-icon-img.component';

@NgModule({
	imports: [CommonModule, CustomDatePipeModule, RouterModule],
	declarations: [FigmaIconImgComponent, EmbedFigmaComponent],
	exports: [FigmaIconImgComponent, EmbedFigmaComponent],
})
export class FigmaIconImgModule {}
