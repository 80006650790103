<div class="container-pagi">
	<div class="row m-0">
		<div class="col-lg-2 w-100 pe-0">
			<div class="dataTable-dropdown">
				<label class="d-flex align-items-center float-end">
					<select
						class="dataTable-selector q-pointer"
						(change)="changeSize($event)"
						[(ngModel)]="size"
					>
						<option *ngFor="let _size of pageSizes" [value]="_size">
							{{ _size }}
						</option>
					</select>

					Entries Per Page
				</label>
			</div>
		</div>
	</div>
</div>
