import { Component, OnInit } from '@angular/core';
import { DashboardDrawerService } from '@app/shared/services/dashboard-drawer-service';

@Component({
  selector: 'app-feedback-suggestion',
  templateUrl: './feedback-suggestion.component.html',
  styleUrls: ['./feedback-suggestion.component.scss']
})
export class FeedbackSuggestionComponent implements OnInit {

  constructor(
    public drawer: DashboardDrawerService,
  ){}

  ngOnInit(): void {
    
  }

  feedbackPopup(){
    /* this._dashboardDrawerService.openDrawer({
      componentType: FeedbackWidgetPopupComponent,
      openerId : 'feedback-widget-popup',
      hasBackdrop : false
    }); */
    //this._mainDashboardCardService.openFeedbackList = true;
    this.drawer.openFeedbackList = true;

  }
}
