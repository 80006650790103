/*
  Example: 
  
  <span 
    [tooltipContentV2]="{ title: 'Dummy title', message: 'Sample Description' }"
    [tooltipPosition]="'top'"
    [textAlign]="'left'">
  </span>
*/

/* Angular Libraries */ 
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy  } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

/* Third Party Libraries */ 
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { TooltipContentV2Service } from './tooltip-content-v2.service';

/* Components */
import { TooltipContentV2Component } from './tooltip-content-v2.component';

/* Interfaces */
import { TooltipContent } from './tooltip-content-v2.interface';


type PositionAllowedValues = 'top' | 'bottom' | 'left' | 'right';
type TextAlignAllowedValues = 'left' | 'center' | 'right';

@Directive({
  selector: '[tooltipContentV2]',
  providers: [TooltipContentV2Service]
})

export class TooltipContentV2Directive implements OnDestroy {
  @Input('tooltipContentV2') bctData: TooltipContent;
  @Input() tooltipPosition?: PositionAllowedValues = 'top';
  @Input() textAlign?: TextAlignAllowedValues = 'left';
  @Input() isShowTooltip: boolean = true;
  
  private _overlayRef: OverlayRef;
  private _positionStrategy: any;
  private _changePosition: any;
  private _$unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _tooltipContentService: TooltipContentV2Service
  ) {}

  ngOnInit(): void {
    if (!this.isShowTooltip) { return; }

    let positions: any = [];
    let margin: number = 5;

    switch(this.tooltipPosition) {
      case 'top':
        positions = this._tooltipContentService.positionTop();
        break;

      case 'bottom':
        positions = this._tooltipContentService.positionBottom();
        break;

      case 'left':
        positions = this._tooltipContentService.positionLeft();
        break;

      case 'right':
        positions = this._tooltipContentService.positionRight();
        break;
    }

    this._positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withViewportMargin(margin)
      .withPositions(positions);

    this._overlayRef = this._overlay.create({ positionStrategy: this._positionStrategy });

    this._positionStrategy.positionChanges
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (change: any) => {
        this._changePosition = change;
      }
    });
  }

  @HostListener('mouseenter')
  show() {
    if (!this.isShowTooltip) { return; }

    const tooltipRef: ComponentRef<TooltipContentV2Component> = this._overlayRef.attach(new ComponentPortal(TooltipContentV2Component));
    tooltipRef.instance.bctData = this.bctData;
    tooltipRef.instance.textAlign = this.textAlign;

    const caretPositions: any = {
      'top-center-center-bottom': 'top-center', /* Top center */
      'top-start-start-bottom': 'top-start', /* Top start */
      'top-end-end-bottom': 'top-end', /* Top end */
      'bottom-center-center-top': 'bottom-center', /* Bottom center */
      'bottom-start-start-top': 'bottom-start', /* Bottom start */
      'bottom-end-end-top': 'bottom-end', /* Bottom end */
      'center-end-start-center': 'right-center', /* Right center */
      'top-end-start-top': 'right-top', /* Right top */
      'bottom-end-start-bottom': 'right-bottom', /* Right bottom */
      'center-start-end-center': 'left-center', /* Left center */
      'top-start-end-top': 'left-top', /* Left top */
      'bottom-start-end-bottom': 'left-bottom', /* Left bottom */
    };

    setTimeout(() => {
      tooltipRef.instance.caretPosition = caretPositions[`${this._changePosition.connectionPair.originY}-${this._changePosition.connectionPair.originX}-${this._changePosition.connectionPair.overlayX}-${this._changePosition.connectionPair.overlayY}`];
    }, 0);
  }

  @HostListener('mouseleave')
  hide() {
    if (!this.isShowTooltip) { return; }

    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  @HostListener('mousedown')
  onMouseDown() {
    if (!this.isShowTooltip) { return; }

    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  ngOnDestroy(): void {
    if (this._overlayRef) this._overlayRef.detach();
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
