import { Component, Input, OnInit, inject } from '@angular/core';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-sat-survey',
	templateUrl: './sat-survey.component.html',
	styleUrls: ['./sat-survey.component.scss'],
	standalone: true,
})
export class SatSurveyComponent implements OnInit {
	@Input() data: IDataModal;
	dataModalService = inject(DataModalService);
	chosen: number = 0;
	constructor() {}

	ngOnInit(): void {}
	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}

	updateChosen(chosenNum: number) {
		this.chosen = chosenNum;
	}

	onSubmit() {
		let link = '';
		if (this.data.templateData!['link'] === 'sourcepass') {
			link = `https://api.simplesat.io/api/embed/rating/${this.chosen}/${
				environment.ssat.link
			}/?source=generic_embed&customer_email=${
				this.data.templateData!['userData'].email
			}&customer_first_name=${
				this.data.templateData!['userData'].firstName
			}&customer_last_name=${this.data.templateData!['userData'].lastName}`;
		} else if (this.data.templateData!['link'] === 'ticket') {
			link = `https://api.simplesat.io/api/embed/rating/${this.chosen}/${
				environment.ssat.link
			}/${
				this.data.templateData!['element'].ticketId
			}/?source=connectwise&customer_email=${
				this.data.templateData!['userData'].email
			}&customer_first_name=${
				this.data.templateData!['userData'].firstName
			}&customer_last_name=${
				this.data.templateData!['userData'].lastName
			}&company_name=${
				this.data.templateData!['userData'].companyName
			}&customer_id=${this.data.templateData!['userData'].id}&ticket_subject=${
				this.data.templateData!['element'].description
			}&team_member_id=${
				this.data.templateData!['element'].memberId
			}&team_member_first_name=${
				this.data.templateData!['element'].memberFirstName
			}&team_member_last_name=${
				this.data.templateData!['element'].memberLastName
			}`;
		}

		window.open(link, '_blank');
		this.closeModal(true);
	}
}
