<div id="manage-autopay-modal" *ngIf="isOpenModal">
	<ng-container *ngIf="page === 'main'; then main; else terms"></ng-container>
</div>

<ng-template #main>
	<modal-v2 [isLoading]="isLoading">
		<ng-template #modalV2Header>
			<div class="d-flex align-items-center w-100">
				<div class="manage-autopay-modal-header-left">
					<div class="manage-autopay-modal-header-left-icon">
						<app-figma-icon-img
							[iconName]="'refresh-cw-05'"
							[hasSpaceOnRight]="false"
						></app-figma-icon-img>
					</div>

					<div class="manage-autopay-modal-header-left-info font-h3">
						Manage Autopay
					</div>
				</div>

				<app-badge
					*ngIf="paymentMethodForm.value?.isForAutopay"
					[label]="'ON'"
					[type]="'#CAF4DB'"
					[fontColor]="'#0D6630'"
				></app-badge>
			</div>
		</ng-template>

		<ng-template #modalV2Body>
			<div class="manage-autopay-modal-body">
				<div class="manage-autopay-modal-body-detail font-b1">
					By enrolling in autopay, you authorize Sourcepass to automatically
					charge the debit or credit card account or to automatically debit
					the checking or savings account specified for payments due on the
					Sourcepass account.
				</div>

				<div class="manage-autopay-modal-body-form">
					<div class="manage-autopay-modal-form-group">
						<label class="manage-autopay-label font-b1">Pay From</label>

						<div id="form-input-with-icon">
							<div
								class="form-icon-prefix"
								(click)="paymentMethodSelect.select()"
							>
								<app-card-logos
									*ngIf="paymentMethodForm.value?.name"
									[branName]="paymentMethodForm.value.name"
									[isCard]="
										paymentMethodForm.value.type === paymentMethodType.card
									"
								>
								</app-card-logos>
							</div>

							<div
								class="form-input-postfix"
								(click)="paymentMethodSelect.select()"
							>
								<app-filter-chevron [isCollapsed]="true"></app-filter-chevron>
							</div>

							<input
								#paymentMethodSelect
								type="text"
								matInput
								[formControl]="paymentMethodForm"
								class="form-control font-b1"
								[class.form-input-pl]="paymentMethodForm.value?.name"
								[matAutocomplete]="auto"
								placeholder="- Select Payment Method -"
							/>

							<mat-autocomplete
								#auto="matAutocomplete"
								[displayWith]="displayFn"
								(optionSelected)="onSelectionChange()"
							>
								<mat-option class="form-input-with-icon-option" value="">
									<div class="clear-select-form font-b1">
										<div>Clear Select</div>
									</div>
								</mat-option>
								<mat-option
									class="form-input-with-icon-option"
									*ngFor="let option of filteredOptions | async"
									[value]="option"
								>
									<div class="form-icon-pos">
										<app-card-logos
											[branName]="option.name"
											[isCard]="option.type === paymentMethodType.card"
										>
										</app-card-logos>

										<div class="form-icon-detail font-b1">
											{{ option.name }} **** {{ option.last4 }}
										</div>
									</div>
								</mat-option>
							</mat-autocomplete>
						</div>
					</div>

					<div class="manage-autopay-acknowledge">
						<div class="manage-autopay-note font-b3">
							<b style="font-weight: 600;">Please Note:</b> All invoices will be paid 10 days after the
							invoice is posted.
						</div>
					</div>

					<ng-container *ngIf="!paymentMethodForm.value?.isForAutopay">
						<div class="manage-autopay-acknowledge">
							<input
								#acknowledgeCheckbox
								type="checkbox"
								[(ngModel)]="isChecked"
								hidden
							/>

							<a
								href="javascript:void(0);"
								class="acknowledge-form"
								[class.disabled-checkbox]="!paymentMethodForm.value"
								(click)="
									paymentMethodForm.value ? acknowledgeCheckbox.click() : ''
								"
							>
								<app-figma-icon-img
									[iconName]="isChecked ? 'check-square' : 'square'"
									[hasSpaceOnRight]="false"
									[isForButton]="true"
								></app-figma-icon-img>
							</a>

							<div class="manage-autopay-acknowledge-detail font-b1">
								I acknowledge the
								<a
									href="javascript:void(0);"
									class="highlighted-text font-b1"
									(click)="page = 'terms'"
									>Terms & Conditions</a
								>
								for Autopay.
							</div>
						</div>
					</ng-container>
				</div>

				<div class="manage-autopay-footer">
					<button
						type="button"
						class="btn-2 btn-2-destructive"
						[style.visibility]="paymentMethodForm.value?.isForAutopay ? 'visible' : 'hidden'"
						(click)="onClickUnenroll()"
						[class.disabled]="!paymentMethodForm.value"
					>
						Unenroll
					</button>

					<div class="manage-autopay-footer-pos-right">
            <app-tertiary-button
              [btnText]="'Cancel'"
							(click)="closeModal()"
            ></app-tertiary-button>

						<button
							type="button"
							class="btn-2 btn-2-primary"
							(click)="onClickEnroll()"
							[class.disabled]="!isChecked || !paymentMethodForm.value"
							>
							Enroll
						</button>
					</div>
				</div>

				<!-- <div id="manage-autopay-audit-trail">
					<app-audit-trail
						[url]="historyUrl.MANAGE_AUTOPAY"
						history_category="invoice"
						id="invoice-autopay"
					></app-audit-trail>
				</div> -->
			</div>
		</ng-template>
	</modal-v2>
</ng-template>

<ng-template #terms>
	<modal-v2 [modalWidth]="545" [isLoading]="isLoading">
		<ng-template #modalV2Header>
			<div class="manage-autopay-modal-header-left">
				<div class="manage-autopay-modal-header-left-icon q-pointer">
					<app-figma-icon-img
						[iconName]="page !== 'main' ? 'check-square' : 'square'"
						(click)="page = 'main'"
						[isForButton]="true"
					></app-figma-icon-img>
				</div>

				<div class="manage-autopay-modal-header-left-info font-h3">
					Terms and Conditions
				</div>
			</div>
		</ng-template>

		<ng-template #modalV2Body>
			<div class="manage-autopay-modal-body">
				<div class="manage-autopay-modal-body-detail font-b1">
					<p>
						By enrolling in autopay, you authorize Sourcepass to automatically
						charge the debit or credit card account or to automatically debit
						the checking or savings account specified for payments due on the
						Sourcepass account.
					</p>
				</div>
			</div>
		</ng-template>
	</modal-v2>
</ng-template>

