import { Injectable, inject } from '@angular/core';
import {
	convertUTCToLocal,
	getEquivalentTimezone,
	getHourDifferenceFromDate,
} from '../functions/date.functions';
import { UserService } from '@app/core/services/user.service';

@Injectable({ providedIn: 'root' })
export class DateService {
	private _user = inject(UserService);

	getMinsFromNow(dateString: string) {
		let givenDate = new Date(dateString);
		let today = new Date();
		if (!isNaN(givenDate.getTime())) {
			const diffInMs = Math.abs(givenDate.getTime() - today.getTime());

			// Convert milliseconds to minutes
			const diffInMinutes = Math.floor(diffInMs / 1000 / 60);

			return diffInMinutes;
		} else return 0;
	}

	onConvertUTCToLocal(utcTimestamp: string): string {
		return convertUTCToLocal(utcTimestamp);
	}

	onGetHourDifferenceFromDate(date: Date): number {
		return getHourDifferenceFromDate(date);
	}

	onGetHourDifferenceFromTimezone(date: Date) {
		const userTimeZone = getEquivalentTimezone(this._user.timezone || 'EST');
		const timeZone = this.isValidTimeZone(userTimeZone)
			? userTimeZone
			: this.isValidTimeZone('EST')
			? 'EST'
			: undefined;
		const currentDate = new Date(
			new Date().toLocaleString('en-US', { timeZone })
		);
		const timeDifferenceInMillis = currentDate.getTime() - date.getTime();
		let minuteDifference = Math.floor(timeDifferenceInMillis / 60000);
		if (minuteDifference < 0) minuteDifference += 60; // this is due to the bug that system timezone does not match with browser timezone
		// there is a 60 minutes difference, it is present when negative values is given
		return minuteDifference;
	}

	onConvertLocalToUTC(date: Date): string {
		// add timezone offset first
		const localDate = new Date(
			date.getTime() + date.getTimezoneOffset() * 60000
		);
		let utcDate = localDate.toISOString();
		return utcDate;
	}

	isValidTimeZone(timeZone: string) {
		try {
			Intl.DateTimeFormat(undefined, { timeZone: timeZone });
			return true;
		} catch (err) {
			return false;
		}
	}
}
