<div class="verification-message-container">
	<div class="verification-message">
		<div>
			<div class="d-flex justify-content-center">
				<app-figma-icon-img
					[iconName]="'info-circle'"
					[hasSpaceOnRight]="false"
          [width]="50"
          [height]="50"
				></app-figma-icon-img>
			</div>
			<div class="mt-3">You may now close this window to view status.</div>
		</div>
	</div>
</div>
