import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject, finalize, ReplaySubject, tap } from 'rxjs';

import { IGenericInterface } from '@app/shared/interfaces/generic.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NotificationCenterService {
	territoryNotificationFilters: IGenericInterface;
	territoryCompanyNotificationFilters: IGenericInterface;
	companyNotificationFilters: IGenericInterface;

	private _territoryNotificationData = new ReplaySubject<any[]>(1);
	territoryNotificationData$ = this._territoryNotificationData.asObservable();

	private _territoryNotificationTotalItems = new ReplaySubject<number>(1);
	territoryNotificationTotalItems$ =
		this._territoryNotificationTotalItems.asObservable();

	private _territoryCompanyNotificationData = new ReplaySubject<any[]>(1);
	territoryCompanyNotificationData$ =
		this._territoryCompanyNotificationData.asObservable();

	private _territoryCompanyNotificationTotalItems = new ReplaySubject<number>(
		1
	);
	territoryCompanyNotificationTotalItems$ =
		this._territoryCompanyNotificationTotalItems.asObservable();

	private _companyNotificationData = new ReplaySubject<any[]>(1);
	companyNotificationData$ = this._companyNotificationData.asObservable();

	private _companyNotificationTotalItems = new ReplaySubject<number>(1);
	companyNotificationTotalItems$ =
		this._companyNotificationTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	constructor(private _http: HttpClient) {}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	getTerritoryName(id: number) {
		return this._http.get(
			`${environment.apiBaseUrl}NotificationAccess/GetTerritoryNameById/${id}`
		);
	}

	getTerritoryList(params: Params) {
		return this._http.get(
			`${environment.apiBaseUrl}NotificationAccess/GetTerritoryList`,
			{
				params,
			}
		);
	}

	getTerritoryNotificationAccessById(params: Params) {
		const filters = params as IGenericInterface;
		this.saveCurrentFilter(filters);
		this.clearNotificationData(filters);

		return this._http
			.get(
				`${environment.apiBaseUrl}NotificationAccess/GetTerritoryNotificationAccessById`,
				{
					params,
				}
			)
			.pipe(
				tap((res) => {
					this.setNotificationData(filters, res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	saveCurrentFilter(options: IGenericInterface) {
		if (options.territoryId) {
			if (options.filterByCompanyId) {
				this.territoryCompanyNotificationFilters = options;
			} else {
				this.territoryNotificationFilters = options;
			}
		}

		if (options.companyId) {
			this.companyNotificationFilters = options;
		}
	}

	clearNotificationData(options: IGenericInterface) {
		this.isLoading = true;

		if (options.territoryId) {
			if (options.filterByCompanyId) {
				this._territoryCompanyNotificationData.next([]);
				this._territoryCompanyNotificationTotalItems.next(0);
			} else {
				this._territoryNotificationData.next([]);
				this._territoryNotificationTotalItems.next(0);
			}
		}

		if (options.companyId) {
			this._companyNotificationData.next([]);
			this._companyNotificationTotalItems.next(0);
		}
	}

	setNotificationData(options: IGenericInterface, res: any) {
		if (options.territoryId) {
			if (options.filterByCompanyId) {
				this._territoryCompanyNotificationData.next(res.data);
				this._territoryCompanyNotificationTotalItems.next(res.totalCount);
			} else {
				this._territoryNotificationData.next(res.data);
				this._territoryNotificationTotalItems.next(res.totalCount);
			}
		}

		if (options.companyId) {
			this._companyNotificationData.next(res.data);
			this._companyNotificationTotalItems.next(res.totalCount);
		}
	}

	getCompanyNotificationAccessById(params: Params) {
		const filters = params as IGenericInterface;
		this.saveCurrentFilter(filters);
		this.clearNotificationData(filters);

		return this._http
			.get(
				`${environment.apiBaseUrl}NotificationAccess/GetCompanyNotificationAccessById`,
				{
					params,
				}
			)
			.pipe(
				tap((res) => {
					this.setNotificationData(filters, res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getCompanyNotificationAccessOverride(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}NotificationAccess/CompanyNotificationAccess/GetOverride/${companyId}`
		);
	}

	territoryNotificationAccess(payload: Params, id: string | number) {
		return this._http.put(
			`${environment.apiBaseUrl}NotificationAccess/TerritoryNotificationAccess/${id}`,
			payload
		);
	}

	filterByCompanyNotificationAccess(payload: Params, id: string | number) {
		return this._http.put(
			`${environment.apiBaseUrl}NotificationAccess/FilterCompanyNotificationAccess/${id}`,
			payload
		);
	}

	companyNotificationAccess(payload: Params, id: string | number) {
		return this._http.put(
			`${environment.apiBaseUrl}NotificationAccess/CompanyNotificationAccess/${id}`,
			payload
		);
	}

	companyNotificationAccessOverride(payload: Params) {
		return this._http.put(
			`${environment.apiBaseUrl}NotificationAccess/CompanyNotificationAccess/SetOverride`,
			payload
		);
	}

	getCompanyTerritoryNotification(params: any) {
		return this._http.get(
			`${environment.apiBaseUrl}NotificationAccess/GetCompanyTerritoryNotification`,
			{ params }
		);
	}
}
