import {
	Component,
	ViewChild,
	ChangeDetectorRef,
	AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { SortDirection, MatSort } from '@angular/material/sort';
import { finalize } from 'rxjs';

import { ClientAdminPermissionComponent } from './modal/client-admin-permission/client-admin-permission.component';
import { SelectAdminComponent } from './modal/select-admin/select-admin.component';
import { ItemPerPageComponent } from './../../../../shared/components/paginator/item-per-page/item-per-page.component';
import { PaginatorComponent } from './../../../../shared/components/paginator/paginator.component';
import { UnauthorizedAccessComponent } from './modal/unauthorized-access/unauthorized-access.component';

import { CompaniesService } from './../../companies.service';
import { SpinnerService } from './../../../../core/services/spinner.service';
import { CwpodVerificationService } from '@app/modules/public/pages/cwpod-verification/cwpod-verification.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';

import { PaginationConstants } from '@app/shared/constants';

import { HeaderSort } from './../../../../shared/interfaces/knowledge-base.interface';

@Component({
	selector: 'app-client-admin-list',
	templateUrl: './client-admin-list.component.html',
	styleUrls: ['./client-admin-list.component.scss'],
	providers: [CwpodVerificationService],
})
export class ClientAdminListComponent implements AfterViewInit {
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ViewChild(ItemPerPageComponent) itemsPerPage: ItemPerPageComponent;
	@ViewChild(MatSort) sort: MatSort;

	hasData: boolean = false;
	clientData: any[] = [];
	totalItems = 0;
	order: SortDirection;
	companyId: any;
	pageSizes = PaginationConstants.pageSizes;
	displayedColumns: string[] = ['name', 'title', 'actions'];
	noRecordsMessage = 'No records found';

	constructor(
		public spinner: SpinnerService,
		public dialog: MatDialog,
		private _cd: ChangeDetectorRef,
		private _activatedRoute: ActivatedRoute,
		private _companiesService: CompaniesService,
		private _dataModalService: DataModalService
	) {
		this.companyId = this._activatedRoute.snapshot.params['companyId'];
	}

	ngAfterViewInit() {
		this.getCompanyListing();
	}

	sortData(e: HeaderSort) {
		this.order = e.direction as SortDirection;
		this.getCompanyListing('page');
	}

	getCompanyListing(flag = 'page') {
		this.spinner.start();

		const options = {
			page: this.paginator.page,
			pageSize: this.paginator.size,
			order: 'asc',
			column: 'name',
		};

		this._companiesService
			.getCompanyAdmin(this.companyId, options)
			.pipe(finalize(() => this.spinner.stop()))
			.subscribe({
				next: (resp: any) => {
					const data = resp.data;

					this.clientData = resp.data;
					this.hasData = !!data.length;
					this.totalItems = resp.totalCount;
					this._cd.detectChanges();
				},
			});
	}

	openClientAddDialog() {
		this.dialog
			.open(SelectAdminComponent, {
				width: '600px',
				height: '336.5px',
				data: this.companyId,
			})
			.afterClosed()
			.subscribe((result) => {
				if (result.event == 'Added') {
					this.getCompanyListing();
				}
			});
	}

	openUnauthorizedDialog() {
		this.dialog.open(UnauthorizedAccessComponent, {
			width: '380px',
			height: '240px',
			disableClose: true,
			data: {
				message: 'Unauthorized access to admin listing',
			},
		});
	}

	openInvoiceDialog(userId: any) {
		this.dialog.open(ClientAdminPermissionComponent, {
			width: '600px',
			height: '336.5px',
			data: {
				companyId: this.companyId,
				userId: userId,
			},
		});
	}

	onDelete(app: any) {
		const data = this._dataModalService.getDeleteModel(
			'Company',
			`${app?.firstName.trim()} ${app?.lastName.trim()}`
		);

		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					this._companiesService.updateRole(app.id, 3).subscribe({
						next: () => {
							this.spinner.start();
							this.getCompanyListing();
						},
						error: () => {
							this.spinner.stop();
						},
					});
				}
			},
		});
	}
}
