<div
	*ngIf="show"
	[id]="id"
	class="modal fade"
	[ngClass]="[modalClass]"
	role="dialog"
>
	<div
		class="modal-dialog modal-dialog-centered"
		[ngClass]="[modalSize]"
		role="document"
	>
		<div class="modal-content">
			<div *ngIf="withHeader" class="modal-header">
				<ng-container *ngTemplateOutlet="modalHeader"></ng-container>
				<ng-container *ngIf="!modalHeader">
					<!-- insert here if no modal header template pass -->
					<h5 class="modal-title text-sm" [title]="headerTitle">
						{{ headerTitle }}
					</h5>
					<button
						type="button"
						class="close"
						data-bs-dismiss="modal"
						aria-label="Close"
						(click)="onClose()"
					>
						<span aria-hidden="true">
							<app-figma-icon-img
								[iconName]="'x'"
								[isForButton]="true"
								[hasSpaceOnRight]="false"
							></app-figma-icon-img>
						</span>
					</button>
				</ng-container>
			</div>
			<div class="modal-body">
				<ng-container *ngTemplateOutlet="modalBody"></ng-container>
				<div *ngIf="!modalBody">
					<!-- insert here if no modal body template pass -->
				</div>
				<div *ngIf="withDefaultFooterBtn">
					<div class="submit-buttons mt-0">
						<button
							class="btn cancel-btn btn-gradient-primary addnewapp-btn btn-sm"
							[disabled]="disableCancel"
							(click)="onClose()"
						>
							{{ cancelBtnText }}
						</button>
						<button
							class="btn submit-btn btn-gradient-primary addnewapp-btn btn-sm"
							[disabled]="disableSave"
							(click)="onSave()"
						>
							{{ saveBtnText }}
						</button>
					</div>
				</div>
				<div *ngIf="modalFooter" class="modal-footer">
					<ng-container *ngTemplateOutlet="modalFooter"></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
