import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, finalize, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private _spinnerSubject = new BehaviorSubject<boolean>(false);
  private _count = 0;
  timeout = 500;

  get spinner() {
    return this._spinnerSubject.asObservable();
  }

  start() {
    if(++this._count === 1) this._spinnerSubject.next(true);
  }

  stop(timeout:number=this.timeout) {
    if(this._count === 0 || --this._count === 0) {
      setTimeout(() => {
        this._spinnerSubject.next(false);
      }, timeout);
    }
  }

  reset() {
    this._count = 0;
    this._spinnerSubject.next(false);
  }

  run(process: Observable<any>){
    return defer(() => {
      this.start();
      return of(0);
    }).pipe(
      switchMap(() => process),
      finalize(() => this.stop())
    );
  }
}
