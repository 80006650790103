import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Permission } from '@app/shared/constants';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private _userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const permissions: Permission[] = route.data['permissions'];
    return this._userService.hasPermission(permissions);
  }
  
}
