import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalSettingsRoutingModule } from './global-settings-routing.module';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import { SharedModule } from '@app/shared/shared.module';

import { EditGlobalSettingsComponent } from './page/edit-global-settings/edit-global-settings.component';
import { GlobalSettingsComponent } from './page/global-settings/global-settings.component';

@NgModule({
  declarations: [GlobalSettingsComponent, EditGlobalSettingsComponent],
  imports: [
    CommonModule,
    GlobalSettingsRoutingModule,
    SharedModule,
    FormTooltipModule,
  ],
})
export class GlobalSettingsModule {}
