import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DevServices, DevModules, DevDeclarations } from 'modules/app-modules';
import { FigmaDemoComponent } from './figma-demo/figma-demo.component';
// Please do not add new imports, providers, declarations here. Add it to their respective constants
@NgModule({
	declarations: [DevDeclarations, FigmaDemoComponent],
	imports: [DevModules],
	providers: [DevServices],
	bootstrap: [AppComponent],
})
export class AppModule {}
