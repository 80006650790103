<div class="tooltip" [class]="className" [style.background]="bgColor">
	<ng-container *ngIf="!isChart; else aChart">
		<p>{{ message.m1 }}</p>
		<p *ngIf="message.m2">
			Last Updated:
			{{ message.m2 ? (message.m2 | date : 'MMM d, y, h:mm a') : '' }}
		</p>
		<p class="loop-text" *ngFor="let m of message.m3">{{ m }}</p>
	</ng-container>

	<ng-template #aChart>
		<h3>{{ message.m1 }}</h3>
		<p class="font-14">{{ message.m2 }}</p>
	</ng-template>
</div>
