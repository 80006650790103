import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Component, Input, OnInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() label = '';
  @Input() type = '#000000';
  @Input() fontColor = '#ffffff';
  @Input() size = 'sm'; // sm, md, lg
  @Input() firstLetterOnly = false;
  @Input() faIcon = '';
  @Input() iconName: string; // from localstorage
  @Input() filename: string; // from assets folder
  @Input() height: number;
  @Input() width: number;
  badgeColor: any;

  badgeType() {
    if (this.type) {
      let value = `background: linear-gradient(310deg, ${this.type} 0%, ${this._utilityService.light(this.type)} 100%)`;
      return value;
    } else {
      return `background: linear-gradient(310deg, #5a5a5a 0%,  ${this._utilityService.light('#5a5a5a')} 100%)`;
    }

  }

  get badgeLabel(): string {
    let value = this.label;

    if(this.firstLetterOnly) {
      value = value.charAt(0);
    }

    return value;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.badgeColor = {
      'background-image': this.badgeType.toString()
    };

    if(!this.type) this.type = '#000000';
    if(!this.fontColor) this.fontColor = '#ffffff';
    this._cd.detectChanges();
  }
  constructor(public _utilityService: UtilitiesService, private _cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

}
