<span
	*ngIf="label"
	class="badge"
	[ngClass]="['badge-' + size]"
	[style.background]="type"
	[style.color]="fontColor"
>
	<app-figma-icon-img
		*ngIf="iconName"
		[iconName]="iconName"
		[isForButton]="true"
		[hasSpaceOnRight]="false"
		[height]="height"
		[width]="width"
	></app-figma-icon-img>

	<app-local-figma-icon-img
		*ngIf="filename"
		[height]="height"
		[width]="width"
		[filename]="filename"
    class="d-flex"
	></app-local-figma-icon-img>

	<span class="badge-name d-flex" style="margin-top: 1.8px;">{{ badgeLabel }}</span>
</span>
