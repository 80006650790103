import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'online-offline-status',
  templateUrl: './online-offline.component.html',
  styleUrls: ['./online-offline.component.scss']
})
export class OnlineOfflineComponent implements OnInit {

  @Input() isOnline: boolean = false;

  constructor() { }

  get text(): string {
    return this.isOnline ? 'Online' : 'Offline';
  }

  ngOnInit() {
  }

}
