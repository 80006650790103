<div class="card-2 q-p-0 q-pt-24 q-br-0">
		<div class="card-header-2">
      <h3 class="q-mr-auto">Account Activity</h3>

				<search-input
          #searchTextbox
          size="small"
          [formControl]="queryString"
          (keyup)="queryChange(500)"
          (keyup.enter)="queryChange()"
          placeholder="Search Account Activity">
          </search-input>

          <app-tertiary-button
            [isFilterOn]="isFilterOn"
            [isFilterToggle]="true"
          ></app-tertiary-button>

        <a href="javascript:void(0)"
          class="q-pointer q-d-flex q-ai-center"
          [matMenuTriggerFor]="topRowButtonAction">
					<app-figma-icon-img
						[iconName]="'dots-vertical'"
            [width]="24"
            [height]="24"
						[isForButton]="true"
						[hasSpaceOnRight]="false">
					</app-figma-icon-img>
				</a>

        <mat-menu #topRowButtonAction="matMenu" class="v2-menu-action-button">
          <button mat-menu-item (click)="export(applicationType.CSV)">
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Export As CSV</div>
						</div>
					</button>

					<button mat-menu-item (click)="export(applicationType.EXCEL)">
						<div class="menu-item-group">
							<div class="menu-item-icon">
								<mat-icon svgIcon="file-download-01" class="menu-item-svg"></mat-icon>
							</div>
							<div class="menu-item-name font-b3">Export As XLSX</div>
						</div>
					</button>
				</mat-menu>
		</div>

    <section id="demo" class="q-w-100 collapse">
      <div class="table-filter-2 tf-col-4">
        <div class="filter-item">
          <label>Start Date</label>
          <date-input
            [formControl]="startDateFilter"
            max="{{ endDateFilter.value | date : 'yyyy-MM-dd' }}"
            [isString]="true"
          ></date-input>
        </div>

        <div class="filter-item">
          <label>End Date</label>
          <date-input
            [formControl]="endDateFilter"
            min="{{ startDateFilter.value | date : 'yyyy-MM-dd' }}"
            [isString]="true"
          ></date-input>
        </div>

        <div class="filter-item">
          <label>Status </label>
          <multi-select-input-2
            [options]="statusOption"
            [formControl]="statusFilter"
            [autoClosePerSelect]="true"
            placeholder="- Select Status -"
            [hideIcon]="true"
            [resetVal]="resetVal">
          </multi-select-input-2>
        </div>

        <div class="filter-item filter-actions">
          <app-clear-filter></app-clear-filter>
        </div>
      </div>
  </section>

  <section id="account-activity" class="filter-table-wrap q-w-100">
    <app-new-table-shared
      loadingText="Fetching Account Activities..."
      [searchFilters]="searchFilters"
      [quickFilter]="quickFilter"
      (emitTotalItems)="onEmitTotalItems($event)"
    ></app-new-table-shared>
  </section>
</div>
