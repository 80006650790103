import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from './../../shared.module';
import { ContactsKBAddEditComponent } from './contact-kb-add-edit.component';
import { ContactsKnowledgeBaseComponent } from './contacts-kb.component';
import { KbTopicListModule } from './../../../modules/knowledge-base/pages/kb-topic-list/kb-topic-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';




@NgModule({
  declarations: [
    ContactsKnowledgeBaseComponent,
    ContactsKBAddEditComponent
  ],
  imports: [
    CommonModule,
    KbTopicListModule,
    MatSortModule
  ],

})
export class ContactsKnowledgeBaseModule {

}
