import { NotificationService } from './../../../../core/services/notification.service';
import {
  IPermissionData,
  Permission,
  PermissionType,
  HistoryUrl
} from '@app/shared/constants';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { KnowledgeBaseService } from '../../knowledge-base.service';
import { History } from '@app/shared/interfaces/common.interface';
import { UserService } from '@app/core/services/user.service';
import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { MatTabGroup } from '@angular/material/tabs';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-kb-topic-tab',
  templateUrl: './kb-topic-tab.component.html',
  styleUrls: [
    '../../../service-and-support/pages/custom-form/custom-form.component.scss',
    './kb-topic-tab.component.scss',
  ],
})
export class KbTopicTabComponent
  extends CompanyIdRouteComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('permissionSettings') permissionSettings: any;
  @ViewChild(MatTabGroup) tabs: MatTabGroup;

  stateAction: string;
  savedId: number;
  topicPermissionData: IPermissionData;
  topicName: string;
  isAuditTrailEnabled: boolean;
  genSetting: string;
  selectedTabIndex = 0;

  form: any;
  companyAccess: number[] = [];

  historyUrl = HistoryUrl;
  constructor(
    private _kbService: KnowledgeBaseService,
    private _notifier: NotificationService,
    private _userService: UserService,
    private _cd: ChangeDetectorRef,
    private _breadcrumbService: BreadcrumbService,
    public override _route: ActivatedRoute,
    public override _companiesService: CompaniesService
  ) {
    super(_companiesService, _route);
  }

  get permissionModuleId() {
    return PermissionType.KbTopics;
  }
  
  get isUserRole() {
    return true;//this._userService.isUser; temporarily hide the permission settings
  }
  ngOnInit(): void {
    this.getCompanyIdFromParams();
    this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(this._companiesService.subCompanyId!);
    this.isAuditTrailEnabled =
      this._userService.hasPermission(Permission.CompanyAdminAuditTrailKnowledgeBase) ||
      this._userService.isSpAdmin;
  }

  ngAfterViewInit(): void {
/*     const handleTabClick = this.tabs._handleClick;
    const generalSettings = 0;
    const permissionSettings = 1;

    this.tabs._handleClick = (tab, header, index) => {
      if (index === permissionSettings && this.form.valid) {
        handleTabClick.apply(this.tabs, [tab, header, index]);
      } else if (index === generalSettings) {
        if (this.companyAccess.length > 0) {
          handleTabClick.apply(this.tabs, [tab, header, index]);
        } else {
          (
            this.permissionSettings.saveButton.nativeElement as HTMLElement
          ).click();
        }
      }
    }; */
  }

  getCompanyAccess(companyAccess: number[]) {
    this.companyAccess = [...companyAccess];
  }

  getFormData(form: any) {
    this.form = form;
  }

  getStateAction(state: string) {
    this.stateAction = state;
  }
  selectPermissionTabEvent(index: number) {
    this.selectedTabIndex = index;
  }
  getSavedId(data: number) {
    this.savedId = data;
  }
  getTopicName(name: string) {
    this.topicName = name;
  }
  getTopicPermissionData(data: IPermissionData) {
    this.topicPermissionData = data;
  }

  getGenSettingName(name: string) {
    this.genSetting = name;
    this._cd.markForCheck();
  }
}
