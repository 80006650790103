import { FormAnswerType, FormValidationType } from './global-enum';

export class HttpRoutes {
	// Application
	public static readonly Application = 'Application';
	public static readonly ApplicationCategory = 'ApplicationCategory';

	// userMenu
	public static readonly UserMenus = 'MenuPermissions/GetUserMenu';

	// quotes
	public static readonly Quote = 'Quotes';
	public static readonly QuoteStatus = 'Quotes/GetQuoteStatusDropdown';

	// orders
	public static readonly Order = 'Orders';
	public static readonly OrderProductList = 'Orders/GetProductList';
	public static readonly OrderStatus = 'Orders/GetOrderStatusDropdown';
	public static readonly OrderPrintInvoice = 'CW_Invoices/PrintInvoice';
	public static readonly OrderInquiry = 'Orders/SubmitInquiry';
}

export class StaticIds {
	public static readonly KnowledgeBase = 57;
	public static readonly Applications = 58;
	public static readonly SystemDefaultUser = 50;
}

export class SupportConstants {
	static FORM_ANSWER_TYPES = [
		{
			id: FormAnswerType.Textbox,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [
				FormValidationType.MinLength,
				FormValidationType.MaxLength,
				FormValidationType.NoSpecialCharacters,
			],
		},
		{
			id: FormAnswerType.Textarea,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [FormValidationType.MinLength, FormValidationType.MaxLength],
		},
		{
			id: FormAnswerType.RadioButton,
			isMultiple: true,
			allowMultipleAnswer: false,
			validations: [],
		},
		{
			id: FormAnswerType.Checkbox,
			isMultiple: true,
			allowMultipleAnswer: true,
			validations: [FormValidationType.MaxAnswer],
		},
		{
			id: FormAnswerType.Dropdown,
			isMultiple: true,
			allowMultipleAnswer: false,
			validations: [],
		},
		{
			id: FormAnswerType.MultiSelectDropdown,
			isMultiple: true,
			allowMultipleAnswer: true,
			validations: [FormValidationType.MaxAnswer],
		},
		{
			id: FormAnswerType.Date,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [
				FormValidationType.RestrictPastDate,
				FormValidationType.RestrictFutureDate,
			],
		},
		{
			id: FormAnswerType.Datetime,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [
				FormValidationType.RestrictPastDate,
				FormValidationType.RestrictFutureDate,
			],
		},
		{
			id: FormAnswerType.Time,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [],
		},
		{
			id: FormAnswerType.Image,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [FormValidationType.AllowMultipleFiles],
		},
		{
			id: FormAnswerType.File,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [FormValidationType.AllowMultipleFiles],
		},
		{
			id: FormAnswerType.Email,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [],
		},
		{
			id: FormAnswerType.Number,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [FormValidationType.MinNumber, FormValidationType.MaxNumber],
		},
		{
			id: FormAnswerType.Url,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [FormValidationType.MinLength, FormValidationType.MaxLength],
		},
		{
			id: FormAnswerType.DropdownWithInput,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [],
		},
    {
      id: FormAnswerType.EmailDropdown,
      isMultiple: false,
      allowMultipleAnswer: false,
      validations: []
    }
		// {id: FormAnswerType.Password, isMultiple: false, allowMultipleAnswer: false, validations: [FormValidationType.MinLength, FormValidationType.MaxLength]},
	];

	static REWST_ANSWER_TYPES = [
		{
			id: FormAnswerType.RadioButton,
			isMultiple: true,
			allowMultipleAnswer: false,
			validations: [],
		},
		{
			id: FormAnswerType.Checkbox,
			isMultiple: true,
			allowMultipleAnswer: true,
			validations: [FormValidationType.MaxAnswer],
		},
		{
			id: FormAnswerType.Dropdown,
			isMultiple: true,
			allowMultipleAnswer: false,
			validations: [],
		},
		{
			id: FormAnswerType.MultiSelectDropdown,
			isMultiple: true,
			allowMultipleAnswer: true,
			validations: [FormValidationType.MaxAnswer],
		},
		{
			id: FormAnswerType.DropdownWithInput,
			isMultiple: false,
			allowMultipleAnswer: false,
			validations: [],
		},
		// {id: FormAnswerType.DropdownWithSearch, isMultiple: true, allowMultipleAnswer: false, validations: []},
		// {id: FormAnswerType.DropdownWithFilter, isMultiple: true, allowMultipleAnswer: false, validations: []},
	];
}

export class NewTicket {
	public static readonly url = '/service-and-support/new-ticket';
}

export class TableMessages {
	public static readonly EmptyTable = 'No records found.';
}
