import { Component, HostListener, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { SharedModule } from '@app/shared/shared.module';
import { MonthlyActivityGraphModule } from '@app/modules/portal-usage/components';
import { PortalPageService } from '@app/modules/portal-usage/pages';

@Component({
	selector: 'app-platform-usage',
	standalone: true,
	imports: [CommonModule, SharedModule, MonthlyActivityGraphModule],
	templateUrl: './platform-usage.component.html',
	styleUrls: ['./platform-usage.component.scss'],
})
export class PlatformUsageComponent implements OnInit {
	@Input() data: IDataModal;
	dataModalService = inject(DataModalService);

	constructor() {}

	ngOnInit(): void {}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}

	@HostListener('document:keydown.escape', ['$event'])
	onEscapePress(event: KeyboardEvent) {
		this.closeModal(true);
	}
}
