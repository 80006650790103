<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-lg-flex">
          <div>
            <h5 class="mb-0">
              {{ isInvoiceType ? 'Invoice #' : 'Reference No.' }} <strong>{{ invoiceNumber }}</strong>
            </h5>
          </div>
        </div>
        <div class="ms-auto my-auto mt-lg-0 mt-4">
          <div class="ms-auto my-auto">
            <!-- <a
              href="javascript:void(0)"
              routerLink="../../"
              class="btn btn-gradient-primary  addnewapp-btn btn-sm export mb-0 mt-sm-0 mt-"
            >
              Close
            </a> -->
            <app-secondary-button
                [isBackButton]="true"
                routerLink="../../"
                [removeMsAuto]="true"
            ></app-secondary-button>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pb-0 pt-0">
        <div>
          <div class="pdf-container">
            <!-- <ngx-extended-pdf-viewer
              [src]="pdfSrc"
              [height]="'100%'"
              [useBrowserLocale]="true"
              [showOpenFileButton]="false"
              [zoom]="'page-actual'"
              [showPropertiesButton]="false"
            >
            </ngx-extended-pdf-viewer> -->
            <object [data]="pdfSrc + '#zoom=150' | safeUrl" type="application/pdf" width="100%" height="700px"></object>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
