import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateAlertBarService {
  private _topBarCwUser = new BehaviorSubject<boolean | string>('');
  public topBarCwUser = this._topBarCwUser.asObservable();

  public init() {
    this._topBarCwUser.next(true);
  }
}
