import { AfterViewInit, Component, OnInit,} from '@angular/core';
import { TicketService } from '@app/modules/service-and-support/ticket.service';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit, AfterViewInit {

  genericForm = this._ticket.getUsageId();

  constructor(
    private _ticket: TicketService
  ) { }


  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

}
