import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { FormUsage } from '@app/shared/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface ICompanyFormUsage{
  "formUsageId": number,
  "srFormId": number,
  "companyId": number[]
}

@Injectable({
  providedIn: 'root',
})
export class FormUsageService {
  searchText = '';
  constructor(private _http: HttpClient) {}
  clearSearch() {
    this.searchText = '';
  }
  getFormUsage(
    Search: string,
    Page: number,
    PageSize: number,
    Column: string,
    Order: string
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}FormUsageMapping?Search=${Search}&Page=${Page}&PageSize=${PageSize}&Column=${Column}&Order=${Order}`
    );
  }

  getFormUsageDetail(id: number) {
    return this._http.get(`${environment.apiBaseUrl}FormUsageMapping/${id}`);
  }

  updateCompanyFormUsage(id: number,body: ICompanyFormUsage){
    return this._http.put(`${environment.apiBaseUrl}FormUsageMapping/${id}`,body)
  }



  getPurposeDropdown() {
    return this._http.get(`${environment.apiBaseUrl}FormUsage/GetFormUsageDropdown`);
  }

  getTitleDropdown(): Observable<any[]> {
    return this._http.get<any[]>(`${environment.apiBaseUrl}SRForm/GetFormDropdown`);
  }

  getFormUsageByUsage(id: FormUsage) {
    return this._http.get(`${environment.apiBaseUrl}FormUsageMapping/UniqueId/${id}`);
  }


  getCompanyFormUsage(
    formUsageId:number,
    search: string = '',
    page: number = 1,
    pageSize: number = 10,
    column: string = 'name',
    order: string = 'ASC',
    companyId: number[],
    srFormId: number[]
  ) {
    return this._http.get<{
      data: any[];
      totalCount: number;
      currentPage: number;
    }>(
      `${
        environment.apiBaseUrl
      }FormUsageMapping/GetCompanyByFormUsageId/${formUsageId}?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&Search=${search}&companyId=${companyId}&srFormId=${srFormId}`
    );
  }

  deleteCompanyFormUsage(id:number){
    return this._http.delete(
      `${
        environment.apiBaseUrl
      }FormUsageMapping/DeleteCompanyMapping/${id}`
    );
  }
}
