import {
	BehaviorSubject,
	catchError,
	filter,
	finalize,
	from,
	Observable,
	of,
	Subject,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { KBWidget } from '@app/shared/interfaces/knowledge-base.interface';
import { toFormData } from '@app/shared/utilities/helper';
import {
	AcronisBackupAllocation,
	AcronisBackupStatus,
	AcronisBackupStatusItems,
	AcronisCloudBackup,
	ApplicationWidget,
	GraphApi,
	KB4Training,
	KB4Users,
	KnowBe4Training,
	MailboxUsage,
	MSTeams,
	MSTeamsAdmin,
	OfflineSite,
	OneDriveUsage,
	OnSiteTicket,
	QuotesWidget,
	ServiceHealthStatus,
	SingletonWidget,
	TablePage,
	TicketWidget,
	WidgetCardList,
	WidgetDynamicTable,
	WidgetList,
} from '@app/shared/interfaces/dashboard.interface';
import { DashboardMainService } from '@app/shared/services/dashboard-main.service';
import { Order } from '@app/shared/interfaces/order.interface';
import { NotificationService } from '@app/core/services/notification.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UserService } from '@app/core/services/user.service';
import { UserTypes } from '@app/shared/constants';
import { SpinnerService } from '@app/core/services/spinner.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class DashboardService extends DashboardMainService {
	test: boolean = false;
	#systemRoleId: UserTypes;
	#isKB4Manager: boolean;
	//loadOnce = false;

	#offlineSiteSub_ = new BehaviorSubject<OfflineSite[] | null>(null);
	offlineSite$ = this.#offlineSiteSub_
		.asObservable()
		.pipe(catchError((e) => of([])));

	#msTeams_ = new BehaviorSubject<MSTeams[] | MSTeamsAdmin[] | null>(null);
	msTeams$ = this.#msTeams_.asObservable().pipe(catchError((e) => of([])));

	#onedriveSub = new BehaviorSubject<OneDriveUsage[] | null>(null);
	onedrive$ = this.#onedriveSub.asObservable().pipe(catchError((e) => of([])));

	#mailboxUsage_ = new BehaviorSubject<MailboxUsage[] | null>(null);
	mailboxUsage$ = this.#mailboxUsage_
		.asObservable()
		.pipe(catchError((e) => of([])));

	#kb4Trainings_ = new BehaviorSubject<KnowBe4Training | KB4Training[] | KB4Users[] | null>(null);
	kb4Trainings$ = this.#kb4Trainings_
		.asObservable()
		.pipe(catchError((e) => of([])));

	#acronicBackupStatus_ = new BehaviorSubject<AcronisBackupStatus | null>(null);
	acronicBackupStatus$ = this.#acronicBackupStatus_
		.asObservable()
		.pipe(catchError((e) => of(null)));

	#acronicBackupAllocation_ = new BehaviorSubject<
		AcronisBackupAllocation[] | null
	>(null);
	acronicBackupAllocation$ = this.#acronicBackupAllocation_
		.asObservable()
		.pipe(catchError((e) => of([])));

	#acronisCloudBackup_ = new BehaviorSubject<AcronisCloudBackup[] | null>(null);
	acronisCloudBackup$ = this.#acronisCloudBackup_
		.asObservable()
		.pipe(catchError((e) => of([])));

	#onsite_ = new BehaviorSubject<OnSiteTicket[] | null>(null);
	onsite$ = this.#onsite_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of([]))
	);

	#quotes_ = new BehaviorSubject<QuotesWidget[] | null>(null);
	quotes$ = this.#quotes_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of([]))
	);

	#application_ = new BehaviorSubject<ApplicationWidget[] | null>(null);
	application$ = this.#application_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of([]))
	);

	#kbwidget_ = new BehaviorSubject<KBWidget[] | null>(null);
	kbwidget$ = this.#kbwidget_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of([]))
	);

	#openTicket_ = new BehaviorSubject<TicketWidget[] | null>(null);
	openTicket$ = this.#openTicket_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of(null))
	);

	#vipTicket_ = new BehaviorSubject<TicketWidget[] | null>(null);
	vipTicket$ = this.#vipTicket_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of(null))
	);

	#currentOrders_ = new BehaviorSubject<Order[] | null>(null);
	currentOrders$ = this.#currentOrders_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of(null))
	);

	#itConfiguration_ = new BehaviorSubject<TablePage | null>(null);
	itConfiguration$ = this.#itConfiguration_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of(null))
	);

	#serviceHealthStatus_ = new BehaviorSubject<ServiceHealthStatus[] | null>(
		null
	);
	serviceHealthStatus$ = this.#serviceHealthStatus_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of(null))
	);

	#graphApi_ = new BehaviorSubject<GraphApi | null>(null);
	graphApi$ = this.#graphApi_.asObservable().pipe(
		filter((data: any) => data !== null),
		catchError((e) => of(null))
	);

	singleton: SingletonWidget = this.#getDefaultSingletonWidget();

	constructor(
		private _toastMessageService: ToastMessageService,
		private _user: UserService,
		private _spinner: SpinnerService
	) {
		super();
	}

	/** Init */

	initMSTeam(test: boolean = false) {
		if (this.singleton?.msTeam === true) return;
		this.test = test;
		this.#systemRoleId = this._user.user?.systemRoleId!;

		//this._spinner.start();
		if (!test)
			this.getMSTeams()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#msTeams_));
		else {
			this.msTeams$ = this.#getMSTeams_test();
			this._spinner.stop();
		}

		this.singleton.msTeam = true;
	}

	initOneDrive(test: boolean = false) {
		if (this.singleton?.oneDrive === true) return;
		this.test = test;
		//systemRoleId - 1=spadmin, 2=clientadmin, 3=user
		this.#systemRoleId = this._user.user?.systemRoleId!;
		//this._spinner.start();
		if (!test)
			this.getOneDriveUsage()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#onedriveSub));
		else {
			this.onedrive$ = this.#getOneDriveUsers_test();
			this._spinner.stop();
		}

		this.singleton.oneDrive = true;
	}

	initMailboxUsage(test: boolean = false) {
		if (this.singleton?.mailboxUsage === true) return;
		this.test = test;

		this.#systemRoleId = this._user.user?.systemRoleId!;
		//this._spinner.start();
		if (!test) {
			this.getMailboxUsage()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#mailboxUsage_));
		} else {
			this.mailboxUsage$ = this.#getMailboxUsage_test();
			this._spinner.stop();
		}

		this.singleton.mailboxUsage = true;
	}

	initOfflineSite(test: boolean = false) {
		if (this.singleton?.offlineSite === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getOfflineSites()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#offlineSiteSub_));
		} else {
			this.offlineSite$ = this.#getOfflineSites_test();
			this._spinner.stop();
		}

		this.singleton.offlineSite = true;
	}

	initOnSite(test: boolean = false) {
		if (this.singleton?.onSite === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getOnSite()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#onsite_));
		} else {
			this.onsite$ = this.#getOnSite_test();
			this._spinner.stop();
		}

		this.singleton.onSite = true;
	}

	initKb4Trainings(test: boolean = false) {
		if (this.singleton?.kb4Trainings === true) return;
		this.test = test;

		this.#systemRoleId = this._user.user?.systemRoleId!;
		this.#isKB4Manager = this._user.isKB4Manager!;
		//this._spinner.start();
		if (!test) {
			this.getKB4Trainings1_1()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#kb4Trainings_));
		} else {
			this.kb4Trainings$ = this.#getKB4Trainings_test();
			this._spinner.stop();
		}

		this.singleton.kb4Trainings = true;
	}

	initAcronisBackupStatus(test: boolean = false) {
		if (this.singleton?.acronisBackupStatus === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getAcronisBackupStatus()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#acronicBackupStatus_));
		} else {
			this.acronicBackupStatus$ = this.#getAcronisBackupStatus_test();
			this._spinner.stop();
		}

		this.singleton.acronisBackupStatus = true;
	}

	initAcronisBackupAllocation(test: boolean = false) {
		if (this.singleton?.acronisBackupAllocation === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getAcronisBackupAllocation()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#acronicBackupAllocation_));
		} else {
			this.acronicBackupAllocation$ = this.#getAcronisBackupAllocation_test();
			this._spinner.stop();
		}

		this.singleton.acronisBackupAllocation = true;
	}

	initAcronisCloudBackup(test: boolean = false) {
		if (this.singleton?.acronisCloudBackup === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getAcronisCloudBackup()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#acronisCloudBackup_));
		} else {
			this.acronisCloudBackup$ = this.#getAcronisCloudBackup_test();
			this._spinner.stop();
		}

		this.singleton.acronisCloudBackup = true;
	}

	initQuotes(test: boolean = false) {
		if (this.singleton?.quotes === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getQuotes()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#quotes_));
		} else {
			this.quotes$ = this.#getQuotes_test();
			this._spinner.stop();
		}

		this.singleton.quotes = true;
	}

	initApplicationWidget(test: boolean = false, override: boolean = false) {
		if (this.singleton?.applicationWidget === true && override === false)
			return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getApplicationWidget()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#application_));
		} else {
			this.application$ = super.getApplicationWidget();
			this._spinner.stop();
		}

		this.singleton.applicationWidget = true;
	}

	initKBWidget(test: boolean = false, override: boolean = false) {
		if (this.singleton?.kbWidget === true && override === false) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getKbWidget()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#kbwidget_));
		} else {
			this.kbwidget$ = super.getKbWidget();
			this._spinner.stop();
		}

		this.singleton.kbWidget = true;
	}

	initOpenTicket(test: boolean = false) {
		if (this.singleton?.openTicket === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getOpenticketsWidget()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#openTicket_));
		} else {
			this.openTicket$ = super.getOpenticketsWidget();
			this._spinner.stop();
		}

		this.singleton.openTicket = true;
	}

	initVIPTicket(test: boolean = false) {
		if (this.singleton?.vipTicket === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getVIPticketsWidget()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#vipTicket_));
		} else {
			this.vipTicket$ = super.getVIPticketsWidget();
			this._spinner.stop();
		}

		this.singleton.vipTicket = true;
	}

	initCurrentOpenOrders(test: boolean = false) {
		if (this.singleton?.currentOpenOrders === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getCurrentOpenOrders()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#currentOrders_));
		} else {
			this.currentOrders$ = super.getCurrentOpenOrders();
			this._spinner.stop();
		}

		this.singleton.currentOpenOrders = true;
	}

	initITConfiguration(test: boolean = false) {
		if (this.singleton?.itConfiguration === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getITConfiguration()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#itConfiguration_));
		} else {
			this.itConfiguration$ = super.getITConfiguration();
			this._spinner.stop();
		}

		this.singleton.itConfiguration = true;
	}

	initServiceHealthStatus(test: boolean = false) {
		if (this.singleton?.serviceHealthStatus === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getServiceHealthStatusOfTools()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#serviceHealthStatus_));
		} else {
			this.serviceHealthStatus$ = super.getServiceHealthStatusOfTools();
			this._spinner.stop();
		}

		this.singleton.serviceHealthStatus = true;
	}

	initGraphApi(test: boolean = false) {
		if (this.singleton?.graphApi === true) return;
		this.test = test;

		//this._spinner.start();
		if (!test) {
			this.getGraphAPI()
				.pipe(finalize(() => this._spinner.stop()))
				.subscribe(this.#getSubject(this.#graphApi_));
		} else {
			this.graphApi$ = super.getGraphAPI();
			this._spinner.stop();
		}

		this.singleton.graphApi = true;
	}

	/*   initAll(){ // this is called by dashboard component
    if(this.loadOnce) return;
    this.initMSTeam();
    this.initOneDrive();
    this.initMailboxUsage();
    this.initOfflineSite();
    this.initOnSite();
    this.initKb4Trainings();
    this.initAcronisBackupStatus();
    this.initAcronisBackupAllocation();
    this.initAcronisCloudBackup();
    this.initQuotes();
    this.initApplicationWidget();
    this.initKBWidget();
    this.initOpenTicket();
    this.initVIPTicket();
    this.initCurrentOpenOrders();
    this.initITConfiguration();
    this.initServiceHealthStatus();
    this.initGraphApi();
    this.loadOnce = true;
  } */

	/** API Calls */

	override getKbWidget(): Observable<KBWidget[]> {
		return this.http.get<KBWidget[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetKBTopicUserQuickAccessList`
		);
	}

	override getApplicationWidget(): Observable<ApplicationWidget[]> {
		return this.http.get<ApplicationWidget[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetApplicationUserQuickAccessList`
		);
	}

	override getOpenticketsWidget(): Observable<any[]> {
		return this.http.get<any[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetOpenTickets`
		);
	}

	override getVIPticketsWidget(): Observable<any[]> {
		return this.http.get<any[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetVIPTickets`
		);
	}

	getWidgetList() {
		return this.http.get<any[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetVIPTickets`
		);
	}

	getCompanyWidgetList() {
		return this.http.get<WidgetList[]>(
			`${environment.apiBaseUrl}UserDashboardCard/getCardList` // on commit use this
			//`${environment.apiBaseUrl}UserDashboardCard/getAllCompanyCards`
		);
	}

	getCardWidgetList() {
		return this.http.get<WidgetCardList[]>(
			`${environment.apiBaseUrl}UserDashboardCard/getCards`
		);
	}

	/*   getKB4Widget(){ //
    return this.http.get<KB4Training[] | KB4Users[]>(
      `${environment.apiBaseUrl}DashboardWidget/GetRemainingKB4Training`
    );
  } */

	getGraphAPIWidget() {
		//
		return this.http.get<any[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetGraphApiSecurityScore`
		);
	}

	override getGraphAPI() {
		//
		return this.http.get<GraphApi>(
			`${environment.apiBaseUrl}DashboardWidget/GetGraphApiSecurityScore`
		);
	}

	getGraphApiCategory(controlCategoryId: number, secureScoreId: string) {
		const categoryId = `controlCategoryId=${controlCategoryId}`;
		const scoreId = `secureScoreId=${secureScoreId}`;
		return this.http.get<any[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetGraphApiControlCategoryScoreList?${categoryId}&${scoreId}`
		);
	}

	override getITConfiguration(
		params:
			| {
					search?: string;
					page?: number;
					pageSize?: number;
					column?: string;
					order?: string;
			  }
			| undefined = undefined
	): Observable<TablePage> {
		let def = { search: '', page: 1, pageSize: 5, column: '', order: '' };
		if (params) def = { ...def, ...params };
		return this.http.get<TablePage>(
			`${environment.apiBaseUrl}Configurations?search=${def.search}&page=${def.page}&pageSize=${def.pageSize}&column=${def.column}&order=${def.order}`
		);
	}

	override getServiceHealthStatusOfTools() {
		return this.http.get<ServiceHealthStatus[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetServiceHealthStatusOftools`
		);
	}

	addCards(ids: Array<number>) {
		//const body = toFormData(ids)
		return this.http.post<WidgetCardList[]>(
			`${environment.apiBaseUrl}UserDashboardCard/addCards`,
			ids
		);
		// save add cards dragged to card list area
	}

	removeCards(ids: Array<number>) {
		// post cards removed from card list area
		return this.http.post<WidgetCardList[]>(
			`${environment.apiBaseUrl}UserDashboardCard/removeCards`,
			ids
		);
	}

	saveDisplayOrder(ids: Array<number>) {
		// save new orders...
		return this.http.post<WidgetCardList[]>(
			`${environment.apiBaseUrl}UserDashboardCard/saveDisplayOrder`,
			ids
		);
	}

	addFeedbackSuggestion(body: any, attachments: File[]): Observable<any> {
		const formData = toFormData(body);
		attachments.forEach((file) => {
			formData.append('Files', file);
		});

		return this.http.post(
			`${environment.apiBaseUrl}FeedbackSuggestion`,
			formData
		);
	}

	createFeedbackTicket(id: number) {
		const body = { feedbackSuggestionId: id };
		return this.http.post(
			`${environment.apiBaseUrl}FeedbackSuggestion/CreateTicket`,
			body
		);
	}

	getOnSiteTickets() {
		return this.http.get<OnSiteTicket[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetOnSiteScheduleTickets`
		); // ?page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}
	}

	override getCurrentOpenOrders() {
		return this.http.get<Order[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetCurrentOpenOrders`
		);
	}

	getOfflineSites() {
		return this.http.get<OfflineSite[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetOfflineSiteServers`
		);
	}

	getAcronisBackupStatus() {
		// add actual API call here // /api/ACR_ServerBackupStatus/GetStatus?page=page1&pageSize=10
		return this.http.get<AcronisBackupStatus>(
			`${environment.apiBaseUrl}ACR_ServerBackupStatus/GetStatus?page=1&pageSize=10`
		);
	}

	getMailboxUsage() {
		// add actual api call here: /api/DashboardWidget/GetMSMailboxUsage
		return this.http.get<OneDriveUsage[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetMSMailboxUsage`
		);
	}

	getAcronisBackupAllocation() {
		return this.http.get<AcronisBackupAllocation[]>(
			`${environment.apiBaseUrl}ACR_TenantsBackupAllocation/GetAllocation`
		);
	}

	getAcronisCloudBackup() {
		//  add actual api call here: ACR_TenantsBackupAllocation/GetCloudBackup
		return this.http.get<AcronisBackupAllocation[]>(
			`${environment.apiBaseUrl}ACR_TenantsBackupAllocation/GetCloudBackup`
		);
	}

	getMSTeams() {
		return this.http.get<MSTeams | MSTeamsAdmin[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetMSTeamsUsage`
		);
	}

	getQuotes() {
		return this.http.get<QuotesWidget[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetQuotesForDashboardWidget`
		);
	}

	/**setters and getters */
	get systemRoleId() {
		return this.#systemRoleId;
	}

	get isKB4Manager() {
		return this.#isKB4Manager;
	}

	#getSubject(subj: Subject<any>) {
		return {
			next: (v: any) => subj.next(v),
			error: (e: any) => subj.error(e),
			//complete: ()=>subj.complete()
		};
	}

	/**test data / dummy */

	#getMSTeams_test(): Observable<MSTeams[] | MSTeamsAdmin[]> {
		return of<MSTeams[] | MSTeamsAdmin[]>(
			generateDummyData_MSTeams(this.#systemRoleId)
		);
	}

	getOneDriveUsage() {
		// put code here when there's an API
		return this.http.get<OneDriveUsage[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetMSOneDriveUsage`
		);
	}

	transformMSTeamsData(data: any, format: 'stats' | 'users' = 'stats') {
		return transformMSTeams_data(data, format);
	}

	transformOneDriveUsageData(data: OneDriveUsage[]) {
		if (data.length < 1) return null;

		const usage = data[0];

		return [
			{
				allocation: 'Used',
				val: parseFloat(usage.storageUsed.replace(' GB', '')),
			},
			{
				allocation: 'Allocated',
				val: parseFloat(usage.allocatedStorage.replace(' GB', '')),
			},
		];
	}

	getOnSite() {
		return this.http.get<OnSiteTicket[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetOnSiteScheduleTickets`
		); // ?page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}
	}

	#getDefaultSingletonWidget(): SingletonWidget {
		return {
			msTeam: false,
			oneDrive: false,
			mailboxUsage: false,
			offlineSite: false,
			onSite: false,
			kb4Trainings: false,
			acronisBackupStatus: false,
			acronisBackupAllocation: false,
			acronisCloudBackup: false,
			quotes: false,
			applicationWidget: false,
			kbWidget: false,
			openTicket: false,
			vipTicket: false,
			currentOpenOrders: false,
			itConfiguration: false,
			serviceHealthStatus: false,
			graphApi: false,
		};
	}

	#getOneDriveUsage_test(): Observable<OneDriveUsage[]> {
		return of<OneDriveUsage[]>([
			{
				id: 1,
				fullName: 'John Doe',
				fileCount: 231,
				storageUsed: '0.8 Gb',
				allocatedStorage: '5 Gb',
				lastUpdated: new Date(),
			},
		]);
	}

	#getOneDriveUsers_test(): Observable<OneDriveUsage[]> {
		return of<OneDriveUsage[]>([
			{
				id: 1,
				fullName: 'John Doe',
				fileCount: 231,
				storageUsed: '0.8 GB',
				allocatedStorage: '5 GB',
				lastUpdated: new Date(),
			},
			{
				id: 2,
				fullName: 'Jane Doe',
				fileCount: 211,
				storageUsed: '1.2 GB',
				allocatedStorage: '5 GB',
				lastUpdated: new Date(),
			},
		]);
	}

	#getMailboxUsage_test(): Observable<MailboxUsage[]> {
		return of<MailboxUsage[]>([
			{
				id: 1,
				fullName: 'Lindaa Brotherton',
				itemCount: 5,
				storageUsed: '0.00 GB',
				allocatedStorage: '50 GB',
				lastUpdated: new Date(),
			},
		]);
	}

	#getOfflineSites_test() {
		return of<OfflineSite[]>([
			{
				id: 1,
				name: 'Dummy data',
				site: 'Test site',
				status: 'offline',
				type: 'Workstation',
			},
		]);
	}

	#getOnSite_test(): Observable<OnSiteTicket[]> {
		return of<OnSiteTicket[]>([
			{
				ticketId: 1,
				title: 'dummy ticket',
				personName: 'John Doe',
				dateStart: new Date().toLocaleString(),
				dateEnd: new Date().toLocaleString(),
			},
		]);
	}

	getKB4Trainings() {
		return this.http.get<KB4Training[] | KB4Users[]>(
			`${environment.apiBaseUrl}DashboardWidget/GetRemainingKB4Training`
		);
	}

	getKB4Trainings1_1() {
		const headers = new HttpHeaders()
			.set('x-api-version', '1.1');
		return this.http.get<KnowBe4Training>(
			`${environment.apiBaseUrl}DashboardWidget/GetRemainingKB4Training`,
			{headers}
		);
	}

	sendReminderKB4Trainings(userId?: number) {
		const headers = new HttpHeaders()
			.set('x-api-version', '1.1');
		return this.http.post(
			`${environment.apiBaseUrl}DashboardWidget/SendReminderKB4Training`,
			userId ? {userId} : {},
			{headers}
		);
	}

	#getKB4Trainings_test(): Observable<KB4Training[] | KB4Users[]> {
		if (this.#systemRoleId < 3 || this.#isKB4Manager) {
			return of<KB4Users[]>(getUserTraining());
		} else {
			return of<KB4Training[]>(getTrainings());
		}
	}

	#getAcronisBackupStatus_test(): Observable<AcronisBackupStatus> {
		return of<AcronisBackupStatus>(getAcronisBackupStatus());
	}

	#getAcronisBackupAllocation_test(): Observable<AcronisBackupAllocation[]> {
		return of<AcronisBackupAllocation[]>(getAcronisBackupAllocation());
	}

	#getAcronisCloudBackup_test(): Observable<AcronisCloudBackup[]> {
		return of<AcronisCloudBackup[]>(getAcronisCloudBackup());
	}

	#getQuotes_test(): Observable<QuotesWidget[]> {
		return of<QuotesWidget[]>([
			{
				id: 1,
				category: 'cat1',
				name: 'John Doe',
				quoteDate: new Date().toLocaleString(),
				total: 10,
				status: 'Ok',
				quoteURL: null,
				color: 'blue',
			},
		]);
	}
}

/** These are for dummy test / data */
function generateDummyData_MSTeams(role: number): MSTeams[] | MSTeamsAdmin[] {
	if (role < 3)
		return [
			{
				userId: '1',
				firstName: 'Jane',
				lastName: 'Doe',
				messageCount: 300,
				callCount: 130,
				meetingCount: 55,
				days: 30,
				lastUpdated: new Date().toJSON(),
				companyId: '1',
			},
		];
	else
		return [
			{
				id: 1,
				fullName: 'Jane Doe',
				messageCount: 300,
				callCount: 130,
				meetingCount: 55,
				days: 30,
				lastUpdated: new Date().toJSON(),
			},
		];
}

function transformMSTeams_data(
	data: any | any[],
	format: 'stats' | 'users' = 'stats'
) {
	let usage = [];
	if (format == 'stats') {
		for (const props in data[0]) {
			if (props.toLowerCase().includes('count')) {
				usage.push({
					activity: props.replace('Count', ''),
					count: data[0][props],
				});
			}
		}
	} else if (format == 'users') {
		for (let i = 0; i < data.length; i++) {
			usage.push({
				...data[i],
				...{
					//fullName:data[i].firstName + ' ' + data[i].lastName,
					lastUpdated: new Date(data[i].lastUpdated).toLocaleDateString(),
				},
			});
		}
	}
	return usage;
}

function getUserTraining() {
	let data = [];
	for (let i = 0; i <= 5; i++) {
		data.push({
			id: i,
			firstName: 'Linda' + (i + 1),
			lastName: 'Test',
			managerFirstName: 'Sample',
			managerLastName: 'Manager',
			trainingList: [
				{
					enrollmentDate: new Date(),
					id: 2,
					moduleId: 2,
					moduleName:
						'Sample Knowbe4 module with testing how long the title could be',
				},
				{
					enrollmentDate: new Date(),
					id: 3,
					moduleId: 3,
					moduleName:
						'Another Sample Knowbe4 module with testing how long the title could be',
				},
			],
		});
	}
	return data;
}

function getTrainings() {
	let data = [];
	for (let i = 0; i <= 5; i++) {
		data.push({
			id: i,
			firstName: 'Ralph' + (i + 1),
			lastName: 'Recto',
			enrollmentDate: new Date().toLocaleString(),
			knowBe4Link: '',
			managerFirstName: 'Linda',
			managerLastName: 'Test',
			moduleId: 1,
			moduleName: 'Security Purposes ' + (i + 1),
			status: 'Not finished',
			userId: 1,
		});
	}
	return data;
}

function getAcronisBackupStatus() {
	return {
		items: [
			{
				companyId: 1,
				deviceName: 'BCF-SRV-DC5.bcf',
				status: 'Ok',
				lastSuccessTime: new Date(),
				lastRunTime: new Date(),
				nextRunTime: new Date(),
			},
		],
		page: {
			number: 1,
			size: 10,
			totalCount: 1,
		},
	};
}

function getAcronisBackupAllocation(): AcronisBackupAllocation[] {
	return [
		{
			allocation: 'Local Backup',
			count: 4,
			usage: '1566 GB',
			size: '12903 GB',
			result: 'No Backup',
		},
		{
			allocation: 'Cloud Backup',
			count: 4,
			usage: '1991 GB',
			size: 'Unlimited GB',
			result: '1991 GB / Unlimited GB',
		},
		{
			allocation: 'Total protected workloads',
			count: 4,
			usage: null,
			size: null,
			result: '4',
		},
	];
}

function getAcronisCloudBackup() {
	return [
		{
			name: '',
			tenant_id: 2647070,
			uiName: null,
			usage_name: 'Microsoft 365 seats',
			value: 0,
		},
		{
			name: '',
			tenant_id: 2647070,
			uiName: null,
			usage_name: 'Microsoft 365 Sharepoint Online',
			value: 0,
		},
		{
			name: '',
			tenant_id: 2647070,
			uiName: null,
			usage_name: 'Microsoft 365 Teams',
			value: 0,
		},
		{
			name: '',
			tenant_id: 2647070,
			uiName: null,
			usage_name: 'Microsoft 365 shared seats',
			value: 0,
		},
	];
}
