import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';
import { MenuPermissionConstant } from '@app/shared/constants';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { Observable, Subscription } from 'rxjs';
import { SideBarService } from '../../side-bar/side-bar.service';

@Component({
	selector: '[app-sub-menu-accordion]',
	templateUrl: './sub-menu-accordion.component.html',
	styleUrls: ['./sub-menu-accordion.component.scss'],
})
export class SubMenuAccordionComponent implements OnInit, OnDestroy {
	@Input() sideMenuData: RoleMenuInterface;
	@Input() index: number;
	@Input() menuId: number;
	@Input() activeMenu$: Observable<number>;
	@Output() selectedMenu = new EventEmitter<number>();
	id: number | string;
	userId: number;

	activeMenu: number;
	#subscription = new Subscription();

	constructor(
		public _sideBarService: SideBarService,
		private router: Router,
		private _companiesService: CompaniesService,
		private _contactsService: ContactsService,
		public _route: ActivatedRoute
	) {}

	ngOnDestroy(): void {
		this.#subscription.unsubscribe();
	}

	ngOnInit(): void {
		// if(this.menuId === MenuPermissionEnum.CONTACTS_SPADMIN || this.menuId === MenuPermissionEnum.CONTACTS_CLIENTADMIN){
		if (
			this.menuId === MenuPermissionConstant.Contacts[1] ||
			this.menuId === MenuPermissionConstant.Contacts[2] ||
			this.menuId ===
				MenuPermissionConstant.Companies.subMenus!['contactSettings']![
					'subMenus'
				]!['contacts']![1]
		) {
			this.id = this._contactsService.subUserId!;
		} else if (this.menuId === MenuPermissionConstant.Companies[1]) {
			this.id = Number(this._companiesService.subCompanyId!);
		}

		this.#subscription.add(
			this.activeMenu$.subscribe((v) => {
				this.activeMenu = v;
			})
		);
	}

	goTo(url: string) {
		let _userId = this._contactsService.subUserId!.replace(/[^\w\s]/gi, '');
		let newRoute = url.replace('userId', _userId);
		if (this._route.snapshot.data['fromCompany'] === true) {
			this.router.navigate([`../${newRoute}`], { relativeTo: this._route });
		} else {
			this.router.navigateByUrl(newRoute);
		}
	}

	selectMenu(id: number) {
		this.selectedMenu.emit(id);
	}
}
