<div class="edit-wrap">
  <label for=""><ng-content></ng-content></label>

  <multi-select-input
  class="form-control full-width"
  placeholder="Select Users"
  [(ngModel)]="chosenUsers"
  [options]="filteredUsers"
  [optionKeys]="{value:'id', label:'name'}"
  [disabled]="!users"
  [customClass]="'company-permission-autocomplete'"
  (onChange)="getChosenUserId()"
  ></multi-select-input>

</div>
