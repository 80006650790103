import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { CwpodVerificationService } from '../cwpod-verification.service';

@Component({
  selector: 'app-cwpod-user-validation',
  templateUrl: './cwpod-user-validation.component.html',
  styleUrls: ['./cwpod-user-validation.component.scss'],
  providers: [CwpodVerificationService]
})
export class CwpodUserValidationComponent implements OnInit {

  message:string = '';
  id:string = '';
  ticketId = '';
  status:string = '';
  cssClass = {
    screen:'rejected rejected-screen',
    form: 'rejected-form',
    message: 'rejected-message',
  }

  get url(){
    return environment.redirectUri
  }

  constructor(
    private _podVerification: CwpodVerificationService,
    private _route: ActivatedRoute,
    private _cd: ChangeDetectorRef
  ){ 
    this.id = this._route.snapshot.queryParams['id'];
    this.status = this._route.snapshot.queryParams['status'];
    this.ticketId = this._route.snapshot.queryParams['ticketId'];
  }

  ngOnInit() {
    this._podVerification.validationByEmail(this.id, this.status).subscribe(
      {
        next: (response) => {
          this._setCSSClass(this.status);
        },
        error: (err:any)=>{
          this.message = err.message;
        }
      }
    )
  }

  _setCSSClass(status:string){
    let screenCss = '', formCss = '', messageCss='';
    
    if(status == 'approved'){
      screenCss = 'approved approved-screen';
      formCss = 'approved-form';
      messageCss = 'approved-message';
    }else{
      screenCss = 'rejected rejected-screen'
      formCss = 'rejected-form'
      messageCss = 'rejected-message';
    }

    this.cssClass.screen = screenCss;
    this.cssClass.form = formCss;
    this.cssClass.message = messageCss;
    this._cd.markForCheck();
  }

}
