import { Component, Inject, InjectionToken, OnInit } from '@angular/core';

export const tooltipData = new InjectionToken<TooltipData>('');

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  /** Use ErrorDirective for error tooltip and add new directive for other tooltip */

  constructor(@Inject(tooltipData) public data: TooltipData) { }

}

export interface TooltipData {
  title?: string,
  message: string,
  type?: 'error', //TODO: add more type for other tooltip
  position?: 'top',
  isHide?: boolean
}
