import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store } from '@ngrx/store';
import { topBarUpdateAction } from './store/top-bar.action';

@Directive({
  selector: '[sideNavBtn]'
})
export class SideNavBtnDirective {

  @HostBinding('class.open') isOpen = true;
  constructor(public elRef: ElementRef, private renderer: Renderer2,private store: Store<AppStateInterface>) {  }

  @Input('pages') pages: number | undefined = undefined;


  @HostListener('click') toggleOpen(){
    this.isOpen = !this.isOpen;

    if(this.isOpen === false){
      this.renderer.addClass(document.body, 'g-sidenav-hidden');
      this.renderer.removeClass(document.body, 'g-sidenav-pinned');
    }else{
      this.renderer.removeClass(document.body, 'g-sidenav-hidden');
      this.renderer.addClass(document.body, 'g-sidenav-pinned');
    }
    this.store.dispatch(topBarUpdateAction({sidebar: {isOpen: this.isOpen}}));
  }
}
