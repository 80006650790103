<div class="card-2 q-mb-24">
	<div class="card-header-2">
		<h3 class="q-mr-auto">Quotes</h3>

		<app-parent-child-input-selector
			*ngIf="hasChildCompanies"
			url="ParentChildCompany/GetCurrentChildren?access=3"
			(onChangeSelector)="childCompanyChanged($event)"
		></app-parent-child-input-selector>

		<input
			type="text"
			class="font-btn q-m-0"
			placeholder="Search"
			[formControl]="search"
			#searchTextbox
		/>

		<app-tertiary-button
			[isFilterOn]="isFilterOn"
			[isFilterToggle]="true"
		></app-tertiary-button>

			<button
				class="btn-2 btn-2-primary imp-disabled-op"
				type="button"
				id="dropdownMenuButton1"
				data-bs-toggle="dropdown"
				aria-expanded="false"
				#exportEl
			>
				Export As
				<mat-icon
					[svgIcon]="
						exportEl.classList.contains('show') ? 'chevron-up' : 'chevron-down'
					"
				></mat-icon>
			</button>
			<ul
				class="dropdown-menu q-shadow-1"
				aria-labelledby="dropdownMenuButton1"
			>
				<li>
					<a
						class="dropdown-item"
						href="javascript:void(0)"
						(click)="export(applicationType.CSV)"
					>
						CSV file
					</a>
				</li>
				<li>
					<a
						class="dropdown-item"
						href="javascript:void(0)"
						(click)="export(applicationType.EXCEL)"
					>
						Excel file
					</a>
				</li>
			</ul>
	</div>

	<div id="demo" class="collapse">
		<div [formGroup]="form" class="table-filter-2 tf-col-4">
			<div class="filter-item">
				<label>Start Date</label>
				<date-input
					formControlName="queryStartDate"
					max="{{ form.controls.queryEndDate.value | date : 'yyyy-MM-dd' }}"
					[isString]="true">
				</date-input>
			</div>

			<div class="filter-item">
				<label>End Date</label>
				<date-input
					formControlName="queryEndDate"
					min="{{ form.controls.queryStartDate.value | date : 'yyyy-MM-dd' }}"
					[isString]="true">
				</date-input>
			</div>

			<div class="filter-item">
				<label>Quote Type</label>
				<multi-select-input-2
					[options]="quoteTypes"
					formControlName="typeIds"
					[autoClosePerSelect]="true"
					placeholder="- Select Type -"
					[resetVal]="resetVal"
					[allowSelectAll]="false"
					[hideIcon]="true"
				>
				</multi-select-input-2>
			</div>

			<div class="filter-item">
				<label>Status</label>
				<multi-select-input-2
					[options]="quoteStatus"
					formControlName="statusIds"
					[autoClosePerSelect]="true"
					placeholder="- Select Status -"
					[resetVal]="resetVal"
					[allowSelectAll]="false"
					[hideIcon]="true"
				>
				</multi-select-input-2>
			</div>

			<div class="filter-item filter-actions">
				<app-clear-filter></app-clear-filter>
			</div>
		</div>
	</div>

	<app-new-table-shared
    loadingText="Fetching Quotes..."
		[searchFilters]="searchFilters"
		[quickFilter]="quickFilter"
		(emitTotalItems)="onEmitTotalItems($event)"
	></app-new-table-shared>
</div>
