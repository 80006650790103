import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropDirective } from './drag-drop.directive';
import { ImageErrorDetectionDirective } from './image-error-detection/image-error-detection.directive';
import { DragDropFileDirective } from '../components/messaging/view-ticket/reply/drag-drop.directive';

@NgModule({
  declarations: [DragDropDirective, DragDropFileDirective, ImageErrorDetectionDirective],
  imports: [CommonModule],
  exports: [DragDropDirective, ImageErrorDetectionDirective,DragDropFileDirective],
})
export class DragDropCustomModule {}
