import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewTicketModule } from './view-ticket/view-ticket.module';
import { ViewOrderModule } from './view-order/view-order.module';




@NgModule({
  imports: [
    CommonModule,
    ViewTicketModule,
    ViewOrderModule
  ],
  exports:[
    ViewTicketModule,
    ViewOrderModule
  ]
})
export class SharedMessagingModule { }
