import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import {
	DevicesTicketsFilter,
	OnbTicketsFilter,
	ServiceCategoryTicketsFilter,
	ServiceTicketsFilter,
	SitesTicketsFilter,
	TimeSpentFilter,
	TotalTicketsFilter,
	UserRequestSourceFilter,
	UsersTicketsFilter,
	VulnerabilitiesFilter,
} from '@app/shared/interfaces/total-tickets.interface';
import { environment } from 'environments/environment';
import {
	BehaviorSubject,
	ReplaySubject,
	debounceTime,
	finalize,
	of,
	tap,
	timeout,
} from 'rxjs';
import { DashboardReportTab } from './dashboard-report.enum';

@Injectable({
	providedIn: 'root',
})
export class DashboardReportService {
	public dbModal: DashboardReportTab;
	public timeSpentStatus: string;
	public timeSpentStatusField: string;

	private _tableData = new ReplaySubject<any[]>(1);
	tableData$ = this._tableData.asObservable();

	private _tableItems = new ReplaySubject<number>(1);
	tableItems$ = this._tableItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	private _onbtableData = new ReplaySubject<any[]>(1);
	onbtableData$ = this._onbtableData.asObservable();

	private _onbtableItems = new ReplaySubject<number>(1);
	onbtableItems$ = this._onbtableItems.asObservable();

	private _onbisLoading = new BehaviorSubject(false);
	onbisLoading$ = this._onbisLoading.asObservable();

	set onbisLoading(isLoading: boolean) {
		this._onbisLoading.next(isLoading);
	}

	private _servTickettableData = new ReplaySubject<any[]>(1);
	servTickettableData$ = this._servTickettableData.asObservable();

	private _servTickettableItems = new ReplaySubject<number>(1);
	servTickettableItems$ = this._servTickettableItems.asObservable();

	private _servTicketisLoading = new BehaviorSubject(false);
	servTicketisLoading$ = this._servTicketisLoading.asObservable();

	set servTicketisLoading(isLoading: boolean) {
		this._servTicketisLoading.next(isLoading);
	}

	private _servTimestableData = new ReplaySubject<any[]>(1);
	servTimestableData$ = this._servTimestableData.asObservable();

	private _servTimestableItems = new ReplaySubject<number>(1);
	servTimestableItems$ = this._servTimestableItems.asObservable();

	private _servTimesisLoading = new BehaviorSubject(false);
	servTimesisLoading$ = this._servTimesisLoading.asObservable();

	set servTimesisLoading(isLoading: boolean) {
		this._servTimesisLoading.next(isLoading);
	}

	//
	private _deviceTickettableData = new ReplaySubject<any[]>(1);
	deviceTickettableData$ = this._deviceTickettableData.asObservable();

	private _deviceTickettableItems = new ReplaySubject<number>(1);
	deviceTickettableItems$ = this._deviceTickettableItems.asObservable();

	private _deviceTicketisLoading = new BehaviorSubject(false);
	deviceTicketisLoading$ = this._deviceTicketisLoading.asObservable();

	set deviceTicketisLoading(isLoading: boolean) {
		this._deviceTicketisLoading.next(isLoading);
	}

	//
	private _userTickettableData = new ReplaySubject<any[]>(1);
	userTickettableData$ = this._userTickettableData.asObservable();

	private _userTickettableItems = new ReplaySubject<number>(1);
	userTickettableItems$ = this._userTickettableItems.asObservable();

	private _userTicketisLoading = new BehaviorSubject(false);
	userTicketisLoading$ = this._userTicketisLoading.asObservable();

	set userTicketisLoading(isLoading: boolean) {
		this._userTicketisLoading.next(isLoading);
	}

	//
	private _siteTickettableData = new ReplaySubject<any[]>(1);
	siteTickettableData$ = this._siteTickettableData.asObservable();

	private _siteTickettableItems = new ReplaySubject<number>(1);
	siteTickettableItems$ = this._siteTickettableItems.asObservable();

	private _siteTicketisLoading = new BehaviorSubject(false);
	siteTicketisLoading$ = this._siteTicketisLoading.asObservable();

	set siteTicketisLoading(isLoading: boolean) {
		this._siteTicketisLoading.next(isLoading);
	}

	//
	private _reqSourceTickettableData = new ReplaySubject<any[]>(1);
	reqSourceTickettableData$ = this._reqSourceTickettableData.asObservable();

	private _reqSourceTickettableItems = new ReplaySubject<number>(1);
	reqSourceTickettableItems$ = this._reqSourceTickettableItems.asObservable();

	private _reqSourceTicketisLoading = new BehaviorSubject(false);
	reqSourceTicketisLoading$ = this._reqSourceTicketisLoading.asObservable();

	set reqSourceTicketisLoading(isLoading: boolean) {
		this._reqSourceTicketisLoading.next(isLoading);
	}

	//
	private _sattableData = new ReplaySubject<any[]>(1);
	sattableData$ = this._sattableData.asObservable();

	private _sattableItems = new ReplaySubject<number>(1);
	sattableItems$ = this._sattableItems.asObservable();

	private _satisLoading = new BehaviorSubject(false);
	satisLoading$ = this._satisLoading.asObservable();

	set satisLoading(isLoading: boolean) {
		this._satisLoading.next(isLoading);
	}

	//
	private _timeSpentTickettableData = new ReplaySubject<any[]>(1);
	timeSpentTickettableData$ = this._timeSpentTickettableData.asObservable();

	private _timeSpentTickettableItems = new ReplaySubject<number>(1);
	timeSpentTickettableItems$ = this._timeSpentTickettableItems.asObservable();

	private _timeSpentTicketisLoading = new BehaviorSubject(false);
	timeSpentTicketisLoading$ = this._timeSpentTicketisLoading.asObservable();

	set timeSpentTicketisLoading(isLoading: boolean) {
		this._timeSpentTicketisLoading.next(isLoading);
	}

	//
	private _vulnerabilitiesTableData = new ReplaySubject<any[]>(1);
	vulnerabilitiesTableData$ = this._vulnerabilitiesTableData.asObservable();

	private _vulnerabilitiesTableItems = new ReplaySubject<number>(1);
	vulnerabilitiesTableItems$ = this._vulnerabilitiesTableItems.asObservable();

	private _vulnerabilitiesIsLoading = new BehaviorSubject(false);
	vulnerabilitiesIsLoading$ = this._vulnerabilitiesIsLoading.asObservable();

	set vulnerabilitiesIsLoading(isLoading: boolean) {
		this._vulnerabilitiesIsLoading.next(isLoading);
	}

	//
	private _atRiskUsersTableData = new ReplaySubject<any[]>(1);
	atRiskUsersTableData$ = this._atRiskUsersTableData.asObservable();

	private _atRiskUsersTableItems = new ReplaySubject<number>(1);
	atRiskUsersTableItems$ = this._atRiskUsersTableItems.asObservable();

	private _atRiskUsersIsLoading = new BehaviorSubject(false);
	atRiskUsersIsLoading$ = this._atRiskUsersIsLoading.asObservable();

	set atRiskUsersIsLoading(isLoading: boolean) {
		this._atRiskUsersIsLoading.next(isLoading);
	}

	//
	private _atRiskDevicesTableData = new ReplaySubject<any[]>(1);
	atRiskDevicesTableData$ = this._atRiskDevicesTableData.asObservable();

	private _atRiskDevicesTableItems = new ReplaySubject<number>(1);
	atRiskDevicesTableItems$ = this._atRiskDevicesTableItems.asObservable();

	private _atRiskDevicesIsLoading = new BehaviorSubject(false);
	atRiskDevicesIsLoading$ = this._atRiskDevicesIsLoading.asObservable();

	set atRiskDevicesIsLoading(isLoading: boolean) {
		this._atRiskDevicesIsLoading.next(isLoading);
	}

	//
	private _recentSecurityTicketsTableData = new ReplaySubject<any[]>(1);
	recentSecurityTicketsTableData$ = this._recentSecurityTicketsTableData.asObservable();

	private _recentSecurityTicketsTableItems = new ReplaySubject<number>(1);
	recentSecurityTicketsTableItems$ = this._recentSecurityTicketsTableItems.asObservable();

	private _recentSecurityTicketsIsLoading = new BehaviorSubject(false);
	recentSecurityTicketsIsLoading$ = this._recentSecurityTicketsIsLoading.asObservable();

	set recentSecurityTicketsIsLoading(isLoading: boolean) {
		this._recentSecurityTicketsIsLoading.next(isLoading);
	}

	http = inject(HttpClient);

	getNextAndPrevious(ticketId: number, filters: any) {
		const params = this.constructBody(filters);
		console.log(params);
		return this.http.post(
			`${environment.apiBaseUrl}Tickets/NextAndPreviousForServiceReport/${ticketId}/${this.dbModal}`,
			params
		);
	}

	currentFilters: any;

	constructBody(filters: any) {
		let params = {
			page: filters.page,
			pageSize: filters.pageSize,
			order: filters.order,
			column: filters.column,
			isVIP: filters.isVIP,
		};
		switch (this.dbModal) {
			case DashboardReportTab.TotalTickets:
				return {
					...params,
					totalTicketsDetailMonth: filters.month,
				};
			case DashboardReportTab.ServiceTicket:
				return {
					...params,
					categoryId: filters.categoryId,
					subCategoryId: filters.subCategoryId,
				};
			case DashboardReportTab.UserTicket:
				return {
					...params,
					contactId: filters.contactId,
				};
			case DashboardReportTab.SiteTicket:
				return {
					...params,
					siteId: filters.siteId,
				};
			case DashboardReportTab.ServiceTimes:
				return {
					...params,
					toDate: filters.toDate,
					fromDate: filters.fromDate,
				};
			case DashboardReportTab.DeviceTicket:
				return {
					...params,
					deviceId: filters.deviceId,
				};
			case DashboardReportTab.RequestSource:
				return {
					...params,
					sourceId: filters.sourceId,
				};
			case DashboardReportTab.Onboarding:
				return {
					...params,
					month: filters.month,
					year: filters.year,
					metric: filters.metric,
				};
			case DashboardReportTab.SatisfactionScore:
				return {
					...params,
				};
			case DashboardReportTab.TimeSpent:
				return {
					...params,
					statusName: filters.statusName,
					fromDate: filters.fromDate,
					toDate: filters.toDate,
				};
				case DashboardReportTab.Vulnerabilities:
					return {
						...params,
						typeName: filters.typeName,
					};
			case DashboardReportTab.AtRiskUsers:
				return {
					...params,
				};
			default:
				return {};
		}
	}

	resetTotalTickets() {
		this._tableData.next([]);
	}

	transformToQueryString(params: { [key: string]: any }): string {
		return Object.keys(params)
			.map((key) => {
				const value = params[key];
				return `${key}=${value}`;
			})
			.join('&');
	}

	downloadTicket(url: string, options: any) {
		let params = this.transformToQueryString(options);
		return this.http.get(
			`${environment.apiBaseUrl}DashboardReport/${url}?${params}`
		);
	}

	getSatisfactionScore(options: TotalTicketsFilter) {
		{
			this.satisLoading = true;
			this._sattableData.next([]);
			this._sattableItems.next(0);
			return this.http
				.get(
					`${environment.apiBaseUrl}DashboardReport/GetSatisfactionScoreDetails?isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
				)
				.pipe(
					tap((res: any) => {
						this._sattableData.next(res.data);
						this._sattableItems.next(res.totalCount);
						this.currentFilters = options;
					}),
					finalize(() => {
						this.satisLoading = false;
					})
				);
		}
	}

	getTicketData(options: TotalTicketsFilter) {
		{
			this.isLoading = true;
			this._tableData.next([]);
			this._tableItems.next(0);
			return this.http
				.get(
					`${environment.apiBaseUrl}DashboardReport/GetTotalTicketsDetailList?month=${options.month}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
				)
				.pipe(
					tap((res: any) => {
						this._tableData.next(res.data);
						this._tableItems.next(res.totalCount);
						this.currentFilters = options;
					}),
					finalize(() => {
						this.isLoading = false;
					})
				);
		}
	}

	getOnbData(options: OnbTicketsFilter) {
		{
			this.onbisLoading = true;
			this._onbtableData.next([]);
			this._onbtableItems.next(0);

			let url = `${environment.apiBaseUrl}DashboardReport/GetEmployeesOnboardedAndOffboardedDetail?metric=${options.metric}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`;
			if (options.month) url = url + `&month=${options.month}`;
			if (options.year) url = url + `&year=${options.year}`;
			return this.http.get(url).pipe(
				tap((res: any) => {
					this._onbtableData.next(res.data);
					this._onbtableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.onbisLoading = false;
				})
			);
		}
	}

	getServiceTimesData(options: ServiceTicketsFilter) {
		{
			this.servTimesisLoading = true;
			this._servTimestableData.next([]);
			this._servTimestableItems.next(0);
			return this.http
				.get(
					`${environment.apiBaseUrl}DashboardReport/GetServiceTimesDetail?toDate=${options.toDate}&fromDate=${options.fromDate}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
				)
				.pipe(
					tap((res: any) => {
						this._servTimestableData.next(res.data);
						this._servTimestableItems.next(res.totalCount);
						this.currentFilters = options;
					}),
					finalize(() => {
						this.servTimesisLoading = false;
					})
				);
		}
	}

	getServiceTicketData(options: ServiceCategoryTicketsFilter) {
		{
			this.servTicketisLoading = true;
			this._servTickettableData.next([]);
			this._servTickettableItems.next(0);
			return this.http
				.get(
					`${environment.apiBaseUrl}DashboardReport/GetServiceTicketCategoriesDetail?categoryId=${options.categoryId}&subCategoryId=${options.subCategoryId}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
				)
				.pipe(
					tap((res: any) => {
						this._servTickettableData.next(res.data);
						this._servTickettableItems.next(res.totalCount);
						this.currentFilters = options;
					}),
					finalize(() => {
						this.servTicketisLoading = false;
					})
				);
		}
	}

	getDeviceTicketData(options: DevicesTicketsFilter) {
		{
			this.deviceTicketisLoading = true;
			this._deviceTickettableData.next([]);
			this._deviceTickettableItems.next(0);
			return this.http
				.get(
					`${environment.apiBaseUrl}DashboardReport/GetDevicesTicketSubmissionDetail?deviceId=${options.deviceId}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
				)
				.pipe(
					tap((res: any) => {
						this._deviceTickettableData.next(res.data);
						this._deviceTickettableItems.next(res.totalCount);
						this.currentFilters = options;
					}),
					finalize(() => {
						this.deviceTicketisLoading = false;
					})
				);
		}
	}

	getUserTicketData(options: UsersTicketsFilter) {
		this.userTicketisLoading = true;
		this._userTickettableData.next([]);
		this._userTickettableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetUsersTicketSubmissionDetail?contactId=${options.contactId}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					console.log(res.data);
					console.log(res);
					this._userTickettableData.next(res.data);
					this._userTickettableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.userTicketisLoading = false;
				})
			);
	}

	getSiteTicketData(options: SitesTicketsFilter) {
		this.siteTicketisLoading = true;
		this._siteTickettableData.next([]);
		this._siteTickettableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetSitesTicketSubmissionDetail?siteId=${options.siteId}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._siteTickettableData.next(res.data);
					this._siteTickettableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.siteTicketisLoading = false;
				})
			);
	}

	getRequestSourceTicketData(options: UserRequestSourceFilter) {
		this.reqSourceTicketisLoading = true;
		this._reqSourceTickettableData.next([]);
		this._reqSourceTickettableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetUserRequestSourceDetail?sourceId=${options.sourceId}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._reqSourceTickettableData.next(res.data);
					this._reqSourceTickettableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.reqSourceTicketisLoading = false;
				})
			);
	}

	getTimeSpentTicketData(options: TimeSpentFilter) {
		this.timeSpentTicketisLoading = true;
		this._timeSpentTickettableData.next([]);
		this._timeSpentTickettableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetTicketStatusTimeSpentDetail?statusName=${options.statusName}&fromDate=${options.fromDate}&toDate=${options.toDate}&isVIP=${options.isVIP}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._timeSpentTickettableData.next(res.data);
					this._timeSpentTickettableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.timeSpentTicketisLoading = false;
				})
			);
	}

	getVulnerabilitiesData(options: VulnerabilitiesFilter) {
		this.vulnerabilitiesIsLoading = true;
		this._vulnerabilitiesTableData.next([]);
		this._vulnerabilitiesTableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetVulnerabilitiesDetail?typeName=${options.typeName}&page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._vulnerabilitiesTableData.next(res.data);
					this._vulnerabilitiesTableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.vulnerabilitiesIsLoading = false;
				})
			);
	}

	getAtRiskUsersDetail(options: IPageState) {
		this.atRiskUsersIsLoading = true;
		this._atRiskUsersTableData.next([]);
		this._atRiskUsersTableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetAtRiskUsersDetail?page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._atRiskUsersTableData.next(res.data);
					this._atRiskUsersTableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.atRiskUsersIsLoading = false;
				})
			);
	}

	getAtRiskDevicesDetail(options: IPageState) {
		this.atRiskDevicesIsLoading = true;
		this._atRiskDevicesTableData.next([]);
		this._atRiskDevicesTableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetAtRiskDevicesDetail?page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._atRiskDevicesTableData.next(res.data);
					this._atRiskDevicesTableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.atRiskDevicesIsLoading = false;
				})
			);
	}

	getRecentSecurityTicketsDetail(options: IPageState) {
		this.recentSecurityTicketsIsLoading = true;
		this._recentSecurityTicketsTableData.next([]);
		this._recentSecurityTicketsTableItems.next(0);
		return this.http
			.get(
				`${environment.apiBaseUrl}DashboardReport/GetRecentSecurityTicketsDetail?page=${options.page}&pageSize=${options.pageSize}&column=${options.column}&order=${options.order}`
			)
			.pipe(
				tap((res: any) => {
					this._recentSecurityTicketsTableData.next(res.data);
					this._recentSecurityTicketsTableItems.next(res.totalCount);
					this.currentFilters = options;
				}),
				finalize(() => {
					this.recentSecurityTicketsIsLoading = false;
				})
			);
	}
}
