import { Component, Input, OnInit, inject } from '@angular/core';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { SharedModule } from '@app/shared/shared.module';
import {
	OwlDateTimeModule,
	OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
} from '@angular/forms';
import * as moment from 'moment';

@Component({
	selector: 'app-schedule-project',
	templateUrl: './schedule-project.component.html',
	styleUrls: ['./schedule-project.component.scss'],
	standalone: true,
	imports: [
		SharedModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		FormTooltipModule,
		ReactiveFormsModule,
	],
})
export class ScheduleProjectComponent implements OnInit {
	@Input() data: IDataModal;
	dataModalService = inject(DataModalService);
	form: FormGroup;
	publishTime = moment(new Date()).format('HH:mm');

	ngOnInit(): void {
		this.form = new FormGroup({
			startDate: new FormControl(''),
		});
	}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}

	refreshDateRangeFieldValidation(current: AbstractControl) {
		let c = this.form.get('startDate');
		if (c && current != c) c.updateValueAndValidity();
	}
}
