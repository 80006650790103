import { createAction, props } from "@ngrx/store";
import { IOrders } from "./orders.interface";

export enum ActionTypes{
  ORDERS_UPDATE = '[Orders] Update',
  ORDERS_UPDATE_SUCCESS = '[Orders] Update Success',

}

export const ordersUpdateAction =
createAction(
  ActionTypes.ORDERS_UPDATE,
  props<Partial<IOrders>>()
)

export const ordersUpdateSuccessAction =
createAction(
  ActionTypes.ORDERS_UPDATE_SUCCESS
)
