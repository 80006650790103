import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { DynamicTableSettings } from '@app/modules/dashboard/widget-dynamic-table/widget-dynamic-table.component';
import { TableMessages } from '@app/shared/constants';
import { MSTeams, MSTeamsAdmin, TableDynamicColumns, WidgetDynamicTable, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { DashboardWidgetService } from '@app/shared/services/dashboard/dashboard-widget.service';
import { BehaviorSubject, filter, map, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-teams-usage',
  templateUrl: './ms-teams-usage.component.html',
  styleUrls: ['./ms-teams-usage.component.scss'],
})
export class MsTeamsUsageComponent implements OnInit, OnDestroy{
  
  dataSource$:Observable<MSTeams[] | MSTeamsAdmin[] | null>;
  #msTeams: any;
  systemRoleId: number;

  dataTable:WidgetDynamicTable;
  usageSettings:DynamicTableSettings = { // need to improve this settings
    ngStyle:{
      td:{'textAlign': 'left !important'},
      th:{'textAlign': 'left !important'},
      row: {},
    },
  }

  #subscription = new Subscription();

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: ()=>{}
  noClick: ()=>{}

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dbWidget: DashboardWidgetService,
    private _dashboard: DashboardService,
    private _cd: ChangeDetectorRef,
  ){
    _dashboard.initMSTeam();
  }


  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  ngOnInit() {
    this.#initProperties();
  }

  /**Private functions */
  #initProperties(){ 
    this.dataSource$ = this._dashboard.msTeams$;
    this.systemRoleId = this._dashboard.systemRoleId;

    this.#subscription.add(
      this.dataSource$
        .pipe(
          filter(v=>v!=null),
          map(v=>{
            if(this.systemRoleId < 3)
              return this._dashboard.transformMSTeamsData(v, 'users')
              //return v;
            else{
              return this._dashboard.transformMSTeamsData(v)
            }
          })
        )
        .subscribe(v=>{
          this.#setData(v!);
        })
    )
  }
  
  #setData(teams:{activity:string, count: string| number}[] | MSTeamsAdmin[] | MSTeams){
    if(this.systemRoleId < 3){
/*       this.dataTable = this.#createDataTable(teams as any[]);
      this.usageSettings = this.#getUsageSettings(); */
      this.#createDataTable(teams as any[])
    }else{
      this.#msTeams = teams;
    }

  }

  #createDataTable(teams:any[]){
    this.source = teams;
    this.columns = this.#getColumns()
    this.displayedColumns = this.#getDisplayedColumns()
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
  }

  #getColumns():any[]{
    return [{
        text: 'Name',
        matColumnDef: 'fullName'
      },
      {
        text: 'Message',
        matColumnDef:'messageCount',
      },
      {
        text: 'Call',
        matColumnDef:'callCount',
      },
      {
        text: 'Meeting',
        matColumnDef:'meetingCount',
      },
      {
        text: 'Last Updated',
        matColumnDef:'lastUpdated',
      },
    ]
  }

  #getDisplayedColumns(){
    return ['fullName', 'messageCount', 'callCount', 'meetingCount', 'lastUpdated'];
  }


  #getUsageSettings(){
    return {
      ngStyle:{
        td:{'textAlign': 'center'},
        row: {},
      },
      ngClass:{
        th: 'center-th',
      }
    }
  }

  getDataTable(): WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      displayedColumns: this.displayedColumns,
    }
  }

  /**setters and getters */
  get msTeams(){
    return this.#msTeams;
  }

  get title(){
    return 'MS Teams Usage Report';
  }

}
