import { IDropdown } from '@app/shared/constants';
import { companyId } from './store/portal-usage.selector';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
export interface PortalUsageStore{
  monthlyReport: MonthlyReportUsage[],

  companyReport: CompanyReportUsage[],
  companyReportFilters: PortalUsageFilters,
  companyReportListDetails: Omit<iListPage, 'data'>

  companyReportTop5: CompanyReportUsage[],
  companyReportTop5Filters: PortalUsageFilters,

  companyReportLeast5: CompanyReportUsage[],
  companyReportLeast5Filters: PortalUsageFilters,

  usersActivity: UsersActivity[],
  userActivityFilters: PortalUsageFilters,
  userActivityDetails: Omit<iListPage, 'data'>,
  companyData: CompanyData | null,
  companyId: number | null,

  usersActivityGraph: UsersActivity[],
  usersActivityGraphFilters: PortalUsageFilters,

  dailyReport: CompanyReportUsage[],
  year: number | null,
  month: number | null,
  currentMonthActivity: number,
  currentMonthUsers: number,
  pastWeekActivity: number,
  pastWeekUsers: number,
  todayActivity: number, // currentDailyActivity: number;
  todayUsers: number, //currentDailyUsers: number;
  companyListReport: CompanyListReportUsage[],

  userActivityHistory: UserActivityHistory[],
  userActivityHistoryFilters: PortalUsageFilters,
  userActivityHistoryDetails: Omit<iListPage, 'data'>,
  userId: number | null,
  userDetails: UserDetails | null,

  loginReportSummaryList: Omit<iListPage, 'data'>
  loginReportSummary: LoginReportSummary[],
  loginReportDetailsList: Omit<iListPage, 'data'>
  loginReportDetails: LoginReportDetails[],
  usersDropdown: IDropdown[],
  companyDropdown: IDropdown[],
  companyIdDropdown: number | null,
  userQuery: string,
  DAUType: DAUTypeDropdown[],
  fromDate: string | null,
  toDate: string | null,
  userLoginFilters: UserLoginFilters,
  userLoginFiltersDetails: UserLoginFilters,

  exportFilters: PortalUsageFilters,
}

export interface UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  companyId: number;
  companyName: string;
}

export interface UserLoginFilters extends IPageState{
  fromDate: string;
  toDate: string;
  companyId?: number,
  userId?: number,
  DAUTypeId?: number,
  typeIds?: number | number[],
}

export interface PortalUsageFilters extends UserLoginFilters{
  // they have the same types but need to create separate as best practice
  // change this in case it would go to a different path
}

export interface UserActivityHistory {
  userId: number;
  menuName: string;
  clicks: number;
}

export interface CompanyData{
  id: number;
  name: string;
}

export interface UsersActivity{
  userId: number;
  name: string;
  userActivity: number;
  fromDate: string;
  toDate: string;
}

export interface MonthlyReportUsageFormat{
  portalUsage: MonthlyReportUsage[];
  currentMonthActivity: number;
  currentMonthUsers: number;
  pastWeekActivity: number;
  pastWeekUsers: number;
  todayActivity: number,
  todayUsers: number,
}

export interface MonthlyReportUsage{
  monthYear?: string;
  year: number;
  monthName: string;
  uniqueUsers:number;
  usersActivity: number;
  fromDate: string;
  toDate: string;
}

/* export interface CompanyReportUsageFormat{
  top: CompanyReportUsage[];
  bottom: CompanyReportUsage[];
} */

export interface CompanyReportUsage{
  companyId: number;
  companyName: string;
  uniqueUsers: number;
  usersActivity: number;
  fromDate: string;
  toDate: string;
}

export interface CompanyListReportUsage{
  companyId: number;
  companyName: string;
  uniqueUsers: number;
  usersActivity: number;
}

export interface DailyReportUsageFormat{
  dailyUsage: CompanyReportUsage[];
  todayActivity: number, // currentDailyActivity: number;
  todayUsers: number, //currentDailyUsers: number;
}

export interface ReportButton{
  id: number,
  isAllowed:boolean,
  name: string,
  url: string
}

export class PortalWidgetDynamicTable {
  source: any[];
  columns: PortalTableDynamicColumns[];
  noRecordMessage: string;
  displayedColumns:string[];
  filter: UserLoginFilters
}


export interface PortalTableDynamicColumns {
  text: string,
  matColumnDef: string,
  isSort?: boolean,
  style?: {
      th?: any,
      td?: any,
  },
  class?:{
      th?:any,
      td?:any,
  }
}

export interface iListPage{
  currentPage: number,
  data: any[],
  pageSize: number,
  totalCount: number,
}

export interface LoginReportSummary{ // to change when API arrives
  id: number,
  date: Date | string,
  companyName: string,
  userName: string,
  description: string,
  details: string,
  count: number,
  platformType?: string,
  browserType?: string,
  browserVersion?: string,
  browserResolution?: string,
}

export interface LoginReportDetails{ // to change when API arrives
  id: number,
  date: Date | string,
  time?: string,
  companyName: string,
  userName: string,
  description: string,
  details: string,
  platformType?: string,
  browserType?: string,
  browserVersion?: string,
  browserResolution?: string,
}

export interface DAUTypeDropdown{
  id: number,
  description: string
}

export type PortalExportList = 'company' | 'user activity' | 'activity history';

export interface EmailPreview {
  id: number,
  userId: number,
  firstName: string,
  lastName: string,
  subject: string,
  content: string,
  fileAttachments: string,
  email: string,
  ccEmails: string,
  sentDateTime: string,
  companyName: string,
}