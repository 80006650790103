import { createReducer, on } from '@ngrx/store';
import {
	jwtRemoveAction,
	jwtRemoveSuccessAction,
	jwtUpdateAction,
	jwtUpdateSuccessAction,
	jwtUpdateWoEffectAction,
} from './jwt.action';
import { IJWT } from './jwt.interface';

const initialState: IJWT = {
	accessToken: null,
	refreshToken: null,
};

export const jwtReducer = createReducer(
	initialState,
	on(
		jwtUpdateAction,
		(state, action): IJWT => ({
			...state,
			accessToken: action.accessToken,
			refreshToken: action.refreshToken
				? action.refreshToken
				: state.refreshToken,
		})
	),
	on(
		jwtUpdateSuccessAction,
		(state): IJWT => ({
			...state,
		})
	),
	on(
		jwtRemoveAction,
		(state): IJWT => ({
			...state,
			accessToken: null,
			refreshToken: null,
		})
	),
	on(
		jwtRemoveSuccessAction,
		(state): IJWT => ({
			...state,
		})
	),
	on(
		jwtUpdateWoEffectAction,
		(state, action): IJWT => ({
			...state,
			accessToken: action.accessToken,
			refreshToken: action.refreshToken,
		})
	)
);
