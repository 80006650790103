export class ApiRoutes {
	// Auth Related
	public static readonly AUTH_3RD_PARTY_CLIENT_ID =
		'AzureKeyVault/GetGoogleAndMicrosoftClientId';
	public static readonly AUTH_SEND_TOKEN = 'UserLogin/sendtoken';
	public static readonly AUTH_EMAIL_TOKEN_LOGIN = 'UserLogin/EmailTokenLogin';
	public static readonly AUTH_RESEND_TOKEN = 'UserLogin/resendtoken';
	public static readonly AUTH_GOOGLE_LOGIN = 'UserLogin/googlelogin';
	public static readonly AUTH_MICROSOFT_LOGIN = 'UserLogin/microsoftlogin';
	public static readonly AUTH_CONTINUE_PENDING_LOGIN =
		'UserLogin/ContinuePendingLogin';
	public static readonly AUTH_CONTINUE_LOGIN = 'UserLogin/ContinueLogin';
	public static readonly AUTH_SWITCHROLE = 'UserLogin/SwitchRole';
	public static readonly AUTH_REFRESHTOKEN = 'UserLogin/RefreshToken';
	public static readonly AUTH_START_IMPERSONATE = 'UserLogin/StartImpersonate';
	public static readonly AUTH_END_IMPERSONATE = 'UserLogin/EndImpersonate';
	public static readonly AUTH_SID_LOGIN = 'UserLogin/SidLogin';
	public static readonly AUTH_CONNECTWISE_LOGIN =
		'UserLogin/ConnectWiseTokenLogin';

	// DASHBOARD
	public static readonly DASHBOARD_CARD = 'DashboardCard';
	public static readonly DASHBOARD_CARD_CATEGORY = 'DashboardCardCategory';
	public static readonly USER_DASHBOARD_CARD = 'UserDashboardCard';
	public static readonly USER_DASHBOARD_TAB_CARD = 'UserDashboardTabCards';
	public static readonly USER_DASHBOARD_VERSIONS = 'UserDashboardVersions';
	//public static readonly USER_DASHBOARD_TABS = "UserDashboardTabs";

	// APPLICATION
	public static readonly APPLICATION = 'Application';
	public static readonly APPLICATION_CATEGORY = 'ApplicationCategory';

	//TICKETS
	public static readonly TICKETS_ADD_OR_REMOVE_CONTACTS =
		'Tickets/AddOrRemoveContacts';

	// NOTIFICATIONS
	public static readonly NOTIFICATION_SETTING = 'NotificationSetting';

	public static readonly ASSETS = 'Invoices/AccountActivities';

	public static getAllProperties(): string[] {
		const routes: string[] = [];

		Object.keys(this).forEach((key: string) => {
			routes.push(this[key as keyof ApiRoutes]);
		});

		return routes;
	}
}
