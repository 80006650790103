import { IKnowledgeBase } from '../kb-topic.interface';
import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  CONTACTS_KB_TOPICS_UPDATE = '[ContactsKbTopics] Update',
  CONTACTS_GLOBAL_KB_TOPICS_UPDATE_SUCCESS = '[ContactsKbTopics] Update Success',

}

export const contactsKbTopicsUpdateAction =
createAction(
  ActionTypes.CONTACTS_KB_TOPICS_UPDATE,
  props<Partial<IKnowledgeBase>>()
)

export const contactsKbTopicsUpdateSuccessAction =
createAction(
  ActionTypes.CONTACTS_GLOBAL_KB_TOPICS_UPDATE_SUCCESS
)
