import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'quest-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss'],
	standalone: true,
})
export class StatusComponent implements OnInit {
	@Input() status: string;
	constructor() {}

	ngOnInit(): void {}
}
