import { createReducer, on } from "@ngrx/store"
import {  adminUrlUpdateAction, jwtDecodeUpdateAction, jwtDecodeUpdateSuccessAction, userFirstLoginAction, userFirstLoginSuccessAction, userRemoveAction, userRemoveSuccessAction, userUpdateAction, userUpdateSuccessAction, userUrlUpdateAction } from "./user.action"
import { IUser } from "./user.interface"



const initialState: IUser = {
  userData: null,
  jwt: null,
  userUrl: '',
  adminUrl: '',
  userFirstLogin: null
}

export const userReducer =
createReducer(
  initialState,
  on(userUpdateAction, (state,action): IUser => ({
    ...state,
    userData: {...action},
  })),
  on(userUpdateSuccessAction, (state): IUser => ({
    ...state,
  })),
  on(userFirstLoginAction, (state,action): IUser => ({
    ...state,
    userFirstLogin: {...action},
  })),
  on(userFirstLoginSuccessAction, (state): IUser => ({
    ...state,
  })),
  on(userRemoveAction, (state): IUser => ({
    ...state,
    ...initialState
  })),
  on(userRemoveSuccessAction, (state): IUser => ({
    ...state,
  })),
  on(jwtDecodeUpdateAction, (state,action): IUser =>({
    ...state,
    jwt: {...action},
  })),
  on(jwtDecodeUpdateSuccessAction, (state): IUser => ({
    ...state,
  })),
  on(userUrlUpdateAction, (state,action): IUser => ({
    ...state,
    userUrl: action.url,
  })),
  on(userUpdateSuccessAction, (state): IUser => ({
    ...state,
  })),
  on(adminUrlUpdateAction, (state,action): IUser => ({
    ...state,
    adminUrl: action.url,
  })),
  on(userUpdateSuccessAction, (state): IUser => ({
    ...state,
  })),
)
