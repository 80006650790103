import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-cross-check',
	templateUrl: './cross-check.component.html',
	styleUrls: ['./cross-check.component.scss'],
})
export class CrossCheckComponent implements OnInit {
	@Input() isCheck: boolean = true;

	constructor() {}

	ngOnInit() {}
}
