<div class="quest-logo-container" #logo  >
  <div class="big-circle">
    <div class="white-circle">
      <div class="small-circle">
        <div class="white-quarter"></div>
      </div>
    </div>
  </div>
  <div class="solid-line"></div>
  <div class="transparent-line"></div>
</div>
