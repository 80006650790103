<div
	class="multi-select"
	#multiSelect
	[class.has-selected]="selectedItems.length > 0"
>
	<mat-chip-list
		[selectable]="true"
		[disabled]="disabled"
		#chipList
		cdkOverlayOrigin
		#chipsOrigin="cdkOverlayOrigin"
		matAutocompleteOrigin
		#autoOrigin="matAutocompleteOrigin"
		tabindex="-1"
	>
		<input
			#searchInput
			[formControl]="searchControl"
			[placeholder]="placeholder"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matAutocompleteConnectedTo]="autoOrigin"
			class="form-control"
			[class]="customClassSelect"
			(blur)="onTouched()"
			[class.multi-select-search-icon]="isShowSearchIcon"
		/>

		<div #scroll class="chip-wrapper" [class.with-chips]="selectedItems.length">
			<ng-container *ngIf="selectedItems.length < 5; else trimContainer">
				<mat-chip
					*ngFor="let item of selectedItems"
					(removed)="toggleSelection(item)"
					(click)="optionClicked($event, item, true)"
					class="d-flex align-items-center"
				>
					{{ item.display }}
					<mat-icon matChipRemove svgIcon="x-close" style="stroke: #08354F; width: 16px; height: 16px; opacity: 1;"></mat-icon>
				</mat-chip>
			</ng-container>

			<ng-template #trimContainer>
				<ng-container *ngFor="let item of selectedItems; let i = index">
					<mat-chip
						*ngIf="i < 4"
						(removed)="toggleSelection(item)"
						(click)="optionClicked($event, item, true)"
						class="d-flex align-items-center"
					>
						{{ item.display }}
						<mat-icon matChipRemove svgIcon="x-close" style="stroke: #08354F; width: 16px; height: 16px; opacity: 1;"></mat-icon>
					</mat-chip>
				</ng-container>
				<mat-chip (click)="openSelectedPanel()"> ... </mat-chip>
			</ng-template>
		</div>
	</mat-chip-list>

	<mat-autocomplete
		#auto="matAutocomplete"
		panelWidth="auto"
		[class]="'default-autocomplete ' + customClass"
	>
		<mat-option *ngIf="allowSelectAll" class="multi-option">
			<div (click)="selectAll($event)" style="padding-top: 1px">
				<app-figma-icon-img
					[iconName]="isAllSelected ? 'check-square' : 'square'"
					[isForButton]="true"
				></app-figma-icon-img>

				<input
					type="checkbox"
					[checked]="isAllSelected"
					(change)="selectAll()"
					(click)="$event.stopPropagation()"
				/>
				Select All
			</div>
		</mat-option>
		<cdk-virtual-scroll-viewport
      #virtualScroll
      style="max-height: 200px;"
      [ngStyle]="{'height': getVirtualScrollHeight(virtualScroll.elementRef.nativeElement), 'width.px': optionWidth}"
      itemSize="25">
			<mat-option
				[ngStyle]="{ 'width.px': optionWidth, 'position': 'relative' , 'borderBottom': 'none'}"
				*cdkVirtualFor="let item of filteredItems | async"
				class="multi-option"
			>
				<div (click)="optionClicked($event, item)" style="padding-top: 1px">
					<app-figma-icon-img
						[iconName]="item.selected ? 'check-square' : 'square'"
						[isForButton]="true"
					></app-figma-icon-img>

					<input
						type="checkbox"
						[checked]="item.selected"
						style="pointer-events: none"
					/>
					{{ item.display }}
				</div>
			</mat-option>
		</cdk-virtual-scroll-viewport>
	</mat-autocomplete>

	<span class="show-all">
		<mat-chip
			class="view-all"
			*ngIf="selectedItems.length > 1"
			(click)="openSelectedPanel()"
		>
			{{ selectedItems.length + '/' + items.length }}</mat-chip
		>
	</span>
</div>

<ng-template
	cdkConnectedOverlay
	cdkConnectedOverlayHasBackdrop="true"
	(backdropClick)="closeSelectedPanel()"
	cdkConnectedOverlayBackdropClass="transparent"
	[cdkConnectedOverlayOrigin]="chipsOrigin"
	[cdkConnectedOverlayOpen]="selectedPanelOpen && selectedItems.length > -1"
>
	<div class="multi-select-overlay">
		<mat-chip-list>
			<mat-chip
				[disabled]="disabled"
				(click)="optionClicked($event, item, true)"
				*ngFor="let item of selectedItems"
				(removed)="toggleSelection(item)"
				class="d-flex align-items-center"
			>
				{{ item.display }}
				<mat-icon matChipRemove svgIcon="x-close" style="stroke: #08354F; width: 16px; height: 16px; opacity: 1;"></mat-icon>
			</mat-chip>
		</mat-chip-list>
	</div>
</ng-template>
