<!-- <p *ngFor="let activity of usersActivity.usersActivity$ | async">
  [{{ activity.userId }}] {{activity.name}} - Activity: {{ activity.userActivity }}
</p> -->


<dx-chart
  id="chart"
  [dataSource]="usersActivity.usersActivityGraph$ | async"
  palette="Harmony Light"
  (onPointClick)="onChartClick($event)"
  (onPointHoverChanged)="onPointHoverChanged($event)"
>
  <dxi-value-axis name="acvivities" position="left">
  </dxi-value-axis>

  <dxi-series
    type="bar"
    valueField="userActivity"
    name="Activity"
    axis="acvivities"
    color="#A07CF1"
  ></dxi-series>
  <dxo-common-series-settings
    argumentField="name"
  ></dxo-common-series-settings>

  <dxo-tooltip
    [enabled]="true"
    [customizeTooltip]="customizeTooltip"
    ></dxo-tooltip>
  <dxo-legend
    [visible]="false"
  ></dxo-legend>


</dx-chart>
