import { ProfileBranding } from '@app/shared/interfaces/user.interface';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	ViewChild,
} from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { sideBarColorSelector } from './store/configuration.selector';
import { ColorService } from './../../services/color.service';
import { AccountService } from '@app/modules/account/account.service';

import { UserService } from '@app/core/services/user.service';
import { NotificationService } from '@app/core/services/notification.service';
import {
	DEFAULT_COLOR,
	DefaultBranding,
	IThemeSettings,
} from './theme.defaults';

@Component({
	selector: '[spConfiguration]',
	templateUrl: './configuration.component.html',
	styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements AfterViewInit {
	@ViewChild('navbarFixed') navbarFixed: ElementRef;
	@ViewChild('navbarMinimize') navbarMinimize: ElementRef;
	@ViewChild('darkVersion') darkMode: ElementRef;
	@ViewChild('lightVersion') lightMode: ElementRef;

	@ViewChild('whiteNav') whiteNav: ElementRef;
	@ViewChild('defaultNav') defaultNav: ElementRef;

	darkMode$: Observable<boolean>;
	sidebarColor$: Observable<string>;

	profBrandingSub: Subscription;
	themeSettings: IThemeSettings = {
		companyDarkThemeColor: DEFAULT_COLOR,
		companyLightThemeColor: DEFAULT_COLOR,
		lightThemeColor: DEFAULT_COLOR,
		darkThemeColor: DEFAULT_COLOR,
		isDarkMode: false,
		selectedColor: DEFAULT_COLOR,
		darkColorsList: [],
		lightColorsList: [],
		userId: 0,
	};
	mode: string;
	colorIndex: number;
	colorValue: string;
	brandingItem: any;
	defaultBranding: ProfileBranding;

	constructor(
		private localStorageService: LocalStorageService,
		private store: Store<AppStateInterface>,
		private _colorService: ColorService,
		private _accountService: AccountService,
		private _userService: UserService,
		private _notifier: NotificationService,
		private _cd: ChangeDetectorRef
	) {}

	ngAfterViewInit(): void {
		this.checkConfigValues();
		this._cd.detectChanges();
	}

	openEditMode(mode: string, i: number) {
		this.mode = mode;
		this.colorIndex = i;
		// update color
		if (this.colorIndex === -1) {
			this.colorValue = DEFAULT_COLOR;
		} else {
			this.colorValue = this.themeSettings.lightColorsList[i];
		}
	}

	setSideBarColor(color: string) {
		if (color === this.themeSettings.selectedColor) {
			return;
		}
		this.themeSettings.selectedColor = color;
		this.updateColor();
		// this.updateLS();
	}

	saveColor() {
    const colorList = [
      ...this.themeSettings.lightColorsList,
      this.colorValue,
    ];
    if (this.colorValue) {
      this.updateColor(colorList);
    }
	}

	updateColor(lightColors?: string[]) {
		const body = {
			userId: this.themeSettings.userId,
			logoColors: this.brandingItem.logoColors,
			dominantColors: this.brandingItem.dominantColors,
			isDefault: this.brandingItem.isDefault,
			companyIsDefault: this.brandingItem.companyIsDefault,
			companyIsDarkMode: this.brandingItem.companyIsDarkMode,
			darkThemeColor: this.themeSettings.darkThemeColor,
			lightThemeColor: this.themeSettings.lightThemeColor,
			isDarkMode: this.themeSettings.isDarkMode,
			selectedColor: this.themeSettings.selectedColor,
			companyDarkThemeColor: this.themeSettings.companyDarkThemeColor,
			companyLightThemeColor: this.themeSettings.companyLightThemeColor,
			lightColors: lightColors
				? lightColors.join(',')
				: this.themeSettings.lightColorsList.join(','),
			darkColors: '',
		};
		this._accountService
			.updateBranding(this._userService.userId, body)
			.subscribe({
				next: (res: any) => {
					this.brandingItem = res;
					this.themeSettings = this.mapData(res);
					if (this.themeSettings.lightColorsList[0] === '') {
						this.themeSettings.lightColorsList = [];
					}
					this.initializeConfig();
				},
			});
		this.mode = '';

		// this.updateLS();
	}

	deleteUserColor(color: string) {
		if (
			this.themeSettings.selectedColor &&
			this.themeSettings.selectedColor === color
		) {
			this._notifier.notifyError('Color is currently selected and cannot be deleted');
		} else {
			this.mode = '';
			const colorList = this.themeSettings.lightColorsList;
			colorList.splice(this.themeSettings.lightColorsList.indexOf(color), 1);
			this.updateColor(colorList);
		}
	}

	updateLS() {
		this.localStorageService.setStorageObject(
			'themeSettings',
			this.themeSettings
		);
	}

	setDefaultBranding() {
		this.defaultBranding = {
			...DefaultBranding,
			userId: this._userService.userId,
		};
	}
	checkConfigValues() {
		this._accountService
			.getBrandingById(this._userService.userId)
			.subscribe((data) => {
				this.setDefaultBranding();
				this.brandingItem = data ?? this.defaultBranding;
				this.themeSettings = this.mapData(this.brandingItem);

				if (this.themeSettings.lightColorsList[0] === '') {
					this.themeSettings.lightColorsList = [];
				}
				this.initializeConfig();
			});
		this.sidebarColor$ = this.store.pipe(select(sideBarColorSelector));
	}

	initializeConfig() {
		if (this.themeSettings.selectedColor) {
			this._colorService.setSideBarColor(this.themeSettings.selectedColor);
		}
	}

	setSideNavType(color: string) {
		if (color === 'dark') {
			this.defaultNav.nativeElement.click();
		} else if (color === 'light') {
			this.whiteNav.nativeElement.click();
		}
	}
	mapData(data: ProfileBranding) {
		const body = {
			userId: data.userId,
			darkThemeColor: data.darkThemeColor,
			lightThemeColor: data.lightThemeColor,
			companyDarkThemeColor: data.companyDarkThemeColor,
			companyLightThemeColor: data.companyLightThemeColor,
			isDarkMode: data.isDarkMode,
			selectedColor: data.selectedColor,
			lightColorsList: data.lightColorsList,
			darkColorsList: data.darkColorsList,
		};
		return body as IThemeSettings;
	}
}
