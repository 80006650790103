import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'app-local-figma-icon-img',
	templateUrl: './local-figma-icon-img.component.html',
	styleUrls: ['./local-figma-icon-img.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalFigmaIconImgComponent implements OnInit {
	@Input() filename: string;
	@Input() height = 15;
	@Input() width = 15;
	@Input() hasSpaceOnRight = true;
	@Input() hasSpaceOnLeft = false;
	@Input() classList: string;

	constructor() {}

	ngOnInit() {}

	get src(): string {
		return `assets/images/icons/${this.filename}.svg`;
	}
}
