import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapTooltipDirective } from './bootstrap-tooltip.directive';





@NgModule({
  declarations: [
    BootstrapTooltipDirective,
  ],
  imports: [
    CommonModule
  ],
  exports:[
    BootstrapTooltipDirective
  ]
})
export class BootstrapTooltipModule { }
