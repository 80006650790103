import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { IAsset } from './pages/assets/store/assets.interface';
import { ApplicationType } from '@app/shared/constants';
import { BehaviorSubject, finalize, map, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private _http: HttpClient) { }

  getTypesOptions() {
    return this._http.get<any>(
      `${environment.apiBaseUrl}Asset/GetAssetTypeDropdown`
    );
  }

  statusOptions$ = this._http.get<any>(
    `${environment.apiBaseUrl}Asset/GetAssetStatusDropdown`
  );

  getStatusOptions() {
    return this._http.get<any>(
      `${environment.apiBaseUrl}Asset/GetAssetStatusDropdown`
    );
  }

  getOnlineOfflineStatusOptions() {
    return this._http.get<any>(
      `${environment.apiBaseUrl}Asset/GetAssetOnlineOfflineStatusDropdown`
    );
  }

  assetFilters: IAsset;

  private _assetData = new ReplaySubject<any[]>(1);
	assetData$ = this._assetData.asObservable();

	private _assetTotalItems = new ReplaySubject<number>(1);
	assetTotalItems$ = this._assetTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

  set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

  getAssets(options: IAsset) {
    this.assetFilters = options;

    var param = {
      Page: options.page,
      PageSize: options.pageSize,
      SearchText: options.query,
      Column: options.column,
      Order: options.order
    } as any;

    if (options.typeIds !== undefined && options.typeIds !== null && options.typeIds?.length > 0) {
      param.TypeIds = options.typeIds.join(',');
    }
    if (options.onlineOfflineStatus !== undefined && options.onlineOfflineStatus !== null && options.onlineOfflineStatus?.length > 0) {
      param.OnlineOfflineStatus = options.onlineOfflineStatus.join(',');
    }
    if (options.statusIds !== undefined && options.statusIds !== null && options.statusIds?.length > 0) {
      param.statusIds = options.statusIds.join(',');
    }

    this.isLoading = true;
		this._assetData.next([]);
		this._assetTotalItems.next(0);

    return this._http.get<any>(`${environment.apiBaseUrl}Asset`, { params: param })
      .pipe(
        map(response => {
          return {
            data: response.data.map((asset: any) => {
              return {
                id: asset.id,
                name: asset.name,
                status: asset.status,
                color: asset.status === 'Active' ? '#caf4db' : asset.status === 'Inactive' ? '#f8d1cd' : '',
                fontColor: asset.status === 'Active' ? '#0d6630' : asset.status === 'Inactive' ? '#6d1008' : '',
                expirationDate: asset.expirationDate,
                type: asset.type,
                lastBackup: asset.lastBackup,
                onlineOffline: asset.onlineOffline
              }
            }),
            totalCount: response.totalCount,
            pageSize: response.pageSize,
            currentPage: response.currentPage
          }
        }),
        tap((res: any) => {
          this._assetData.next(res.data);
          this._assetTotalItems.next(res.totalCount);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getAssetsExport(options: IAsset, exportType: ApplicationType) {
    var param = {
      //Page: options.page,
      //PageSize: options.pageSize,
      SearchText: options.query,
      Column: options.column,
      Order: options.order,
      exportType
    } as any;

    if (options.typeIds !== undefined && options.typeIds !== null && options.typeIds?.length > 0) {
      param.TypeIds = options.typeIds.join(',');
    }
    if (options.onlineOfflineStatus !== undefined && options.onlineOfflineStatus !== null && options.onlineOfflineStatus?.length > 0) {
      param.OnlineOfflineStatus = options.onlineOfflineStatus.join(',');
    }
    if (options.statusIds !== undefined && options.statusIds !== null && options.statusIds?.length > 0) {
      param.statusIds = options.statusIds.join(',');
    }

    return this._http.get<any>(`${environment.apiBaseUrl}Asset/Export`, { params: param });
  }

  checkValidOnlineOfflineStatus(status: string) {
		if (status.toLowerCase() === 'online' || status.toLowerCase() === 'offline')
			return true;
		else return false;
	}
}
