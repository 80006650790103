import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subscription, debounceTime, filter, map, of, tap } from 'rxjs';

@Component({
  selector: 'select-input-async',
  templateUrl: './select-input-async.component.html',
  styleUrls: ['./select-input-async.component.scss'],
  providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectInputAsyncComponent,
			multi: true,
		},
	],
})
export class SelectInputAsyncComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor{
  //injection
  private _cd = inject(ChangeDetectorRef)

  // props
  control = new FormControl('');
  value: ValueLabel;
  _internalOptions$:Observable<ValueLabel[]>;

  onChange: Function;
  private _origPlaceholder = '';
  private _subscription = new Subscription();
  private _options: ValueLabel[] = [];
  private _onTouched: Function;

  // Input / Output
  /**
   * options must be: Observable {value: string | number, label: string }
   * commonly you will pass the API call here but an already formatted data 
   * as the type requires
   */
  @Input() options$: Observable<ValueLabel[]> = of([]);

	//@Input() optionKeys: SelectKeys; // we will not be using this here
	@Input() inputClass: string;
	@Input() placeholder: string = '';
	@Input() disabled = false;
	@Input() readonly = false;
	@Input() hasClear = true;
	//@Output() onChange = new EventEmitter(); //use (onChange) instead of (change)
	@Output() inputChange = new EventEmitter();
  @Output() search = new EventEmitter<string>();
  @Output() onBlur = new EventEmitter();
  @ViewChild('inputSelect') inputSelect: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;

  constructor() { }

  // lifecycle
  ngOnInit() {
    //this._internalOptions$ = this.options$;
  }
  ngAfterViewInit(): void {
    this._eventListener();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['placeholder'] && changes['placeholder'].firstChange) this._origPlaceholder = changes['placeholder'].currentValue;
    if(changes['options$']) {
      if(changes['options$'].currentValue.length <= 0) this.options$ = of([{value:0, label:''}]);
      else this.options$ = changes['options$'].currentValue;
      this._cd.detectChanges();
      // if(changes['options$'].currentValue.length <= 0)
      //   this._internalOptions$ = of([{value:0, label:''}]);
      // else this._internalOptions$ = changes['options$'].currentValue;
    }
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  // Control Value Accessor
  writeValue(obj: ValueLabel): void {
    if(obj?.label) this.control.patchValue(obj.label)
    else {
      this.placeholder = this._origPlaceholder;
      this.search.emit('');
      this.options$ = of([{value:0, label:''}]);//, this._internalOptions$ = of([{value:0, label:''}]);
      this._cd.detectChanges();
    }
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled? this.control.disable(): this.control.enable();
  }

  private _eventListener(){
    this._subscription.add(
      this.control.valueChanges
        .pipe(
          filter(v=>v!.length >= 3),
          debounceTime(500),
        )
        .subscribe(v=>{
          this.search.emit(v!)
        })
    )

/*     this._subscription.add(
      this._internalOptions$.subscribe(v=>{
        this._options = v;
        console.log('options', v);
      })
    ) */
    
  }

  setValue(obj: ValueLabel){
    if(!obj.label) this.placeholder = this._origPlaceholder
    else this.placeholder = obj.label

    if(typeof obj === 'object' && 'label' in obj) this.control.setValue(obj.label)
    else this.control.setValue('');
    this.value = obj;
    this.onChange(obj);
  }

  displayFn(value:ValueLabel): string {
    return value.label
  }

  clear(){
    this.control.patchValue('');
    this.value?.value? 
      this.placeholder = this.value.label:
      this.placeholder = this._origPlaceholder;
  }

  onTouched(){
    if(this.value?.value){
      this.control.patchValue(this.value.label)
      this.placeholder = this.value.label
    }else{
      this.control.patchValue('')
      this.placeholder = this._origPlaceholder;
    }

    this.onBlur.emit();
  }

  clickForm() {
		this.inputSelect.nativeElement.select();
		this.autoTrigger.openPanel();
	}

	closeSelection() {
		setTimeout(() => this.autoTrigger.closePanel(), 100);
	}
}

export interface ValueLabel{
  value: string | number,
  label: string,
}