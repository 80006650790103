
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DashboardDrawerService } from '@app/shared/services/dashboard-drawer-service';
import { filter, Observable, pairwise } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FeedbackGuard implements CanActivate{

  constructor(private _drawer:DashboardDrawerService){
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this._drawer.openFeedbackList = true;
    return false;
  }
}
