import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabGroupComponent } from './tab-group.component';
import { TabItemComponent } from './components/tab-item/tab-item.component';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';

@NgModule({
  declarations: [TabGroupComponent, TabItemComponent],
  imports: [CommonModule, FigmaIconImgModule],
  exports: [TabGroupComponent, TabItemComponent],
})
export class TabGroupModule {}
