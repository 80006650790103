import { Injectable } from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { configUpdateAction } from '../navigation/configuration/store/configuration.action';
import { sideBarColorSelector } from '../navigation/configuration/store/configuration.selector';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  dominantColor: string[] = [];

  constructor(private store: Store<AppStateInterface>, private _utilitiesService: UtilitiesService) {}
  getColor(status: string){
    switch(status.toLowerCase()){
      case 'new':
        return '#c4e6f9';
      case 'in progress':
        return '#FAF2CE';
      case 'in queue':
        return '#B5C0D5';
      case 'resolved':
        return '#caf4db ';
      case 'awaiting response':
        return '#d9cafa';
      case 'closed':
        return '#dfe0e2';
      case 'in progress':
        return '#faf2ce';
      case 'need to schedule':
        return '#f8d1cd';
      default: return '#dfe0e2'
    }
  }

  getFontColor(status: string){
    switch(status.toLowerCase()){
      case 'new':
        return '#08354f';
      case 'in progress':
        return '#705C03';
      case 'in queue':
        return '#0E1A31';
      case 'resolved':
        return '#0d6630';
      case 'awaiting response':
        return '#2c0c6e';
      case 'closed':
        return '#444548';
      case 'need to scheduled':
        return '#705c03';
      default: return '#444548'
    }
  }
  setSideBarColor(color: string, isDark: boolean = false) {
    this.store.dispatch(configUpdateAction({sideBarColor: color}));
    this.store.pipe(
      select(sideBarColorSelector),take(1)).subscribe(
        (color: string) =>{
          if(!isDark){
            document.documentElement.style.setProperty('--dark-color-base',color as string);
            let bgLightColor = this._utilitiesService.lighten(color);
            document.documentElement.style.setProperty('--dark-color-bg',bgLightColor as string);
          }else{
            // let bgDarkColor = this._utilitiesService.smartColorAlgorithm(color);
            // let tertiaryDarkColor = this._utilitiesService.smartColorAlgorithm(color);
            // let primaryDarkModeColor = this._utilitiesService.checkAndConvertLight(color);
            // document.documentElement.style.setProperty('--dark-color-base',primaryDarkModeColor as string);
            // document.documentElement.style.setProperty('--dark-color-bg',bgDarkColor as string);
            // document.documentElement.style.setProperty('--dark-color-tertiary',tertiaryDarkColor  as string);
          }

        }
      )
  }


  buildPalette(colorsList: any) {
    const paletteContainer = document.getElementById('palette')!;
    const complementaryContainer = document.getElementById('complementary')!;
    // reset the HTML in case you load various images
    paletteContainer.innerHTML = '';
    complementaryContainer.innerHTML = '';

    const orderedByColor = this.orderByLuminance(colorsList);
    const hslColors = this.convertRGBtoHSL(orderedByColor);

    for (let i = 0; i < orderedByColor.length; i++) {
      const hexColor = this.rgbToHex(orderedByColor[i]);

      const hexColorComplementary = this.hslToHex(hslColors[i]);

      if (i > 0) {
        const difference = this.calculateColorDifference(
          orderedByColor[i],
          orderedByColor[i - 1]
        );

        // if the distance is less than 120 we ommit that color
        if (difference < 120) {
          continue;
        }
      }

      // create the div and text elements for both colors & append it to the document
      const colorElement = document.createElement('div');
      colorElement.style.backgroundColor = hexColor;
      colorElement.appendChild(document.createTextNode(hexColor));
      paletteContainer.appendChild(colorElement);
      // true when hsl color is not black/white/grey
      if (hslColors[i].h) {
        const complementaryElement = document.createElement('div');
        complementaryElement.style.backgroundColor = `hsl(${hslColors[i].h},${hslColors[i].s}%,${hslColors[i].l}%)`;

        complementaryElement.appendChild(
          document.createTextNode(hexColorComplementary)
        );
        complementaryContainer.appendChild(complementaryElement);
      }
    }
  }

  setDominantColor(dominantColor: string[]) {
    this.dominantColor = dominantColor;
  }

  getLogoColors(colorsList: any): string[] {
    const logoColor: string[] = [];
    const orderedByColor = this.orderByLuminance(colorsList);

    for (let i = 0; i < orderedByColor.length; i++) {
      const hexColor = this.rgbToHex(orderedByColor[i]);

      if (i > 0) {
        const difference = this.calculateColorDifference(
          orderedByColor[i],
          orderedByColor[i - 1]
        );

        // if the distance is less than 120 we ommit that color
        if (difference < 120) {
          continue;
        }
      }

      logoColor.push(hexColor);
    }

    return logoColor;
  }

  //  Convert each pixel value ( number ) to hexadecimal ( string ) with base 16
  rgbToHex(pixel: { r: any; g: any; b: any }) {
    const componentToHex = (c: { toString: (arg0: number) => any }) => {
      const hex = c.toString(16);
      return hex.length == 1 ? '0' + hex : hex;
    };

    return (
      '#' +
      componentToHex(pixel.r) +
      componentToHex(pixel.g) +
      componentToHex(pixel.b)
    ).toUpperCase();
  }

  /**
   * Convert HSL to Hex
   * this entire formula can be found in stackoverflow, credits to @icl7126 !!!
   * https://stackoverflow.com/a/44134328/17150245
   */
  hslToHex(hslColor: any) {
    const hslColorCopy = { ...hslColor };
    hslColorCopy.l /= 100;
    const a =
      (hslColorCopy.s * Math.min(hslColorCopy.l, 1 - hslColorCopy.l)) / 100;
    const f = (n: number) => {
      const k = (n + hslColorCopy.h / 30) % 12;
      const color =
        hslColorCopy.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
  }

  /**
   * Convert RGB values to HSL
   * This formula can be
   * found here https://www.niwa.nu/2013/05/math-behind-colorspace-conversions-rgb-hsl/
   */
  convertRGBtoHSL = (rgbValues: any) => {
    return rgbValues.map((pixel: { r: number; g: number; b: number }) => {
      let hue = 0;
      let saturation = 0;
      let luminance = 0;

      // first change range from 0-255 to 0 - 1
      let redOpposite = pixel.r / 255;
      let greenOpposite = pixel.g / 255;
      let blueOpposite = pixel.b / 255;

      const Cmax = Math.max(redOpposite, greenOpposite, blueOpposite);
      const Cmin = Math.min(redOpposite, greenOpposite, blueOpposite);

      const difference = Cmax - Cmin;

      luminance = (Cmax + Cmin) / 2.0;

      if (luminance <= 0.5) {
        saturation = difference / (Cmax + Cmin);
      } else if (luminance >= 0.5) {
        saturation = difference / (2.0 - Cmax - Cmin);
      }

      /**
       * If Red is max, then Hue = (G-B)/(max-min)
       * If Green is max, then Hue = 2.0 + (B-R)/(max-min)
       * If Blue is max, then Hue = 4.0 + (R-G)/(max-min)
       */
      const maxColorValue = Math.max(pixel.r, pixel.g, pixel.b);

      if (maxColorValue === pixel.r) {
        hue = (greenOpposite - blueOpposite) / difference;
      } else if (maxColorValue === pixel.g) {
        hue = 2.0 + (blueOpposite - redOpposite) / difference;
      } else {
        hue = 4.0 + (greenOpposite - blueOpposite) / difference;
      }

      hue = hue * 60; // find the sector of 60 degrees to which the color belongs

      // it should be always a positive angle
      if (hue < 0) {
        hue = hue + 360;
      }

      // When all three of R, G and B are equal, we get a neutral color: white, grey or black.
      if (difference === 0) {
        return false;
      }

      return {
        h: Math.round(hue) + 180, // plus 180 degrees because that is the complementary color
        s: parseFloat((saturation * 100).toString()).toFixed(2),
        l: parseFloat((luminance * 100).toString()).toFixed(2),
      };
    });
  };

  /**
   * Using relative luminance we order the brightness of the colors
   * the fixed values and further explanation about this topic
   * can be found here -> https://en.wikipedia.org/wiki/Luma_(video)
   */
  orderByLuminance(rgbValues: any[]) {
    const calculateLuminance = (p: { r: number; g: number; b: number }) => {
      return 0.2126 * p.r + 0.7152 * p.g + 0.0722 * p.b;
    };

    return rgbValues.sort((p1, p2) => {
      return calculateLuminance(p2) - calculateLuminance(p1);
    });
  }

  buildRgb(imageData: any) {
    const rgbValues = [];
    // note that we are loopin every 4!
    // for every Red, Green, Blue and Alpha
    for (let i = 0; i < imageData.length; i += 4) {
      const rgb = {
        r: imageData[i],
        g: imageData[i + 1],
        b: imageData[i + 2],
      };

      rgbValues.push(rgb);
    }

    return rgbValues;
  }

  /**
   * Calculate the color distance or difference between 2 colors
   *
   * further explanation of this topic
   * can be found here -> https://en.wikipedia.org/wiki/Euclidean_distance
   * note: this method is not accuarate for better results use Delta-E distance metric.
   */
  calculateColorDifference(
    color1: { r: number; g: number; b: number },
    color2: { r: number; g: number; b: number }
  ) {
    const rDifference = Math.pow(color2.r - color1.r, 2);
    const gDifference = Math.pow(color2.g - color1.g, 2);
    const bDifference = Math.pow(color2.b - color1.b, 2);

    return rDifference + gDifference + bDifference;
  }

  // returns what color channel has the biggest difference
  findBiggestColorRange(rgbValues: any[]) {
    /**
     * Min is initialized to the maximum value posible
     * from there we procced to find the minimum value for that color channel
     *
     * Max is initialized to the minimum value posible
     * from there we procced to fin the maximum value for that color channel
     */
    let rMin = Number.MAX_VALUE;
    let gMin = Number.MAX_VALUE;
    let bMin = Number.MAX_VALUE;

    let rMax = Number.MIN_VALUE;
    let gMax = Number.MIN_VALUE;
    let bMax = Number.MIN_VALUE;

    rgbValues.forEach((pixel) => {
      rMin = Math.min(rMin, pixel.r);
      gMin = Math.min(gMin, pixel.g);
      bMin = Math.min(bMin, pixel.b);

      rMax = Math.max(rMax, pixel.r);
      gMax = Math.max(gMax, pixel.g);
      bMax = Math.max(bMax, pixel.b);
    });

    const rRange = rMax - rMin;
    const gRange = gMax - gMin;
    const bRange = bMax - bMin;

    // determine which color has the biggest difference
    const biggestRange = Math.max(rRange, gRange, bRange);
    if (biggestRange === rRange) {
      return 'r';
    } else if (biggestRange === gRange) {
      return 'g';
    } else {
      return 'b';
    }
  }

  /**
   * Median cut implementation
   * can be found here -> https://en.wikipedia.org/wiki/Median_cut
   */
  quantization(rgbValues: any, depth: number): any {
    const MAX_DEPTH = 4;

    // Base case
    if (depth === MAX_DEPTH || rgbValues.length === 0) {
      const color = rgbValues.reduce(
        (
          prev: { r: any; g: any; b: any },
          curr: { r: any; g: any; b: any }
        ) => {
          prev.r += curr.r;
          prev.g += curr.g;
          prev.b += curr.b;

          return prev;
        },
        {
          r: 0,
          g: 0,
          b: 0,
        }
      );

      color.r = Math.round(color.r / rgbValues.length);
      color.g = Math.round(color.g / rgbValues.length);
      color.b = Math.round(color.b / rgbValues.length);

      return [color];
    }

    /**
     *  Recursively do the following:
     *  1. Find the pixel channel (red,green or blue) with biggest difference/range
     *  2. Order by this channel
     *  3. Divide in half the rgb colors list
     *  4. Repeat process again, until desired depth or base case
     */
    const componentToSortBy = this.findBiggestColorRange(rgbValues);
    rgbValues.sort(
      (p1: { [x: string]: number }, p2: { [x: string]: number }) => {
        return p1[componentToSortBy] - p2[componentToSortBy];
      }
    );

    const mid = rgbValues.length / 2;
    return [
      ...this.quantization(rgbValues.slice(0, mid), depth + 1),
      ...this.quantization(rgbValues.slice(mid + 1), depth + 1),
    ];
  }
}
