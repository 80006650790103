import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import {
	CurrentLevel,
	GlobalAccess,
	OptionsAddEditModeModel,
	OptionsInterface,
	OptionsModel,
} from '@app/shared/functions/options';

import { UserService } from '@app/core/services/user.service';
import { GlobalPermissionsService } from '@app/shared/services/base-application.service';
import { ApplicationsService } from '@app/modules/applications/applications.service';

@Component({
	selector: 'app-global-applications',
	template: `
		<app-application-list
			*ngIf="roleId === 1"
			[viewOptions]="options$"
		></app-application-list>
		<mat-tab-group
			*ngIf="roleId === 2"
			[selectedIndex]="applicationsService.tab"
			class="no-header"
			animationDuration="0ms"
		>
			<mat-tab label="Global">
				<div>
					<app-application-list
						[viewOptions]="options$"
						[isCustom]="false"
						[isMainPage]="false"
					></app-application-list>
				</div>
			</mat-tab>
			<mat-tab label="Company">
				<div>
					<app-application-list
						[viewOptions]="options$"
						[isCustom]="true"
						[isMainPage]="false"
					></app-application-list>
				</div>
			</mat-tab>
		</mat-tab-group>
	`,
	styles: [''],
	providers: [GlobalPermissionsService],
})
export class GlobalApplicationsComponent implements OnInit {
	roleId: any;
	options$: Observable<OptionsModel | OptionsAddEditModeModel | null>;

	constructor(
		private _globalPermissionService: GlobalPermissionsService,
		private _userService: UserService,
		public applicationsService: ApplicationsService,
	) {}

	ngOnInit(): void {
		this.roleId = this._userService.user?.roleId;
		this.options$ = this._globalPermissionService.options$;

		const data: OptionsInterface = {
			levelId: this.assignLevelId(),
			roleId: this._userService.userRole,
			moduleId: GlobalAccess.Application,
		};

		this._globalPermissionService.setData(data, 'list');
	}

	assignLevelId() {
		if (this._userService.isSpAdmin) {
			return CurrentLevel.GLOBAL;
		} else if (this._userService.isClientAdmin) {
			return CurrentLevel.COMPANY;
		} else return CurrentLevel.CONTACT;
	}
}
