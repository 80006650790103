
export function convertUTCToLocal(utcTimestamp: string): string {
  const utcDate = new Date(utcTimestamp);
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  return localDate.toLocaleString();
}

export function getHourDifferenceFromDate(date: Date): number {
  const currentDate = new Date();
  const timeDifferenceInMillis = currentDate.getTime() - date.getTime();
  const minuteDifference = Math.floor(timeDifferenceInMillis / 60000);
  return minuteDifference;
}



export function getEquivalentTimezone(timezone:string){
  switch(timezone){
    case 'UTC -5': return 'EST'
    case 'UTC -6': return 'CST'
    case 'UTC -7': return 'MST'
    case 'UTC -8': return 'PST'
    default: return timezone;
  }
}

/*
  {
      "id": 4,
      "name": "Eastern",
      "value": "UTC -5"
  },
  {
      "id": 3,
      "name": "Central",
      "value": "UTC -6"
  },
  {
      "id": 2,
      "name": "Mountain",
      "value": "UTC -7"
  },
  {
      "id": 1,
      "name": "Pacific",
      "value": "UTC -8"
  }
 */