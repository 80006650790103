/* Angular Libraries */ 
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

/* Third Party Libraries */ 
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { NewFeatureInfoModalService } from './new-feature-info-modal.service';

/* Interfaces */
import { MobileUpdateType } from '@app/shared/constants/mobile-update.enum';
import { MobileUpdateAction } from '@app/shared/interfaces/sms-quest-mobile-update.interface';

@Component({
  selector: 'new-feature-info-modal',
  templateUrl: './new-feature-info-modal.component.html',
  styleUrls: ['./new-feature-info-modal.component.scss']
})
export class NewFeatureInfoModalComponent implements OnInit, AfterViewInit, OnDestroy {
	dotsBtn: DotButton[] = [];

	private _$unsubscribe: Subject<void> = new Subject<void>();
	
	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _renderer2: Renderer2,
		private _cd: ChangeDetectorRef,
		public newFeatureInfoService: NewFeatureInfoModalService
	) {}

	ngOnInit(): void {

	}

	ngAfterViewInit(): void {
		this.loadScriptViaDOM();
	}

	onClickSkip(campaignId: number, isSkipped = true) {
		this.newFeatureInfoService.setCampaignAction(campaignId, isSkipped);
	}

	loadScriptViaDOM() {
		const scriptElement = this._renderer2.createElement('script');
		const scriptTagStr = `
		var items = document.querySelectorAll('#imrf');
		for (var i = 0, len = items.length; i < len; i++) {
			(function(){
				var e = this,
						t = "https://cdnjs.cloudflare.com/ajax/libs/lory.js/2.3.4/lory.min.js",
						l = ["0", "false"],
						s = "";
				s = "true" == s ? 1 : parseInt(s, 10);
				var a = {
					slidesToScroll: parseInt("1", 10),
					enableMouseEvents: l.indexOf("") >= 0 ? 0 : 1,
					infinite: !isNaN(s) && s,
					rewind: !(l.indexOf("") >= 0),
					slideSpeed: parseInt("300", 10),
					rewindSpeed: parseInt("600", 10),
					snapBackSpeed: parseInt("200", 10),
					ease: "ease",
					classNameFrame: "gjs-lory-frame",
					classNameSlideContainer: "gjs-lory-slides",
					classNamePrevCtrl: "gjs-lory-prev",
					classNameNextCtrl: "gjs-lory-next"
				}
				,
						r = function () {
							window.sliderLory = lory(e, a);
						};
				if ("undefined" == typeof lory) {
					var n = document.createElement("script");
					n.src = t, n.onload = r, document.head.appendChild(n);
				}
				else r();
			}
			.bind(items[i]))();
		}
	`;

		this._renderer2.setProperty(scriptElement, "text", scriptTagStr); /* Added script type (text) */
		this._renderer2.appendChild(this._document.body, scriptElement); /* It will add a new `<script>` on each call */
		this.initLorySlider(true);
		this.initSliderEvent();
		this._cd.detectChanges();
	}

	initLorySlider(isInitial: boolean = false) {
		const slides = [...(document.querySelectorAll('.gjs-lory-slide') as any)];

		if (slides.length) {
			this.dotsBtn = slides.map((el: any, i: number) => {
				return {
					id: el.id,
					index: i + 1,
					isActive: isInitial && i == 0 ? true : el.className.includes('active')
				}
			});
			this.arrowNextBtn();
			this._cd.detectChanges();
		}
	}

	initSliderEvent() {
		const doc = document as any;
		doc.getElementById(doc.querySelector('.gjs-lory-frame').id).onmousemove = () => setTimeout(() => this.initLorySlider(), 0);
		doc.getElementById(doc.querySelector('.gjs-lory-frame').id).onmouseleave = () => setTimeout(() => this.initLorySlider(), 0);
		doc.getElementById(doc.querySelector('.gjs-lory-prev').id).onclick = () => setTimeout(() => this.initLorySlider(), 0);
		doc.getElementById(doc.querySelector('.gjs-lory-next').id).onclick = () => setTimeout(() => this.initLorySlider(), 0);
	}

	clickDot(dot: DotButton) {
		const currentSelected: any = this.dotsBtn.find(dot => dot.isActive);
		const total = Math.abs(Number(currentSelected.index) - Number(dot.index));

		for (let i = 0; i < total; i ++) {
			Number(currentSelected.index) < Number(dot.index) ? this.nextSlide() : this.prevSlide();
		}

		this.initLorySlider();
	}

	prevSlide() {
		const prev: any = document.getElementById((document.querySelector('.gjs-lory-prev') as any).id);
		prev.click();
		this.initLorySlider();
	}

	nextSlide() {
		const next: any = document.getElementById((document.querySelector('.gjs-lory-next') as any).id);

		next.click();
		this.initLorySlider();
	}

	arrowNextBtn() {
		const el = (document as any).querySelector('.gjs-lory-next').classList;
		this.isLastClickOnNext ? el.add('disabled') : el.remove('disabled');
	}

	// get isDisabledPrevBtn() {
	// 	return this.dotsBtn.findIndex(dot => dot.isActive) === 0;
	// }

	get isLastClickOnNext() {
		return this.dotsBtn.findIndex(dot => dot.isActive) === (this.dotsBtn.length - 1);
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}

export interface DotButton {
	id?: string;
	index?: number;
	isActive?: boolean;
}

