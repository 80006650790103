export interface IGroup{
  id: number,
  defaultGroupId: 0,
  defaultGroup: string,
  name: string,
  companyId: 0,
  companyName: string;
  isEdit?: boolean;
  tempName?: string;
  roleId: number;
  userCount: number;
  isSystemGroup?: boolean;
  error?: string;
}

export interface IGroupRequest{
  id: number,
  name: string,
  createdBy?: 0,
  modifiedBy?: 0
}

export interface IPermissionsCheck{
  //groupId: number | null;
  roleId: number | null;
  menuIds: Record<any, any>;
  menuActionIds: Record<any, any>;
  //kbTopicIds: Record<any, any>;
  //applicationIds: Record<any, any>;
  //kbCategoryIds: Record<any, any>;
  //applicationCategoryIds: Record<any, any>;
}

export interface IPermissionsCheckNew{
  userId: number | null;
  menuIds: Record<any, any>;
  menuActionIds: Record<any, any>;
}
export interface MenuPermissionParam{
  roleId: number;
  companyId: number;
  groupId: number;
  userId: number;
  menuIds: number[];
  isAllowed: boolean;
}

export interface MenuActionPermissionParam{
  roleId: number;
  companyId: number;
  groupId: number;
  userId: number;
  menuActionIds: number[];
  isAllowed: boolean;
}

export interface MenuAndActionPermissionParam{
  roleId: number;
  companyId: number;
  groupId: number;
  userId: number;
  menuValues: MenuValuesParam[];
  menuActionValues: MenuActionValuesParam[];
}
export interface MenuValuesParam{
  menuId: number;
  isAllowed: boolean;
}
export interface MenuActionValuesParam{
  menuActionId: number;
  isAllowed: boolean;
}

export interface MenuOverrideParam{
  companyId: number;
  groupId: number;
  userId: number;
  isChanged: boolean;
}

export enum permissionProperties{
  menuIds = 'menuIds',
  menuActionIds = 'menuActionIds',
}

export enum permissionPropertiesNew{
  menuIds = 'menuIds',
  menuActionIds = 'menuActionIds',
}




