import { Injectable } from "@angular/core";
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { LocalStorageService } from "@app/shared/services/local-storage.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { first, map, switchMap } from "rxjs";
import { topBarUpdateAction, topBarUpdateSuccessAction } from "./top-bar.action";
import { topBarSelector } from "./top-bar.selector";


@Injectable()
export class TopBarEffect{
  topBarUpdate$ = createEffect(()=>
  this.actions$.pipe(
    ofType(topBarUpdateAction),
    map((_data)=>{}),
    switchMap((_)=>{return this.store.pipe(
      select(topBarSelector),
      first()
    )}),
    map((data)=>{
      this.localStorageService.setStorageObject('topBar',data);
      return topBarUpdateSuccessAction()
    })
  )
  )

  topBarUpdateSuccess$ = createEffect(()=>
  this.actions$.pipe(
    ofType(topBarUpdateSuccessAction),
    map((_data)=>{

    })
  ),
  {dispatch: false}
  )

  constructor(private actions$: Actions, private store: Store<AppStateInterface>, private localStorageService: LocalStorageService){}
}
