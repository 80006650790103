import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { BaseUserComponent } from './../../../../../../shared/components/user/base-user.component';

import { SpinnerService } from './../../../../../../core/services/spinner.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { UserService } from './../../../../../../core/services/user.service';

@Component({
	selector: 'app-select-admin',
	templateUrl: './select-admin.component.html',
	styleUrls: ['./select-admin.component.scss'],
})
export class SelectAdminComponent extends BaseUserComponent {
	users: any[] = [];
	selectedUser: number = 0;

	constructor(
		userServce: UserService,
		@Inject(MAT_DIALOG_DATA) public data: number,
		public spinner: SpinnerService,
		public dialogRef: MatDialogRef<SelectAdminComponent>,
		private _companiesService: CompaniesService
	) {
		super(userServce);

		this._companiesService
			.getUsersDropdownByCompanyId(this.data)
			.subscribe((res: any) => {
				if (res.length > 0) {
					this.users = res.filter(
						(user: { id: number }) => user.id !== this.userId
					);
				} else {
					this.users = [];
				}
			});
	}

	userChange(id: number) {
		this.selectedUser = id;
	}

	onNoClick(): void {
		this.dialogRef.close({
			event: 'closed',
		});
	}

	saveUser() {
		this.spinner.start();
		this._companiesService
			.updateRole(this.selectedUser, 2)
			.pipe(finalize(() => this.spinner.stop()))
			.subscribe({
				next: () => {
					this.dialogRef.close({
						event: 'Added',
					});
				},
			});
	}
}
