//import { UserTypes } from "../constants/user-types.constants"

import { UserTypes } from "../constants";

export class OptionsAddEditModeModel implements OptionsClassInterface {

  moduleId: GlobalAccess;
  levelId: CurrentLevel;
  roleId: UserTypes;

  companyId: number | null = null;
  userId: number | null = null;
  groupId: number | null = null;
  setMainOptions(_options: OptionsInterface){
    const options = _options as OptionsModel;
    this.moduleId = options.moduleId;
    this.levelId = options.levelId;
    this.roleId = options.roleId;
  }
}


export class OptionsModel implements OptionsClassInterface{

  moduleId: GlobalAccess;

  levelId: CurrentLevel //Global, Contact, User, Groups
  roleId: UserTypes


  column: columnType;

  columnSettings: {
    icon: string | null;
    iconAlt: string | null;
    tooltip: string | null;
    tooltipAlt: string | null;
    fieldName: string | null;
  }

  apiUrl: apiUrl;
  canAdd: boolean | null;


  companyId: number | null = null;
  userId: number | null = null;
  groupId: number | null = null;

  hasOverrideFlag: boolean;
  overrideSettings : {
    message: string | null;
    checkbox: string | null;
  }

  defaultColumnDisabledValue: boolean = false;

  setMainOptions(_options: OptionsInterface){
    const options = _options as OptionsModel;
    this.moduleId = options.moduleId;
    this.levelId = options.levelId;
    this.roleId = options.roleId;
    this.setPageOptions(options.levelId, options.roleId);

  }

  setPageOptions(levelId: CurrentLevel, roleId: UserTypes){
    if(roleId === UserTypes.SourcepassAdmin){
      if(levelId === CurrentLevel.GLOBAL){
        this.column = ColumnSettings.Accessibility

        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }

        this.apiUrl = apiUrl.GLOBAL;
        this.hasOverrideFlag = false;


      }else
      if(levelId === CurrentLevel.COMPANY){
        this.column = ColumnSettings.Accessibility
        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }

        this.hasOverrideFlag = true;
        this.overrideSettings = {
        checkbox: OverrideSettings.CompanyCheckbox,
        message: OverrideSettings.CompanyMessage,
        }
        this.apiUrl = apiUrl.COMPANY;
        //
      }else
      if(levelId === CurrentLevel.CONTACT){
        this.column = ColumnSettings.Accessibility
        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }


        this.hasOverrideFlag = true;
        this.overrideSettings = {
        checkbox: OverrideSettings.ContactCheckbox,
        message: OverrideSettings.ContactMessage,
        }

        this.apiUrl = apiUrl.CONTACT;
        //
      }else
      if (levelId === CurrentLevel.GROUP){
        this.column = ColumnSettings.Accessibility
        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }


        this.hasOverrideFlag = true;
        this.overrideSettings = {
        checkbox: OverrideSettings.GroupCheckbox,
        message: OverrideSettings.GroupMessage,
        }

        this.apiUrl = apiUrl.GROUP;
        this.canAdd = false;
      }
    } else
    if(roleId === UserTypes.ClientAdmin){
      if(levelId === CurrentLevel.COMPANY){
        this.column = ColumnSettings.Accessibility
        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }

        this.hasOverrideFlag = true;
        this.overrideSettings = {
        checkbox: OverrideSettings.CompanyCheckbox,
        message: OverrideSettings.CompanyMessage,
        }

        this.apiUrl = apiUrl.COMPANY;
        //
      }else
      if(levelId === CurrentLevel.CONTACT){
        this.column = ColumnSettings.Accessibility
        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }


        this.hasOverrideFlag = true;
        this.overrideSettings = {
        checkbox: OverrideSettings.ContactCheckbox,
        message: OverrideSettings.ContactMessage,
        }

        this.apiUrl = apiUrl.CONTACT;
        //
      }else
      if (levelId === CurrentLevel.GROUP){
        this.column = ColumnSettings.Accessibility
        this.columnSettings = {
          icon:ColumnSettings.AccessibilityIconTrue,
          iconAlt:ColumnSettings.AccessibilityIconFalse,
          tooltip: ColumnSettings.AccessibilityToolTipTrue,
          tooltipAlt: ColumnSettings.AccessibilityToolTipFalse,
          fieldName: ColumnSettings.accessField
        }


        this.hasOverrideFlag = true;
        this.overrideSettings = {
        checkbox: OverrideSettings.GroupCheckbox,
        message: OverrideSettings.GroupMessage,
        }

        this.apiUrl = apiUrl.GROUP;
        this.canAdd = false;
      }
    }else

    if(roleId === UserTypes.User){
      if(levelId === CurrentLevel.CONTACT){
        this.column = ColumnSettings.Visibility
        this.columnSettings = {
        icon: ColumnSettings.VisibilityIconTrue,
        iconAlt:ColumnSettings.VisibilityIconFalse,
        tooltip: ColumnSettings.VisibilityToolTipTrue,
        tooltipAlt: ColumnSettings.VisibilityToolTipFalse,
        fieldName: ColumnSettings.visibilityField
        }

        this.hasOverrideFlag = false;

        this.apiUrl = apiUrl.CONTACT;
      }
    }
  }



}

class OverrideSettings{
  public static readonly ContactMessage = 'Select the Knowledge Bases that you want to display. By selecting Knowledge Bases here you will override the global company settings for this contact.';
  public static readonly GroupMessage = 'Select the Knowledge Bases that you want to display. By selecting Knowledge Bases here you will override the global company settings for this group.';
  public static readonly CompanyMessage = 'Select the Knowledge Bases that you want to display. By selecting Knowledge Bases here you will override the global Knowledge Base settings for this company.';

  public static readonly CompanyCheckbox = 'Override global Knowledge Base settings';
  public static readonly ContactCheckbox = 'Override company Knowledge Base settings';
  public static readonly GroupCheckbox = 'Override group Knowledge Base settings';

}

class ColumnSettings{
  public static readonly Accessibility = 'Access';
  public static readonly Visibility = 'Visibility';

  public static readonly SetGlobalAccess = 'etGlobalAccess';
  public static readonly SetCompanyAccess = 'setCompanyAccess';
  public static readonly SetUserAccess = 'setUserAccess';

  public static readonly SetCompanyVisibility = 'setCompanyVisibility';
  public static readonly SetUserVisibility = 'setUserVisibility';

  public static readonly VisibilityIconTrue = '';
  public static readonly VisibilityIconFalse = '';
  public static readonly AccessibilityIconTrue = '';
  public static readonly AccessibilityIconFalse = '';

  public static readonly VisibilityToolTipTrue = 'Knowledge Base is visible now. Click to change visibility mode.'
  public static readonly VisibilityToolTipFalse = 'Knowledge Base is not visible now. Click to change visibility mode.'
  public static readonly AccessibilityToolTipTrue = 'Knowledge Base is accessible now. Click to change access mode.'
  public static readonly AccessibilityToolTipFalse = 'Knowledge Base is not accessible now. Click to change access mode.'

  public static readonly accessField = 'isAllowed';
  public static readonly visibilityField = 'isVisible';
}

export enum CurrentLevel{
  GLOBAL = 1,
  COMPANY = 2,
  CONTACT = 3,
  GROUP = 4
}

export enum apiUrl{
  GLOBAL = 'Global',
  COMPANY = 'Company',
  CONTACT = 'User',
  GROUP = 'Group'
}

export interface OptionsInterface{
    levelId: CurrentLevel //Global, Contact, User
    roleId: UserTypes
    moduleId: GlobalAccess;
}

interface OptionsClassInterface extends OptionsInterface{
  setMainOptions(_options: OptionsInterface): void
}

export enum GlobalAccess{
  Application  = 1,
  KBTopics = 2,
  Forms = 3,
  Groups = 7
}

type columnType = 'Visibility' | 'Access';
