import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  navItems = [
    {
      link: 'profile',
      image: 'group-icon3',
      name: 'User'
    },
    // {
    //   link: 'notifications',
    //   image: 'group-icon8',
    //   name: 'Notifications'
    // },
    {
      link: 'theme',
      image: 'group-icon8',
      name: 'Theme Customization'
    },
    // {
    //   link: 'authentication',
    //   image: 'group-icon9',
    //   name: 'Authentication'
    // },

  ]
  constructor() { }

  ngOnInit(): void {
  }

}
