<div class="modal-v2_backdrop modal fade" (click)="clickBackdrop($event)">
	<div role="document" class="modal-dialog modal-dialog-centered">
		<div class="modal-v2_bg" [class.loading-container]="isLoading" [class.modal-border]="hasBorder">
			<div class="modal-v2_scroll" [style.height.px]="modalHeight">
				<div class="modal-v2_container" [style.width.px]="modalWidth">
					<div class="modal-v2_header">
						<ng-container *ngTemplateOutlet="modalV2Header"></ng-container>
					</div>
			
					<div class="modal-v2_body-container">
						<div class="modal-v2_body">
							<ng-container *ngTemplateOutlet="modalV2Body"></ng-container>
						</div>
			
						<div class="modal-v2_footer">
							<ng-container *ngTemplateOutlet="modalV2Footer"></ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
