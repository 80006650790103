import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoutes } from '@app/shared/constants';
import { environment } from 'environments/environment';
import { BehaviorSubject, retry, tap } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class KeyVaultService {
  googleClientId: string = '';
  azureClientId: string = '';

  #clientIdsLoaded = new BehaviorSubject(false);
  clientIdsLoaded$ = this.#clientIdsLoaded.asObservable();

  constructor(private _http:HttpClient){
  }

  getClient(){
    return this._http.get<any>(`${environment.apiBaseUrl}${ApiRoutes.AUTH_3RD_PARTY_CLIENT_ID}`)
      .pipe(
        retry(1),
        tap(v=>{
        this.googleClientId = v.googleAuthClientId
        this.azureClientId = v.azureADClientId
        this.#clientIdsLoaded.next(true);
      }))
  }
}
