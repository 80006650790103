/* Angular Libraries */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';

/* Services */
import { UserService } from '@app/core/services/user.service';

/* Functions */
import { ChildCompanyDropdownComponent } from '@app/shared/components/child-company-dropdown/child-company-dropdown.component';
import { CompanyDropdownComponent } from '@app/shared/components/company-dropdown/company-dropdown.component';

/* Interfaces */
import { UserTypes } from '@app/shared/constants/global-enum';

@Component({
	selector: 'company-view',
	templateUrl: './company-view.component.html',
	styleUrls: ['./company-view.component.scss'],
})

export class CompanyViewComponent implements OnInit, AfterViewInit {
  /* ViewChild */
	@ViewChild(CompanyDropdownComponent) companyDropdown: CompanyDropdownComponent;
	@ViewChild(ChildCompanyDropdownComponent) childCompanyDropdown: ChildCompanyDropdownComponent;

  /* Input/Output */
	@Output() onUpdatedCompanyId: EventEmitter<void> = new EventEmitter<void>();

  /* Properties */
	queryCompanyId: number | null;
	isViewCompany: boolean = true;
	companyName: string | null;
	isAdmin: boolean;
	storageName: string = '';
	dataStorage: any = { companyId: null, companyName: null };
	isInitialLoad: boolean;

  /* Constructor */
	constructor(private _userService: UserService, private _cd: ChangeDetectorRef) {}

  /* Methods */
	ngOnInit() {
		this.isInitialLoad = true;
		this.isAdmin = this._userService.userRole === UserTypes.SourcepassAdmin;
		this.storageName = this.isAdmin ? 'companyAdmin' : 'companyUser';
		this.init();
	}

	init() {
		this.dataStorage = JSON.parse(localStorage.getItem(this.storageName) as any);
		this.companyName = this.dataStorage?.companyName ? this.dataStorage.companyName : null;
		this.queryCompanyId = this.dataStorage?.companyId ? this.dataStorage?.companyId : null;
		this.isViewCompany = this.queryCompanyId === this._userService.user?.companyId && this.companyName === null;

		if (
			this.queryCompanyId &&
			!this.isViewCompany &&
			this.companyName &&
			this.companyName !== 'null'
		) {

			this._cd.detectChanges();

			if (this.isAdmin) {
				this.companyDropdown.selectInputAsync.setValue({ label: this.dataStorage.companyName, value: this.queryCompanyId });

			} else {
				this.childCompanyDropdown.selectInputAsync.setValue(this.queryCompanyId);
			}

		} else {
			localStorage.setItem(this.storageName, JSON.stringify({ companyId: this._userService.user?.companyId, companyName: null }));
			this.isViewCompany = true;
		}

		this.isInitialLoad = false;
		this.onUpdatedCompanyId.emit();
	}

	ngAfterViewInit(): void {	
		if (this.isAdmin) {
			this._eventListener();
		}
	}

	onClickMenu(isViewCompany: boolean) {
		console.log('company view is clicked')
		if (isViewCompany) {
			this.companyName = null;
			localStorage.setItem(this.storageName, JSON.stringify({ companyId: this._userService.user?.companyId, companyName: this.companyName }));
		}

		this.init();
	}

	clickCompanyDropdown() {
		this._clearFormValues();
		document.body.classList.add('company-dropdown-style-option');
		setTimeout(() => this.isAdmin ? this.companyDropdown.clickForm() : this.childCompanyDropdown.clickForm(), 0);
	}

	onBlur() {
		// setTimeout(() => this.isAdmin ? this.companyDropdown.closeSelection() : this.childCompanyDropdown.closeSelection(), 0);
		setTimeout(() => document.body.classList.remove('company-dropdown-style-option'), 100);
	}

	onSelectChildCompaniesUpdated(selectedChildCompany: any) {
		this._setCompanyItem(selectedChildCompany);
	}

	private _clearFormValues() {
		if (this.isViewCompany) {
			if (this.isAdmin) {
				this.companyDropdown.searchCompany('');
			} else {
				this.childCompanyDropdown.selectInputAsync.setValue(null);
			}
		}
	}

	private _eventListener() {
		this.companyDropdown.selectAsync.valueChanges
		.subscribe((v) => this._setCompanyItem(v));
	}

	private _setCompanyItem(selectedCompany: any) {
		this.queryCompanyId = selectedCompany && selectedCompany.value ? selectedCompany.value : 0;
		this.companyName = selectedCompany && selectedCompany.label ? selectedCompany.label : null;

		if (this.queryCompanyId) {
			this.isViewCompany = false;
			localStorage.setItem(this.storageName, JSON.stringify({ companyId: this.queryCompanyId, companyName: this.companyName }));

			if (!this.isInitialLoad) {
				this.onUpdatedCompanyId.emit();
			}
		}

		this.isInitialLoad = false;
	}
}
