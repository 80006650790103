import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { toFormData } from 'app/shared/utilities/helper';

import {
	Application,
	ApplicationCategory,
} from '@interfaces/application.interface';
import { TableOptions } from '@interfaces/table.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	catType: Observable<ApplicationCategory>;
	appName: Observable<Application | null>;

	static menuIds = {
		// used for save layout feature - static reference to menus
		contacts: 56,
		assets: 206,
	};

	private loadedFilterLayout_ = new BehaviorSubject(false);
	isFilterLayoutLoaded$ = this.loadedFilterLayout_.asObservable();

	private _catTypeSource = new BehaviorSubject<ApplicationCategory>({
		id: -1,
		name: '',
		iconName: '',
	});
	private _appNameSource = new BehaviorSubject<Application | null>(null);

	constructor(private _http: HttpClient) {
		this.catType = this._catTypeSource.asObservable();
		this.appName = this._appNameSource.asObservable();
	}

	setFilterLayoutLoaded() {
		this.loadedFilterLayout_.next(true);
	}

	changeCatType(category: ApplicationCategory) {
		this._catTypeSource.next(category);
	}

	changeAppName(appName: Application | null) {
		this._appNameSource.next(appName);
	}

	applicationList(options: TableOptions) {
		return this._http.get<{ items: Application[]; total_count: number }>(
			`${environment.apiBaseUrl}Application?Page=${options.page}&PageSize=${
				options.pageSize
			}&searchText=${options.query ?? ''}&userRole=${
				options.userRole
			}&companyId=${options.companyId}`
		);
	}

	addApplication(app: Application) {
		const body = toFormData(app);
		return this._http.post(`${environment.apiBaseUrl}applications`, body);
	}

	updateApplication(app: Application) {
		const body = toFormData(app);
		return this._http.put(`${environment.apiBaseUrl}applications`, body);
	}

	deleteApplication(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}applications?id=${id}`);
	}

	addCategory(category: ApplicationCategory) {
		return this._http.post(
			`${environment.apiBaseUrl}applications/categories`,
			category
		);
	}

	updateCategory(category: ApplicationCategory) {
		return this._http.put(
			`${environment.apiBaseUrl}applications/categories`,
			category
		);
	}

	deleteCategory(id: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}applications/categories?id=${id}`
		);
	}

	getCompanies(): Observable<any> {
		return this._http.get(`${environment.apiBaseUrl}Companies`);
	}
}
