import { createAction, props } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';

export enum ActionTypes {
	AGREEMENTS_TYPE_UPDATE = '[Agreement Types] Update',
	AGREEMENTS_TYPE_UPDATE_SUCCESS = '[Agreement Types] Update Success',
}

export const agreementTypesUpdateAction = createAction(
	ActionTypes.AGREEMENTS_TYPE_UPDATE,
	props<Partial<IPageState>>()
);

export const agreementTypesUpdateSuccessAction = createAction(
	ActionTypes.AGREEMENTS_TYPE_UPDATE_SUCCESS
);
