<div class="media-select" [class.error-state-email]="emailError">
  <mat-form-field class="chip-list w-100 has-error" appearance="outline">
    <mat-chip-list #chipList aria-label="Related Tag selection">
      <div class="email-dropdown-form q-w-100">
        <input
          errorTooltip
          [attr.etShow]="isShowTooltipEmailError ? emailError : ''"
          [etMessage]="emailValidateMessage"
          [placeholder]="placeholder"
          [ngModel]="value"
          [formControl]="relatedEmail"
          #relatedTagsInput
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          (blur)="onBlur($event)"
          (keyup)="checkEmptyValue($event)"
          class="related-tag-input font-b1"
          [class.search-input-icon]="isShowSearchIcon"
        />
      </div>

      <!-- (keydown)="spaceEvent($event)" -->

      <div #scroll class="chip-wrapper">
        <mat-chip *ngFor="let tag of relatedEmails" (removed)="remove(tag)">
          {{ tag }}
          <mat-icon matChipRemove svgIcon="x-close" style="stroke: #08354F; width: 16px; height: 16px; opacity: 1;"></mat-icon>
        </mat-chip>
      </div>
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let item of relatedEmailList | async"
        [value]="item.id"
      >
        {{ item.email }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

