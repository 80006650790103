import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { map, Observable, shareReplay } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { UserService } from '@app/core/services/user.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { SideBarService } from '../side-bar.service';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';

import { configSelector } from '../../configuration/store/configuration.selector';

@Component({
	selector: '[app-side-bar-item]',
	templateUrl: './side-bar-item.component.html',
	styleUrls: ['./side-bar-item.component.scss'],
})
export class SideBarItemComponent implements OnInit {
	@ViewChild('navLink') navLink: ElementRef;
	@Input() sideBarData: RoleMenuInterface;

	id: number;

	sideBarColor$: Observable<string>;
	bgImage$: Observable<string>;
	bg$: Observable<string | null>;

	constructor(
		public _sideBarService: SideBarService,
		private _userService: UserService,
		private router: Router,
		private store: Store<AppStateInterface>,
		private _sessionStorageService: SessionStorageService
	) {}

	ngOnInit() {
		this.id = Number(this._userService.companyId);

		this.sideBarColor$ = this.store.pipe(
			select(configSelector),
			map((data) => data.sideBarColor),
			shareReplay()
		);

		this.bgImage$ = this.sideBarColor$.pipe(map((data) => data));

		this.bg$ = this.sideBarColor$.pipe(map((data) => data));
	}

	routerCheck() {
		return this.router.isActive(this.sideBarData.url, false);
	}

	navigate(url: string) {
		let newUrl = url.replace(':id', this.id.toString());
		this.router.navigate([newUrl]);
	}

	clearGlobalSearch() {
		const searchText = this._sessionStorageService.getStorage('global-search');

		if (searchText) {
			this._sessionStorageService.removeStorageItem('global-search');
		}
	}
}
