import { Injectable } from "@angular/core";
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { LocalStorageService } from "@app/shared/services/local-storage.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { first, map, switchMap } from "rxjs";
import { adminUrlUpdateAction, adminUrlUpdateSuccessAction, userUrlUpdateAction, userUrlUpdateSuccessAction } from "./user.action";
import { adminUrlSelector, userUrlSelector } from "./user.selector";



@Injectable()
export class UserEffect{
  userUrlUpdate$ = createEffect(()=>
  this.actions$.pipe(
    ofType(userUrlUpdateAction),
    map((_data)=>{}),
    switchMap((_)=>{return this.store.pipe(
      select(userUrlSelector),
      first()
    )}),
    map((data)=>{
      this.localStorageService.setStorageObject('userUrl',data);
      return userUrlUpdateSuccessAction()
    })
  )
  )

  adminUrlUpdate$ = createEffect(()=>
  this.actions$.pipe(
    ofType(adminUrlUpdateAction),
    map((_data)=>{}),
    switchMap((_)=>{return this.store.pipe(
      select(adminUrlSelector),
      first()
    )}),
    map((data)=>{
      this.localStorageService.setStorageObject('adminUrl',data);
      return adminUrlUpdateSuccessAction()
    })
  )
  )


  constructor(private actions$: Actions, private store: Store<AppStateInterface>, private localStorageService: LocalStorageService){}
}
