/* Angular Libraries */ 
import {
	Component,
	Inject,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

/* Third Party Libraries */ 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

/* Services */
import { NotificationService } from '@app/core/services/notification.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';

/* Interfaces */
import { ICodeVerify } from './sms-request-code.interface';

@Component({
	selector: 'sms-request-code',
  templateUrl: './sms-request-code.component.html',
  styleUrls: ['./sms-request-code.component.scss']
})

export class SmsRequestCodeComponent implements OnInit, OnDestroy {
	code = new FormControl('', [Validators.required, Validators.minLength(6)]);
	phone: string;
  srFormCodeId: number;
	isVerifying$ = new Subject<boolean>();
	isSedingCode$ = new BehaviorSubject<boolean>(true);
	isInvalidCode$ = new BehaviorSubject<boolean>(false);

	private _inputs: any[];
	private _destroy$: Subject<void> = new Subject<void>();

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: SmsVerifModalData,
		private _dialogRef: MatDialogRef<SmsRequestCodeComponent>,
		private _contactsService: ContactsService
	) {}

	ngOnInit(): void {
		const ps = this._data.phone?.toString();
		if (ps) this.phone = ps.length > 4 ? ps.substring(ps.length - 4) : ps;

		this.sendCode(this._data.isRequestNewCode);
	}

	verify() {
		if (this.code.invalid) return;

		this._dialogRef.close({ 
			SRFormCodeId: this.srFormCodeId, 
			Code: this.code.value!
		} as ICodeVerify);
	}

	sendCode(isRequestNewCode: boolean = true) {
		this.isSedingCode$.next(true);
		this.isInvalidCode$.next(false);
		this._clearCode();

		if (!isRequestNewCode) { 
			this.isSedingCode$.next(false);
			this.isVerifying$.next(false);
			this.isInvalidCode$.next(true);
		} 
		
		this._contactsService
			.requestVerificationCode(this._data.id)
			.subscribe({
				next: (res: any) => {
          this.srFormCodeId = res;
					this.isSedingCode$.next(false);
					this._clearCode();
				},
				error: (err) => {
					this.isSedingCode$.next(false);
					this._dialogRef.close();
				},
			});
	}

	codeChange(index: number, event: any) {
		if (!this._inputs)
			this._inputs = Array.from(document.querySelectorAll('input.code-input'));
		if (this.isInvalidCode$.getValue()) this.isInvalidCode$.next(false);

		const value: string = event.target.value;
		if (event.data && value.length <= 1) this._inputs[index + 1]?.focus();
		else if (event.keyCode === 8) this._inputs[index - 1]?.focus();
		else if (value.length > 1) {
			value.split('').forEach((v) => {
				const input = this._inputs[index];
				if (input) (input.value = v), this._inputs[index + 1]?.focus();
				index++;
			});
		}
		this.code.setValue(this._inputs.map((i) => i.value).join(''));
	}

	private _clearCode() {
		if (!this._inputs) return;
		this._inputs.forEach((i) => (i.value = ''));
		this._inputs[0]?.focus();
		this.code.setValue('');
		setTimeout(() => this._inputs[0]?.focus(), 100);
	}

	ngOnDestroy(): void {
		this._destroy$.next();
		this._destroy$.complete();
	}
}

interface SmsVerifModalData {
	id?: number;
	phone?: number;
	isRequestNewCode?: boolean;
}

