import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { IDropdown } from '@app/shared/constants';
import { Observable, take, tap } from 'rxjs';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit, OnChanges {
  companies$: Observable<IDropdown[]>;
  @Input() isEnabledInputCompany: boolean = true;
  @Input() companyFilterIds: number[];
  @Input() selectedCompany: number;
  @Output()
  chosenCompanyId: EventEmitter<number> = new EventEmitter<number>();
  companies: IDropdown[];
  filteredCompanies: IDropdown[];

  constructor(
    private _companiesService: CompaniesService,
    ) {
    }

   ngOnChanges(changes: SimpleChanges): void {
    if(changes['companyFilterIds'] && changes['companyFilterIds'].currentValue != undefined){
      if(this.companies){
        this.companyFilter();
      }
     }
     if(changes['selectedCompany'] && changes['selectedCompany'].currentValue != undefined){
      if(this.selectedCompany){
        this.chosenCompanyId.emit(this.selectedCompany);
      }
     }
   }

   companyFilter(){
     if(this.companyFilterIds.length > 0){
      this.filteredCompanies = this.companies.filter(company=> this.companyFilterIds.includes(company.id))
     }else{
      this.filteredCompanies = this.companies
     }
   }

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies(){
    this._companiesService.getCompanyDropdown().pipe(take(1),tap(data=>{this.companies = data;this.companyFilter();})).subscribe()
  }

  getChosenCompanyId(id: number){
    this.chosenCompanyId.emit(id);
  }


}
