<div class="row">
	<div class="col-12">
		<div class="card-2 q-mb-16">
			<div class="card-header-2">
				<h3 class="q-mr-auto">{{ id ? 'Edit' : 'Add New' }} Category</h3>

        <app-secondary-button
          [isBackButton]="true"
          [link]="stateAction === 'add' ? '../' : '../../'"
        ></app-secondary-button>
			</div>

			<form class="categories-form" [formGroup]="form" (ngSubmit)="submit()">
				<div class="categories-form-label">
					<label>Category Name*</label>
					<input
						class="form-control"
						type="text"
						formControlName="name"
            maxlength="50"
						(ngModelChange)="resetError()"
					/>
				</div>

				<div class="categories-form-label">
					<label>Icon Upload*</label>
					<div
						class="form-control icon-search-control"
						matAutocompleteOrigin
						#origin="matAutocompleteOrigin"
            [ngClass]="{
              'is-invalid':
                form.get('iconName')!.touched && !!form.get('iconName')!.errors
            }"
					>
						<img [src]="selectedIconSrc" alt="" class="mb-0" />
						<input
							#iconInput
							type="text"
							formControlName="iconName"
							class="form-control icon-search-input"
							(focus)="iconPlaceholder = 'Search for an icon'"
							[class.with-icon]="selectedIconSrc"
							[placeholder]="iconPlaceholder"
							[formControl]="iconControl"
							[matAutocomplete]="auto"
							[matAutocompleteConnectedTo]="origin"
							(blur)="onBlur()"
						/>
						<mat-autocomplete
							#auto="matAutocomplete"
							(optionSelected)="selectIcon($event)"
							class="icon-select"
						>
							<mat-option
								*ngFor="let iconName of filteredOptions | async"
								[value]="iconName"
								(click)="iconInput.blur()"
							>
								<img [src]="getSvgSrc(iconName)" alt="" /> &nbsp; {{ iconName }}
							</mat-option>
						</mat-autocomplete>
					</div>
				</div>

				<div class="categories-form-label">
					<label>Icon Color</label>
					<input
						type="color"
						formControlName="color"
						class="color-picker-input"
						(click)="openColorPicker()"
						#colorPicker
					/>
				</div>

				<div class="submit-buttons q-d-flex q-jc-end q-g-16 q-p-0">
					<button
						type="submit"
						[disabled]="spinner.spinner | async"
						class="btn-2 btn-2-primary imp-disabled-op"
					>
						Save
					</button>
				</div>
			</form>
		</div>

		<app-audit-trail
			*ngIf="isAuditTrailEnabled && stateAction === 'edit'"
			[id]="id"
			[url]="historyUrl.KNOWLEDGE_BASE_CATEGORY"
		></app-audit-trail>
	</div>
</div>
