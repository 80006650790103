import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, filter, lastValueFrom, skip } from 'rxjs';

import {
	CategoryList,
	KBTopTopic,
	LatestTopic,
	LatestTopic2,
	RecentlyReviewed,
	TopicsByCategory,
	TrendingTopic,
} from '@app/shared/interfaces/knowledge-base.interface';

import { environment } from 'environments/environment';
import { UserService } from '@app/core/services/user.service';

@Injectable({
	providedIn: 'root',
})
export class KnowledgeBaseService {
	categoryList: any[];
	searchText = '';
	categoryName: string = '';

	private _categories$ = new BehaviorSubject<CategoryList[]>([]);
	private _trending$ = new BehaviorSubject<KBTopTopic[]>([]);
	private _recent$ = new BehaviorSubject<KBTopTopic[]>([]);
	private _latest$ = new BehaviorSubject<KBTopTopic[]>([]);

	constructor(
		private _http: HttpClient,
		private _user: UserService,
	) {}

	clearSearch() {
		this.searchText = '';
	}

	setSearchText(searchText: string) {
		this.searchText = searchText;
	}

	initUserKB(){
		(
			this.getUserCategory(this.userId, this.companyId!) as unknown as Observable<{data:CategoryList[]}>
		).subscribe(v=>{
			this._categories$.next(v.data);
			console.log('categories', v);
			(
				this.getLatestTopics(this.companyId!) as unknown as Observable<KBTopTopic[]>
			).subscribe(v=>this._latest$.next(v));
			this.getTrendingTopics(this.companyId!).subscribe(v=>this._trending$.next(v))
			this.getRecentlyReviewed(this.companyId!).subscribe(v=>this._recent$.next(v))
		});

	}

	getUserCategory(
		userId: number,
		companyId: number
	): Observable<CategoryList[]> {
		return this._http.get(
			`${environment.apiBaseUrl}KBCategory/user/${userId}?companyId=${companyId}`
		) as Observable<CategoryList[]>;
	}

	getCategory(
		page: number,
		pageSize: number,
		companyId: number
	): Observable<CategoryList[]> {
		return this._http.get(
			`${environment.apiBaseUrl}KBCategory?Page=${page}&PageSize=${pageSize}&searchText=&companyId=${companyId}`
		) as Observable<CategoryList[]>;
	}

	getLatestTopics(companyId: number): Observable<LatestTopic[]> {
		return this._http.get(
			`${environment.apiBaseUrl}kb/latest?companyId=${companyId}`
		) as Observable<LatestTopic[]>;
	}

	getTrendingTopics(companyId: number): Observable<TrendingTopic[]> {
		return this._http.get(
			`${environment.apiBaseUrl}kb/trending?companyId=${companyId}`
		) as Observable<TrendingTopic[]>;
	}

	getRecentlyReviewed(companyId: number): Observable<RecentlyReviewed[]> {
		return this._http.get(
			`${environment.apiBaseUrl}kb/recently-viewed?companyId=${companyId}`
		) as Observable<RecentlyReviewed[]>;
	}

	getCompanyTopics(
		page: number,
		pageSize: number,
		search: string,
		categoryId: string | number,
		sort: string,
		column: string,
		companyId: number | undefined,
		startDate: string,
		endDate: string
	) {
		return this._http.get(
			`${
				environment.apiBaseUrl
			}kb/GetTopicsForCompany/${companyId}?Page=${page}&PageSize=${pageSize}&SearchText=${
				search ?? ''
			}${
				categoryId ? '&categoryId=' + categoryId : ''
			}&Order=${sort}&Column=${column}&startDate=${
				startDate !== 'Invalid date' ? startDate : ''
			}&endDate=${endDate !== 'Invalid date' ? endDate : ''}`
		);
	}

	getTopicsByCategoryId(
		categoryId: number,
		companyId: number,
		search = ''
	): Observable<TopicsByCategory[]> {
		return this._http.get(
			`${environment.apiBaseUrl}kb/category/${categoryId}?companyId=${companyId}&searchText=${search}`
		) as Observable<TopicsByCategory[]>;
	}

	getTopicById(id: number, isRecordViewCount: boolean = false) {
		return this._http.get(
			`${environment.apiBaseUrl}kb/${id}?isRecordViewCount=${isRecordViewCount}`
		);
	}

	getCompanyTopicSearch(
		page: number,
		pageSize: number,
		categoryId: string | number,
		companyId: number,
		userId: number,
		roleId: number,
		search = ''
	) {
		return this._http.get(
			`${environment.apiBaseUrl}kb/company?Page=${page}&PageSize=${pageSize}&SearchText=${search}&CategoryId=${categoryId}&CompanyId=${companyId}&UserId=${userId}&RoleId=${roleId}`
		);
	}

	getBotToken(userId: any) {
		return this._http.get(
			`${environment.apiBaseUrl}BotToken/GenerateBotToken/${userId}`
		);
	}

	topicLikeDislike(topicId: number, data: any) {
		return this._http.put(
			`${environment.apiBaseUrl}KB/LikeOrDislikeTopic/${topicId}`,
			data
		);
	}

	topicQuickAccess(topicId: number, isQa: boolean) {
		return this._http.post(
			`${environment.apiBaseUrl}kb/AddOrRemoveQuickAccess/${topicId}?value=${isQa}`,
			isQa
		);
	}
	getKBBotById(id: number) {
		return this._http.get(`${environment.apiBaseUrl}kbbot/${id}`);
	}

	getKBBotList() {
		return this._http.get(`${environment.apiBaseUrl}kbbot`);
	}

	getKBCategoryById(categoryId: number) {
		return this._http.get(`${environment.apiBaseUrl}KBCategory/${categoryId}`);
	}

	// setters and getters
	get userId(){return this._user.user!.id}
	get companyId(){return this._user.user?.companyId}

	// OUTPUT
	get categories$(){return this._categories$.asObservable().pipe(skip(1))}
	get categories(){return this._categories$.value!} // do not use in init
	get trending$(){return this._trending$.asObservable()}
	get recent$(){return this._recent$.asObservable()}
	get latest$(){return this._latest$.asObservable()}
}
