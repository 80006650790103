import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { AcronisCloudBackup } from '@app/shared/interfaces/dashboard.interface';
import { filter, Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-acronis-cloud-backup',
  templateUrl: './acronis-cloud-backup.component.html',
  styleUrls: ['./acronis-cloud-backup.component.scss']
})
export class AcronisCloudBackupComponent implements OnInit, OnDestroy {

  cloudBackup$: Observable<AcronisCloudBackup[]| null>
  isStillLoading = true;
  subs = new Subscription()

  constructor(
    private _dashboard:DashboardService,
    private _cd: ChangeDetectorRef
  ){
    this._dashboard.initAcronisCloudBackup();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.#initProperties();
  }

  #initProperties(){
    this.cloudBackup$ = this._dashboard.acronisCloudBackup$
      //.pipe(filter(v=>v!=null));
    this.subs.add(
      this.cloudBackup$
        .pipe(
          filter(v=>v!==null),
          tap(v=>{
            this.isStillLoading = false;
            this._cd.detectChanges();
          })
        )
        .subscribe()
    )
  }


}
