import { Injectable } from "@angular/core";
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { LocalStorageService } from "@app/shared/services/local-storage.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { first, map, switchMap } from "rxjs";
import { jwtRemoveAction, jwtRemoveSuccessAction, jwtUpdateAction, jwtUpdateSuccessAction } from "./jwt.action";
import { jwtSelector } from "./jwt.selector";
import * as moment from 'moment';


@Injectable()
export class JWTEffect{
  jwtUpdate$ = createEffect(()=>
  this.actions$.pipe(
    ofType(jwtUpdateAction),
    map((_data)=>{}),
    switchMap((_)=>{return this.store.pipe(
      select(jwtSelector),
      first()
    )}),
    map((data)=>{
      if(data.accessToken){
        this.localStorageService.setStorageObject('accessToken',data.accessToken);
        sessionStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('dateToday', moment().format('l').toString());
      }
      if(data.refreshToken){
        this.localStorageService.setStorageObject('refreshToken',data.refreshToken);
      }
      return jwtUpdateSuccessAction()
    })
  )
  )

  jwtUpdateSuccess$ = createEffect(()=>
  this.actions$.pipe(
    ofType(jwtUpdateSuccessAction),
    map((_data)=>{

    })
  ),
  {dispatch: false}
  )

  jwtRemove$ = createEffect(()=>
  this.actions$.pipe(
    ofType(jwtRemoveAction),
    map((_data)=>{}),
    switchMap((_)=>{return this.store.pipe(
      select(jwtSelector),
      first()
    )}),
    map((_data)=>{
     this.localStorageService.removeStorageObject('accessToken');
     this.localStorageService.removeStorageObject('refreshToken');
     sessionStorage.removeItem('accessToken');
      return jwtRemoveSuccessAction()
    })
  )
  )

  jwtRemoveSuccess$ = createEffect(()=>
  this.actions$.pipe(
    ofType(jwtRemoveSuccessAction),
    map((_data)=>{

    })
  ),
  {dispatch: false}
  )

  constructor(private actions$: Actions, private store: Store<AppStateInterface>, private localStorageService: LocalStorageService){}
}
