<ng-container *ngIf="isCard">
	<ng-container *ngIf="branName.toUpperCase() === cardLogo.visa">
		<app-local-figma-icon-img
			[filename]="'visa'"
			[width]="48"
			[height]="15"
		></app-local-figma-icon-img>
	</ng-container>

	<ng-container *ngIf="branName.toUpperCase() === cardLogo.masterCard">
		<app-local-figma-icon-img
			[filename]="'mastercard'"
			[width]="48"
			[height]="30"
		></app-local-figma-icon-img>
	</ng-container>

	<ng-container *ngIf="branName.toUpperCase() === cardLogo.discover">
		<app-local-figma-icon-img
			[filename]="'discover'"
			[width]="32"
			[height]="32"
		></app-local-figma-icon-img>
	</ng-container>

	<ng-container *ngIf="branName.toUpperCase() === cardLogo.unionPay">
		<app-local-figma-icon-img
			[filename]="'unionpay'"
			[width]="32"
			[height]="32"
		></app-local-figma-icon-img>
	</ng-container>

	<ng-container *ngIf="branName.toUpperCase() === cardLogo.jcb">
		<app-local-figma-icon-img
			[filename]="'jcb'"
			[width]="32"
			[height]="32"
		></app-local-figma-icon-img>
	</ng-container>

	<ng-container *ngIf="branName.toUpperCase() === cardLogo.dinersClub">
		<app-local-figma-icon-img
			[filename]="'diners-club'"
			[width]="32"
			[height]="32"
		></app-local-figma-icon-img>
	</ng-container>

	<ng-container *ngIf="branName.toUpperCase() === cardLogo.americanExpress">
		<app-local-figma-icon-img
			[filename]="'american-express'"
			[width]="32"
			[height]="32"
		></app-local-figma-icon-img>
	</ng-container>
</ng-container>

<ng-container *ngIf="!isCard">
	<app-figma-icon-img
		[iconName]="'bank'"
		[hasSpaceOnRight]="false"
	></app-figma-icon-img>
</ng-container>
