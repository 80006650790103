import { ActivatedRoute, Router } from '@angular/router';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import {
	BehaviorSubject,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	merge,
	Observable,
	Subscription,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SortDirection } from '@angular/material/sort';

import { AuditTrailComponent } from '@app/shared/components/audit-trail/audit-trail.component';
import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';
import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';

import { AccessSettingsService } from '@app/shared/services/access-settings.service';
import { AccountService } from '@app/modules/account/account.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { GroupsService } from './../../../groups/groups.service';
import { KnowledgeBaseService } from '../../knowledge-base.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SessionStorageService } from './../../../../shared/services/session-storage.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UserService } from '@app/core/services/user.service';

import { apiUrl } from '@app/shared/functions/options-copy';
import { OptionsModel } from '@app/shared/functions/options';

import {
	Permission,
	HistoryUrl,
	NotificationMessages,
} from '@app/shared/constants';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IKnowledgeBase } from './store/kb-topic.interface';
import { IPageOption } from '@app/shared/new-table-shared/new-table-shared.interface';

import { companyKbTopicsSelector } from './store/companies/kb-topic.companies.selector';
import { contactsKbTopicsSelector } from './store/contacts/kb-topic.contacts.selector';
import { customKbTopicsSelector } from './store/custom/kb-topic.custom.selector';
import { globalKbTopicsSelector } from './store/global/kb-topic.selector';
import { groupKbTopicsSelector } from './store/group/kb-topic.group.selector';

import { companyKbTopicsUpdateAction } from './store/companies/kb-topic.companies.actions';
import { contactsKbTopicsUpdateAction } from './store/contacts/kb-topic.contacts.actions';
import { customKbTopicsUpdateAction } from './store/custom/kb-topic.custom.actions';
import { globalKbTopicsUpdateAction } from './store/global/kb-topic.actions';
import { groupKbTopicsUpdateAction } from './store/group/kb-topic.group.actions';

@Component({
	selector: 'app-kb-topic-list',
	templateUrl: './kb-topic-list.component.html',
	styleUrls: ['./kb-topic-list.component.scss'],
})
export class KbTopicListComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild(AuditTrailComponent) auditTrail: AuditTrailComponent;
	@ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;
	@ViewChild('searchTextbox') searchTextbox: ElementRef;
	@ViewChild('startDate') startDate: ElementRef;
	@ViewChild('endDate') endDate: ElementRef;
	@Input() viewOptions: any;
	@Input() isCustom?: boolean;
	@Input() isMainPage = true;

	form = new FormGroup({
		categoryIds: new FormControl<number[]>([]),
	});
	groupId: any;
	companyId: number | undefined;
	categoryId: string | number = '';
	userId: string | undefined;
	categoryList: any[] = [];
	searchPlaceHolder = 'Search Topics';
	categories$: Observable<{ name: string; id: number }[]>;
	isClient: boolean;

	isViewEnabled = this._userService.hasPermission([
		Permission.CompanyAdminKbTopicView,
		Permission.SpAdminKbTopicView,
	]);
	isManageEnabled = this._userService.hasPermission([
		Permission.CompanyAdminKbTopicAddEdit,
		Permission.SpAdminKbTopicAddEdit,
	]);
	isAccessEnabled = false;

	filterUrl: string = 'sort15';
	dropdownOption: any[] = [];
	historyUrl = HistoryUrl;

	/**Date Filter Implementation */
	// Start Date
	_startDate = ''; // we need this since we are not getting the values directly from a formcontrol
	// End Date
	_endDate = ''; // we need this since we are not getting the values directly from a formcontrol
	queryStartDate = new FormControl('');
	queryEndDate = new FormControl('');
	visibilityOption: boolean;
	dataOptions: OptionsModel;
	loading = true;

	categoryName?: string;

	queryCategory = [0];
	storeSelector: (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase>;
	storeAction: any;
	moduleLevel: apiUrl;

	resetVal = false;

	searchFilters: IKnowledgeBase;
	search = new FormControl();
	order: SortDirection;
	page: number;
	pageSize: number;
	column: string;
	kbOptions: IPageOption;
	kbTopicsData: any;

	private _subscription = new Subscription();
	private _afterLoad$ = new BehaviorSubject<boolean | null>(null);

	constructor(
		public kbService: KnowledgeBaseService,
		public spinner: SpinnerService,
		public override _companiesService: CompaniesService,
		public override _route: ActivatedRoute,
		private _accessSettingsService: AccessSettingsService,
		private _accountService: AccountService,
		private _activatedRoute: ActivatedRoute,
		private _breadcrumbService: BreadcrumbService,
		private _cd: ChangeDetectorRef,
		private _contactsService: ContactsService,
		private _dataModalService: DataModalService,
		private _groupsService: GroupsService,
		private _notifier: NotificationService,
		private _renderer: Renderer2,
		private _router: Router,
		private _sessionStorageService: SessionStorageService,
		private _store: Store<AppStateInterface>,
		private _toastMessageService: ToastMessageService,
		private _userService: UserService
	) {
		super(_companiesService, _route);

		const searchText = this._sessionStorageService.getStorage('global-search');

		if (searchText) {
			this.kbService.setSearchText(searchText);
		}

		if (this._route.snapshot.params['userId']) {
			_contactsService.subUserId = this._route.snapshot.params['userId'];
			this._accountService
				.getUserData(Number(atob(this._route.snapshot.params['userId'])))
				.subscribe((response: any) => {
					this.userId = window.atob(this._route.snapshot.params['userId']);
					this._contactsService.subUserId =
						this._route.snapshot.params['userId'];
					this._breadcrumbService.updateBreadCrumbsText(
						'_contactId',
						this._route.snapshot.params['userId']
					);
					this._breadcrumbService.updateBreadCrumbsText(
						'_userName',
						(response.firstName ? response.firstName : '') +
							' ' +
							(response.lastName !== null ? response.lastName : '')
					);
				});
			this.userId = window.atob(this._route.snapshot.params['userId']);
			this.companyId = this._userService.user?.companyId;
		} else if (this._activatedRoute.snapshot.params['companyId']) {
			this.companyId = this._activatedRoute.snapshot.params['companyId'];
		}
		if (
			this._userService.user?.roleId !== 1 &&
			this._userService.user?.companyId
		) {
			this.companyId = this._userService.user?.companyId;
			this.isClient = true;
		}
	}

	get globalKb(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(globalKbTopicsSelector);
	}

	get globalKbUpdate() {
		return globalKbTopicsUpdateAction;
	}

	get companyKb(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(companyKbTopicsSelector);
	}

	get companyKbUpdate() {
		return companyKbTopicsUpdateAction;
	}

	get customKb(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(customKbTopicsSelector);
	}

	get customKbUpdate() {
		return customKbTopicsUpdateAction;
	}

	get contactsKb(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(contactsKbTopicsSelector);
	}

	get contactsKbUpdate() {
		return contactsKbTopicsUpdateAction;
	}

	get groupKb(): (
		source$: Observable<AppStateInterface>
	) => Observable<IKnowledgeBase> {
		return select(groupKbTopicsSelector);
	}

	get groupKbUpdate() {
		return groupKbTopicsUpdateAction;
	}

	ngOnInit() {
		this._subscription.add(
			this.viewOptions
				.pipe(
					filter((data) => data !== null),
					take(1)
				)
				.subscribe((response: OptionsModel) => {
					// set options for global permission
					this.dataOptions = response;
					this.loading = false;
					this._afterLoad$.next(true);
					this._initView();
				})
		);
		this._getPageCompanyId();
		this._getPageUserId();
		this._getPageGroupId();
		this._getGroupId();
		this.getCompanyIdFromParams();

		this.categories$ = this.kbService
			.getKBCategoryDropdown()
			.pipe(map((data: any) => data.data));
		this.categories$.subscribe((resp) => {
			this.dropdownOption = resp;
		});

		if (
			this._activatedRoute.snapshot.params['groupId'] &&
			this._companiesService.subCompanyId &&
			this._userService.user?.roleId === 1
		) {
			this.companyId = this._companiesService.subCompanyId;
		}

		if (!this.companyId && !this.userId && !this.groupId) {
			this.moduleLevel = apiUrl.GLOBAL;
			this.storeSelector = this.globalKb;
			this.storeAction = this.globalKbUpdate;
		} else if (this.companyId && !this.userId && !this.groupId) {
			this.moduleLevel = apiUrl.COMPANY;

			if (this.isCustom === true) {
				this.storeSelector = this.customKb;
				this.storeAction = this.customKbUpdate;
			} else {
				this.storeSelector = this.companyKb;
				this.storeAction = this.companyKbUpdate;
			}
		} else if (this.companyId && this.userId && !this.groupId) {
			this.moduleLevel = apiUrl.CONTACT;
			this.storeSelector = this.contactsKb;
			this.storeAction = this.contactsKbUpdate;
		} else if (this.companyId && this.groupId && !this.userId) {
			this.moduleLevel = apiUrl.GROUP;
			this.storeSelector = this.groupKb;
			this.storeAction = this.groupKbUpdate;
		}
	}

	ngAfterViewInit() {
		this._loadPreviousFilters();
		this._clearAllFilterSubscription();
		this._searchSubscription();
		this._dateSubscription();

		this.isAccessEnabled =
			(this._userService.hasPermission(Permission.SpAdminKbTopicAddEdit) &&
				this.moduleLevel === apiUrl.GLOBAL) ||
			(this._userService.hasPermission([
				Permission.SpAdminKbTopicAddEdit,
				Permission.CompanyAdminKbTopicAddEdit,
			]) &&
				this.moduleLevel === apiUrl.COMPANY) ||
			(this._userService.hasPermission([
				Permission.SpAdminKbTopicAddEdit,
				Permission.CompanyAdminKbTopicAddEdit,
			]) &&
				this.moduleLevel === apiUrl.GROUP) ||
			(this._userService.hasPermission([
				Permission.SpAdminKbTopicAddEdit,
				Permission.CompanyAdminKbTopicAddEdit,
			]) &&
				this.moduleLevel === apiUrl.CONTACT);

		this._setKBOptions();
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	setIsCustom() {
		localStorage.setItem('kb_isCustom', String(this.isCustom));
	}

	categoryChange() {
		if (this.queryCategory.length > 1 && !this.queryCategory[0])
			this.queryCategory = this.queryCategory.slice(1);
		else if (this.queryCategory.includes(0)) this.queryCategory = [0];
		this.page = 1;
		this.fetchNewData();
	}

	setStartDate(startDate: string) {
		this._startDate = startDate;
		this.fetchNewData();
	}

	setEndDate(endDate: string) {
		this._endDate = endDate;
		this.fetchNewData();
	}

	formatDate(d: Date) {
		return moment(d).format('ll');
	}

	onDelete(app: any) {
		const data = this._dataModalService.getDeleteModel(
			'KB Topic',
			app.topic.trim()
		);

		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					this.kbService.deleteTopic(app.id).subscribe({
						next: () => {
							this.kbService.allowToFetchNewData = true;
							this.fetchNewData();
						},
						error: () => {
							this.spinner.stop();
							this._notifier.notifyError(
								'Delete Failed',
								NotificationMessages.Try
							);
						},
						complete: () => {
							this._toastMessageService.showSuccessMessage(
								NotificationMessages.delete('Topic')
							);
						},
					});
				}
			},
		});
	}

	fetchNewData() {
		if (!this.isViewEnabled) {
			this.spinner.reset();
			return;
		}

		const filters: IKnowledgeBase = this._getFilters();
		this.searchFilters = filters;
	}

	onEdit(id: number) {
		this._router.navigate(['edit'], {
			relativeTo: this._activatedRoute,
			queryParams: {
				topic: btoa(id.toString()),
			},
			queryParamsHandling: 'merge',
		});
	}

	getTopicCount(count?: number, id?: number) {
		let value = '';

		if (!this.categoryId) {
			value = `(${count})`;
		} else {
			if (this.categoryId === id) {
				value = `(${count})`;
			} else {
				value = '';
			}
		}

		return value;
	}

	onDateSearch() {
		let startDateFilter = new Date(this.startDate.nativeElement.value);
		let endDateFilter = new Date(this.endDate.nativeElement.value);

		if (this.startDate.nativeElement.value) {
			this._renderer.setProperty(
				this.endDate.nativeElement,
				'min',
				this.startDate.nativeElement.value
			);
		} else {
			this._renderer.setProperty(this.endDate.nativeElement, 'min', '');
		}

		if (startDateFilter > endDateFilter) {
			this.endDate.nativeElement.value = this.startDate.nativeElement.value;
			endDateFilter = new Date(this.endDate.nativeElement.value);
		}

		if (
			(startDateFilter.getFullYear() > 1900 &&
				!this.endDate.nativeElement.value) || // start + no end
			(!this.startDate.nativeElement.value &&
				!this.endDate.nativeElement.value) || // no start + no end
			(endDateFilter.getFullYear() > 1900 &&
				!this.startDate.nativeElement.value) || // no start + end
			(startDateFilter.getFullYear() > 1900 &&
				endDateFilter.getFullYear() > 1900) // start + end > 1900
		) {
			this.fetchNewData();
		}
	}

	getTopicById(id: number, categoryId: number, topicName: string) {
		this.categoryId = categoryId;
		const catId = this.categoryList.findIndex((x) => x.id === this.categoryId);
		sessionStorage.setItem('topicName', topicName);
		sessionStorage.setItem('categoryName', this.categoryList[catId].name);
		let pathName = 'topic';
		this._router.navigate([pathName], {
			relativeTo: this._activatedRoute,
			state: {
				topicId: id,
				categoryId: this.categoryId,
			},
			queryParams: {
				topicId: id,
				categoryId: this.categoryId,
			},
		});
	}

	getOverrideFlag() {
		this._accessSettingsService
			.getOverrideFlag(
				this.dataOptions.apiUrl,
				this.dataOptions.moduleId,
				this.dataOptions.companyId!,
				this.dataOptions.userId,
				this.dataOptions.groupId
			)
			.subscribe({
				next: (data: any) => {
					this.visibilityOption = data ? data.isChanged : false;
					this._setKBOptions();
				},
			});
	}

	updateOverrideFlag() {
		this.spinner.start();
		this._accessSettingsService
			.setOverrideFlag(
				this.dataOptions.apiUrl,
				this.dataOptions.moduleId,
				this.dataOptions.companyId!,
				this.visibilityOption,
				this.dataOptions.userId,
				this.dataOptions.groupId
			)
			.subscribe((data) => {
				this.spinner.stop();
				this.fetchNewData();
				this.auditTrailRefresh();
			});
	}

	updateAccessVisibility(row: any) {
		this.spinner.start();
		const body = {
			levelAccess: this.dataOptions.apiUrl!,
			moduleId: this.dataOptions.moduleId!,
			appId: row.id,
			isAllowed: !row[this.dataOptions.columnSettings.fieldName!],
			companyId:
				this.dataOptions.companyId !== null ? this.dataOptions.companyId : null,
			userId: this.dataOptions.userId !== null ? this.dataOptions.userId : null,
			groupId:
				this.dataOptions.groupId !== null ? this.dataOptions.groupId : null,
		};
		this._accessSettingsService
			.updateFlag(body)
			.pipe(take(1))
			.subscribe({
				next: (data) => {
					this.spinner.stop();
					row[this.dataOptions.columnSettings.fieldName!] =
						!row[this.dataOptions.columnSettings.fieldName!];
					this.auditTrailRefresh();
				},
			});
	}

	auditTrailRefresh() {
		if (this.auditTrail) this.auditTrail.refresh();
	}

	private _getFilters(): IKnowledgeBase {
		const filter: IKnowledgeBase = {
			page: this.page,
			pageSize: this.pageSize,
			order: this.order,
			column: this.column,
			categoryId: this.queryCategory[0] ? this.queryCategory : [],
			dateStart: this.queryStartDate.value! || this.kbTopicsData.dateStart,
			dateEnd: this.queryEndDate.value! || this.kbTopicsData.dateEnd,
			query: this.search.value!.trim(),

			companyId: this.companyId ? this.companyId : null,
			userId: this.userId !== null ? this.userId : null,
			isCustom: this.isCustom,
			groupId: this.groupId !== null ? this.groupId : null,
			userRole: this._userService.userRole,
		};

		return filter;
	}

	private _initView() {
		this.visibilityOption = this.dataOptions.hasOverrideFlag ? false : true;
		this._setKBOptions();

		if (this.dataOptions.hasOverrideFlag && this.dataOptions.companyId) {
			this.getOverrideFlag();
		}
	}

	private _setKBOptions() {
		this.kbOptions = {
			visibilityOption: this.visibilityOption,
			dataOptions: this.dataOptions,
			isCustom: this.isCustom,
			isAccessEnabled: this.isAccessEnabled,
		};
		this._cd.detectChanges();
	}

	private _searchSubscription() {
		const inputEvent = this.search.valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged()
		);

		const enterEvent = fromEvent(
			this.searchTextbox.nativeElement as HTMLInputElement,
			'keyup'
		).pipe(
			filter((e) => (e as KeyboardEvent).keyCode === 13),
			map((e) => (e.target as HTMLInputElement).value)
		);

		this._subscription.add(
			merge(inputEvent, enterEvent)
				.pipe(
					distinctUntilChanged(),
					switchMap(async (query) => {
						this.page = 1;
						this.fetchNewData();

						return query;
					})
				)
				.subscribe()
		);

		this._subscription.add(
			this.kbService.isLoading$
				.pipe(
					tap((isLoading) => {
						if (isLoading) {
							this.search.disable();
						} else {
							if (this.search.disabled) {
								this.search.enable();
							}
							(this.searchTextbox.nativeElement as HTMLInputElement).focus();
						}
					})
				)
				.subscribe()
		);
	}

	private _clearAllFilterSubscription() {
		this._subscription.add(
			this.clearFilter.clearAll.subscribe(() => {
				this.form.get('categoryIds')?.setValue([]);
				this.queryStartDate.reset();
				this.queryEndDate.reset();
				this.resetVal = true; // trigger the reset
				setTimeout(() => {
					this.resetVal = false; // set back to false after you trigger reset
				}, 500);
			})
		);
	}

	private _dateSubscription() {
		this._subscription.add(
			this.queryStartDate.valueChanges
				.pipe(debounceTime(0), delay(500))
				.subscribe((date) => {
					this.fetchNewData();
					this._cd.detectChanges();
				})
		);

		this._subscription.add(
			this.queryEndDate.valueChanges
				.pipe(debounceTime(0))
				.subscribe((date) => this.fetchNewData())
		);
	}

	private _getPageGroupId() {
		if (this._route.snapshot.paramMap.get('groupId')) {
			this.groupId = this._route.snapshot.paramMap.get('groupId');
			this._groupsService.subGroupId = Number(
				this._route.snapshot.paramMap.get('groupId')
			);
		}
	}

	private _getPageCompanyId() {
		if (this._route.snapshot.paramMap.get('companyId')) {
			this._companiesService.subCompanyId = Number(
				this._route.snapshot.paramMap.get('companyId')
			);
			this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(
				this._companiesService.subCompanyId!
			);
		}
	}

	private _getPageUserId() {
		if (this._route.snapshot.params['userId']) {
			this._contactsService.subUserId = this._route.snapshot.params['userId'];
			this._accountService
				.getUserData(Number(atob(this._route.snapshot.params['userId'])))
				.subscribe((response: any) => {
					this._contactsService.subUserId =
						this._route.snapshot.params['userId'];
					this._breadcrumbService.updateBreadCrumbsText(
						'_contactId',
						this._route.snapshot.params['userId']
					);
					this._breadcrumbService.updateBreadCrumbsText(
						'_userName',
						(response.firstName ? response.firstName : '') +
							' ' +
							(response.lastName !== null ? response.lastName : '')
					);
				});
		}
	}

	private _getGroupId() {
		if (this._groupsService.subGroupId) {
			this._groupsService
				.getGroupForBreadcrumb(this._groupsService.subGroupId!)
				.pipe(take(1))
				.subscribe((group: any) => {
					this._breadcrumbService.updateBreadCrumbsText(
						'_groupName',
						group.name
					);
				});
		}
	}

	private _loadPreviousFilters() {
		this._store.pipe(this.storeSelector, take(1)).subscribe((filter) => {
			this.kbTopicsData = filter;
			this.queryCategory = (filter.categoryId as number[]).length
				? (this.kbTopicsData.categoryId as number[])
				: [0];
			this.search.setValue(filter.query!, { emitEvent: false });
			this.queryStartDate.setValue(filter.dateStart!, {
				emitEvent: false,
			});
			this.queryEndDate.setValue(filter.dateEnd!, {
				emitEvent: false,
			});
			this.page = filter.page;
			this.pageSize = filter.pageSize;
			this.column = filter.column;
			this.column = filter.column;
			this.order = filter.order;

			this.fetchNewData();
		});
	}
}
