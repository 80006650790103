import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './phone-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';

@NgModule({
  declarations: [
    PhoneInputComponent
  ],
  imports: [
    CommonModule,
    FigmaIconImgModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [PhoneInputComponent]
})
export class PhoneInputModule { }
