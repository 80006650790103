import { Injectable } from '@angular/core';

import { BehaviorSubject, filter } from 'rxjs';

import { UserService } from '@app/core/services/user.service';

import { TableSignalType } from '../constants/global-enum';

@Injectable({
	providedIn: 'root',
})

export class TableReloadSignalService {
	allowToFetchNewDataTicketPage: boolean = false;
	allowToFetchNewDataQuotePage: boolean = false;
	allowToFetchNewDataOrdersPage: boolean = false;
	allowToFetchNewDataInvoiceTab: boolean = false;

	private _onReloadTable = new BehaviorSubject<string>('');
	onReloadTable = this._onReloadTable.asObservable();

	setTypeOfTableToBeReload(tableType: string, hasAccess: boolean) {
		if (!hasAccess) { return; }

		this._onReloadTable.next(tableType);
	}

	tableReloadFromSignaIR(tableType: string) {
		return this.onReloadTable.pipe(filter((x) => x === tableType));
	}

	allowToFetchNewData(tableType: string, userService: UserService) {
		switch (tableType) {
			case TableSignalType.TICKET:
				this.allowToFetchNewDataTicketPage = true;
				this.setTypeOfTableToBeReload(tableType, userService.isUser);
				break;

			case TableSignalType.QUOTE:
				this.allowToFetchNewDataQuotePage = true;
				this.setTypeOfTableToBeReload(tableType, userService.isUser);
				break;

			case TableSignalType.ORDER:
				this.allowToFetchNewDataOrdersPage = true;
				this.setTypeOfTableToBeReload(tableType, userService.isUser);
				break;

			case TableSignalType.INVOICE:
				this.allowToFetchNewDataInvoiceTab = true;
				this.setTypeOfTableToBeReload(tableType, userService.isUser || userService.isSpAdmin);
				break;
		}
	}
}
