import { LocalFigmaIconImgModule } from '@app/shared/local-figma-icon-img/local-figma-icon-img.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmailDropdownInputComponent } from './email-dropdown-input.component';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';
@NgModule({
  declarations: [EmailDropdownInputComponent],
  imports: [
    CommonModule,
		ReactiveFormsModule,
		FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatChipsModule,
    FormTooltipModule,
    MatAutocompleteModule,
    LocalFigmaIconImgModule,
    FigmaIconImgModule
  ],
  exports: [EmailDropdownInputComponent],
})
export class EmailDropdownInputModule {}
