<div class="et-container">
	<div class="heading-container">
		<div class="title-heading">
			<h3>Platform Usage</h3>
		</div>
		<div class="action-heading">
			<app-tertiary-button
				btnText="Collapse Chart"
				iconName="expand-01"
				[isIconLeft]="false"
				[isSelected]="true"
				(click)="closeModal(false)"
			></app-tertiary-button>
		</div>
	</div>
	<div class="graph-container">
		<div class="row">
			<div class="graph-container col-12">
				<monthly-activity-graph></monthly-activity-graph>
			</div>
		</div>

		<div class="row p-0">
			<div class="acitivity-summary">
				<ng-container *ngTemplateOutlet="daily"></ng-container>
				<ng-container *ngTemplateOutlet="sevenDays"></ng-container>
				<ng-container *ngTemplateOutlet="currentMonth"></ng-container>
			</div>
		</div>

		<ng-template #currentMonth>
			<div
				class="currentMonth"
				*ngIf="
					data.templateData!['page'].currentMonthlyUsage$ | async as monthly
				"
			>
				<h3 class="underline">Current Month Usage</h3>
				<div class="noOfUsers">
					<h3>{{ $any(monthly).currentMonthUsers | number }}</h3>
					<span>Number of users</span>
				</div>
				<div class="userActivity">
					<h3>{{ $any(monthly).currentMonthActivity | number }}</h3>
					<span>User activity</span>
				</div>
			</div>
		</ng-template>

		<ng-template #sevenDays>
			<div
				class="sevenDays"
				*ngIf="data.templateData!['page'].pastWeeklyUsage$ | async as weekly"
			>
				<h3 class="underline">Past 7 Days Usage</h3>
				<div class="noOfUsers">
					<h3>{{ $any(weekly).pastWeekUsers | number }}</h3>
					<span>Number of users</span>
				</div>
				<div class="userActivity">
					<h3>{{ $any(weekly).pastWeekActivity | number }}</h3>
					<span>User activity</span>
				</div>
			</div>
		</ng-template>

		<ng-template #daily>
			<div
				class="dailyReport"
				*ngIf="data.templateData!['page'].dailyUsage$ | async as daily"
			>
				<h3 class="underline">Current Day Usage</h3>
				<div class="noOfUsers">
					<h3>{{ $any(daily).todayUsers | number }}</h3>
					<span>Number of users</span>
				</div>
				<div class="userActivity">
					<h3>{{ $any(daily).todayActivity | number }}</h3>
					<span>User activity</span>
				</div>
			</div>
		</ng-template>
	</div>
</div>
