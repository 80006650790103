import { ISatisfactionScore } from './../components/gauge-meter/gauge-meter.component';
import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { ITotalTickets } from '../components/bar-graph/bar-graph.component';
import * as humanizeDuration from 'humanize-duration';
import { PDF } from '@app/shared/classes/pdf.class';
import jsPDF from 'jspdf';

export interface IEmployeesData {
	totalOnboarded: number;
	totalOffboarded: number;
	data: IEmployeeDate[];
	lastUpdated: string;
}

export interface IEmployeeDate {
	year: string;
	month: string;
	onboarded: number;
	offboarded: number;
}
export interface IServiceTicketCategories {
	totalTickets: number;
	lastUpdated: string;
	data: {
		categoryId: number;
		categoryName: string;
		subCategoryId: number;
		tickets: number;
	}[];
}
export interface ISourceCategories {
	data: [];
	totalRequests: number;
	lastUpdated: string;
}

export interface IResponseTime {
	data: {
		responseTime?: number;
		resolutionTime?: number;
		diffFromPrevScore: number;
		message: string;
	};
	lastUpdated: string;
}

export interface ITopDevicesTicketSubmission {
	data: {
		deviceId: number;
		deviceName: string;
		noOfTickets: number;
		resolutionTime: string;
		warrantyExpiration: string;
		ticketPercentage: number;
	}[];
	lastUpdated: string;
}

export interface ITopUsersTicketSubmission {
	data: {
		userId: number;
		userName: string;
		noOfTickets: number;
		resolutionTime: string;
	}[];
	lastUpdated: string;
}

export interface IAcknowledgementTime {
	data: {
		acknowledgementTime?: number;
		resolutionTime?: number;
		diffFromPrevScore: number;
		message: string;
	};
	lastUpdated: string;
}

export interface ISatisfactionScoreDetailsResponse {
	data: ISatisfactionScoreDetails[];
	lastUpdated: string;
}

export interface ISatisfactionScoreDetails {
	name: string;
	comments: string;
	rating: string;
}

export interface ITicketStatus {
	data: any[];
	statuses: string[];
	topStatuses: any[];
}
@Injectable({
	providedIn: 'root',
})
export class ReportApiService {
	http = inject(HttpClient);

	async downloadPDF(element: ElementRef, pdfName: string) {
		try {
			element.nativeElement.style.border = '13px solid #dfe0e2';
			const elementWidth = element.nativeElement.clientWidth;
			const elementHeight = element.nativeElement.clientHeight;
			const newElementWidth = 459 * 0.9; //459 is portrait width
			const newElementHeight = (newElementWidth / elementWidth) * elementHeight;
			let pdf = new PDF('portrait', [459, newElementHeight + 40]);
			const imgData = await pdf.loadImage(element);
			// const scale = 0.15;

			pdf.addImage(
				imgData,
				'PNG',
				'center',
				20,
				newElementWidth,
				newElementHeight
			);
			pdf.completePDF(pdfName);
			element.nativeElement.style.border = 'none';
		} catch (error) {
			console.error('Error generating PDF:', error);
			// Handle the error gracefully, e.g., show an error message to the user
		}
	}

	getSatisfactionScoreDetails(): Observable<ISatisfactionScoreDetailsResponse | null> {
		return this.http.get<ISatisfactionScoreDetailsResponse>(
			`${environment.apiBaseUrl}DashboardReport/GetSatisfactionScoreDetails`
		);
	}

	getSatisfactionScore(isVIP: boolean): Observable<ISatisfactionScore | null> {
		return this.http.get<ISatisfactionScore>(
			`${environment.apiBaseUrl}DashboardReport/GetSatisfactionScore?isVIP=${isVIP}`
		);
	}

	getTotalTickets(isVIP: boolean): Observable<ITotalTickets | null> {
		return this.http.get<ITotalTickets>(
			`${environment.apiBaseUrl}DashboardReport/GetTotalTickets?isVIP=${isVIP}`
		);
	}

	getUserRequestsBySource(
		isVIP: boolean
	): Observable<ISourceCategories | null> {
		return this.http.get<ISourceCategories>(
			`${environment.apiBaseUrl}DashboardReport/GetUserRequestSource?isVIP=${isVIP}`
		);
	}

	getResponseTime(isVIP: boolean): Observable<IResponseTime | null> {
		return this.http.get<IResponseTime>(
			`${environment.apiBaseUrl}DashboardReport/GetResponseTime?isVIP=${isVIP}`
		);
	}

	getAcknowledgementTime(
		isVIP: boolean
	): Observable<IAcknowledgementTime | null> {
		return this.http.get<IAcknowledgementTime>(
			`${environment.apiBaseUrl}DashboardReport/GetAcknowledgementTime?isVIP=${isVIP}`
		);
	}

	getTicketStatusTimeSpent(
		filterType: number,
		isVIP: boolean
	): Observable<ITicketStatus | null> {
		return this.http.get<any>(
			`${environment.apiBaseUrl}DashboardReport/GetTicketStatusTimeSpent?filterType=${filterType}&isVIP=${isVIP} `
		);
	}

	getResponseResolution(
		apiType: number,
		filterType: number,
		isVIP: boolean
	): Observable<any | null> {
		return this.http.get<any>(
			`${environment.apiBaseUrl}DashboardReport/GetResponseOrResolutionTime?apiType=${apiType}&filterType=${filterType}&isVIP=${isVIP}`
		);
	}

	getResolutionTime(isVIP: boolean): Observable<IResponseTime | null> {
		return this.http.get<IResponseTime>(
			`${environment.apiBaseUrl}DashboardReport/GetResolutionTime?isVIP=${isVIP}`
		);
	}

	getServiceTicketCategories(
		isVIP: boolean
	): Observable<IServiceTicketCategories | null> {
		return this.http.get<IServiceTicketCategories>(
			`${environment.apiBaseUrl}DashboardReport/GetServiceTicketCategories?isVIP=${isVIP}`
		);
	}

	getTopDevicesTicketSubmission(
		isVIP: boolean
	): Observable<ITopDevicesTicketSubmission | null> {
		return this.http.get<ITopDevicesTicketSubmission>(
			`${environment.apiBaseUrl}DashboardReport/GetTopDevicesTicketSubmission?isVIP=${isVIP}`
		);
	}

	getTopUsersTicketSubmission(
		isVIP: boolean
	): Observable<ITopUsersTicketSubmission | null> {
		return this.http.get<ITopUsersTicketSubmission>(
			`${environment.apiBaseUrl}DashboardReport/GetTopUsersTicketSubmission?isVIP=${isVIP}`
		);
	}

	getTopSitesTicketSubmission(
		isVIP: boolean
	): Observable<ITopUsersTicketSubmission | null> {
		return this.http.get<ITopUsersTicketSubmission>(
			`${environment.apiBaseUrl}DashboardReport/GetTopSitesTicketSubmission?isVIP=${isVIP}`
		);
	}

	getEmployeesOnboardedOffboarded(
		isVIP: boolean
	): Observable<IEmployeesData | null> {
		return this.http.get<IEmployeesData>(
			`${environment.apiBaseUrl}DashboardReport/GetEmployeesOnboardedAndOffboarded?isVIP=${isVIP}`
		);
	}

	getTopServiceTickets(
		categoryId: number,
		subCategoryId: number,
		isVIP: boolean
	): Observable<any> {
		return this.http.get<any>(
			`${environment.apiBaseUrl}DashboardReport/GetTopServiceTickets?categoryId=${categoryId}&subCategoryId=${subCategoryId}&isVIP=${isVIP}`
		);
	}

	downloadServiceReportPDF(isVIP: boolean) {
		return this.http.get(
			`${environment.apiBaseUrl}DashboardReport/GetServiceReportPdf?isVIP=${isVIP}`
		);
	}

	convertHuman(min: number, type: string) {
		const ms = min * 60 * 1000;

		let units: humanizeDuration.Unit[] = [];
		if (type === 'hour') {
			units = ['h'];
		} else if (type === 'double') {
			if (ms > 86400000) {
				units = ['d', 'h'];
			} else if (ms === 86400000) {
				units = ['d'];
			} else if (ms > 3600000) {
				units = ['h', 'm'];
			} else if (ms === 3600000) {
				units = ['h'];
			} else if (ms > 60000) {
				units = ['m', 's'];
			} else if (ms >= 1000) {
				units = ['s'];
			} else {
				units = ['ms'];
			}
		} else if (type === 'single') {
			if (ms >= 86400000 * 365) {
				units = ['y'];
			} else if (ms >= 86400000 * 30) {
				units = ['mo'];
			} else if (ms >= 86400000 * 7) {
				units = ['w'];
			} else if (ms >= 86400000) {
				units = ['d'];
			} else if (ms > 3600000) {
				units = ['h'];
			} else if (ms >= 60000) {
				units = ['m'];
			} else if (ms >= 1000) {
				units = ['s'];
			} else {
				units = ['ms'];
			}
		}

		const duration = humanizeDuration(ms, { units: units, round: true });
		return duration;
	}

	getIntervals(data: number[], interval: number) {
		let max = Math.max(...data);
		while (Math.round(max) % interval !== 0) {
			max++;
		}
		return max > 1 ? Math.round(max) : Math.ceil(max);
	}

	isGreaterThan1Hour(data: number[]) {
		let max = Math.max(...data);
		return max >= 1 ? true : false;
	}
}
