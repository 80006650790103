import { on, createReducer } from '@ngrx/store';

import { PaginationConstants } from '@app/shared/constants';

import { IApplication } from '../../application.interface';

import {
	contactsCompanyApplicationUpdateAction,
	contactsCompanyApplicationUpdateSuccessAction,
} from './application.actions';

const initialState: IApplication = {
	page: 1,
	pageSize: PaginationConstants.pageSize,
	order: 'asc',
	column: 'name',
	query: '',
	totalItems: 0,
	categoryId: [],
};

export const contactsCompanyApplicationReducer = createReducer(
	initialState,
	on(
		contactsCompanyApplicationUpdateAction,
		(state, action): IApplication => ({
			...state,
			...action,
		})
	),
	on(
		contactsCompanyApplicationUpdateSuccessAction,
		(state): IApplication => ({
			...state,
		})
	)
);
