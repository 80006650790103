export function getInitials(set: InitialsSet){
  let initials = '', 
  tFirst = typeof set.firstName === 'string', 
  tLast = typeof set.lastName === 'string',
  tEmail = typeof set.email === 'string';
  set = trimAll(set);
  
  if(tFirst && tLast && set.firstName && set.lastName)
    initials = set.firstName.substring(0,1)+set.lastName.substring(0,1)
  else if(tFirst && set.firstName && !set.lastName)
    initials = set.firstName.substring(0,2)
  else if(tLast && !set.firstName && set.lastName)
    initials = set.lastName.substring(0,2)
  else if(tEmail && !set.firstName && !set.lastName)
    initials = set.email.substring(0,2)

  return initials;
}

function trimAll(obj: any){
  let newObj = obj;
  for(const prop in obj){
    newObj[prop] = obj[prop]? (obj[prop] as string).trim(): '';
  }
  return newObj;
}

interface InitialsSet{
  firstName: string,
  lastName: string,
  email: string,
}