import { createAction, props } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';

export enum ActionTypes {
	COMPANY_APP_CATEGORY_LIST_UPDATE = '[Company Application Category List] Update',
	COMPANY_APP_CATEGORY_LIST_UPDATE_SUCCESS = '[Company Application Category List] Update Success',
}

export const companyAppCategoryListUpdateAction = createAction(
	ActionTypes.COMPANY_APP_CATEGORY_LIST_UPDATE,
	props<Partial<IPageState>>()
);

export const companyAppCategoryListUpdateSuccessAction = createAction(
	ActionTypes.COMPANY_APP_CATEGORY_LIST_UPDATE_SUCCESS
);
