export enum TemplateItems {
	'heading',
	'subtitle',
	'body-text',
	'image',
	'button',
	'table',
	'qr-code',
	'url-link',
	'divider',
	'footer',
	'one-column',
	'two-column',
	'three-column',
	'four-column',
	'largeOne-two-column',
}

export enum ItemTypes { // determines the type of items currently selected
	'blocks',
	'layout',
}

export interface BlocksItem {
	item: TemplateItems;
	types: ItemTypes;
	title: string;
	svgIcon: string;
}

export interface SettingsMode {
	mode: 'settings' | 'content';
	label: string;
}

export const BLOCKS_ITEM = [
	{
		item: TemplateItems.heading,
		types: ItemTypes.blocks,
		title: 'Heading',
		svgIcon: 'heading-02',
	},
	{
		item: TemplateItems.subtitle,
		types: ItemTypes.blocks,
		title: 'Subtitle',
		svgIcon: 'menu-05',
	},
	{
		item: TemplateItems['body-text'],
		types: ItemTypes.blocks,
		title: 'Body Text',
		svgIcon: 'menu-03',
	},
	{
		item: TemplateItems.image,
		types: ItemTypes.blocks,
		title: 'Image',
		svgIcon: 'image-01',
	},
	{
		item: TemplateItems.button,
		types: ItemTypes.blocks,
		title: 'Button',
		svgIcon: 'cursor-box',
	},
	{
		item: TemplateItems.table,
		types: ItemTypes.blocks,
		title: 'Table',
		svgIcon: 'table',
	},
	{
		item: TemplateItems['qr-code'],
		types: ItemTypes.blocks,
		title: 'QR Code',
		svgIcon: 'qr-code-01',
	},
	{
		item: TemplateItems['url-link'],
		types: ItemTypes.blocks,
		title: 'URL Link',
		svgIcon: 'link-01',
	},
	{
		item: TemplateItems.divider,
		types: ItemTypes.blocks,
		title: 'Divider',
		svgIcon: 'divider',
	},
	{
		item: TemplateItems.footer,
		types: ItemTypes.blocks,
		title: 'Footer',
		svgIcon: 'flex-align-bottom',
	},
];

export const cSettingsMode = {
	content: {
		mode: 'content',
		label: 'Switch to Settings',
	},
	settings: {
		mode: 'settings',
		label: 'Switch to Content',
	},
};

export const LAYOUT_ITEM = [
	{
		item: TemplateItems['one-column'],
		types: ItemTypes.layout,
		title: 'One Column',
		svgIcon: '',
	},
	{
		item: TemplateItems['two-column'],
		types: ItemTypes.layout,
		title: 'Two Column',
		svgIcon: '',
	},
	{
		item: TemplateItems['three-column'],
		types: ItemTypes.layout,
		title: 'Three Column',
		svgIcon: '',
	},
	{
		item: TemplateItems['four-column'],
		types: ItemTypes.layout,
		title: 'Four Column',
		svgIcon: '',
	},
	{
		item: TemplateItems['largeOne-two-column'],
		types: ItemTypes.layout,
		title: 'Two Column - Part 2',
		svgIcon: '',
	},
];

//export const MAPPED_BLOCK_ITEMS_ITEM = BLOCKS_ITEM.map((v) => v.item);
export interface EmailTemplateSelectedElement {
	element: HTMLElement;
	type: ElementType;
}

export type ElementType = 'image' | 'text' | 'button';

export interface EmailTemplateState {
	mode: EmailTemplateMode;
	focusItem: TemplateItems | null;
	itemType: ItemTypes | null;
	selectedElement: HTMLElement | null;
}

export type EmailTemplateMode = 'edit' | 'preview';

// this is display:flex using 'justify-content'
export enum EmailTemplateAlign {
	FLEX_START = 'flex-start',
	FLEX_END = 'flex-end',
	CENTER = 'center',
	SPACE_EVENLY = 'space-evenly',
}

export const KeysToCSS: any = {
	fontFamily: 'font-family',
	fontWeight: 'font-weight',
	fontSize: 'font-size',
	lineHeight: 'line-height',
	textColor: 'color',
	align: 'justify-content',
	backgroundColor: 'background-color',
	outlineColor: 'border-color',
	outlineWeight: 'border-width',
	height: 'height',

	// verticalSpacing // px but needs special case
	// horizontalSpacing: 	// px but needs special case
	// horizontalResizing // px but needs special case - fixed / hug / fill
	// verticalResizing // px but needs special case - fixed / hug / fill
	// horizontalPadding // px
	// verticalPadding // px
	// isBold: false,
	// isItalic: false,
	// isUnderline: false,
	// isStrikeThrough: false,
};

export const KeysToCSSException: string[] = [
	'verticalSpacing', // px but needs special case
	'horizontalSpacing', // px but needs special case
	'horizontalResizing', // px but needs special case - fixed / hug / fill
	'verticalResizing', // px but needs special case - fixed / hug / fill
	'horizontalPadding', // px
	'verticalPadding', // px
	'isBold',
	'isItalic',
	'isUnderline',
	'isStrikeThrough',
];
