import { ElementRef, AfterViewInit } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-modal',
  templateUrl: './yes-no-modal.component.html',
  styleUrls: ['./yes-no-modal.component.scss'],
})
export class YesNoModalComponent implements OnInit, AfterViewInit {
  TODO: "DELETE COMPONENT"
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<YesNoModalComponent>,
    private _elRef: ElementRef
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    const overlay = this._elRef.nativeElement.closest(
      '.cdk-overlay-pane'
    ) as HTMLElement;
    overlay.classList.add('delete-modal');
  }

  close(value: string) {
    this.dialogRef.close({ event: value });
  }
}
