<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="card-header  pb-0">
          <div style="width:100%;">
            <ng-container *ngTemplateOutlet="cardHeader"></ng-container>
            <ng-container *ngTemplateOutlet="orderHeader"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <!-- <div class="card-body px-0 pb-0 pt-0"> -->
        <ng-container *ngTemplateOutlet="productTbl"></ng-container>
      <!-- </div> -->
    </div>
    <div class="card">
      <!-- <div class="card-body px-0 pb-0 pt-0"> -->
        <ng-container *ngTemplateOutlet="invoiceTbl"></ng-container>
      <!-- </div> -->
    </div>
  </div>
</div>

<!--cardHeader Template-->
<ng-template #cardHeader>
  <div class="d-lg-flex">
    <div>
      <h6>Order Details</h6>
    </div>
    <div class="ms-auto my-auto mt-lg-0 mt-4">
      <div class="actions-wrapper">
        <a
          *ngIf="isInquireEnabled && orderInquiryFormId"
          class="btn-2 btn-2-primary"
          routerLink="/service-and-support/new-ticket/{{orderInquiryFormId}}/create"
          activityTracker
          [activityAction]="permission.SubmitOrderInquiry"
        >Submit an Inquiry</a>

        <app-secondary-button
          [isBackButton]="true"
          (click)="goBack()"
          [removeMsAuto]="true"
        ></app-secondary-button>
      </div>
    </div>
  </div>
</ng-template>

<!--orderHeader template-->
<ng-template #orderHeader>
  <div class="row mt-3" *ngIf="orderDetails as header;">
    <div class="col-lg-6 px-0">
      <div class="categories-form categories-orders-form" >
        <div class="categories-form-col row">
            <div class="form-group col-12 col-sm-6">
                <label for="example-text-input" class="form-control-label">Sales Order #</label>
                <!-- <input class="form-control text-xs" type="text" value="" placeholder="6844" /> -->
                <p class="order-content2">{{header.id}}
                  <!-- <span class="badge badge-sm bg-gradient-success">{{header.status}}</span></p> -->
                  <!-- <app-badge
                    [label]="header.status"
                    [type]="
                      header.color
                    "
                  ></app-badge> -->
            </div>

            <div class="form-group col-12 col-sm-6">
              <label class="mt-4">Subtotal</label>
              <!-- <input class="form-control text-xs" type="text" value="" placeholder="2022-04-04T14:23:19" /> -->
              <p class="order-content">{{header.subtotal | number: '1.2-2'}}</p>
            </div>
            <div class="form-group col-12 col-sm-6">
                <label class="mt-4">Customer PO #</label>
                <!-- <input class="form-control text-xs" type="text" value="" placeholder="219329" /> -->
                <p class="order-content">{{header.customerPO}}</p>
            </div>
            <div class="form-group col-12 col-sm-6">
              <label class="mt-4">Tax Total</label>
              <!-- <input class="form-control text-xs" type="text" value="" placeholder="2,335.75" /> -->
              <p class="order-content">{{header.taxTotal | number: '1.2-2' }}</p>
            </div>
    <!--         <div class="form-group col-12 col-sm-6">
                <label>Status</label>
                <p class="order-content2">{{header.status}}</p>
            </div> -->
            <div class="form-group col-12 col-sm-6">
                <label class="mt-4">Order Date</label>
                <!-- <input class="form-control text-xs" type="text" value="" placeholder="139.46" /> -->
                <p class="order-content2">{{header.orderDate | date}}</p>
            </div>


            <div class="form-group col-12 col-sm-6">
              <label class="mt-4">Total</label>
              <!-- <input class="form-control text-xs" type="text" value="" placeholder="2,335.75" /> -->
              <p class="order-content">{{header.total | number: '1.2-2' }}</p>
          </div>
          <div class="form-group col-12 col-sm-6">
            <label for="mt-4">Status</label>
            <app-badge
              [label]="header.status"
              [type]="header.color"
              [fontColor]="header.fontColor"
            ></app-badge>
          </div>
          <div class="form-group col-12 col-sm-6">
              <label class="mt-4">Description</label>
              <!-- <input class="form-control" type="text" value="" placeholder="219329" /> -->
              <!-- <textarea name="" class="form-control text-xs" placeholder="1YR Cisco Renewable (MS120 - 8FP/s#W2cx-wum3-u7ID)(ms120-48/s#Q2FX-J75Q-WS5J)(MX64W/s#Q2MN-6L58-unkp)(mr46/S#Q3AC-J8LN-SQSM,Q3AC-W54K-WTRF,Q3AC-X574-EQMU,Q2PD-9MRX-TV9Z)  Qoute:#028487 version 1"></textarea> -->
              <p class="order-content1">{{header.description}}</p>
          </div>
          <div class="form-group col-12 col-sm-6">

          </div>
          <!-- <div *ngIf="isSpAdmin()" class="form-group col-12 col-sm-6">
            <label class="mt-4">Link</label>
            <p class="order-content">
              <span *ngIf="!!header.link">
                <a
                  (click)="openURL(header.link)"
                  class="link--hidden visiblity-icon imp-disabled-op cursor-pointer"
                  [tooltipContentV2]="{ message: 'View sales order' }"
                >
                  <img src="{{'./../../../../../../assets/images/icons/link-external-icon.svg'}}" alt="">
                </a>
              </span>

              <ng-container *ngIf="!header.link">
                <span>NA</span>
              </ng-container>
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-6">
      <div class="order-details-badge-area">
        <span class="order-details-badge" [style.background]="header.color" [style.color]="header.fontColor">{{header.status}}</span>
      </div>
    </div> -->
  </div>

</ng-template>

<!--orderHeader template (previous setup)-->
<ng-template #inputTpl>
  <div class="row" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-sm-6">
        <label>Sales order#</label>
        <input
          class="form-control text-xs"
          formControlName="id"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
      <div class="col-12 col-sm-6 mt-3 mt-sm-0">
        <label>Customer Po#</label>
        <input
          class="form-control text-xs"
          formControlName="customerPO"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="mt-4">Order Date</label>
        <input
          class="form-control text-xs"
          formControlName="orderDate"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
      <div class="col-12 col-sm-6">
        <label class="mt-4">Status</label>
        <input
          class="form-control text-xs"
          formControlName="status"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <label class="mt-4">Sub Total</label>
        <input
          class="form-control text-xs"
          formControlName="subtotal"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
      <div class="col-12 col-sm-4">
        <label class="mt-4">Tax Total</label>
        <input
          class="form-control text-xs"
          formControlName="taxTotal"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
      <div class="col-12 col-sm-4">
        <label class="mt-4">Total Amount</label>
        <input
          class="form-control text-xs"
          formControlName="total"
          type="text"
          [readonly]="isReadOnly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="mt-4">Description</label>
        <textarea
          class="form-control text-xs"
          formControlName="description"
          [readonly]="isReadOnly"
          rows="6"
        ></textarea>
      </div>
    </div>
  </div>
</ng-template>

<!--product Table-->
<ng-template #productTbl>
  <div class="table-responsive directory-table">
    <div
      class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
    >
      <div class="dataTable-container">
        <app-column-slider
        [(displayedColumns)]="displayedColumns"
        ></app-column-slider>
        <table
          class="table table-flush dataTable-table"
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="id"
          matSortDirection="asc"
          matSortDisableClear="true"
          (matSortChange)="sortData($event, 'product')"
        >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              ID
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">{{ row.id }}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="identifier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Product ID
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.identifier }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="trackingNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Tracking #
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.trackingNumber }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Description
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.description }}
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>
            <td mat-cell *matCellDef="let row">
              <!-- <div class="d-flex">
                <p class="text-xs text-secondary mb-0">{{row.status}}</p>
              </div> -->
              <div class="text-xs font-weight-normal">
                <app-badge
                  [label]="row.status"
                  [type]="row.color"
                  [fontColor]="row.fontColor"
                ></app-badge>
                <!-- <span>&nbsp;{{ row.status }}</span> -->
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Quantity
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.quantity }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="unitPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Unit Price
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.unitPrice | currency }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Total
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.total | currency }}
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th class="text-center tablesaw-swipe-cellpersist-action" mat-header-cell *matHeaderCellDef>
              Action
            </th>
            <td
              class="text-center tablesaw-swipe-cellpersist-action"
              mat-cell
              *matCellDef="let row"
              (click)="$event.stopPropagation()"
            >
              <div class="d-flex align-items-center justify-content-center">
                <span *ngIf="!!row.trackingURL">
                  <a
                    (click)="openURL(row.trackingURL)"
                    class="link--hidden edit visiblity-icon imp-disabled-op"
                    [tooltipContentV2]="{ message: 'View sales order' }"
                  >
                    <img src="{{'./../../../../../../assets/images/icons/link-external-icon.svg'}}" alt="">
                  </a>
                </span>

                <ng-container *ngIf="!(!!row.trackingURL)">
                  <span>NA</span>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10">
              {{ message.noRecord }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="table-paginator-container" [hidden]="!hasData">
      <div class="paginator-group">
        <table-paginator
          #productPaginator
          [totalItems]="totalItems"
          [pageSizes]="pageSizes"
          (pageChange)="fetchNewData('product', 'page', $event)"
        ></table-paginator>
      </div>

      <div class="item-per-page-group">
        <app-item-per-page
          (sizeChange)="fetchNewData('product', 'size', $event)"
          [totalItems]="totalItems"
        ></app-item-per-page>
      </div>
    </div>
  </div>
</ng-template>

<!--invoice Table-->
<ng-template #invoiceTbl>
  <div class="table-responsive directory-table">
    <div
      class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
    >
      <div class="dataTable-container">
         <app-column-slider
              [(displayedColumns)]="displayedColumns"
              ></app-column-slider>
        <table
          class="table table-flush dataTable-table"
          mat-table
          [dataSource]="dataSourceInvoice"
          matSort
          matSortActive="invoiceNumber"
          matSortDirection="asc"
          matSortDisableClear="true"
          (matSortChange)="sortData($event, 'invoice')"
        >
          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Invoice Number
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.invoiceNumber }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="billAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              billAmount
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.billAmount | currency }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Balance
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.balance | currency }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Invoice Date
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.invoiceDate | customDate }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Due Date
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <div class="d-flex">
                <p class="text-xs text-secondary mb-0">
                  {{ row.dueDate | customDate }}
                </p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>
            <td class="dark-color" mat-cell *matCellDef="let row">
              <app-badge
                [label]="row.status"
                [type]="row.color"
                [fontColor]="row.fontColor"
              ></app-badge>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td
              class="action-options align-middle"
              mat-cell
              *matCellDef="let row"
              (click)="$event.stopPropagation()"
            >
              <span class="imp-parent">
                <a
                  class="edit visiblity-icon imp-disabled-op"
                  [tooltipContentV2]="{ message: 'Generate PDF File' }"
                  (click)="printInvoice(row.id, row.invoiceNumber)"
                >
                <img src="{{'./../../../../../../assets/images/icons/pdf-icon.svg'}}" alt="">
                </a>
              </span>

              <a
                class="edit visiblity-icon"
                [tooltipContentV2]="{ message: 'View Invoice Detail' }"
                (click)="navigateTo(row.id)"
              >
              <img src="{{'./../../../../../../assets/images/icons/eye-icon.svg'}}" alt="">
              </a>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsInvoice"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsInvoice"
            (click)="navigateTo(row.id)"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">
              {{ message.noRecord }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="table-paginator-container" [hidden]="!hasDataInvoice">
      <div class="paginator-group">
        <table-paginator
          #invoicePaginator
          [totalItems]="totalItemsInvoice"
          [pageSizes]="pageSizesInvoice"
          (pageChange)="fetchNewData('invoice', 'page', $event)"
        ></table-paginator>
      </div>

      <div class="item-per-page-group">
        <app-item-per-page
          (sizeChange)="fetchNewData('invoice', 'size', $event)"
          [totalItems]="totalItemsInvoice"
        ></app-item-per-page>
      </div>
    </div>
  </div>
</ng-template>
