import { Injectable } from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store } from '@ngrx/store';
import * as select from '../../core/store/portal-usage.selector'

@Injectable({
  providedIn: 'root'
})
export class UsersActivityGraphService {

  constructor(
    private _store:Store<AppStateInterface>,
  ){}

/*   get usersActivity$(){
    return this._store.select(select.usersActivity)
  } */

  top15UsersActivity$ = this._store.select(select.top15UsersActivity)
  usersActivityGraph$ = this._store.select(select.usersActivityGraph)
}
