import { createSelector } from '@ngrx/store';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IOrders } from './orders.interface';

export const ordersSelector = (state: AppStateInterface): IOrders =>
	state.orders;

export const pageSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.page
);

export const pageSizeSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.pageSize
);

export const orderSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.order
);

export const columnSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.column
);

export const querySelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.query
);

export const statusIdSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.statusId
);

export const dateStartSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.startDate
);

export const dateEndSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.endDate
);

export const ordersSavedFilterSelector = createSelector(
	ordersSelector,
	(state: IOrders) => state.ordersSavedFilter
);

export const ordersFilterSelector = createSelector(
	dateStartSelector,
	dateEndSelector,
	statusIdSelector,
	(dateStart, dateEnd, statusId) => ({ dateStart, dateEnd, statusId })
);

export const isOrdersFilterChanged = createSelector(
	ordersFilterSelector,
	ordersSavedFilterSelector,
	(filter, savedFilter) => isSame(filter, savedFilter)
);

function isSame(val1: any, val2: any) {
	return JSON.stringify(val1) === JSON.stringify(val2);
}
