import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
	selector: '[smooth-scroll], [qScroll]',
	standalone: true,
})
export class SmoothScrollDirective implements OnInit {
	@Input('qsOptions') options: PerfectScrollbar.Options;

	constructor(public elRef: ElementRef) {}

	ngOnInit(): void {
		let options: PerfectScrollbar.Options = {
			suppressScrollY: false,
			minScrollbarLength: 25,
			maxScrollbarLength: 200,
			wheelPropagation: false,
		};

		if (this.options) options = { ...options, ...this.options };
		const ps = new PerfectScrollbar(this.elRef.nativeElement, options);
		ps.railYMarginHeight = 16;
	}
}
