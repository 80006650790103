<div class="main-contents">
	<div class="main-search-wrap">
		<div class="row m-0">
			<div class="col-md-3 col-sm-12 col-12 p-0">
				<h3>Order Inquiry</h3>
			</div>
		</div>
	</div>

	<div class="editapplication-wrap order-wraps">
		<h5>Order Details</h5>

		<form [formGroup]="form" class="cmn-form">
			<div class="row">
				<div class="col-lg-2 col-md-3">
					<label>Sales order#</label>
				</div>

				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="id"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>

				<div class="col-lg-2 col-md-3">
					<label>Order Date</label>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="orderDate"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<label>Sub Total</label>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="subtotal"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<label>Customer PO#</label>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="customerPO"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<label>Status</label>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="status"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<label>Tax Total</label>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="taxTotal"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<label>Description</label>
				</div>
				<div class="col-lg-6 col-md-3">
					<div class="form-group">
						<textarea
							formControlName="description"
							[readonly]="true"
							class="form-control"
							rows="3"
						></textarea>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<label>Total Amount</label>
				</div>

				<div class="col-lg-2 col-md-3">
					<div class="form-group">
						<input
							formControlName="total"
							type="text"
							[readonly]="true"
							class="form-control"
						/>
					</div>
				</div>
			</div>
		</form>

		<h5>Inquiry Details</h5>

		<form [formGroup]="formNote" class="cmn-form">
			<div class="row">
				<div class="col-lg-2 col-md-3">
					<label>Message*</label>
				</div>
				<div class="col-lg-6 col-md-3">
					<div class="form-group">
						<textarea
							formControlName="note"
							class="form-control"
							rows="3"
							errorTooltip
							[etControl]="formNote.controls.note"
							[etIsTouched]="formNote.controls.note.touched"
						>
            >
            </textarea>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-2 col-md-3">
					<label>Attachment</label>
				</div>
				<div class="col-lg-6 col-md-3 repot-wrap repot">
					<div class="form-group">
						<file-input
							[isMultiple]="true"
							class="form-control"
							(change)="onAddFile($event)"
						></file-input>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-2 col-md-3"></div>
				<div class="col-lg-6 col-md-3 repot-wrap repot">
					<div class="files-container">
						<ul>
							<li
								class="files-list"
								*ngFor="let file of fileFormArray.controls; let i = index"
							>
								<span class="files-text">
									{{ file.value.name | trim : 30 }}
									<a (click)="removeFile(i)">
										<app-figma-icon-img
											[iconName]="'x'"
											[isForButton]="true"
											[hasSpaceOnRight]="false"
											[hasSpaceOnLeft]="true"
										></app-figma-icon-img>
									</a>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="pro-btns">
				<ul class="list-unstyled d-flex d-inline-flex m-0">
					<li>
						<a (click)="submit()" class="btn" tabindex="1">Submit</a>
					</li>
					<li>
						<a href="javascript:void(0)" (click)="onGoBack()" class="btn">
							Cancel
						</a>
					</li>
				</ul>
			</div>
		</form>
	</div>
</div>
