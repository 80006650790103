import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'email-dropdown-input',
  templateUrl: './email-dropdown-input.component.html',
  styleUrls: ['./email-dropdown-input.component.scss'],
  providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: EmailDropdownInputComponent,
			multi: true,
		}
	],
})
export class EmailDropdownInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() value: string[];
  @Input() ccEmails?: any[];
  @Input() placeholder: string = 'Please select';
  @Input() isShowSearchIcon: boolean = false;
  @Input() isShowTooltipEmailError: boolean = true;
  allRelatedEmails: any[] = [];
  relatedEmailList: Observable<any[]>;
  relatedEmail = new FormControl('', Validators.required);
  relatedEmails: string[] = [];
  emailValidateMessage: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  _onChange: any = () => {};
	_onTouched: any = () => {};
  @ViewChild('relatedTagsInput') relatedTagsInput: ElementRef<HTMLInputElement>;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ccEmails']) {
      const ccEmail = this.ccEmails?.filter((user: any)=> user.email !== null) as any[];
      this.allRelatedEmails = ccEmail;
      this.relatedEmailList = this.relatedEmail.valueChanges.pipe(
        startWith(null),
        map((t: string | null)=> t ? typeof t === 'string' ? this._filterEmail(t) : this.sliceEmail(t): this.sliceEmail(t))
      )
    }
  }
  remove(tag: string): void {
    const index = this.relatedEmails.indexOf(tag);

    if (index >= 0) {
      this.relatedEmails.splice(index, 1);
    }

    if (this.relatedEmails.length === 0) {
      //this.hasError = true;
    }
    this.emailError = this.validateEmail(tag);
  }
  emailError: boolean = false;
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const existingValue = this.allRelatedEmails.filter((x: any) => x.email === value);
    const existingSelection = this.relatedEmails.filter((x: any) => x === value);

    // Add our tag
    if (value) {
      if (!this.validateEmail(value)) {
        if (existingValue.length === 0 && existingSelection.length === 0) {
          this.relatedEmails.push(value);
          // control.setValue(values)
          this._onChange(this.relatedEmails);
          this.emailError = false;
          // Clear the input value
          event.chipInput!.clear();
          this.relatedEmail.setValue(null);
        } else {
          this.emailValidateMessage = 'This tag already selected or already available on selection';
          this.emailError = true;
        }
      } else {
        if (this.isShowTooltipEmailError) {
          this.emailValidateMessage = 'Invalid email';
        }
        this.emailError = true;
      }
    } else {
      // Clear the input value
      event.chipInput!.clear();
    }
  }

  spaceEvent(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      const inputElement = event.target as HTMLInputElement;
      const value = (inputElement.value || '').trim();
      const existingValue = this.allRelatedEmails.filter((x: any) => x.email === value);
      const existingSelection = this.relatedEmails.filter((x: any) => x === value);

      // Add our tag
      if (value) {
        if (!this.validateEmail(value)) {
          if (existingValue.length === 0 && existingSelection.length === 0) {
            this.relatedEmails.push(value);
            this._onChange(this.relatedEmails);
            this.emailError = false;
            // Clear the input value
            inputElement.value = '';
            this.relatedEmail.setValue(null);
          } else {
            this.emailValidateMessage = 'This tag already selected or already available on selection';
            this.emailError = true;
          }
        } else {
          if (this.isShowTooltipEmailError) {
            this.emailValidateMessage = 'Invalid email';
          }
          this.emailError = true;
        }
      } else {
        // Clear the input value
        inputElement.value = '';
      }
    }
  }
  onBlur(event: any) {
    event.code = 'Space'; 
    this.spaceEvent(event);
  }
  checkEmptyValue(event: any) {
    const value = event.target.value.trim();
    if (value === '') {
      this.emailError = false;
      // this.form.get('RelatedTags')?.setErrors(null);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    // const control = this.form.controls['q' + question.id]
    const tag = event.option.viewValue;
    this.relatedEmails.push(tag);
    this._onChange(this.relatedEmails)
    this.relatedTagsInput.nativeElement.value = '';
    this.relatedEmail.setValue(null);
    this.filterTagList(tag);
  }
  filterTagList(tag: any) {
    return this.relatedEmailList.pipe(
      map(tags => tags.filter(x=> x.email !== tag))
    )
  }

  validateEmail(email: any){
    const emailToValidate = [email];
    return !emailToValidate.every(email=> email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
   }

  sliceEmail(t: any): any {
    const filteredTags = this.allRelatedEmails.filter((tags: any) => {
      return this.relatedEmails.findIndex(x => x === tags.email) === -1;
    });
    return filteredTags;
  }
  private _filterEmail(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allRelatedEmails.filter((tag) =>{
      return tag.email.toLowerCase().includes(filterValue)
    });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(value: string[]) {
    // this.value = value;
    if (value && value.length)
      value.forEach(email => {
        this.relatedEmails.push(email);
      });
    else  
      this.relatedEmails = [];
  }
}
