<div *ngIf="status == versionStatus.MandatoryUpdate" class="mandatory-backdrop"></div>

<div
	*ngIf="!isCollapsed"
	class="new-update-card q-d-flex q-f-column q-g-8 q-p-16"
	[class.is-updated]="status == versionStatus.Updated"
>
	<ng-container *ngIf="status != versionStatus.Updated">
		<div class="q-d-flex q-jc-between q-ai-center q-f-wrap q-g-12">
			<div class="font-b3 font-w-700">New Update Available!</div>

			<mat-icon svgIcon="zap"></mat-icon>

			<div class="q-w-100 font-b3">
				Get the latest version of Quest<sup>TM</sup>
			</div>
		</div>

		<button class="btn-2 btn-2-secondary" (click)="update()">Update</button>
	</ng-container>

	<ng-container *ngIf="status == versionStatus.Updated">
		<div class="q-d-flex q-jc-between q-ai-center q-f-wrap q-g-12">
			<div class="font-b3 font-w-700">Quest<sup>TM</sup> Updated!</div>

			<mat-icon
				svgIcon="x-close"
				class="q-pointer"
				(click)="close()"
			></mat-icon>

			<div class="q-d-flex q-ai-center q-g-12 q-w-100 font-b3">
				<mat-icon svgIcon="check-circle"></mat-icon>

				<a routerLink="/release-notes" (click)="close()">What’s New?</a>
			</div>
		</div>
	</ng-container>
</div>

<button
	*ngIf="isCollapsed && status != versionStatus.Updated"
	class="btn-2 btn-2-secondary"
	(click)="update()"
>
	Update
</button>
