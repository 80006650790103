import { Directive, Input, ViewContainerRef } from '@angular/core';
import { DummyWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/dummy-widget/dummy-widget.component';
import { ExternalWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/external-widget/external-widget.component';
import { TicketsWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/tickets-widget/tickets-widget.component';
import { WidgetContainerComponent } from '@app/shared/components/widget-container/widget-container.component';
import { ComponentWidget, WidgetDynamicTable } from '@app/shared/interfaces/dashboard.interface';
import { DashboardWidgetService } from '@app/shared/services/dashboard/dashboard-widget.service';
//import { InternalComponent } from './widgets/internal/internal.component';

@Directive({
  selector: '[componentHost]'
})
export class ComponentHostDirective {

  defaultComponent = DummyWidgetComponent;
  constructor(
    public viewContainerRef: ViewContainerRef,
  ){}

  @Input() set comps(dynamicComp: Partial<ComponentWidget>){
    //console.log('dynamicComp', dynamicComp);
    if(dynamicComp.contentTypeId == 1){
      try{
        this.#tryDynamicComponent(dynamicComp);
      }catch{
        this.#catchDynamicComponent(dynamicComp);
      }
    }else if(dynamicComp.contentTypeId == 2){ // type 1 and 2 are the same atm
      try{
        this.#tryDynamicComponent(dynamicComp);
      }catch{
        this.#catchDynamicComponent(dynamicComp);
      }
    }else if(dynamicComp.contentTypeId == 3){ // type 3 is for external link
      try{
        this.#tryDynamicComponent2(dynamicComp);
      }catch{
        this.#catchDynamicComponent(dynamicComp);
      }
    }
  }

  #tryDynamicComponent(dynamicComp:Partial<ComponentWidget>){
    const vref = this.viewContainerRef.createComponent<any>(dynamicComp.component);
    if(dynamicComp.component == TicketsWidgetComponent){
      if(dynamicComp.title!.toLowerCase().includes('open')){
        (<TicketsWidgetComponent>vref.instance).category = 'Open';
      }else{
        (<TicketsWidgetComponent>vref.instance).category = 'Vip';
      }
    }
  }

  #catchDynamicComponent(dynamicComp:Partial<ComponentWidget>){
    const vref = this.viewContainerRef.createComponent<any>(this.defaultComponent);
    (<DummyWidgetComponent>vref.instance).title = dynamicComp.title!;
  }

  #tryDynamicComponent2(dynamicComp:Partial<ComponentWidget>){
    const comp = ExternalWidgetComponent;
    const vref = this.viewContainerRef.createComponent<any>(comp);
    if(dynamicComp.content) (<ExternalWidgetComponent>vref.instance).url = dynamicComp.content;
    (<ExternalWidgetComponent>vref.instance).description = dynamicComp.title!;
  }
}
