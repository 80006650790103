import { ActivatedRoute } from "@angular/router";
import { CompaniesService } from "@app/modules/companies/companies.service";

export class CompanyIdRouteComponent {

  constructor(
    public _companiesService: CompaniesService,
    public _route: ActivatedRoute
    ) { }

  protected getCompanyIdFromParams(){
    if(this._route.snapshot.paramMap.get('companyId')){
      this._companiesService.subCompanyId = Number(this._route.snapshot.paramMap.get('companyId'));
    }
  }

}
