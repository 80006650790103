<div class="end-conts">
	<form [formGroup]="replyForm">
		<div class="reply-content">
			<!-- <div class="reply-header q-d-flex q-g-24">
				<div class="reply-header__left">
					<button
						*ngIf="!isClosed"
						class="btn-2 btn-2-link"
						type="button"
						[class.gray]="isReply"
						(click)="updateReply(true)"
					>
						<img
							src="{{
								'./../../../../../../assets/images/icons/send-email-icon.svg'
							}}"
							alt=""
						/>
						Send Message
					</button>

					<button
						class="btn-2 btn-2-link"
						type="button"
						[class.gray]="!isReply"
						(click)="updateReply(false)"
					>
						<img
							*ngIf="!isClosed"
							src="{{
								'./../../../../../../assets/images/icons/download-file.svg'
							}}"
						/>
						<img
							*ngIf="isClosed"
							src="{{
								'./../../../../../../assets/images/icons/send-email-icon.svg'
							}}"
						/>
						{{ isClosed ? 'Reopen Ticket' : 'Close Ticket' }}
					</button>
				</div>
				<div class="reply-header__right">
					<carbon-copy
						[users]="contacts"
						[isShowOthers]="isShowOthers"
						(onClickModal)="isOpenModal = true;"
					></carbon-copy>
					<mat-icon
						svgIcon="users-plus" class="q-pointer"
						[tooltipContentV2]="{ message: 'Add & Manage Users' }"
						(click)="isOpenModal = true"
					></mat-icon>
				</div>
			</div> -->

			<div class="reply-body">
				<textarea
					formControlName="comment"
					class="reply-area"
					[placeholder]="
						isReply
							? 'Write your response here'
							: 'Explain why you are ' +
							  (isClosed ? 'reopening' : 'closing') +
							  ' the ticket'
					"
					errorTooltip
					#textArea
					[etControl]="isReply ? replyForm.get('comment')! : undefined"
					[etIsTouched]="
						isReply ? replyForm.get('comment')!.touched : undefined
					"
					(paste)="fileAdded($event)"
				></textarea>

				<div
					id="drop-zone"
					class="attach-container font-b3 q-pointer drop-zone"
					[ngClass]="{
						'justify-content-center':
							fileFormArray.controls.length === 0 || isDragEnter,
						'file-error': hasFileError && fileFormArray.controls.length === 0,
						'file-attach': isDragEnter
					}"
					fileDragDropFile
					(fileDropped)="fileAdded($event)"
					[isCustom]="true"
					[stopPropagating]="false"
					(click)="uploadFiles()"
					(drop)="onDrop()"
					(dragenter)="onDragEnter($event)"
					(dragend)="onDragEnd()"
				>
					<input
						type="file"
						#fileUploadInput
						class="file-input"
						multiple
						(change)="onAddFile($event)"
					/>

					<mat-icon
						*ngIf="fileFormArray.controls.length === 0"
						svgIcon="paperclip"
						disabled
					></mat-icon>

					<span
						*ngIf="
							fileFormArray.controls.length === 0 &&
							!hasFileError &&
							!isDragEnter
						"
						class="ps-3"
					>
						Attach files by dragging & dropping or clicking to browse your
						computer.
					</span>

					<span
						*ngIf="hasFileError && fileFormArray.controls.length === 0"
						class="ps-3"
						style="font-size: 12px; line-height: 19.416px"
					>
						{{ fileErrorMessage }}
					</span>

					<span disabled *ngIf="isDragEnter" class="ps-3">
						Attach {{ draggedFileCount }} File{{
							draggedFileCount === 1 ? '' : 's'
						}}
					</span>

					<div *ngIf="!isDragEnter" class="files-container">
						<ul class="files mb-0">
							<li
								*ngIf="fileFormArray.controls.length > 0"
								class="d-flex align-items-center"
							>
								<mat-icon
									*ngIf="fileFormArray.controls.length > 0"
									svgIcon="paperclip"
									disabled
								></mat-icon>
							</li>

							<li
								*ngFor="let file of fileFormArray.controls; let i = index"
								class="attachment"
							>
								<div
									class="d-flex align-items-center w-100"
									style="margin-top: 8px; height: 5px"
								>
									<div class="d-flex">
										<app-figma-icon-img
											[height]="17"
											[width]="17"
											[iconName]="getIcon(file.value.name)"
											[hasSpaceOnRight]="false"
											class="me-2"
										></app-figma-icon-img>
									</div>

									<div class="d-flex w-100 file-name">
										<div class="ellipsis">
											{{ file.value.name2 || file.value.name }}
										</div>
									</div>

									<div class="d-flex q-pointer">
										<app-figma-icon-img
											[iconName]="'x'"
											[isForButton]="true"
											[hasSpaceOnRight]="false"
											[height]="17"
											[width]="17"
											class="ms-3"
											(click)="removeFile(i)"
										></app-figma-icon-img>
									</div>
								</div>

								<div class="d-flex w-100">
									<div class="w-50 file-type">
										{{ getFileType(file.value.name) }}
									</div>

									<div class="w-50 text-end file-size">
										{{ getFileSize(file.value.size) }} MB
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div
			type="submit"
			class="flex-button-container"
			[class.closed-btn]="isClosed"
		>
			<button
				[class]="!isClosed ? 'btn-2 btn-2-tertiary' : 'btn-2 btn-2-primary'"
				(click)="onSubmit('close')"
				[disabled]="isClosed"
			>
				<img
					src="{{
						!isClosed
							? './../../../../../../assets/images/icons/download-file.svg'
							: './../../../../../../assets/images/icons/send-email-icon.svg'
					}}"
				/>
				{{ !isClosed ? 'Close Ticket' : 'Send Message' }}
			</button>
			<button
				type="submit"
				[class]="!isClosed ? 'btn-2 btn-2-primary' : 'btn-2 btn-2-tertiary'"
				(click)="onSubmit('reply')"
			>
				<img
					*ngIf="isReply"
					src="{{
						'./../../../../../../assets/images/icons/send-email-icon.svg'
					}}"
				/>
				<img
					*ngIf="!isReply && isClosed"
					src="{{
						'./../../../../../../assets/images/icons/download-file.svg'
					}}"
				/>
				<img
					*ngIf="!isReply && !isClosed"
					src="{{
						'./../../../../../../assets/images/icons/send-email-icon.svg'
					}}"
				/>
				{{
					isReply ? 'Send Message' : isClosed ? 'Reopen Ticket' : 'Close Ticket'
				}}
			</button>
		</div>
	</form>
</div>

<!-- <modal-add-manage-users
	[ticketId]="ticketId"
	[isOpenModal]="isOpenModal"
	[users]="contacts"
	(close)="isOpenModal = false"
	(addDelete)="addDeleteContact($event)"
></modal-add-manage-users> -->
