import { CommonModule, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationService } from '@app/shared/services/navigation.service';

@Component({
	selector: 'under-maintenance',
	templateUrl: './under-maintenance.component.html',
	styleUrls: ['./under-maintenance.component.scss'],
	standalone: true,
	imports: [MatIconModule, CommonModule],
})
export class MaintenanceComponent {
	constructor(
		private _navService: NavigationService,
		private _location: Location
	) {}

	back() {
		if (history.state.navigationId !== 1) this._location.back();
		else this._navService.smartNavigate();
	}
}
