import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ParentChildInputSelectorComponent } from './parent-child-input-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';
import { MultipleSelectionModule } from '@app/shared/directives/multiple-selection/multiple-selection.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    ParentChildInputSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FigmaIconImgModule,
    MultipleSelectionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ScrollingModule,
    AsyncPipe
  ],
  exports: [ParentChildInputSelectorComponent]
})
export class ParentChildInputSelectorModule { }
