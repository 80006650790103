import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';
import { FilterChevronModule } from '@app/shared/filter-chevron/filter-chevron.module';

import { BannerMessageWidgetComponent } from './banner-message-widget.component';

@NgModule({
	declarations: [BannerMessageWidgetComponent],
	imports: [CommonModule, RouterModule, FigmaIconImgModule, FilterChevronModule],
	exports: [BannerMessageWidgetComponent],
})
export class BannerMessageWidgetModule {}
