/* Angular Libraries */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';

/* Interfaces */
import { NotificationMessages } from '@app/shared/constants';
import {
	CardInformation,
	States,
} from '@app/shared/interfaces/invoice.interface';
import { CardValidationMessages } from '../../validation-messages/invoice-validation-messages.inum';

@Component({
	selector: 'app-card-details',
	templateUrl: './card-details.component.html',
	styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent implements OnInit, OnDestroy {
	/* Properties */
	cardForm: FormGroup;
	monthList: string[] = [
		'01',
		'02',
		'03',
		'04',
		'05',
		'06',
		'07',
		'08',
		'09',
		'10',
		'11',
		'12',
	];
	yearList: string[] = [];
	stateList: States = [];
	cardDetails: CardInformation;
	cardValidationMessages = CardValidationMessages;

	/* Private Properties */
	private _$unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor(
		private _fb: FormBuilder,
		private _stripeService: StripeService,
		private _route: ActivatedRoute,
		private _notifier: NotificationService,
		private _spinner: SpinnerService,
		private _toastMessageService: ToastMessageService,
		private _router: Router
	) {}

	get isAdmin() {
		return this._stripeService.isAdmin;
	}

	/* Methods */
	ngOnInit(): void {
		this.initCardForm();
		this.yearList = this.getYearList();
		this.getCardDetails();
		this.initStates();
	}

	initStates() {
		this._stripeService
			.getStatesForUnitedStates()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: any) => {
					this.stateList = result;
				},
			});
	}

	getCardDetails() {
		this._spinner.start();
		const cardId: any = atob(this._route.snapshot.params['id']);

		this._stripeService
			.getStripeCards(cardId)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: CardInformation) => {
					this.cardDetails = result;
					this.cardForm.controls['id'].setValue(result.id);
					this.cardForm.controls['name'].setValue(result.name);
					this.cardForm.controls['expMonth'].setValue(
						JSON.stringify(result.expMonth).length === 1
							? '0' + JSON.stringify(result.expMonth)
							: JSON.stringify(result.expMonth)
					);
					this.cardForm.controls['expYear'].setValue(
						JSON.stringify(result.expYear)
					);
					this.cardForm.controls['addressCity'].setValue(result.addressCity);
					this.cardForm.controls['addressCountry'].setValue('United States');
					this.cardForm.controls['addressLine1'].setValue(result.addressLine1);
					this.cardForm.controls['addressLine2'].setValue(result.addressLine2);
					this.cardForm.controls['addressState'].setValue(result.addressState);
					this.cardForm.controls['addressZip'].setValue(result.addressZip);

					this._spinner.stop();
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err.errors);
				},
			});
	}

	getYearList() {
		const yearList: string[] = [];
		const d = new Date();

		for (let i = 0; i < 20; i++) {
			const year = d.getFullYear() + i;
			yearList.push(JSON.stringify(year));
		}

		return yearList;
	}

	initCardForm() {
		this.cardForm = this._fb.group({
			id: [''],
			name: ['', Validators.required],
			expMonth: ['', Validators.required],
			expYear: ['', Validators.required],
			addressCity: ['', Validators.required],
			addressCountry: ['', Validators.required],
			addressLine1: ['', Validators.required],
			addressLine2: [''],
			addressState: ['', Validators.required],
			addressZip: ['', [, Validators.required, Validators.minLength(5)]],
		});
	}

	save() {
		this.cardForm.markAllAsTouched();

		if (this.cardForm.controls['name'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['addressLine1'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['addressState'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardForm.controls['addressCity'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (
			this.cardForm.controls['addressZip'].value &&
			this.cardForm.controls['addressZip'].value.length < 5
		) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (['', null].includes(this.cardForm.controls['addressZip'].value)) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		this._spinner.start();

		const params = {
			id: this.cardForm.value.id,
			name: this.cardForm.value.name,
			expMonth: Number(this.cardForm.value.expMonth),
			expyear: Number(this.cardForm.value.expYear),
			addressCity: this.cardForm.value.addressCity,
			addressCountry: this.cardForm.value.addressCountry,
			addressLine1: this.cardForm.value.addressLine1,
			addressLine2: this.cardForm.value.addressLine2,
			addressState: this.cardForm.value.addressState,
			addressZip: this.cardForm.value.addressZip,
		};

		this._stripeService
			.updateStripeCards(params)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result) => {
					this._spinner.stop();
					this._notifier.notifySuccess(NotificationMessages.save('Card'), 'You can use this card for future transactions');
					this._router.navigate([
						`/${
							this.isAdmin ? 'billing-orders' : 'billing-and-orders'
						}/invoices`,
					]);
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err.errors);
				},
			});
	}

	returnAsNumber(event: any) {
		return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
