import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipV2Directive } from './custom-tooltip-v2.directive';

@NgModule({
  declarations: [
    CustomTooltipV2Directive
  ],
  imports: [
    CommonModule
  ],
  exports:[
    CustomTooltipV2Directive
  ]
})
export class CustomTooltipV2Module { }
