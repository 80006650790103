<div
	class="add-card-field w-360"
	[ngClass]="{ 'add-cart-active': drawer.openContactSupport }"
	(scroll)="scrollEvent()"
>
	<div class="form-type">
		<button
			type="button"
			class="btn-close text-lg py-3 message-close opacity-10 d-flex align-items-center"
			aria-label="Close"
			(click)="drawer.openContactSupport = false"
		>
			<app-figma-icon-img
				[iconName]="'x'"
				[isForButton]="true"
				[hasSpaceOnRight]="false"
			></app-figma-icon-img>
		</button>
		<div class="row" [class.impersonating]="impersonating">
			<div class="setup-form">
				<form *ngIf="formData && form" (ngSubmit)="submit()">
					<h3 class="text-sm">{{ formData.title }}</h3>
					<p class="text-xs">{{ formData.description }}</p>
					<div class="form-group">
						<sr-form
							[form]="form"
							[questions]="formData.questions"
							[scrollEvent]="isScroll"
              [ccEmails]="formData.isCcEmail ? formData.emailList : []"
						></sr-form>
						<div class="submit-buttons">
							<span class="imp-parent">
								<a
									(click)="drawer.openContactSupport = false"
									class="btn-2 btn-2-link d-inline"
								>
									Cancel
								</a>
								<button
									type="submit"
									[disabled]="emailError"
									class="btn-2 btn-2-primary d-inline"
								>
									Submit Quest Support
								</button>
							</span>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
