        <mat-tab-group
          class="no-header"
          animationDuration="0ms"
          (selectedTabChange)="setIndex($event)"
          [selectedIndex]="kbService.tab"
        >
          <mat-tab label="Global">
            <div>
              <app-kb-topic-list
                [viewOptions]="options$"
                [isCustom]="false"
                [isMainPage]="false"
              ></app-kb-topic-list>
            </div>
          </mat-tab>
          <mat-tab label="Company">
            <div>
              <app-kb-topic-list
                [viewOptions]="options$"
                [isCustom]="true"
                [isMainPage]="false"
              ></app-kb-topic-list>
            </div>
          </mat-tab>
        </mat-tab-group>
