<div class="file-input-wrapper">
  <input
    [disabled]="readonly"
    #file
    type="file"
    class="text-xs form-control custom-file-upload-hidden"
    [accept]="fileTypes"
    tabindex="-1"
    style="display: none;"
    onclick="this.value=null"
  />
  <input
    type="text"
    class="file-upload-input custom-file font-b3 form-control"
    readonly
    [value]="showValue ? fileName ?? '' : ''"
    [placeholder]="placeholder"
    tabindex="-1"
    (click)="file.click()"
  />
  <button
    [disabled]="readonly"
    type="button"
    class="btn-40h file-upload-button drap-drop-message"
    (click)="file.click()"
  >
    <span class="font-b1 font-h-normal">Choose File{{isMultiple ? 's' : ''}}</span>
    <span class="font-b1 font-h-normal">Drop Image Here</span>
  </button>
</div>
<!-- <input type="file" class="text-xs form-control" multiple="false" accept="image/*" id=finput onchange="upload()"> -->
