import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApplicationWidget, DashboardCard, GraphApi, ServiceHealthStatus, TablePage, TicketWidget, WidgetCardList, WidgetList, WidgetListWithCategory } from '../interfaces/dashboard.interface';
import { environment } from 'environments/environment';
import { filter, map, Observable, of, Subject, take } from 'rxjs';
import { toFormData } from "@app/shared/utilities/helper";
import { KBWidget } from '../interfaces/knowledge-base.interface';
import { Order } from '../interfaces/order.interface';



@Injectable({
  providedIn: 'root'
})
export class DashboardMainService {

  private moveWidgets = new Subject<number>();
  private newWidgetList = new Subject<WidgetListWithCategory[]>();
  protected http = inject(HttpClient);
  public moveToDashboard = this.moveWidgets.asObservable();
  public refreshWidgets = this.newWidgetList.asObservable();
  //public openWidgetList:boolean = false;
  //public openFeedbackList:boolean = false;

  constructor() {}

  moveWidgetsToDashboard(w:number){
    this.moveWidgets.next(w);
  }

  refreshWidgetList(){
    this.getAllCompanyCards()
      .pipe(
        map(v=>{
          let data = v;
          v.forEach((w, wi)=>{
            data[wi].cards = w.cards.filter(x=>x.selected==false)
          })
          return data;
        })
      )
      .subscribe(
        {
          next: res=>{
            this.newWidgetList.next(res)
          },
          error: err=>{
          }
        }
      )
  }

  getAllCompanyCards(){
    return this.http.get<WidgetListWithCategory[]>(
      `${environment.apiBaseUrl}UserDashboardCard/getCardList` // use this later for commit
    );
  }

  addCardToDashboard(ids: number[]){
    return this.http.post<WidgetCardList[]>(
      `${environment.apiBaseUrl}UserDashboardCard/addCards`,
      ids
    );
  }



    /**
     * @API: resizeCard
     * @Payload: {userId, height, width}
     */
    resizeCard(cardId:number, body:{userId:number, height?:number, width?:number,}){
      const data = this.setNullToEmpty(body);
      //const toSubmit = toFormData(data);
      return this.http.put(
        `${environment.apiBaseUrl}UserDashboardCard/ResizeCard/${cardId}`,
        data
      );
    }

     setNullToEmpty(data:any){
      const toClean = {...data}
      for(const key in toClean){
          if(toClean[key] === null){
              toClean[key] = '';
          }
      }
      return toClean;
    }

  
    getApplicationWidget(): Observable<ApplicationWidget[]> {
      return of([{
        id: 1,
        categoryId: 1,
        categoryName: 'Application',
        title: 'Application',
      }])
    }

    getKbWidget(): Observable<KBWidget[]> {
      return of([{
        id: 1,
        title: 'Application',
        categoryId: 1,
        categoryName: 'Application',
      }])
    }

    getOpenticketsWidget(): Observable<TicketWidget[]> {
      return of([{
        id: 1,
        title: 'Open Tickets',
        ticketBoardId: 1,
      }])
    }
  
    getVIPticketsWidget(): Observable<TicketWidget[]> {
      return of([{
        id: 1,
        title: 'VIP Tickets',
        ticketBoardId: 1,
      }])
    }

    getCurrentOpenOrders(): Observable<Order[]> {
      return of([{
        id: 1,
        customerPO: '1233445',
        description: 'Test Order',
        orderDate: new Date(),
        total: 100,
        subtotal: 100,
        taxTotal: 0,
        status: 'Open',
        salesOrder: 1,
        color: '#000000',
        fontColor: '#ffffff',
      }])
    }

    getITConfiguration(
      params:{
        search?: string,
        page?: number,
        pageSize?: number,
        column?: string,
        order?: string
      }|undefined=undefined
    ):Observable<TablePage>{
      return of({
        currentPage: 1,
        data: [
          {
            id: 1,
            name: 'Name Test',
            userId: 1,
            contact: 'Contact Test',
            companyId: 1,
            company: 'Company Test',
            operationSystemId: 1,
            locationId: 1,
            location: 'Location Test',
            configurationTypeId: 1,
            type: 'Type Test',
            configurationStatusId: 1,
            status: 'Status Test',
            primaryIP: 'IP Test',
            serialNo: 'Serial Test',
            expireDate: new Date(),
          }
        ],
        pageSize: 5,
        totalCount: 1,
      });
    }

    getServiceHealthStatusOfTools():Observable<ServiceHealthStatus[]>{
      return of([{
        serviceId: '123456',
        serviceName: 'Service Name',
        serviceStatus: 'Service Status',
        issueId: '123131231',
        issueTitle: 'Issue Title',
        issueStatus: 'Issue Status',
        issueType: 'Issue Type',
        startDate: new Date(),
        endDate: new Date(),
        isResolved: true,
      }])
    }

    getGraphAPI():Observable<GraphApi>{ // 
      return of({
        // controlCategoryScore, currentScore, id, maxScore, scoreDate, secureScore, securityScoreHistory, tenantId, tenantName
        controlCategoryScore: [{
          //currentScore, id, maxScore, secureScore
          currentScore: 1,
          id: 1,
          maxScore: 1,
          name: 'test name',
          secureScore: 1,
        }],
        currentScore: 1,
        id: '1',
        maxScore: 1,
        scoreDate: new Date(),
        secureScore: 1,
        securityScoreHistory: [{
          //currentScore, id, maxScore, scoreDate, secureScore
          currentScore: 1,
          id: '1',
          maxScore: 1,
          scoreDate: new Date(),
          secureScore: 1,
        }],
        tenantId: '1',
        tenantName: 'Tenant Name',
      })
    }
  

}
