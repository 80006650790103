import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FigmaIconImgModule } from './../../../figma-icon-img/figma-icon-img.module';
import { MatDialogModule } from '@angular/material/dialog';

import { YesNoModalComponent } from './yes-no-modal.component';

@NgModule({
	declarations: [YesNoModalComponent],
	imports: [CommonModule, MatDialogModule, FigmaIconImgModule],
})
export class YesNoModalModule {}
