import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	OnDestroy,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';

import * as moment from 'moment';
import { MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements AfterViewInit, OnDestroy {
	@ViewChild(MatStartDate) startDate: MatStartDate<Date>;
	@ViewChild(MatEndDate) endDate: MatEndDate<Date>;
	@Output() emitStartDate = new EventEmitter<string>();
	@Output() emitEndDate = new EventEmitter<string>();

	private _subscription = new Subscription();

	private _el = inject(ElementRef);
	private _renderer = inject(Renderer2);

	ngAfterViewInit() {
		const inputs = this._el.nativeElement.querySelectorAll(
			'.mat-date-range-input-inner'
		);

		for (const input of inputs) {
			this._renderer.removeClass(input, 'mat-date-range-input-inner');
		}

		this.startDateSubscription();
		this.endDateSubscription();
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	startDateSubscription() {
		this._subscription.add(
			this.startDate.dateChange.subscribe((input) => {
				const date = moment(input.value).format('yyyy-MM-DD');
				this.emitStartDate.emit(date);
			})
		);
	}

	endDateSubscription() {
		this._subscription.add(
			this.endDate.dateChange.subscribe((input) => {
				const date = moment(input.value).format('yyyy-MM-DD');
				this.emitEndDate.emit(date);
			})
		);
	}
}
