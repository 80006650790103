import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
	Notification,
	NotificationService,
	NotifType,
} from '@app/core/services/notification.service';

@Component({
	selector: '[sp-notification]',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
	constructor(public notifier: NotificationService) {}

	refresh(notification: Notification) {
		document.location.reload();
		this.closeNotif(notification);
		localStorage.setItem('new-version-refresh-event', Math.random().toString());
	}

	closeNotif(notification: Notification) {
		notification.isShown = false;
		setTimeout(() => {
			this.notifier.close(notification);
		}, 350);
	}

	mapNotifierType(type: NotifType) {
		switch (type) {
			case 'success':
				return 'success';
			case 'error':
				return 'error';
			case 'default':
				return 'success';
			default:
				return 'success';
		}
	}

	mapNotifierIcon(type: NotifType) {
		switch (type) {
			case 'success':
				return 'check-circle';
			case 'error':
				return 'alert-triangle';
			case 'default':
				return 'info-circle';
			default:
				return 'info-circle';
		}
	}
}
