import { UserService } from './../../../core/services/user.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
	Component,
	OnInit,
	AfterViewInit,
	Input,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	DoCheck,
} from '@angular/core';
import { KnowledgeBaseService } from '@app/modules/company/pages/knowledge-base/knowledge-base.service';
import {
	BotDirective,
	ComService,
	IPayload,
	DEFAULT_OPTIONS,
	BotService,
	BotHelperDirective,
	StyleSetDirective,
	IWebChat,
} from 'ngx-microsoft-bot-framework';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { ChatBotService } from './chat-bot.service';
import { NewTicket } from '@app/shared/constants';
@Component({
	selector: 'app-chat-bot',
	templateUrl: './chat-bot.component.html',
	styleUrls: ['./chat-bot.component.scss'],
	providers: [
		BotService,
		ComService,
		BotDirective,
		BotHelperDirective,
		StyleSetDirective,
	],
})
export class ChatBotComponent implements OnInit, AfterViewInit {
	newTicketUrl = NewTicket.url;
	iframeUrl: any;
	chatBotId: number;
	botData: any;
	isMinimized: boolean | null;
	tokenData: any;
	private _botWindowElement: ElementRef;
	@ViewChild('botWindow', { static: false }) set content(content: ElementRef) {
		if (content) {
			this._botWindowElement = content;
		}
	}
	passViewChild: ViewChild;
	payload: IPayload;
	renderObject: IWebChat;
	stylesetPayload: DEFAULT_OPTIONS = {
		rootHeight: '100%',
		root: {
			/* width */
			' ::-webkit-scrollbar': {
				width: '3px',
			},
		},
	};
	url: string;
	styleOptionsPayload: DEFAULT_OPTIONS = {
		rootHeight: '100%',
		botAvatarInitials: 'SP',
		userAvatarInitials: 'CH',
		backgroundColor: 'red',
		hideUploadButton: true,
	};
	constructor(
		private _sanitizer: DomSanitizer,
		private _kbService: KnowledgeBaseService,
		private _utilityService: UtilitiesService,
		private _userService: UserService,
		private _comService: ComService,
		private _bot: BotDirective,
		private _botHelper: BotHelperDirective,
		private _botService: BotService,
		private _ref: ChangeDetectorRef,
		private _sessionStorage: SessionStorageService,
		private _chatBotService: ChatBotService
	) {}

	ngOnInit(): void {
		this.obtainStylePayload();
	}

	ngAfterViewInit(): void {
		if (this._userService.user?.role === 'User') {
			const isChatBotMinimized =
				this._sessionStorage.getStorage('isChatBotMinimized');

			if (isChatBotMinimized === 'true') {
				this.isMinimized = true;
			} else {
				this.isMinimized = false;
			}

			this._chatBotService.isMinimized$.subscribe({
				next: (data: boolean | null) => {
					this.isMinimized = data;
				},
			});

			this._kbService.getKBBotList().subscribe((res: any) => {
				if (res.data.length > 0) {
					this.chatBotId = res.data[0].id;
					this._kbService
						.getKBBotById(res.data[0].id)
						.subscribe((response: any) => {
							this.botData = response;
						});
				}
			});
			if (!sessionStorage.getItem('botToken')) {
				this._kbService.getBotToken(this._userService.user?.id).subscribe({
					next: (data) => {
						this.tokenData = data;
						this.customBotDirective();
						// this.iframeUrl = this.sanitizer('https://webchat.botframework.com/embed/ElevateDEV?t=' + this.tokenData.token+'&userid='+);
						if (
							!sessionStorage.getItem('botId') ||
							sessionStorage.getItem('botId') !== this.tokenData.conversationId
						) {
							sessionStorage.setItem('botId', this.tokenData.conversationId);
						}
						if (!sessionStorage.getItem('botToken')) {
							sessionStorage.setItem('botToken', this.tokenData.token);
						}
					},
				});
			} else {
				this.customBotDirective();
			}
		}
	}

	customBotDirective(): void {
		this.passViewChild = this._botWindowElement.nativeElement;
		const directLine = window.WebChat.createDirectLine({
			token: sessionStorage.getItem('botToken')
				? sessionStorage.getItem('botToken')
				: this.tokenData.token,
			conversationId: sessionStorage.getItem('botId')
				? sessionStorage.getItem('botId')
				: this.tokenData.conversationId,
			webSocket: false,
		});
		const styleSet = window.WebChat.createStyleSet(this.stylesetPayload);
		this.renderObject = {
			directLine: directLine,
			userID: this._userService.user?.id.toString(),
			styleOptions: this.styleOptionsPayload,
			styleSet: styleSet,
			locale: 'en-US',
		};
		directLine
			.postActivity({
				from: { id: this._userService.user?.id.toString(), name: 'USER_NAME' },
				name: 'requestWelcomeDialog',
				type: 'event',
				value: sessionStorage.getItem('botToken')
					? sessionStorage.getItem('botToken')
					: this.tokenData.token,
			})
			.subscribe();
		this._botHelper.renderWebChat(this.passViewChild, this.renderObject);
		styleSet.textContent = Object.assign({}, styleSet.textContent, {
			cursor: 'crosshair',
			color: 'white',
		});
		styleSet.root = Object.assign({}, styleSet.root);
	}

	obtainLocalToken() {}

	obtainStylePayload() {
		this._comService.obtainStylePayload(
			this.stylesetPayload,
			this.styleOptionsPayload
		);
	}

	minMaxWindow(value: boolean) {
		this.isMinimized = value;
		this._sessionStorage.setStorage('isChatBotMinimized', value.toString());
	}

	sanitizer(value: any) {
		return this._sanitizer.bypassSecurityTrustResourceUrl(value);
	}

	closeChat() {
		this._utilityService.toggleChatBot(false);
	}
}
