import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { UserMessageCategoryEnum } from '@app/shared/constants';
import { UserMessagePendingNotificationDetail } from '@app/shared/interfaces/usermessage.interface';
import { UserNotificationService } from '@app/shared/services/user-notification.service';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { DrawerContainerComponent } from '../drawer-container/drawer-container.component';
import { messageSelector } from '../../navigation/top-bar/store/top-bar.selector';
import { filter } from 'rxjs';
@Component({
  selector: 'app-message-notification-list',
  templateUrl: './message-notification-list.component.html',
  styleUrls: ['./message-notification-list.component.scss']
})
export class MessageNotificationListComponent implements OnInit {

  messages:UserMessagePendingNotificationDetail[];
  @Input() drawerContainer : DrawerContainerComponent;
  @ViewChild(MatProgressSpinner) _spinner :MatProgressSpinner;
  loading = true;
  impersonating: boolean;

  constructor(
    private _usernotification:UserNotificationService,
    private _router: Router,
    private _userService : UserService,
    private store: Store<AppStateInterface>
    ) { }

  ngOnInit(): void {
    this.store.pipe(
      select(messageSelector),
      filter((data)=> data.isOpen === true)
      ).subscribe(()=>{
        this.impersonating = this._userService.user?.impersonatingBy!= null;
          if(!this.impersonating){

            this.loading = true;
            this._usernotification.getPendingNotificationDetail().subscribe({
              next:s=>{
                this.loading = false;
                this._usernotification.clearAllMessageNotifications();
                s.forEach(s=> this.elapsedTime(s));
                this.messages = s;

            },
            error:e=>{
              this.loading = false;
            }
            });
          }
      })
  }

  close(message:UserMessagePendingNotificationDetail, index:number){

    this.messages.splice(index,1);
  }

  messageListTrackBy(index:number,item:any){
    return item.id;
  }

  open(message:UserMessagePendingNotificationDetail, index:number){

    switch(message.categoryId){
      case UserMessageCategoryEnum.Ticket :
        this._router.navigate(['/client-message/tickets/'+message.id], {queryParams: { categoryId: message.categoryId} });
      break;
      case UserMessageCategoryEnum.Banner :
        this._router.navigate(['/client-message/banner/'+message.id], {queryParams: { categoryId: message.categoryId} });
        break;
      case UserMessageCategoryEnum.BroadCast :
        this._router.navigate(['/client-message/broadcast/'+message.id], {queryParams: { categoryId: message.categoryId} });
      break;
    }

    this.messages.splice(index,1);

    this.closeHostDrawer();

  }

  closeHostDrawer(){

    if(this.drawerContainer) this.drawerContainer.close();

  }

  elapsedTime(message:UserMessagePendingNotificationDetail){
    message._elapsedtime = moment(message.createdDate).fromNow();
  }


}
