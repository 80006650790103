import { createAction, props } from '@ngrx/store';

import { INotificationCenterEditViewParams } from '../../INotificationCenter.interface';

export enum ActionTypes {
	NOTIFICATION_CENTER_EDIT_VIEW_UPDATE = '[Notification Center Edit/View] Update',
	NOTIFICATION_CENTER_EDIT_VIEW_UPDATE_SUCCESS = '[Notification Center Edit/View] Update Success',
}

export const notificationCenterEditViewUpdateAction = createAction(
	ActionTypes.NOTIFICATION_CENTER_EDIT_VIEW_UPDATE,
	props<Partial<INotificationCenterEditViewParams>>()
);

export const notificationCenterEditViewUpdateSuccessAction = createAction(
	ActionTypes.NOTIFICATION_CENTER_EDIT_VIEW_UPDATE_SUCCESS
);
