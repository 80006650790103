import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetLoadingComponent } from './widget-loading.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WidgetLoadingComponent],
  exports: [WidgetLoadingComponent]
})
export class WidgetLoadingModule { }
