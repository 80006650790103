import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GroupsAndPermissionsService } from '@app/modules/groups-and-permissions/groups-and-permissions.service';
import { IGroup } from '@app/shared/interfaces/groups.interface';
import { map, Observable, shareReplay, take, tap } from 'rxjs';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsComponent implements OnChanges {
  @Input() cId: number;
  @Output() chosenGroupIds: EventEmitter<number[]> = new EventEmitter<number[]>();
  groups$: Observable<IGroup[]>;

  selectedGroups: number[];
  constructor(private _groupsService: GroupsAndPermissionsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['cId'].currentValue != undefined){
      // query Groups
      this.groups$ = this._groupsService.getGroupsDropdown(this.cId).pipe(take(1),
      tap((data)=>{
        this.selectedGroups = data.map(group=>group.id);
        this.getChosenGroupId();
      }))
    }
  }

  getChosenGroupId(){
    this.chosenGroupIds.emit(this.selectedGroups);
  }





}
