import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
	SIDEBAR_UPDATE = '[SIDEBAR] Update',
	SIDEBAR_UPDATE_SUCCESS = '[SIDEBAR] Update Success',

	SIDEBAR_REMOVE = '[SIDEBAR] Remove',
	SIDEBAR_REMOVE_SUCCESS = '[SIDEBAR] Remove Success',

	FEEDBACK_URL_UPDATE = '[FEEDBACK URL] Update',
	FEEDBACK_URL_UPDATE_SUCCESS = '[FEEDBACK URL] Update Success',
}

export const sidebarUpdateAction = createAction(
	ActionTypes.SIDEBAR_UPDATE,
	props<{ sidebarData: RoleMenuInterface[] | null }>()
);

export const sidebarUpdateSuccessAction = createAction(
	ActionTypes.SIDEBAR_UPDATE_SUCCESS
);

export const sidebarRemoveAction = createAction(ActionTypes.SIDEBAR_REMOVE);

export const sidebarRemoveSuccessAction = createAction(
	ActionTypes.SIDEBAR_REMOVE_SUCCESS
);

export const feedbackUrlUpdateAction = createAction(
	ActionTypes.FEEDBACK_URL_UPDATE,
	props<{ feedbackUrl: string }>()
);

export const feedbackUrlUpdateSuccessAction = createAction(
	ActionTypes.FEEDBACK_URL_UPDATE_SUCCESS
);
