import { ImageService } from './../../services/image.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { User } from '@app/shared/interfaces/user.interface';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { MatChipInputEvent } from '@angular/material/chips';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	SimpleChanges,
	ViewChildren,
	AfterViewChecked,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core/services/notification.service';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { userDataSelector } from '@app/core/store/user/user.selector';
import { SupportService } from '@app/modules/service-and-support/support.service';
import {
	FormAnswerType,
	FormValidationType,
	SupportConstants,
} from '@app/shared/constants';
import {
	CustomForm,
	CustomFormQuestion,
} from '@app/shared/interfaces/custom-form.interface';
import { keyValuePair } from '@app/shared/interfaces/knowledge-base.interface';
import { FormValidators } from '@app/shared/utilities/form-validators';
import { clone } from '@app/shared/utilities/helper';
import { select, Store } from '@ngrx/store';
import {
	concatMap,
	debounceTime,
	delay,
	distinctUntilChanged,
	from,
	interval,
	map,
	Observable,
	of,
	startWith,
	Subject,
	Subscription,
	take,
	takeUntil,
} from 'rxjs';

@Component({
	selector: 'sr-form',
	templateUrl: './sr-form.component.html',
	styleUrls: ['./sr-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrFormComponent
	implements OnChanges, AfterViewInit, OnDestroy, AfterViewChecked
{
	@Input() isPreview: boolean = false;
	@Input() questions: CustomFormQuestion[];
  @Input() ccEmails?: any[]
	@Input() form: FormGroup<any>;
	@Input() markAllAsTouched = false;
	@Input() scrollEvent = false;
  @Input() companyId?: number;
	@Output() onSubmit = new EventEmitter<any>();
	@Output() newQuestions: EventEmitter<CustomFormQuestion[]> = new EventEmitter<
		CustomFormQuestion[]
	>();
	@ViewChildren('buttonSeq') buttonSeq: QueryList<ElementRef>;

	private _prevData: keyValuePair = {};
	private _listeners: keyValuePair<CustomFormQuestion[]> = {};

	subscriptions: Subscription[] = [];

	optionsForRewst: any[] = [];
	arrayListeners: any[] = [];
	attachFiles: File[] = [];
	previewFiles: any[] = [];
  emailValidateMessage: string;
  @ViewChild('relatedTagsInput') relatedTagsInput: ElementRef<HTMLInputElement>;
  relatedTags: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  relatedTag = new FormControl('', Validators.required);
  relatedTagList: Observable<any[]>;
  allRelatedTags: string[] = []; //['Microsoft Office 365', 'Email', 'Book'];
  user: User;
	private destroy$ = new Subject();
	constructor(
		private _cd: ChangeDetectorRef,
		private _supportService: SupportService,
		private renderer: Renderer2,
		private notifier: NotificationService,
		private store: Store<AppStateInterface>,
    private _companiesService: CompaniesService,
    public imageService: ImageService
	) {
    this.store.pipe(
      select(userDataSelector)
    ).subscribe((user)=>{
      this.user = user;
    })
  }

	ngAfterViewInit(): void {
    this._companiesService.getUsersDropdownByCompanyId(this.user.companyId).subscribe((tag: any)=>{
      this.allRelatedTags = tag.filter((user: any)=> user.email !== null);
      this.relatedTagList = this.relatedTag.valueChanges.pipe(
        startWith(null),
        map((t: string | null)=> t ? this._filterTag(t) : this.sliceTags(t))
      )
    })
  }

	ngAfterViewChecked(): void {
		if (this.markAllAsTouched) {
			this.markAllAsTouched = false;
			this.form.markAllAsTouched();
			this._cd.detectChanges();
		}
	}

	@Input() testVal: string;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isPreview']) {
			(this.form = new FormGroup<any>({})),
				(this._prevData = {}),
				(this._listeners = {});
		}
		if (changes['questions']) {
			if(!changes['questions'].firstChange){
				(this.form = new FormGroup<any>({})),
					(this._prevData = {}),
					(this._listeners = {});
			}

			changes['questions'].currentValue = changes['questions'].currentValue
				? this.questions
				: [];
			this.questions = clone(this.questions);
			this.initForm(this.questions);
			this._cd.detectChanges();
		}
    if (changes['ccEmails']) {
      const ccEmail = this.ccEmails?.filter((user: any)=> user.email !== null) as any[];
      this.allRelatedTags = ccEmail;
      this.relatedTagList = this.relatedTag.valueChanges.pipe(
        startWith(null),
        map((t: string | null)=> t ? this._filterTag(t) : this.sliceTags(t))
      )
    }
	}

	validateForm() {
		this.form.markAllAsTouched();
		this._cd.detectChanges();
	}

	loadRewst(question: any, i: number) {
		const el = this.buttonSeq.find(
			(item) => item.nativeElement.id === `buttonSeq${i}`
		);
		this.renderer.addClass(el?.nativeElement, 'active');
		this.renderer.setProperty(el?.nativeElement, 'disabled', true);
		// compose rewst request
		// identify which case 1st
		const val =
			question.rewstDependencyValue
				? this.form.controls['q' + question.rewstDependencyQuestionId].value
				: '';
		this._supportService.getRewst(question.id, val, this.companyId).subscribe(
			(data: any) => {
				this.renderer.removeClass(el?.nativeElement, 'active');
				this.renderer.setProperty(el?.nativeElement, 'disabled', false);
				if (data === null) {
					question.error = 'No response from REWST';
				} else if (data.error) {
					question.error = data.error;
					// this.notifier.notify('Error getting REWST Data',{duration: 5});
				} else if (data.body === null) {
					question.error = 'No response from REWST';
				} else if (data === '' || data === 'null') {
					question.error = 'No records available';
				} else if (data && typeof data === 'string') {
					question.error = data;
					this._cd.detectChanges();
				} else {
					question.error = '';
					// case 2 filter
					if (
						question.isRewstDependentQuestion === true &&
						question.rewstDependencyValue === 'filter'
					) {
						const parentQuestion = this.questions.find(
							(q: any) => q.id === question.rewstDependencyQuestionId
						)!;
						question.answerList = data[
							question.rewstDataObject || question.graphDatakey
						].filter(
							(data: any) =>
								this.form.controls['q' + question.rewstDependencyQuestionId]
									.value === data[parentQuestion.rewstDataTextField!]
						);
            if (question.answerList[0] && question.answerList[0][question.rewstDataTextField])
              this.form.controls['q' + question.id].setValue(question.answerList[0][question.rewstDataTextField]);
					} else {
						if (question.rewstDataObject)
							question.answerList = data[question.rewstDataObject];
						if (question.graphDatakey)
							question.answerList = data[question.graphDatakey].filter(
								(item: any) => item[question.graphDataSubKey] !== null
							);
						if (question.answerList.length <= 0)
							question.error = 'No records available';
					}
					this._cd.detectChanges();
				}
				this.newQuestions.emit(this.questions);
			},
			(err) => {
				this.renderer.removeClass(el?.nativeElement, 'active');
			}
		);
	}

	parentQuestionChange(evt: any) {}

	filterOptions(question: CustomFormQuestion) {
		this.store.pipe(select(userDataSelector), take(1)).subscribe((user) => {
			if (question.id === 29) {
				// filter answerlist for user
				// check mode
				// Dashboard
			}

			if (question.id === 35) {
				// filter answerlist for admin
				// check mode
			}
		});
	}

	initForm(questions: CustomFormQuestion[], parentId?: number) {
		questions.forEach((question, index) => {
			// filter question based on permission QUEST-5107
			this.filterOptions(question);
			let valid = true;
			if (parentId && !this.form.get('q' + parentId)) valid = false;
			if (question.questionAnswerDependencyList.length) {
				question.questionAnswerDependencyList.forEach((condition) => {
					if (!this._listeners['q' + condition.questionId])
						this._listeners['q' + condition.questionId] = [question];
					else if (
						!this._listeners['q' + condition.questionId].includes(question)
					)
						this._listeners['q' + condition.questionId].push(question);
					if (condition.action) valid = false;
				});
			}

			if (valid) {
				this.addQuestion(question);
				if (question.subQuestions!.length)
					this.initForm(question.subQuestions!, question.id);
			}

			// check all dependency questions
			if (question.isRewstDependentQuestion === true) {
				// find parent
				let parentQ = this.questions.find(
					(q) => q.id === question.rewstDependencyQuestionId
				);
				if (parentQ && parentQ.id) {
					if (this.arrayListeners.find((arr) => arr.id === parentQ!.id)) {
					} else {
						this.arrayListeners.push(parentQ);
					}
				}
			}

			setTimeout(() => {
				// sendrequest to rewst
				if (question.isRewst || question.isGraph) {
					this.loadRewst(question, question.id);
				}
			}, 100);
			//  find all parent questions
		});

		this.arrayListeners.forEach((q) => {
			this.subscriptions.push(
				this.form.controls['q' + q.id].valueChanges
					.pipe(debounceTime(300), distinctUntilChanged())
					.subscribe((val) => {
						//  find all children
						let childQ: CustomFormQuestion[] | [] = this.questions.filter(
							(child) => q.id === child.rewstDependencyQuestionId
						);
						// loop child
						childQ.forEach((el) => {
							this.loadRewst(el, el.id);
						});
					})
			);
		});
	}

	addQuestion(question: CustomFormQuestion) {
		if (!question.id) question.id = question.tempId!;

		let validators: any[] = [];
		question.answerValidationList.forEach((v) => {
			switch (v.answerValidationTypeId) {
				case FormValidationType.MaxLength:
					if (v.maxLength) validators.push(Validators.maxLength(v.maxLength!));
					break;
				case FormValidationType.MinLength:
					if (v.minLength) validators.push(Validators.minLength(v.minLength!));
					break;
				case FormValidationType.MaxNumber:
					if (v.maxNumericValue)
						validators.push(Validators.max(v.maxNumericValue!));
					break;
				case FormValidationType.MinNumber:
					if (v.minNumericValue)
						validators.push(Validators.min(v.minNumericValue!));
					break;
				case FormValidationType.RestrictPastDate:
					if (question.answerTypeId === FormAnswerType.Date)
						validators.push(FormValidators.pastDate);
					else validators.push(FormValidators.past);
					break;
				case FormValidationType.RestrictFutureDate:
					if (question.answerTypeId === FormAnswerType.Date)
						validators.push(FormValidators.futureDate);
					else validators.push(FormValidators.future);
					break;
				case FormValidationType.NoSpecialCharacters:
					validators.push(FormValidators.alphaNumeric);
					break;
			}
		});

		switch (question.answerTypeId) {
			case FormAnswerType.Email:
				validators.push(Validators.email);
				break;
			case FormAnswerType.Image:
				validators.push(
					FormValidators.fileTypes(['png', 'jpg', 'jpeg', 'webp'])
				);
				break;
			case FormAnswerType.Url:
				validators.push(FormValidators.url);
				break;
		}

		if (question.required === 1) validators.push(Validators.required);

		let control = new FormControl(
			this._prevData['q' + question.id] ?? null,
			validators
		);
		this.form.addControl('q' + question.id, control);

		if (
			SupportConstants.FORM_ANSWER_TYPES.find(
				(f) => f.id === question.answerTypeId
			)?.isMultiple
		)
			//Subscribe to changes for multiple inputs
			this.subscriptions.push(
				control.valueChanges.subscribe((value) => this.onChange(question.id))
			);
	}

	removeQuestion(question: CustomFormQuestion) {
		this._prevData = this.form.value;
		this.form.removeControl('q' + question.id);
		question.subQuestions?.forEach((q) => {
			if (this.form.controls['q' + q.id]) this.removeQuestion(q);
		});
	}

	onChange(questionId: number) {
		const listeners = this._listeners['q' + questionId];
		if (listeners)
			listeners.forEach((question) => this.checkConditions(question));
	}

	checkConditions(question: CustomFormQuestion) {
		let show = true,
			validCount = 0,
			control = this.form.controls['q' + question.id];

		question.questionAnswerDependencyList.forEach((condition) => {
			const value = this.form.controls['q' + condition.questionId]?.value;
			if (
				condition!.answerId.includes(+value) ||
				(value instanceof Array &&
					value.some((v) => condition!.answerId.includes(v))) ||
          condition!.isNotNull === true
			) {
				if (condition.action === 0) show = false;
				if (condition.action === 1) validCount++;
			} else if (condition.action === 0) validCount++;
			// else if(condition.action === 1) show = false;
		});

		if (show && !validCount) show = false;
		if (show && !control) {
			this.addQuestion(question);
			if (question.subQuestions?.length)
				this.initForm(question.subQuestions, question.id);
		} else if (!show && control) {
			this.removeQuestion(question);
		}
	}

	checkBoxToggled(
		event: Event,
		question: CustomFormQuestion,
		optionId: number
	) {
		const control = this.form.controls['q' + question.id],
			values: number[] = control.value || [],
			target = event.target as HTMLInputElement;

		if (!target.checked) {
			const i = values.indexOf(optionId);
			values.splice(i, 1);
		} else {
			const max = this.getMaxSelection(question);
			if (!max || values.length < max) values.push(optionId);
			else {
				target.checked = false;
				return;
			}
		}
		control.setValue(values), control.markAsTouched();
	}

	submit() {
		this.onSubmit.emit({
			event: 'submitted',
			data: this.form.value,
		});
	}

	async fileChange(files: FileList | any, questionId: number) {
		const control = this.form.controls['q' + questionId];
		control.setValue(files);
		control.markAsTouched();
		if (files instanceof FileList) {
			var invalidFiles = 0;
			for (let index = 0; index < files.length; index++) {
				const file = files[index];
				this.attachFiles.push(file);
				// let prevFile = await this.previewFile(file)
				// this.imagePreview.push(imageFile)
			}
		}
		this._cd.detectChanges();
	}

	openFile(file: File): void {
		const fileUrl = URL.createObjectURL(file);
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = file.name;
		link.click();
		URL.revokeObjectURL(fileUrl);
	}

	onRemoveFile(index: number, questionId: number) {
		this.attachFiles.splice(index, 1);
		const control = this.form.controls['q' + questionId];
		control.setValue(this.attachFiles);
		control.markAsTouched();
		this._cd.detectChanges();
	}

	get formAnswerType(): typeof FormAnswerType {
		return FormAnswerType;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => {
			sub.unsubscribe();
		});
		this.destroy$.next('');
		this.destroy$.complete();
	}

	getRewstOptions(question: CustomFormQuestion, changes: any) {
		// find parent question
		let rewstParentQuestion = this.questions.find(
			(q) => q.id === question.rewstDependencyQuestionId
		)!;

		// get parent form value
		if (changes) {
			let value = rewstParentQuestion.answerList.find(
				(ans) => ans.id === changes
			)!;
			value = value[question.rewstDependencyValue];
			return [{ [question.rewstDependencyValue]: value }];
		} else {
			return [];
		}
	}

  remove(tag: string): void {
    const index = this.relatedTags.indexOf(tag);

    if (index >= 0) {
      this.relatedTags.splice(index, 1);
    }

    if (this.relatedTags.length === 0) {
      //this.hasError = true;
    }
    this.emailError = this.validateEmail(tag);
  }
  emailError: boolean = false;
  add(event: MatChipInputEvent, question: CustomFormQuestion): void {
    const control = this.form.controls['q' + question.id],
    values: string[] = control.value || []
    const value = (event.value || '').trim();
    const existingValue = this.allRelatedTags.filter((x: any) => x.email === value);
    const existingSelection = this.relatedTags.filter((x: any) => x === value);

    // Add our tag
    if (value) {
      if (!this.validateEmail(value)) {
        if (existingValue.length === 0 && existingSelection.length === 0) {
          this.relatedTags.push(value);
          values.push(value)
          control.setValue(values)
          this.emailError = false;
          // Clear the input value
          event.chipInput!.clear();
          this.relatedTag.setValue(null);
        } else {
          this.emailValidateMessage = 'This tag already selected or already available on selection';
          this.emailError = true;
        }
      } else {
        this.emailValidateMessage = 'Invalid email';
        this.emailError = true;
      }
    } else {
      // Clear the input value
      event.chipInput!.clear();
    }
  }

  spaceEvent(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      const inputElement = event.target as HTMLInputElement;
      const value = (inputElement.value || '').trim();
      const existingValue = this.allRelatedTags.filter((x: any) => x.email === value);
      const existingSelection = this.relatedTags.filter((x: any) => x === value);

      // Add our tag
      if (value) {
        if (!this.validateEmail(value)) {
          if (existingValue.length === 0 && existingSelection.length === 0) {
            this.relatedTags.push(value);
            this.emailError = false;
            // Clear the input value
            inputElement.value = '';
            this.relatedTag.setValue(null);
          } else {
            this.emailValidateMessage = 'This tag already selected or already available on selection';
            this.emailError = true;
          }
        } else {
          this.emailValidateMessage = 'Invalid email';
          this.emailError = true;
        }
      } else {
        // Clear the input value
        inputElement.value = '';
      }
    }
  }
  checkEmptyValue(event: any) {
    const value = event.target.value.trim();
    if (value === '') {
      this.emailError = false;
      this.form.get('RelatedTags')?.setErrors(null);
    }
  }
  selected(event: MatAutocompleteSelectedEvent, question: CustomFormQuestion): void {
    const control = this.form.controls['q' + question.id]
    const tag = event.option.viewValue;
    this.relatedTags.push(tag);
    control.setValue(this.relatedTags.toString())
    this.relatedTagsInput.nativeElement.value = '';
    this.relatedTag.setValue(null);
    this.filterTagList(tag);
  }
  filterTagList(tag: any) {
    return this.relatedTagList.pipe(
      map(tags => tags.filter(x=> x.email !== tag))
    )
  }

  validateEmail(email: any){
    const emailToValidate = [email];
    return !emailToValidate.every(email=> email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
   }

  sliceTags(t: any): any {
    const filteredTags = this.allRelatedTags.filter((tags: any) => {
      return this.relatedTags.findIndex(x => x === tags.email) === -1;
    });
    return filteredTags;
  }
  private _filterTag(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allRelatedTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  }
  convertAsArray(val: any) {
    const filtered = val.filter((x: any) => !this.relatedTags.includes(x.label))
    return filtered as any[];
  }

	getMaxSelection(question: CustomFormQuestion) {
		const max = question.answerValidationList.find(
			(v) => v.answerValidationTypeId === FormValidationType.MaxAnswer
		)?.maxAllowSelected || question.answerList.length;
		return max;
	}
}
