import {
	Component,
	AfterViewInit,
	ChangeDetectorRef,
	Input,
	EventEmitter,
	Output,
	OnDestroy,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	BreakpointObserver,
	Breakpoints,
	BreakpointState,
} from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';
import { Observable, Subject, of, takeUntil } from 'rxjs';

@Component({
	selector: 'app-column-slider',
	templateUrl: './column-slider.component.html',
	styleUrls: ['./column-slider.component.scss'],
})
export class ColumnSliderComponent
	implements AfterViewInit, OnChanges, OnDestroy
{
	@Input() displayedColumns: string[] = [];
	@Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter<
		string[]
	>();
	@Input() freezePanes: number = 1; //first n columns will be freezed
	@Input() columnBreakpoints: number[] = [2, 2, 3, 4, 5];
	@Input() reduceColumn$: Observable<null | ParentChildWidthContainer> =
		of(null);
	@Input() hasAction: boolean = false;

	hiddenLeftColumns: string[] = [];
	hiddenRightColumns: string[] = [];
	originalColumns: string[] = [];
	XXSmall: string = '(max-width: 399.98px)';
	bPoint: string = '';
	private _$unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		private cdr: ChangeDetectorRef
	) {}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['displayedColumns']?.firstChange) {
			this.displayedColumns = changes['displayedColumns'].currentValue;
		} else if (changes['displayedColumns']) {
			if (
				this.isSame(
					changes['displayedColumns'].currentValue,
					this.originalColumns
				) &&
				(this.hiddenLeftColumns.length > 0 ||
					this.hiddenRightColumns.length > 0)
			) {
				this.displayedColumns = changes['displayedColumns'].previousValue;
				this.displayedColumnsChange.emit(this.displayedColumns);
			}
		} else if (changes['columnBreakpoints']) {
			if (
				this.parseToString(changes['columnBreakpoints'].currentValue) !==
				this.parseToString(changes['columnBreakpoints'].previousValue)
			) {
				this.checkBreakPoint().subscribe((result) => {
					let breakpoints = result.breakpoints;
					for (let b in breakpoints) {
						if (breakpoints[b as string] === true) {
							this.bPoint = b;
							this.updateColumns(b);
						}
					}
					//optional can be removed just testing if change detection does not work, sometimes it doesnt because some components have a different change detection strategy
					this.cdr.detectChanges();
				});
			}
		}
	}

	ngAfterViewInit(): void {
		this.originalColumns = [...this.displayedColumns];
		this.checkBreakPoint().subscribe((result) => {
			let breakpoints = result.breakpoints;
			for (let b in breakpoints) {
				if (breakpoints[b as string] === true) {
					this.bPoint = b;
					this._initiateColumnUpdate(b);
				}
			}
			//optional can be removed just testing if change detection does not work, sometimes it doesnt because some components have a different change detection strategy
			this.cdr.detectChanges();
		});

		this.reduceColumn$
			.pipe(
				takeUntil(this._$unsubscribe),
				filter((v) => v != null)
			)
			.subscribe((v) => {
				if (v?.child! > v?.parent!) {
					if (v?.reset === true) this.resetColumns();
					this.removeColumn(this.displayedColumns.length - 1);
				}
			});
	}

	parseToString(arr: any[]) {
		return JSON.stringify(arr);
	}

	private _initiateColumnUpdate(b: string) {
		this.resetColumns();
		this.updateColumns(b);
	}

	checkBreakPoint(): Observable<BreakpointState> {
		return this.breakpointObserver
			.observe([
				this.XXSmall,
				Breakpoints.XSmall,
				Breakpoints.Small,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge,
			])
			.pipe(takeUntil(this._$unsubscribe));
	}

	updateColumns(b: string) {
		let col: number = 0;
		switch (b) {
			case this.XXSmall: // must have 2 columns
				col = this.columnBreakpoints[0];
				break;
			case Breakpoints.XSmall: // must have 3 columns
				col = this.columnBreakpoints[1];
				break;
			case Breakpoints.Small: // must have 3 columns
				col = this.columnBreakpoints[2];
				break;
			case Breakpoints.Medium: // must have 4 columns
				col = this.columnBreakpoints[3];
				break;
			case Breakpoints.Large: // must have 5 columns
				col = this.columnBreakpoints[4];
				break;
			case Breakpoints.XLarge: // must have more than 6 columns
				col = this.displayedColumns.length;
				break;
			default:
				col = this.displayedColumns.length;
				break;
		}
		this.removeColumn(col);
	}

	removeColumn(cols: number) {
		if (cols > 1) {
			this.#hasAction('remove');
			while (
				cols < this.displayedColumns.length &&
				this.displayedColumns.length > this.freezePanes
			) {
				let poppedColumn: string = this.displayedColumns.pop() as string;
				//const index = this.hiddenRightColumns.findIndex(v=>v===poppedColumn)
				if (isDuplicate(this.hiddenRightColumns, poppedColumn))
					this.hiddenRightColumns.unshift(poppedColumn);
				//(i>0)??this.hiddenRightColumns.unshift(poppedColumn);
			}
			this.#hasAction('return');
			this.#checkColumns();
		}
	}

	toggleLeft(): void {
		if (!this.isLeft) return;
		this.#hasAction('remove');
		this.hiddenRightColumns.unshift(this.displayedColumns.pop() as string);
		this.displayedColumns.splice(
			this.freezePanes,
			0,
			this.hiddenLeftColumns.pop() as string
		);
		this.#hasAction('return');
		this.#checkColumns();
	}

	toggleRight(): void {
		if (!this.isRight) return;
		this.#hasAction('remove');
		this.hiddenLeftColumns.push(
			...this.displayedColumns.splice(this.freezePanes, 1)
		);
		this.displayedColumns.push(...this.hiddenRightColumns.splice(0, 1));
		this.#hasAction('return');
		this.#checkColumns();
	}

	#hasAction(action: 'remove' | 'return') {
		if (this.hasAction) {
			if (action == 'remove') {
				this.displayedColumns.pop();
			} else {
				this.displayedColumns.push('action');
			}
		}
	}

	private isSame(val1: any, val2: any) {
		return JSON.stringify(val1) === JSON.stringify(val2);
	}

	#checkColumns() {
		// this is just for tracing... enable if wanted
		//console.log('displayed', this.displayedColumns, 'left', this.hiddenLeftColumns, 'right', this.hiddenRightColumns);
	}

	get isLeft() {
		return this.hiddenLeftColumns.length > 0;
	}

	get isRight() {
		return this.hiddenRightColumns.length > 0;
	}

	private resetColumns() {
		this.displayedColumns = [...this.originalColumns];
		this.displayedColumnsChange.emit(this.displayedColumns);
		this.hiddenLeftColumns = [];
		this.hiddenRightColumns = [];
		this.cdr.detectChanges();
	}
}

export interface ParentChildWidthContainer {
	parent: number;
	child: number;
	reset?: boolean;
}

const isDuplicate = (arr: string[], search: string) => arr.indexOf(search) < 0;
