<div class="row">
  <div class="col-md-12 p-0">
    <div class="main-searchbar invoice-searchs search text-left">
      <ul class="list-unstyled d-flex d-inline-flex m-0">
        <li>
          <input
            type="text"
            class="form-control mainsearch"
            placeholder="Search"
            (keyup.enter)="setupAndSendProps()"
            (input)="onSearch()"
            #search
          />
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="row">
  <div
    class="mx-1 applist-table agree-table table-responsive table-striped col-md-12"
  >
    <div class="col-md-12 col-12 px-0">
      <app-column-slider
      [(displayedColumns)]="displayedColumns"
      ></app-column-slider>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="table"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>NAME</th>
          <td class="py-3" mat-cell *matCellDef="let row">
            {{ row.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>NAME</th>
          <td class="py-3" mat-cell *matCellDef="let row">
            {{ row.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="categoryName">
          <th mat-header-cell *matHeaderCellDef>CATEGORY NAME</th>
          <td class="py-3" mat-cell *matCellDef="let row">
            {{ row.categoryName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="notificationTitle">
          <th mat-header-cell *matHeaderCellDef>TITLE</th>
          <td class="py-3" mat-cell *matCellDef="let row">
            {{ row.notificationTitle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="publishDateTime">
          <th mat-header-cell *matHeaderCellDef>PUBLISHED DATE</th>
          <td class="py-3" mat-cell *matCellDef="let row">
            {{ row.publishDateTime | customDate }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">
            {{ message.noRecord }}
          </td>
        </tr>
      </table>
    </div>

    <table-paginator
      [totalItems]="totalItems"
      [pageSizes]="pageSizes"
      (pageChange)="setupAndSendProps($event)"
      class="mb-5"
    ></table-paginator>
  </div>
</div>
