import { Component, EventEmitter, Input, Output } from '@angular/core';

import { VersionStatus } from '@app/services/pwa.service';

@Component({
	selector: 'new-update-card',
	templateUrl: './new-update-card.component.html',
	styleUrls: ['./new-update-card.component.scss'],
})
export class NewUpdateCardComponent {
	@Input() status: VersionStatus;
	@Input() isCollapsed = false;
	@Output() closed = new EventEmitter<boolean>();
	versionStatus = VersionStatus;

	update() {
		document.location.reload();
		localStorage.setItem('new-version-refresh-event', Math.random().toString());
	}

	close() {
		this.closed.emit(true);
	}
}
