<div class="card">
	<div class="profile-wraps">
		<ul class="list-unstyled m-0 text-sm">
			<li
				*ngFor="let nav of navItems"
				[routerLink]="nav.link"
				routerLinkActive="active"
			>
				<a href="javascript:void(0)">
					<i
						class="fas"
						[ngClass]="{
							'text-info14': nav.name === 'User',
							'text-info9': nav.name === 'Notifications',
							'text-info3': nav.name === 'Theme Customization'
						}"
					></i>
					{{ nav.name }}
				</a>
			</li>
		</ul>
	</div>
</div>
