import { Component, OnInit, inject } from '@angular/core';
import { UnderConstructionService } from './under-construction.service';

@Component({
	selector: 'quest-under-construction',
	templateUrl: './under-construction.component.html',
	styleUrls: ['./under-construction.component.scss'],
})
export class UnderConstructionComponent implements OnInit {
	ngOnInit(): void {}
}
