<div class="card">
  <div class="card-header pb-0 sidebar-left">
    <div class="d-lg-flex">
      <section class="section-content mini-sidebar">
        <div class="row">
          <aside class="col-lg-12">
            <!-- ============= COMPONENT ============== -->
            <nav class="sidebar card py-2 mb-4">
              <ul class="nav flex-column" id="sidebarNav1">
                <ng-container *ngFor="let menu of subMenu; let i = index">
                  <ng-container *ngIf="(menu.subMenus && menu.subMenus.length > 0);else noDropdown">
                    <li class="nav-item has-submenu" app-sub-menu-accordion [sideMenuData]="menu" [index]="i" [menuId]="menuId"
                      [activeMenu$]="activeMenu$" (selectedMenu)="setActiveMenu($event)"
                    >
                    </li>
                  </ng-container>

                  <ng-template #noDropdown>
                    <li class="nav-item" app-sub-menu-item [sideMenuData]="menu" [menuId]="menuId"
                      (click)="setParentMenu(menu.id)"
                    >
                    </li>
                  </ng-template>
                </ng-container>
              </ul>
            </nav>
          </aside>
        </div>
      </section>
    </div>
  </div>
</div>
