import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';

import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { DashboardService } from '../../../../dashboard.service';

import { TableMessages } from '@app/shared/constants';

import {
	QuotesWidget,
	SeeMore,
	TableDynamicColumns,
	WidgetDynamicTable2,
} from '@app/shared/interfaces/dashboard.interface';

@Component({
	selector: 'app-quotes-widget',
	templateUrl: './quotes-widget.component.html',
	styleUrls: ['./quotes-widget.component.scss'],
})
export class QuotesWidgetComponent implements OnInit, OnDestroy {
	source: any[] = [];
	noRecords: string = TableMessages.EmptyTable;
	columns: TableDynamicColumns[] = [];
	displayedColumns: string[] = [];
	rowClick: (arg1: any) => void;
	noClick: () => void;
	seeMore: SeeMore;
	rowClickable = false;

	dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);
	private _quotes$: Observable<QuotesWidget[] | null>;
	private _subscription = new Subscription();

	constructor(
		private _dashboard: DashboardService,
		private _cd: ChangeDetectorRef,
		private _toastMessageService: ToastMessageService
	) {
		this._dashboard.initQuotes();
	}

	ngOnInit() {
		this._quotes$ = this._dashboard.quotes$;
		this._subscription.add(
			this._quotes$.pipe(filter((v) => v != null)).subscribe((v) => {
				this._createDatatable(v as QuotesWidget[]);
			})
		);
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	private _rowClickFn = (data: any) => {
		const { quoteURL } = data;
		if (quoteURL) window.open(quoteURL, '_blank');
		else this._toastMessageService.showErrorMessage('No URL provided');
	};

	private _createDatatable(quotes: QuotesWidget[]) {
		this.source = quotes;
		this.columns = this._getColumns();
		this.displayedColumns = this._getDisplayedColumns();
		this.rowClick = this._rowClickFn;
		this.seeMore = this._getSeeMore();
		this.rowClickable = true;
		this.dataTable$.next(this._getDataTable());
		this._cd.detectChanges();
	}

	private _getColumns(): any[] {
		return [
			{
				text: 'Quote #',
				matColumnDef: 'id',
			},
			{
				text: 'Date',
				matColumnDef: 'quoteDate',
			},
			{
				text: 'Category',
				matColumnDef: 'category',
			},
			{
				text: 'Total',
				matColumnDef: 'total',
				class: {
					th: 'center-text',
					td: 'center-text',
				},
			},
			{
				text: 'Status',
				matColumnDef: 'status',
				statusType: 'badge',
				badgeColor: 'color',
			},
		];
	}

	private _getSeeMore() {
		return {
			link: '/billing-and-orders/quotes',
			state: null,
		};
	}

	private _getDisplayedColumns() {
		return ['id', 'quoteDate', 'category', 'total', 'status'];
	}

	private _getDataTable(): WidgetDynamicTable2 {
		return {
			source: this.source,
			columns: this.columns,
			rowClick: this.rowClick,
			noClick: this.noClick,
			noRecordMessage: this.noRecords,
			displayedColumns: this.displayedColumns,
			seeMore: this.seeMore,
			rowClickable: this.rowClickable,
		};
	}
}
