<div class="main-contents spdmin-main">
  <h3>Enable Two Factor Authentication</h3>

  <div class="pro-conts" *ngIf="(twoFAQR$ | async)">
    <div class="mfa-container">
      <h4>Scan the QR Code below using an Authenticator App from your Mobile Device</h4>
      <div class="qr-code-container">
        <img image-error-detection [component]="'two-factor-auth.component.html'"
        [src]="(twoFAQR$ | async)" alt="">
      </div>
      <div class="secret-key-container">
        <a (click)="isCodeShown = !isCodeShown;">{{!isCodeShown ? 'Show' : 'Hide'}} Secret Key for Manual Configuration</a>
        <div class="code-container" *ngIf="isCodeShown">
          <input type="text" [value]="(secretCode$ | async)" readonly>
          <button (click)="copy()" [cdkCopyToClipboard]="(secretCode$ | async)!"></button>
        </div>
      </div>

      <div class="fa-label">
        <label>Verify 2FA</label>
      </div>
      <div class="fa-input">
        <input type="text" #faValue/>
      </div>
      <div class="btn-container">
        <button (click)="save(faValue)" class="btn blue-btn">Save</button>
      </div>
    </div>
  </div>
  <script>

  </script>
</div>
