<div class="edit-wrap">
  <label for=""><ng-content></ng-content></label>
  <multi-select-input
  class="form-control full-width"
  placeholder="Select Groups"
  [(ngModel)]="selectedGroups"
  [options]="( groups$ | async)!"
  [optionKeys]="{value:'id', label:'name'}"
  [customClass]="'company-permission-autocomplete'"
  (onChange)="getChosenGroupId()"
  ></multi-select-input>
</div>
