import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { ThemeComponent } from './pages/user/theme/theme.component';
import { UserComponent } from './pages/user/user.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full',
  },
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: 'profile',
        data: { 
          title: 'Profile',
          isMyAccountPage: true
        },
        loadChildren: () => import('../../shared/user-profile-shared/user-profile-shared.module').then((m) => m.UserProfileSharedModule),
      },
      {
        path: 'theme',
        data: { title: 'Theme' },
        component: ThemeComponent,
      },
      {
        path: 'notifications',
        data: { title: 'Notifications' },
        component: NotificationsComponent,
      },
      // {
      //   path: 'authentication',
      //   data: { title: 'Authentication' },
      //   children: [
      //     {
      //       path: '',
      //       component: AuthenticationComponent,
      //     },
      //     {
      //       path: '2fa',
      //       data: { title: '2 Factor Authentication' },
      //       component: TwoFactorAuthComponent,
      //       canActivate: [Is2FAGuardGuard],
      //     },
      //   ],
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
