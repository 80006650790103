import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxListComponent } from './checkbox-list.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrimPipeModule } from '@app/shared/pipes/trim.pipe.module';






@NgModule({
  declarations: [
    CheckboxListComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    TrimPipeModule
  ],
  exports:[
    CheckboxListComponent
  ],
})
export class CheckboxListModule { }
