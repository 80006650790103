import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	inject,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
	distinctUntilKeyChanged,
	filter,
	fromEvent,
	merge,
	Subscription,
	tap,
} from 'rxjs';

@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TimePickerComponent),
			multi: true,
		},
	],
})
export class TimePickerComponent
	implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
	@Output() emitTime = new EventEmitter<string>();

	timeSlots: string[] = [];
	internalControl = new FormControl();
	timeInput = '';

	private _onChange = (value: any) => {};
	private _onTouch = () => {};
	private _subscription = new Subscription();

	private _el = inject(ElementRef);
	private _renderer = inject(Renderer2);

	ngOnInit() {
		for (let hour = 0; hour < 12; hour++) {
			for (let minute = 0; minute < 60; minute += 15) {
				const hourFormatter =
					hour === 0 ? hour.toString().replace('0', '12') : hour;
				const minuteFormatted = minute < 10 ? '0' + minute : minute;
				this.timeSlots.push(`${hourFormatter}:${minuteFormatted}`);
			}
		}
	}

	ngAfterViewInit() {
		this.clickSubscription();
		this.keypressSubscription();
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	onBlur() {
		if (
			!(
				this.timeInput.toUpperCase().includes('AM') ||
				this.timeInput.toUpperCase().includes('PM')
			) &&
			this.timeInput.trim().length >= 4
		) {
			let time = `${this.timeInput.trim()} PM`;
			if (this.timeInput.trim().indexOf(':') === -1) {
				if (this.timeInput.length < 4) {
					this.timeInput = this.timeInput.padStart(4, '0');
				}
				const hours = this.timeInput.trim().slice(0, 2);
				const minutes = this.timeInput.trim().slice(2, 4);
				time = `${hours}:${minutes} PM`;
			}
			this.writeValue(time);
			this.emitTime.emit(time);
		} else {
			if (/^(0?[1-9]|1[0-2]):([0-5][0-9])([APap][Mm])$/.test(this.timeInput)) {
				// Insert space before AM/PM
				this.timeInput = this.timeInput.replace(/([APap][Mm])$/, ' $1');
				this.writeValue(this.timeInput);
				this.emitTime.emit(this.timeInput);
			}
		}
	}

	onClickPeriod(period: MouseEvent) {
		const currentSelectedPeriod =
			this._el.nativeElement.querySelector('.am-pm.selected');
		const target = period.target as HTMLElement;
		const selectedPeriod = (
			target.tagName.toLowerCase() === 'div' ? target : target.closest('.am-pm')
		) as HTMLElement;

		if (currentSelectedPeriod) {
			this._renderer.removeClass(currentSelectedPeriod, 'selected');
		}

		this._renderer.addClass(selectedPeriod, 'selected');
	}

	onClickTime(time: MouseEvent | Event) {
		const currentSelectedTime = this._el.nativeElement.querySelector(
			'.time-slot.selected'
		);
		const selectedTime = time.target as HTMLElement;

		if (currentSelectedTime) {
			this._renderer.removeClass(currentSelectedTime, 'selected');
		}

		this._renderer.addClass(selectedTime, 'selected');

		const selectedPeriod = this._el.nativeElement.querySelector(
			'.am-pm.selected'
		) as HTMLElement;

		if (selectedPeriod) {
			const time = `${selectedTime.dataset['value']} ${selectedPeriod.dataset['value']}`;
			this.writeValue(time);
			this.emitTime.emit(time);
			this.timeInput = time;
			this._hideTimePicker();
		}
	}

	keypressSubscription() {
		const timeInput = this._el.nativeElement.querySelector('.time-input');

		const keyupEvent = fromEvent<KeyboardEvent>(timeInput, 'keyup').pipe(
			tap((event) => {
				const currentValue = timeInput.value;
				const keyPressed = event.key;

				if (
					!/[\d:APapMm ]/.test(keyPressed) &&
					keyPressed.toLowerCase() !== 'shift'
				) {
					event.preventDefault();
				} else if (keyPressed === ':' && currentValue.includes(':')) {
					event.preventDefault();
				} else if (
					/^\d{2}$/.test(currentValue) &&
					!currentValue.includes(':')
				) {
					// Automatically add ':' after 2 digits
					timeInput.value = currentValue + ':';
				} else if (
					/^\d{2}:\d{3}$/.test(currentValue) &&
					!/^[APap][Mm]?$/.test(keyPressed)
				) {
					// Prevent further typing if the format is '12:34' except for AM/PM
					event.preventDefault();
				} else if (
					/^\d{2}:\d{2}\s?[APap][Mm]?$/.test(currentValue) &&
					!/[APapMm ]/.test(keyPressed) &&
					keyPressed.toLowerCase() !== 'shift'
				) {
					// Prevent further typing if the format is '12:34 AM' and no space or AM/PM is typed
					event.preventDefault();
				}
			})
		);
		const inputEvent = fromEvent<Event>(timeInput, 'input').pipe(
			tap(() => {
				let currentValue: string = timeInput.value;

				currentValue = currentValue.replace(/[^\d:APapMm ]/g, '');
				currentValue = currentValue.replace(/\s+/g, ' ').toUpperCase();
				timeInput.value = currentValue;
			})
		);

		this._subscription.add(
			merge(keyupEvent, inputEvent)
				.pipe(
					distinctUntilKeyChanged('defaultPrevented'),
					tap((input) => {
						if (input.defaultPrevented) {
							const target = (input.target as HTMLInputElement).value;

							if (target.includes('::')) {
								this.writeValue(target.replace('::', ':'));
							} else {
								const timeRegex =
									/^(0?[1-9]|1[0-2]):([0-5][0-9]) ?([APap][Mm])?$/;

								if (!timeRegex.test(target)) {
									this.writeValue(target.slice(0, -1));
								}
							}
						}
					})
				)
				.subscribe()
		);

		this._subscription.add(
			this.internalControl.valueChanges.subscribe((input) => {
				this.timeInput = input.toUpperCase();
			})
		);
	}

	clickSubscription() {
		const timeInput = this._el.nativeElement.querySelector('.time-input-cont');

		this._subscription.add(
			fromEvent(timeInput, 'click')
				.pipe(
					tap(() => {
						const input = this._el.nativeElement.querySelector('.time-input');
						input.focus();

						const timePicker = this._el.nativeElement.querySelector(
							'.time-picker-wrapper'
						) as HTMLElement;

						if (timePicker.classList.contains('d-none')) {
							this._renderer.removeClass(timePicker, 'd-none');
						}
					})
				)
				.subscribe()
		);

		this._subscription.add(
			fromEvent(document, 'click')
				.pipe(
					filter((event) => {
						const timePicker = this._el.nativeElement.querySelector(
							'.time-picker-wrapper'
						) as HTMLElement;
						const isHidden = timePicker.classList.contains('d-none');
						return !this._el.nativeElement.contains(event.target) && !isHidden;
					}),
					tap(() => {
						this._hideTimePicker();
					})
				)
				.subscribe()
		);
	}

	writeValue(value: any) {
		if (value) {
			this.internalControl.setValue(value);
		}
	}

	registerOnChange(fn: any) {
		this._onChange = fn;
		this._subscription.add(this.internalControl.valueChanges.subscribe(fn));
	}

	registerOnTouched(fn: any) {
		this._onTouch = fn;
	}

	private _hideTimePicker() {
		this._onTouch();
		const timePicker = this._el.nativeElement.querySelector(
			'.time-picker-wrapper'
		) as HTMLElement;
		this._renderer.addClass(timePicker, 'd-none');
	}
}
