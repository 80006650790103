import { Component, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DrawerComponentConfig } from '../interfaces/drawer-component-config.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardDrawerService {

  openDrawerRequested = new Subject<DrawerComponentConfig>();

  private _openWidgetList:boolean = false;
  private _openFeedbackList:boolean = false;
  private _openContactSupport:boolean = false;

  constructor() { }

  openDrawer(component: DrawerComponentConfig){
     this.openDrawerRequested.next(component);
  }

  get openWidgetList(){return this._openWidgetList;}
  get openFeedbackList(){return this._openFeedbackList;}
  get openContactSupport(){return this._openContactSupport;}

  set openWidgetList(status:boolean){
    if(status){
      this._openContactSupport = false;
      this._openFeedbackList = false;
    }
    this._openWidgetList = status;
  }
  set openFeedbackList(status:boolean){
    if(status){
      this._openContactSupport = false;
      this._openWidgetList = false;
    }
    this._openFeedbackList = status;
  }
  set openContactSupport(status:boolean){
    if(status){
      this._openFeedbackList = false;
      this._openWidgetList = false;
    }
    this._openContactSupport = status;
  }

}
