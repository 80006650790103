import { Directive, Input, TemplateRef, OnInit, ElementRef, HostListener, ViewContainerRef } from '@angular/core';
import { ComponentType, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { CustomTooltipV2Service } from './custom-tooltip-v2.service';

@Directive({
  selector: '[customTooltip]'
})
export class CustomTooltipV2Directive implements OnInit {
  @Input('customTooltip') tooltipContent: TemplateRef<any> | ComponentType<any>;
  @Input() tooltipData: any;
  @Input() isTooltipEnabled: boolean = false;
  private _overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private customTooltipV2Service: CustomTooltipV2Service
  ) { }

  ngOnInit(): void {
    if (!this.isTooltipEnabled) { return; }

    if (this.tooltipContent) {
      const position = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically()

      this._overlayRef = this.overlay.create({
        positionStrategy: position,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: 'custom-tooltip',
      });
    }
  }

  private updateTooltipPosition(event: MouseEvent) {
    const position = this.overlayPositionBuilder
      .flexibleConnectedTo({ x: event.clientX , y: event.clientY })
      .withViewportMargin(40)
      // .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 5,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -5,
        }
      ]);

    this._overlayRef.updatePositionStrategy(position);
  }

  @HostListener('mouseenter')
  private _show(): void {
    if (!this.isTooltipEnabled) { return; }

    if (this._overlayRef) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;

      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
      }

      else {
        containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      }

      if (this.tooltipData) {
        this.customTooltipV2Service.setRowData(this.tooltipData);
        this._overlayRef.attach(containerPortal);
      }
    }
  }

  @HostListener('mouseleave')
  private _hide(): void {
    if (!this.isTooltipEnabled) { return; }

    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isTooltipEnabled) { return; }

    this.updateTooltipPosition(event);
  }
}
