import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-unauthorized-access',
	templateUrl: './unauthorized-access.component.html',
	styleUrls: ['./unauthorized-access.component.scss'],
})
export class UnauthorizedAccessComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
