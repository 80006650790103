import {
  agreementsUpdateAction,
  agreementsUpdateSuccessAction,
} from './agreements.actions';
import { on } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import { PaginationConstants } from '@app/shared/constants';
import { IAgreements } from './agreements.interface';

const initialState: IAgreements = {
  page: 1,
  pageSize: PaginationConstants.pageSize,
  order: 'asc',
  column: 'name',
  query: '',
  totalItems: 0,
  startDate: '',
  endDate: '',
  statusId: [],
  typeId: [],
};

export const agreementsReducer = createReducer(
  initialState,
  on(
    agreementsUpdateAction,
    (state, action): IAgreements => ({
      ...state,
      ...action,
    })
  ),
  on(
    agreementsUpdateSuccessAction,
    (state): IAgreements => ({
      ...state,
    })
  )
);
