import { createAction, props } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';

export enum ActionTypes {
	COMPANY_AGREEMENTS_TYPE_UPDATE = '[Company Agreement Types] Update',
	COMPANY_AGREEMENTS_TYPE_UPDATE_SUCCESS = '[Company Agreement Types] Update Success',
}

export const companyAgreementTypesUpdateAction = createAction(
	ActionTypes.COMPANY_AGREEMENTS_TYPE_UPDATE,
	props<Partial<IPageState>>()
);

export const companyAgreementTypesUpdateSuccessAction = createAction(
	ActionTypes.COMPANY_AGREEMENTS_TYPE_UPDATE_SUCCESS
);
