import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuPermissionGuard } from '@app/core/guards/menu-permission.guard';

import { PermissionGuard } from '@app/core/guards/permission.guard';
import { UserTypeGuard } from '@app/core/guards/user-type.guard';

import { MenuPermissionConstant, Permission, UserTypes } from '@app/shared/constants';
import { invoiceRoutes } from '../finance/finance-routing.module';

import { OrdersDetailComponent } from './pages/orders-detail/orders-detail.component';
import { OrdersInquiryComponent } from './pages/orders-inquiry/orders-inquiry.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
const routes: Routes = [
  {
    path: 'quotes',
    component: QuotesComponent,
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    data: {
      title: 'Quotes',
      userTypes: [UserTypes.ClientAdmin, UserTypes.User,UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.QuoteAndOrders.subMenus!['quotes'],
    },
  },
  {
    path: 'orders',
    canActivate: [MenuPermissionGuard],
    data: {
      title: 'Orders',
      hasUrl: true,
      userTypes: [
        UserTypes.ClientAdmin,
        UserTypes.User,
        UserTypes.SourcepassAdmin],
        id: MenuPermissionConstant.QuoteAndOrders.subMenus!['orders'],
    },
    children: [
      { path: '', component: OrdersComponent, canActivate: [UserTypeGuard] },
      {
        path: ':id',
        component: OrdersDetailComponent,
        data: {
          title: 'Order Details'
        },
      },
      {
        path: ':id/inquiry',
        component: OrdersInquiryComponent,
        data: {
          title: 'Order Inquiries'
        },
      },
    ],
  },
  { path: 'invoices',  canActivate: [UserTypeGuard, MenuPermissionGuard],
  data: {
    title: 'Invoices',
    hasUrl: true,
    userTypes: [
      UserTypes.ClientAdmin,
      UserTypes.User,
      UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.QuoteAndOrders.subMenus!['invoices'],
  },
  children: invoiceRoutes

},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuotesAndOrdersRoutingModule {}
