import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";
import { IJWT } from "./jwt.interface";




export const jwtSelector = (state: AppStateInterface): IJWT => state.jwt;

export const accessTokenSelector =
createSelector(jwtSelector, (state: IJWT)=>state.accessToken);

export const refreshTokenSelector =
createSelector(jwtSelector, (state: IJWT)=>state.refreshToken);
