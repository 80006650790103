import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalFigmaIconImgComponent } from './local-figma-icon-img.component';

@NgModule({
	declarations: [LocalFigmaIconImgComponent],
	imports: [CommonModule],
	exports: [LocalFigmaIconImgComponent],
})
export class LocalFigmaIconImgModule {}
