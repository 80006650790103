import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLogsService {
  //injection 
  private _router = inject(Router);

  // props
  console = console.error;
  private _error: any[] = [];

  constructor(private _http: HttpClient) {}

  overrideError = (message: any, ...optionalParams: any[]) => {
    const err = new Error(message);
    let msg = optionalParams.length > 0 ? optionalParams[0].message : '';

    this._error.push(msg);

    if (!environment.production) {
      // this.console(message, optionalParams);
    }
  };

  get error() {
    return this._error;
  }

  get errorStringify() {
    return JSON.stringify(this._error);
  }

  clearError() {
    this._error = [];
  }

  sendError(body: any[]) {
    let httpReq = null;

    if (!environment.isDevMode) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
        'X-API-Key': environment.xApikey,
      });
      const options = { headers };

      httpReq = this._http.post(
        `${environment.apiBaseUrl}Developers/SendFrontendConsoleError`,
        body,
        options
      );
    }

    return httpReq;
  }

  composeErrorLog(error: string, file: string = ''){
    if(!error)error = 'Unknown error';
    if(!file && this._router.url) file = `Unable to capture the file, url provided: ${this._router.url}`;
    else file = 'Unable to capture file and url'

    return [{
      error, 
      file,
      dateTime: moment().format()
    }]
  }
}
