import { createAction, props } from '@ngrx/store';

import { ICompanyNotificationCenterEditViewParams } from '@app/modules/notification-center/INotificationCenter.interface';

export enum ActionTypes {
	COMPANY_NOTIFICATION_CENTER_EDIT_VIEW_UPDATE = '[Company Notification Center Edit] Update',
	COMPANY_NOTIFICATION_CENTER_EDIT_VIEW_UPDATE_SUCCESS = '[Company Notification Center Edit] Update Success',
}

export const companyNotificationCenterEditViewUpdateAction = createAction(
	ActionTypes.COMPANY_NOTIFICATION_CENTER_EDIT_VIEW_UPDATE,
	props<Partial<ICompanyNotificationCenterEditViewParams>>()
);

export const companyNotificationCenterEditViewUpdateSuccessAction =
	createAction(
		ActionTypes.COMPANY_NOTIFICATION_CENTER_EDIT_VIEW_UPDATE_SUCCESS
	);
