<section class="tab-content__section" data-tab-section="verify">
  <div class="row">
        <div class="form-field row2">
            <div class="input-label" style="flex-direction: inherit;" ><!--  -->
                <div class="col-md-1">
                    <label>Client Pin:</label>
                </div>
                <div class="col-md-4">
                    <input type="text" (input)="onKeyDown($event)" placeholder="Client Pin" [(ngModel)]="clientPin" [ngClass]="borderColor">
                </div>
            </div>
        </div>
      <div class="see-btn row2">
          <a href="javascript:;" class="btn verify-btn" (click)="verifyPin()">Verify</a>
      </div>
  </div>
</section>
