/* Angular Libraries */ 
import { Component, OnDestroy, OnInit } from '@angular/core';

/* Third Party Libraries */ 
import { Store } from '@ngrx/store';
import { Subject, switchMap, take, takeUntil } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { SideBarService } from '@app/shared/navigation/side-bar/side-bar.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { ImpersonateAlertBarService } from './impersonate-alert-bar.service';

/* Functions */
import { jwtUpdateAction } from '@app/core/store/jwt/jwt.action';

/* Interfaces */
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IJWT } from '@app/core/store/jwt/jwt.interface';

@Component({
  selector: 'impersonate-alert-bar',
  templateUrl: './impersonate-alert-bar.component.html',
  styleUrls: ['./impersonate-alert-bar.component.scss']
})

export class ImpersonateAlertBarComponent implements OnInit, OnDestroy {
  /* Properties */
  private _$unsubscribe: Subject<void> = new Subject<void>();
  
  /* Constructor */
  constructor(
    private _spinner: SpinnerService,
    private _userService: UserService,
    private _store: Store<AppStateInterface>,
    private _localStorageService: LocalStorageService,
    private _sidebarService: SideBarService,
    private _impersonateAlertBarService: ImpersonateAlertBarService
  ) { }

  /* Getters/Setters */
  get getImpersonate() {
    const user = this._userService.user;
    let role, fullName, message;

    if(user?.impersonatingBy){
      role = mapUserRole(user?.systemRoleId!);
      fullName = user?.fullName!;
      message = this._construct(fullName, role, user!.companyName)
    }
    return message;
  }

  /* Methods */
  ngOnInit(): void {
  }

  logoutImpersonate() {
		this._spinner.start();
		this._userService
			.endImpersonate()
			.pipe(
				take(1),
				switchMap((jwt: IJWT) => {
					this._store.dispatch(
						jwtUpdateAction({
							accessToken: jwt.accessToken,
							refreshToken:
								this._localStorageService.getStorageObject('refreshToken'),
						})
					);
					return this._userService.refreshUser();
				}),
				switchMap((_res) => this._sidebarService.getSideBarContents())
			)
			.subscribe({
				next: (res) => {
					this._sidebarService.getContents(res);
					this._spinner.stop();
					this._userService.redirectToDefault();
          this._impersonateAlertBarService.init();
				},
			});
	}

  private _construct(fullName: string, role: string,  company: string) {
    return `${fullName} / ${role} / ${company}`;
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
		this._$unsubscribe.complete();
  }
}

const mapUserRole = (roleId: number) =>{
  if(roleId === 1) return 'Sourcepass Admin';
  if(roleId === 2) return 'Client Admin';
  if(roleId === 3) return 'User';
  else return 'Unknown Role'
}
