import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserService } from '@app/core/services/user.service';
import { KnowledgeBaseService } from '@app/modules/knowledge-base/knowledge-base.service';

import { KbPermissionService } from '@app/shared/services/base-kb.service';
//import { GlobalPermissionsService } from '@app/shared/services/base-application.service'; // altered by above
import {  Observable } from 'rxjs';
//borrowing option from application - need to refactor later on
import { CurrentLevel, GlobalAccess, OptionsInterface, OptionsModel } from '../../functions/options-copy';

@Component({
  selector: 'app-global-kb-topics',
  template: `
    <app-kb-topic-list *ngIf="roleId === 1"  [viewOptions]="options$"></app-kb-topic-list>
    <mat-tab-group
      *ngIf="roleId === 2"
      class="no-header"
      animationDuration="0ms"
      (selectedTabChange)="setIndex($event)"
      [selectedIndex]="kbService.tab"
    >
      <mat-tab label="Global">
        <div>
          <app-kb-topic-list [viewOptions]="options$" [isCustom]="false" [isMainPage]="false"></app-kb-topic-list>
        </div>
      </mat-tab>
      <mat-tab label="Company">
        <div>
          <app-kb-topic-list [viewOptions]="options$" [isCustom]="true" [isMainPage]="false"></app-kb-topic-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  `,
  styles: [''],
  providers:[KbPermissionService]//[GlobalPermissionsService]
})
export class GlobalKnowledgeBaseComponent /* implements OnInit */ {
  roleId: any;
  options$: Observable<OptionsModel | null>;

  constructor(
    public kbService: KnowledgeBaseService,
    private _kbGlobalPermissionsService: KbPermissionService, //GlobalPermissionsService,
    private _userService: UserService){
  }

  ngOnInit(): void {
    this.roleId = this._userService.user?.roleId;
    this.options$ = this._kbGlobalPermissionsService.options$ as Observable<OptionsModel>;
    const data: OptionsInterface = {
      levelId: this.assignLevelId(),
      roleId: this._userService.userRole,
      moduleId: GlobalAccess.KBTopics
    }
    this._kbGlobalPermissionsService.setData(data, 'list');
  }

  assignLevelId(){
    if(this._userService.isSpAdmin){
      return CurrentLevel.GLOBAL
    }else
    if(this._userService.isClientAdmin){
      return CurrentLevel.COMPANY
    }else
    return CurrentLevel.CONTACT
  }

  setIndex(e: MatTabChangeEvent) {
    this.kbService.tab = e.index;
  }
}
