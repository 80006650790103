import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ErrorDirective } from './error.directive';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
  ],
  exports: [
    ErrorDirective,
  ],
  declarations: [
    TooltipComponent,
    ErrorDirective
  ]
})
export class FormTooltipModule { }
