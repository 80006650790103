import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private _notificationsSubject = new BehaviorSubject<Notification[]>([]);
	private _notificationList: Notification[] = [];
	isRefreshNotifying: boolean = false;
	hasErrorMessageFromHttpInterceptor: boolean = false;

	get notifications$(): Observable<Notification[]> {
		return this._notificationsSubject.asObservable();
	}

	/**
	 *@param message - Notification Message. Add \n for line breaks.
	 *@param {NotifOptions} [options] - Properties:
	 * - `duration`: Length of time in seconds before notification hides. Design default is 5. No need to set in most cases. Set to 0 to not auto hide.
	 * - `location`: Screen location - 'left' or 'right'
	 * - `panelClass`: Extra CSS classes to be added to the notification container. Sample Classes: 'success' and 'error'
	 * @param description - Notification Description. Add \n for line breaks.
	 * 
	 * - Do not use notification to confirm success when a create action brings the user to the newly created item. For example, creating a new record from a list view leads to the new record, so a toast is unnecessary.
	 * - Do not show notification after inline edit. Returning to view state is indication enough that the changes were successfully submitted—if the form didn’t save properly, it would still be open (+ error popover & message).
	 */
	notify(
		message: string,
		{
			duration = 5,
			location = 'bottom-right',
			panelClass = 'default',
			hasRefresh = false,
		}: NotifOptions,
		description?: string,
	) {
		const notif: Notification = {
			message,
			description,
			duration,
			location,
			panelClass,
			hasRefresh,
			isShown: false,
			timer: duration
				? setTimeout(() => this.close(notif), duration * 1000)
				: '',
		};
		const showNotif = this.shouldShowNotif(hasRefresh);

		if (showNotif) {
			this._notificationList.push(notif);
			this._notificationsSubject.next(this._notificationList);
			setTimeout(() => {
				this._notificationList.forEach((el) => {
					el.isShown = true;
				});
				this._notificationsSubject.next(this._notificationList);
			}, 50);
		}

		if (hasRefresh) {
			this.isRefreshNotifying = true;
		}
	}

	notifySuccess(message: string, description?: string) {
		this.notify(message, {panelClass: 'success'}, description);
	}

	notifyError(message: string, description?: string) {
		this.notify(message, {panelClass: 'error'}, description);
	}

	shouldShowNotif(hasRefresh: boolean) {
		if (this.isRefreshNotifying && hasRefresh) return false;
		return true;
	}

	close(notif: Notification) {
		const i = this._notificationList.indexOf(notif);
		if (i != -1) {
			this._notificationList.splice(i, 1);
			this._notificationsSubject.next(this._notificationList);
		}
		if(notif.hasRefresh) this.isRefreshNotifying = false;
	}
}

export type Notification = {
	message: string;
	description?: string;
	duration: number;
	location: string;
	panelClass: NotifType;
	timer: Object;
	isShown: boolean;
	hasRefresh: boolean;
};

export type NotifOptions = {
	duration?: number;
	location?: string;
	panelClass?: NotifType;
	hasRefresh?: boolean;
};

export type NotifType = 'error' | 'success' | 'default';
