export enum TransactionType {
  invoice = 'CustInvc',
  creditMemo = 'CustCred',
  payment = 'CustPymt'
}

export enum TransactionTypeName {
  invoice = 'Invoice',
  creditMemo = 'Credit Memo',
  payment = 'Payment'
}

export enum DefaultCreditAmount {
  creditAmount = '0.00'
}

export enum TransactionStatus {
  canceled = 'CANCELED',
  outstanding = 'OUTSTANDING',
  paid = 'PAID',
  paymentProcessing = 'PAYMENT PROCESSING',
  rejected = 'REJECTED',
  undefined = 'UNDEFINED',
  voided = 'VOIDED'
}

export enum PaymentNote {
  cardNote = 'It may be a few minutes to reflect successful payments within the Quest platform',
  bankNote = 'It may be 1-2 days to reflect successful bank account payments in your Quest platform.  Please be cautious to not make double payments during that wait time.'
}

export enum MessageStatus {
  succeeded = 'succeeded',
  requiresAction = 'requires_action',
  canceled = 'canceled',
  requiresPaymentMethod = 'requires_payment_method'
}

export enum PaymentMethodType {
  card = 'Card',
  bankAccount = 'Bank Account'
}