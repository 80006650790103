import { SortDirection } from "@angular/material/sort";

export type Invoice = {
    id: number,
    invoiceNumber: number,
    description: string,
    billAmount: number,
    balance: number,
    invoiceDate: Date,
    dueDate: Date,
    status: string,
    orderId: number,
    type: string,
}

export interface InvoiceParameters {
    page: number;
    pageSize: number;
    sort?: string;
    column?: string;
    order?: SortDirection;
    query?: string;
    userRole?: number;
    statusIds?: number[] | null;
    startDate: string;
    endDate: string;
    dueStartDate?: string;
    dueEndDate?: string;
    companyId?: number | null;
    haveBalance: boolean;
    typeIds?: number[] | null;
    isAutopay?: boolean | null;
    transactionType?: string | null;
    companyIds?: number | null;
}

export interface FinanceParameters extends InvoiceParameters {
  isPrinted?: boolean | null;
  isEmailSent?: boolean | null;
  deliveryMethodIds?: number[] | null;
}

export interface InvoiceWidgets {
  outstandingBalance: number;
  billingContacts: BillingContacts[];
  // lastTransaction: any[];
  unappliedCredits: number;
  unappliedPayments: number;
}

export interface PaymentMethod {
  bankAccount: any[];
  card: any[];
}

export interface BillingContacts {
  name: string;
  email: string;
  phone: string;
  directExt: string;
  title: string;
  imagePath: string;
}

export interface IsPrintedParams{
  id: number;
  isPrinted: boolean;
}
