import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';

import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';

import { PaginationConstants, TableMessages, NotificationMessages} from '@app/shared/constants';
import { Global, Pages } from '@app/shared/constants';

import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';

import { SpinnerService } from '@app/core/services/spinner.service';
import { GlobalSettingsService } from '../../global-settings.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { GlobalSettingsType } from './global-setting.constant';
import { DataModalService } from '@app/core/data-modal/data-modal.service';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSettingsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  TODO: "DELETE COMPONENT"
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild('search') search: ElementRef;

  displayedColumns: string[] = ['name', 'category', 'action'];
  dataSource: MatTableDataSource<any>;
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;
  order = 'asc';
  column = 'name';
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  searchTimeout: any;

  moduleId: GlobalSettingsType;

  constructor(
    public spinner: SpinnerService,
    private _globalSettings: GlobalSettingsService,
    private _cd: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _dataModalService: DataModalService,
    private _breadcrumbService: BreadcrumbService
  ) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._activatedRoute.params.subscribe((param: any) => {
      this.moduleId = GlobalSettingsType[
        param.module
      ] as unknown as GlobalSettingsType;
      this._breadcrumbService.updateGlobalSettingsType(this.moduleId);
    });
  }

  get headerName(): string {
    return `Global ${this._getPageText('UI')}`;
  }

  get btnName(): string {
    return `Manage Global ${this._getPageText('UI')}`;
  }

  get tooltipName(): string {
    return `Remove ${this._getPageText('UI')}`;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.fetchNewData();
  }

  ngOnDestroy() {
    this.spinner.stop();
  }

  fetchNewData() {
    this.spinner.start();
    this._globalSettings
      .getGlobalList(
        this.moduleId,
        this.paginator.page,
        this.paginator.size,
        this.column,
        this.order,
        this.search.nativeElement.value
      )
      .subscribe((resp: any) => {
        clearTimeout(this.searchTimeout);
        const data = resp.data;
        this.dataSource = new MatTableDataSource(data);
        this.totalItems = resp.totalCount;
        this.spinner.stop();
        this._cd.markForCheck();
      });
  }

  onSearch() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.fetchNewData();
    }, 1000);
  }

  onDelete(resp: any) {
    const data = this._dataModalService.getDeleteModel(
      'Global Settings',
      resp.name.trim()
    );

    this._dataModalService.showModal(data).subscribe({
      next: (result) => {
        if (result) {
          const payload = {
            permissionModule: this.moduleId,
            ids: [resp.id],
          };

          this._globalSettings.deleteData(payload).subscribe({
            next: () => {
              this.fetchNewData();
            },
            error: () => {
              this.spinner.stop();
            },
          });
        }
      },
    });
  }

  sortData(e: HeaderSort) {
    this.order = e.direction.toUpperCase();
    this.column = e.active;
    this.fetchNewData();
  }

  onManagePage() {
    this._router.navigate([`edit`], {
      relativeTo: this._activatedRoute,
    });
  }

  private _getPageText(flag?: string): string {
    let label = '';

    switch (Number(this.moduleId)) {
      case Global.APPLICATIONS:
        label = NotificationMessages.ApplicationSuccessDeleted;

        if (flag === 'UI') {
          label = Pages.APPLICATIONS;
        }
        break;

      case Global.KNOWLEDGE_BASE:
        label = NotificationMessages.KnowledgeBaseSuccessDeleted;

        if (flag === 'UI') {
          label = Pages.KNOWLEDGE_BASE;
        }
        break;

      case Global.SERVICE_AND_REQUEST:
        label = NotificationMessages.ServiceAndRequestDeleted;

        if (flag === 'UI') {
          label = Pages.SERVICE_AND_REQUEST;
        }
        break;

      default:
        break;
    }

    return label;
  }
}
