<mat-form-field>
	<mat-datepicker-toggle
		class="position-absolute"
		matIconSuffix
		[for]="picker"
	></mat-datepicker-toggle>
	<mat-date-range-input (click)="picker.open()" [rangePicker]="picker">
		<input
			#startDate
			class="start-date-range"
			autocomplete="off"
			[placeholder]="'MM/DD/YYYY'"
			matStartDate
			typing-not-allowed
		/>
		<input
			class="end-date-range"
			autocomplete="off"
			[placeholder]="'MM/DD/YYYY'"
			matEndDate
			typing-not-allowed
		/>
	</mat-date-range-input>
	<mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
