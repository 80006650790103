<div
	class="tablesaw-bar tablesaw-mode-swipe"
	*ngIf="!(hiddenLeftColumns.length == 0 && hiddenRightColumns.length == 0)"
>
	<div class="tablesaw-advance">
		<a
			(click)="toggleLeft()"
			class="btn tablesaw-btn btn-micro left left-btn"
			[class.disabled]="!isLeft"
		>
			<app-figma-icon-img
				[iconName]="'arrow-square-left'"
				[isForButton]="true"
        [hasSpaceOnRight]="false"
			></app-figma-icon-img>
		</a>
		<!-- disabled to disable -->
		<a
			(click)="toggleRight()"
			class="btn tablesaw-btn btn-micro right right-btn"
			[class.disabled]="!isRight"
		>
			<app-figma-icon-img
				[iconName]="'arrow-square-right'"
				[isForButton]="true"
        [hasSpaceOnRight]="false"
			></app-figma-icon-img>
		</a>
	</div>
</div>
