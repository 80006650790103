<div class="modal-body">
  <div class="ErrorModule-content">
    <h4>Enable MFA for all Users</h4>
    <p class="font-size-15">{{data.message}}</p>
    <p class="font-size-15">To request a more accurate quote for your recommendation, click the "Send Request" Button</p>
    <div class="gray-card">
      <div class="inline-container">
        <p><span class="mr">Recommendation:</span> Enable MFA for all users</p>
      </div>
      <div class="inline-container flex-item">
        <p class="mr">Status: </p><quest-status [status]="'To Address'"></quest-status>
      </div>
      <div class="inline-container">
        <p><span class="mr">Estimated Time:</span> 3 Months</p>
      </div>
      <div class="inline-container">
        <p><span class="mr">Estimated Cost:</span> $1,000/year</p>
      </div>

      <div class="divider"></div>
      <div class="inline-container">
        <p><span class="mr">Current Security Score:</span> 75 - Fair</p>
      </div>
      <div class="inline-container">
        <p><span class="mr">Points Added:</span> +10</p>
      </div>

      <div class="inline-container">
        <p class="note"> <span class="mr-1">Your new score would be 85 - Good</span> <img [src]="'/assets/icons/increase.png'" class="time" alt=""></p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer no-top-border q-g-16" [class.flex-start]="!data.options && data.altMessage">
   <app-tertiary-button
    *ngIf="data.options && data.options.noText"
    [btnText]="'Back'"
    (click)="closeModal(false)"
  >
  </app-tertiary-button>

  <button
    class="imp-disabled-op"
    *ngIf="data.options && data.options.yesText"
    type="button"
    [class.btn-2-primary]="
      data.type == 'success' ||
      data.type == 'info' ||
      data.type == 'warn'
    "
    [class.btn-2-destructive]="data.type == 'danger'"
    class="btn-2"
    (click)="closeModal(true)"
  >
    Send Request
  </button>
</div>
