<main class="main-content  mt-0">
  <div class="page-header min-vh-100" style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/error-500.jpg');">
    <span class="mask bg-gradient-primary opacity-4"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7 mx-auto text-center">
          <h1 class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5">Error 404</h1>
          <h2 class="fadeIn3 fadeInBottom mt-3 text-white">Page not Found</h2>
          <p class="lead fadeIn2 fadeInBottom text-white">The page you are looking for is not found.</p>
          <a (click)="onNavigate()" type="button" class="btn bg-gradient-primary mt-4 fadeIn2 fadeInBottom">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</main>
