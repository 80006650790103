import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthlyActivityGraphComponent } from './monthly-activity-graph.component';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { MonthlyActivityGraphService } from './monthly-activity-graph.service';


@NgModule({
  imports: [
    CommonModule,
    DxChartModule,
  ],
  declarations: [MonthlyActivityGraphComponent],
  exports: [MonthlyActivityGraphComponent],
  providers: [MonthlyActivityGraphService]
})
export class MonthlyActivityGraphModule { }
