import { Component, OnInit } from "@angular/core";
import { UserService } from "@app/core/services/user.service";
//import { GlobalPermissionsService } from "@app/shared/services/base-application.service";
import { KbPermissionService } from '@app/shared/services/base-kb.service';
import { Observable } from "rxjs";
import { CurrentLevel, GlobalAccess, OptionsAddEditModeModel, OptionsInterface, OptionsModel } from '../../functions/options-copy';


@Component({
  selector: 'app-global-add-edit-topic',
  template: `<app-kb-topic-tab></app-kb-topic-tab>`, // [viewOptions]="options$"
  styles: [''],
  providers:[KbPermissionService]
})

export class GlobalKBAddEditComponent implements OnInit {
  optionsAddEdit$: Observable<OptionsModel | OptionsAddEditModeModel | null>;

  constructor(private _globalPermissionService: KbPermissionService,
    private _userService: UserService){

  }

  ngOnInit(){
    this.optionsAddEdit$ = this._globalPermissionService.options$;
    const data: OptionsInterface = {
      levelId: this.assignLevelId(),
      roleId: this._userService.userRole,
      moduleId: GlobalAccess.Application
    }
    this._globalPermissionService.setData(data, 'add/edit');
  }

  assignLevelId(){
    if(this._userService.isSpAdmin){
      return CurrentLevel.GLOBAL
    }else
    if(this._userService.isClientAdmin){
      return CurrentLevel.COMPANY
    }else
    return CurrentLevel.CONTACT
  }
}
