import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { TableMessages } from '@app/shared/constants';
import { KBWidget } from './../../../../../../shared/interfaces/knowledge-base.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { DashboardService } from './../../../../dashboard.service';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { KnowledgeBaseService } from '@app/modules/company/pages/knowledge-base/knowledge-base.service';
import { CategoryList } from '@app/shared/interfaces/knowledge-base.interface';
import { Observable, filter, delay, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-kb-widget',
  templateUrl: './kb-widget.component.html',
  styleUrls: ['./kb-widget.component.scss']
})
export class KbWidgetComponent implements OnInit, AfterViewInit {
  kbWidget$: Observable<KBWidget[] | null>
  kbData: KBWidget[] = [];
  user = this._userService.user;
  categoryList: CategoryList[];
  categoryId: number;
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  displayedColumns = [
    'categoryName',
    'title',
  ]
  isStillLoading = true;
  subs = new Subscription()

  constructor(
    private _spinner: SpinnerService,
    private _dashboardService: DashboardService,
    private _cd: ChangeDetectorRef,
    private _sessionStorage: SessionStorageService,
    private _kbService: KnowledgeBaseService,
    private _userService: UserService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _toastMessageService: ToastMessageService,
    )
  {
    _dashboardService.initKBWidget();
    this.kbWidget$ = this._dashboardService.kbwidget$
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getKbTopics();
  }
  getKbTopics() {
    this._spinner.start();
    this.kbWidget$
      .pipe(
        delay(500),
        tap(v=>{
          this.isStillLoading = false;
          this._cd.detectChanges();
        })
      )
      .subscribe({
        next: (data: KBWidget[] | null) => {
          this.kbData = data as KBWidget[];
          this._spinner.stop();
          this._cd.markForCheck();
        }, error: (error) => {
          this._spinner.stop();
          this._toastMessageService.showErrorMessage(error);
        }
      })
  }
  goToTopic(id: number, categoryId: number, topicName: string, categoryName: string) {
    this.categoryId = categoryId;
    this._sessionStorage.setStorage('topicName', topicName);
    this._sessionStorage.setStorage('categoryName', categoryName);
    this._router.navigate(['../company/knowledge-base/category/topic'], {
      relativeTo: this._activatedRoute,
      state: {
        topicId: id,
        categoryId: this.categoryId,
      },
      queryParams: {
        topicId: id,
        categoryId: this.categoryId,
      },
    });
  }
  getCategoryList() {
    if (this.user?.roleId === 2 || this.user?.roleId === 3) {
      this._kbService
        .getUserCategory(this.user.id, this.user.companyId)
        .subscribe((category: any) => {
          this.categoryList = category.data;
        });
    }
  }
  getTopicCatName(id: number) {
    return this.categoryList.length !== 0 ? this.categoryList[
      this.categoryList.findIndex((x) => x.id === id)]
      .name
      : '';
  }
}

