import { PaginationConstants } from "@app/shared/constants/pagination-options.constants";
import { createReducer, on } from "@ngrx/store";
import { ticketsUpdateAction, ticketsUpdateSuccessAction } from "./tickets.actions";
import { ITickets } from "./tickets.interface";

export const initialStateITickets: ITickets = {
  page: 1,
  pageSize: PaginationConstants.pageSize,
  order: 'desc',
  column: 'lastUpdated',
  query: '',
  statusIds: [],
  priorityIds: [],
  totalItems: 0,
  viewMyTicket: false
}

export const ticketsReducer =
createReducer(
  initialStateITickets,
  on(ticketsUpdateAction, (state, action): ITickets =>({
    ...state,
    ...action
  })),
  on(ticketsUpdateSuccessAction, (state): ITickets => ({
    ...state
  }))
)
