import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';

import {
	BehaviorSubject,
	filter,
	distinctUntilChanged,
	of,
	map,
	catchError,
	retry,
	tap,
} from 'rxjs';

import { UserService } from './user.service';
import { NotificationsClienthubService } from '@app/shared/services/notifications-clienthub.service';

import {
	MaintenanceAlert,
	MaintenanceAlertDisplay,
	MaintenanceEvent,
	MaintenanceMenu,
} from '@app/modules/system/interfaces/maintenance.interface';
import { NameId } from '@app/shared/interfaces/support.interface';

import { UserTypes } from '@app/shared/constants';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MaintenanceService {
	private _isUndeMaintenanceSubject = new BehaviorSubject<MaintenanceEvent>({
		isInMaintenance: false,
	});
	private _maintenanceBannerSubject =
		new BehaviorSubject<MaintenanceAlertDisplay | null>(null);
	isUndeMaintenance$ = this._isUndeMaintenanceSubject.asObservable();
	maintenanceBanner$ = this._maintenanceBannerSubject.asObservable();

	private _appInMaintenance: boolean;
	private _menuId: number;
	private _parentMenuId?: number;
	private _undeMaintenanceMenu: number[] = [];
	private _parentMenuList = [23, 336, 30, 358]; // Companies, System, Reports, Template Styles
	private _excludeMenu = [351]; // Site Maintenance
	private _currentEvent: ActivationEnd;

	constructor(
		private _user: UserService,
		private _http: HttpClient,
		private _router: Router,
		private _notification: NotificationsClienthubService
	) {
		this._router.events
			.pipe(
				distinctUntilChanged(),
				filter((e) => e instanceof ActivationEnd),
				tap((e) => {
					if (
						this._user.isProdDev &&
						this._isUndeMaintenanceSubject.value.isInMaintenance
					)
						this._setMaintenace(-1);
				}),
				filter((e) => !this._user.isProdDev) // Check maintenance if user is not product dev
			)
			.subscribe((e) => this._checkPageMaintenance(e as ActivationEnd));

		this._notification.maintenanceNotificationReceived.subscribe((res) =>
			this._updateMaintenanceList(res)
		);

		this._notification.maintenanceBannerNotificationReceived
			.pipe(
				map((res) => (this._user.isAuthenticated ? res : null)),
				filter((res) => res != this._maintenanceBannerSubject.value)
			)
			.subscribe((res) => this._maintenanceBannerSubject.next(res));
	}

	get inMaintenance() {
		return this._appInMaintenance;
	}

	get parentMenu() {
		return this._parentMenuList.slice();
	}

	isAppInMaintenance() {
		if (this._appInMaintenance == undefined) {
			return this.getMenuMaintenance().pipe(
				retry(1),
				map((res) => {
					this._appInMaintenance = res.includes(0);
					this._undeMaintenanceMenu = res.filter(
						(m) => !this._excludeMenu.includes(m)
					);
					return this._user.isProdDev ? false : this._appInMaintenance;
				}),
				catchError((err) => {
					this._appInMaintenance = false;
					return of(false);
				})
			);
		} else return of(this._user.isProdDev ? false : this._appInMaintenance);
	}

	getMenuList() {
		return this._http.get<MaintenanceMenu[]>(
			`${environment.apiBaseUrl}Maintenance/GetList`
		);
	}

	getMenuMaintenance() {
		return this._http.get<number[]>(
			`${environment.apiBaseUrl}Maintenance/GetMenuMaintenance`
		);
	}

	updateMenuStatus(data: MaintenanceMenu[]) {
		return this._http.post(
			`${environment.apiBaseUrl}Maintenance/UpdateStatus`,
			data
		);
	}

	getAlerts() {
		return this._http.get<MaintenanceAlert[]>(
			`${environment.apiBaseUrl}Maintenance/GetAlerts`
		);
	}

	getAlertTypes() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}Maintenance/GetAlertTypes`
		);
	}

	addUpdateAlert(data: MaintenanceAlert) {
		return this._http.post<MaintenanceAlert>(
			`${environment.apiBaseUrl}Maintenance/AddOrUpdateAlert`,
			data
		);
	}

	deleteAlert(id: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}Maintenance/DeleteAlert/${id}`
		);
	}

	private _checkPageMaintenance(event: ActivationEnd) {
		this._currentEvent = event;
		let snapshot: ActivatedRouteSnapshot | null =
				event.snapshot.parent || event.snapshot,
			menuId = 0,
			parentId = 0,
			pathId = -1;

		while (snapshot) {
			const ids = this._getIds(snapshot);
			if (pathId != ids.pathId) {
				if (ids.pathId == 0) parentId = ids.menuId;
				if (ids.menuId) menuId = ids.menuId;
				pathId = ids.pathId;
			}
			snapshot = ids.child;
		}
		parentId = menuId == parentId ? 0 : parentId;

		this._setMaintenace(menuId, parentId || undefined);
	}

	private _setMaintenace(menuId: number, parentId?: number) {
		if (this._menuId == menuId && this._parentMenuId == parentId) return;

		this._menuId = menuId;
		this._parentMenuId = parentId;

		if (this._undeMaintenanceMenu.includes(this._parentMenuId!))
			this._isUndeMaintenanceSubject.next({ isInMaintenance: true });
		else if (this._undeMaintenanceMenu.includes(this._menuId))
			this._isUndeMaintenanceSubject.next({
				isInMaintenance: true,
				parentId: this._parentMenuList.includes(this._parentMenuId!)
					? this._parentMenuId
					: undefined,
			});
		else this._isUndeMaintenanceSubject.next({ isInMaintenance: false });
	}

	private _updateMaintenanceList(list: number[]) {
		const inMaintenance = list.includes(0);

		if (this._appInMaintenance != inMaintenance) {
			this._appInMaintenance = inMaintenance;

			if (this._user.isProdDev || !this._user.isAuthenticated) return;
			else if (this._appInMaintenance) this._navToMaintenance();
			else document.location.reload();
		}

		this._undeMaintenanceMenu = list.filter(
			(m) => !this._excludeMenu.includes(m)
		);
		if (!this._currentEvent) return;
		this._menuId = 0;
		this._parentMenuId = undefined;
		this._checkPageMaintenance(this._currentEvent);
	}

	private _getIds(snapshot: ActivatedRouteSnapshot) {
		const id = snapshot.data['id'];
		let menuId;
		if (id)
			menuId = this._user.isUser
				? id[UserTypes.User]
				: id[UserTypes.SourcepassAdmin] ?? id[UserTypes.ClientAdmin];

		return {
			menuId: menuId,
			pathId: (snapshot as any)['_lastPathIndex'],
			child: snapshot.firstChild,
		};
	}

	private _navToMaintenance() {
		this._router.navigate(['/quest_under_maintenance'], {
			skipLocationChange: true,
		});
	}
}
