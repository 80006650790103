import { CheckboxService } from './checkbox.service';
import { ShiftClickDirective } from './shift-click.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [ShiftClickDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [ShiftClickDirective],
  providers: [CheckboxService]
})
export class MultipleSelectionModule { }
