import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OptimizeQuestModalService {
	isShowOptimizeQuestModal$: Observable<boolean> = of(false);

	constructor() {}

	get isMobileOrTabletDevice() {
		const win = window as any;
		const match = win.matchMedia || win['msMatchMedia'];

		if (match) {
			const mq = match('(pointer:coarse)');
			return mq.matches;
		}

		return false;
	}

	get isAnroid() {
		return navigator.userAgent.toLowerCase().indexOf('android') > -1;
	}

	get isIOS() {
		return /iPad|iPhone|iPod/.test(navigator.userAgent);
	}

	init() {
		this._deviceCheck();
	}

	closeModal() {
		this._setModalVisibility(false);
	}

	openApp() {
		if (this.isMobileOrTabletDevice) {
			if (this.isIOS) {
				window.location.replace(environment.iosAppLink);
			} else {
				window.location.replace(environment.androidAppLink);
			}
		}
	}

  private _deviceCheck() {
    this.isMobileOrTabletDevice ? this._setModalVisibility(true) : this._setModalVisibility(false);
  }

	private _setModalVisibility(isShow: boolean) {
		this.isShowOptimizeQuestModal$ = of(isShow);
	}
}
