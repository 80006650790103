import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, inject, EventEmitter, Output } from '@angular/core';
import { IDropdown } from '@app/shared/constants';
import { environment } from 'environments/environment';
import { Observable, map, of } from 'rxjs';
import { SelectInputAsyncComponent, ValueLabel } from '../select-input/select-input-async/select-input-async.component';
import { FormControl } from '@angular/forms';
import { FinanceService } from '@app/modules/finance/finance.service';

@Component({
  selector: 'company-dropdown',
  templateUrl: './company-dropdown.component.html',
  styleUrls: ['./company-dropdown.component.scss']
})
export class CompanyDropdownComponent implements OnInit {
  // injection
  private _http = inject(HttpClient)

  // Input / Output
  @Input() hasClear = true;
  @Input() showInactive: boolean = false;
  @Input() inputClass = 'form-control';
  @Input() placeholder: string = 'Select company';
  @Output() onBlurForm: EventEmitter<void> = new EventEmitter<void>();

  // ViewChild
  @ViewChild(SelectInputAsyncComponent) selectInputAsync: FormControl = new FormControl();
  @ViewChild(SelectInputAsyncComponent) selectInputAsyncComponent: SelectInputAsyncComponent;

  // props
  companies$: Observable<ValueLabel[]> = of([])
  selectAsync = new FormControl();

  constructor(private _financeService: FinanceService) { }

  ngOnInit() {
  }

  searchCompany(search:string){
    if(!search) {this.companies$ = of([]); return;}

    this.companies$ = this._getCompanyDropdown(search)
      .pipe(
        map(v=>{
          let data:any[] = [];
          v.map(x=>{
            data.push({
              value: x.id,
              label: x.name
            })
          })
          return data as ValueLabel[];
        })
      )
  }

  onBlur() {
    this.onBlurForm.emit();
  }

  clickForm() {
		this.selectInputAsyncComponent.clickForm();
	}

  closeSelection() {
		this.selectInputAsyncComponent.closeSelection();
	}

  private _getCompanyDropdown(search:string=''): Observable<IDropdown[]> {
    const param: any = this._financeService.getValidParameters({ search: search, showInactive: this.showInactive });

		return this._http.get<IDropdown[]>(
			`${environment.apiBaseUrl}companies/GetDropdown`, { params: param }
		);
	}

}
