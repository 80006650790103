/* Angular Libraries */
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';

/* Interfaces */
import { StripePublishable } from '@app/shared/interfaces/invoice.interface';
import { NotificationMessages, UserTypes } from '@app/shared/constants';
import { BankValidationMessages } from '../../validation-messages/invoice-validation-messages.inum';

declare let Stripe: any;

@Component({
	selector: 'app-add-us-bank-account',
	templateUrl: './add-us-bank-account.component.html',
	styleUrls: ['./add-us-bank-account.component.scss'],
})
export class AddUsBankAccountComponent implements OnInit {
	/* Properties */
	bankForm: FormGroup;
	bankValidationMessages = BankValidationMessages;
	isAccept: boolean = false;
	accntHolderTypeOpt = [
		{
			val: 'individual',
			label: 'Individual'
		},
		{
			val: 'company',
			label: 'Company'
		}
	];

	/* Private Properties */
	private _$unsubscribe: Subject<void> = new Subject<void>();
	private _stripe: any;

	/* Constructor */
	constructor(
		private fb: FormBuilder,
		private _stripeService: StripeService,
		private _location: Location,
		private _toastMessageService: ToastMessageService,
		private _spinner: SpinnerService,
		private _notifier: NotificationService
	) {}

	/* Methods */
	ngOnInit(): void {
		this.initStripePublishableKey();
		this.initForm();
	}

	initStripePublishableKey() {
		this._stripeService.getStripePublishableKey().subscribe({
			next: (result: StripePublishable) =>
				(this._stripe = Stripe(result.publishableKey)),
		});
	}

	initForm() {
		this.bankForm = this.fb.group({
			accountHolderType: ['individual', Validators.required],
			accountHolderName: ['', Validators.required],
			routingNumber: ['', Validators.required],
			accountNumber: [
				'', 
				[
					Validators.required,
					(control: AbstractControl) => {
						if (control.value && (control.value === this.bankForm?.controls['confirmAccountNumber'].value)) {
							this.bankForm?.controls['confirmAccountNumber'].markAsUntouched();
							this.bankForm?.controls['confirmAccountNumber'].setErrors({ notMatched: false });
							return null;

						} else {
							if (this.bankForm?.controls['confirmAccountNumber'].touched) {
								this.bankForm?.controls['confirmAccountNumber'].markAsTouched();
								this.bankForm?.controls['confirmAccountNumber'].setErrors({ notMatched: true });
							}
							
							return
						}
					}
				]
			],
			confirmAccountNumber: [
				'', 
				[
					Validators.required,
					(control: AbstractControl) => {
						if (control.value === this.bankForm?.controls['accountNumber'].value) {
							return null;
						} else {
							return { notMatched: true };
						}
					}
				]
			],
			email: [''],
		});
	}

	back() {
		this._location.back();
	}

	onEmitAcceptUpdate(isAccept: boolean) {
		this.isAccept = isAccept;
	}

	async onClickSubmit() {
		const params = {
			country: 'US',
			currency: 'usd',
			routing_number: this.bankForm.controls['routingNumber'].value,
			account_number: this.bankForm.controls['accountNumber'].value,
			account_holder_name: this.bankForm.controls['accountHolderName'].value,
			account_holder_type: this.bankForm.controls['accountHolderType'].value,
		};

		this.bankForm.markAllAsTouched();

		if (this.bankForm.controls['accountHolderName'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['routingNumber'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['accountNumber'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.bankForm.controls['confirmAccountNumber'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (
			this.bankForm.controls['accountNumber'].value !==
			this.bankForm.controls['confirmAccountNumber'].value
		) {
			this._notifier.notifyError(NotificationMessages.Review, 'Confirm both account numbers match');
			return;
		}

		const { token, error } = await this._stripe.createToken('bank_account', params);

		if (error && ((error.code === 'bank_account_auto_verification_failed') || (error.code === 'account_number_invalid'))) {
			this._notifier.notifyError(NotificationMessages.Review, 'The bank account failed validation. Please check the account and routing numbers provided.');
			return;
		}

		// if (error && error.type === 'invalid_request_error') {
		// 	this._notifier.notifyError(NotificationMessages.Review, error.message);
		// 	return;
		// }

		this._spinner.start();

		if (!token) {
			this._spinner.stop();
			this._toastMessageService.showErrorMessage(error.message);
			return;
		}

		if (token) {
			this._stripeService
				.addStripeBanks(
					{
						token: token.id,
						email: this.bankForm.controls['email'].value
							? this.bankForm.controls['email'].value
							: null,
					}
				)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (result) => {
						this._spinner.stop();
						this._notifier.notifySuccess(NotificationMessages.save('Bank Account'), 'You can use this account for future transactions');
						this._location.back();
					},
					error: (err) => {
						this._spinner.stop();
						this._toastMessageService.showErrorMessage(err.errors);
					},
				});
		}
	}

	returnAsNumber(event: any) {
		return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
