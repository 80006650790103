import { Component, Input, OnInit, inject } from '@angular/core';
import { ApplicationType, NotificationMessages } from '@app/shared/constants';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { ExportService } from './export.service';
import { PortalExportList } from '../../core';

@Component({
  selector: 'export-report',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  // injection
  private _toastMessageService = inject(ToastMessageService);
  private _export = inject(ExportService);

  // Input 
  @Input() totalItems = 0;
  @Input() listItem: PortalExportList = 'company';

  // props
  applicationType = ApplicationType;

  // lifecycle
  ngOnInit() {
  }

  // public method
  export(exportType: ApplicationType) {
    if (this.totalItems === 0) {
      this._toastMessageService.showErrorMessage(
        NotificationMessages.NoRecordFound
      );
    } else {
      //this._export.exportUserLoginReport(exportType, this.listItem);
      this._export.exportList(this.listItem, exportType)
    }
  }
}
