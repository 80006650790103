import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { GroupsAndPermissionsService } from '@app/modules/groups-and-permissions/groups-and-permissions.service';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';

import { PaginationConstants, companyListProps, TableMessages } from '@app/shared/constants';
import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
  skip,
  Subscription,
  tap,
} from 'rxjs';

@Component({
  selector: 'company-permissions-table',
  templateUrl: './company-permissions-table.component.html',
  styleUrls: ['./company-permissions-table.component.scss'],
})
export class ApplicationTableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  // paginator
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @Input() isActionButtonsDisabled: boolean = false;
  @Input() isMainPage = true;

  @Output() sendCompanyId = new EventEmitter<number>();
  @Output() sendhasUserLevelPermission = new EventEmitter<boolean>();
  @Output() sendDeleteId = new EventEmitter<number>();
  @Output() sendProps = new EventEmitter<companyListProps>();

  order = 'asc';
  column = 'name';

  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;

  @Input() tableData: any;
  // search
  @ViewChild('search') search: ElementRef;

  // table
  displayedColumns = ['name', 'usersWithPerm', 'usersCount', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  hasData: boolean = false;
  message = {
    noRecord: TableMessages.EmptyTable,
  };

  searchFilterSubject: BehaviorSubject<string> = new BehaviorSubject('');
  searchSub: Subscription;

  searchTimeout: any;

  constructor(
    public spinner: SpinnerService,
    public _groupsService: GroupsAndPermissionsService,
    private _dataModalService: DataModalService  ) {}

  ngOnInit(): void {
    this.searchSub = this.searchObs().subscribe();
  }

  searchObs(): Observable<any> {
    return this.searchFilterSubject.pipe(
      skip(1),
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.setupAndSendProps())
    );
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.setupAndSendProps();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['tableData'] &&
      changes['tableData'].currentValue != undefined
    ) {
      this.dataSource = new MatTableDataSource(this.tableData.data);
      this.hasData = !!this.tableData.data.length;
      this.totalItems = this.tableData.totalCount;
    }
  }

  editCompanyPermission(id: number, hasUserLevelPermission: boolean) {
    this.sendCompanyId.emit(id);
    this.sendhasUserLevelPermission.emit(hasUserLevelPermission);
  }

  onRemoveCompany(id: number, name: string) {
    const data = this._dataModalService.getDeleteModel(
      'Company Permission',
      name.trim()
    );

    this._dataModalService.showModal(data).subscribe({
      next: (result) => {
        if (result) {
          this.sendDeleteId.emit(id);
        }
      },
    });
  }

  setupAndSendProps(flag = 'page', pageOrSize?: number) {
    if (flag === 'page') {
      this.paginator.page = pageOrSize || 1;
    } else if (flag === 'size') {
      this.paginator.size = pageOrSize!;
      this.paginator.setTotalPages();
      this.paginator.setPages();
    }

    const data: companyListProps = {
      search: this.search.nativeElement.value,
      page: this.paginator.page,
      pageSize: this.paginator.size,
      column: this.column,
      order: this.order,
    };
    this.sendProps.emit(data);

    setTimeout(() => {
      clearTimeout(this.searchTimeout);
    }, 200);
  }

  onSearch() {
    // this.searchFilterSubject.next(keyword)
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setupAndSendProps();
    }, 1000);
  }

  sortData(e: HeaderSort) {
    this.order = e.direction.toUpperCase();
    this.column = e.active;
    this.setupAndSendProps();
  }

  checkUserAccess(data: any) {
    if (data.hasUserLevelPermission) {
      return data.userPermissionCount;
    } else {
      return data.userCount;
    }
  }
}
