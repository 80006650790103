/* Angular Libraries */
import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

/* Third Party Libraries */
import { Subject, takeUntil, tap } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { QuickFilterService } from '@app/shared/components/quick-filter/quick-filter.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { FinanceService } from '../../finance.service';
import { InvoiceService } from '../invoices/store/invoice.service';

/* Components */
import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

/* Interfaces */
import {
	AccountActivityStatusDropdown,
	IAccountActivity,
} from '@app/shared/interfaces/invoice.interface';
import {
	NotificationMessages,
	PaginationConstants,
} from '@app/shared/constants/global-constants';
import { ApplicationType, FilterUrl } from '@app/shared/constants/global-enum';
import { isValueEmpty } from '@app/shared/utilities/helper';
import { IQuickFilter } from '@app/shared/new-table-shared/new-table-shared.interface';

@Component({
	selector: 'account-activity',
	templateUrl: './account-activity.component.html',
	styleUrls: ['./account-activity.component.scss'],
})
export class AccountActivityComponent implements OnInit, OnDestroy {
	/* ViewChild */
	@ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;
	@ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
	@ViewChild('searchTextbox') searchTextbox: ElementRef;

	/* Properties */
	queryString: FormControl = new FormControl<string>('');
	selectedSortColumn: string = 'activityDate';
	selectedSortType: string = 'desc';
	selectedPageSize: number = 10;
	selectedPage: number = 1;
	pageSizes = PaginationConstants.pageSizes;
	qFilterUrl = FilterUrl;
	searchTimeout: any;
	applicationType = ApplicationType;
	statusOption: AccountActivityStatusDropdown[] = [];
	resetVal = false;
	quickFilterOptions: any = [];
	isFilterHaveBeenCalled: boolean = false;
	quickFilter: IQuickFilter;
	searchFilters: IAccountActivity;
	totalItems: number;

	/* [Start]::Filter Forms */
	startDateFilter: FormControl<string | null> = new FormControl<string | null>('');
	endDateFilter: FormControl<string | null> = new FormControl<string | null>('');
	statusFilter: FormControl = new FormControl([]);
	/* [End]::Filter Forms */

	private _$unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor(
		private _financeService: FinanceService,
		private _spinner: SpinnerService,
		private _toastMessageService: ToastMessageService,
		private _quickFilterService: QuickFilterService,
		private _invoiceService: InvoiceService,
	) {}

	get isFilterOn(): boolean {
		const hasStartDate = !isValueEmpty(this.startDateFilter.value) && ![null, 'Invalid date'].includes(this.startDateFilter.value);
		const hasEndDate = !isValueEmpty(this.endDateFilter.value) && ![null, 'Invalid date'].includes(this.endDateFilter.value);
		const hasStatus = this.statusFilter.value.length > 0;
		return hasStartDate || hasEndDate || hasStatus;
	}

	/* Methods */
	ngOnInit(): void {
		setTimeout(() => {
			this._statusOption();
			this._initFilterValueChanges();
			this._getAccountActivitiesQuickFilters();
			this._eventListeners();
		}, 0);
	}

	onEmitTotalItems(totalItems: number) {
		this.totalItems = totalItems;
	}

	queryChange(delayTime: number = 0) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.isFilterHaveBeenCalled = true;
			this.fetchNewData();
		}, delayTime);
	}

	export(exportType: ApplicationType) {
		const params: any = {
			search: this.queryString.value,
			activityStartDate: ![null, 'Invalid date'].includes(this.startDateFilter.value) ? this.startDateFilter.value!.trim() : '',
			activityEndDate: ![null, 'Invalid date'].includes(this.endDateFilter.value) ? this.endDateFilter.value!.trim() : '',
			status: ![null, '', []].includes(this.statusFilter.value) ? this.statusFilter.value : '',
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.selectedPage,
			pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.selectedPageSize,
			column: this.selectedSortColumn,
			order: this.selectedSortType,
			exportType: exportType,
		};

		if (this.totalItems === 0) {
			this._toastMessageService.showErrorMessage(
				NotificationMessages.NoRecordFound
			);
		} else {
			this._spinner.start();

			this._financeService
				.exportPayments(params)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (resp) => {
						const link = document.createElement('a');
						link.href = resp;
						link.setAttribute('download', 'Account Activities');
						document.body.appendChild(link);
						link.click();
					},
					error: () => {
						this._toastMessageService.showErrorMessage(
							NotificationMessages.FailedToGenerateFile
						);
					},
					complete: () => {
						this._toastMessageService.showSuccessMessage(
							NotificationMessages.Export
						);
					},
				});
		}
	}

	setQuickFilter(event: any) {
		const statusIds = this.statusFilter.value as Number[];
		this.statusFilter?.setValue(this._quickFilterService.mapStatusIds(statusIds, event));
	}

	fetchNewData() {
		const filters: IAccountActivity = this._getFilters();
		this.searchFilters = filters;
	}

	private _statusOption() {
		this._financeService
			.accountActivityStatus()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: AccountActivityStatusDropdown[]) => {
					this.statusOption = result;
				},
			});
	}

	private _initFilterValueChanges() {
		this.fetchNewData();
		this._valueChanges(this.startDateFilter);
		this._valueChanges(this.endDateFilter);
		this._valueChanges(this.statusFilter);

		setTimeout(() => {
			this.clearFilter.clearAll.pipe(takeUntil(this._$unsubscribe)).subscribe({
				next: () => {
					this.startDateFilter.setValue('');
					this.endDateFilter.setValue('');
					this.statusFilter.setValue([]);
					this.isFilterHaveBeenCalled = true;
					this.resetVal = true;
				},
			});
		}, 100);
	}

	private _valueChanges(prop: FormControl) {
		prop.valueChanges.pipe(takeUntil(this._$unsubscribe)).subscribe({
			next: () => {
				this.isFilterHaveBeenCalled = true;
				this.fetchNewData();
				this._getAccountActivitiesQuickFilters();
			},
		});
	}

	private _getAccountActivitiesQuickFilters() {
		this.quickFilter = {
			statusIds: this.statusFilter?.value as number[],
			onSelectIds: this.setQuickFilter.bind(this),
			url: this.qFilterUrl.INVOICE,
			optionList: [
				{
					color: '#caf4db',
					fontColor: '#0d6630',
					borderColor: '#06560b',
					id: 'success',
					name: 'Success',
					addressLine1: null
				},
				{
					color: '#f8d1cd',
					fontColor: '#6d1008',
					borderColor: '#6d1008',
					id: 'error',
					name: 'Error',
					addressLine1: null
				}
			]
		};
	}

	private _getFilters(): IAccountActivity {
		return {
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.selectedPage,
			pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.selectedPageSize,
			column: this.selectedSortColumn,
			order: this.selectedSortType,
			searchText: this.queryString.value!.trim(),
      activityStartDate: ![null, 'Invalid date'].includes(this.startDateFilter.value) ? this.startDateFilter.value!.trim() : '',
      activityEndDate: ![null, 'Invalid date'].includes(this.endDateFilter.value) ? this.endDateFilter.value!.trim() : '',
      status: ![null, '', []].includes(this.statusFilter.value) ? this.statusFilter.value : '',
			companyId: this._financeService.companyIdType
		};
	}

	private _eventListeners() {
		this._invoiceService.isLoading$
			.pipe(
				tap((isLoading) => {
					if (isLoading) {
						this.queryString.disable();
					} else {
						this.queryString.enable();
						(this.searchTextbox as any).focus();
					}
				})
			).subscribe()
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
