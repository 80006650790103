import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserService } from '@app/core/services/user.service';
import { NotificationSettingsList } from './notification-settings.interface';

@Injectable({
	providedIn: 'root',
})
export class NotificationSettingsService {
	constructor(
		private _http: HttpClient,
		private _userService: UserService
	) {}

	getNotificationSettings(userId: number): Observable<NotificationSettingsList[]> {
		return this._http.get<NotificationSettingsList[]>(`${environment.apiBaseUrl}NotificationSetting`, { params: { UserId: userId } });
	}

	updateNotificationSettings(payload: Params, userId: number) {
		return this._http.post(`${environment.apiBaseUrl}NotificationSetting`, payload, { params: { UserId: userId }});
	}
}

