import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true
    }
  ]
})

export class RadioInputComponent implements ControlValueAccessor {
  @ViewChild('radioButtonControl') input: ElementRef;

  @Input() id: string;
  @Input() label: string;
  @Input() name: string;
  @Input() value: string | number | boolean;
  @Input() isDisabled: boolean = false;
  @Input() _radioValue: any;

  get radioValue() {
    return this._radioValue;
  }

  set radioValue(val) {
    this._radioValue = val;
    this.onChange(this._radioValue);
  }

  /* [Start]::Control value accessor implementation */ 
  onChange: any = () => {}
  onTouched: any = () => {}

  writeValue(value: any): void {
    if (value !== undefined) this._radioValue = value;
    if (this._radioValue === this.value) this.input?.nativeElement.click();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /* [End]::Control value accessor implementation */
}
