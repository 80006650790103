import { IPageState } from "@app/shared/interfaces/page-state.interface";
import { createAction, props } from "@ngrx/store";



export enum ActionTypes{
  SUPPORT_CATEGORIES_UPDATE = '[CompanySupportCategories] Update',
  SUPPORT_CATEGORIES_UPDATE_SUCCESS = '[CompanySupportCategories] Update Success',


}

export const companiesSupportCategoriesUpdateAction =
createAction(
  ActionTypes.SUPPORT_CATEGORIES_UPDATE,
  props<Partial<IPageState>>()
)

export const companiesSupportCategoriesUpdateActionSuccess =
createAction(
  ActionTypes.SUPPORT_CATEGORIES_UPDATE_SUCCESS
)

