<div
	class="svg-icon-embed"
	[ngClass]="{ 'mr-12': hasSpaceOnRight, 'ml-12': hasSpaceOnLeft }"
></div>
<embed
	#embedSVG
	[src]="data.iconUrl | safeUrl"
	type=""
	(load)="onLoad(embedSVG, data)"
	[width]="width"
	[height]="height"
	class="invisible"
/>
