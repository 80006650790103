import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { assetsUpdateAction } from '@app/modules/inventory/pages/assets/store/assets.actions';
import { IAsset } from '@app/modules/inventory/pages/assets/store/assets.interface';
import { initialStateIAsset } from '@app/modules/inventory/pages/assets/store/assets.reducer';
import { TableMessages } from '@app/shared/constants';
import { ITConfiguration, SeeMore, TableDynamicColumns, TablePage, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-asset-information-widget',
  templateUrl: './asset-information-widget.component.html',
  styleUrls: ['./asset-information-widget.component.scss']
})
export class AssetInformationWidgetComponent implements OnInit {

  //displayedColumns = ['name', 'status', 'type'];
  itConfuguration$: Observable<TablePage | null>;
  dataSource: Observable<ITConfiguration[]>;
  totalItems = 0;
  message = {
    noRecord: TableMessages.EmptyTable,
  };

  source: any[] = [];
  noRecords: string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns: string[] = [];
  rowClick: () => void
  noClick: () => {}
  seeMore: SeeMore;

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dashboardService: DashboardService,
    private _router: Router,
    private _store: Store,
    private _cd: ChangeDetectorRef,

  ) {
    _dashboardService.initITConfiguration();
    this.itConfuguration$ = this._dashboardService.itConfiguration$;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    /* const data$ = this._dashboardService
      .getITConfiguration()
      .pipe(
        map(v=>v.data as ITConfiguration[]),
        tap(value=>this.totalItems=value.length)
      )
    this.dataSource = data$; */
    this.itConfuguration$
      .pipe(
        catchError(v => []),
        map(v => v!.data as ITConfiguration[])
      )
      .subscribe(v => {
        this.#createDataTable(v)
      })
  }

  #createDataTable(assets: ITConfiguration[]) {
    this.source = assets;
    this.columns = this.#getColumns()
    this.displayedColumns = this.#getDisplayedColumns()
    this.seeMore = this.#getSeeMore();
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
  }

  #getColumns(): TableDynamicColumns[] {
    return [{
      text: 'Name',
      matColumnDef: 'name',
    },
    {
      text: 'Status',
      matColumnDef: 'status',
      statusType: 'check-mark',
      class: {
        th: 'center-text',
        td: 'center-text',
      }
    },
    {
      text: 'Expiration Date',
      matColumnDef: 'expireDate',
    },
    {
      text: 'Type',
      matColumnDef: 'type',
    },
    ]
  }

  #getDisplayedColumns() { return ['name', 'status', 'expireDate', 'type'] }

  #getSeeMore(): SeeMore {
    const state = { ...initialStateIAsset, ...{ statusIds: [11027] } }
    return {
      link: '/company/assets',
      state: assetsUpdateAction(state),
    }
  }

  getDataTable(): WidgetDynamicTable2 {
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      displayedColumns: this.displayedColumns,
      seeMore: this.seeMore
    }
  }

  goToOnline() {
    const state = { statusIds: [11027], typeIds: [], onlineOfflineStatus: [] }
    this.#updateInventoryState(state);
    this._router.navigateByUrl('/inventory/assets');
  }

  #updateInventoryState(options: Partial<IAsset>) {
    this._store.dispatch(assetsUpdateAction({ ...options }))
  }

}
