/* Initialized gainsight PX Tag and it stores your cookies data in your browser */
export function initGainsightPxScript(tagKey: string, localStorageCookie: boolean) {
  (function (n: any, t: any, a: any, e: any, co: any) {
    var i: any = 'aptrinsic';
    (n[i] =
      n[i] ||
      function () {
        (n[i].q = n[i].q || []).push(arguments);
      }),
      (n[i].p = e);
    n[i].c = co;
    
    var r = t.createElement('script');
    (r.async = !0), (r.src = a + '?a=' + e);

    var c: any = t.getElementsByTagName('script')[0];
    c.parentNode.insertBefore(r, c);
  })(
    window,
    document,
    'https://web-sdk.aptrinsic.com/api/aptrinsic.js',
    tagKey,
    { localStorageCookie: localStorageCookie }
  );
}