export enum DashboardReportTab {
	TotalTickets = 1,
	ServiceTicket = 2,
	UserTicket = 3,
	SiteTicket = 4,
	ServiceTimes = 5,
	DeviceTicket = 6,
	RequestSource = 7,
	Onboarding = 8,
	SatisfactionScore = 9,
	TimeSpent = 10,
	Vulnerabilities = 11,
	AtRiskUsers = 12,
	AtRiskDevices = 13,
	RecentSecurityTickets = 14,
}
