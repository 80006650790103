import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment } from '@app/shared/interfaces/general/attachment.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-attachment-preview-modal',
  templateUrl: './attachment-preview-modal.component.html',
  styleUrls: ['./attachment-preview-modal.component.scss']
})
export class AttachmentPreviewModalComponent implements OnInit {
  @Input() show = false;
  @Input() data: Attachment | string | File | null = null;
  @Input() typeFile: 'image' | 'video' | null = null;
  @Input() title = '';
  @Input() deleteEnabled = false;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteEmitter = new EventEmitter<void>();

  imgTypes = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
  videoTypes = ['mp4', 'mov', 'wmv', 'flv'];

  get fileType(): string {
    let type = '';

    if (this.data) {
      if (!this.typeFile) {
        if (this.data instanceof File) {
          type = (this.data as File).type.split('/')[0];
        } else if (typeof this.data === 'string' && this.data.includes(';base64')) {
          type = this.data.split('data:')[1].split('/')[0];
        } else if (typeof this.data === 'string') {
          const url = (this.data as string).split('/');
          const fileName = url[url.length - 1];
          const extension = fileName.split('.').pop()?.toLowerCase();

          if (extension) {
            if (this.imgTypes.includes(extension)) {
              type = 'image';
            } else if (this.videoTypes.includes(extension)) {
              type = 'video';
            }
          }
        } else {
          type = this.data.fileType.split('/')[0];
        }
      } else {
        type = this.typeFile
      }
    }

    return type;
  }

  get fileName(): string {
    let name = 'Uploaded image';

    if (this.title) {
      name = this.title;
    }/* else 
    if(this.data) {
      if(this.data instanceof File) {
        name = (this.data as File).name;
      } else if(typeof this.data === 'string') { // remove this
        const url = (this.data as string).split('/');
        const fileName = url[url.length - 1];
        //name = fileName;
      } else {
        name = this.data.fileName;
      }
    } */

    return name;
  }

  get filePath(): string {
    let path = '';

    if (this.data) {
      if (this.data instanceof File) {
        const blob = new Blob([this.data])
        path = URL.createObjectURL(blob);
      } else if (typeof this.data === 'string') {
        path = this.data;
      } else {
        path = this.data.path;
      }
    }

    return path;
  }

  sanitizer(value: any) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  delete() {
    this.deleteEmitter.emit();
    this.close.emit();
  }

  constructor(
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

}
