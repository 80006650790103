import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.scss']
})
export class ModalV2Component implements OnInit {
  @ContentChild('modalV2Header') modalV2Header: TemplateRef<unknown>;
  @ContentChild('modalV2Body') modalV2Body: TemplateRef<unknown>;
  @ContentChild('modalV2Footer') modalV2Footer: TemplateRef<unknown>;

  @Input() modalHeight: number;
  @Input() modalWidth: number;
  @Input() isLoading: boolean = false;
  @Input() hasBorder: boolean = true;
  @Output() onCloseModalViaBackdrop: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  clickBackdrop(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.onCloseModalViaBackdrop.emit();
    }
  }
}
