import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import {
  Message,
  MessageCategory,
} from '@app/shared/interfaces/message.interface';
import {
  UserMessageCategory,
  UserMessageCategoryCount,
  UserMessageCategoryNotification,
} from '@app/shared/interfaces/usermessage.interface';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientMessageService {
  private searchKeySource: BehaviorSubject<string> = new BehaviorSubject('');
  searchKey$ = this.searchKeySource.asObservable();

  messageCategoryAndCount = new Subject<UserMessageCategory[]>();

  constructor(private _http: HttpClient, private _router: Router) {}

  private sub_messageRouteChange: Subscription;
  searchText = '';
  clearSearch() {
    this.searchText = '';
  }
  startMessageModuleRouteMonitoring() {
    if (this.sub_messageRouteChange) this.sub_messageRouteChange.unsubscribe();
    this.sub_messageRouteChange = this._router.events.subscribe((s) => {
      if (
        s instanceof NavigationEnd &&
        !(
          (s.url + '/').startsWith('/client-message/') ||
          s.url.includes('/global-search') ||
          s.url.includes('/message/all-messages')
        )
      ) {
        this.clearSearch();
        if (this.sub_messageRouteChange)
          this.sub_messageRouteChange.unsubscribe();
      }
    });
  }

  searchKeyUpdate(key: string) {
    this.searchKeySource.next(key);
  }
  setTdColor(value: string) {
    let color = '';
    if (value === 'fa-sitemap') {
      color = 'broadcast';
    } else if (value === 'fa-calendar-minus-o') {
      color = 'banner';
    } else if (value === 'fa-bullhorn') {
      color = 'support';
    } else if (value === 'fa-floppy-o') {
      color = 'saved';
    } else {
      color = 'service';
    }
    return color;
  }

  formatDate(d: any) {
    const newDate = new Date(d);
    return newDate ? moment(newDate).format('ll') : '';
  }

  getCategories() {
    return this._http.get<UserMessageCategory[]>(
      `${environment.apiBaseUrl}UserMessages/GetCategories`
    );
  }

  getMessageCategory() {
    return this._http.get<UserMessageCategoryCount>(
      `${environment.apiBaseUrl}UserMessages/GetUserMessageCountPerCategory`
    );
  }

  refreshMessageCategory() {
    this._http
      .get<any>(
        `${environment.apiBaseUrl}UserMessages/GetUserMessageCountPerCategory`
      )
      .pipe(
        tap((s) => {
          this.messageCategoryAndCount.next(s?.data as UserMessageCategory[]);
        })
      )
      .subscribe();
  }

  getMessageList(
    categoryId?: any,
    userId?: number,
    searchText?: string,
    page?: number,
    pageSize?: number,
    column?: string,
    order?: string
  ): Observable<any> {
    return this._http.get<any>(
      `${environment.apiBaseUrl}UserMessages?UserId=${userId}&CategoryId=${categoryId}&Page=${page}&PageSize=${pageSize}&SearchText=${searchText}&Column=${column}&Order=${order}`
    );
  }

  prevNextMessage(
    action: string,
    currentId: number,
    userId: number,
    categoryId: number
  ): Observable<Message> {
    return this._http.get<Message>(
      `${environment.apiBaseUrl}UserMessages/${action}/${currentId}?userId=${userId}&categoryId=${categoryId}`
    );
  }

  getMessage(
    id: number,
    userId: number,
    categoryId: number
  ): Observable<Message> {
    return this._http.get<Message>(
      `${environment.apiBaseUrl}UserMessages/${id}?userId=${userId}&categoryId=${categoryId}`
    );
  }

  getMessageDetail(id: number, categoryId: number) {
    return this._http.get<Message>(
      `${environment.apiBaseUrl}UserMessages/getdetail?id=${id}&categoryId=${categoryId}`
    );
  }

  pinMessage(id: number, isPinned: boolean) {
    return this._http.get(
      `${environment.apiBaseUrl}UserMessages/PinOrUnpinMessage/${id}?isPinned=${isPinned}`
    );
  }

  saveMessage(id: number, isSaved: boolean) {
    return this._http
      .get(
        `${environment.apiBaseUrl}UserMessages/SaveOrUnsaveMessage/${id}?isSaved=${isSaved}`
      )
      .pipe(tap(() => this.refreshMessageCategory()));
  }

  readMessage(id: number, isRead: boolean) {
    return this._http
      .get(
        `${environment.apiBaseUrl}UserMessages/ReadOrUnreadMessage/${id}?isRead=${isRead}`
      )
      .pipe(tap(() => this.refreshMessageCategory()));
  }

  getMessages(
    page: number,
    pageSize: number,
    column: string,
    order: string,
    keyword: string,
    userRole: number,
    userId: number,
    companyId: number
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}Messages?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&keyword=${keyword}&userRole=${userRole}&userId=${userId}&companyId=${companyId}` //&categoryType=${categoryType}
    );
  }

  updateMessage(payload: Params, id: number) {
    return this._http.put(`${environment.apiBaseUrl}Messages/${id}`, payload);
  }

  deleteMessage(id: number) {
    return this._http
      .delete(`${environment.apiBaseUrl}Messages/${id}`)
      .pipe(tap(() => this.refreshMessageCategory()));
  }

  deleteMessages(id: number[]) {
    return this._http
      .post(`${environment.apiBaseUrl}UserMessages/DeleteMessages`, id)
      .pipe(tap(() => this.refreshMessageCategory()));
  }

  readMessages(payload: Params) {
    return this._http
      .post(`${environment.apiBaseUrl}UserMessages/ReadMessages`, payload)
      .pipe(tap(() => this.refreshMessageCategory()));
  }

  saveMessages(payload: Params) {
    return this._http
      .post(`${environment.apiBaseUrl}UserMessages/SaveMessages`, payload)
      .pipe(tap(() => this.refreshMessageCategory()));
  }

  downloadTicket(id: number){
    return this._http.get(`${environment.apiBaseUrl}tickets/getPdf/${id}`, { responseType: 'text' });
  }
}
