import { createAction, props } from "@ngrx/store";
import { ITickets } from "./tickets.interface";
export enum ActionTypes{
  TICKETS_UPDATE = '[Tickets] Update',
  TICKETS_UPDATE_SUCCESS = '[Tickets] Update Success',
}
export const ticketsUpdateAction =
createAction(
  ActionTypes.TICKETS_UPDATE,
  props<Partial<ITickets>>()
)
export const ticketsUpdateSuccessAction =
createAction(
  ActionTypes.TICKETS_UPDATE_SUCCESS
)