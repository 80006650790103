<div class="cloud-backup">
	<ng-container *ngIf="!isStillLoading; else loading">
    <ng-container *ngIf="(cloudBackup$ | async) as backups">
      <ng-container *ngIf="backups.length > 0; else noRecord">
        <ng-container *ngFor="let backup of backups">
          <div class="cloud-item">
            <span class="item-header font-weight-normal text-xs">{{backup.usage_name}}</span>
            <span class="item-detail font-weight-normal text-xs">{{backup.value}}/unlimited</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #noRecord>
  <span class="font-weight-normal text-xs no-records">No records found.</span> 
</ng-template>

<ng-template #loading>
	<widget-loading></widget-loading>
</ng-template>