import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomDatePipe } from './custom-date.pipe';
import { MapUserRoleIdPipe } from './map-user-role-id.pipe';
import { PhonePipe } from './phone.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeURLPipe } from './safe-url.pipe';

@NgModule({
	declarations: [
		CustomDatePipe,
		MapUserRoleIdPipe,
		PhonePipe,
		SafeHtmlPipe,
		SafeURLPipe,
	],
	imports: [CommonModule],
	exports: [
		CustomDatePipe,
		MapUserRoleIdPipe,
		PhonePipe,
		SafeHtmlPipe,
		SafeURLPipe,
	],
})
export class CustomDatePipeModule {}
