import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ChatBotComponent } from './../../../../../shared/components/chat-bot/chat-bot.component';
import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';

import { finalize } from 'rxjs';

import { SpinnerService } from '@app/core/services/spinner.service';
import { KnowledgeBaseService } from '../knowledge-base.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { NotificationService } from './../../../../../core/services/notification.service';
import { UserService } from './../../../../../core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { ChatBotService } from '@app/shared/components/chat-bot/chat-bot.service';

import {
	Modules,
	NotificationMessages,
	NotifOperation,
	NewTicket,
} from '@app/shared/constants';

import {
	keyValuePair,
	TopicList,
	TopicsByCategory,
} from './../../../../../shared/interfaces/knowledge-base.interface';

@Component({
	selector: 'app-search-by-topic',
	templateUrl: './search-by-topic.component.html',
	styleUrls: ['./search-by-topic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchByTopicComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit
{
	@ViewChild(ChatBotComponent) chatBot: ChatBotComponent;

	selectedCategoryHeader: any;
	topics: keyValuePair;
	state: keyValuePair;
	topicList: TopicList[];
	topicId: number;
	categoryId: number;
	htmlWithCss: any;
	el: HTMLElement;
	isChatOpen: boolean = false;
	isChatMinimized: boolean = false;
	user = this._userService.user;
	tokenData: any;
	iframeUrl: string;
	chatBotId: number;
	botData: any;
	isAdmin: boolean;
	switch = false;
	newTicketUrl = NewTicket.url;

	btnText = '';
	iconName: string;
	isProcessing = false;

	constructor(
		public spinner: SpinnerService,
		public override _route: ActivatedRoute,
		public override _companiesService: CompaniesService,
		private _cd: ChangeDetectorRef,
		private _router: Router,
		private _location: Location,
		private _userService: UserService,
		private _notifier: NotificationService,
		private _utilityService: UtilitiesService,
		private _toastMessageService: ToastMessageService,
		private _breadcrumbService: BreadcrumbService,
		private _dashboard: DashboardService,
		private _sessionStorage: SessionStorageService,
		private _kbService: KnowledgeBaseService,
		private _chatBotService: ChatBotService
	) {
		super(_companiesService, _route);

		if (this._userService.user?.roleId === 1) {
			this.isAdmin = true;
		}
	}

	get tootltipTitle(): string {
		return !this.switch
			? 'Click here to save to dashboard'
			: 'Click here to remove from dashboard';
	}

	ngOnInit() {
		this._route.queryParams.subscribe((param) => {
			const topicId = +param['topicId'];
			const categoryId = +param['categoryId'];
			this.topicId = topicId;
			this.categoryId = categoryId;

			this.getCompanyIdFromParams();
			this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(
				this._companiesService.subCompanyId!
			);

			this.getTopicById(this.topicId, 'oninit');
			this.getCategoryName();

			if (this.user?.companyId) {
				this._kbService
					.getTopicsByCategoryId(this.categoryId, this.user?.companyId)
					.subscribe((topicList: TopicsByCategory[]) => {
						this.topicList = topicList;
						this._cd.markForCheck();
					});
			}
		});
	}

	ngAfterViewInit() {}

	setBtnText() {
		this.btnText = this.switch ? 'Saved' : 'Save to Dashboard';
		this._cd.detectChanges();
	}

	getActiveTopic(topic: TopicsByCategory, topicId: number): boolean {
		let isActive = false;

		if (topicId && topic.id === topicId) {
			isActive = true;
		}

		return isActive;
	}

	getCategoryName() {
		if (this.user?.roleId === 2 || this.user?.roleId === 3) {
			this._kbService
				.getUserCategory(this.user.id, this.user.companyId)
				.subscribe((category: any) => {
					const cat = category?.data.filter(
						(x: any) => x.id === this.categoryId
					);

					this._breadcrumbService.updateBreadCrumbsText(
						'_kbCategoryName',
						cat[0].name
					);
					sessionStorage.setItem('categoryName', cat[0].name);
					this.selectedCategoryHeader = cat[0].name;
					this._cd.markForCheck();
					this.spinner.stop();
				});
		}
	}

	getTopicById(id: number, type: string) {
		this.spinner.start();
		this.topicId = id;
		this._kbService
			.getTopicById(id, true) // pass true to record view count
			.subscribe((topicData: keyValuePair) => {
				this._breadcrumbService.updateBreadCrumbsText(
					'_kbTopicName',
					topicData['title']
				);
				this.topics = topicData;
				this.switch = this.topics['isQuickAccess'];

				const desc = JSON.parse(this.topics['description']);
				const css = desc.css;
				const html = desc.html;

				this.htmlWithCss = `<style>${css}</style>${html}`;

				if (type === 'click-by-topic') {
					this._updateQueryParams(this.topics['title']);
				}

				this.setBtnText();

				this._cd.detectChanges();
				this.spinner.stop();
			});
	}

	openChat() {
		const value = false;

		this._utilityService.toggleChatBot(true);
		this._sessionStorage.setStorage('isChatBotMinimized', value.toString());
		this._chatBotService.isMinimize(value);
	}

	goBack() {
		this._location.back();
	}

	likeDislikeTopic(liked: boolean, disliked: boolean, type: string) {
		this.spinner.start();

		const likeDislikeVal = {
			userId: this._userService.user?.id,
			companyId: this._userService.user?.companyId,
			isLiked: liked,
			isDisliked: disliked,
		};

		this._kbService.topicLikeDislike(this.topicId, likeDislikeVal).subscribe({
			next: () => {
				this.getTopicById(this.topicId, 'oninit');
				this.spinner.stop();
			},
		});
	}

	quickAccessTopic() {
		this.switch = !this.switch;
		this.btnText = '...Processing';
		this.isProcessing = true;
		this.spinner.start();
		this._kbService
			.topicQuickAccess(this.topicId, this.switch)
			.pipe(finalize(() => this.spinner.stop()))
			.subscribe({
				next: () => {
					this._dashboard.initKBWidget(false, true);
				},
				complete: () => {
					this.isProcessing = false;
					this.setBtnText();
				},
			});
	}

	isLikeStyle(like: boolean, dislike: boolean) {
		let style = '';

		if (like === false && dislike === false) {
			style = '';
		} else {
			if (like === true) {
				style = 'is-liked';
			} else {
				style = 'is-none';
			}
		}

		return style;
	}
	isDislikeStyle(like: boolean, dislike: boolean) {
		let style = '';

		if (like === false && dislike === false) {
			style = '';
		} else {
			if (like === true) {
				style = 'is-none';
			} else {
				style = 'is-disliked';
			}
		}

		return style;
	}

	private _updateQueryParams(topicName: string) {
		sessionStorage.setItem('topicName', topicName);
		this._router.navigate([], {
			relativeTo: this._route,
			queryParams: {
				topicId: this.topicId,
			},
			queryParamsHandling: 'merge',
		});
	}
}
