import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CwpodVerificationService, CWPodVerificationState, VerificationState } from '../cwpod-verification.service';

@Component({
  selector: 'app-verify-pin',
  templateUrl: './verify-pin.component.html',
  styleUrls: ['./verify-pin.component.scss']
})
export class VerifyPinComponent implements OnInit {

  clientPin:string = '';
  state:Observable<CWPodVerificationState>;
  verifyState: VerificationState;
  @Output() sendClientPin = new EventEmitter<string>();
  @Input() verificationStatus$:Observable<boolean>;

  constructor(
    private _podVerification: CwpodVerificationService,
    private _cd: ChangeDetectorRef
  ){
    this.state = _podVerification.state;
    this.state.subscribe(v=>{
      this.verifyState = v.verification;
      _cd.markForCheck();
    })
  }

  ngOnInit() {
  }

  verifyPin(){
    if(this.clientPin.toString().trim()){
      this.sendClientPin.emit(this.clientPin);
    }
  }
  onKeyDown(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const numbersOnly = inputValue.replace(/[^0-9]/g, '');
    input.value = numbersOnly;
  }
  get borderColor(){
    if(this.verifyState == 'success'){
      return 'success';
    }else if(this.verifyState == 'error'){
      return 'error';
    }
    return '';
  }

}
