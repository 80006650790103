import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewChild,
	OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
	WidgetList,
	WidgetListWithCategory,
} from '@app/shared/interfaces/dashboard.interface';
import { DashboardDrawerService } from '@app/shared/services/dashboard-drawer-service';
import { DashboardWidgetService } from '@app/shared/services/dashboard/dashboard-widget.service';
import { debounceTime, Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-user-dashboard-card-add-list',
	templateUrl: './user-dashboard-card-add-list.component.html',
	styleUrls: ['./user-dashboard-card-add-list.component.scss'],
})
export class UserDashboardCardAddListComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('container') cont: ElementRef;
	@ViewChild('searchEl') searchEl: ElementRef;

	listener: any;

	categories: {
		original: WidgetListWithCategory[];
		filtered: WidgetListWithCategory[];
		filteredCount: number;
	} = { original: [], filtered: [], filteredCount: 0 };

	widgets$: Observable<WidgetListWithCategory[]>;
	search = new FormControl();
	img = 'assets/images/others/light.png';

	#subscription = new Subscription();
	#listener: () => void;

	constructor(
		private _dashboardWidgetService: DashboardWidgetService,
		private _changeDetection: ChangeDetectorRef,
		public drawer: DashboardDrawerService,
		private _renderer2: Renderer2	) {
		this.widgets$ = this._dashboardWidgetService.refreshWidgets;
		this._dashboardWidgetService.refreshWidgetList();
	}

	ngAfterViewInit(): void {
		this.#eventListeners();
	}

	ngOnDestroy(): void {
		this.#subscription.unsubscribe();
		this.#listener();
	}

	ngOnInit(): void {}

	addCard(i: WidgetList, index: number) {
		this._dashboardWidgetService.moveWidgetsToDashboard(i.id);
	}

	dragEvent(event: any, eventType: 'start' | 'end') {
		this._dashboardWidgetService.dragDropEvent$.next(eventType);
	}

	#eventListeners() {
		this.#subscription.add(
			this.widgets$.subscribe({
				next: (widgets) => {
					this.categories.original = widgets;
					this.categories.filtered = { ...widgets };
					this.#setFilteredCategories(this.search.value);
				},
			})
		);

		this.#subscription.add(
			this.search.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
				this.#setFilteredCategories(val);
			})
		);

		this.#listener = this._renderer2.listen(
			this.cont.nativeElement,
			'scroll',
			(e: Event) => {
				const scrolltop = (e.target as Element).scrollTop;
				if (scrolltop > 0) {
					this._renderer2.addClass(this.searchEl.nativeElement, 'searchSticky');
				} else {
					this._renderer2.removeClass(
						this.searchEl.nativeElement,
						'searchSticky'
					);
				}
			}
		);
	}

	closeWidgetList() {
		this.drawer.openWidgetList = false;
	}

	#setFilteredCategories(filter: string = '') {
		filter = filter ? filter : '';
		this.categories.filtered = [];
		this.categories.filteredCount = 0;
		this.categories.original.forEach((cat, i) => {
			this.categories.filtered.splice(i, 0, cat);
			const sample = filter
				? cat.cards.filter((c) =>
						c.title?.toLowerCase()?.includes(filter.toLowerCase())
				  )
				: cat.cards;
			if (sample.length > 0) this.categories.filteredCount++;
			this.categories.filtered[i] = {
				...this.categories.filtered[i],
				...{ cards: sample },
			};
		});
		this._changeDetection.detectChanges();
	}

	handleImgError(elem: HTMLImageElement, event: any) {
		elem.src = this.img;
	}
}
