import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { assetsUpdateAction } from '@app/modules/inventory/pages/assets/store/assets.actions';
import { IAsset } from '@app/modules/inventory/pages/assets/store/assets.interface';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { TableMessages } from '@app/shared/constants';
import { OfflineSite, SeeMore, TableDynamicColumns, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-offline-site-widget',
  templateUrl: './offline-site-widget.component.html',
  styleUrls: ['./offline-site-widget.component.scss']
})
export class OfflineSiteWidgetComponent implements OnInit, OnDestroy{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  offlineSite$: Observable<OfflineSite[] | null>;
  dataSource:Observable<OfflineSite[]>;
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  //totalItems:number = 0;

  #subscription = new Subscription();

  // properties for widgetDynamicTable / responsiveDynamicTable
  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: ()=>{}
  noClick: ()=>{}
  seeMore: SeeMore;

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);

  constructor(
    private _dashboardService: DashboardService,
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _store: Store, 
  ){
    this._dashboardService.initOfflineSite();
    this.offlineSite$ = this._dashboardService.offlineSite$
  }
  
  ngOnInit() { 
    this.#initProperties()
  }
    
  ngOnDestroy(): void {
    this.#subscription.unsubscribe()
  }

  #initProperties(){
    this.#subscription.add(
      this.offlineSite$.subscribe(v=>{
        this.#setData(v!)
      })
    )
  }

  #setData(offline:OfflineSite[]){
    this.source = offline;
    this.columns = this.#getColumns()
    this.displayedColumns = this.#getDisplayedColumns()
    this.seeMore = this.#getSeeMore();

    this.dataTable$.next(this.getDataTable())
  }

  #getColumns(){
    return [
      {
        text:'Name',
        matColumnDef: 'name'
      },
      {
        text:'Site',
        matColumnDef: 'site'
      },
      {
        text:'Status',
        matColumnDef: 'status'
      },
      {
        text:'Type',
        matColumnDef: 'type'
      },
    ]
  }

  #getDisplayedColumns(){
    return [
      'name',
      'site',
      'status',
      'type',
    ]
  }

  #getSeeMore():SeeMore{
    const state = {statusIds:[], typeIds: [], onlineOfflineStatus:['Offline']}
    return {
      link: '/company/assets',
      state: assetsUpdateAction(state),
    }
  }

  goToOffline(){
    const state = {statusIds:[], typeIds: [], onlineOfflineStatus:['Offline']}
    this.#updateInventoryState(state);
    this._router.navigateByUrl('/inventory/assets');
  }

  #updateInventoryState(options: Partial<IAsset>){
    this._store.dispatch(assetsUpdateAction({...options}))
  }

  getDataTable():WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.rowClick,
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      //seeMoreLink: this.seeMoreLink,
      seeMore: this.seeMore,
      displayedColumns: this.displayedColumns,
    }
  }

}

