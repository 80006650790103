<div class="table-responsive table-striped"><!--applist-table cat-apps-->
  <div>
    <app-column-slider
    [(displayedColumns)]="displayedColumns"
    ></app-column-slider>
    <table class="table" mat-table
    [dataSource]="appData">
      <!-- Ticket Title -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>{{title}}</th>
        <td class="py-3" mat-cell *matCellDef="let row">
          {{row.title}}
        </td>
      </ng-container>
      <tr>
        <th colspan="2" class="p-2 text-center">
          {{ message.noRecord }}
        </th>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="pointer"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8" style="text-align: center;">
          {{ message.noRecord }}
        </td>
      </tr>
    </table>
  </div>
</div>

