import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { YesNoModalComponent } from "./yes-no-modal.component";
import { YesNoConfig } from "./yes-no-modal.interface";


@Injectable({
  providedIn: 'root'
})
export class YesNoModalService{

  constructor(private dialog: MatDialog) { }

  confirmDialog(data: YesNoConfig): MatDialogRef<YesNoModalComponent>{
    return this.dialog.open(YesNoModalComponent,{
      width: '400px',
      maxWidth: '400px',
      height: '400px',
      data:{
        icon:{
          name: data.iconName,
          color: data.iconColor
        },
        header: data.header,
        message: data.message,
        yesName: data.yesName,
        options: {
          cancel: data.cancel,
          accept: data.accept
        }
      }
    })
  }
}
