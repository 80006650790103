import {
	HttpErrorResponse,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HasXApiVersionHeader, UntokenizedRoutes } from '@app/shared/constants';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { Store, select } from '@ngrx/store';
import { environment } from 'environments/environment';
import {
	Observable,
	catchError,
	exhaustMap,
	filter,
	switchMap,
	take,
	throwError,
} from 'rxjs';
import { UserService } from '../services/user.service';
import { AppStateInterface } from '../store/app-state.interface';
import {
	jwtUpdateAction,
	jwtUpdateWoEffectAction,
} from '../store/jwt/jwt.action';
import { IJWT } from '../store/jwt/jwt.interface';
import { accessTokenSelector } from '../store/jwt/jwt.selector';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	untokenizedRoutes: string[] = UntokenizedRoutes;
	hasXApiVersionHeader: string[] =
		HasXApiVersionHeader; /* Add here for those api endpoints that are supported with 1.1 api version. */
	isRefreshing: boolean = false;

	_store = inject(Store<AppStateInterface>);
	_localStorageService = inject(LocalStorageService);
	_userService = inject(UserService);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
		return this._store.pipe(
			select(accessTokenSelector),
			take(1),
			exhaustMap((token) => {
				const updatedRequest = this.addToken(req, token);
				const urlWithoutBase = this.removeBase(req.url);

				const hasAccessToken = !!localStorage.getItem('accessToken');
				const hasRefreshToken = !!localStorage.getItem('refreshToken');
				if (this.hasBaseUrl(req.url)) {
					return next.handle(updatedRequest).pipe(
						catchError((error: HttpErrorResponse) => {
							if (
								(error.status === 400 &&
									req.url === 'UserLogin/RefreshToken') || // Invalid Token
								(error.status === 403 && this._userService.isAuthenticated) // Deactivated
							) {
								return this.genericErrorHandler(error);
							} else if (
								!this.untokenizedRoutes.includes(urlWithoutBase) &&
								error.status === 401 &&
								error.statusText === 'invalid_token' &&
								hasAccessToken &&
								hasRefreshToken
							) {
								return this.handle401Error(req, next);
							} else {
								return throwError(() => error);
							}
						})
					);
				} else {
					return next.handle(req);
				}
			})
		);
	}

	private hasBaseUrl(url: string) {
		return url.includes(environment.apiBaseUrl);
	}
	private removeBase(url: string) {
		return url.replace(environment.apiBaseUrl, '');
	}

	private genericErrorHandler(err: HttpErrorResponse) {
		if (err.status === 400 || err.status === 403) {
			this._userService.logout();
		}
		return throwError(() => err);
	}

	private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this._store.dispatch(
				jwtUpdateWoEffectAction({ accessToken: null, refreshToken: null })
			);
			return this._userService.getNewAccessToken().pipe(
				switchMap((jwt: IJWT) => {
					console.log('error');
					this.isRefreshing = false;
					this._store.dispatch(
						jwtUpdateAction({
							accessToken: jwt.accessToken,
							refreshToken: jwt.refreshToken,
						})
					);
					this._localStorageService.setStorageObject(
						'accessToken',
						jwt.accessToken ? jwt.accessToken : ''
					);
					this._localStorageService.setStorageObject(
						'refreshToken',
						jwt.refreshToken ? jwt.refreshToken : ''
					);
					sessionStorage.setItem(
						'accessToken',
						jwt.accessToken ? jwt.accessToken : ''
					);
					// window.location.reload();
					return next.handle(this.addToken(req, jwt.accessToken));
				}),
				catchError((err) => {
					console.log('error2');
					return this.genericErrorHandler(err);
				})
			);
		} else {
			return this._store.pipe(
				select(accessTokenSelector),
				filter((token) => token !== null),
				take(1),
				switchMap((token) => {
					return next.handle(this.addToken(req, token));
				}),
				catchError((error) => {
					return throwError(() => error);
				})
			);
		}
	}

	private addToken(req: HttpRequest<any>, token: string | null) {
		let newHeaders: HttpHeaders = req.headers;

		const urlWithoutBase = this.removeBase(req.url);
		if (!this.untokenizedRoutes.includes(urlWithoutBase.toLowerCase())) {
			newHeaders = newHeaders.append(
				'Authorization',
				'Bearer ' + JSON.parse(JSON.stringify(token))
			);
		}

		/* Checking for api endpoints that are supported with api versioning. */

		let isVersion1P2 =
			req.headers
				.keys()
				.map((key) => `${key}: ${req.headers.get(key)}`)
				.findIndex((obj) => obj === 'x-api-version: 1.2') === -1
				? false
				: true;

		if (
			this.hasXApiVersionHeader.includes(
				this.removeIdAndParameters(urlWithoutBase)
			) &&
			!isVersion1P2
		) {
			newHeaders = newHeaders.set('x-api-version', '1.1');
		}

		const updatedRequest = req.clone({
			url: req.url,
			headers: newHeaders,
		});
		return updatedRequest;
	}

	removeIdAndParameters(endpointWithIdAndParams: string) {
		const [path, _] = endpointWithIdAndParams.split('?');
		const regex = /\/\d+$/;
		const endpointWithoutId = path.replace(regex, '');
		return endpointWithoutId;
	}
}
