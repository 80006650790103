import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import {
	filter,
	retry,
	distinctUntilChanged,
	BehaviorSubject,
	merge,
} from 'rxjs';
import { formatNote } from '@app/shared/utilities/helper';

import { UserService } from '@app/core/services/user.service';
import { SmsQuestMobileUpdateModalService } from '../sms-quest-mobile-update-modal/sms-quest-mobile-update-modal.service';
import { MobileLaunchModalService } from '../mobile-launch-modal/mobile-launch-modal.service';

import { CampaignModal } from '@app/modules/system/interfaces/campaign.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NewFeatureInfoModalService {
	campaign = new BehaviorSubject<CampaignModal | null>(null);
	campaign$ = this.campaign.asObservable();
	campaigns: CampaignModal[];
	interval: any;
	isSubscribed = false;
	currentUrl: string;

	isMobileModalDisplayed = false;

	constructor(
		private _userService: UserService,
		private _http: HttpClient,
		private _router: Router,
		private _mobileUpdate: SmsQuestMobileUpdateModalService,
		private _mobileLaunch: MobileLaunchModalService
	) {}

	initCampaigns() {
		this.campaigns = [];
		clearInterval(this.interval);

		if (this._userService.user?.impersonatingBy) return;
		this.#getCampaigns();
		this.interval = setInterval(() => this.#getCampaigns(), 300000); // check every 5 minutes

		if (this.isSubscribed) return;
		this.isSubscribed = true;

		merge(
			this._mobileLaunch.isMobileModalDisplayed$,
			this._mobileUpdate.isShowQuestMobileUpdate$
		)
			.pipe(filter((e) => e != this.isMobileModalDisplayed))
			.subscribe((e) => {
				this.isMobileModalDisplayed = e;
				if (!this.isMobileModalDisplayed) this.#checkCampaigns();
			});

		this._router.events
			.pipe(
				distinctUntilChanged(),
				filter(() => !!this.campaigns.length),
				filter((e) => e instanceof NavigationStart)
			)
			.subscribe((e) => {
				this.currentUrl = (e as NavigationStart).url;
				this.#checkCampaigns();
			});
	}

	displayCampaign(campaign: CampaignModal) {
		campaign.features.forEach((f) => {
			f.description = formatNote(f.description);
		});
		this.campaign.next(campaign);
	}

	#checkCampaigns() {
		if (this.campaign.value || this.isMobileModalDisplayed) return;

		if (!this.currentUrl) this.currentUrl = this._router.url;
		const i = this.campaigns.findIndex((c) =>
			this.currentUrl.startsWith('/' + c.triggerScreen)
		);
		if (i != -1) {
			const c = this.campaigns.splice(i, 1);
			if (c.length) {
				if (
					!c[0].triggerPermission ||
					this._userService.hasPermission(c[0].triggerPermission)
				)
					this.displayCampaign(c[0]);
			}
		}
	}

	#getCampaigns() {
		this._http
			.get<CampaignModal[]>(
				`${environment.apiBaseUrl}MarketingDisplays/Campaigns?platform=0`
			)
			.pipe(retry(1))
			.subscribe({
				next: (res) => {
					this.campaigns = res;
					if (this.campaigns.length)
						setTimeout(() => {
							this.currentUrl = this._router.url;
							this.#checkCampaigns();
						}, 1000);
				},
			});
	}

	setCampaignAction(id: number, isSkipped = false) {
		this.campaign.next(null);
		if (!id) return;
		this.campaigns = this.campaigns.filter((c) => c.id != id);
		setTimeout(() => this.#checkCampaigns(), 500);

		this._http
			.post(`${environment.apiBaseUrl}MarketingDisplays/Campaigns`, {
				id,
				action: isSkipped ? 0 : 1,
			})
			.subscribe();
	}
}
