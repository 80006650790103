import { createSelector } from '@ngrx/store';

import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { AppStateInterface } from '@app/core/store/app-state.interface';

export const appCategoryListSelector = (state: AppStateInterface): IPageState =>
	state.appCategoryList;

export const pageSelector = createSelector(
	appCategoryListSelector,
	(state: IPageState) => state.page
);

export const pageSizeSelector = createSelector(
	appCategoryListSelector,
	(state: IPageState) => state.pageSize
);

export const orderSelector = createSelector(
	appCategoryListSelector,
	(state: IPageState) => state.order
);

export const columnSelector = createSelector(
	appCategoryListSelector,
	(state: IPageState) => state.column
);

export const querySelector = createSelector(
	appCategoryListSelector,
	(state: IPageState) => state.query
);
