import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileInputComponent,
      multi: true,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() value: File | FileList;
  @Input() fileTypes = '*';
  @Input() isMultiple = false;
  @Input() showValue = true;
  @Input() readonly = false;
  @Input() placeholder = 'Select File';
  @Output() newFileList = new EventEmitter<File>();
  file: File | null | File[] = null;
  fileName: string | undefined | string[] = '';

  _onChange: Function;
  _onTouched: Function;

  @HostListener('change', ['$event.target.files']) emitFiles(event: any) {
    if (this.isMultiple) {
      const fileList = event;
      this.setMultipleFile(fileList);
      this.newFileList.emit(fileList as File);
    } else {
      const file = event && event.item(0);
      this.file = file;
      this.fileName = file?.name;
      this.newFileList.emit(file);
    }

    if (this._onChange) this._onChange(this.file);
    if (this._onTouched) this._onTouched();
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (this.isMultiple) {
      const fileuploader =
        this.el.nativeElement.querySelector('input[type=file]');
      this.renderer.setAttribute(fileuploader, 'multiple', '');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
		if (changes['value']) this.writeValue(this.value);
	}

  writeValue(value: File | FileList | string) {
    if (value instanceof File) {
      let val;

      if (this.isMultiple) {
        val = value as unknown as File[];
        this.setMultipleFile(val);
      } else {
        val = value as File;
        this.fileName = val.name;
        this.file = val;
      }
    } else if(value instanceof FileList) {
      if (this.isMultiple) this.setMultipleFile([...value]);
      else {
        const file = value && value.item(0);
        this.fileName = file?.name;
        this.file = file;
      }
    } else {
      this.fileName = '';
      this.host.nativeElement.value = '';
      this.file = null;
    }
  }

  registerOnChange(fn: Function) {
    this._onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.readonly = isDisabled;
  }

  private setMultipleFile(fileList: File[]) {
    if (fileList.length > 0) {
      let filenames: string[] = [];
      this.file = [...fileList];

      for (const file of fileList) {
        const _file = file as unknown as File;
        filenames.push(_file.name);
      }

      this.fileName = [...filenames];
    }
  }
}
