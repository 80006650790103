import { IKnowledgeBase } from '../kb-topic.interface';
import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  CUSTOM_KB_TOPICS_UPDATE = '[CustomKbTopics] Update',
  CUSTOM_GLOBAL_KB_TOPICS_UPDATE_SUCCESS = '[CustomKbTopics] Update Success',

}

export const customKbTopicsUpdateAction =
createAction(
  ActionTypes.CUSTOM_KB_TOPICS_UPDATE,
  props<Partial<IKnowledgeBase>>()
)

export const customKbTopicsUpdateSuccessAction =
createAction(
  ActionTypes.CUSTOM_GLOBAL_KB_TOPICS_UPDATE_SUCCESS
)
