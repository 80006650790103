import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IJWT } from '@app/core/store/jwt/jwt.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ImpersonateModalComponent } from './impersonate-modal.component';
import { ApiRoutes } from '@app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateModalService {
  constructor(private dialog: MatDialog, private _http: HttpClient) { }

  openDialog(companyId?: number): MatDialogRef<ImpersonateModalComponent>{
    return this.dialog.open(ImpersonateModalComponent,{
      width: '600px',
      height: '336.5px',
      disableClose: true,
      panelClass: 'my-dialog',
      autoFocus: false,
      data: companyId,
    })
  }

  startImpersonate(id: number): Observable<IJWT>{
    return this._http.post<IJWT>(`${environment.apiBaseUrl}${ApiRoutes.AUTH_START_IMPERSONATE}/${id}`,null);
  }

  endImpersonate(): Observable<IJWT>{
    return this._http.post<IJWT>(`${environment.apiBaseUrl}UserLogin/EndImpersonate`,null);
  }
}
