
<ng-container *ngIf="!isLoading; else loading">
  <div class="table-contain" *ngIf="dataSource && dataSource.data && dataSource.data.length > 0; else noData">
    <div class="table-contain">
      <table cdk-table [dataSource]="dataSource">

        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef> Name </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.name}} </td>
        </ng-container>


        <ng-container cdkColumnDef="comment">
          <th cdk-header-cell *cdkHeaderCellDef> Comment/s </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.comments}} </td>
        </ng-container>


        <ng-container cdkColumnDef="rating">
          <th cdk-header-cell *cdkHeaderCellDef style="width: 20%;line-height: 15px;"> Rating </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.rating}} </td>
        </ng-container>


        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns" class="heading-padding"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;" class="body-padding"></tr>
      </table>
    </div>
  </div>
</ng-container>


<ng-template #loading>
  <quest-loading></quest-loading>
</ng-template>

<ng-template #noData>
<quest-no-data [height]="300"></quest-no-data>
</ng-template>
