<div class="global-search-container" [class.opened]="isSearchOpened">
	<div class="global-search-contents">
		<search-input
			[formControl]="searchControl"
			[withClose]="searchControl.value && isSearchOpened ? true : false"
			(click)="toggleSearch(true)"
			placeholder="Search Quest"
			fontSize="medium"
			maxlength="100"
			>
		</search-input>

		<div *ngIf="isSearchOpened" class="global-search-results scroll-v2">
			<ng-container *ngIf="!searchResults.length; else hasResult">
				<div *ngIf="searchState == SearchState.searching" class="global-result-not-found global-result-searching font-b3">
					<mat-icon class="loading-v2" svgIcon="loading-01"></mat-icon>
					Searching for results...
				</div>
				<div *ngIf="searchState == SearchState.complete && searchControl.value" class="global-result-not-found font-b3">
					<h5 class="font-b1 font-w-600">Sorry, we couldn’t find anything for “{{searchControl.value}}”.</h5>
					You can try a different search or submit a general IT ticket below.
				</div>
				<div *ngIf="searchState == SearchState.complete || searchState == SearchState.typing" class="global-result-wrapper">
					<div class="global-result-group font-b3 font-w-700"><mat-icon svgIcon="list"></mat-icon>SUBMIT TICKET</div>
					<div class="global-search-result imp-disabled-op" [routerLink]="generalTicket" (click)="toggleSearch(false)">
						<h5 class="font-b1 font-w-600">General IT Ticket</h5>
						<p class="font-b2">If you are experiencing a problem with Quest</p>
					</div>
				</div>
			</ng-container>

			<ng-template #hasResult>
				<div *ngFor="let group of searchResults" class="global-result-wrapper">
					<div class="global-result-group font-b3 font-w-700">
						<mat-icon [svgIcon]="group.icon"></mat-icon>{{group.name}}
					</div>
					<div *ngFor="let item of group.items" class="global-search-result imp-disabled-op" (click)="viewItem(item, group)">
						<h5 class="font-b2 font-w-600">{{item.name}}</h5>
						<p class="font-b2">{{item.description}}</p>
					</div>
				</div>
				<span
				*ngIf="searchState == SearchState.complete && searchControl.value && isSearchOpened"
				class="font-b3 ss-complete">
					Search Complete!
				</span>
			</ng-template>
		</div>
	</div>

	<div class="global-search-backdrop" (click)="toggleSearch(false)"></div>
</div>