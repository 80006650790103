<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" class="row">
	<ng-container
		*ngTemplateOutlet="questionsTemp; context: { questions }"
	></ng-container>
	<button type="submit" style="display: none"></button>
</form>

<ng-template #questionsTemp let-questions="questions">
	<ng-container *ngFor="let question of questions; let i = index">
		<ng-container *ngIf="question.isRewst === false; else rewstQuestion">
			<ng-container *ngIf="question.isGraph === false; else graphQuestion">
				<ng-container
					*ngIf="
						this.form.controls['q' + $any(question).id] &&
						$any(question).questionText
					"
				>
					<ng-container
						*ngTemplateOutlet="questionTemp; context: { question, i }"
					></ng-container>
				</ng-container>
			</ng-container>
		</ng-container>

		<ng-template #rewstQuestion>
			<ng-container
				*ngIf="
					this.form.controls['q' + $any(question).id] &&
					$any(question).questionText
				"
			>
				<ng-container
					*ngTemplateOutlet="questionTempRewst; context: { question, i }"
				></ng-container>
			</ng-container>
		</ng-template>

		<ng-template #graphQuestion>
			<ng-container
				*ngIf="
					this.form.controls['q' + $any(question).id] &&
					$any(question).questionText
				"
			>
				<ng-container
					*ngTemplateOutlet="questionTempGraph; context: { question, i }"
				></ng-container>
			</ng-container>
		</ng-template>
	</ng-container>
</ng-template>

<ng-template #questionTemp let-question="question">
	<div class="col-md-12" [formGroup]="form">
		<div class="setup-wrap">
			<!-- <h4>{{question.questionText}}{{question.required ? '*' : ''}}</h4> -->
			<span class="font-b1 font-w-600">
				{{ question.questionText }}{{ question.required === 1 ? '*' : '' }}
			</span>
			<small *ngIf="question.subTitle" class="font-b2">
				{{ question.subTitle }}
			</small>

			<ng-container
				*ngIf="
					question.answerTypeId == formAnswerType.Textbox ||
					question.answerTypeId == formAnswerType.Datetime ||
					question.answerTypeId == formAnswerType.Date ||
					question.answerTypeId == formAnswerType.Email ||
					question.answerTypeId == formAnswerType.Number ||
					question.answerTypeId == formAnswerType.Time ||
					question.answerTypeId == formAnswerType.Url
				"
				class="cus-field"
			>
				<input
					[formControlName]="'q' + question.id"
					[type]="
						question.answerTypeId == formAnswerType.Textbox
							? 'text'
							: question.answerTypeId == formAnswerType.Datetime
							? 'datetime-local'
							: formAnswerType[question.answerTypeId]
					"
					errorTooltip
					[scrollEvent]="scrollEvent"
					[etControl]="form.get('q' + question.id)!"
					[etIsTouched]="form.get('q' + question.id)!.touched"
					[fieldName]="question.questionText"
					class="form-control font-b1"
				/>
			</ng-container>

			<div
				class="media-select"
				*ngIf="question.answerTypeId == formAnswerType.EmailDropdown"
			>
				<mat-form-field class="chip-list w-100 has-error" appearance="outline">
					<mat-chip-list #chipList aria-label="Related Tag selection">
						<mat-chip *ngFor="let tag of relatedTags" (removed)="remove(tag)">
							{{ tag }}
							<app-local-figma-icon-img
								matChipRemove
								[filename]="'x-white'"
								[hasSpaceOnRight]="false"
								class="h-auto"
							></app-local-figma-icon-img>
						</mat-chip>
						<input
							errorTooltip
							[etShow]="emailError"
							[etMessage]="emailValidateMessage"
							placeholder="Please select"
							[formControlName]="'q' + question.id"
							#relatedTagsInput
							[matAutocomplete]="auto"
							[matChipInputFor]="chipList"
							[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							(matChipInputTokenEnd)="add($event, question)"
							(keydown)="spaceEvent($event)"
							(keyup)="checkEmptyValue($event)"
							class="related-tag-input"
						/>
					</mat-chip-list>
					<mat-autocomplete
						#auto="matAutocomplete"
						(optionSelected)="selected($event, question)"
					>
						<mat-option
							*ngFor="let item of relatedTagList | async"
							[value]="item.id"
						>
							{{ item.email }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>

			<textarea
				*ngIf="question.answerTypeId == formAnswerType.Textarea"
				[formControlName]="'q' + question.id"
				errorTooltip
				[scrollEvent]="scrollEvent"
				[etControl]="form.get('q' + question.id)!"
				[etIsTouched]="form.get('q' + question.id)!.touched"
				[fieldName]="question.questionText"
				class="form-control text-sm full-width font-b1"
				rows="2"
			>
			</textarea>

			<ng-container
				*ngIf="
					question.answerTypeId == formAnswerType.Image ||
					question.answerTypeId == formAnswerType.File
				"
			>
				<file-input
					[formControlName]="'q' + question.id"
					[isMultiple]="question.answerValidationList.length > 0"
					[fileTypes]="
						question.answerTypeId == formAnswerType.Image ? 'image/*' : '*'
					"
					fileDragDrop
					(fileDropped)="fileChange($event, question.id)"
					(newFileList)="fileChange($event, question.id)"
					errorTooltip
					[etControl]="form.get('q' + question.id)!"
					[etIsTouched]="form.get('q' + question.id)!.touched"
					[fieldName]="question.questionText"
					class="custom-file-upload mb-2 font-b1"
				>
				</file-input>
				<li
					*ngFor="let file of attachFiles; let i = index"
					class="mt-2 d-flex"
					style="display: flex !important; align-items: center"
				>
					<div class="attachment">
						<app-figma-icon-img
							[iconName]="'x-close'"
							(click)="onRemoveFile(i, question.id)"
							[hasSpaceOnRight]="false"
							[width]="16"
							[height]="16"
						>
						</app-figma-icon-img>
						<app-figma-icon-img
							[iconName]="imageService.getExt(file)"
							[hasSpaceOnRight]="false"
							[width]="16"
							[height]="16"
						>
						</app-figma-icon-img>
						<a (click)="openFile(file)" target="_blank" class="attach-file">
							{{ file.name }}
						</a>
					</div>
					<!-- <div
						class="col-md-3 col-sm-4 text-right"
						style="
							display: flex !important;
							align-items: center;
							justify-content: center;
						"
					>
						<button
							type="button"
							title="Remove File"
							class="btn btn-danger btn-sm"
							style="margin: 5px 0"
							(click)="onRemoveFile(i, question.id)"
						>

						</button>
					</div> -->
				</li>
			</ng-container>

			<!-- <file-input [formControlName]="'q'+question.id" *ngIf="question.answerTypeId == formAnswerType.File" [isMultiple]="question.answerValidationList.length > 0" fileDragDrop (fileDropped)="fileChange($event, question.id)" class="custom-file-upload"></file-input> -->

			<select
				*ngIf="question.answerTypeId == formAnswerType.Dropdown"
				[formControlName]="'q' + question.id"
				errorTooltip
				[etControl]="form.get('q' + question.id)!"
				[etIsTouched]="form.get('q' + question.id)!.touched"
				[fieldName]="question.questionText"
				class="form-control font-b1"
				[ngClass]="{
					'is-invalid':
						form.get('q' + question.id)!.touched &&
						!!form.get('q' + question.id)!.errors
				}"
			>
				<option
					*ngFor="let option of question.answerList"
					[value]="$any(option).id"
				>
					{{ $any(option).label }}
				</option>
			</select>

			<ng-container
				*ngIf="question.answerTypeId == formAnswerType.MultiSelectDropdown"
				class="cus-field cat-select"
			>
				<multi-select-input
					[formControlName]="'q' + question.id"
					[options]="question.answerList"
					[optionKeys]="{ value: 'id', label: 'label' }"
					[maxSelectionCount]="
						question.answerValidationList[0]?.maxAllowSelected
					"
					[allowSelectAll]="false"
					errorTooltip
					[etControl]="form.get('q' + question.id)!"
					[etIsTouched]="form.get('q' + question.id)!.touched"
					[fieldName]="question.questionText"
					class="form-control h-100 font-b1"
				>
				</multi-select-input>
			</ng-container>

			<div *ngIf="question.answerTypeId == formAnswerType.Checkbox">
				<div
					errorTooltip
					[etControl]="form.get('q' + question.id)!"
					[etIsTouched]="form.get('q' + question.id)!.touched"
					[fieldName]="question.questionText"
					class="option-container"
				>
					<div
						*ngFor="let option of question.answerList"
						class="option-wrapper"
					>
						<checkbox-input
							(change)="
								checkBoxToggled(
									$event,
									question,
									$any(option).id || $any(option).tempId!
								)
							"
							[disabled]="
								form.get('q' + question.id)!.value?.length >=
									getMaxSelection(question) &&
								!form
									.get('q' + question.id)!
									.value?.includes($any(option).id || $any(option).tempId)
							"
						>
							<small class="font-b1">{{ $any(option).label }}</small>
						</checkbox-input>
					</div>
				</div>
			</div>

			<div *ngIf="question.answerTypeId == formAnswerType.RadioButton">
				<div
					errorTooltip
					[etControl]="form.get('q' + question.id)!"
					[etIsTouched]="form.get('q' + question.id)!.touched"
					[etPosition]="'bottom'"
					[fieldName]="question.questionText"
					class="option-container"
				>
					<radio-input
						*ngFor="let option of question.answerList"
						[formControlName]="'q' + question.id"
						[name]="'q' + question.id"
						[value]="$any(option).id || $any(option).tempId"
						[label]="$any(option).label"
					></radio-input>
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngIf="question.subQuestions?.length">
		<ng-container
			*ngTemplateOutlet="
				questionsTemp;
				context: { questions: question.subQuestions }
			"
		></ng-container>
	</ng-container>
</ng-template>

<ng-template #questionTempGraph let-question="question" let-i="i">
	<div class="col-md-12" [formGroup]="form">
		<ng-container *ngIf="question.isRewstDependentQuestion === false">
			<div class="setup-wrap">
				<h6 class="text-sm">
					{{ question.questionText }}{{ question.required ? '*' : '' }}
				</h6>
				<p *ngIf="question.subTitle" class="text-sm">{{ question.subTitle }}</p>
				<ng-container
					*ngIf="question.answerTypeId == formAnswerType.Dropdown"
					class="cus-field cat-select"
				>
					<div style="display: flex">
						<select-input
							[formControlName]="'q' + question.id"
							[options]="question.answerList"
							[optionKeys]="{
								value: question.graphDataSubKey,
								label: question.graphDataSubKey
							}"
							[etControl]="form.get('q' + question.id)!"
							[etIsTouched]="form.get('q' + question.id)!.touched"
							[fieldName]="question.questionText"
							inputClass="form-control"
							errorTooltip
						>
						</select-input>
						<div class="btn-container">
							<button
								class="button"
								[id]="'buttonSeq' + question.id"
								type="button"
								(click)="loadRewst(question, question.id)"
								#buttonSeq
							>
								<div class="overlay">
									<div class="cv-spinner">
										<span class="spinner"><div class="spinner-1"></div></span>
									</div>
								</div>
							</button>
							<div class="tooltip-btn">
								<h6>Refetch options from integration</h6>
								<p>
									WARNING: Some integrations may be very slow to return data
								</p>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container
					*ngIf="question.answerTypeId == formAnswerType.MultiSelectDropdown"
					class="cus-field cat-select"
				>
					<div style="display: flex">
						<multi-select-input
							[formControlName]="'q' + question.id"
							[options]="question.answerList"
							[optionKeys]="{
								value: question.graphDataSubKey,
								label: question.graphDataSubKey
							}"
							[maxSelectionCount]="
								question.answerValidationList[0]?.maxAllowSelected
							"
							[allowSelectAll]="false"
							errorTooltip
							[etControl]="form.get('q' + question.id)!"
							[etIsTouched]="form.get('q' + question.id)!.touched"
							[fieldName]="question.questionText"
							class="form-control h-100"
						>
						</multi-select-input>
						<div class="btn-container">
							<button
								class="button"
								[id]="'buttonSeq' + question.id"
								type="button"
								(click)="loadRewst(question, question.id)"
								#buttonSeq
							>
								<div class="overlay">
									<div class="cv-spinner">
										<span class="spinner"><div class="spinner-1"></div></span>
									</div>
								</div>
							</button>
							<div class="tooltip-btn">
								<h6>Refetch options from integration</h6>
								<p>
									WARNING: Some integrations may be very slow to return data
								</p>
							</div>
						</div>
					</div>
				</ng-container>

				<div *ngIf="question.answerTypeId == formAnswerType.Checkbox">
					<div
						errorTooltip
						[etControl]="form.get('q' + question.id)!"
						[etIsTouched]="form.get('q' + question.id)!.touched"
						[fieldName]="question.questionText"
						class="option-container"
					>
						<div
							*ngFor="let option of question.answerList"
							class="option-wrapper"
						>
							<checkbox-input
								(change)="
									checkBoxToggled(
										$event,
										question,
										$any(option).id || $any(option).tempId!
									)
								"
								[disabled]="
									form.get('q' + question.id)!.value?.length >=
										getMaxSelection(question) &&
									!form
										.get('q' + question.id)!
										.value?.includes($any(option).id || $any(option).tempId)
								"
							>
								<small class="font-b1">{{
									$any(option).rewstDataTextField
								}}</small>
							</checkbox-input>
						</div>
					</div>
				</div>

				<div *ngIf="question.answerTypeId == formAnswerType.RadioButton">
					<div
						errorTooltip
						[etControl]="form.get('q' + question.id)!"
						[etIsTouched]="form.get('q' + question.id)!.touched"
						[fieldName]="question.questionText"
						class="option-container"
					>
						<radio-input
							*ngFor="let option of question.answerList"
							[formControlName]="'q' + question.id"
							[name]="'q' + question.id"
							[value]="$any(option).id || $any(option).tempId"
							[label]="$any(option).rewstDataTextField"
						></radio-input>
					</div>
				</div>
				<p
					class="text-error"
					style="color: red !important"
					*ngIf="question.error"
				>
					Error: {{ question.error }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngIf="question.isRewstDependentQuestion === true">
			<div class="setup-wrap">
				<h6 class="text-sm">
					{{ question.questionText }}{{ question.required ? '*' : '' }}
				</h6>
				<p *ngIf="question.subTitle" class="text-sm">{{ question.subTitle }}</p>
				<ng-container
					*ngIf="question.answerTypeId == formAnswerType.Dropdown"
					class="cus-field cat-select"
				>
					<div style="display: flex">
						<select-input
							[formControlName]="'q' + question.id"
							[options]="question.answerList"
							[optionKeys]="{
								value: question.graphDataSubKey,
								label: question.graphDataSubKey
							}"
							errorTooltip
							[etControl]="form.get('q' + question.id)!"
							[etIsTouched]="form.get('q' + question.id)!.touched"
							[fieldName]="question.questionText"
							inputClass="form-control"
						>
						</select-input>
						<div class="btn-container">
							<button
								class="button"
								[id]="'buttonSeq' + question.id"
								type="button"
								(click)="loadRewst(question, question.id)"
								#buttonSeq
							>
								<div class="overlay">
									<div class="cv-spinner">
										<span class="spinner"><div class="spinner-1"></div></span>
									</div>
								</div>
							</button>
							<div class="tooltip-btn">
								<h6>Refetch options from integration</h6>
								<p>
									WARNING: Some integrations may be very slow to return data
								</p>
							</div>
						</div>
					</div>
				</ng-container>
				<p
					class="text-error"
					style="color: red !important"
					*ngIf="question.error"
				>
					Error: {{ question.error }}
				</p>
			</div>
		</ng-container>
	</div>
	<ng-container *ngIf="question.subQuestions?.length">
		<ng-container
			*ngTemplateOutlet="
				questionsTemp;
				context: { questions: question.subQuestions }
			"
		></ng-container>
	</ng-container>
</ng-template>

<ng-template #questionTempRewst let-question="question" let-i="i">
	<div class="col-md-12" [formGroup]="form">
		<ng-container *ngIf="question.isRewstDependentQuestion === false">
			<div class="setup-wrap">
				<h6 class="text-sm">
					{{ question.questionText }}{{ question.required ? '*' : '' }}
				</h6>
				<p *ngIf="question.subTitle" class="text-sm">{{ question.subTitle }}</p>
				<ng-container
					*ngIf="question.answerTypeId == formAnswerType.Dropdown"
					class="cus-field cat-select"
				>
					<div style="display: flex">
						<select-input
							[formControlName]="'q' + question.id"
							[options]="question.answerList"
							[optionKeys]="{
								value: question.rewstDataValueField,
								label: question.rewstDataTextField
							}"
							[etControl]="form.get('q' + question.id)!"
							[etIsTouched]="form.get('q' + question.id)!.touched"
							[fieldName]="question.questionText"
							inputClass="form-control"
							errorTooltip
						>
						</select-input>
						<div class="btn-container">
							<button
								class="button"
								[id]="'buttonSeq' + question.id"
								type="button"
								(click)="loadRewst(question, question.id)"
								#buttonSeq
							>
								<div class="overlay">
									<div class="cv-spinner">
										<span class="spinner"><div class="spinner-1"></div></span>
									</div>
								</div>
							</button>
							<div class="tooltip-btn">
								<h6>Refetch options from integration</h6>
								<p>
									WARNING: Some integrations may be very slow to return data
								</p>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container
					*ngIf="question.answerTypeId == formAnswerType.MultiSelectDropdown"
					class="cus-field cat-select"
				>
					<div style="display: flex">
						<multi-select-input
							[formControlName]="'q' + question.id"
							[options]="question.answerList"
							[optionKeys]="{
								value: question.rewstDataValueField,
								label: question.rewstDataTextField
							}"
							[maxSelectionCount]="
								question.answerValidationList[0]?.maxAllowSelected
							"
							[allowSelectAll]="false"
							errorTooltip
							[etControl]="form.get('q' + question.id)!"
							[etIsTouched]="form.get('q' + question.id)!.touched"
							[fieldName]="question.questionText"
							class="form-control h-100"
						>
						</multi-select-input>
						<div class="btn-container">
							<button
								class="button"
								[id]="'buttonSeq' + question.id"
								type="button"
								(click)="loadRewst(question, question.id)"
								#buttonSeq
							>
								<div class="overlay">
									<div class="cv-spinner">
										<span class="spinner"><div class="spinner-1"></div></span>
									</div>
								</div>
							</button>
							<div class="tooltip-btn">
								<h6>Refetch options from integration</h6>
								<p>
									WARNING: Some integrations may be very slow to return data
								</p>
							</div>
						</div>
					</div>
				</ng-container>

				<div *ngIf="question.answerTypeId == formAnswerType.Checkbox">
					<div
						errorTooltip
						[etControl]="form.get('q' + question.id)!"
						[etIsTouched]="form.get('q' + question.id)!.touched"
						[fieldName]="question.questionText"
						class="option-container"
					>
						<div
							*ngFor="let option of question.answerList"
							class="option-wrapper"
						>
							<checkbox-input
								(change)="
									checkBoxToggled(
										$event,
										question,
										$any(option).id || $any(option).tempId!
									)
								"
								[disabled]="
									form.get('q' + question.id)!.value?.length >=
										getMaxSelection(question) &&
									!form
										.get('q' + question.id)!
										.value?.includes($any(option).id || $any(option).tempId)
								"
							>
								<small class="font-b1">{{
									$any(option).rewstDataTextField
								}}</small>
							</checkbox-input>
						</div>
					</div>
				</div>

				<div *ngIf="question.answerTypeId == formAnswerType.RadioButton">
					<div
						errorTooltip
						[etControl]="form.get('q' + question.id)!"
						[etIsTouched]="form.get('q' + question.id)!.touched"
						[fieldName]="question.questionText"
						class="option-container"
					>
						<radio-input
							*ngFor="let option of question.answerList"
							[formControlName]="'q' + question.id"
							[name]="'q' + question.id"
							[value]="$any(option).id || $any(option).tempId"
							[label]="$any(option).rewstDataTextField"
						></radio-input>
					</div>
				</div>
				<p
					class="text-error"
					style="color: red !important"
					*ngIf="question.error"
				>
					Error: {{ question.error }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngIf="question.isRewstDependentQuestion === true">
			<div class="setup-wrap">
				<h6 class="text-sm">
					{{ question.questionText }}{{ question.required ? '*' : '' }}
				</h6>
				<p *ngIf="question.subTitle" class="text-sm">{{ question.subTitle }}</p>
				<ng-container
					*ngIf="
						question.answerTypeId == formAnswerType.Dropdown ||
						question.answerTypeId == formAnswerType.DropdownWithInput
					"
					class="cus-field cat-select"
				>
					<div style="display: flex">
						<select-input
							[formControlName]="'q' + question.id"
							[options]="question.answerList"
							[optionKeys]="{
								value: question.rewstDataValueField,
								label: question.rewstDataTextField
							}"
							errorTooltip
							[etControl]="form.get('q' + question.id)!"
							[etIsTouched]="form.get('q' + question.id)!.touched"
							[fieldName]="question.questionText"
							[canCreateOption]="
								question.answerTypeId == formAnswerType.Dropdown ? false : true
							"
							inputClass="form-control"
						>
						</select-input>
						<div class="btn-container">
							<button
								class="button"
								[id]="'buttonSeq' + question.id"
								type="button"
								(click)="loadRewst(question, question.id)"
								#buttonSeq
							>
								<div class="overlay">
									<div class="cv-spinner">
										<span class="spinner"><div class="spinner-1"></div></span>
									</div>
								</div>
							</button>
							<div class="tooltip-btn">
								<h6>Refetch options from integration</h6>
								<p>
									WARNING: Some integrations may be very slow to return data
								</p>
							</div>
						</div>
					</div>
				</ng-container>
				<p
					class="text-error"
					style="color: red !important"
					*ngIf="question.error"
				>
					Error: {{ question.error }}
				</p>
			</div>
		</ng-container>
	</div>
	<ng-container *ngIf="question.subQuestions?.length">
		<ng-container
			*ngTemplateOutlet="
				questionsTemp;
				context: { questions: question.subQuestions }
			"
		></ng-container>
	</ng-container>
</ng-template>
