<div class="container-modal">
  <div class="heading">
    <div class="item-1">
      <h4>{{ this.data.templateData!['title'] }}
        &nbsp;
        <span class="tooltip-container">
          <img [src]="'../../../../../../../assets/icons/info-circle.svg'" />
          <modal-tooltip 
          [bgColor]="'#293855'"
          [message]="
            this.data.templateData!['message']
              ? this.data.templateData!['message']
              : message
          "></modal-tooltip>
        </span>
      </h4>
    </div>
    <div class="item-2">
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal(false)"
      >
        <span aria-hidden="true">
          <app-figma-icon-img
            [iconName]="'x'"
            [hasSpaceOnRight]="false"
          ></app-figma-icon-img>
        </span>
      </button>
    </div>
  </div>
  <div class="heading-2">
    <div class="item-1">
      <h3>Last 30 Days</h3>
    </div>
    <div class="item-2">
      <button class="btn-dashboard" (click)="download()">
        <app-figma-icon-img
        [iconName]="'download-02'"
        [width]="20"
        [height]="20"
        [hasSpaceOnRight]="false"
        [isForButton]="false"
      >
      </app-figma-icon-img>
        Download
    </button>
    </div>
  </div>

  <div class="modal-content">
    <app-new-table-shared
      [searchFilters]="searchFilters"
      [loadingText]="'Fetching Recent Security Tickets...'"
	></app-new-table-shared>
  </div>
</div>
