import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { BaseUserComponent } from '@app/shared/components/user/base-user.component';

@Component({
  selector: 'app-permissions-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent extends BaseUserComponent {
  chosenCompanyId: number; //chosen company
  chosenGroupIds: number[]; //chosen GroupIds
  chosenUserIds: number[]; //chosen User Ids

  @Input() hasUserLevelPermission: boolean = true;
  @Input() permissionData: number[]; //get request
  @Input() companyIds: number[] = []; //list of company Ids available
  @Input() isEnabledInputCompany: boolean; //if company input is disabled
  @Input() selectedCompany: number;
  @Input() showButtons: boolean = false;
  @Input() isSaveUsersDisabled: boolean = false
  @Output() cId: EventEmitter<number> = new EventEmitter<number>(); // list of companyIds
  @Output() uIds: EventEmitter<number[]> = new EventEmitter<number[]>(); //list of userIds

  @Output() sendSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public override _userService: UserService) {
    super(_userService);
  }

  onSendSave() {
    this.sendSave.emit(true);
  }

  onCancel() {
    this.sendCancel.emit(true);
  }

  getCompanyId(id: number) {
    this.chosenCompanyId = id;
    this.cId.emit(this.chosenCompanyId);
  }

  getGroupIds(data: number[]) {
    this.chosenGroupIds = data;
  }

  getUserIds(data: number[]) {
    this.chosenUserIds = data;
    this.uIds.emit(this.chosenUserIds);
  }
}
