<div class="row">
	<div class="col-12">
		<div class="card">
			<div class="card-header pb-0">
				<div class="d-lg-flex">
					<div>
						<h5 class="mb-0">
							{{ form.get('id')?.value ? 'Edit' : 'Add New' }} Application
							Catalog
						</h5>
					</div>

					<app-secondary-button
						[isBackButton]="true"
						[link]="stateAction === 'add' ? '../' : '../../'"
					></app-secondary-button>
				</div>
			</div>

			<div class="card-body px-0 pb-0 pt-0">
				<div class="categories-form">
					<form [formGroup]="form" (ngSubmit)="onSubmit()">
						<div class="categories-form-label">
							<label>Category*</label>
							<select-input
								formControlName="categoryId"
								[options]="categories"
								[optionKeys]="{ value: 'id', label: 'name' }"
								inputClass="form-control"
								errorTooltip
								[etControl]="form.get('categoryId')!"
								[etIsTouched]="form.get('categoryId')!.touched"
								[etShow]="
									form.get('categoryId')!.touched &&
									!!form.get('categoryId')!.errors
								"
								[fieldName]="'Category'"
							>
							</select-input>
						</div>
						<div class="categories-form-label">
							<label>Name*</label>
							<input
								formControlName="name"
								type="text"
								class="form-control"
								placeholder=""
								maxlength="100"
								errorTooltip
								[etControl]="form.get('name')!"
								[etIsTouched]="form.get('name')!.touched"
								[etShow]="
									form.get('name')!.touched && !!form.get('name')!.errors
								"
								[fieldName]="'Name'"
							/>
						</div>
						<div class="categories-form-label">
							<label>Icon*</label>
							<a
								*ngIf="iconSrc"
								class="bin visiblity-icon imp-disabled-op delete-icon"
								[tooltipContentV2]="{ message: 'Remove Icon' }"
								href="javascript:void(0)"
								(click)="deleteIcon()"
							>
								<app-local-figma-icon-img
									[filename]="'x-white'"
									[hasSpaceOnRight]="false"
								></app-local-figma-icon-img>
							</a>
							<img
								image-error-detection
								[component]="'app-catalog-add-edit.component.html'"
								*ngIf="iconSrc"
								class="img-fluid me-3 icon-small thumbnail"
								[src]="iconSrc"
							/>
							<file-input
								[ngClass]="{
									error: form.get('file')!.touched && !!form.get('file')!.errors
								}"
								formControlName="file"
								fileTypes="image/*"
								class="spl-fileupload"
								[class.with-icon]="iconSrc"
								errorTooltip
								[etControl]="form.get('file')!"
								[etIsTouched]="form.get('file')!.touched"
								[etShow]="
									form.get('file')!.touched && !!form.get('file')!.errors
								"
								[fieldName]="'Icon'"
								(newFileList)="fileChange($event)"
							></file-input>
						</div>

						<div class="categories-form-label">
							<label>URL*</label>
							<input
								formControlName="url"
								type="url"
								class="form-control"
								placeholder=""
								maxlength="100"
								errorTooltip
								[etControl]="form.get('url')!"
								[etIsTouched]="form.get('iconURL')!.touched"
								[etShow]="
									form.get('iconURL')!.touched && !!form.get('iconURL')!.errors
								"
								[fieldName]="'URL'"
							/>
						</div>
						<div class="categories-form-label">
							<label>Description*</label>
							<textarea
								formControlName="description"
								class="form-control"
								placeholder=""
								rows="3"
								maxlength="150"
								errorTooltip
								[etControl]="form.get('description')!"
								[etIsTouched]="form.get('description')!.touched"
								[etShow]="
									form.get('description')!.touched &&
									!!form.get('description')!.errors
								"
								[fieldName]="'Description'"
							></textarea>
						</div>

						<div class="submit-buttons q-d-flex q-jc-end q-g-16">
							<button type="submit" class="btn-2 btn-2-primary imp-disabled-op">
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>

<app-audit-trail
	*ngIf="form.get('id')?.value as val"
	[url]="historyUrl"
	history_category="default"
	[id]="val"
></app-audit-trail>
