<section class="invoice-billing-sec">
	<div class="container-fluid">
		<div class="invoice-inner">
			<div class="row">
				<div class="col-lg-12">
					<div class="invoice-right-block">
						<div class="invoice-right-inner">
							<div class="invoice-title">
								<h4>Quest Pod</h4>
							</div>
							<div class="invoice-table-main-area">
								<div class="editinvoice-billing-table">
									<table>
										<thead>
											<tr>
												<th>Permissions</th>
												<th colspan="2">Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Set User as Client Admin</td>
												<td>
													<label class="switch">
														<input
															type="checkbox"
															[(ngModel)]="isClientAdmin"
															(click)="setAsClientAdmin()"
															checked=""
															id="togBtn"
														/>
														<div class="slider round">
															<span class="on">On</span>
															<span class="off">Off</span>
														</div>
													</label>
												</td>
												<td>
													<a
														href="javascript:void(0)"
														class="editinvoice-permission-btn d-flex align-items-center"
														(click)="openPermissionDialog()"
													>
														<app-figma-icon-img
															[iconName]="'edit-02'"
															[isForButton]="true"
														></app-figma-icon-img>
														Edit Invoice Permissions
													</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div class="editinvoice-permission-popup">
									<div class="editinvoice-popup-overlay"></div>
									<div class="editinvoice-popup-inner">
										<div class="editinvoice-popup-content">
											<a
												href="javascript:void(0)"
												class="editinvoice-popup-close"
											>
												<app-figma-icon-img
													[iconName]="'x'"
                          [hasSpaceOnRight]="false"
												></app-figma-icon-img>
											</a>
											<div class="editinvoice-popup-head">
												<h4>List of Invoice Permissions</h4>
											</div>
											<div class="editinvoice-popup-con-inner">
												<div class="editinvoice-popup-table">
													<table>
														<thead>
															<tr>
																<th>Permissions</th>
																<th>Actions</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Payment of Invoice</td>
																<td>
																	<label class="switch">
																		<input
																			type="checkbox"
																			checked=""
																			id="togBtn"
																		/>
																		<div class="slider round">
																			<span class="on">On</span>
																			<span class="off">Off</span>
																		</div>
																	</label>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
