import { GoogleLoginProvider, MicrosoftLoginProvider } from "@abacritt/angularx-social-login"
import { KeyVaultService } from "@app/core/services/key-vault.service"

export function keyvaultConfig(k: KeyVaultService){
  return k.getClient().toPromise().then(v=>{
    return {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(v.googleAuthClientId, {
            oneTapEnabled: false,
            scopes: ['email', 'profile', 'openid'],
          }),
        },
        {
          id: MicrosoftLoginProvider.PROVIDER_ID,
          provider: new MicrosoftLoginProvider(v.azureADClientId),
        },
      ],
    }
  })
}
