<div class="acronis-backup">
  <ng-container *ngIf="!isStillLoading; else loading">
    <ng-container *ngIf="(backupAllocation$ | async) as allocs; else empty">
      <ng-container *ngIf="allocs.length > 0; else empty">
        <ng-container *ngTemplateOutlet="allocation; context: {allocs: allocs}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- templates-->

<ng-template #allocation let-allocs="allocs">
  <div class="backup-item" *ngFor="let alloc of allocs">
    <span class="backup-header font-weight-normal text-xs">
      <ng-container *ngIf="isShow(alloc.allocation)">
        <i [ngStyle]="getStatus(alloc.result)"></i>
      </ng-container>
      {{alloc.allocation}}
    </span>
    <span class="backup-details font-weight-normal text-xs">{{alloc.result}}</span>
  </div>
</ng-template>

<ng-template #empty>
  <span class="font-weight-normal text-xs no-records">No records found.</span>
</ng-template>

<ng-template #loading>
	<widget-loading></widget-loading>
</ng-template>