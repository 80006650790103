import { Component, ElementRef, EventEmitter, forwardRef, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() id = '';
  @Input() label = '';
  @Input() isError: Boolean = false;
  @Input() type = 'text';
  @Input() min: string | number | Date;
  @Input() max: string | number | Date;
  @Input() inputClass = '';
  @Input() errors: Array<String>;

  @Output() changeDate: EventEmitter<any> = new EventEmitter<any>();

  disabled = false;
  inputValue: any = null;

  //The internal data model
  private inner_value: any = null;

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;


  get value(): any {
    return this.inner_value;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.inner_value) {
      this.inner_value = v;
      this.onChangeCallback(moment(this.inner_value).format('yyyy-MM-DD'));
    }
  }

  constructor(private elementRef: ElementRef) {}

  onChangeDate(): void {
    this.changeDate.emit(this.inner_value);
  }

  onClearDate(): void {
    this.inner_value = '';
    this.onChangeCallback(null);
    this.onChangeDate();
  }

  onToday(): void {
    const currentDate = new Date(moment().format('M/D/YYYY'));
    this.inner_value = currentDate;
    this.onChangeCallback(moment(this.inner_value).format('yyyy-MM-DD'));
    this.onChangeDate();
  }

  onApply(): void {
    if(!this.value) {
      this.onToday();
    } else {
      this.onChangeDate();
    }
  }


  onCloseCalendar(event: any): void {
    this.onTouchedCallback();
  }

  //Set touched on blur
  onBlur(): void {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: string): void {
    if (value !== this.inner_value) {
      this.inner_value =  new Date(moment(value).format('MM-DD-yyyy')); // changed from yyyy-MM-DD but was causing error
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
