import { TemplateRef } from "@angular/core";
import { Observable } from "rxjs";

export interface WidgetCommonProperty{
    id:number,
    title?: string,
    name?: string,
    icon?: string,
    description: string,
}

export interface WidgetList extends WidgetCommonProperty{
    selected: boolean,
}

export interface WidgetListWithCategory {
    id: number,
    name: string,
    cards: Array<WidgetList>
}

export interface WidgetCardList extends WidgetCommonProperty{
    cards: Array<WidgetList>;
    selected: boolean;
    contentTypeId: 1|2|3 // component | internal | external
    content: string,
    contentId:number,
    icon: string,
    width: string,
    height?: string,
    displayOrder:number,
    isStatic:boolean,
}

export interface DynamicComponentList{
    componentId: number,
    widgetCardListId: number,
    component: any, // replace this with generic inteface for component widget
}

export interface ComponentWidget extends WidgetCardList{
    component: any, // replace this with generic inteface for component widget
    size?: number,
    resizePosition?: any,
}

/* export interface DashboardCardList extends WidgetCommonProperty{ // used in dashboard card list page
    contentTypeId: number,
    content:string,
    contentId: number,
    isActive: boolean,
    icon: string,
    width: string,
    roleId: Array<number>
}
 */
export interface DashboardCard extends WidgetCommonProperty{
    contentTypeId: number,
    contentTypeName: string,
    contentId: number,
    isActive: boolean
    icon: string,
    width: string,
    height?:string,
    roleId: Array<number>
}

export interface DashboardCategoryDropdown {
    id: number,
    name: string
}

/* export interface Knowbe4Training{
    id:number,
    userId: number,
    moduleId: number,
    enrollmentDate: Date,
    status: string
} */

export interface KB4Training{
    id: number,
    firstName: string,
    lastName: string,
    enrollmentDate: Date | string,
    knowBe4Link: string,
    managerFirstName: string | null,
    managerLastName: string | null,
    moduleId: number,
    moduleName: string,
    status: string,
    userId: number
}

export interface KB4Users
{
    id: number,
    firstName: string,
    lastName: string,
    managerFirstName: string,
    managerLastName: string,
    trainingList: Array<KB4TrainingList>
}

export interface KB4TrainingList{
    enrollmentDate: Date,
    id: number,
    moduleId: number,
    moduleName: string
}

export interface ITConfiguration{
    id: number,
    name: string,
    userId: number,
    contact:  string,
    companyId: number,
    company: string | null,
    operationSystemId: number,
    locationId: number,
    location: string,
    configurationTypeId: number,
    type: string,
    configurationStatusId: number,
    status: string,
    primaryIP: string,
    serialNo: string,
    expireDate: Date | string | null
}

export interface ServiceHealthStatus{
    serviceId: string,
    serviceName: string,
    serviceStatus: string,
    issueId: string,
    issueTitle: string,
    issueStatus: string,
    issueType: string,
    startDate: Date,
    endDate: Date,
    isResolved: boolean
}

export interface TablePage{
    currentPage: number,
    data: ITConfiguration[],
    pageSize: number,
    totalCount: number
}

export interface GraphApi {
    controlCategoryScore: Array<CategoryScore>, // to place here
    currentScore: number,
    id: string,
    maxScore: number,
    scoreDate:Date,
    secureScore: number,
    securityScoreHistory: Array<ScoreHistory>, // to place here
    tenantId: string,
    tenantName: string,
}

export interface CategoryScore{
    currentScore: number,
    id: number,
    maxScore: number,
    name: string,
    secureScore:number,
}

export interface ScoreHistory{
    currentScore: number,
    id: string,
    maxScore: number,
    scoreDate: Date | string,
    secureScore: number,
}

export interface CategoryScoreList{
    id: number,
    rank: number,
    category: string,
    service: string,
    actionUrl: string,
    currentScore: number,
    maxScore: number,
    secureScore: number,
}

export interface OnSiteTicket {
    ticketId: number,
    title: string,
    personName: string,
    dateStart: any,
    dateEnd: any
}

export interface OfflineSite{
    id: number,
    name: string,
    site: string,
    status: string,
    type: string,
}

export interface WidgetContainer {
    componentId: number,
}

/* export interface MSTeams{
    messageCount: number,
    callCount: number,
    meetingCount: number,
    days: number,
    lastUpdate: string,
}
 */
export interface MSTeams{
    id: number,
    fullName: string,
    messageCount: number,
    callCount: number,
    meetingCount: number,
    days: number,
    lastUpdated: string,
}

export interface MSTeamsAdmin{
    messageCount: number,
    callCount: number,
    meetingCount: number,
    days: number,
    lastUpdated: string,
    userId: string,
    firstName: string,
    lastName: string,
    companyId: string
}

export class WidgetDynamicTable {
    data: any[];
    columns: {
      text: string,
      matColumnDef: string
    }[];
    rowClick?: (arg1:any)=>any | null;
    noClick: ()=>void;
    noRecordMessage: string;
    seeMoreLink: string | null;
    displayedColumns:string[];
}

export class WidgetDynamicTable2 {
    source: any[];
    columns: TableDynamicColumns[];
    rowClick?: (arg1:any)=>any | null;
    noClick?: ()=>void;
    noRecordMessage: string;
    seeMoreLink?: string | null; //to remove
    seeMoreClick?: ()=>void; // to remove
    seeMore?: SeeMore;
    displayedColumns:string[];
    rowClickable?: boolean;
    isMultiTemplateRows?: boolean;
    multiTemplateRows?:TableMultiTemplateRows
}

export interface TableMultiTemplateRows{
    expandedTd: TemplateRef<unknown>,
    columns: {
        text: string,
        column: string,
    }[],
}

export interface SeeMore{
    link: string,
    state: any,
};
export interface TableDynamicColumns {
    text: string,
    matColumnDef: string,
    style?: {
        th?: any,
        td?: any,
    },
    class?:{
        th?:any,
        td?:any,
    }
    width?:number,
    isStatusBadge?: boolean,
    badgeColor?: string,
    isStatusCheckMark?: boolean,
    statusType?: 'default' | 'badge' | 'check-mark',
    isOpenTicket?: boolean
}

export interface OneDriveUsage{
    id:number,
    fullName: string,
    fileCount: number,
    storageUsed: string,
    allocatedStorage: string,
    lastUpdated: Date
}

export interface MailboxUsage{
    id: number,
    fullName: string,
    itemCount: number,
    storageUsed: string,
    allocatedStorage: string,
    lastUpdated: Date,
}

export interface AcronisBackupStatus {
    items: AcronisBackupStatusItems[],
    page: {
        number: number,
        size: number,
        totalCount: number,
    }
}

export interface AcronisBackupStatusItems{
    companyId: number,
    deviceName: string,
    status: string,
    lastSuccessTime: Date | string,
    lastRunTime: Date | string,
    nextRunTime: Date | string,
}

export interface AcronisBackupAllocation{
    allocation:string,
    count: number,
    usage: string | null,
    size: string | null,
    result: string,
}

export interface AcronisCloudBackup{
    name: string,
    tenant_id: number,
    uiName: string | null,
    usage_name: string,
    value: number
}


export interface QuotesWidget{
    id:number,
    category: string,
    name: string,
    quoteDate: Date | string,
    total: number,
    status: string,
    quoteURL: string | null,
    color: string
}

export interface QueryString {
    query?: string,
    page?: number,
    pageSize?: number,
    column?: string,
    order?: string,
}

export interface ResponseDataDashboardCard{
    currentPage: number,
    data: Array<DashboardCard>,
    pageSize: number,
    totalCount: number,
    categoryId?: number,
    categoryName?: string,
    // page: number,
    // pageSize: number,
    // count: number,
    // items: any[],
}


//for List response
export interface DefaultResponse{
    title:string,
    statusCode: number,
    message: string,
    data: any,
}

export interface DefaultDataListResponse{
    page: number,
    pageSize: number,
    count: number,
    items: any[],
}

export interface ApplicationWidget{
    id: number,
    categoryId: number,
    categoryName: string,
    title: string,
}

export interface TicketWidget{
    id: number,
    title: string,
    ticketBoardId: number,
}

export interface SingletonWidget{
    msTeam?:boolean,
    oneDrive?:boolean,
    mailboxUsage?:boolean,
    offlineSite?:boolean,
    onSite?:boolean,
    kb4Trainings?:boolean,
    acronisBackupStatus?:boolean,
    acronisBackupAllocation?:boolean,
    acronisCloudBackup?:boolean,
    quotes?:boolean,
    applicationWidget?:boolean,
    kbWidget?:boolean,
    openTicket?:boolean,
    vipTicket?:boolean,
    currentOpenOrders?:boolean,
    itConfiguration?:boolean,
    serviceHealthStatus?:boolean,
    graphApi?:boolean,
}

export interface KnowBe4Training
{
    status: string;
    users: KnowBe4User[];
}

export interface KnowBe4User
{
    userId: number;
    firstName: string;
    lastName: string;
    managerFirstName: string;
    managerLastName: string;
    percentage: number;
    trainingList: KnowBe4Module[];
}

export interface KnowBe4Module{
    dueDate: string;
    moduleName: string;
}

export enum WidgetSize {
    Compact,
    Standard,
    Expanded
}

/*
export interface DashboardCardListResponse extends DefaultResponse{
    data: DashboardCardDataResponse,
}

export interface DashboardCardDataResponse extends DefaultDataListResponse{
    items: DashboardCard[]
}

export interface DashboardCardSingleResponse extends DefaultResponse{
    data: DashboardCard
}

export interface DashboardCardCategoryDropdown extends DefaultResponse{
    data: DashboardCategoryDropdown[]
}

export interface DashboardCardDeleteResponse extends DefaultResponse{
    data: boolean
} */
