import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'switch-input',
	templateUrl: './switch-input.component.html',
	styleUrls: ['./switch-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SwitchInputComponent,
			multi: true,
		},
	],
})
/**
 * Use with ngModel, Form Control, and Form
 */
export class SwitchInputComponent implements OnInit, ControlValueAccessor {
	@Input() checked = false;
	@Input() disabled = false;
	@Input() labelPosition: 'before' | 'after' = 'after';
	@Input() color: 'primary' | 'danger' = 'primary';
	@Output() onChange = new EventEmitter();

	_onChange: any = () => {};
	_onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	writeValue(checked: boolean) {
		this.checked = checked;
	}

	onModelChange(e: boolean) {
		this.checked = e;
		this._onChange(e);
		this.onChange.emit(this.checked);
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}
}
