import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params } from '@angular/router';

import {
	BehaviorSubject,
	finalize,
	Observable,
	ReplaySubject,
	tap,
} from 'rxjs';

import { toFormData } from 'app/shared/utilities/helper';

import { UserService } from '@app/core/services/user.service';

import { UserTypes } from '@app/shared/constants';

import { IKnowledgeBase } from './pages/kb-topic-list/store/kb-topic.interface';
import {
	KBCategory,
	keyValuePair,
	KnowledgeBase,
} from './../../shared/interfaces/knowledge-base.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class KnowledgeBaseService {
	@Output() categoryName: EventEmitter<string> = new EventEmitter();
	searchCategoryText: string = '';
	searchTopicText: string = '';
	customTopicText: string = '';
	tab = 0;

	globalKBFilters: IKnowledgeBase;
	companyGlobalKBFilters: IKnowledgeBase | undefined;
	companyKBFilters: IKnowledgeBase | undefined;

	private _globalKBData = new ReplaySubject<any[]>(1);
	globalKBData$ = this._globalKBData.asObservable();

	private _globalKBTotalItems = new ReplaySubject<number>(1);
	globalKBTotalItems$ = this._globalKBTotalItems.asObservable();

	private _companyGlobalKBData = new ReplaySubject<any[]>(1);
	companyGlobalKBData$ = this._companyGlobalKBData.asObservable();

	private _companyGlobalKBTotalItems = new ReplaySubject<number>(1);
	companyGlobalKBTotalItems$ = this._companyGlobalKBTotalItems.asObservable();

	private _companyKBData = new ReplaySubject<any[]>(1);
	companyKBData$ = this._companyKBData.asObservable();

	private _companyKBTotalItems = new ReplaySubject<number>(1);
	companyKBTotalItems$ = this._companyKBTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	private _isCompanyLoading = new BehaviorSubject(false);
	isCompanyLoading$ = this._isCompanyLoading.asObservable();

	allowToFetchNewData = false;

	globalKBCategoryFilters: IKnowledgeBase;
	companyKBCategoryFilters: IKnowledgeBase;

	private _globalKBCategoryData = new ReplaySubject<any[]>(1);
	globalKBCategoryData$ = this._globalKBCategoryData.asObservable();

	private _globalKBCategoryTotalItems = new ReplaySubject<number>(1);
	globalKBCategoryTotalItems$ = this._globalKBCategoryTotalItems.asObservable();

	private _companyKBCategoryData = new ReplaySubject<any[]>(1);
	companyKBCategoryData$ = this._companyKBCategoryData.asObservable();

	private _companyKBCategoryTotalItems = new ReplaySubject<number>(1);
	companyKBCategoryTotalItems$ =
		this._companyKBCategoryTotalItems.asObservable();

	constructor(private _http: HttpClient, private _userService: UserService) {}

	set isCompanyLoading(isLoading: boolean) {
		this._isCompanyLoading.next(isLoading);
	}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	clearSearch() {
		this.searchTopicText = '';
		this.searchCategoryText = '';
		this.customTopicText = '';
	}

	setSearchText(searchText: string) {
		this.searchTopicText = searchText;
	}

	changeCatType(category: any) {
		this.categoryName.emit(category);
	}

	addKb(app: KnowledgeBase) {
		const payload = {
			Id: app.Id,
			CompanyId: app.CompanyId,
			CreatedBy: this._userService.user?.id,
			KBCategoryId: app.KBCategoryId,
			Title: app.Title,
			Description: app.Description,
			PrimaryTag: app.PrimaryTag,
			RelatedTags: this._sanitizeArr(app.RelatedTags, 'Name'),
			CategoryIds: app.CategoryIds,
			SupportRequest: this._sanitizeArr(app.SupportRequest, 'SupportRequestId'),
			RelatedTopics: this._sanitizeArr(app.RelatedTopics, 'RelatedKBTopicId'),
			RelatedLinks: this._sanitizeArr(app.RelatedLinks, 'Link'),
			Files: app.Files,
			LevelId: app.LevelId,
			UserId: app.UserId,
		};
		if (payload.UserId === 0) {
			delete payload.UserId;
		}

		const body = toFormData(payload);
		const httpHeaders = new HttpHeaders();
		const options = {
			headers: httpHeaders,
			reportProgress: true,
		};
		return this._http.post(`${environment.apiBaseUrl}kb`, body);
	}

	updateKb(app: KnowledgeBase) {
		const payload = {
			Id: app.Id,
			CompanyId: app.CompanyId,
			ModifiedBy: this._userService.user?.id,
			KBCategoryId: app.KBCategoryId,
			Title: app.Title,
			Description: app.Description,
			PrimaryTag: app.PrimaryTag,
			RelatedTags: this._sanitizeArr(app.RelatedTags, 'Name'),
			CategoryIds: app.CategoryIds,
			SupportRequest: this._sanitizeArr(app.SupportRequest, 'SupportRequestId'),
			RelatedTopics: this._sanitizeArr(app.RelatedTopics, 'RelatedKBTopicId'),
			RelatedLinks: this._sanitizeArr(app.RelatedLinks, 'Link'),
			Files: app.Files,
			Media: [{}],
			LevelId: app.LevelId,
			UserId: app.UserId,
		};
		if (payload.UserId === 0) {
			delete payload.UserId;
		}
		payload.Media = [];

		for (let index = 0; index < app.Files.length; index++) {
			const item = app.Files[index];

			if (!(item instanceof File)) {
				payload.Media.push(app.Files.splice(index, 1)[0]);
				--index;
			}
		}

		const body = toFormData(payload);
		return this._http.put(`${environment.apiBaseUrl}kb/${app.Id}`, body);
	}

	getSupportRequest() {
		return this._http.get(
			`${environment.apiBaseUrl}SRForm/GetFormDropdownList`
		);
	}

	getRelatedTopic(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}kb/related-topic/dropdown?CompanyId=${companyId}`
		);
	}

	getRelatedTag(companyId: number) {
		return this._http.get(
			`${environment.apiBaseUrl}kb/related-tag/dropdown?CompanyId=${companyId}`
		);
	}

	getCategory(id: number | null = null, companyId: number) {
		const groupId = id !== undefined && id !== null ? `&groupId=${id}` : '';
		return this._http.get(
			`${environment.apiBaseUrl}kbcategory/dropdownlist?SearchText=${groupId}&companyId=${companyId}`
		);
	}

	getTopics(options: IKnowledgeBase, isCustom?: boolean) {
		const company = options.companyId ? `&CompanyId=${options.companyId}` : '';
		const user = options.userId ? `&UserId=${options.userId}` : '';
		const custom =
			options.isCustom !== undefined ? `&isCustom=${options.isCustom}` : '';
		const group = options.groupId ? `&groupId=${options.groupId}` : '';
		const hasCategoryId =
			options.categoryId !== undefined
				? `&CategoryId=${options.categoryId.join(',')}`
				: '';

		this.saveCurrentFilter(options, isCustom);
		this.clearKBData(options, isCustom);

		return this._http
			.get(
				`${environment.apiBaseUrl}kb?Page=${options.page}&PageSize=${options.pageSize}${company}${user}${hasCategoryId}&Order=${options.order}&Column=${options.column}&startDate=${options.dateStart}&endDate=${options.dateEnd}${custom}${group}&SearchText=${options.query}`
			)
			.pipe(
				tap((res) => {
					this.setKBData(options, isCustom, res);
				}),
				finalize(() => {
					if (
						(options.userRole === UserTypes.SourcepassAdmin ||
							options.userRole === UserTypes.ClientAdmin) &&
						options.companyId &&
						isCustom
					) {
						this.isCompanyLoading = false;
					} else {
						this.isLoading = false;
					}
				})
			);
	}

	setKBData(
		options: IKnowledgeBase,
		kb_isCustom: boolean | undefined,
		res: any
	) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				if (kb_isCustom) {
					this._companyKBData.next(res.data);
					this._companyKBTotalItems.next(res.totalCount);
				} else {
					this._companyGlobalKBData.next(res.data);
					this._companyGlobalKBTotalItems.next(res.totalCount);
				}
			} else {
				this._globalKBData.next(res.data);
				this._globalKBTotalItems.next(res.totalCount);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			if (kb_isCustom) {
				this._companyKBData.next(res.data);
				this._companyKBTotalItems.next(res.totalCount);
			} else {
				this._companyGlobalKBData.next(res.data);
				this._companyGlobalKBTotalItems.next(res.totalCount);
			}
		}
	}

	saveCurrentFilter(options: IKnowledgeBase, kb_isCustom?: boolean) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				if (kb_isCustom) {
					this.companyKBFilters = options;
				} else {
					this.companyGlobalKBFilters = options;
				}
			} else {
				this.globalKBFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			if (kb_isCustom) {
				this.companyKBFilters = options;
			} else {
				this.companyGlobalKBFilters = options;
			}
		}
	}

	clearKBFilters(isOnCompanyPage: boolean, kb_isCustom: boolean) {
		if (isOnCompanyPage) {
			if (kb_isCustom) {
				this.companyKBFilters = undefined;
			} else {
				this.companyGlobalKBFilters = undefined;
			}
		}
	}

	clearKBData(options: IKnowledgeBase, kb_isCustom?: boolean) {
		if (
			(options.userRole === UserTypes.SourcepassAdmin ||
				options.userRole === UserTypes.ClientAdmin) &&
			options.companyId &&
			kb_isCustom
		) {
			this.isCompanyLoading = true;
		} else {
			this.isLoading = true;
		}

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				if (kb_isCustom) {
					this._companyKBData.next([]);
					this._companyKBTotalItems.next(0);
				} else {
					this._companyGlobalKBData.next([]);
					this._companyGlobalKBTotalItems.next(0);
				}
			} else {
				this._globalKBData.next([]);
				this._globalKBTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			if (kb_isCustom) {
				this._companyKBData.next([]);
				this._companyKBTotalItems.next(0);
			} else {
				this._companyGlobalKBData.next([]);
				this._companyGlobalKBTotalItems.next(0);
			}
		}
	}

	getDynamicTopics({
		...options
	}: {
		page?: number;
		pageSize?: number;
		search?: string;
		categoryId?: string | number;
		sort?: string;
		column?: string;
	}) {
		const page = options.page !== undefined ? `Page=${options.page}` : '';
		const pageSize =
			options.pageSize !== undefined ? `&PageSize=${options.pageSize}` : '';
		const search =
			options.search !== undefined ? `&SearchText=${options.search}` : '';
		const categoryId =
			options.categoryId !== undefined
				? `&CategoryId=${options.categoryId}`
				: '';
		const sort = options.sort !== undefined ? `&Order=${options.sort}` : '';
		const column =
			options.column !== undefined ? `&Column=${options.column}` : '';
		return this._http.get(
			`${environment.apiBaseUrl}kb?${page}${pageSize}${search}${categoryId}${sort}${column}`
		);
	}

	getTopicById(id: number) {
		return this._http.get(`${environment.apiBaseUrl}kb/${id}`);
	}

	deleteTopic(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}kb/${id}`);
	}

	deleteCategory(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}kbcategory/${id}`);
	}

	getCategoryList(options: IKnowledgeBase) {
		this.saveCurrentCategoryFilter(options);
		this.clearKBCategoryData(options);

		return this._http
			.get(
				`${environment.apiBaseUrl}kbcategory?Page=${options.page}&PageSize=${
					options.pageSize
				}&Column=${options.column}&Order=${options.order}&searchText=${
					options.query ?? ''
				}${options.companyId ? '&companyId=' + options.companyId : ''}`
			)
			.pipe(
				tap((res) => {
					this.setKBCategoryData(options, res);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	setKBCategoryData(options: IKnowledgeBase, res: any) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this._companyKBCategoryData.next(res.data);
				this._companyKBCategoryTotalItems.next(res.totalCount);
			} else {
				this._globalKBCategoryData.next(res.data);
				this._globalKBCategoryTotalItems.next(res.totalCount);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companyKBCategoryData.next(res.data);
			this._companyKBCategoryTotalItems.next(res.totalCount);
		}
	}

	saveCurrentCategoryFilter(options: IKnowledgeBase) {
		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this.companyKBCategoryFilters = options;
			} else {
				this.globalKBCategoryFilters = options;
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this.companyKBCategoryFilters = options;
		}
	}

	clearKBCategoryData(options: IKnowledgeBase) {
		this.isLoading = true;

		if (options.userRole === UserTypes.SourcepassAdmin) {
			if (options.companyId) {
				this._companyKBCategoryData.next([]);
				this._companyKBCategoryTotalItems.next(0);
			} else {
				this._globalKBCategoryData.next([]);
				this._globalKBCategoryTotalItems.next(0);
			}
		} else if (options.userRole === UserTypes.ClientAdmin) {
			this._companyKBCategoryData.next([]);
			this._companyKBCategoryTotalItems.next(0);
		}
	}

	getCategoryById(id: number): Observable<KBCategory> {
		return this._http.get<KBCategory>(
			`${environment.apiBaseUrl}kbcategory/${id}`
		);
	}

	addCategory(body: any) {
		body.name = body.name.trim();
		return this._http.post(`${environment.apiBaseUrl}kbcategory`, body);
	}

	updateCategory(body: Params) {
		body['name'] = body['name'].trim();
		return this._http.put(
			`${environment.apiBaseUrl}kbcategory/${body['id']}`,
			body
		);
	}

	addKBBot(app: any) {
		const body = toFormData(app);
		body.append('file', app.file);
		return this._http.post(`${environment.apiBaseUrl}kbbot`, body);
	}

	updateKBBot(app: any) {
		const body = toFormData(app);
		body.append('file', app.file);
		return this._http.put(`${environment.apiBaseUrl}kbbot/${app.id}`, body);
	}

	deleteImage(id: number) {
		return this._http.delete(`${environment.apiBaseUrl}KBBot/DeleteIcon/${id}`);
	}

	getKBBotById(id: number) {
		return this._http.get(`${environment.apiBaseUrl}kbbot/${id}`);
	}

	getKBBotList() {
		return this._http.get(`${environment.apiBaseUrl}kbbot`);
	}

	getChatBotHistory(chatBotId: number, pageNumber: number, pageSize: number) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}kbbot/history/${chatBotId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}

	getKBCategoryHistory(
		kbCategoryId: number,
		pageNumber: number,
		pageSize: number
	) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}kbcategory/history/${kbCategoryId}?page=${pageNumber}&pageSize=${pageSize}`
		);
	}

	getKBTopicHistory(
		kbTopicId: number,
		companyId: number,
		pageNumber: number,
		pageSize: number
	) {
		return this._http.get<History[]>(
			`${environment.apiBaseUrl}kb/history/${kbTopicId}?companyId=${companyId}&page=${pageNumber}&pageSize=${pageSize}`
		);
	}

	private _sanitizeArr(arr: keyValuePair, key: string): keyValuePair[] {
		const keys = ['Name', 'Link', 'RelatedKBTopicId', 'SupportRequestId'];
		const objKey = keys[keys.indexOf(key)];
		const newValue = [];

		if (arr) {
			const keys = ['Name', 'Link', 'RelatedKBTopicId', 'SupportRequestId'];
			const objKey = keys[keys.indexOf(key)];

			for (const val of arr as keyValuePair[]) {
				const data: any = {};
				data['Id'] = 0;
				data[objKey] = val;
				newValue.push(data);
			}
		}

		return newValue;
	}

	getKBCategoryDropdown() {
		return this._http.get<any[]>(
			`${environment.apiBaseUrl}KBCategory/Dropdownlist`
		);
	}
}
