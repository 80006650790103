<ng-template #searchTemp>
	<search-input
		#searchTextbox
		size="small"
		class="q-w-100"
		[formControl]="queryString"
		(keyup)="queryChange(500)"
		(keyup.enter)="queryChange()"
		placeholder="Search Credits"
	></search-input>

	<app-tertiary-button [isFilterOn]="isFilterOn" [isFilterToggle]="true">
	</app-tertiary-button>
</ng-template>

<div class="card-2 q-p-0 q-pt-24 q-br-0">
	<section id="credits-header" class="tab-v2-container_header">
		<div
			class="tab-v2-container_head-con q-d-flex q-ai-start q-as-stretch q-g-24"
		>
			<div
				class="q-d-flex q-f-column q-jc-between q-ai-start q-as-stretch q-f-1-0-0"
			>
				<div class="tab-v2-container_header_title font-h3">
					Credits & Unapplied Payments Record
				</div>

				<div
					class="tab-v2-container_header_top-right-search-bar q-d-flex q-g-16 q-w-100 q-jc-start q-ai-center search-lg"
				>
					<ng-container *ngTemplateOutlet="searchTemp"></ng-container>
				</div>
			</div>

			<div class="breakdown-container">
				<div class="q-d-flex q-jc-between q-ai-center q-as-stretch">
					<div class="font-btn">Available Credits</div>
					<div class="font-h4 q-right">{{ availableCredits | currency }}</div>
				</div>

				<div class="q-d-flex q-jc-between q-ai-center q-as-stretch">
					<div class="font-btn">Unapplied Payments</div>
					<div class="font-h4 q-right">{{ unappliedPayments | currency }}</div>
				</div>
			</div>

			<div
				class="q-d-flex q-f-column q-jc-between q-ai-start q-as-stretch q-f-1-0-0 search-sm"
			>
				<div
					class="tab-v2-container_header_top-right-search-bar q-d-flex q-g-16 q-w-100 q-jc-start q-ai-center"
				>
					<ng-container *ngTemplateOutlet="searchTemp"></ng-container>
				</div>
			</div>
		</div>
	</section>

	<section id="demo" class="q-w-100 collapse">
		<div class="table-filter-2 tf-col-4">
      <div class="filter-item">
        <label>Start Date</label>
        <date-input
					[formControl]="startDateFilter"
					max="{{ endDateFilter.value | date : 'yyyy-MM-dd' }}"
					[isString]="true"
				></date-input>
      </div>

      <div class="filter-item">
        <label>End Date</label>
        <date-input
					[formControl]="endDateFilter"
					min="{{ startDateFilter.value | date : 'yyyy-MM-dd' }}"
					[isString]="true"
				></date-input>
      </div>

			<div class="filter-item">
        <label>Transaction Type</label>
				<select-input
					[options]="transactionOption"
					[optionKeys]="{ value: 'id', label: 'name' }"
					inputClass="form-control"
					[formControl]="transactionTypeFilter"
					placeholder="Transaction Type"
					class="input-class"
				>
				</select-input>
      </div>

      <div class="filter-item filter-actions">
        <app-clear-filter></app-clear-filter>
      </div>
    </div>
	</section>

	<section id="credits-table" class="q-w-100">
		<app-new-table-shared
			loadingText="Fetching Credits..."
			[searchFilters]="searchFilters"
		>
			<!-- For action column -->
			<ng-template #customizeActionColumnRef let-option let-i="index">
				<div
					class="q-d-flex q-ai-center q-jc-start gap-3"
					style="max-width: 144px"
				>
					<a
						[ngClass]="{
							disabled: !(option.transactionType === 'Credit Memo')
						}"
						href="javascript:void(0)"
						class="edit visiblity-icon"
						[tooltipContentV2]="{ message: 'Download' }"
						mat-raised-button
						(click)="getPrintInvoice(option, 'viewPdf')"
					>
						<app-figma-icon-img
							[iconName]="'download-02'"
							[width]="24"
							[height]="24"
							[hasSpaceOnRight]="false"
							[isForButton]="false"
						>
						</app-figma-icon-img>
					</a>

					<a
						[ngClass]="{
							disabled: !(option.transactionType === 'Credit Memo')
						}"
						href="javascript:void(0)"
						class="edit visiblity-icon"
						[tooltipContentV2]="{ message: 'Print' }"
						(click)="getPrintInvoice(option)"
						mat-raised-button
					>
						<app-figma-icon-img
							[iconName]="'printer'"
							[width]="24"
							[height]="24"
							[hasSpaceOnRight]="false"
							[isForButton]="false"
						>
						</app-figma-icon-img>
					</a>
				</div>
			</ng-template>
		</app-new-table-shared>
	</section>
</div>
