import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  setStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  clearStorage() {
    sessionStorage.clear();
  }

  removeStorageItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
