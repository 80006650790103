import { Params } from '@angular/router';
import { ApplicationType, IDropdown } from '@app/shared/constants';
import { Injectable, inject } from '@angular/core';
import { PortalUsageTesterService } from './portal-usage-tester.service';
import { PortalUsageModule } from '../portal-usage.module';
import { Observable, filter, map, of, take } from 'rxjs';
import {
	CompanyData,
	CompanyReportUsage,
	DAUTypeDropdown,
	DailyReportUsageFormat,
	LoginReportDetails,
	LoginReportSummary,
	MonthlyReportUsageFormat,
	ReportButton,
	UserActivityHistory,
	UserDetails,
	UserLoginFilters,
	UsersActivity,
	iListPage,
	EmailPreview,
} from './portal-usage.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	FinanceParameters,
	InvoiceParameters,
	IsPrintedParams,
} from './../../../shared/interfaces/finance.interface';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { Store } from '@ngrx/store';
import * as select from '../core/store/portal-usage.selector';
import { initial } from './store';
import { environment } from 'environments/environment';
import { UserDropdown } from '@app/modules/companies/pages/company-org-chart/company-org-chart.component';

@Injectable({
	providedIn: 'root',
})
export class PortalUsageService extends PortalUsageTesterService {
	// injection
	private _http = inject(HttpClient);
	private _store = inject(Store);
	// props
	statusId?: number[] | null = [];
	deliveryMethodIds?: number[] | null = [];
	typeIds?: number[] | null = [];
	companyIds?: number | null;
	autopay?: boolean | null;
	isPrinted?: boolean | null;
	isEmailSent?: boolean | null;
	userLoginFilters$ = this._store.select(select.userLoginFilters).pipe(
		take(1),
		filter((v) => v !== null)
	);
	userLoginFiltersDetails$ = this._store
		.select(select.userLoginFiltersDetails)
		.pipe(
			take(1),
			filter((v) => v !== null)
		);

	companyReportFilters$ = this._store.select(select.companyReportFilters).pipe(
		take(1),
		map((v) => nullToEmpty(v))
	);
	companyReportTop5Filters$ = this._store
		.select(select.companyReportTop5Filters)
		.pipe(
			take(1),
			map((v) => nullToEmpty(v))
		);
	companyReportLeast5Filters$ = this._store
		.select(select.companyReportLeast5Filters)
		.pipe(
			take(1),
			map((v) => nullToEmpty(v))
		);

	userActivityFilters$ = this._store.select(select.userActivityFilters).pipe(
		take(1),
		map((v) => nullToEmpty(v))
	);
	usersActivityGraphFilters$ = this._store
		.select(select.usersActivityGraphFilters)
		.pipe(
			take(1),
			map((v) => nullToEmpty(v))
		);

	userActivityHistoryFilters$ = this._store
		.select(select.userActivityHistoryFilters)
		.pipe(
			take(1),
			map((v) => nullToEmpty(v))
		);

	startEndDate$ = this._store.select(select.startEndDate);
	email?: number[] | null = [];
	emailType?: number[] | null = [];
	emailCompany?: number[] | null = [];
	territoryId?: number[] | null = [];
	userId?: number[] | null = [];

	constructor() {
		super();
		//this.isTest = true;
	}
	// public methods
	override getMonthlyUsage(): Observable<MonthlyReportUsageFormat> {
		if (this.isTest) return super.getMonthlyUsage();

		let params: any = {};
		this.startEndDate$.pipe(take(1)).subscribe((v) => {
			params = nullToEmpty(v);
		});
		return this._http.get<MonthlyReportUsageFormat>(
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsage`,
			{ params: params }
		);
	}

	/*   override getDailyUsage(): Observable<DailyReportUsageFormat> {
    if(this.isTest) return super.getDailyUsage();
    return this._http.get<DailyReportUsageFormat>(`${environment.apiBaseUrl}PortalUsage/GetPortalUsage?isToday=true`)
  } */

	override getCompanyUsage(): Observable<iListPage> {
		if (this.isTest) return super.getCompanyUsage();
		const params = this._getParams('company');
		return this._http.get<iListPage>(
			//`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByYearMonth/${year}/${month}`
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByDateRange`,
			{ params }
		);
	}
	override getCompanyUsageTop5() {
		if (this.isTest) return super.getCompanyUsageTop5();
		const params = this._getParams('top 5');
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByDateRange`,
			{ params }
		);
	}
	override getCompanyUsageLeast5() {
		if (this.isTest) return super.getCompanyUsageLeast5();
		const params = this._getParams('least 5');
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByDateRange`,
			{ params }
		);
	}

	getCompanyDetails(id: number) {
		return this._http.get<CompanyData>(
			`${environment.apiBaseUrl}Companies/${id}`
		);
	}

	override getUsersUsage() {
		if (this.isTest) return super.getUsersUsage();
		const params = this._getParams('users activity');
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByCompany/`,
			{ params }
		);
	}

	override getUsersActivityGraph() {
		if (this.isTest) return super.getUsersActivityGraph();
		const params = this._getParams('users activity graph');
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByCompany/`,
			{ params }
		);
	}

	override getUserDetails(id: number) {
		if (this.isTest) return super.getUserDetails(id);
		return this._http.get<UserDetails>(`${environment.apiBaseUrl}Users/${id}`);
	}

	override getUserActivityHistory() {
		if (this.isTest) return super.getUserActivityHistory();
		const params = this._getParams('users activity history');
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}PortalUsage/GetPortalUsageByUser`,
			{ params }
		);
		//return super.getUserActivityHistory(userId, year, month);
	}

	override getUserLoginReportSummary(): Observable<iListPage> {
		const params = this._getParams();
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}UserLoginReport/GetDailyActiveUsersSummary`,
			{ params: params }
		);
	}
	override getUserLoginReportDetail(): Observable<iListPage> {
		if (this.isTest) return super.getUserLoginReportDetail();

		const params = this._getParams('details');
		return this._http.get<iListPage>(
			`${environment.apiBaseUrl}UserLoginReport/GetDailyActiveUsersDetails`,
			{ params: params }
		);
	}

	override getDAUTypeDropdown(): Observable<DAUTypeDropdown[]> {
		return this._http.get<DAUTypeDropdown[]>(
			`${environment.apiBaseUrl}UserLoginReport/GetActiveUserTypes/`
		);
	}

	getReportButtons() {
		return this._http.get<ReportButton[]>(
			`${environment.apiBaseUrl}MenuPermissions/GetReportButtons`
		);
	}

	getCompanyDropdown(): Observable<IDropdown[]> {
		return this._http.get<IDropdown[]>(
			`${environment.apiBaseUrl}companies/GetDropdown`
		);
	}

	getCompanyRoleDropdown(companyId: number[]) {
		return this._http.post<IDropdown[]>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/GetCompanyRoleDropdown`,
			{ companyId }
		);
	}

	getRoleMenuGroup(roleId: number) {
		return this._http.get<IDropdown[]>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/GetRoleMenuGroup?roleId=${roleId}`
		);
	}

	getUsersByPermission(options: IPageState) {
		return this._http.post<any[]>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/GetUsersByPermissions`,
			options
		);
	}

	exportUsersByPermission(options: IPageState) {
		return this._http.post<string>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/ExportUsersByPermissions`,
			options,
			{ responseType: 'text' as any }
		);
	}

	getUsersDropdownByCompanyId(
		companyId: number,
		search?: string
	): Observable<any[]> {
		if (search) {
			return this._http.get<any[]>(
				`${environment.apiBaseUrl}users/GetDropdownByCompanyId/${companyId}?searchText=${search}`
			);
		} else {
			return this._http.get<any[]>(
				`${environment.apiBaseUrl}users/GetDropdownByCompanyId/${companyId}`
			);
		}
	}

	/* 	getUsersDropdown(searchText: string = ''){
		return this._http.get<UserDropdown[]>(
			`${environment.apiBaseUrl}Users/GetDropdown?search=${searchText}`
		);
	} */

	// for finance report
	export(options: FinanceParameters, exportType: ApplicationType) {
		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.sort,
			Order: options.order,
			InvoiceStartDate: options.startDate ? options.startDate : '',
			InvoiceEndDate: options.endDate ? options.endDate : '',
			DueStartDate: options.dueStartDate ? options.dueStartDate : '',
			DueEndDate: options.dueEndDate ? options.dueEndDate : '',
			exportType,
			HaveBalance: options.haveBalance,
		} as any;

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			param.StatusIds = options.statusIds;
		}

		if (
			options.deliveryMethodIds !== undefined &&
			options.deliveryMethodIds !== null &&
			options.deliveryMethodIds?.length > 0
		) {
			param.DeliveryMethodIds = options.deliveryMethodIds;
		}

		if (options.isAutopay === true || options.isAutopay === false) {
			param.isAutopay = options.isAutopay;
		}

		if (
			options.typeIds !== undefined &&
			options.typeIds !== null &&
			options.typeIds?.length > 0
		) {
			param.TypeIds = options.typeIds;
		}

		if (options.companyIds !== undefined && options.companyIds !== null) {
			param.CompanyIds = options.companyIds;
		}

		if (
			(options.isPrinted === true || options.isPrinted === false) &&
			options.isPrinted !== undefined &&
			options.isPrinted !== null
		) {
			param.isPrinted = options.isPrinted;
		}

		if (
			(options.isEmailSent === true || options.isEmailSent === false) &&
			options.isEmailSent !== undefined &&
			options.isEmailSent !== null
		) {
			param.isEmailSent = options.isEmailSent;
		}

		return this._http.get(`${environment.apiBaseUrl}Finances/ExportInvoice`, {
			params: param,
			responseType: 'text',
		});
	}

	getInvoiceStatusDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}invoices/GetInvoiceStatusDropdown`
		);
	}
	getInvoiceTypes() {
		return this._http.get(`${environment.apiBaseUrl}InvoiceTypes/GetDropdown`);
	}
	downloadInvoice2(id: number) {
		return this._http.get(
			`${environment.apiBaseUrl}CW_Invoices/PrintInvoice/${id}`,
			{ responseType: 'text' }
		);
	}
	getAutopayDropdown() {
		return of([
			{
				val: true,
				label: 'On',
			},
			{
				val: false,
				label: 'Off',
			},
			{
				val: null,
				label: 'All',
			},
		]);
	}
	getEmailPrintDropdown() {
		return of([
			{
				val: null,
				label: 'All',
			},
			{
				val: true,
				label: 'Yes',
			},
			{
				val: false,
				label: 'No',
			},
		]);
	}
	updateIsPrinted(isPrintedParams: IsPrintedParams[]) {
		const body = {
			invoiceIsPrintedById: isPrintedParams,
		};
		return this._http.put(
			`${environment.apiBaseUrl}Finances/InvoiceIsPrintedByIds`,
			body
		);
	}
	sendWithClaimForm(id: number) {
		return this._http.post(
			`${environment.apiBaseUrl}Finances/SendWithClaimForm/${id}/`,
			id
		);
	}
	getFinances<TResult = Object>(options: FinanceParameters) {
		//console.log('getinvoices called')
		var param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.sort,
			Order: options.order,
			InvoiceStartDate: options.startDate ? options.startDate : '',
			InvoiceEndDate: options.endDate ? options.endDate : '',
			DueStartDate: options.dueStartDate ? options.dueStartDate : '',
			DueEndDate: options.dueEndDate ? options.dueEndDate : '',
			HaveBalance: options.haveBalance,
		} as any;

		if (
			options.statusIds !== undefined &&
			options.statusIds !== null &&
			options.statusIds?.length > 0
		) {
			param.StatusIds = options.statusIds;
		}

		if (
			options.deliveryMethodIds !== undefined &&
			options.deliveryMethodIds !== null &&
			options.deliveryMethodIds?.length > 0
		) {
			param.DeliveryMethodIds = options.deliveryMethodIds;
		}

		if (
			options.typeIds !== undefined &&
			options.typeIds !== null &&
			options.typeIds?.length > 0
		) {
			param.TypeIds = options.typeIds;
		}

		if (options.companyIds !== undefined && options.companyIds !== null) {
			param.companyIds = options.companyIds;
		}

		if (options.isAutopay === true || options.isAutopay === false) {
			param.isAutopay = options.isAutopay;
		}

		if (
			(options.isPrinted === true || options.isPrinted === false) &&
			options.isPrinted !== undefined &&
			options.isPrinted !== null
		) {
			param.isPrinted = options.isPrinted;
		}

		if (
			(options.isEmailSent === true || options.isEmailSent === false) &&
			options.isEmailSent !== undefined &&
			options.isEmailSent !== null
		) {
			param.isEmailSent = options.isEmailSent;
		}

		return this._http.get<TResult>(`${environment.apiBaseUrl}Finances`, {
			params: param,
		});
	}
	uploadClaimForm(id: number, data: FormData) {
		return this._http.post(
			`${environment.apiBaseUrl}Finances/UploadClaimForm/${id}`,
			data
		);
	}

	downloadClaimForm(id: number) {
		return this._http.post<any[]>(
			`${environment.apiBaseUrl}Finances/DownloadClaimForm/${id}`,
			id
		);
	}

	deleteClaimForm(invoiceId: number, formId: number) {
		return this._http.post(
			`${environment.apiBaseUrl}Finances/DeleteClaimForm/${invoiceId}/${formId}`,
			invoiceId
		);
	}
	getEmailNotification(options: any) {
		return this._http.get(`${environment.apiBaseUrl}EmailQueues`, {
			params: options,
		});
	}
	getEmailTypeDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}EmailQueues/GetEmailTypeDropdown`
		);
	}
	getEmailCompanyDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}EmailQueues/GetCompanyDropdown`
		);
	}
	getEmailDropdown() {
		return this._http.get(
			`${environment.apiBaseUrl}EmailQueues/GetEmailDropdown`
		);
	}

	getEmailStatusList() {
		return this._http.get<string[]>(
			`${environment.apiBaseUrl}EmailQueues/GetStatusList`
		);
	}

	exportUserLoginReportSummary(exportType: ApplicationType) {
		const params = this._getParams('summary', exportType);
		return this._http.get(
			`${environment.apiBaseUrl}UserLoginReport/ExportSummary`,
			{
				params: params,
				responseType: 'text',
			}
		);
	}
	exportUserLoginReportDetails(exportType: ApplicationType) {
		const params = this._getParams('details', exportType);
		return this._http.get(
			`${environment.apiBaseUrl}UserLoginReport/ExportDetails`,
			{
				params: params,
				responseType: 'text',
			}
		);
	}

	exportEmailNotif(options: any, exportType: ApplicationType) {
		const params = { ...options, exportType };
		return this._http.get(`${environment.apiBaseUrl}EmailQueues/Export`, {
			params: params,
			responseType: 'text',
		});
	}

	exportNetsuite(options: any, exportType: ApplicationType) {
		const params = { ...options, exportType };
		return this._http.get(
			`${environment.apiBaseUrl}Invoices/ExportNetsuiteCreditMemos`,
			{
				params: params,
				responseType: 'text',
			}
		);
	}

	setIsReleased(payload: Params) {
		return this._http.put(
			`${environment.apiBaseUrl}EmailQueues/SetIsReleased`,
			payload
		);
	}

	setIsDeleted(payload: Params) {
		return this._http.put(
			`${environment.apiBaseUrl}EmailQueues/SetIsDeleted`,
			payload
		);
	}

	getEmailById(id: number) {
		return this._http.get<EmailPreview>(
			`${environment.apiBaseUrl}NotificationAccess/getEmailPreview/${id}`
		);
	}

	private _getParams(
		type: GetParamsType = 'summary',
		exportType?: ApplicationType
	) {
		let filters: any = initial.userLoginFilters;

		if (type === 'summary')
			this._getSummaryParams.subscribe((v) => (filters = v));
		else if (type === 'details')
			this._getDetailsParams.subscribe((v) => (filters = v));
		else if (type === 'top 5')
			this.companyReportTop5Filters$.subscribe((v) => (filters = v));
		else if (type === 'least 5')
			this.companyReportLeast5Filters$.subscribe((v) => (filters = v));
		else if (type === 'company')
			this.companyReportFilters$.subscribe((v) => (filters = v));
		else if (type === 'users activity')
			this.userActivityFilters$.subscribe((v) => (filters = v));
		else if (type === 'users activity graph')
			this.usersActivityGraphFilters$.subscribe((v) => (filters = v));
		else if (type === 'users activity history')
			this.userActivityHistoryFilters$.subscribe((v) => (filters = v));

		if (exportType) filters.exportType = exportType;
		return new HttpParams({ fromObject: filters });
	}

	// props for _getParams
	private _getSummaryParams = this.userLoginFilters$.pipe(
		map((v: any) => {
			let params = nullToEmpty(v);
			delete params.DAUTypeId;
			return params;
		}),
		take(1)
	);
	private _getDetailsParams = this.userLoginFiltersDetails$.pipe(
		map((v) => {
			if (v.DAUTypeId) {
				let details = { ...v, ...{ typeIds: v.DAUTypeId } };
				delete details.DAUTypeId;
				return details;
			}
			return v;
		}),
		map((v: any) => nullToEmpty(v)),
		take(1)
	);
}

function nullToEmpty(data: any) {
	const toClean = { ...data };
	for (const key in toClean) {
		if (
			toClean[key] === null ||
			toClean[key] === undefined ||
			toClean[key] === '' ||
			toClean[key] === 0
		) {
			//toClean[key] = '';
			delete toClean[key];
		}
	}
	return toClean;
}

type GetParamsType =
	| 'summary'
	| 'details'
	| 'company'
	| 'top 5'
	| 'least 5'
	| 'users activity'
	| 'users activity graph'
	| 'users activity history';
