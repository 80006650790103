import { Component, OnInit } from "@angular/core";
//import { GlobalPermissionsService } from "@app/shared/services/base-application.service";

@Component({
  selector: 'app-global-add-edit-topic',
  template: `<app-kb-topic-tab></app-kb-topic-tab>`,
  styles: [''],
  //providers:[GlobalPermissionsService]
})

export class CompaniesKBAddEditComponent implements OnInit {
  ngOnInit(){

  }
}
