import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-quest-logo',
  templateUrl: './quest-logo.component.html',
  styleUrls: ['./quest-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestLogoComponent {
  @Input() classList: string;
  constructor() { }
}
