import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { IDropdown } from '@app/shared/constants';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import {  take, tap } from 'rxjs';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnChanges {
  @Input() gIds: number[];
  @Input() cId: number
  @Input() permissionData: number[];
  @Input() isSelectAll: boolean = false;
  @Output() uIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  users: IDropdown[];
  filteredUsers: IDropdown[];
  chosenUsers: number[];
  constructor(private _companiesService: CompaniesService, private _utilitiesService: UtilitiesService) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['gIds'] && changes['gIds'].currentValue != undefined){
     this.filterUsers();
    }
    // if (changes['gIds'] && (changes['gIds'].currentValue && changes['gIds'].currentValue.length === 0)) {
    //   this.filterUsers();
    // }
    // if(changes['gIds'] && !changes['gIds'].isFirstChange() && changes['gIds'].previousValue != undefined){
    //   let addArray = this._utilitiesService.compareAdd(changes['gIds'].previousValue,this.gIds);
    //   let removeArray = this._utilitiesService.compareRemove(changes['gIds'].previousValue,this.gIds);
    //   if(addArray.length > 0){
    //     this.updateChosenUsers(addArray, true)
    //   }else
    //   if(removeArray.length > 0){
    //     this.updateChosenUsers(removeArray, false)
    //   }
    // }
    if(changes['permissionData'] && changes['permissionData'].currentValue != undefined){
      this.chosenUsers = this.permissionData;
      this._companiesService.getUsersDropdownByCompanyId(this.cId)
     .pipe(
       take(1),
       tap(users => {
         this.users = users;
         this.filteredUsers = this.users;
        //  if(this.isSelectAll === false){
        //   this.chosenUsers = this.filteredUsers.map((user)=>user.id);
        //  }
        //  this.getChosenUserId();
        })
     ).subscribe()
     }
     if(changes['isSelectAll'] && changes['isSelectAll'].currentValue != undefined){

     }
  }



  updateChosenUsers(groupIds: number[], value: boolean){
    // get removed users

    let toUpdateArr: number[] = [];
    groupIds.forEach((groupId)=>{
      toUpdateArr = this.users.filter((user)=>user.groupId?.includes(groupId)).map((user)=>user.id);
      if(value === false){
        this.chosenUsers = this._utilitiesService.compareRemove(this.chosenUsers,toUpdateArr)
      }
      else
      if(value === true){
        this.chosenUsers = this.chosenUsers.concat(toUpdateArr)
      }
    })

    // this.getChosenUserId();
  }
  filterUsers(){

    if(this.filteredUsers){
      if (this.gIds.length === 0) {
        this.filteredUsers = this.users;
      } else {
        this.filteredUsers = this.users.filter(user=> user.groupId?.some(num=>this.gIds.includes(num)));
      }
    }
  }


  getChosenUserId(){
    this.uIds.emit(this.chosenUsers);
  }


}
