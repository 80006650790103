import { Directive, HostListener, Input, inject } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import * as user from '@app/core/store/user/user.reducer';
import { Permission } from '@app/shared/constants';
import { UserActivity } from '@app/shared/interfaces/user.interface';
import { BrowserInfoService } from '@app/shared/services/browser-info.service';
import { pipe, take } from 'rxjs';

@Directive({
  selector: '[activityTracker]'
})
export class ActivityTrackerDirective {
  browserInfo = inject(BrowserInfoService)

  @Input() activityTracker: any; // equivalent to [menuId] in API
  @Input() activityAction?: Permission;

  @HostListener('click', ['$event']) onClick($event:any){
    const activity: UserActivity = {
      menuId: this.activityTracker || 0,
      platformType: 2, // web
      browserType: this.browserInfo.getBrowserType(),
      browserVersion: this.browserInfo.getBrowserVersion(),
      browserResolution: this.browserInfo.resolution,
      menuActionId: this.activityAction,
    }
    this._user.saveUserActivity(activity)
      .pipe(take(1))
      .subscribe((res:any)=>{})
  }

  constructor(private _user:UserService){}

}
