import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, defer, from, Subject, tap } from 'rxjs';
import { MessageNotificationCounts, UserMessageNotificationCount, UserMessagePendingNotificationDetail } from '../interfaces/usermessage.interface';
import { NotificationsClienthubService } from './notifications-clienthub.service';
import { UserMessageCategoryEnum } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  private _message_notification_counts = new BehaviorSubject<any>('');
  public message_notification_counts_ref = this._message_notification_counts.asObservable();

  constructor(private notificationHub: NotificationsClienthubService,
    private http: HttpClient,
    private _userService:UserService,
    private notifier: NotificationService,
    private spinner: SpinnerService) {
     notificationHub.messageNotificationReceived.subscribe(s=>this.messageNotificationReceived(s));

  }

/*   public orderSyncRecevied(s: {state: boolean}){
    if(s.state === true){
      this.notifier.notify('Orders is Syncing...',{duration: 2, location: 'bottom-right'});
      this.spinner.start();
      setTimeout(()=>{
        this.spinner.stop();
      },2000)
    }
  } */

  private messageNotificationReceived(s:UserMessageNotificationCount[]){
    return this._message_notification_counts.next({
      countAll : s.map(o=>o.notificationCount).reduce((a,c)=>a+c,0),
      countBroadcast : (s.find(o=>o.categoryId == UserMessageCategoryEnum.BroadCast)?.notificationCount) ?? 0,
      countTickets : (s.find(o=>o.categoryId == UserMessageCategoryEnum.Ticket)?.notificationCount) ?? 0,
      countMessages : (s.filter(o=>o.categoryId == UserMessageCategoryEnum.Ticket || o.categoryId == UserMessageCategoryEnum.BroadCast || o.categoryId == UserMessageCategoryEnum.Banner)?.map(p=>p.notificationCount).reduce((a,c)=>a+c,0)) ?? 0,
      countBell : (s.filter(o=>o.categoryId == UserMessageCategoryEnum.Order || o.categoryId == UserMessageCategoryEnum.Invoice)?.map(p=>p.notificationCount).reduce((a,c)=>a+c,0)) ?? 0,
   });
  }

  // public refreshMessageNotificationCount(){
  //    this.http.get<UserMessageNotificationCount[]>(environment.apiBaseUrl+'usermessages/getnotificationcount').subscribe(s=>{
  //       this.messageNotificationReceived(s);
  //    });

  // }

  public clearAllMessageNotifications(){
   if(!this._userService.user?.impersonatingBy){
      this.http.get<UserMessageNotificationCount[]>(environment.apiBaseUrl+'usermessages/clearAllNotifications').subscribe(s=>{
        //  this.refreshMessageNotificationCount();
       });
   }
 }

 public getPendingNotificationDetail(){
   return this.http.get<UserMessagePendingNotificationDetail[]>(environment.apiBaseUrl+'usermessages/Web/GetPendingNotificationDetail');
 }

 public getMessageNotifications(){
   return this.http.get<UserMessagePendingNotificationDetail[]>(environment.apiBaseUrl+'usermessages/GetMessageNotifications');
 }

 public getBellNotifications(){
   return this.http.get<UserMessagePendingNotificationDetail[]>(environment.apiBaseUrl+'usermessages/GetBellNotifications');
 }

 public clearNotificationById(id: number){
 if(!this._userService.user?.impersonatingBy){
    this.http.get(environment.apiBaseUrl+'usermessages/Web/ClearNotification/'+id).subscribe(s=>{
      //  this.refreshMessageNotificationCount();
     });
 }
}

}

