import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	inject,
	OnDestroy,
} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DataModalService, IDataModal } from '../../data-modal.service';
import { ModalTooltipComponent } from '../../modal-utils/tooltip/tooltip.component';
import { NewTableSharedModule } from '@app/shared/new-table-shared/new-table-shared.module';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { DashboardReportService } from '@app/modules/dashboard/pages/dashboard/dashboard-report.service';
import { Subscription } from 'rxjs';
import { SharedMessagingModule } from '@app/shared/components/messaging/shared-messaging.module';
import { PdfTicketComponentModal } from '../pdf-ticket/pdf-ticket.component';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { NotificationMessages } from '@app/shared/constants';
@Component({
  selector: 'app-at-risk-devices',
  templateUrl: './at-risk-devices.component.html',
  styleUrls: ['./at-risk-devices.component.scss'],
	standalone: true,
	imports: [
		SharedModule,
		ModalTooltipComponent,
		NewTableSharedModule,
		SharedMessagingModule,
		PdfTicketComponentModal,
	],
})
export class AtRiskDevicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: IDataModal;
	searchFilters: any;
	dataModalService = inject(DataModalService);
	dashboardReport = inject(DashboardReportService);
	toastMessageService = inject(ToastMessageService);

	_cd = inject(ChangeDetectorRef);
	message = {
		m1: 'Results are limited to the first 100 tickets. Use the scroll bar to view more tickets or download the file to view all tickets.',
		m2: '',
	};

	subscription: Subscription = new Subscription();
	constructor() {}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
		this.dashboardReport.resetTotalTickets();
	}

	fetchNewData() {
		const filters: IPageState = this._getFilters();

		this.searchFilters = filters;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.fetchNewData();
			this._cd.detectChanges();
		}, 0);
	}
	
	private _getFilters(): IPageState {
		return this.data.templateData!['filters'];
	}

	download() {
		this.dashboardReport
			.downloadTicket(this.data.templateData!['pdfUrl'], {
				...this._getFilters(),
				exportType: 1,
			})
			.subscribe({
				next: (res: any) => {
					const link = document.createElement('a');
					link.href = res;
					link.setAttribute('download', 'AtRiskDevices');
					document.body.appendChild(link);
					link.click();
				},
				error: () => {
					this.toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
				complete: () => {
					this.toastMessageService.showSuccessMessage(
						NotificationMessages.Export
					);
				},
			});
	}

	capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}
}
