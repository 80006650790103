import { Injectable } from '@angular/core';
import { WidgetCardList, WidgetListWithCategory } from '@app/shared/interfaces/dashboard.interface';
import { DashboardTab } from '@app/shared/interfaces/dashboards/dashboard-tab.interface';
import { Subject } from 'rxjs';
import { DashboardCardTabService } from './dashboard-tab-card.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetService {
  private _tab: DashboardTab;
  private _widgetList: WidgetCardList[] = [];

  private moveWidgets = new Subject<number>(); 
  private newWidgetList = new Subject<WidgetListWithCategory[]>();
  public moveToDashboard = this.moveWidgets.asObservable();
  public refreshWidgets = this.newWidgetList.asObservable();

  public dragDropEvent$ = new Subject<'start'|'end'>();

  get tab(): DashboardTab {
    return this._tab;
  }

  get widgetList(): WidgetCardList[] {
    return this._widgetList;
  }

  constructor(private _dashboardTabCardService : DashboardCardTabService) {}

  setTab(tab: DashboardTab): void {
    this._tab = tab;
  }

  setWidgetList(widgetList: WidgetCardList[]): void {
    this._widgetList = widgetList;
  }

  moveWidgetsToDashboard(w:number){
    this.moveWidgets.next(w);
  }

  refreshWidgetList(): void {
    if(this._widgetList?.length) {
      let cardsIds: number[] = [];
      if(this._tab?.cards.length) {
        cardsIds = this._tab.cards.map(x => x.id);
      }

      const newData = this._widgetList.map(x => {
        return {
          ...x,
          cards: x.cards.filter(y => !cardsIds.includes(y.id))
        }
      }) as unknown as WidgetListWithCategory[];
  
      this.newWidgetList.next(newData)
    }
  }
}