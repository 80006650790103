
  <div class="row mt-4">
    <!-- <div class="col-12 col-md-3 mb-4 mb-md-0">
      <app-user-nav></app-user-nav>
    </div> -->

    <div class="col-12 col-md-12 mb-4 mb-md-0">
      <router-outlet></router-outlet>
    </div>
  </div>


<!-- <div class="main-wrap">
  <div class="row">
    <div class="col-md-3">
      <app-user-nav></app-user-nav>
    </div>
    <div class="col-md-9">

    </div>
  </div>
</div> -->
