import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ResponseResult } from '@app/shared/interfaces/general/response/response-result.interface';
import { SrBoard } from '@app/shared/interfaces/sr-boards/sr-board.interface';

@Injectable({
  providedIn: 'root',
})
export class SetupService {
  searchText = '';
  constructor(private _http: HttpClient) {}
  clearSearch() {
    this.searchText = '';
  }
  getBoards(
    page: number,
    pageSize: number,
    column: string,
    order: string,
    searchText: string,
    userRole: number,
    userId: number | null,
    companyId: number | null,
    groupId: number | null = null,
    visibilityId: number | string = ''
  ) {
    let apiUrl = `${environment.apiBaseUrl}SRBoard?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&searchText=${searchText}&RoleId=${userRole}`;

    if(userId) {
      apiUrl += `&UserId=${userId}`;
    }
    if(companyId) {
      apiUrl += `&CompanyId=${companyId}`;
    }

    if(groupId) {
      apiUrl += `&groupId=${groupId}`;
    }


    if (visibilityId !== '') {
      apiUrl += `&visibilityId=${visibilityId}`;
    }

    return this._http.get<ResponseResult<SrBoard[]>>(apiUrl);
  }

  addBoards(payload: Params) {
    return this._http.post(`${environment.apiBaseUrl}SRBoard`, payload);
  }

  updateBoards(id: number, payload: Params) {
    return this._http.put(`${environment.apiBaseUrl}SRBoard/${id}`, payload);
  }

  getBoardDetails(id: number) {
    return this._http.get(`${environment.apiBaseUrl}SRBoard/${id}`);
  }

  getCompanyList() {
    return this._http.get(`${environment.apiBaseUrl}Companies/GetDropdown`);
  }

  getBoardsCompanyList() {
    return this._http.get(`${environment.apiBaseUrl}SRBoard/GetBoardCompaniesDropdown`);
  }

  getCompanyDetails(id: number) {
    return this._http.get(`${environment.apiBaseUrl}Companies/${id}`);
  }

  getBoardCompanyList(
    page: number,
    pageSize: number,
    column: string,
    order: string,
    searchText: string,
    boardId: number
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}SRBoard/GetBoardCompanyGetListByBoardId?Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&searchText=${searchText}&boardId=${boardId}`
    );
  }

  removeCompany(boardId: number, companyId: number) {
    return this._http.delete(
      `${environment.apiBaseUrl}SRBoard/${boardId}/${companyId}`
    );
  }

  updateDefaultBoardForCompany(boardId: number, companyId: number): Observable<void> {
    const url = `${environment.apiBaseUrl}SRBoard/UpdateDefaultBoardForCompany?boardId=${boardId}&companyId=${companyId}`;

    return this._http.put<void>(url, {
      boardId,
      companyId,
    });
  }

  setTicketAccess(prop: any, endpoint: string): Observable<void> {
    const url = `${environment.apiBaseUrl}GlobalPermissions/${endpoint}`;
    return this._http.post<void>(url, { ...prop });
  }
}
