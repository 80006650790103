<div class="row" [ngClass]="{ 'm-0': !isMainPage }">
  <div class="col-12">
    <div [class.card]="isMainPage">
      <div class="card-header pb-0">
        <div class="d-lg-flex">
          <div class="ms-auto my-auto mt-lg-0 mt-4">
            <div class="ms-auto my-auto">
              <input
                #search
                type="text"
                class="search-text"
                (input)="onSearch()"
                (keydown.enter)="setupAndSendProps()"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pb-0 pt-0">
        <div class="table-responsive directory-table">
          <div
            class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
          >
            <div class="dataTable-container">
              <app-column-slider
              [(displayedColumns)]="displayedColumns"
              ></app-column-slider>
              <table
                id="products-list"
                class="table table-flush dataTable-table"
                mat-table
                [dataSource]="dataSource"
                matSort
                matSortActive="name"
                matSortDirection="asc"
                matSortDisableClear="true"
                (matSortChange)="sortData($event)"
              >
                <ng-container matColumnDef="name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [mat-sort-header]="
                      dataSource && dataSource.filteredData.length > 0
                        ? 'name'
                        : ' '
                    "
                    [disabled]="
                      !(dataSource && dataSource.filteredData.length > 0)
                    "
                  >
                    Company Name
                  </th>
                  <td class="dark-color" mat-cell *matCellDef="let row">
                    <div class="d-flex">
                      <p class="text-xs text-secondary mb-0">{{ row.name }}</p>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="usersWithPerm">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Users with Access
                  </th>
                  <td class="dark-color" mat-cell *matCellDef="let row">
                    <div class="d-flex">
                      <p class="text-xs text-secondary mb-0">
                        {{ row.userPermissionCount }}
                      </p>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="usersCount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Total Users
                  </th>
                  <td class="dark-color" mat-cell *matCellDef="let row">
                    <div class="d-flex">
                      <p class="text-xs text-secondary mb-0">
                        {{ row.userCount }}
                      </p>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td
                    class="text-center px-4 py-3 action-options align-middle"
                    mat-cell
                    *matCellDef="let row"
                    (click)="$event.stopPropagation()"
                  >
                    <ng-container *ngIf="!isActionButtonsDisabled">
                      <a
                        class="edit visiblity-icon"
                        [tooltipContentV2]="{ message: 'Edit Company Permission' }"
                        (click)="
                          editCompanyPermission(
                            row.id,
                            row.hasUserLevelPermission
                          )
                        "
                      >
                        <img src="{{'./../../../../../../assets/images/icons/edit-icon.svg'}}" alt="">
                      </a>
                      <a
                        href="javascript:void(0)"
                        class="link--hidden visiblity-icon imp-disabled-op"
                        mat-raised-button
                        [tooltipContentV2]="{ message: 'Remove Company' }"
                        (click)="
                          $event.stopPropagation();
                          onRemoveCompany(row.id, row.name)
                        "
                      >
                        <img src="{{'./../../../../../../assets/images/icons/trash-icon.svg'}}" alt="">
                      </a>
                    </ng-container>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  (click)="
                    editCompanyPermission(row.id, row.hasUserLevelPermission)
                  "
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4" style="text-align: center !important;">
                    {{ message.noRecord }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="table-paginator-container" [hidden]="!hasData">
            <div class="paginator-group">
              <table-paginator
                [totalItems]="totalItems"
                [pageSizes]="pageSizes"
                (pageChange)="setupAndSendProps('page', $event)"
              ></table-paginator>
            </div>

            <div class="item-per-page-group">
              <app-item-per-page
                (sizeChange)="setupAndSendProps('size', $event)"
                [totalItems]="totalItems"
              ></app-item-per-page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
