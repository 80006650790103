import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import {
	CurrentLevel,
	GlobalAccess,
	OptionsAddEditModeModel,
	OptionsInterface,
	OptionsModel,
} from '@app/shared/functions/options';

import { UserService } from '@app/core/services/user.service';
import { GlobalPermissionsService } from '@app/shared/services/base-application.service';

@Component({
	selector: 'app-global-add-edit-applications',
	template: `<app-application-tab></app-application-tab>`,
	styles: [''],
	providers: [GlobalPermissionsService],
})
export class GlobalAddEditApplicationsComponent implements OnInit {
	optionsAddEdit$: Observable<OptionsModel | OptionsAddEditModeModel | null>;

	constructor(
		private _globalPermissionService: GlobalPermissionsService,
		private _userService: UserService
	) {}

	ngOnInit() {
		this.optionsAddEdit$ = this._globalPermissionService.options$;

		const data: OptionsInterface = {
			levelId: this.assignLevelId(),
			roleId: this._userService.userRole,
			moduleId: GlobalAccess.Application,
		};

		this._globalPermissionService.setData(data, 'add/edit');
	}

	assignLevelId() {
		if (this._userService.isSpAdmin) {
			return CurrentLevel.GLOBAL;
		} else if (this._userService.isClientAdmin) {
			return CurrentLevel.COMPANY;
		} else return CurrentLevel.CONTACT;
	}
}
