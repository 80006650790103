import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
/* Angular Libraries */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

/* Third Party Libraries */
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { PaymentProcessService } from './payment-process.service';
import { FinanceService } from '../../finance.service';
import { NotificationService } from '@app/core/services/notification.service';

/* Functions */
import { ItemPerPageComponent } from '@app/shared/components/paginator/item-per-page/item-per-page.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { SelectMethodComponent } from '../select-method/select-method.component';

/* Interfaces */
import { TableMessages, PaginationConstants, NotificationMessages } from '@app/shared/constants';
import { DefaultCreditAmount, TransactionTypeName } from '@app/shared/constants/transaction-type.enum';
import { BankMethod, CardMethod, Credit, CreditsData, InvoiceSummary, PayButtonLabel, PendingPayment, RequestPayment } from '@app/shared/interfaces/invoice.interface';


@Component({
	selector: 'app-payment-process',
	templateUrl: './payment-process.component.html',
	styleUrls: ['./payment-process.component.scss'],
})

export class PaymentProcessComponent implements OnInit, OnDestroy, AfterViewInit {
	/* ViewChild */
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ViewChild(ItemPerPageComponent) itemsPerPage: ItemPerPageComponent;
	@ViewChild(SelectMethodComponent) selectMethodComponent: SelectMethodComponent;

	/* Public Properties */
	cardOption: Array<CardMethod> = [];
	bankOption: Array<BankMethod> = [];
	selectedId: string[] = [];
	invoiceList: Array<InvoiceSummary> = [];
	hasPendingValue: number;
	paymentType: FormControl = new FormControl('');
	displayedColumns = [
		'selectedMemoCredit',
		'id',
		'invoiceNumber',
		'type',
		'creditMemoDate',
		'isNetsuiteUpdated',
		'status',
		'currency',
		'amount',
		'originalAmount',
		'action',
	];
	creditMemoList: Array<Credit> = [];
	dataSource: MatTableDataSource<Credit> | Credit[] = [];
	selectedInvoice: Array<InvoiceSummary> = [];
	message = { noRecord: TableMessages.EmptyTable };
	pendingPayment: PendingPayment = {
		credits: 0,
		invoice: 0,
		pendingPaymentAmount: 0,
	};
	totalItems: number = 0;
	pageSizes: number[] = PaginationConstants.pageSizes;
	selectedPageSize: number = 10;
	selectedPage: number = 1;
	selectedSortColumn: string = 'creditMemoDate';
	selectedSortType: string = 'desc';
	queryString: FormControl = new FormControl('');
	searchTimeout: any;
	selectedCreditMemoIds: number[] = [];
	isCheckedAll: boolean = false;
	hasUnappliedPaymentSelected: boolean = false;
	isAllUnappliedPaymentSelected: boolean = false;
	isFilterHaveBeenCalled: boolean = false;
	hasSelectedCredits: boolean = false;
	totalPaymentAmount: number = 0;
	totalCreditMemoAmount: number = 0;
	payButtonLabel: PayButtonLabel = {
		isValid: false,
		isShowPayNowLabel: false,
		isShowApplyCreditLabel: false,
		isShowPaymentWithUnappliedPaymentLabel: false,
		isShowApplyCreditAndPayLabel: false
	};
	isInitialLoad: boolean = false;

	/* Private Properties */
	private _$unsubscribe: Subject<void> = new Subject<void>();

	get isAdmin() {
		return this._stripeService.isAdmin;
	}

	/* Constructor */
	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _stripeService: StripeService,
		private _spinner: SpinnerService,
		private _toastMessageService: ToastMessageService,
		private _invoiceService: FinanceService,
		private _paymentProcessService: PaymentProcessService,
    private _cd: ChangeDetectorRef,
		private _notifier: NotificationService,
	) {}

	/* Methods */
	ngOnInit(): void {
		this.isInitialLoad = true;
		this._clearAllSelectedRecords();
		this.initQueryParam();
	}

  ngAfterViewInit(): void {
    this._cd.detectChanges();
  }

	initQueryParam() {
		this._route.queryParamMap
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (params) => {
				if (params.getAll('selectedId').length) {
					window.scroll(0, 0);
					this.selectedId = params.getAll('selectedId');
					this.initSummaryInvoice();
					this.initCreditMemo();
					this.initSelectedInvoice();

				} else {
					this._router.navigate([`/${this.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices`]);
				}
			},
			error: (err) => {
				this._toastMessageService.showErrorMessage(err);
			},
		});
	}

	initSummaryInvoice() {
		if (this.selectedId.length) {
			this._spinner.start();

			this._invoiceService
				.getForPayment(this.selectedId)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (result: Array<InvoiceSummary>) => {
						this.invoiceList = result;
						this.invoiceList.filter(inv => {
							inv['partialAmount'] = Number(inv.balance).toFixed(2);
							inv['partialAmountInput'] = inv['partialAmount'];
							inv['isOpen'] = false;
						});
						this._stripeService.setSelectedInvoice(result);
					},
					error: (err) => {
						this._spinner.stop();
						this._toastMessageService.showErrorMessage(err);
					},
					complete: () => {
						this._spinner.stop();
					},
				});
		}
	}

	async submit() {
		if (this.paymentType.value === 'one-time-payment') {
			this.selectMethodComponent.createNewPaymentMethod();

		} else {
			this._RequestPayment();
		}
	}

	onCallRequestPaymentUpdated() {
		this._RequestPayment();
	}

	setLoading(isLoading: boolean) {
		const doc: any = document;

		if (isLoading) {
			// Disable the button and show a spinner
			doc.querySelector('#submit').disabled = true;
			doc.querySelector('#spinner').classList.remove('hidden');
			doc.querySelector('#button-text').classList.add('hidden');

		} else {
			doc.querySelector('#submit').disabled = false;
			doc.querySelector('#spinner').classList.add('hidden');
			doc.querySelector('#button-text').classList.remove('hidden');
		}
	}

	initCreditMemo() {
		this._spinner.start();

		if (this.paginator !== undefined) {
			this.paginator.page = this.isFilterHaveBeenCalled ? 1 : this.paginator.page;
		}

		this._stripeService
			.getCreditMemoList(
				this.paginator?.page ? this.paginator?.page : this.selectedPage,
				this.itemsPerPage?.pageSize ? this.itemsPerPage?.pageSize : this.selectedPageSize,
				this.selectedSortColumn,
				this.selectedSortType,
				this.queryString.value!.trim()
			)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: CreditsData) => {
					this.creditMemoList = result.data;

					this.creditMemoList.filter((d) => {
						d['isChecked'] = false;
						d['toApply'] = DefaultCreditAmount.creditAmount;
					});

					if (this._paymentProcessService.selectedCreditItems.length) {
						let indexArr: any = [];
						this._paymentProcessService.selectedCreditItems.filter((selectedInv: any, i: number) => {
								indexArr[i] = this.creditMemoList.findIndex(inv => selectedInv.id === inv.id);

								if (this.creditMemoList[indexArr[i]]) {
									this.creditMemoList[indexArr[i]].isChecked = true;
									this.creditMemoList[indexArr[i]].toApply = parseFloat(selectedInv.toApply).toFixed(2);
								}
							}
						);

					}

					this.dataSource = new MatTableDataSource(result.data);
					this.totalItems = result.totalCount;
					this.paginator.size = result.pageSize;
					this.paginator.page = result.currentPage;
					this.isFilterHaveBeenCalled = false;
					this.isInitialLoad = false;
					this._spinner.stop();
				},
				error: () => {
					this._spinner.stop();
				},
			});
	}

	initSelectedInvoice() {
		this._stripeService.selectedInvoiceRef
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: Array<InvoiceSummary>) => {
					if (Array.isArray(result) && result.length) {
						this.selectedInvoice = result;
						this.initPendingPayment();
					}
				},
			});
	}

	initPendingPayment() {
		setTimeout(() => {
			this.pendingPayment.invoice = this.invoiceList
				.map((e: any) => Number(e.partialAmount))
				.reduce((a: any, b: any) => a + b, 0);

			this.pendingPayment.credits =
				this._paymentProcessService.selectedCreditItems
					.filter((inv: any) => inv.isChecked)
					.map((e: any) => Number(e.toApply))
					.reduce((a: any, b: any) => a + b, 0);

			this.pendingPayment.pendingPaymentAmount =
				this.pendingPayment.invoice > this.pendingPayment.credits
					? this.pendingPayment.invoice - this.pendingPayment.credits
					: this.pendingPayment.credits - this.pendingPayment.invoice;

			this.hasPendingValue = Number(this.pendingPayment.pendingPaymentAmount.toFixed(2));
			this.selectedCreditMemoIds = this.creditMemoList.filter((inv) => inv.isChecked).map((inv) => inv.id);
			this.isCheckedAll = !this.isInitialLoad ? this.creditMemoList.every((cred) => cred.isChecked || cred.isChecked === null) || !this.hasPendingValue : false;

			this.creditMemoList.filter(cred => {
				if (Number(cred.toApply) === NaN || cred.toApply === 'NaN') {
					cred.toApply = DefaultCreditAmount.creditAmount;
				}
			});

			this.hasUnappliedPaymentSelected = this._paymentProcessService.selectedCreditItems.some((cred: any) => cred.isChecked && cred.transactionType === TransactionTypeName.payment);
			this.isAllUnappliedPaymentSelected = this._paymentProcessService.selectedCreditItems.every((cred: any) => cred.transactionType === TransactionTypeName.payment) && this._paymentProcessService.selectedCreditItems.length > 0;
			this.hasSelectedCredits = this._paymentProcessService.selectedCreditItems.some((cred: any) => cred.isChecked);

			this.totalCreditMemoAmount = this._paymentProcessService.getCreditsTotalAmount(TransactionTypeName.creditMemo);
			this.totalPaymentAmount = this._paymentProcessService.getCreditsTotalAmount(TransactionTypeName.payment);
			setTimeout(() => this.selectMethodComponent.onChangeSelectedCredits(), 0);
		}, 0);
	}

	onSelectCreditMemo(row: any = null) {
		if (row) {
			this._paymentProcessService.isCalculationsDone = false;
			this.onCreditAmountChange(row, true);
			row.isChecked ? this._paymentProcessService.checkItems(row) : this._paymentProcessService.uncheckItems(row);

		} else {
			this.creditMemoList.forEach((cred) => {
				this.onCreditAmountChange(cred, true);
				this._paymentProcessService.checkAllItems(cred, this.isCheckedAll);
			});
		}

		this.initPendingPayment();
		this.convertIntoDecimal();
	}

	changePage() {
		this.initCreditMemo();
	}

	updateSize() {
		this.paginator.size = this.itemsPerPage.pageSize;
    this.paginator.setTotalPages();
    this.paginator.setPages();

		if(this.paginator.totalItems < this.paginator.size * (this.paginator.page - 1) + 1){
      this.paginator.changePageWithoutEmit(1);
    }

		this.initCreditMemo();
	}

	sortChangeEvent(sortEvent: any) {
		this.selectedSortColumn = sortEvent.active;
		this.selectedSortType = sortEvent.direction;
		this.initCreditMemo();
	}

	checkAllRecords() {
		if (!this.isCheckedAll) {
			this._paymentProcessService.isCalculationsDone = false;

		} else {
			const clearItems = this.creditMemoList.filter(cred => this._paymentProcessService.selectedCreditItems.map((d: any) => d.id).includes(cred.id));
			clearItems.filter(cred => this._paymentProcessService.uncheckItems(cred));
		}

		this.creditMemoList.filter((cred) => {
			if ((cred.isNetsuiteUpdated || cred.isNetsuiteUpdated === null) && (cred.amount > 0)) {
				cred.isChecked = this.isCheckedAll;

			} else {
				cred.isChecked = null;
				cred.toApply = DefaultCreditAmount.creditAmount;
			}

		});
		this.onSelectCreditMemo();
	}

	queryChange(delayTime: number = 0) {
		clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(() => {
			this.isFilterHaveBeenCalled = true;
			this.isCheckedAll = !this.hasPendingValue;
			this.initCreditMemo();
		}, delayTime);
	}

	onCreditAmountChange(row: any, isSelectCreditRecord: boolean = false) {
		this._paymentProcessService.creditInputCalculation(row, isSelectCreditRecord, this.pendingPayment);

		if (!isSelectCreditRecord) {
			this.initPendingPayment();
			this._paymentProcessService.creditInputCalculation(row, isSelectCreditRecord, this.pendingPayment);
			this.selectedCreditMemoIds = this.creditMemoList.filter((inv) => inv.isChecked).map((inv) => inv.id);
			this.initPendingPayment();
		}
	}

	convertIntoDecimal() {
		this.creditMemoList.filter(cred => {
			!['', '0', 0, null].includes(Number(cred.toApply)) ? cred.toApply = Number(cred.toApply).toFixed(2) : '';

			if (Number(cred.toApply) === NaN || cred.toApply === 'NaN') {
				cred.toApply = DefaultCreditAmount.creditAmount;
			}
		});
	}

	partialAmountConvertIntoDecimal() {
		this.invoiceList.filter(inv => inv.partialAmountInput = Number(inv.partialAmountInput).toFixed(2));
	}

	onSelectedMethodUpdated(event: any) {
		this.paymentType.setValue(event);
	}

	onInitPaymentMethodListUpdated(event: any) {
		this.cardOption = event.paymentMethodList.card;
		this.bankOption = event.paymentMethodList.bankAccount;
		this.paymentType.setValue(event.defaultSelectedPaymentId);
	}

	onCreditsCheckedUpdated(data: any) {
		this.payButtonLabel = {
			isValid: data.isValid,
			isShowPayNowLabel: data.isShowPayNowLabel,
			isShowApplyCreditLabel: data.isShowApplyCreditLabel,
			isShowPaymentWithUnappliedPaymentLabel: data.isShowPaymentWithUnappliedPaymentLabel,
			isShowApplyCreditAndPayLabel: data.isShowApplyCreditAndPayLabel
		};
	}

	applyPartialAmount(row: any) {
		if (Number(parseFloat(row.partialAmountInput).toFixed(2)) < 0.50) {
			row.partialAmountInput = row.balance.toFixed(2);
			this._notifier.notifyError('Partial Amount Error', 'Enter a value greater than $0.50 minimum');
			this.initPendingPayment();
			return;
		}

		if (Number(parseFloat(row.partialAmountInput).toFixed(2)) <= Number(parseFloat(row.balance).toFixed(2))) {
			row.partialAmount = row.partialAmountInput;

		} else {
			row.partialAmountInput = row.balance.toFixed(2);
		}

		this.initPendingPayment();
	}

	getOutstandingBalance(row: any) {
		return Number(row.balance) - Number(row.partialAmount);
	}

	private _RequestPayment() {
		this.setLoading(true);
		this._spinner.start();

		const selectedInvoiceAmount =
		this.invoiceList.map((inv: any) => {
			return {
				id: inv.id,
				amount: Number(inv.partialAmount),
			};
		});

		const selectedCreditsAmount =
		this._paymentProcessService.selectedCreditItems
		.filter((cred: any) => cred.transactionType === TransactionTypeName.creditMemo || cred.transactionType === TransactionTypeName.payment)
		.map((cred: any) => {
			return {
				id: cred.id,
				amount: Number(cred.toApply),
			};
		});

		const payload = {
			invoices: selectedInvoiceAmount,
			credits: selectedCreditsAmount,
			paymentMethodId: this.paymentType.value ? this.paymentType.value : null
		};

		this._stripeService.cancelAllRequiresAction()
      .subscribe(result => {
				this._stripeService
				.requestPayment(payload)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (transaction: RequestPayment) => {
						localStorage.setItem('stripePaymentRecord', JSON.stringify([]));
						this._paymentStatus(transaction);
						this.setLoading(false);
						this._spinner.stop();
					},
					error: (err) => {
						this.setLoading(false);
						this._spinner.stop();
					},
				});
			});
	}

	private _paymentStatus(transaction: RequestPayment) {
		const hasTransactionThatRequiresActions = transaction.data.some(inv => inv.message === 'requires_action');

		if (hasTransactionThatRequiresActions) {
			this._redirectedToPaymentConfirmation(transaction, true);

		} else {

			this._redirectedToPaymentConfirmation(transaction);
			// let isAllTransactionSuccess = transactionList.every((inv: any) => inv.isSucceeded || inv.isProcessing);

			// if (isAllTransactionSuccess) {
			// 	this._redirectedToPaymentConfirmation(transactionList);

			// } else {
			// 	let hasSuccessTransction = transactionList.some((inv: any) => inv.isSucceeded || inv.isProcessing);
			// 	let failedTransactionsList = transactionList.filter((inv: any) => !inv.isSucceeded && !inv.isProcessing);

			// 	failedTransactionsList.filter((inv: any) => this._toastMessageService.showFailedMessages(`Payment Transaction Failed`, inv.message));

			// 	if (hasSuccessTransction) {
			// 		setTimeout(() => this._redirectedToPaymentConfirmation(transactionList), 5000);
			// 	}
			// }
		}
	}

	private _redirectedToPaymentConfirmation(transaction: RequestPayment, isRedirectToPaymentSecurity: boolean = false) {
		localStorage.setItem('stripePaymentRecord', JSON.stringify(transaction));
		this._router.navigate([`/${this.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices/payment-process/${ isRedirectToPaymentSecurity ? 'payment-security' : 'payment-confirm' }`]);
	}

	private _clearAllSelectedRecords() {
		this.isCheckedAll = false;
    this.checkAllRecords();
		this._paymentProcessService.selectedCreditItems = [];
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
