<!--PUT YOUR TEMPLATES HERE-->
<ng-container *ngIf="template == 'default'">
  <ul class="list-unstyled d-flex d-inline-flex m-0 default">
    <li class="">
      <label for="startDate">{{dateLabels.start}}</label>
      <ng-container [ngTemplateOutlet]="startDateTpl"></ng-container>
    </li>
    <li>
      <label for="endDate">{{dateLabels.end}}</label>
      <ng-container [ngTemplateOutlet]="endDateTpl"></ng-container>
    </li>
  </ul>
</ng-container>


<ng-container *ngIf="template == 'double-li'">
  <li>{{dateLabels.start}}</li>
  <li>
    <div class="dateFilterSample">
      <ng-container [ngTemplateOutlet]="startDateTpl"></ng-container>
    </div>
  </li>
  <li>{{dateLabels.end}}</li>
  <li>
    <div class="dateFilterSample">
      <ng-container [ngTemplateOutlet]="endDateTpl"></ng-container>
    </div>
  </li>
</ng-container>

<ng-container *ngIf="template == 'row-inline'">
  <div class="row">
    <div class="col-md-3">
      <div>
        <label>{{dateLabels.start}}</label>
        <ng-container [ngTemplateOutlet]="startDateTpl"></ng-container>
      </div>
    </div>
    <div class="col-md-3">
      <div>
        <label>{{dateLabels.end}}</label>
        <ng-container [ngTemplateOutlet]="endDateTpl"></ng-container>
      </div>
    </div>
  </div>
  <!-- <div class="d-inline-flex w-100">
    <div class="cus-field cat-select w-100 mr-3">
      <label for="" class="d-inline">{{dateLabels.start}}</label>
      <ng-container [ngTemplateOutlet]="startDateTpl"></ng-container>
    </div>
    <div class="cus-field cat-select w-100">
      <label for="" class="d-inline">{{dateLabels.end}}</label>
      <ng-container [ngTemplateOutlet]="endDateTpl"></ng-container>
    </div>
  </div> -->
</ng-container>



<!--JUST CALL THESE TO INTEGRATE WITH YOUR TEMPLATE/HTML-->
<ng-template #startDateTpl>
  <date-input
    [max]="endDate"
    (dateChange)="onChangeStartDate($event!)"
    [formControl]="startDateFc"
  ></date-input>
  <!-- <app-date-picker
    [inputClass]="getDateClass"
    [max]="endDate"
    (changeDate)="onChangeStartDate($event)"
    [formControl]="startDateFc"
  ></app-date-picker> -->
</ng-template>

<ng-template #endDateTpl>
  <date-input
    [min]="startDate"
    (dateChange)="onChangeEndDate($event!)"
    [formControl]="endDateFc"
  ></date-input>
  <!-- <app-date-picker
    [inputClass]="getDateClass"
    [min]="startDate"
    (changeDate)="onChangeEndDate($event)"
    [formControl]="endDateFc"
  ></app-date-picker> -->
</ng-template>
