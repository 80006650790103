export type UserMessageCategoryNotification = {
      id : number,
      name : string,
      iconName : string,
      color : string,
      messageCount : number,
}

export type UserMessageCategory = {
      id : number,
      name : string,
      iconName : string,
      color : string,
      messageCount : number,
      link?:string,
      countAll:number,
      countUnRead:number,
}

export type UserMessageCategoryCount = {
      id : number,
      countAll : number,
      countUnRead : number,
}

export type UserMessageNotificationCount = {
      categoryId: number,
      notificationCount : number
}

export type MessageNotificationCounts = {
      countAll: number,
      countBroadcast : number,
      countTickets : number,
      countMessages: number,
      countBell: number,
}

export type UserMessagePendingNotificationDetail = {
      id: number,
      refId: number,
      title: string,
      message: string,
      categoryId: number,
      createdDate: string,
      catedByName: string,
      createdByEmail: string,
      _elapsedtime : any,
      imagePath: string,
      count: number,
}