<div class="card-body add-edit-form">
	<div class="pro-conts categories-form">
		<h6 class="text-sm">Topic Details</h6>
		<form [formGroup]="form" class="mt-2" (ngSubmit)="onSubmit()">
			<div class="form-group">
				<label class="form-control-label">Categories*</label>
				<select-input
					formControlName="KBCategoryId"
					[options]="categories"
					[optionKeys]="{ value: 'id', label: 'name' }"
					inputClass="form-control"
					placeholder=""
				>
				</select-input>
			</div>
			<div class="form-group">
				<label class="form-control-label">Title*</label>
				<input
					formControlName="Title"
					type="text"
					class="form-control"
					(ngModelChange)="resetError()"
					(input)="onValidChange()"
          maxlength="100"
				/>
			</div>
			<div class="form-group">
				<div class="col-lg-3 col-md-3 text-right">
					<label class="form-control-label">Description</label>
				</div>
				<div class="editor-container">
					<div class="editor">
						<div id="gjs"></div>
						<div id="blocks"></div>
					</div>
					<input type="hidden" name="Description" />
				</div>
			</div>
			<h6 class="text-sm">Tags</h6>
			<div class="form-group">
				<label class="form-control-label">Primary Tag*</label>
				<input
					formControlName="PrimaryTag"
					type="text"
					class="form-control text-xs"
				/>
			</div>
			<div class="form-group">
				<label class="form-control-label">Related Tags</label>
				<div class="media-select">
					<mat-form-field class="chip-list w-100" appearance="outline">
						<mat-chip-list #chipList aria-label="Related Tag selection">
							<mat-chip
								class="d-flex align-items-center"
								*ngFor="let tag of relatedTags"
								(removed)="remove(tag)"
							>
								{{ tag }}
								<app-local-figma-icon-img
									matChipRemove
									[filename]="'x-white'"
									[hasSpaceOnRight]="false"
								></app-local-figma-icon-img>
							</mat-chip>
							<input
								placeholder="Select Tag"
								#relatedTagsInput
								formControlName="RelatedTags"
								[matAutocomplete]="auto"
								[matChipInputFor]="chipList"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
								(matChipInputTokenEnd)="add($event)"
								(keyup)="checkEmptyValue($event)"
								class="related-tag-input"
							/>
						</mat-chip-list>
						<mat-autocomplete
							#auto="matAutocomplete"
							(optionSelected)="selected($event)"
						>
							<mat-option
								*ngFor="let item of relatedTagList | async"
								[value]="item.id"
							>
								{{ item.name }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
			</div>
			<h6 class="text-sm">Related Details</h6>
			<div class="">
				<label class="form-control-label">Support Category</label>
				<div class="r-link media-select" formArrayName="CategoryIds">
					<div *ngFor="let category of form.controls.CategoryIds.controls; let i = index" [formGroupName]="i" class="d-flex media-select form-group" style="gap: 12px">
						<select-input
							formControlName="mainCategoryId"
							style="flex: 1;"
							placeholder="Select Main Category"
							[options]="filteredMainCategories[i]"
							[optionKeys]="{ value: 'id', label: 'name' }"
							inputClass="form-control">
						</select-input>
						<multi-select-input
							class="form-control support-request"
							formControlName="subCategoryIds"
							style="height: max-content;"
							placeholder="Select Sub Category"
							[options]="subCategories[category.controls['mainCategoryId'].value??0]"
							[optionKeys]="{ value: 'id', label: 'name' }">
						</multi-select-input>
						<app-figma-icon-img
							(click)="addRemoveSupportCategory(i)"
							class="extra-fields-customer"
							style="cursor: pointer;"
							[attr.data-iconName]="i === 0 ? 'plus' : 'minus'"
							[iconName]="i === 0 ? 'plus' : 'minus'"
							[isForButton]="true"
							[hasSpaceOnRight]="false">
						</app-figma-icon-img>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label class="form-control-label">Support Request Form </label>
				<div class="cus-field media-select">
					<multi-select-input
						class="form-control support-request"
						formControlName="SupportRequest"
						[options]="supportRequestOptions"
						[selectedOptons]="selectedSupportRequestOptions"
						[autoClosePerSelect]="true"
					>
					</multi-select-input>
				</div>
			</div>
			<div class="form-group">
				<label class="form-control-label">Related Topics</label>
				<div class="cus-field media-select">
					<multi-select-input
						class="form-control related-topic"
						formControlName="RelatedTopics"
						[options]="relatedTopicsOptions"
						[selectedOptons]="selectedRelatedTopicsOptions"
						[autoClosePerSelect]="true"
					>
					</multi-select-input>
				</div>
			</div>
			<div class="form-group">
				<label class="form-control-label label-baseline">Related Links</label>
				<div class="r-link media-select" formArrayName="RelatedLinks">
					<div
						*ngFor="let link of relatedLinks.controls; let i = index"
						class="d-flex align-items-center flex-wrap"
					>
						<input
							name="links"
							class="links form-control d-inline customer_name text-xs"
							type="text"
							value=""
							[formControlName]="i"
						/>
						<a
							class="extra-fields-customer"
							href="javascript:void(0);"
							(click)="addRemoveLinks($event, i)"
						>
							<app-figma-icon-img
								[attr.data-iconName]="i === 0 ? 'plus' : 'minus'"
								[iconName]="i === 0 ? 'plus' : 'minus'"
								[isForButton]="true"
								[hasSpaceOnRight]="false"
							></app-figma-icon-img>
						</a>
						<br />
						<div
							*ngIf="relatedLinks.controls[i].errors?.['url']"
							class="d-block w-100"
							style="margin-top: -10px"
						>
							<small class="text-danger"> Please provide valid url </small>
						</div>
					</div>
				</div>
				<div class="customer_records_dynamic"></div>
			</div>
			<div class="submit-buttons q-d-flex q-jc-end q-g-16">
						<button
							type="submit"
							[disabled]="spinner.spinner | async"
							class="btn-2 btn-2-primary imp-disabled-op"
						>
							Save
						</button>
			</div>
		</form>
	</div>
</div>
