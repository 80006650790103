import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe, Location } from '@angular/common';

import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';

import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';
import { Order } from '@app/shared/interfaces/order.interface';
import { TableOptions } from '@app/shared/interfaces/table.interface';
import { PaginationConstants, TableMessages, Permission, NotificationMessages, FormUsage, TableSignalType } from '@app/shared/constants';

import * as moment from 'moment';

import { SpinnerService } from '@app/core/services/spinner.service';
import { QuotesOrdersService } from '../../quotes-and-orders.service';
import { UserService } from '@services/user.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { FormUsageService } from '@app/modules/form-usage/form-usage.service';
import { UserNotificationService } from '@app/shared/services/user-notification.service';
import { NotificationsClienthubService } from '@app/shared/services/notifications-clienthub.service';
import { filter, Subscription } from 'rxjs';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { NotificationService } from '@app/core/services/notification.service';
import { TableReloadSignalService } from '@app/shared/services/table-reload-signal.service';

@Component({
  selector: 'app-orders-detail',
  templateUrl: './orders-detail.component.html',
  styleUrls: ['./orders-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersDetailComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('productPaginator') productPaginator: PaginatorComponent;
  @ViewChild('invoicePaginator') invoicePaginator: PaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;

  form = new FormGroup({
    id: new FormControl(''),
    salesOrder: new FormControl(''),
    customerPO: new FormControl(''),
    description: new FormControl(''),
    orderDate: new FormControl(''),
    subtotal: new FormControl(''),
    total: new FormControl(''),
    taxTotal: new FormControl(''),
    status: new FormControl(''),
  });

  order: SortDirection = 'asc';
  column = 'identifier';
  columnInvoice = 'invoiceNumber';

  displayedColumns: string[] = [
    'id',
    'identifier',
    'trackingNumber',
    'description',
    'status',
    'quantity',
    'unitPrice',
    'total',
    'action'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  hasData: boolean = false;
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;

  displayedColumnsInvoice: string[] = [
    'invoiceNumber',
    'billAmount',
    'balance',
    'invoiceDate',
    'dueDate',
    'status',
    'action',
  ];
  dataSourceInvoice: MatTableDataSource<any> = new MatTableDataSource();
  hasDataInvoice: boolean = false;
  totalItemsInvoice = 0;
  pageSizesInvoice = PaginationConstants.pageSizes;
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  isReadOnly = true;

  id: number;

  private readonly _tableName = {
    Product: 'product',
    Invoice: 'invoice',
  };

  orderInquiryFormId: number;
  formUsageData: any;
  //isInquireEnabled = this._userService.hasPermission([Permission.UserSubmitInquiry]);
  isInquireEnabled = true;
  //orderSyncSub: Subscription;
  orderDetails: Order;

  constructor(
    public spinner: SpinnerService,
    private _cd: ChangeDetectorRef,
    private _quotesOrdersService: QuotesOrdersService,
    private _userService: UserService,
    private _formUsageService: FormUsageService,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _router: Router,
    private _toastMessageService: ToastMessageService,
    private _location: Location,
    private currencyPipe: CurrencyPipe,
    private userNotification: UserNotificationService,
    private notificationHub: NotificationsClienthubService,
    public _utilitiesService: UtilitiesService,
    private _notifier: NotificationService,
    private _tableReloadSignalService: TableReloadSignalService
  ) {
    /* this.orderSyncSub =
      this.notificationHub.cWSyncNotificationReceived.subscribe((s) =>
        this.userNotification.orderSyncRecevied(s)
      ); */
  }

  ngOnInit() {}

  ngOnDestroy() {
/*     if (this.orderSyncSub) {
      this.orderSyncSub.unsubscribe();
    } */
  }

  ngAfterViewInit() {
    this._activatedRoute.params.subscribe(params => {
      this.id = Number(params['id']);

      this._getOrderDetails();
      this.fetchNewData(this._tableName.Product);
      this.fetchNewData(this._tableName.Invoice);

      if (this.isInquireEnabled) this.getFormId();
    });

    this._initTableReloadFromSignaIR();
  }

  sortData(e: HeaderSort, tableName: string) {
    this.order = e.direction.toUpperCase() as SortDirection;

    if (tableName === this._tableName.Product) {
      this.column = e.active;
      this.fetchNewData(this._tableName.Product);
    }

    if (tableName === this._tableName.Invoice) {
      this.columnInvoice = e.active;
      this.fetchNewData(this._tableName.Invoice);
    }
  }

  fetchNewData(tableName: string, flag = 'page', pageOrSize?: number) {
    const params: TableOptions = {
      order: this.order,
    };

    this.spinner.start();
    if (tableName === this._tableName.Product) {
      if (flag === 'page') {
        this.productPaginator.page = pageOrSize || 1;
      } else if (flag === 'size') {
        this.productPaginator.size = pageOrSize!;
        this.productPaginator.setTotalPages();
        this.productPaginator.setPages();
      }

      params.page = this.productPaginator.page;
      params.pageSize = this.productPaginator.size;
      params.sort = this.column;
      this._quotesOrdersService
        .getOrderProductList(params, this.id)
        .subscribe({
          next: (resp: any) => {
            const data = resp.data;
            this.dataSource = new MatTableDataSource(data);
            this.hasData = !!data.length;
            this.totalItems = resp.totalCount;
            this.spinner.stop();
            this._cd.markForCheck();
          },
          error: () => {
            this.spinner.stop();
          }
        });
    }

    if (tableName === this._tableName.Invoice) {
      if (flag === 'page') {
        this.invoicePaginator.page = pageOrSize || 1;
      } else if (flag === 'size') {
        this.invoicePaginator.size = pageOrSize!;
        this.invoicePaginator.setTotalPages();
        this.invoicePaginator.setPages();
      }

      params.page = this.invoicePaginator.page;
      params.pageSize = this.invoicePaginator.size;
      params.sort = this.columnInvoice;
      this._quotesOrdersService
        .getOrderInvoices(params, this.id)
        .subscribe({
          next: (resp: any) => {
            const data = resp.data;
            this.dataSourceInvoice = new MatTableDataSource(data);
            this.hasDataInvoice = !!data.length;
            this.totalItemsInvoice = resp.totalCount;
            this.spinner.stop();
            this._cd.markForCheck();
          },
          error: () => {
            this.spinner.stop();
          }
        });
    }
  }

  goBack() {
    this._location.back();
  }

  formatDate(val: string) {
    return val ? moment(val).format('MMM DD, YYYY') : '';
  }

  printInvoice(id: number, invoiceNumber: number) {
    this.spinner.start();

    this._quotesOrdersService.printInvoice(id).subscribe({
      next: (resp: any) => {
        const blob = new Blob([resp.body], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');

        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.target = '_blank';
        downloadLink.click();

        this._toastMessageService.showSuccessMessage(
          NotificationMessages.FileGenerated
        );
      },
      error: () => {
        this._toastMessageService.showErrorMessage(
          NotificationMessages.FailedToGenerateFile
        );
      },
    });
  }

  navigateTo(id: number) {
    this._router.navigate([`/finance/invoices/${id}`]);
  }

  private _getOrderDetails() {
    this._quotesOrdersService.getOrderDetails(this.id).subscribe({
      next: (resp: Order) => {
        this.orderDetails = resp;
        this._setData(resp);
      },
      error: (error) => {
        if(error.status === 401) this._notifier.notifyError('No access to Order No: '+this.id);
        else this._notifier.notifyError(NotificationMessages.unable('fetch Order'), NotificationMessages.Try);
        this._router.navigate(['../'], {relativeTo: this._activatedRoute, replaceUrl: true});
      },
    });
  }

  private _setData(resp: any) {
    this.form = this._fb.group({
      id: [resp.id],
      salesOrder: [resp.salesOrder],
      customerPO: [resp.customerPO],
      description: [resp.description],
      orderDate: [this.formatDate(resp.orderDate)],
      subtotal: this.currencyPipe.transform(resp.subtotal),
      total: this.currencyPipe.transform(resp.total),
      taxTotal: this.currencyPipe.transform(resp.taxTotal),
      status: [resp.status],
    });

    this._cd.markForCheck();
  }

  getFormId() {
    this._formUsageService
      .getFormUsageByUsage(FormUsage.OrderInquiry)
      .subscribe({
        next: (res: any) => {
          const data = {
            usageId: FormUsage.OrderInquiry,
            data: { orderId: this.id },
          };
          (this.orderInquiryFormId = res[0]?.srFormId),
            (this.formUsageData = window.btoa(JSON.stringify(data)));
          this._cd.markForCheck();
        },
      });
  }
  badgeType(color: string) {
    if (color) {
      let value = `background: linear-gradient(310deg, ${color} 0%, ${this._utilitiesService.light(color)} 100%)`;
      return value;
    } else {
      return `background: linear-gradient(310deg, #5a5a5a 0%,  ${this._utilitiesService.light('#5a5a5a')} 100%)`;
    }
  }

  openURL(url: string) {
    if (!!url) {
      window.open(url, '__blank');
    }
  }

  isSpAdmin(){
    return this._userService.user?.systemRoleId === 1;
  }

  get permission() {
    return Permission
  }

  private _initTableReloadFromSignaIR() {
		this._tableReloadSignalService.tableReloadFromSignaIR(TableSignalType.ORDER)
		.subscribe({ 
			next: () => {
        this._getOrderDetails();
			} 
		});
	}
}
