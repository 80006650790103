import { Injectable, inject } from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store } from '@ngrx/store';
import * as select from '../../core/store/portal-usage.selector';
import { ApplicationType, NotificationMessages } from '@app/shared/constants';
import { PortalExportList, PortalUsageFilters } from '../../core/portal-usage.interface';
import { initial } from '../../core/store';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize } from 'rxjs';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';


@Injectable()
export class ExportService {
  // injection
  private _store = inject(Store<AppStateInterface>)
	private _http = inject(HttpClient);
  private _spinner = inject(SpinnerService)
  private _toastMessageService = inject(ToastMessageService)

  // props
  private _exportFilters$ = this._store.select(select.exportFilters)
  private _companyReportFilters$ = this._store.select(select.companyReportFilters)
  private _userActivityFilters$ = this._store.select(select.userActivityFilters)
  private _userActivityHistoryFilters$ = this._store.select(select.userActivityHistoryFilters)
  private _exportType:ApplicationType = ApplicationType.CSV;

  constructor() { }

  // public method
  exportList(listType: PortalExportList, exportType: ApplicationType){
    if(!listType) return;
    this._exportType = exportType;
    switch(listType){
      case 'company':
        this._exportCompanyReports().subscribe(v=>this._downloadReport(v, 'Portal-Usage-Company-Reports'))
      break;
      case 'user activity':
        this._exportUserUsageReports().subscribe(v=>this._downloadReport(v, 'Portal-Usage-Users-Activity'))
      break;
      case 'activity history':
        this._exportActivityHistoryReports().subscribe(v=>this._downloadReport(v, 'Portal-Usage-Activity-History'))
      break;
    }
  }

  // private method
  private _exportCompanyReports() {
    const params = this._getParams('company', this._exportType);
    this._spinner.start();
    return this._http.get(`${environment.apiBaseUrl}PortalUsage/ExportByDateRange`, {
			params: params,
			responseType: 'text',
		}).pipe(finalize(()=>this._spinner.stop()))
  }
  private _exportUserUsageReports() {
    const params = this._getParams('user activity', this._exportType);

    this._spinner.start();
    return this._http.get(`${environment.apiBaseUrl}PortalUsage/ExportByCompany`, {
			params: params,
			responseType: 'text',
		}).pipe(finalize(()=>this._spinner.stop()))
  }
  private _exportActivityHistoryReports() {
    const params = this._getParams('activity history', this._exportType);

    this._spinner.start();
    return this._http.get(`${environment.apiBaseUrl}PortalUsage/ExportByUser`, {
			params: params,
			responseType: 'text',
		}).pipe(finalize(()=>this._spinner.stop()))
  }

  // Utilities (private)
  private _downloadReport(data:any, label:string='Potal-Usage'){
    const link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', label);
    document.body.appendChild(link);
    link.click();
    this._toastMessageService.showSuccessMessage(
      NotificationMessages.Export
    );
  }
  private _getParams(type: PortalExportList = 'company', exportType?: ApplicationType) {
		let filters: any = initial.exportFilters;

		if(type === 'company')
			this._companyReportFilters$.subscribe((v) => (filters = v));
    else if(type === 'user activity')
			this._userActivityFilters$.subscribe((v) => (filters = v));
    else if(type === 'activity history')
			this._userActivityHistoryFilters$.subscribe((v) => (filters = v));

    if(exportType!==undefined) filters = {...filters, ...{exportType}}
		return new HttpParams({ fromObject: filters });
	}

}
