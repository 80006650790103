/* Angular Libraries */ 
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

/* Third Party Libraries */ 
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { NotificationService } from '@app/core/services/notification.service';

/* Interfaces */
import { NotificationMessages } from '@app/shared/constants/global-constants'; 
import { VerifyBankAccountValidationMessages } from '../../validation-messages/invoice-validation-messages.inum';

@Component({
	selector: 'app-verify-bank-account',
	templateUrl: './verify-bank-account.component.html',
	styleUrls: ['./verify-bank-account.component.scss'],
})

export class VerifyBankAccountComponent implements OnInit, OnDestroy {
  /* Properties */
  verifyBankAccountForm: FormGroup;
	bankAccountId: string;
	validationMessages = VerifyBankAccountValidationMessages;

  private _$unsubscribe: Subject<void> = new Subject<void>();

  /* Constructor */
	constructor(
		private fb: FormBuilder,
		private _spinner: SpinnerService,
		private _stripeService: StripeService,
		private _router: Router,
		private _notifier: NotificationService,
	) {}

  /* Getters/Setters */
  get isAdmin() {
		return this._stripeService.isAdmin;
	}

  /* Methods */
	ngOnInit(): void {
		this.bankAccountId = new URLSearchParams(window.location.search).get('bankAccountId')?.toString() as any;
		this.initForm();
	}

	initForm() {
		this.verifyBankAccountForm = this.fb.group({
			firstDeposit: [''],
			secondDeposit: [''],
		});
	}

	saveVerifyBankAccount() {
		const params = {
			bankAccountId: this.bankAccountId,
			microDeposit: [
				Number(this.verifyBankAccountForm.controls['firstDeposit'].value),
				Number(this.verifyBankAccountForm.controls['secondDeposit'].value),
			],
		};

		this._spinner.start();

		this._stripeService
			.verifyBankAccount(params)
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result) => {
					this._router.navigate([`/${this.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices`]);
					this._spinner.stop();
				},
				error: () => {
					this._spinner.stop();
				},
			});
	}

	returnAsNumber(event: any) {
		return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
