import { createAction, props } from '@ngrx/store';

import { IApplication } from '../../application.interface';

export enum ActionTypes {
	CONTACTS_USER_APPLICATION_UPDATE = '[Contacts User Application List] Update',
	CONTACTS_USER_APPLICATION_UPDATE_SUCCESS = '[Contacts User Application List] Update Success',
}

export const contactsUserApplicationUpdateAction = createAction(
	ActionTypes.CONTACTS_USER_APPLICATION_UPDATE,
	props<Partial<IApplication>>()
);

export const contactsUserApplicationUpdateSuccessAction = createAction(
	ActionTypes.CONTACTS_USER_APPLICATION_UPDATE_SUCCESS
);
