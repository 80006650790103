import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

import {
	BehaviorSubject,
	finalize,
	Observable,
	tap,
	ReplaySubject,
} from 'rxjs';

import { HttpRoutes, ApplicationType } from '@app/shared/constants';

import { IOrders } from '@app/modules/quotes-and-orders/pages/orders/store/orders.interface';
import { IQuotes } from './pages/quotes/store/quotes.interface';
import { NameId } from '@app/shared/interfaces/support.interface';
import {
	Order,
	OrderInquiry,
	OrderStatus,
} from '@app/shared/interfaces/order.interface';
import { ParentChildAccessType } from '@app/shared/interfaces/companies.interface';
import { TableOptions } from '@app/shared/interfaces/table.interface';

@Injectable({
	providedIn: 'root',
})
export class QuotesOrdersService {
	orderSearchText = '';
	quoteSearchText = '';
	orderStatusIds: number[] = [];
	quoteStatusIds: number[] = [];
	quoteTypeIds: number[] = [];

	orderFilters: IOrders;
	quoteFilters: IQuotes;

	private _orderData = new ReplaySubject<any[]>(1);
	orderData$ = this._orderData.asObservable();

	private _orderTotalItems = new ReplaySubject<number>(1);
	orderTotalItems$ = this._orderTotalItems.asObservable();

	private _quoteData = new ReplaySubject<any[]>(1);
	quoteData$ = this._quoteData.asObservable();

	private _quoteTotalItems = new ReplaySubject<number>(1);
	quoteTotalItems$ = this._quoteTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

	static menuIds = {
		quotes: 66,
		orders: 67,
		invoices: 69,
	};

	constructor(private _http: HttpClient) {}

	set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

	clearOrderFilters() {
		this.orderSearchText = '';
		this.orderStatusIds = [];
	}

	setSearchText(searchText: string, flag?: string) {
		if (flag === 'quotes') {
			this.quoteSearchText = searchText;
		} else {
			this.orderSearchText = searchText;
		}
	}

	clearQuoteFilters() {
		this.quoteSearchText = '';
		this.quoteStatusIds = [];
		this.quoteTypeIds = [];
	}

	getOrderDetails(id: number) {
		const url = `${environment.apiBaseUrl}${HttpRoutes.Order}/${id}`;
		return this._http.get<Order>(url);
	}

	getOrderProductList(options: TableOptions, id: number) {
		const url = `${environment.apiBaseUrl}${
			HttpRoutes.OrderProductList
		}/${id}?Page=${options.page}&PageSize=${options.pageSize}&Column=${
			options.sort
		}&Order=${options.order}&Search=${options.search ?? ''}`;
		return this._http.get(url);
	}

	getOrderInvoices(options: TableOptions, id: number) {
		const url = `${environment.apiBaseUrl}Invoices?OrderId=${id}&Page=${options.page}&PageSize=${options.pageSize}&Column=${options.sort}&Order=${options.order}`;
		return this._http.get<Order>(url);
	}

	printInvoice(id: number) {
		const url = `${environment.apiBaseUrl}CW_Invoices/PrintInvoice/${id}`;
		return this._http.get(url, { responseType: 'blob', observe: 'response' });
	}

	getChildCompanies(access: ParentChildAccessType) {
		const url = `${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren?access=${access}`;
		return this._http.get<NameId[]>(url);
	}

	getQuoteList<TResult = Object>(options: IQuotes) {
		this.quoteFilters = options;

		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			StartDate: options.startDate,
			EndDate: options.endDate,
			CompanyId: options.companyId,
		} as any;

		if (options.statusIds && options.statusIds?.length > 0) {
			param.StatusIds = options.statusIds;
		}

		if (options.typeIds && options.typeIds?.length > 0) {
			param.TypeIds = options.typeIds;
		}

		this.isLoading = true;
		this._quoteData.next([]);
		this._quoteTotalItems.next(0);

		return this._http
			.get(`${environment.apiBaseUrl}${HttpRoutes.Quote}`, { params: param })
			.pipe(
				tap((res: any) => {
					this._quoteData.next(res.data);
					this._quoteTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getQuoteStatusDropdown() {
		return this._http.get<any[]>(
			`${environment.apiBaseUrl}Quotes/StatusDropdown`
		);
	}

	getOrderList<TResult = Object>(options: IOrders) {
		this.orderFilters = options;

		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			StartDate: options.startDate,
			EndDate: options.endDate,
			UserId: options.userId,
			CompanyId: options.companyId,
		} as any;

		if (options.statusId && options.statusId?.length > 0) {
			param.StatusId = options.statusId;
		}

		this.isLoading = true;
		this._orderData.next([]);
		this._orderTotalItems.next(0);

		return this._http
			.get<TResult>(`${environment.apiBaseUrl}orders`, {
				params: param,
			})
			.pipe(
				tap((res: any) => {
					this._orderData.next(res.data);
					//
					this._orderTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
	}

	getQuoteTypes(): Observable<any[]> {
		return this._http.get<any[]>(
			`${environment.apiBaseUrl}Quotes/TypesDropdown`
		);
	}

	getOrderStatusDropdown() {
		var url = `${environment.apiBaseUrl}${HttpRoutes.OrderStatus}`;
		return this._http.get<OrderStatus[]>(url);
	}

	submitInquiry(id: any, orderInquiry: OrderInquiry) {
		const body = this._generateFormData(orderInquiry);
		const url = `${environment.apiBaseUrl}${HttpRoutes.OrderInquiry}/${id}`;

		return this._http.post(url, body);
	}

	exportQuotes(options: IQuotes, exportType: ApplicationType) {
		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			StartDate: options.startDate,
			EndDate: options.endDate,
			CompanyId: options.companyId,
			exportType,
		} as any;

		if (options.statusIds && options.statusIds?.length > 0) {
			param.StatusIds = options.statusIds;
		}

		if (options.typeIds && options.typeIds?.length > 0) {
			param.TypeIds = options.typeIds;
		}

		return this._http.get(`${environment.apiBaseUrl}Quotes/Export`, {
			params: param,
			responseType: 'text',
		});
	}

	exportOrders(options: IOrders, exportType: ApplicationType) {
		const param = {
			Page: options.page,
			PageSize: options.pageSize,
			Search: options.query,
			Column: options.column,
			Order: options.order,
			StartDate: options.startDate,
			EndDate: options.endDate,
			UserId: options.userId,
			CompanyId: options.companyId,
			exportType,
		} as any;

		if (options.statusId && options.statusId?.length > 0) {
			param.StatusId = options.statusId;
		}

		return this._http.get(`${environment.apiBaseUrl}Orders/Export`, {
			params: param,
			responseType: 'text',
		});
	}

	private _generateFormData<T>(object: T) {
		const fd = new FormData();

		for (const [key, value] of Object.entries(object)) {
			if (value instanceof Array) {
				value.forEach((v) => fd.append(key, v));
			} else if (value instanceof Object) fd.append(key, JSON.stringify(value));
			else fd.append(key, value as any);
		}

		return fd;
	}
}
