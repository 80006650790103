<app-modal
  headerTitle="Create a ticket"
  [show]="show"
  [withDefaultFooterBtn]="true"
  saveBtnText="Create"
  [disableSave]="!board || !status"
  (close)="onClose()"
  (save)="onSave()"
>
  <ng-template #modalBody>
    <div class="row">
      <div class="col-md-12 give-scroll mb-2">
        <label for="" class="d-inline">Board: </label>
        <select-input
          [(ngModel)]="board"
          [options]="boards"
          [optionKeys]="{ value: 'id', label: 'name' }"
          inputClass="form-control"
          placeholder="Select Board"
          (onChange)="onGetTicketStatus()"
        ></select-input>
      </div>
      <div class="col-md-12 give-scroll mb-2">
        <label for="" class="d-inline">Ticket Status: </label>
        <select-input
          [(ngModel)]="status"
          [options]="ticketStatus"
          [optionKeys]="{ value: 'id', label: 'name' }"
          inputClass="form-control"
          placeholder="Select Ticket Status"
          [disabled]="!board || isGettingTicketStatus"
        ></select-input>
      </div>
    </div>
  </ng-template>
</app-modal>