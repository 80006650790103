import {
	AfterViewInit,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ChangeDetectorRef,
	AfterContentInit,
} from '@angular/core';
import { TabItemComponent } from './components/tab-item/tab-item.component';

@Component({
	selector: 'app-tab-group',
	templateUrl: './tab-group.component.html',
	styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements OnInit, AfterViewInit, OnChanges {
	@ContentChildren(TabItemComponent) contentTabs: QueryList<TabItemComponent>;
	@ContentChild('tabTitleComponent') tabTitleComponent: TemplateRef<unknown>;
	@ContentChild('tabHeaderComponent') tabHeaderComponent: TemplateRef<unknown>;

	@Input() tabIndex: number;
	@Input() containerClass: string;
	@Input() headingMessage: string = '';
	@Input() hasMenu: boolean = false;
	@Input() customClass: string = '';
	@Input() isClientAdminTab: boolean = false;
	@Output() tabIndexChange: EventEmitter<number> = new EventEmitter<number>();

	tabs: TabItemComponent[] = [];
	cAdminTabs: TabItemComponent[] = [];
	isLoaded = false;

	constructor(private _cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		// set delay
		setTimeout(() => {
			this.onSetTabs();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { tabIndex } = changes;
		if (tabIndex.previousValue !== tabIndex.currentValue) {
			this.onSelectTab(this.tabIndex);
		}
	}

	ngAfterViewInit(): void {
		this.contentTabs.changes.subscribe(() => {
			this.isLoaded = false;
			this.onSetTabs();
		});
	}

	onSetTabs(): void {
		this.tabs = [];

		this.contentTabs.toArray().forEach((row, index) => {
			row.isActive = row.isActive;
			this.tabs = [...this.tabs, row];
		});

		if (this.tabIndex !== null) {
			this.onSelectTab(this.tabIndex);
		} else {
			const hasActive = this.tabs.find((x) => x.isActive);
			if (!hasActive) {
				this.onSelectTab(0);
			}
		}
		this.isLoaded = true;
		this._cd.detectChanges();
	}

	onSelectTab(index: number): void {
		if (!this.isLoaded || index !== this.tabIndex) {
			if (index !== this.tabIndex) {
				this.tabIndex = index;
				this.tabIndexChange.emit(this.tabIndex);
			}
			this.tabs.forEach((x, i) => {
				// x.isActive = i === index;
				x.setIsActive(i === index);
			});
			this._cd.detectChanges();
		}
	}
}
