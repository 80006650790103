import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { GroupsDropdownList } from '@app/shared/interfaces/companies.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { toFormData } from '@app/shared/utilities/helper';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, Subject, switchMap, timer } from 'rxjs';
import { twoFAResponse } from './pages/user/authentication/two-factor-auth/two-factor-auth.interface';
import { ProfileBranding } from '@app/shared/interfaces/user.interface';
import { IThemeSettings } from '@app/shared/navigation/configuration/theme.defaults';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  testing:boolean = false;
  userId:number;

  private _profileImg: string;
  private _profileBrandingSub = new BehaviorSubject<ProfileBranding | null>(null);
  public profileBranding$ : Observable<ProfileBranding | null> = this._profileBrandingSub.asObservable();


  constructor(private _http: HttpClient) {}

  initBranding(id:number): Promise<ProfileBranding> {
    return new Promise((resolve) => {
      this.userId = id;
      if(!this.testing){
        this.getBrandingById(this.userId).subscribe({
          next: (v) => {
            this._profileBrandingSub.next(v);
            resolve(v);
          },
          error: (e:any)=> {
            this._profileBrandingSub.error(e);
          },
        });
      }else{
        this.profileBranding$ = this._getBrandingByIdTest()
      }
    });
  }

  setProfileBranding(v: any){
    this._profileBrandingSub.next(v)
  }

  refreshBranding(){
    this.initBranding(this.userId);
  }

  getUserProfile(id: number) {
    return this._http.get(
      `${environment.apiBaseUrl}Users/ProfileGetById/${id}`
    );
  }

  updateUserBranding(id: number, body: {userId: number, isDefault?: boolean, isDarkMode: boolean}) {
    return this._http.put(
      `${environment.apiBaseUrl}Users/AutoApplyBranding/${id}`,body
    );
  }

  updateUserProfile(payload: Params, id: number) {
    const body = toFormData(payload);
    return this._http.put(
      `${environment.apiBaseUrl}Users/ProfileUpdate/${id}`,
      body
    );
  }

  getDepartmentDropdown(companyId: number, search = '') {
    return this._http.get(
      `${environment.apiBaseUrl}Departments/GetDropdown?search=${search}`
    );
  }

  getSiteDropdown(companyId: number, search = '') {
    return this._http.get(
      `${environment.apiBaseUrl}Sites/GetDropdown?companyId=${companyId}&search=${search}`
    );
  }

  getReportsToDropdown(companyId: number, userId: number, search = '') {
    return this._http.get(
      `${environment.apiBaseUrl}Users/GetDropdownByCompanyId/${companyId}?userId=${userId}&searchText=${search}`
    );
  }

  getGroupDropdown(companyId: number, userId: number, search = ''):Observable<{data:GroupsDropdownList[]}>{
    /* return this._http.get(
      `${environment.apiBaseUrl}Groups/CompanyByRole/${companyId}/${roleId}` //?companyId=${companyId}
    ); */
    let searchText = search? `&search=${search}` : '';
    return this._http.get<{data:GroupsDropdownList[]}>(
      `${environment.apiBaseUrl}Groups/DropDownList/?companyId=${companyId}&userId=${userId}${searchText}`
    );
  }

  getCompanyDropdown() {
    return this._http.get(`${environment.apiBaseUrl}Companies/GetDropdown`);
  }

  getUserData(id: number): Observable<User> {
    return this._http.get<User>(`${environment.apiBaseUrl}CW_Users/${id}`);
  }

  updateUserData(payload: Params, id: number) {
    const body = toFormData(payload);
    return this._http.put(`${environment.apiBaseUrl}CW_Users/${id}`, body);
  }

  setProfileImg(img: string) {
    this._profileImg = img;
  }

  getProfileImg() {
    return this._profileImg;
  }

  twoFactorAuthenticationEnable(): Observable<twoFAResponse> {
    return this._http.get<twoFAResponse>(
      `${environment.apiBaseUrl}TwoFactorAuthentication/Enable`
    );
  }

  post2FAEnable(body: { code: string }): Observable<any> {
    return this._http.post<twoFAResponse>(
      `${environment.apiBaseUrl}TwoFactorAuthentication/Enable`,
      body
    );
  }

  post2FADisable(): Observable<any> {
    return this._http.post<twoFAResponse>(
      `${environment.apiBaseUrl}TwoFactorAuthentication/Disable`,
      null
    );
  }


  /**ProfileBranding{
    userId: number,
    logoColors: string,
    dominantColors: string,
    darkThemeColor: string,
    lightThemeColor: string,
    isDefault: true,
    isDarkMode: true,
    logoColorList: string[],
    dominantColorList: string[]
} */
  getBrandingById(id:number):Observable<ProfileBranding>{
    return this._http.get<ProfileBranding>(
      `${environment.apiBaseUrl}Users/GetBrandingById/${id}`);
  }

  updateBrandingById(id:number, data:UpdateBranding){
    if(!this.testing)
      return this.updateBranding(id, data)
    else
      return this._updateBrandingTest(id, data)
  }

  updateBranding(id:number, data: any){
    return this._http.put(
      `${environment.apiBaseUrl}Users/UpdateBranding/${id}`, data);
  }

  getInitBranding(id:number){
    this.initBranding(id);
    return this.profileBranding$;
  }

  /** For Testing purposes */
  _getBrandingByIdTest():Observable<ProfileBranding>{
    return of({
      userId: 1,
      logoColors: '#000000',
      dominantColors: '#000000',
      darkThemeColor: '#000000',
      lightThemeColor: '#FFFFFF',
      isDefault: false,
      isDarkMode: true,
      logoColorList: ['#000000'],
      dominantColorList: ['#000000']
    });
  }

  _updateBrandingTest(id:number, data:UpdateBranding){
    return of(data);
  }

  /** Private functions */
  private _getSubject(subj:Subject<any>){
    return {
      next: (v:any)=>{
        subj.next(v)},
      error: (e:any)=>subj.error(e),
      //complete: ()=>subj.complete()
    }
  }

  getLSBrandingData(): Observable<IThemeSettings>{
    const timerObs = timer(300);
    let brandingData: IThemeSettings = JSON.parse(localStorage.getItem('themeSettings')!);
    if(!brandingData){
      brandingData =  {
        "userId": 0,
        "darkThemeColor": "#000000",
        "lightThemeColor": "#b20a0a",
        "companyDarkThemeColor": "#78ddbc",
        "companyLightThemeColor": "#7c7979",
        "isDarkMode": false,
        "selectedColor": "#b20a0a",
        "lightColorsList": ['#aabbcc', '#825ee4', '#5e72e4'],
        "darkColorsList": []
      }
      localStorage.setItem('themeSettings', JSON.stringify(brandingData))
    }
    let brandingObs = of(brandingData)
    return timerObs.pipe(switchMap(()=>brandingObs))
  }

}


interface UpdateBranding{
  userId: number,
  logoColors: string,
  dominantColors: string,
  darkThemeColor: string,
  lightThemeColor: string,
  isDefault: boolean,
  isDarkMode: boolean,
}
