import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterComponent } from './filter.component';






@NgModule({
  declarations: [
    FilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[
    FilterComponent
  ],
})
export class FilterModule { }
