<div class="w3-sidebar w3-bar-block w3-border-right">
	<div class="notifi-wrap">
		<ng-container *ngIf="!impersonating">
			<div *ngIf="loading" class="content-center">
				<mat-spinner #spinner></mat-spinner>
			</div>
			<div
				*ngIf="!loading"
				class="applist-table back-table table-responsive table-striped"
			>
				<table class="table text-md-center text-lg-left">
					<tbody>
						<tr
							*ngFor="
								let i of messages;
								trackBy: messageListTrackBy;
								let index = index
							"
							class="d-flex flex-lg-row flex-column"
						>
							<td class="message-title" (click)="open(i, index)">
								<strong>{{ i.title }}</strong>
								<p class="line-clamp">{{ i.message }}</p>
								<a href="#" class="elapse-time notifi-link">{{
									i?._elapsedtime ?? ''
								}}</a>
							</td>
							<td class="action-options">
								<a
									href="javascript:void(0)"
									(click)="close(i, index)"
									class="bin"
								>
									<app-figma-icon-img
										[iconName]="'x'"
										[isForButton]="true"
                    [hasSpaceOnRight]="false"
									></app-figma-icon-img>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="!loading && !messages?.length" class="content-center">
				<strong> No new notifications.</strong>
			</div>
		</ng-container>
		<div *ngIf="impersonating" class="content-center">
			<strong>No notifications when impersonating.</strong>
		</div>
	</div>
</div>
