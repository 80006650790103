<div>
<!--     <app-widget-dynamic-table
    [data]="dataTable"
    [style]="usageSettings"
  ></app-widget-dynamic-table> -->
  <responsive-table
    [table$]="dataTable$"
  ></responsive-table>
</div>

