import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'quest-no-data',
	templateUrl: './no-data.component.html',
	styleUrls: ['./no-data.component.scss'],
	standalone: true,
})
export class NoDataComponent implements OnInit {
	@Input() height: number = 300;
	constructor() {}

	ngOnInit(): void {}
}
