import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@app/core/services/spinner.service';
import { WidgetCardList, WidgetList } from '@app/shared/interfaces/dashboard.interface';
import { DashboardTab } from '@app/shared/interfaces/dashboards/dashboard-tab.interface';
import { environment } from 'environments/environment';
import { finalize, BehaviorSubject, lastValueFrom, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DashboardCardTabService {
  url = `${environment.apiBaseUrl}UserDashboardTabCards`;

  #cardsChanged_ = new BehaviorSubject<any>(null);
  cardsChanged$ = this.#cardsChanged_.asObservable();

  constructor(
    private _http: HttpClient,
    private _spinner: SpinnerService,
  ) {}

  /**
   * getCardList
   * @fields id, name, cards[{id,icon, title, description,categoryId, category}]
   * @return {*}  {Observable<WidgetCardList[]>}
   * @memberof DashboardCardTabService
   */
  getCardList(): Observable<WidgetCardList[]> {
    return this._http.get<WidgetCardList[]>(`${this.url}/CardList`);
  }

  getCardWidgetList(){
    return this._http.get<WidgetCardList[]>(`${this.url}/getCards`);
  }

  addCards(
    tabId: number,
    cardIds: number[]
  ): Observable<WidgetCardList[]> {
    return this._http.post<WidgetCardList[]>(`${this.url}/addCards`, {
      tabId,
      cardIds,
    })
  }

  removeCards(
    tabId: number,
    cardIds: number[]
  ): Observable<WidgetCardList[]> {
    return this._http.delete<WidgetCardList[]>(`${this.url}/removeCards`, {
      body: {
        tabId,
        cardIds,
      }
    });
  }

  saveDisplayOrder(
    tabId: number,
    cardIds: number[]
  ): Observable<WidgetCardList[]> {
    return this._http.post<WidgetCardList[]>(`${this.url}/saveDisplayOrder`, {
      tabId,
      cardIds,
    });
  }
  
  resizeCard(
    cardId: number,
    tabId: number,
    height: number,
    width: number
  ): Observable<WidgetCardList[]> {
    return this._http.put<WidgetCardList[]>(`${this.url}/ResizeCard`, {
      cardId,
      tabId,
      height,
      width,
    });
  }

  resizeOneCard(cardId: number, tabId: number, height: number, width: number){
    return lastValueFrom(this.resizeCard(cardId, tabId, height, width))
  }

  removeOneCard(tabId: number, cardId:number){
    this._spinner.start();
    this.removeCards(tabId, [cardId])
      .pipe(finalize(() => this._spinner.stop()))
      .subscribe((data: WidgetCardList[]) => {
         this.#cardsChanged_.next(true);
      });
  }

  addOneCard(tabId: number, cardId:number){
    this._spinner.start();
    this.addCards(tabId, [cardId])
      .pipe(finalize(() => this._spinner.stop()))
      .subscribe((data: WidgetCardList[]) => {
        this.#cardsChanged_.next(true);
      })
  }

  addOneCardPromise(tabId: number, cardId:number){
    return lastValueFrom(this.addCards(tabId, [cardId])
      .pipe(finalize(() => this._spinner.stop())))
  }

  cardsChanged(){
    this.#cardsChanged_.next(true);
  }

  saveCardsOrder(tabId:number, cardIds: number[]){
    return lastValueFrom(this.saveDisplayOrder(tabId, cardIds)
      .pipe(finalize(() => this._spinner.stop()))
    )
  }
}