import { AppStateInterface } from '@app/core/store/app-state.interface';

import { createSelector } from '@ngrx/store';
import { ISidebar } from './sidebar.reducer';

export const sidebarSelector = (state: AppStateInterface): ISidebar =>
	state.sidebar;

export const sidebarDataSelector = createSelector(
	sidebarSelector,
	(state: ISidebar) => state.sidebarData
);

export const flatMenuSelector = createSelector(
	sidebarSelector,
	(state: ISidebar) => state.flatMenu
);

export const feedbackUrlSelector = createSelector(
	sidebarSelector,
	(state: ISidebar) => state.feedbackUrl
);
