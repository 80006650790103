import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { UserService } from '@app/core/services/user.service';

/**
 * ConvertedDatePipe
 * @description: Gets the row object, provide the propery name, check if '_Converted' exist
 */
@Pipe({
  name: 'convertedDate'
})
export class ConvertedDatePipe implements PipeTransform {
  // injection
  private _datePipe = inject(DatePipe)

  private _dateFormat = 'MM/dd/yy, hh:mm a';
  private _suffix = '_Converted'

  transform(value: any, field: string): string | null {
    const converted = field+this._suffix;
    let date = '';

    if(field in value) date = value[field];
    else if(converted in value) date = value[converted];

    return this._datePipe.transform(date, this._dateFormat);
  }
}
