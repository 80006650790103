<div class="main-contents spdmin-main support-main">
	<div class="support-head">
		<div class="support-conts">
			<div class="">
				<div class="">
					<p class="converse" style="margin: 0 !important">CONVERSATION</p>
				</div>
				<div class="">
					<ul>
						<li *ngIf="AISummarizationEnabled">
							<a
								href="#ai-summary-control"
								class="collapsed"
								data-bs-toggle="collapse"
								aria-controls="ai-summary-control"
								role="button"
								aria-expanded="false"
								[tooltipContentV2]="{ message: 'AI Summarization' }"
								#collapseEl
								(click)="
									!collapseEl.classList.contains('collapsed') ? openAI() : ''
								"
							>
								<app-figma-icon-img
									class="q-pointer"
									[iconName]="'stars-01'"
									[hasSpaceOnRight]="false"
								>
								</app-figma-icon-img>
							</a>
						</li>

						<li>
							<app-figma-icon-img
								(click)="!isLoading ? loadPrint() : null"
								[tooltipContentV2]="{ message: 'Print Ticket' }"
								class="q-pointer"
								[iconName]="'printer'"
								[hasSpaceOnRight]="false"
								*ngIf="!isLoading"
							></app-figma-icon-img>
							<div *ngIf="isLoading" class="spinner-border" role="status"></div>
						</li>

						<li>
							<mat-icon
								svgIcon="arrow-square-left"
								[class.disabled]="!navData?.previousId"
								[class.q-pointer]="navData"
								(click)="prev()"
								[tooltipContentV2]="{ message: 'Previous' }"
							></mat-icon>
						</li>
						<li>
							<mat-icon
								svgIcon="arrow-square-right"
								[class.disabled]="!navData?.nextId"
								[class.q-pointer]="navData"
								(click)="next()"
								[tooltipContentV2]="{ message: 'Next' }"
							></mat-icon>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div
			class="forecast"
			*ngIf="ticket && ticket.ticket.forecastTime && !isForecastClosed"
			[class.q-yellow]="ticket!.ticket.hasTechnicianResponse === false"
		>
			<div class="forecast-note">
				<p
					[class]="
						ticket!.ticket.hasTechnicianResponse === false
							? 'yellow-text'
							: 'green-text'
					"
				>
					<img
						[src]="
							ticket!.ticket.hasTechnicianResponse === false
								? './assets/icons/clock-stopwatch-y.png'
								: './assets/icons/clock-stopwatch.svg'
						"
						alt=""
					/>
					<span>Forecast:</span>&nbsp;
					{{
						ticket!.ticket.hasTechnicianResponse === false
							? 'The issue is estimated to be resolved in ' +
							  Math.ceil(ticket.ticket.forecastTime / 60) +
							  ' business hour' +
							  (Math.ceil(ticket.ticket.forecastTime / 60) > 1 ? 's' : '')
							: 'You should expect a response typically within ' +
							  Math.ceil(ticket.ticket.forecastTime / 60) +
							  ' business hour' +
							  (Math.ceil(ticket.ticket.forecastTime / 60) > 1 ? 's' : '')
					}}.
				</p>
			</div>
			<div class="close-forecast">
				<a>
					<img
						[src]="
							ticket!.ticket.hasTechnicianResponse
								? './assets/icons/x-close_green.svg'
								: './assets/icons/x-close_yellow.svg'
						"
						alt=""
					/>
				</a>
				<!-- <app-figma-icon-img
					(click)="isForecastClosed = !isForecastClosed"
					class="q-pointer"
					[iconName]="'x-close'"
					[hasSpaceOnRight]="false"
				></app-figma-icon-img> -->
			</div>
		</div>
	</div>

	<div
		class="ticket-comment-container q-d-flex q-w-100 q-f-column q-ai-start q-g-24"
	>
		<div
			*ngIf="AISummarizationEnabled"
			id="ai-summary-control"
			class="ai-summary-ticket q-w-100 collapse"
		>
			<app-ai-summary
				[ticket]="ticket"
				(onCloseSummaryBox)="closeAiSummaryBox()"
			>
			</app-ai-summary>
		</div>

		<div class="conversation-message q-w-100">
			<!-- Conversation/Message -->
			<view-ticket-comment
				[ticket]="ticket && ticket.ticket ? ticket.ticket : null"
				[comments]="ticket && ticket.notes ? ticket.notes : []"
				[attachments]="ticket && ticket.attachments ? ticket.attachments : []"
			></view-ticket-comment>
		</div>
	</div>

	<!-- End of Timeline -->
	<view-ticket-reply
		*ngIf="ticket && hasMessageInput"
		[isClosed]="ticket.ticket.statusName == 'Closed'"
		[ticketId]="ticket && ticket.ticket.id ? ticket.ticket.id : 0"
		(hasSubmitted)="getSubmittedValue($event)"
		(fakeTicketEmitter)="getFakeTicket($event)"
		[isDragEnter]="isDragEnter"
	></view-ticket-reply>
</div>
