import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Inject,
	ViewChild,
	OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import {
	map,
	Observable,
	shareReplay,
	take,
	tap,
	catchError,
	switchMap,
	startWith,
	Subject,
	takeUntil,
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import { setAppTheme } from '@app/shared/utilities/theme';

import { ImageService } from '@app/shared/services/image.service';
import { AccountService } from '@app/modules/account/account.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { UserNotificationService } from '@app/shared/services/user-notification.service';
import { DashboardDrawerService } from '@app/shared/services/dashboard-drawer-service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { GlobalSearchService } from '@app/modules/global-search/global-search.service';
import { TopBarService } from './top-bar.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { IdleService } from '@app/core/services/idle.service';
import { FigmaDemoService } from '@app/figma-demo/figma-demo.service';

import {
	UserTypes,
	NotificationMessages,
	UserMessageCategoryEnum,
	Permission,
	Menus,
} from '@app/shared/constants';

import {
	MessageNotificationCounts,
	UserMessagePendingNotificationDetail,
} from '@app/shared/interfaces/usermessage.interface';
import { ITopBar } from './top-bar.interface';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IJWT } from '@app/core/store/jwt/jwt.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { IFigmaIcon } from '@app/shared/interfaces/generic.interface';

import { ImpersonateAlertBarService } from '@app/core/components/impersonate-alert-bar/impersonate-alert-bar.service';

import { impersonateSelector } from './store/top-bar.selector';
import { userDataSelector } from '@app/core/store/user/user.selector';

import { topBarUpdateAction } from './store/top-bar.action';
import { jwtUpdateAction } from '@app/core/store/jwt/jwt.action';
import { GlobalSearchComponent } from './global-search/global-search.component';

@Component({
	selector: 'nav-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
	@ViewChild(GlobalSearchComponent)
	globalSearchComponent: GlobalSearchComponent;
	impersonateOptions$: Observable<{ isOpen: boolean }>;
	isLogoHasLoaded: boolean = false;
	msg_notifications?: MessageNotificationCounts;
	isImpersonateLogoutOpen: boolean = false;
	colorScheme = 'light';

	logo$: Observable<string>;
	user$: Observable<User | null>;

	search = '';
	bellNotifications: UserMessagePendingNotificationDetail[];
	isMailEnabled = this.userService.hasMenu([Menus.UserViewMailbox]);

	isImpersonateEnabled: boolean;
	
	private _$unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		public userService: UserService,
		public idleService: IdleService,
		private _notifier: NotificationService,
		private spinner: SpinnerService,
		private router: Router,
		private _cd: ChangeDetectorRef,
		private store: Store<AppStateInterface>,
		private _companiesService: CompaniesService,
		private _themeService: ThemeService,
		private _accountService: AccountService,
		private _userNotification: UserNotificationService,
		private _imageService: ImageService,
		private _dashboardDrawerService: DashboardDrawerService,
		private _globalSearch: GlobalSearchService,
		private localStorageService: LocalStorageService,
		private topBarService: TopBarService,
		private sidebarService: SideBarService,
		private _usernotification: UserNotificationService,
		private _figmaService: FigmaDemoService,
		private _impersonateAlertBarService: ImpersonateAlertBarService
	) {
		this.user$ = this.store.pipe(select(userDataSelector));
	}

	public get UserTypes() {
		return UserTypes;
	}

	get img() {
		let profileImg: any = this._accountService.getProfileImg();

		if (!profileImg || profileImg?.includes('profilePic.png')) {
			const figma: IFigmaIcon[] = JSON.parse(
				localStorage.getItem('newFigma') || ''
			);
			profileImg = figma.filter((e) => e.name === 'user-circle')[0].iconUrl;
		}

		return profileImg;
	}

	get isUserRole() {
		return this.userService.userRole === UserTypes.User;
	}

	get isOpenSearchForm() {
		return this.globalSearchComponent?.isSearchOpened;
	}

	ngOnInit() {
		// init store
		this.impersonateOptions$ = this.store.pipe(select(impersonateSelector));
		this.initTopBarStore();
		// this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		// Moved Branding changes to side bar

		this._getUserProfile();

		if (!this.userService.user?.impersonatingBy) {
			this._userNotification.message_notification_counts_ref.subscribe({
				next: (result) => {
					this.msg_notifications = result;
					this._cd.detectChanges();
				},
			});
		}

		this.userService.userChange$.subscribe((res) => {
			this.isImpersonateEnabled = this.userService.hasPermission(
				Permission.SpAdminImpersonation
			);
			this.isMailEnabled = this.userService.hasMenu([Menus.UserViewMailbox]);
		});

		this._onImpersonateEvent();
	}

	onMessageClick() {
		this.topBarService.onMessageClick();
	}

	onImpersonateClick() {
		this.topBarService.onImpersonateClick();
	}

	initTopBarStore() {
		let topBar = this.localStorageService.getStorageObject('topBar') as ITopBar;
		if (!topBar) {
			this.store.dispatch(topBarUpdateAction({}));
		}
	}

	clearAllNotification(e: any) {
		e.stopPropagation();
		this.topBarService.clearAllNotification().subscribe({
			next: () => {
				this.bellNotifications = [];
				this.msg_notifications!.countBell = 0;
			},
		});
	}

	searchGlobal() {
		if (this.userService.userRole === UserTypes.User) {
			this.spinner.start();
			this._globalSearch.getGlobalList(this.search).subscribe((resp: any) => {
				let hasData = false;

				for (const table of resp.tables) {
					if (table.data.length > 0) {
						hasData = true;
						break;
					}
				}

				if (hasData) {
					this.router.navigate(['global-search'], {
						queryParams: { search: this.search },
					});
				} else {
					this.spinner.stop();
					this._notifier.notify(
						'No result was found from the keyword you search',
						{ duration: 5, panelClass: 'success' }
					);
				}
			});
		}
	}

	getCompanyData() {
		return this._companiesService.getCompanyBranding(
			this.userService.user!.companyId
		);
	}

	themeToggled(colorScheme: string) {
		this._themeService.themeType.pipe(take(1)).subscribe((theme) => {
			if (colorScheme == theme) {
				return;
			} else {
				this.colorScheme = colorScheme;
				this.isLogoHasLoaded = false;
				localStorage.setItem(
					'elevate.prefers-color-scheme-' + this.userService.user!.id,
					colorScheme
				);
				setAppTheme(
					colorScheme,
					this._companiesService.branding.lightThemeColor,
					this._companiesService.branding.darkThemeColor
				);
				this._themeService.changeThemeType(colorScheme);

				this._notifier.notify(NotificationMessages.themeMode(colorScheme), {
					duration: 3,
					panelClass: 'success',
				});
			}
		});
	}

	loadingLogo() {
		this.isLogoHasLoaded = true;
	}

	switchMode() {
		this.userService.switchUserRole();
		(document as any).getElementById('sidenav-main').scrollTop = 0;
	}

	checkImpersonateLogout() {
		this.isImpersonateLogoutOpen = !this.isImpersonateLogoutOpen;
	}

	// logoutImpersonate() {
	// 	this.spinner.start();
	// 	this.userService
	// 		.endImpersonate()
	// 		.pipe(
	// 			take(1),
	// 			switchMap((jwt: IJWT) => {
	// 				this.store.dispatch(
	// 					jwtUpdateAction({
	// 						accessToken: jwt.accessToken,
	// 						refreshToken:
	// 							this.localStorageService.getStorageObject('refreshToken'),
	// 					})
	// 				);
	// 				return this.userService.refreshUser();
	// 			}),
	// 			switchMap((_res) => this.sidebarService.getSideBarContents())
	// 		)
	// 		.subscribe({
	// 			next: (res) => {
	// 				this.sidebarService.getContents(res);
	// 				this.spinner.stop();
	// 				this.userService.redirectToDefault();
	// 			},
	// 		});
	// }

	private _getUserProfile() {
		this._accountService
			.getUserData(this.userService.user!.id)
			.pipe(
				catchError((e) => {
					this._accountService.setProfileImg(
						this._imageService.getDefaultProfilePic()
					);
					this._cd.markForCheck();
					throw e;
				})
			)
			.subscribe((resp: any) => {
				let profilePicture = resp.imagePath;

				if (this._isNullorEmpty(profilePicture)) {
					profilePicture = this._imageService.getDefaultProfilePic();
				}

				this._accountService.setProfileImg(profilePicture);
				this._cd.markForCheck();
			});
	}

	gotoServiceRequestMessages(event: Event) {
		event.preventDefault();
		switch (this.userService.userRole) {
			case UserTypes.User:
			case UserTypes.ClientAdmin:
				this.clearMessageNotifications();
				this.router.navigate(['/client-message/service-request']);
				break;
		}
	}

	gotoSupportRequestMessages(event: Event) {
		event.preventDefault();
		switch (this.userService.userRole) {
			case UserTypes.User:
			case UserTypes.ClientAdmin:
				this.clearMessageNotifications();
				this.router.navigate(['/client-message/support-request']);
				break;
		}
	}

	gotoBroadcastMessages(event: Event) {
		event.preventDefault();
		switch (this.userService.userRole) {
			case UserTypes.User:
			case UserTypes.ClientAdmin:
				this.clearMessageNotifications();
				this.router.navigate(['/client-message/broadcast']);
				break;
		}
	}

	clearMessageNotifications() {
		if (this.msg_notifications?.countAll)
			this._userNotification.clearAllMessageNotifications();
	}

	getThemeClasses(value: string): string {
		return `theme-icon ${this.colorScheme === value ? 'enabled' : ''}`;
	}

	private _isNullorEmpty(str: string) {
		return str === null || str === 'null' || str.trim() === '';
	}

	getBellNotifications() {
		this._usernotification.getBellNotifications().subscribe({
			next: (s: any) => {
				// if(s.length <= 0) this._notifier.notify(NotificationMessages.NoNotifications,{duration: 5, panelClass: 'default'});
				this.bellNotifications = s;
				//if(this.bellNotifications.length <= 0) this.bellNotifications = getDummyNotif()
			},
			error: () => {},
		});
	}
	open(message: UserMessagePendingNotificationDetail, index: number) {
		switch (message.categoryId) {
			case UserMessageCategoryEnum.Order:
				this.router
					.navigateByUrl('/billing-and-orders', { skipLocationChange: true })
					.then(() =>
						this.router.navigate([
							'/billing-and-orders/orders/' + message.refId,
						])
					);
				break;

			case UserMessageCategoryEnum.Invoice:
				this.router
					.navigateByUrl('/billing-and-orders', { skipLocationChange: true })
					.then(() =>
						this.router.navigate([
							'/billing-and-orders/invoices/detail/' + message.refId,
						])
					);
				break;
		}
		this.clearNotification(message.id);
		this.bellNotifications.splice(index, 1);
	}

	clearNotification(id: number) {
		this._usernotification.clearNotificationById(id);
	}

	private _onImpersonateEvent() {
		this._impersonateAlertBarService.topBarCwUser
		.pipe(
			startWith(''),
			takeUntil(this._$unsubscribe)
		)
		.subscribe({
			next: (isImpersonating) => {
				if (isImpersonating) {
					console.log('_onImpersonateEvent')
					this._getUserProfile();
				}
			}
		});
	}

	get permission() {
		return Permission;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}

/* function getDummyNotif(): UserMessagePendingNotificationDetail[]{
	let id = 1;
	let title = 'Title '+ id;
	let message = 'Message ' + id;
	let notif:UserMessagePendingNotificationDetail[] = []

	for(id=1; id<30; id++){
		notif.push({
			id: id,
			refId: id,
			title: title,
			message: message,
			categoryId: id,
			createdDate: new Date().toString(),
			catedByName: 'John Doe',
			createdByEmail: 'sample@yopmail.com',
			_elapsedtime : '',
		})
	}
	return notif;
} */
