import { PaginationConstants } from "@app/shared/constants/pagination-options.constants";
import { createReducer, on } from "@ngrx/store";
import { assetsUpdateAction, assetsUpdateSuccessAction } from "./assets.actions";
import { IAsset } from "./assets.interface";

export const initialStateIAsset: IAsset = {
  page: 1,
  pageSize: PaginationConstants.pageSize,
  order: 'asc',
  column: 'name',
  query: '',
  statusIds: [],
  typeIds: [],
  onlineOfflineStatus: [],
  totalItems: 0
}

export const assetsReducer =
createReducer(
  initialStateIAsset,
  on(assetsUpdateAction, (state, action): IAsset =>({
    ...state,
    ...action
  })),
  on(assetsUpdateSuccessAction, (state): IAsset => ({
    ...state
  }))
)