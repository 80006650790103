import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { SideBarService } from '@app/shared/navigation/side-bar/side-bar.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {


  constructor(
    private navigationService: NavigationService,
    private sidebarService: SideBarService
  ) { }

  ngOnInit(): void {
    this.sidebarService.getSidebar()
  }

  onNavigate(): void {
    this.navigationService.smartNavigate();
  }
}
