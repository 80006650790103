import { Component, Input, OnInit } from '@angular/core';
import { TooltipContent } from './tooltip-content-v2.interface';

@Component({
  selector: 'app-tooltip-content',
  templateUrl: './tooltip-content-v2.component.html',
  styleUrls: ['./tooltip-content-v2.component.scss']
})
export class TooltipContentV2Component implements OnInit {
  @Input() bctData: TooltipContent;
  @Input() caretPosition: string = '';
  @Input() textAlign?: string = 'left';

  constructor() { }

  ngOnInit(): void {
  }

}
