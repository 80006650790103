import { HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SearchPagination } from '@app/shared/interfaces/search-pagination.interface';
import { environment } from 'environments/environment';

import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  state = new BehaviorSubject('');
  constructor(private _http: HttpClient, private router: Router) {}

  setSearchText = new Subject<any>();

  getCategories() {
    return this._http.get(
      environment.apiBaseUrl + 'globalsearch/getcategories'
    );
  }

  searchCategoryMatchCount(categoryId: number | null, search: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId ?? '');
    params = params.append('search', search);

    return this._http.get(
      environment.apiBaseUrl + 'globalsearch/SearchCategoryMatchCount',
      { params: params }
    );
  }

  search(pagination: SearchPagination, categoryId: number, search: string) {
    let params = new HttpParams();
    Object.keys(pagination).forEach(
      (k) => (params = params.append(k, (pagination as any)[k] ?? ''))
    );
    params = params.append('search', search ?? '');
    params = params.append('categoryId', categoryId ?? '');

    return this._http.get(environment.apiBaseUrl + 'globalsearch', {
      params: params,
    });
  }

  getRedirectSearch(): string {
    let s = this.router.getCurrentNavigation()?.extras.state as any;

    if (!s) {
      s = history.state;
    }

    return s.globalSearchRedirect;
  }

  setModuleSearchInput(
    search_input: ElementRef,
    globalSearchRedirectText: string
  ) {
    if (
      search_input?.nativeElement &&
      !search_input?.nativeElement.value &&
      globalSearchRedirectText
    ) {
      search_input.nativeElement.value = globalSearchRedirectText;
    }
  }

  getGlobalList(search: string, categoryId = 1) {
    const apiUrl = `${environment.apiBaseUrl}GlobalSearches?search=${search}&categoryId=${categoryId}`;
    return this._http.get(apiUrl);
  }
}
