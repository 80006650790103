export enum GlobalSettingsType{
  'applications' = 1,
  'kb-topics' = 2,
  'forms' = 4
}

export enum GlobalSettingsPermission{
  'applications' = 173,
  'kb-topics' = 174,
  'forms' = 175,
}

