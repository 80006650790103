/* Angular Libraries */
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';

@Component({
	selector: 'app-three-d-secure-modal',
	templateUrl: './three-d-secure-modal.component.html',
	styleUrls: ['./three-d-secure-modal.component.scss'],
})

export class ThreeDSecureModalComponent implements OnInit, OnDestroy {
	/* Input/Output */
	@Output() onCloseModal: EventEmitter<string> = new EventEmitter<string>();

	/* Properties */
	isOpenModal: boolean = false;
	private _paymentIntentId: string;
	private _$unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor(
		private _stripeService: StripeService,
		private _spinner: SpinnerService
	) {}

	/* Methods */
	ngOnInit(): void {}

  clickVerify(paymentIntentId: string) {
		this._spinner.start();
		this._paymentIntentId = paymentIntentId;

		this._stripeService.getPaymentIntentRedirectUrl(this._paymentIntentId)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (result: any) => {
				this.isOpenModal = true;

				setTimeout(() => {
					const iframe = document.createElement('iframe') as any;
					const yourContainer = document.getElementById('three-d-secure-iframe') as any;
					iframe.id = 'three-d-iframe';
					iframe.src = result.url;
					iframe.width = 600;
					iframe.height = 400;
					document.body.classList.add('modal-backdrop-container');
					yourContainer.appendChild(iframe);
					this._spinner.stop();
				}, 0);

			}, error: () =>{
				this._spinner.stop();
			}
		});
	}

  closeModal() {
		this.isOpenModal = false;
    document.body.classList.remove('modal-backdrop-container');
    document.getElementById("three-d-iframe")?.remove();
    this.onCloseModal.emit(this._paymentIntentId);
  }

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
