import { environment } from 'environments/environment';
import { HistoryPaginator, HistoryPageSettings } from './../../interfaces/history.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: "root"
})
export class AuditTrailService {
  constructor(private http: HttpClient){}

  avoidParam: any[] = ['invoice-autopay'];

  getHistory(pageSettings: HistoryPageSettings, id: number | string) {
    const param: any = this.getValidParameters({
      page: pageSettings.page,
      pageSize: pageSettings.size,
      companyId: pageSettings.companyId,
      userId: pageSettings.userId
    });

    // return this.http.get<HistoryPaginator>(`${environment.apiBaseUrl}${pageSettings.url}/history/${id}?page=${pageSettings.page}&pageSize=${pageSettings.size}`);
    return this.http.get<HistoryPaginator>(`${environment.apiBaseUrl}${pageSettings.url}/history/${!this.avoidParam.includes(id) ? id : ''}`, { params: param });
  }
  getPermissionHistory(pageSettings: HistoryPageSettings, companyId:any, groupId: any, userId:any, isCustom: any) {
    return this.http.get<HistoryPaginator>(`${environment.apiBaseUrl}${pageSettings.url}/permissionhistory/?companyId=${companyId??0}&groupId=${groupId??0}&userId=${userId??0}&isCustom=${isCustom??false}&page=${pageSettings.page}&pageSize=${pageSettings.size}`);
  }
  getMenuPermissionHistory(pageSettings: HistoryPageSettings,roleId :any, companyId:any, groupId: any, userId:any) {
    return this.http.get<HistoryPaginator>(`${environment.apiBaseUrl}menupermissions/history?roleId=${roleId??0}&companyId=${companyId??0}&groupId=${groupId??0}&userId=${userId??0}&page=${pageSettings.page}&pageSize=${pageSettings.size}`);
  }
  getAllHistory(pageSettings: HistoryPageSettings) {
    return this.http.get<HistoryPaginator>(`${environment.apiBaseUrl}${pageSettings.url}/history?page=${pageSettings.page}&pageSize=${pageSettings.size}`);
  }

  getValidParameters(param: any) {
		return Object.fromEntries(Object.entries(param).filter(([key, value]: any) => !['', null, undefined].includes(value)));
	}
}
