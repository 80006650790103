import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";




export const globalKbCategorySelector = (state: AppStateInterface): IPageState => state.globalKbCategory;

export const pageSelector =
createSelector(globalKbCategorySelector, (state: IPageState)=>state.page);

export const pageSizeSelector =
createSelector(globalKbCategorySelector, (state: IPageState)=>state.pageSize);

export const orderSelector =
createSelector(globalKbCategorySelector, (state: IPageState)=>state.order);

export const columnSelector =
createSelector(globalKbCategorySelector, (state: IPageState)=>state.column);

export const querySelector =
createSelector(globalKbCategorySelector, (state: IPageState)=>state.query);

// export const companyIdSelector =
// createSelector(kbCategorySelector, (state: IKBCategory)=>state.companyId);

