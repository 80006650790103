import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { UserService } from '@app/core/services/user.service';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  // injection
  private _datePipe = inject(DatePipe)
  //private _user = inject(UserService)

  dateFormat: string;

  transform(value: Date | string | number, withTime?: boolean): string | null {
    //const {timezone} = this._user;
    this.dateFormat = withTime ? 'M/d/y, h:mm a' : 'M/d/y';
    return this._datePipe.transform(value, this.dateFormat);
  }
}
