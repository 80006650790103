import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionTimedOutDialogComponent } from '@app/shared/components/connection-timed-out-dialog/connection-timed-out-dialog.component';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class IdleService {
	private _interval: any;

	constructor(
		private _userService: UserService,
		private _dialog: MatDialog,
		private _notifier: NotificationService
	) {}

	startTracking(): void {
		this.stopTracking();
		this._userTracker();
		this._startInterval();
	}

	private _startInterval() {
		this._updateExpiredTime();
		this._interval = setInterval(() => {
			const expTime = parseInt(
				localStorage.getItem('idleExpTime') || Date.now().toString()
			);
			const minutesPassed = (Date.now() - expTime) / 60000;
			if (minutesPassed > 60 * 12) {
				this._logout(); //logout 12 hours
				this._notifier.notify('You have been automatically logged out due to inactivity.', { panelClass: 'default' });
			}
		}, 10000);
	}

	private _warning(expTime: number) {
		clearInterval(this._interval);
		let isloggedOut = false;

		const dialogRef = this._dialog.open(ConnectionTimedOutDialogComponent, {
			width: '100%',
			maxWidth: '100%',
			panelClass: 'connection-timed-out',
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (res)
				expTime = parseInt(
					localStorage.getItem('idleExpTime') || '0'
				);
			if (!res) (isloggedOut = true), this._logout();
		});
	}

	private _logout() {
		clearInterval(this._interval);
		this._dialog.closeAll(), this._userService.logout();
	}

	private _userTracker() {
		window.addEventListener('mousedown', this._updateExpiredTime, false),
			window.addEventListener('scroll', this._updateExpiredTime, false),
			window.addEventListener('keydown', this._updateExpiredTime, false);
	}

	private _updateExpiredTime = () => {
		localStorage.setItem('idleExpTime', Date.now().toString());
	};

	stopTracking(): void {
		clearInterval(this._interval);
		window.removeEventListener('mousedown', this._updateExpiredTime, false),
			window.removeEventListener('scroll', this._updateExpiredTime, false),
			window.removeEventListener('keydown', this._updateExpiredTime, false);
	}
}
