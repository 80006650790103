import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, concat, map, Subject } from 'rxjs';


@Injectable()
export class CwpodVerificationService {

  stateSubj = new Subject<CWPodVerificationState>();
  private currentState: CWPodVerificationState;

  constructor(private _http: HttpClient){
    this._setDefaultState();
  }

  _setDefaultState(){
    this.currentState= {
      mode: 'verify',
      verification: 'idle',
      resend: 'idle',
    }
  }

  getUserContact(ticketId: number, contactType: string){
    //CW_Pods/GetUserContactByTicketId
    return this._http.get<{contact:string}>(
      `${environment.apiBaseUrl}CW_Pods/GetUserContactByTicketId?TicketId=${ticketId}&ContactType=${contactType}`)
  }

  getUserContacts(ticketId: number, contactType: string){
    //CW_Pods/GetUserContactByTicketId
    const obs1 = this._getUserEmail(ticketId);
    const obs2 = this._getUserSms(ticketId);
    const obsCombined = concat(obs1, obs2);
    return obsCombined;
  }

  _getUserEmail(ticketId: number){
    return this._http.get<{contact:string}>(
      `${environment.apiBaseUrl}CW_Pods/GetUserContactByTicketId?TicketId=${ticketId}&ContactType=email`)
      .pipe(
        map(v=>{
          return {email: v.contact}
        })
      )//map this to email 
  }

  _getUserSms(ticketId: number){
    return this._http.get<{contact:string}>(
      `${environment.apiBaseUrl}CW_Pods/GetUserContactByTicketId?TicketId=${ticketId}&ContactType=sms`)
      .pipe(
        map(v=>{
          return {sms: v.contact}
        })//map this to sms 
      )
  }

  setMode(mode: CWPodMode){
    const state = {...this.currentState, ...{mode}};
    this.currentState = state;
    this.stateSubj.next(state);
  }

  setVerificationState(newState:VerificationState){
    const state = {...this.currentState, ...{verification:newState}};
    this.currentState = state;
    this.stateSubj.next(state);
  }

  setSendByState(newState:VerificationState){
    const state = {...this.currentState, ...{resend:newState}};
    this.currentState = state;
    this.stateSubj.next(state);
  }

  get state(){
    return this.stateSubj.asObservable();
  }

  validateClientPin(ticketId: number, clientPin: string){
    const body = {ticketId, clientPin}
    return this._http.post(
      `${environment.apiBaseUrl}CW_Pods/ValidateClientPin`, body);
  }

  sendUserValidation(ticketId: number, sendBy: string){
    const body = {ticketId, sendBy: sendBy}
    return this._http.post(
      `${environment.apiBaseUrl}CW_Pods/SendUserValidation`, body);
  }

  validationByEmail(id: string, status: string){
    const body = {id, status}
    return this._http.post(
      `${environment.apiBaseUrl}CW_Pods/ValidateUserPodValidationByEmail`, body);
  }


  /**
   * {
      "messageSid": "string",
      "body": "string",
      "from": "string",
      "to": "string"
   * }
   */
  validationBySms(id: string, status: string){
    const body = {id, status}
    return this._http.post(
      `${environment.apiBaseUrl}CW_Pods/ValidateUserPodValidationBySMS`, body);
  }

  isAuthorized(screen:string=''){
    let params = '';
    if(screen) params = `?screen=${screen}`;
    return this._http.get(`${environment.apiBaseUrl}CW_Pods/IsAuthorized${params}`);
  }
}


export type VerificationState = 'idle' | 'success' | 'error' ;
export type  CWPodMode = 'verify' | 'sendBy'

export interface CWPodVerificationState {
  mode: CWPodMode,
  verification: VerificationState,
  resend: VerificationState,
}