import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DrawerComponentConfig } from '@app/shared/interfaces/drawer-component-config.interface';

@Component({
  selector: 'app-drawer-container',
  templateUrl: './drawer-container.component.html',
  styleUrls: ['./drawer-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrawerContainerComponent implements OnInit, OnDestroy {

  constructor() { }
 
  @ViewChild("componentTemplate", { read:ViewContainerRef})
  private _templateRef : ViewContainerRef;

  @ViewChild("drawer", {static:true}) _drawer : MatDrawer;

  config? : DrawerComponentConfig;
  openerId?: string;
  isOpen?: boolean;

  ngOnInit(): void {

    this._drawer.closedStart.subscribe(s=>{
      this._templateRef.clear();
      this.config = undefined;
      this.openerId = undefined;
    });

    this._drawer.openedChange.subscribe(s=>{
      this.isOpen = s;
    });
  }
  ngOnDestroy(): void {
    
  }



  openDrawer(config: DrawerComponentConfig){
  
    if(!this._templateRef) 
      return;
    
    this._templateRef.clear();
    
    if(this.config?.toggle && this.openerId && this.openerId == config.openerId && this.isOpen){
      this._drawer.close();
      return;
    }
    
    this.config = config;
    const cr = this._templateRef.createComponent<any>(config.componentType);
    cr.instance.data = config.data;
    cr.instance.drawerContainer = this;
    this._drawer.open();
    this.openerId = this.config.openerId;


  }

  close(){
    this._drawer.close();
  }

}


