import { Injectable } from '@angular/core';

import { BehaviorSubject, finalize, Observable, of, ReplaySubject, tap } from 'rxjs';

import { IButtonsTemplateFilter, IButtonsTemplateList, ISizeDimensions } from './buttons-template-settings.interface';

@Injectable({
  providedIn: 'root'
})
export class ButtonsTemplateSettingsService {
  buttonsTemplateFilters: IButtonsTemplateFilter;

  private _buttonsTemplateData = new ReplaySubject<any[]>(1);
	buttonsTemplateData$ = this._buttonsTemplateData.asObservable();

	private _buttonsTemplateTotalItems = new ReplaySubject<number>(1);
	buttonsTemplateTotalItems$ = this._buttonsTemplateTotalItems.asObservable();

	private _isLoading = new BehaviorSubject(false);
	isLoading$ = this._isLoading.asObservable();

  set isLoading(isLoading: boolean) {
		this._isLoading.next(isLoading);
	}

  constructor() { }

  getButtonsTemplateTable(options: IButtonsTemplateFilter): Observable<IButtonsTemplateList> {
    this.buttonsTemplateFilters = options;

    const param: any = this.getValidParameters({
			Page: options.page,
			PageSize: options.pageSize,
			Column: options.column,
			Order: options.order
		});

    this.isLoading = true;
		this._buttonsTemplateData.next([]);
		this._buttonsTemplateTotalItems.next(0);

    return of({
          "data": [
              {
									"id": 1,
                  "buttonStyleName": "Primary",
                  "buttonPreview": "Button Text",
									"styles": {
										"background": "var(--primary-500)",
										"border": "none",
										"textDecorationLine": "none",
										"color": "var(--primary-800)",
										"textAlign": "center"
									}
              },
              {
								"id": 2,
                "buttonStyleName": "Tertiary",
                "buttonPreview": "Button Text",
								"styles": {
									"background": "none",
									"border": "2px solid var(--secondary-500)",
									"textDecorationLine": "none",
									"color": "var(--primary-800)",
									"textAlign": "center"
								}
            },
						{
							"id": 3,
							"buttonStyleName": "Link",
							"buttonPreview": "Button Text",
							"styles": {
								"background": "none",
								"border": "none",
								"textDecorationLine": "underline",
								"color": "var(--primary-800)",
								"textAlign": "center"
							}
					},
          ],
          "totalCount": 2,
          "pageSize": 1,
          "currentPage": 1
      })
			.pipe(
				tap((res: any) => {
					this._buttonsTemplateData.next(res.data);
					this._buttonsTemplateTotalItems.next(res.totalCount);
				}),
				finalize(() => {
					this.isLoading = false;
				})
			);
  }

	getSizeDimensions(): Observable<ISizeDimensions[]> {
    return of(
			[
				{
					display: 'FIXED',
					value: 'FIXED'
				},
				{
					display: 'HUG',
					value: 'HUG'
				},
				{
					display: 'FILL',
					value: 'FILL'
				}
			]
		);
  }

  getValidParameters(param: any) {
		return Object.fromEntries(
			Object.entries(param).filter(
				([key, value]: any) => !['', null, undefined].includes(value)
			)
		);
	}
}
