import { Component, OnInit } from '@angular/core';
import { OptimizeQuestModalService } from '@app/core/optimize-quest-modal/optimize-quest-modal.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'generic-mobile-qr-code',
  template: ''
})
export class GenericMobileQrCodeComponent implements OnInit {

  constructor(
    private _optimizeQuestService: OptimizeQuestModalService
  ) { }

  ngOnInit(): void {
    if (this._optimizeQuestService.isMobileOrTabletDevice) {
      if (this._optimizeQuestService.isIOS) {
        window.location.replace(environment.iosAppLink);
      } else {
        window.location.replace(environment.androidAppLink);
      }
    }
  }

}
