export enum CardValidationCode {
  IncompleteNumberCode = 'incomplete_number',
  IncompleteExpiryCode = 'incomplete_expiry',
  IncompleteCvcCode = 'incomplete_cvc',
  IncompleteZipCode = 'incomplete_zip'
}

export enum CardValidationMessages {
  /* Card Form */ 
  InvalidCardNumber = 'The card number is invalid',
  InvalidExpiryMonthYear = `The card's expiration month/year is incorrect`,
  InvalidSecurityCode = `The card's security code is invalid`,
  InvalidPostalCode = `The card's postal code is incorrect`,
  CardHolderNameRequired = `Cardholder's Name is required`,
  IncorrectCardAddress = `The card's address is incorrect`,
  IncorrectCardCity = `The card's city is incorrect`,
  IncorrectCardState = `The card's state/province is incorrect`,
  IncorrectCardCountry = `The card's country is incorrect`
}

export enum BankValidationMessages {
  AccountHolderNameRequired = `Account Holder's Name is required`,
  InvalidRoutingNumber = `The bank routing number provided is invalid`,
  InvalidAccountNumber = `Account Number provided is invalid`,
  InvalidConfirmAccountNumber = `Account Number do not match`
}

export enum VerifyBankAccountValidationMessages {
  EnterValueEqualToFirstDepositPlaceholder = 'Enter a value equal to $0.32',
  EnterValueEqualToSecondDepositPlaceholder = 'Enter a value equal to $0.45',
}