import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgxMicrosoftBotFrameworkModule } from 'ngx-microsoft-bot-framework';
import { DragDropCustomModule } from '@app/shared/directives/drag-drop.module';
import { LocalFigmaIconImgModule } from './../../local-figma-icon-img/local-figma-icon-img.module';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';

import { ChatBotComponent } from './chat-bot.component';

@NgModule({
	declarations: [ChatBotComponent],
	imports: [
		CommonModule,
		NgxMicrosoftBotFrameworkModule,
		RouterModule,
		DragDropCustomModule,
		FigmaIconImgModule,
		LocalFigmaIconImgModule,
	],
	exports: [ChatBotComponent],
})
export class ChatBotModule {}
