import { formatDate } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { filter, map, Subject, takeUntil } from 'rxjs';
import { toSQLDate } from '@app/shared/utilities/helper';
import { MaskPipe } from 'ngx-mask';

import { MatMenuTrigger } from '@angular/material/menu';

@Component({
	selector: 'time-input',
	templateUrl: './time-input.component.html',
	styleUrls: ['./time-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TimeInputComponent,
			multi: true,
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
		MaskPipe,
	],
})
export class TimeInputComponent
	implements OnInit, OnChanges, ControlValueAccessor
{
	@ViewChild(MatMenuTrigger) timeTrigger: MatMenuTrigger;
	@ViewChild('maskRef') private _maskRef: ElementRef;

	@Input() value: null | string | Date;
	@Input() placeholder = '12:00 PM';
	/** Converts date value to string */
	@Input() isString = false;
	@Output() timeChange = new EventEmitter<null | string | Date>();

	@HostBinding('class.disabled') @Input() disabled = false;
	@HostBinding('class.focused') focused: boolean = false;

	maskControl = new FormControl('');
	prevValue: Date;
	timeList: string[] = [];
	selectedTime: number;
	meridiem: 'AM' | 'PM' = 'PM';

	private _onChange: any = () => {};
	private _onTouched: any = () => {};

	private _destroy$ = new Subject();

	private _cd = inject(ChangeDetectorRef);
	private _maskPipe = inject(MaskPipe);

	ngOnInit(): void {
		this._setTimeArray();

		this.maskControl.valueChanges
			.pipe(
				takeUntil(this._destroy$),
				filter((v) => !this.disabled),
				map((v) => this._maskTime(v!))
			)
			.subscribe((v) => this.writeValue(v, true));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value']) this.writeValue(this.value);
	}

	@HostListener('click', ['$event'])
	onClick(e: any) {
		this.timeTrigger.restoreFocus = true;
		this.timeTrigger.openMenu();
		this.focused = true;

		const active = document.querySelector('.q-date-time .active-item');
		if (active) active.scrollIntoView({ block: 'center' });

		setTimeout(() => this._maskRef.nativeElement.focus(), 500);
	}

	writeValue(value: null | string | Date, fromMask?: boolean) {
		if (value) {
			const date = this._setDate(value);
			if (!date) {
				this.value = null;
				this.maskControl.setErrors({ mask: true });
			} else this.value = date;
			if (this.timeTrigger.menuOpen && fromMask) this.timeTrigger.closeMenu();
		} else this.value = null;

		if (!fromMask) {
			this.maskControl.setValue(
				this.value ? formatDate(this.value, 'hh:mm a', 'en-US') : '',
				{ emitEvent: false }
			);
			this.meridiem = this.maskControl.value!.includes('A') ? 'AM' : 'PM';
		} else if (!this.disabled) {
			this.selectedTime = value
				? this.timeList.findIndex((t) => (value as string).includes(t))
				: -1;
			this._onTimeChange(this.value);
		}
		this._cd.detectChanges();
	}

	onTimeSelected(i: number) {
		setTimeout(() => {
			this.timeTrigger.restoreFocus = false;
			this.timeTrigger.closeMenu();
			this.onBlur();
		}, 500);

		if (this.disabled) return;
		this.selectedTime = i;
		const date = this._setDate(this.timeList[i] + ' ' + this.meridiem);
		this.writeValue(date);
		this._onTimeChange(date);
		this._onTouched();
	}

	onMeridiemSelected(meridiem: 'AM' | 'PM') {
		this.meridiem = meridiem;
		const value = this.maskControl.value!.split(' ')[0];
		if (value.length >= 4) {
			const date = this._setDate(value + ' ' + this.meridiem);
			this.writeValue(date);
			this._onTimeChange(date);
		}
		this._maskRef.nativeElement.focus();
	}

	onBlur() {
		if (!this.timeTrigger.menuOpen) {
			this.focused = false;
			this._onTouched();
			if (this.maskControl.invalid) {
				const value = this._maskTime(this.maskControl.value!, true);
				this.writeValue(value, true);
			}
		}
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	onKeyDown(event: KeyboardEvent) {
		if (event.key != 'Tab') return;
		this.timeTrigger.restoreFocus = false;
		this.timeTrigger.closeMenu();
		this.onBlur();
	}

	ngOnDestroy(): void {
		this._destroy$.next(null);
		this._destroy$.complete();
	}

	private _onTimeChange(date: null | string | Date) {
		if (date) date = this.isString ? toSQLDate(date) : date;
		this._onChange(date);
		this.timeChange.emit(date);
	}

	private _maskTime(time: string, fill?: boolean) {
		const caret = this._maskRef.nativeElement.selectionStart;

		if (caret == undefined || time.length == caret || time.length >= 8) {
			const h = time.split(':')[0];
			if (
				(h.length == 1 && (time.includes(':') || parseInt(h) > 1)) ||
				(h.length == 2 && parseInt(h) > 12)
			)
				time = '0' + time;

			time = time.toUpperCase();
			let mTime = this._maskPipe.transform(time!, 'Hh:m0');
			let ampm = '';

			if (time.includes('PM')) ampm = ' PM';
			else if (time.includes('AM')) ampm = ' AM';
			else if (time.includes('P')) ampm = fill ? ' PM' : ' P';
			else if (time.includes('A')) ampm = fill ? ' AM' : ' A';
			else if (time.length >= 5 && time.includes(' '))
				ampm = fill ? ' PM' : ' ';
			else if (time.length >= 4 && fill) ampm = fill ? ' PM' : ' ';

			if (ampm != '' && mTime.length == 4) {
				const tArray = mTime.split(':');
				tArray[1] = parseInt(tArray[1]) > 5 ? '0' + tArray[1] : tArray[1] + '0';
				mTime = tArray.join(':');
			}

			time = mTime + ampm;
			if (this.maskControl.value != time)
				this.maskControl.setValue(time, { emitEvent: false });

			if (time.includes('P')) this.meridiem = 'PM';
			else if (time.includes('A')) this.meridiem = 'AM';
		}

		if (time.length && time.length != 8)
			this.maskControl.setErrors({ mask: true });
		else this.maskControl.setErrors(null);

		return time.length == 8 ? time : null;
	}

	private _setDate(time: string | Date) {
		let resp: Date | null;
		try {
			if (time instanceof Date || time.length > 8) {
				resp = new Date(toSQLDate(time));
			} else {
				const pDate =
					this.prevValue instanceof Date ? this.prevValue : new Date();
				const date = toSQLDate(pDate, false);
				resp = new Date(date + ' ' + time);
			}
		} catch (error) {
			resp = null;
		}
		if (resp) this.prevValue = resp;
		return resp;
	}

	private _setTimeArray() {
		let times = [];
		for (let hour = 0; hour < 12; hour++) {
			for (let minute = 0; minute < 60; minute += 15) {
				const hourFormatter =
					hour === 0 ? hour.toString().replace('0', '12') : hour;
				const minuteFormatted = minute < 10 ? '0' + minute : minute;
				times.push(`${hourFormatter}:${minuteFormatted}`);
			}
		}
		this.timeList = times;
	}
}
