/* Angular Libraries */
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { NotificationService } from '@app/core/services/notification.service';

/* Interfaces | Constants */
import { NotificationMessages } from '@app/shared/constants';
import {
	BankMethod,
	CardMethod,
	PaymentMethod,
	States,
	StripePublishable,
} from '@app/shared/interfaces/invoice.interface';
import { CardValidationCode, CardValidationMessages } from '../../validation-messages/invoice-validation-messages.inum';

declare let Stripe: any;

@Component({
	selector: 'app-select-method',
	templateUrl: './select-method.component.html',
	styleUrls: ['./select-method.component.scss'],
})
export class SelectMethodComponent implements OnInit, OnDestroy {
	/* Input/Output */
	@Input() invoiceAmount: number;
	@Input() set creditMemoTotalAmount(value: number) {
		this._creditMemoTotalAmount = value;
	}
	@Input() set paymentTotalAmount(value: number) {
		this._paymentTotalAmount = value;
	}

	@Output() onInitPaymentMethodList: EventEmitter<any> =
		new EventEmitter<any>();
	@Output() onSelectedMethod: EventEmitter<string> = new EventEmitter<string>();
	@Output() onCallRequestPayment: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCreditsCheckedUpdate: EventEmitter<any> = new EventEmitter<any>();

	/* Public Properties */
	cardOption: Array<CardMethod> = [];
	bankOption: Array<BankMethod> = [];
	stateList: States = [];
	paymentType: FormControl = new FormControl('');
	cardDetailsForm: FormGroup;
	paymentElement: any;
	cardNumberValidationMessage: string = '';
	cardValidationMessages = CardValidationMessages;
	isLoadingCardInput: boolean = true;

	/* Private Properties */
	private _stripe: any;
	private _elements: any;
	private _$unsubscribe: Subject<void> = new Subject<void>();
	private _creditMemoTotalAmount: number;
	private _paymentTotalAmount: number;

	/* Getters/Setters */
	get creditMemoTotalAmount(): number {
		return this._creditMemoTotalAmount;
	}
	get paymentTotalAmount(): number {
		return this._paymentTotalAmount;
	}

	get isAdmin() {
		return this._stripeService.isAdmin;
	}

	get hasInvalidClassName() {
		return (document as any).getElementById('payment-element').classList.contains('StripeElement--invalid');
	}

	/* Constructor */
	constructor(
		private _stripeService: StripeService,
		private _spinner: SpinnerService,
		private _toastMessageService: ToastMessageService,
		private _router: Router,
		private fb: FormBuilder,
		private _notifier: NotificationService
	) {}

	/* Methods */
	ngOnInit(): void {
		this._getPaymentMethodList();
		this._initForm();
		this._initStates();
	}

	onChangeMethodType() {
		if (this.paymentType.value === 'one-time-payment') {
			this._initStripeSandBox();
		}

		this.onSelectedMethod.emit(this.paymentType.value);
	}

	onChangeSelectedCredits() {
		let isValid: boolean = false;
		let total: number = 0;

		total =
			Number(this.paymentTotalAmount) + Number(this.creditMemoTotalAmount);

		if (this.paymentType.value) {
			/* Set to true when it has cardOption or bankOption value */
			isValid = true;
		} else {
			/* Added checking here when cardOption or bankOption has no return value and it should matched the invoice amount and credits amount */
			isValid = total === this.invoiceAmount;
		}

		this.onCreditsCheckedUpdate.emit({
			isValid: isValid && this.invoiceAmount,
			isShowPayNowLabel:
				this.paymentType.value &&
				!this.creditMemoTotalAmount &&
				!this.paymentTotalAmount,
			isShowApplyCreditLabel:
				(this.creditMemoTotalAmount === this.invoiceAmount ||
					total === this.invoiceAmount) &&
				this.creditMemoTotalAmount,
			isShowPaymentWithUnappliedPaymentLabel:
				this.paymentTotalAmount === this.invoiceAmount &&
				this.paymentTotalAmount,
			isShowApplyCreditAndPayLabel:
				total !== this.invoiceAmount &&
				(this.creditMemoTotalAmount || this.paymentTotalAmount) &&
				this.paymentType.value,
		});
	}

	async onSaveToPaymentMethods() {
		const params = {
			name: this.cardDetailsForm.controls['cardHolderName'].value,
			address_country: this.cardDetailsForm.controls['addressCountry'].value,
			address_line1: this.cardDetailsForm.controls['addressLine1'].value,
			address_line2: this.cardDetailsForm.controls['addressLine2'].value,
			address_city: this.cardDetailsForm.controls['addressCity'].value,
			address_state: this.cardDetailsForm.controls['addressState'].value,
		};

		this.cardDetailsForm.markAllAsTouched();

		const { token, error } = await this._stripe.createToken(
			this.paymentElement,
			params
		);

		if (!token) {
			this._spinner.stop();
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['cardHolderName'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['addressLine1'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['addressState'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['addressCity'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (token) {
			this._spinner.start();
			this._stripeService
				.addStripeCards(
					{ token: token.id }
				)
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (result) => {
						this._spinner.stop();
						const message = NotificationMessages.success(
							'Saved',
							'Add Card Details'
						);
						this._notifier.notify(message, {
							duration: 5,
							panelClass: 'success',
						});
						this._getPaymentMethodList();
					},
					error: (err) => {
						this._spinner.stop();
						this._toastMessageService.showErrorMessage(err.errors);
					},
				});
		}
	}

	async createNewPaymentMethod() {
		this._clearForms();
		
		const { paymentMethod, error } = await this._stripe.createPaymentMethod({
			elements: this._elements,
			params: {
				billing_details: {
					address: {
						country: 'US',
						postal_code: '',
					},
					email: '',
				},
			},
		});

		if (error) {
			if (error.code === CardValidationCode.IncompleteNumberCode) {
				this.cardDetailsForm.get('isValidCardNumber')?.markAsTouched();
				this.cardNumberValidationMessage = CardValidationMessages.InvalidCardNumber;
				this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			} else {
				this._toastMessageService.showErrorMessage(error.message);
			}

			return;
		}

		if (paymentMethod) {
			this.paymentType.setValue(paymentMethod.id);
			this.onChangeMethodType();
			this.onCallRequestPayment.emit();
		}
	}

	private _initForm() {
		this.cardDetailsForm = this.fb.group({
			isValidCardNumber: ['', Validators.required],
			cardHolderName: ['', Validators.required],
			addressLine1: ['', Validators.required],
			addressLine2: [''],
			addressCity: ['', Validators.required],
			addressState: ['', Validators.required],
			addressCountry: ['United States', Validators.required],
		});
	}

	private _initStates() {
		this._stripeService
			.getStatesForUnitedStates()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: States) => {
					this.stateList = result;
				},
			});
	}

	private _getPaymentMethodList() {
		this._spinner.start();

		this._stripeService
			.getPaymentMethodList()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: PaymentMethod) => {
					let combinedArray: Array<any> = [];

					if (result) {
						this.cardOption = result.card;
						this.bankOption = result.bankAccount;

						combinedArray = result.card.concat(
							result.bankAccount as Array<any>
						);
						combinedArray.filter((d: any) =>
							d.isDefaultPayment ? this.paymentType.setValue(d.id) : ''
						);
						this.onInitPaymentMethodList.emit({
							paymentMethodList: result,
							defaultSelectedPaymentId: this.paymentType.value,
						});
						if (this.paymentType.value) {
							this.onChangeSelectedCredits();
						}
					}

					this._spinner.stop();
				},
				error: (err) => {
					this._spinner.stop();
					this._toastMessageService.showErrorMessage(err);
				},
			});
	}

	private _initStripeSandBox() {
		this._clearForms();
		this.isLoadingCardInput = true;
		this._spinner.start();

		this._stripeService.getStripePublishableKey().subscribe({
			next: (result: StripePublishable) => {
				this._stripe = Stripe(result.publishableKey);

				const options = {
					mode: 'setup',
					currency: 'usd',
					paymentMethodCreation: 'manual',
					/* Fully customizable with appearance API. */
					appearance: { theme: 'stripe' },
				};

				/* Set up Stripe.js and Elements to use in checkout form */
				this._elements = this._stripe.elements(options);

				/* Create and mount the Payment Element */
				const paymentElementOptions = {
					layout: 'tabs',
					defaultBillingDetails: {
						address: {
							country: 'US',
						},
						email: '',
					},
					fields: {
						billingDetails: {
							address: {
								country: 'never',
								postalCode: 'never',
							},
							email: 'never',
						},
					},
					style: {
						base: {
							iconColor: '#5C5D60',
							color: '#5C5D60',
							fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif, Metropolis',
							fontSize: '15px',
							':-webkit-autofill': {
								color: '#AFB0B3',
							},
							'::placeholder': {
								color: '#AFB0B3',
							},
						},
						invalid: {
							iconColor: '#E96458',
							color: '#E96458',
						},
					},
				};

				this.paymentElement = this._elements.create('card', paymentElementOptions);
				setTimeout(() => this.paymentElement.mount('#payment-element'), 0);
			},
			error: () => {
				this.isLoadingCardInput = false;
				this._spinner.stop();
			},
			complete: () => {
				this._cardNumberValidation();
				this.isLoadingCardInput = false;
				this._spinner.stop();
			},
		});
	}

	private _cardNumberValidation() {
		this.paymentElement.on('blur', (event: any) => this._stripe.createToken(this.paymentElement, {}));
		
		this.paymentElement.on('change', (event: any) => {
			if (event.error !== undefined) {
				this.cardDetailsForm.get('isValidCardNumber')?.markAsTouched();
				this.cardNumberValidationMessage = event.error.message;

				switch(event.error.code) {
					case CardValidationCode.IncompleteNumberCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidCardNumber;
						break;

					case CardValidationCode.IncompleteExpiryCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidExpiryMonthYear;
						break;

					case CardValidationCode.IncompleteCvcCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidSecurityCode;
						break;

					case CardValidationCode.IncompleteZipCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidPostalCode;
				}

			} else {
				this.cardDetailsForm.get('isValidCardNumber')?.markAsUntouched();
			}
		});
	}

	private _clearForms() {
		Object.keys(this.cardDetailsForm.controls).forEach(key => {
			if (key !== 'addressCountry') {
				this.cardDetailsForm.controls[key].setValue(null);
				this.cardDetailsForm.controls[key].markAsUntouched();
			}
		});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
