import { formatDate } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
	DateAdapter,
	MAT_DATE_LOCALE,
	NativeDateAdapter,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { filter, map, Subject, takeUntil } from 'rxjs';
import { toSQLDate, toSQLTime } from '@app/shared/utilities/helper';
import { MaskPipe } from 'ngx-mask';

import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';

export class CustomDateAdapter extends NativeDateAdapter {
	override getDayOfWeekNames(): string[] {
		return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	}
}

@Component({
	selector: 'date-input',
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateInputComponent,
			multi: true,
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
		{
			provide: DateAdapter,
			useClass: CustomDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		MaskPipe,
	],
})
export class DateInputComponent
	implements OnInit, OnChanges, ControlValueAccessor
{
	@ViewChild(MatDatepicker) datepicker: MatDatepicker<any>;
	@ViewChild('maskRef') private _maskRef: ElementRef;

	@Input() value: null | string | Date;
	@Input() placeholder = 'MM/DD/YYYY';
	/** Converts date value to string */
	@Input() isString = false;
	@Input() min: string | Date;
	@Input() max: string | Date;
	/** Time format if string - HH:mm:ss */
	@Input() timeValue: null | string | Date;
	@Output() dateChange = new EventEmitter<null | string | Date>();

	@HostBinding('class.disabled') @Input() disabled = false;
	@HostBinding('class.focused') focused: boolean = false;

	maskControl = new FormControl('');
	customHeader = CalendarHeaderComponent;

	private _onChange: any = () => {};
	private _onTouched: any = () => {};

	private _destroy$ = new Subject();

	private _cd = inject(ChangeDetectorRef);
	private _mask = inject(MaskPipe);

	ngOnInit(): void {
		this.maskControl.valueChanges
			.pipe(
				takeUntil(this._destroy$),
				filter((v) => !this.disabled),
				map((v) => this._maskDate(v!))
			)
			.subscribe((v) => this.writeValue(v, true));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value']) this.writeValue(this.value);
		if (changes['min'] && this.min) {
			const min = new Date(this.min);
			min.setDate(min.getDate() + 1);
			this.min = min;
		}
		if (changes['max'] && this.max) {
			const max = new Date(this.max);
			max.setDate(max.getDate() + 1);
			this.max = max;
		}
		if (changes['timeValue'] && this.value) {
			this._onDateChange(this.value as Date);
		}
	}

	@HostListener('click', ['$event'])
	onClick(e: any) {
		this.datepicker.restoreFocus = true;
		this.datepicker.open();
		this.focused = true;
		setTimeout(() => {
			this._maskRef.nativeElement.focus();
			this.datepicker['_focusedElementBeforeOpen'] =
				this._maskRef.nativeElement;
		}, 500);
	}

	writeValue(value: null | string | Date, fromMask?: boolean) {
		if (!value && !this.value) return;

		if (value) {
			const date = new Date(value);
			if (isNaN(date.getDate())) {
				this.value = null;
				this.maskControl.setErrors({ mask: true });
			} else this.value = date;
			if (this.datepicker?.opened) this.datepicker.close();
		} else this.value = null;

		if (!fromMask)
			this.maskControl.setValue(
				this.value ? formatDate(this.value, 'MM/dd/YYYY', 'en-US') : '',
				{ emitEvent: false }
			);
		else if (!this.disabled) this._onDateChange(this.value);
		this._cd.detectChanges();
	}

	onModelChange(date: Date) {
		this._delayClose();
		if (this.disabled) return;
		this.writeValue(date);
		this._onDateChange(date);
	}

	onBlur() {
		if (!this.datepicker.opened) {
			this.focused = false;
			this._onTouched();
		}
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	onKeyDown(event: KeyboardEvent) {
		if (event.key != 'Tab') return;
		this.datepicker.restoreFocus = false;
		this.datepicker.close();
		this.onBlur();
	}

	ngOnDestroy(): void {
		this._destroy$.next(null);
		this._destroy$.complete();
	}

	moveCaret() {
		this._maskRef.nativeElement.setSelectionRange(10, 10);
	}

	private _maskDate(date: string) {
		const caret = this._maskRef.nativeElement.selectionStart;

		if (
			caret == undefined ||
			date.length == caret ||
			date.replace(/\//g, '').length >= 8
		) {
			const month = date.split('/')[0];
			if ((month.length == 2 || date.includes('/')) && parseInt(month) <= 0)
				date = '0';
			if (
				(month.length == 1 && (date.includes('/') || parseInt(month) > 1)) ||
				(month.length == 2 && parseInt(month) > 12)
			)
				date = '0' + date;

			const dArray = date.split('/');
			let day = dArray[1];
			if (day) {
				if ((day.length == 2 || dArray[2] != undefined) && parseInt(day) <= 0)
					date = date.substring(0, 4);
				else if (
					(day.length == 1 && (dArray[2] != undefined || parseInt(day) > 3)) ||
					(day.length == 2 && parseInt(day) > 31)
				)
					(dArray[1] = '0' + day), (date = dArray.join('/'));
			}

			date = this._mask.transform(date, 'M0/d0/0000');

			if (this.maskControl.value != date)
				this.maskControl.setValue(date, { emitEvent: false });
		}

		if (date.length && date.length != 10)
			this.maskControl.setErrors({ mask: true });
		else this.maskControl.setErrors(null);

		return date.length == 10 ? date : null;
	}

	private _onDateChange(date: null | Date) {
		let value: null | string | Date = date;

		if (value) {
			if (this.timeValue) {
				const time =
					this.timeValue instanceof Date
						? toSQLTime(this.timeValue)
						: this.timeValue;
				value = toSQLDate(value, false) + ' ' + time;
				if (!this.isString) value = new Date(value);
			} else value = this.isString ? toSQLDate(value, false) : value;
		}

		this._onChange(value);
		this.dateChange.emit(value);
	}

	private _delayClose() {
		this.datepicker['_opened'] = false;
		this.datepicker.opened = false;
		this.datepicker.restoreFocus = false;
		setTimeout(() => {
			this.datepicker['_opened'] = true;
			this.datepicker.opened = true;
			this.datepicker.close();
			this.onBlur();
		}, 500);
	}
}
