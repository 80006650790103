<a
	href="javascript:void(0)"
	tabindex="-1"
	mat-dialog-close
	class="editclient-popup-close close"
>
	<span aria-hidden="true">
		<app-figma-icon-img
			[iconName]="'x'"
			[isForButton]="true"
			[hasSpaceOnRight]="false"
		></app-figma-icon-img>
	</span>
</a>

<h6>List of Invoice Permissions</h6>

<div mat-dialog-content>
	<div class="editclient-popup-con-inner">
		<div class="editclient-popup-table">
			<table>
				<thead>
					<tr>
						<th>Permissions</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody *ngFor="let invoice of invoiceData">
					<tr>
						<td>{{ invoice.name }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									(click)="changeMenuPermission(invoice)"
									[(ngModel)]="invoice.isAllowed"
									checked=""
									id="togBtn"
								/>
								<div class="slider round">
									<span class="on">On</span>
									<span class="off">Off</span>
								</div>
							</label>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
