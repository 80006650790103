import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';

import { Order } from '@app/shared/interfaces/order.interface';

import { PaginationConstants, TableMessages, Permission, MenuPermissionConstant} from '@app/shared/constants';

import { Subscription, tap, catchError, BehaviorSubject, Observable } from 'rxjs';

import { ThemeService } from '@app/shared/services/theme.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@services/user.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { QuotesOrdersService } from '@app/modules/quotes-and-orders/quotes-and-orders.service';
import { DashboardService } from '@app/modules/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { IOrders } from '@app/modules/quotes-and-orders/pages/orders/store/orders.interface';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store } from '@ngrx/store';
import { ordersUpdateAction } from '@app/modules/quotes-and-orders/pages/orders/store/orders.actions'
import { ordersInitialState } from '@app/modules/quotes-and-orders/pages/orders/store/orders.reducer';
import { SeeMore, TableDynamicColumns, WidgetDynamicTable2 } from '@app/shared/interfaces/dashboard.interface';
import { style } from '@angular/animations';


@Component({
  selector: 'app-current-open-order',
  templateUrl: './current-open-order.component.html',
  styleUrls: ['./current-open-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentOpenOrderComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  currentOrders$: Observable<Order[] | null>;

  _startDate: string = '';
  _endDate: string = '';

  filterUrl: string = 'sort15';
  themeSubs: Subscription;
  dataSource: MatTableDataSource<Order>;
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;
  tempQuery = this.ordersService.orderSearchText;
  message = {
    noRecord: TableMessages.EmptyTable,
  };

  userId =
    this.userService.user !== null && this.userService.user.roleId === 3
      ? this.userService.user.id
      : 0;
  companyId =
    this.userService.user !== null && this.userService.user.roleId !== 1
      ? this.userService.user.companyId
      : 0;

  source:any[] = [];
  noRecords:string = TableMessages.EmptyTable;
  columns: TableDynamicColumns[] = [];
  displayedColumns:string[] = [];
  rowClick: (data:Order)=>void
  noClick: ()=>void
  seeMore: SeeMore;
  rowClickable = true;

  dataTable$ = new BehaviorSubject<WidgetDynamicTable2 | null>(null);
  isViewEnabled = MenuPermissionConstant.QuoteAndOrders.subMenus!['orders'];

  constructor(
    public ordersService: QuotesOrdersService,
    public userService: UserService,
    public spinner: SpinnerService,
    private _themeService: ThemeService,
    private _toastMessageService: ToastMessageService,
    private _cd: ChangeDetectorRef,
    private _dashboardService: DashboardService,
    private _router: Router,
    private _store: Store<AppStateInterface>,
  ){
    _dashboardService.initCurrentOpenOrders()
    this.currentOrders$ = _dashboardService.currentOrders$;
  }

  ngOnInit(): void {
    this.getOrderList();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  getOrderList() {
    this.currentOrders$
      .pipe(
        tap(v=>this.totalItems = v!.length),
        catchError(v=>[])
      )
      .subscribe((data: Order[]|null) => {
          data = data!.splice(0,5);
          this.#createDataTable(data)
      });
  }

  #createDataTable(orders: Order[]){
    this.source = orders;
    this.columns = this.#getColumns()
    this.displayedColumns = this.#getDisplayedColumns()
    this.seeMore = this.#getSeeMore();
    this.rowClick = this.rowClickFn
    this.dataTable$.next(this.getDataTable())
    this._cd.detectChanges()
  }

  rowClickFn = (data:Order) =>{
    const {id} = data
    this._router.navigateByUrl(`/billing-and-orders/orders/${id}`);
  }

  #getColumns(){
    return [{
        text: 'Order Date',
        matColumnDef: 'orderDate',
      },
      {
        text: 'Sales Order #',
        matColumnDef:'id',
        class:{
          th:'center-text',
          td:'center-text'
        }
      },
      {
        text: 'Customer PO #',
        matColumnDef:'customerPO',
      },
      {
        text: 'Description',
        matColumnDef:'description',
        style:{
          td: {
            'min-width.px': 150,
            'whiteSpace': 'pre-wrap',
            'display': 'table-cell',
          },
          th: {
            'min-width.px': 150,
            'whiteSpace': 'pre-wrap',
            'display': 'table-cell',
          }
        }
      },
      {
        text: 'Total',
        matColumnDef:'total',
      },
      {
        text: 'Status',
        matColumnDef:'status',
      },
    ]
  }

  #getDisplayedColumns(){
    return [
      'orderDate',
      'id',
      'customerPO',
      'description',
      'total',
      'status',
    ]
  }

  #getSeeMore():SeeMore{
    const state = {...ordersInitialState, ...{statusId:[1,2,3,4,5]}}
    return {
      link: '/billing-and-orders/orders',
      state: ordersUpdateAction(state),
    }
  }

  getDataTable(): WidgetDynamicTable2{
    return {
      source: this.source,
      columns: this.columns,
      rowClick: this.isViewEnabled ? this.rowClick : () => {},
      noClick: this.noClick,
      noRecordMessage: this.noRecords,
      displayedColumns: this.displayedColumns,
      seeMore: this.seeMore,
      rowClickable: this.isViewEnabled ? this.rowClickable : false,
    }
  }

  goToTicketList(){
    this.#updateState({...ordersInitialState, ...{statusId:[1,2,3,4,5]}})
    this._router.navigateByUrl('/quote-and-orders/orders');
  }

  #updateState(options: Partial<IOrders>){
    this._store.dispatch(ordersUpdateAction({...options}))
  }

  goToTicket(id:number){
    this._router.navigateByUrl(`/quote-and-orders/orders/${id}`);
  }
}
