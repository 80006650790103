<div
	*ngIf="isBackButton"
	class="my-auto mt-lg-0 mt-4"
	[ngClass]="{ 'ms-auto': !removeMsAuto, 'back-btn': backBtn }"
>
	<div class="ms-auto my-auto">
		<a class="btn-2 btn-2-secondary q-pointer" [routerLink]="link">
			<mat-icon style="margin-bottom: 0.5px" svgIcon="chevron-left"></mat-icon>
			Back
		</a>
	</div>
</div>
