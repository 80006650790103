import { Component, OnInit } from '@angular/core';
import { UsersActivityGraphService } from './users-activity-graph.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'users-activity-graph',
  templateUrl: './users-activity-graph.component.html',
  styleUrls: ['./users-activity-graph.component.scss']
})
export class UsersActivityGraphComponent implements OnInit {

  constructor(
    public usersActivity: UsersActivityGraphService,
    private _router: Router,
    private _route: ActivatedRoute,
  ){

  }

  ngOnInit() {
  }

  customizeTooltip = (info: any) => {
    return ({
      html: `
      <div class="tooltip-container">
        <div class='tooltip-header'>${info.argumentText}</div>
        <div class='tooltip-body'>
          <div class='series'>
            <span class='top-series-name'>${info.seriesName}:</span>
            <span class='top-series-value'>${info.valueText}</span>
          </div>
        </div>
      </div>`
    })
  }

  onChartClick(e:any){
    const {userId} = e.target.data;
    let {fromDate, toDate}  = e.target.data;
    fromDate = (fromDate as string).split('T')[0]
    toDate = (toDate as string).split('T')[0]
    this._router.navigate([userId], { 
      relativeTo: this._route,
      queryParams: {fromDate,toDate}
    });
  }

  onPointHoverChanged(e: any) {
    if(e.target.isHovered()) {
        e.element.style.cursor = "pointer"
    } else {
        e.element.style.cursor = "auto"
    }
  }

}
