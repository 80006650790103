import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'checkbox-input',
	templateUrl: './checkbox-input.component.html',
	styleUrls: ['./checkbox-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CheckboxInputComponent,
			multi: true,
		},
	],
})
/**
 * Use with ngModel, Form Control, and Form
 */
export class CheckboxInputComponent implements OnInit, ControlValueAccessor {
	@Input() checked = false;
	@Input() disabled = false;
	/**
	 * Only applies if checked = false
	 */
	@Input() indeterminate = false;
	@Input() labelPosition: 'before' | 'after' = 'after';

	_onChange: any = () => {};;
	_onTouched: any = () => {};;

	constructor() {}

	ngOnInit(): void {
	}

	registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

	onModelChange(e: boolean) {
    this.checked = e;
    this._onChange(e);
  }

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

}
