import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { OverlayModule } from '@angular/cdk/overlay';
import { FigmaIconImgModule } from './../../figma-icon-img/figma-icon-img.module';

import { MultiSelectInputComponent } from './multi-select-input.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [MultiSelectInputComponent],
	imports: [
		CommonModule,
		MatSelectModule,
		ScrollingModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatChipsModule,
		OverlayModule,
		FigmaIconImgModule,
		MatIconModule
	],
	exports: [MultiSelectInputComponent],
})
export class MultiSelectInputModule {}
