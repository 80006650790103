import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";
import { ITopBar } from "../top-bar.interface";



export const topBarSelector = (state: AppStateInterface): ITopBar => state.topBar;

export const messageSelector =
createSelector(topBarSelector, (state: ITopBar)=>state.message);

export const impersonateSelector =
createSelector(topBarSelector, (state: ITopBar)=>state.impersonate);

export const sidebarOpenSelector =
createSelector(topBarSelector, (state: ITopBar)=>state.sidebar);
