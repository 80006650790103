import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { CommentComponent } from './comment/comment.component';
import { ReplyComponent } from './reply/reply.component';
import {
	TicketDropdownDirective,
	ViewTicketComponent,
} from './view-ticket.component';
import { ViewTicketPdfComponent } from './view-ticket-pdf/view-ticket-pdf.component';

import { SharedModule } from '@app/shared/shared.module';
import { BootstrapTooltipModule } from './../../../directives/bootstrap/bootstrap-tooltip.module';
import { BadgeModule } from './../../badges/badge/badge.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TrimPipeModule } from '@app/shared/pipes/trim.pipe.module';
import { CustomDatePipeModule } from '@pipes/custom-date.pipe.module';
import { DragDropCustomModule } from '@app/shared/directives/drag-drop.module';
import { FormTooltipModule } from '../../form-tooltip/form-tooltip.module';
import { ConvertedDateModule } from '@app/shared/pipes/converted-date/converted-date.module';
import { CarbonCopyModule } from '../../carbon-copy/carbon-copy.module';
import { ModalV2Module } from '../../modal/modal-v2/modal-v2.module';
import { EmailDropdownInputModule } from '../../form-input/email-dropdown-input/email-dropdown-input.module';
import { NoProfileAvatarModule } from '../../no-profile-avatar/no-profile-avatar.module';
import { ViewTicketService } from './view-ticket.service';
import { AiSummaryComponent } from './ai-summary/ai-summary.component';
import { AiSummaryInstructionComponent } from './ai-summary-instruction/ai-summary-instruction.component';

@NgModule({
	declarations: [
		ViewTicketComponent,
		CommentComponent,
		ReplyComponent,
		TicketDropdownDirective,
		ViewTicketPdfComponent,
		AiSummaryComponent,
		AiSummaryInstructionComponent,
	],
	imports: [
		CommonModule,
		DragDropModule,
		TrimPipeModule,
		FormTooltipModule,
		ReactiveFormsModule,
		DragDropCustomModule,
		RouterModule,
		CustomDatePipeModule,
		BadgeModule,
		BootstrapTooltipModule,
		ConvertedDateModule,
		SharedModule,
		CarbonCopyModule,
		ModalV2Module,
		EmailDropdownInputModule,
		NoProfileAvatarModule,
	],
	exports: [ViewTicketComponent, BadgeModule, BootstrapTooltipModule],
	providers: [ViewTicketService],
})
export class ViewTicketModule {}
