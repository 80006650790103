import { MatSortModule } from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTableComponent } from './activity-table.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule
  ],
  declarations: [ActivityTableComponent],
  exports: [ActivityTableComponent],
})
export class ActivityTableModule { }
