<ng-container>
	<div class="support-head">
		<h5 class="text-sm">{{ message?.message }}</h5>
		<div class="sub-close"></div>
	</div>
	<div class="support-conts">
		<div class="row m-0">
			<div class="col-md-8 p-0"></div>
			<div class="col-md-4 p-0">
				<div class="print-list text-end">
					<ul class="list-unstyled d-flex d-inline-flex no-gap">
						<ng-container *ngIf="!impersonating">
							<li
								[class.tagged]="message?.isPinned"
								(click)="togglePin(message!)"
								title="Pin or Un-pin"
								class="imp-disabled-op"
							>
								<a
									class="d-flex justify-content-center align-items-center flex-wrap q-pointer"
									href="javascript:void(0)"
								>
									<app-figma-icon-img
										[iconName]="'pin-01'"
										[isForButton]="true"
										[hasSpaceOnRight]="false"
									></app-figma-icon-img>
								</a>
							</li>
							<li
								[class.tagged]="message?.isSaved"
								(click)="toggleSave(message!)"
								title="Save or Unsave"
								class="imp-disabled-op"
							>
								<a
									class="d-flex justify-content-center align-items-center flex-wrap q-pointer"
									href="javascript:void(0)"
								>
									<app-figma-icon-img
										[iconName]="'save-01'"
										[isForButton]="true"
										[hasSpaceOnRight]="false"
									></app-figma-icon-img>
								</a>
							</li>
						</ng-container>
						<li>
							<a
								class="d-flex justify-content-center align-items-center flex-wrap q-pointer"
								(click)="print()"
							>
								<app-figma-icon-img
									[iconName]="'printer'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
								></app-figma-icon-img>
							</a>
						</li>
						<li>
							<a
								role="button"
								(click)="
									setMessageName(
										message?.title,
										message?.previousId,
										message?.categoryId
									)
								"
								class="prevnext d-flex justify-content-center align-items-center flex-wrap"
								[ngClass]="{ disabled: !message?.previousId }"
							>
								<app-figma-icon-img
									[iconName]="'arrow-square-left'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
								></app-figma-icon-img>
							</a>
						</li>
						<li>
							<a
								(click)="
									setMessageName(
										message?.title,
										message?.nextId,
										message?.categoryId
									)
								"
								role="button"
								class="prevnext d-flex justify-content-center align-items-center flex-wrap"
								[ngClass]="{ disabled: !message?.hasNext }"
							>
								<app-figma-icon-img
									[iconName]="'arrow-square-right'"
									[isForButton]="true"
									[hasSpaceOnRight]="false"
								></app-figma-icon-img>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="msg-conts" id="print-payment">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="card-header pb-0">
							<div style="width: 100%">
								<ng-container *ngTemplateOutlet="orderHeader"></ng-container>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<!-- <div class="card-body px-0 pb-0 pt-0"> -->
					<ng-container *ngTemplateOutlet="productTbl"></ng-container>
					<!-- </div> -->
				</div>
				<div class="card">
					<!-- <div class="card-body px-0 pb-0 pt-0"> -->
					<ng-container *ngTemplateOutlet="invoiceTbl"></ng-container>
					<!-- </div> -->
				</div>
			</div>
		</div>
	</div>
</ng-container>

<!--orderHeader template-->
<ng-template #orderHeader>
	<div class="row mt-3" *ngIf="orderDetail as header">
		<div class="col-lg-6">
			<div class="categories-form categories-orders-form">
				<div class="categories-form-col row">
					<div class="form-group col-12 col-sm-6">
						<label for="example-text-input" class="form-control-label"
							>Sales Order #</label
						>
						<!-- <input class="form-control text-xs" type="text" value="" placeholder="6844" /> -->
						<p class="order-content2">
							{{ header.id }}
							<!-- <span class="badge badge-sm bg-gradient-success">{{header.status}}</span></p> -->
							<!-- <app-badge
                    [label]="header.status"
                    [type]="
                      header.color
                    "
                  ></app-badge> -->
						</p>
					</div>

					<div class="form-group col-12 col-sm-6">
						<label class="mt-4">Subtotal</label>
						<!-- <input class="form-control text-xs" type="text" value="" placeholder="2022-04-04T14:23:19" /> -->
						<p class="order-content">
							{{ header.subtotal | number : '1.2-2' }}
						</p>
					</div>
					<div class="form-group col-12 col-sm-6">
						<label class="mt-4">Customer PO#</label>
						<!-- <input class="form-control text-xs" type="text" value="" placeholder="219329" /> -->
						<p class="order-content">{{ header.customerPO }}</p>
					</div>
					<div class="form-group col-12 col-sm-6">
						<label class="mt-4">Tax Total</label>
						<!-- <input class="form-control text-xs" type="text" value="" placeholder="2,335.75" /> -->
						<p class="order-content">
							{{ header.taxTotal | number : '1.2-2' }}
						</p>
					</div>
					<!--         <div class="form-group col-12 col-sm-6">
                <label>Status</label>
                <p class="order-content2">{{header.status}}</p>
            </div> -->
					<div class="form-group col-12 col-sm-6">
						<label class="mt-4">Order Date</label>
						<!-- <input class="form-control text-xs" type="text" value="" placeholder="139.46" /> -->
						<p class="order-content2">{{ header.orderDate | date }}</p>
					</div>

					<div class="form-group col-12 col-sm-6">
						<label class="mt-4">Total</label>
						<!-- <input class="form-control text-xs" type="text" value="" placeholder="2,335.75" /> -->
						<p class="order-content">{{ header.total | number : '1.2-2' }}</p>
					</div>
					<div class="form-group col-12 col-sm-12">
						<label class="mt-4">Description</label>
						<!-- <input class="form-control" type="text" value="" placeholder="219329" /> -->
						<!-- <textarea name="" class="form-control text-xs" placeholder="1YR Cisco Renewable (MS120 - 8FP/s#W2cx-wum3-u7ID)(ms120-48/s#Q2FX-J75Q-WS5J)(MX64W/s#Q2MN-6L58-unkp)(mr46/S#Q3AC-J8LN-SQSM,Q3AC-W54K-WTRF,Q3AC-X574-EQMU,Q2PD-9MRX-TV9Z)  Qoute:#028487 version 1"></textarea> -->
						<p class="order-content1">{{ header.description }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="order-details-badge-area">
				<!-- <app-badge
          [label]="header.status"
          [type]="
            header.color
          "
          [size]="'xxl'"
        ></app-badge> -->
				<span class="order-details-badge" [style]="badgeType(header.color)"
					>Closed</span
				>
			</div>
		</div>
	</div>
</ng-template>

<!--product Table-->
<ng-template #productTbl>
	<div class="table-responsive directory-table">
		<div
			class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
		>
			<div class="dataTable-container">
				<app-column-slider
					[(displayedColumns)]="displayedColumns"
				></app-column-slider>
				<table
					class="table table-flush dataTable-table"
					mat-table
					[dataSource]="dataSource"
					matSort
					matSortActive="id"
					matSortDirection="asc"
					matSortDisableClear="true"
					(matSortChange)="sortData($event, 'product')"
				>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">{{ row.id }}</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="identifier">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Product ID
						</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.identifier }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="description">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Description
						</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.description }}
								</p>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
						<td mat-cell *matCellDef="let row">
							<!-- <div class="d-flex">
                <p class="text-xs text-secondary mb-0">{{row.status}}</p>
              </div> -->
							<div class="text-xs font-weight-normal">
								<app-badge
									[label]="row.status"
									[type]="row.color"
									[fontColor]="row.fontColor"
								></app-badge>
								<!-- <span>&nbsp;{{ row.status }}</span> -->
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="quantity">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.quantity }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="unitPrice">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Unit Price
						</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.unitPrice | currency }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="total">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.total | currency }}
								</p>
							</div>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

					<!-- Row shown when there is no matching data. -->
					<tr class="mat-row" *matNoDataRow>
						<td class="mat-cell" colspan="4">
							{{ tablemessage.noRecord }}
						</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="table-paginator-container" [hidden]="!hasData()">
			<div class="paginator-group">
				<table-paginator
					#productPaginator
					[totalItems]="totalItems"
					[pageSizes]="pageSizes"
					(pageChange)="fetchNewData('product', 'page', $event)"
				></table-paginator>
			</div>

      <div class="item-per-page-group">
        <app-item-per-page
          (sizeChange)="fetchNewData('product', 'size', $event)"
          [totalItems]="totalItems"
        ></app-item-per-page>
      </div>
    </div>
  </div>
</ng-template>

<!--invoice Table-->
<ng-template #invoiceTbl>
	<div class="table-responsive directory-table">
		<div
			class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"
		>
			<div class="dataTable-container">
				<app-column-slider
					[(displayedColumns)]="displayedColumns"
				></app-column-slider>
				<table
					class="table table-flush dataTable-table"
					mat-table
					[dataSource]="dataSourceInvoice"
					matSort
					matSortActive="invoiceNumber"
					matSortDirection="asc"
					matSortDisableClear="true"
					(matSortChange)="sortData($event, 'invoice')"
				>
					<ng-container matColumnDef="invoiceNumber">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Invoice Number
						</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.invoiceNumber }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="billAmount">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							billAmount
						</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.billAmount | currency }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="balance">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.balance | currency }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="invoiceDate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Invoice Date
						</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.invoiceDate | customDate }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="dueDate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<div class="d-flex">
								<p class="text-xs text-secondary mb-0">
									{{ row.dueDate | customDate }}
								</p>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
						<td class="dark-color" mat-cell *matCellDef="let row">
							<app-badge
								[label]="row.status"
								[type]="row.color"
								[fontColor]="row.fontColor"
							></app-badge>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumnsInvoice"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedColumnsInvoice"
					></tr>

					<!-- Row shown when there is no matching data. -->
					<tr class="mat-row" *matNoDataRow>
						<td class="mat-cell" colspan="4">
							{{ tablemessage.noRecord }}
						</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="table-paginator-container" [hidden]="!hasDataInvoice()">
			<div class="paginator-group">
				<table-paginator
					#invoicePaginator
					[totalItems]="totalItemsInvoice"
					[pageSizes]="pageSizesInvoice"
					(pageChange)="fetchNewData('invoice', 'page', $event)"
				></table-paginator>
			</div>

      <div class="item-per-page-group">
        <app-item-per-page
          (sizeChange)="fetchNewData('invoice', 'size', $event)"
          [totalItems]="totalItemsInvoice"
        ></app-item-per-page>
      </div>
    </div>
  </div>
</ng-template>
