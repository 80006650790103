/* Angular Libraries */ 
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { isValueEmpty } from '@app/shared/utilities/helper';

/* Third Party Libraries */
import { finalize, Subject, takeUntil, tap } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { FinanceService } from '../../finance.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { InvoiceService } from '../invoices/store/invoice.service';

/* Components */
import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

/* Interfaces */
import { ICredits, TransactionType } from '@app/shared/interfaces/invoice.interface';
import { NotificationMessages } from '@app/shared/constants/global-constants';

@Component({
  selector: 'credits-table',
  templateUrl: './credits-table.component.html',
  styleUrls: ['./credits-table.component.scss']
})

export class CreditsTableComponent implements OnInit, OnDestroy {
  /* ViewChild */
  @ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;
  @ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
  @ViewChild('searchTextbox') searchTextbox: ElementRef;

  /* Input/Output */
  @Input() availableCredits: number = 0;
  @Input() unappliedPayments: number = 0;

  /* Properties */
  queryString: FormControl = new FormControl<string>('');
  selectedSortColumn: string = 'invoiceDate';
  selectedSortType: string = 'desc';
  selectedPageSize: number = 10;
  selectedPage: number = 1;
  searchTimeout: any;
  transactionOption: TransactionType[] = [];
  isFilterHaveBeenCalled: boolean = false;

  /* [Start]::Filter Forms */
  startDateFilter: FormControl<string | null> = new FormControl<string | null>('');
  endDateFilter: FormControl<string | null> = new FormControl<string | null>('');
  transactionTypeFilter: FormControl<string | null> = new FormControl<string | null>('');
  searchFilters: ICredits;
  /* [End]::Filter Forms */

  private _$unsubscribe: Subject<void> = new Subject<void>();

  /* Constructor */
  constructor(
    private _financeService: FinanceService,
    private _spinner: SpinnerService,
    private _toastMessageService: ToastMessageService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _invoiceService: InvoiceService
  ) { }

  get isFilterOn(): boolean {
		const hasStartDate = !isValueEmpty(this.startDateFilter.value) && ![null, 'Invalid date'].includes(this.startDateFilter.value);
		const hasEndDate = !isValueEmpty(this.endDateFilter.value) && ![null, 'Invalid date'].includes(this.endDateFilter.value);
		const hasTransactionType = this.transactionTypeFilter.value === null || this.transactionTypeFilter.value !== '';
		return hasStartDate || hasEndDate || hasTransactionType;
	}

  /* Methods */
  ngOnInit(): void {
    setTimeout(() => {
      this._initTransactionOption();
      this._initFilterValueChanges();
      this._eventListeners();
    }, 0);
  }

  queryChange(delayTime: number = 0) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
      this.isFilterHaveBeenCalled = true;
      this.fetchNewData();
    }, delayTime);
	}

  getPrintInvoice(_credit: any, _type: 'download' | 'viewPdf' = 'download') {
		this._spinner.start();
		this._financeService
			.downloadInvoice2(_credit.id)
			.pipe(finalize(() => setTimeout(() => this._spinner.stop(), 500)))
			.subscribe({
				next: async (data: any) => {
					if (_type === 'download') {
            this.#downloadPdf(data, _credit.id);
          } else {
            this.#viewPdf(data, _credit.id);
          }
				},
				error: () => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
			});
	}

  fetchNewData() {
		const filters: ICredits = this._getFilters();
		this.searchFilters = filters;
	}

  #downloadPdf(data: any, creditId: number) {
		const link = document.createElement('a');
		link.href = data;
		link.setAttribute('download', `Credit Memo ${creditId}.pdf`);
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
	}

  #viewPdf(data: any, creditId: number) {
		this._financeService.pdf$.next(data);
		this._router.navigate(['view-pdf', creditId], { queryParams: { isInvoiceType: false }, relativeTo: this._route });
	}

  private _initFilterValueChanges() {
    this.fetchNewData();
    this._valueChanges(this.startDateFilter);
    this._valueChanges(this.endDateFilter);
    this._valueChanges(this.transactionTypeFilter);

    setTimeout(() => {
      this.clearFilter.clearAll
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next:() => {
          this.isFilterHaveBeenCalled = true;
          this.startDateFilter.setValue('');
          this.endDateFilter.setValue('');
          this.transactionTypeFilter.setValue('');
        }
      });
    }, 100);
  }

  private _valueChanges(prop: FormControl) {
    prop.valueChanges
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({ next: () => {
      this.isFilterHaveBeenCalled = true;
      this.fetchNewData();
    } });
  }

  private _initTransactionOption() {
    this._financeService.getTransactionTypesList()
      .pipe(takeUntil(this._$unsubscribe))
			.subscribe({ next: (result: TransactionType[]) =>  {
        if (Array.isArray(result) && result.length) {
          this.transactionOption = result.filter(x => x.name !== 'Invoice');
        }
      }});
  }

  private _getFilters(): ICredits {
		return {
			page: this.newTable.paginator?.page ? this.newTable.paginator?.page : this.selectedPage,
      pageSize: this.newTable.itemsPerPage?.pageSize ? this.newTable.itemsPerPage?.pageSize : this.selectedPageSize,
      column: this.selectedSortColumn,
      order: this.selectedSortType,
      invoiceStartDate: ![null, 'Invalid date'].includes(this.startDateFilter.value) ? this.startDateFilter.value!.trim() : '',
      invoiceEndDate: ![null, 'Invalid date'].includes(this.endDateFilter.value) ? this.endDateFilter.value!.trim() : '',
      transactionType: ![null, ''].includes(this.transactionTypeFilter.value) ? this.transactionTypeFilter.value!.trim() : '',
      searchText: this.queryString.value!.trim(),
      companyId: this._financeService.companyIdType
		};
	}

	private _eventListeners() {
		this._invoiceService.isLoading$
			.pipe(
				tap((isLoading) => {
					if (isLoading) {
						this.queryString.disable();
					} else {
						this.queryString.enable();
						(this.searchTextbox as any).focus();
					}
				})
			).subscribe()
	}

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
