import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormBuilder,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, of, startWith, Subscription, tap } from 'rxjs';
import Picker from 'vanilla-picker';

import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';

import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { SpinnerService } from '@services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { KnowledgeBaseService } from '../../knowledge-base.service';
import { FigmaDemoService } from '@app/figma-demo/figma-demo.service';

import { HistoryUrl, Permission } from '@app/shared/constants';

import { KBCategory } from '@app/shared/interfaces/knowledge-base.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { IFigmaIcon } from '@app/shared/interfaces/generic.interface';

@Component({
	selector: 'app-category-add-edit',
	templateUrl: './category-add-edit.component.html',
	styleUrls: ['./category-add-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KBCategoryAddEditComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit
{
	@ViewChild('colorPicker', { static: false }) colorPicker!: ElementRef;

	iconControl = new FormControl('', Validators.required);
	iconPlaceholder = '';
	icons: string[] = [];
	filteredIcons: string[];
	searchSubscription: Subscription;
	subscriptions: Subscription;
	category: KBCategory;
	hasSubmitted: boolean = false;
	stateAction = 'add';
	errorCategory = '';
	user: User = this._userService.user!;
	isAuditTrailEnabled: boolean;
	specialCharError = '';

	isTouched = false;

	form = new FormGroup({
		id: new FormControl('-1'),
		name: new FormControl('', [
			Validators.required,
			Validators.pattern(/^[a-zA-Z0-9_ ]*$/),
			Validators.maxLength(50),
		]),
		iconName: new FormControl('', Validators.required),
		userId: new FormControl(0),
		companyId: new FormControl(0),
		color: new FormControl('#000000'),
	});

	id: number;
	companyId: string | null;
	historyUrl = HistoryUrl;

	figma: IFigmaIcon[] = [];
	figmaIconNames: string[] = [];
	filteredOptions: Observable<string[]>;
	selectedFigmaIconId = '';
	selectedIconSrc: SafeResourceUrl;

	constructor(
		public spinner: SpinnerService,
		public override _route: ActivatedRoute,
		public override _companiesService: CompaniesService,
		private _router: Router,
		private _fb: FormBuilder,
		private _cd: ChangeDetectorRef,
		private _sanitizer: DomSanitizer,
		private _location: Location,
		private _breadcrumbService: BreadcrumbService,
		private _kbService: KnowledgeBaseService,
		private _figmaService: FigmaDemoService,
		private _userService: UserService
	) {
		super(_companiesService, _route);

		this.figma = this._figmaService.getFigmaValue('newFigma');
		this.figmaIconNames = this.figma.map((figma: IFigmaIcon) => figma.name);
		this.id = Number(this._route.snapshot.paramMap.get('id'));

		if (this._route.snapshot.paramMap.get('companyId')) {
			if (this._userService.user?.roleId === 1) {
				this.companyId = this._route?.snapshot?.paramMap.get('companyId');
			}
		}
	}

	ngOnInit() {
		this.getCompanyIdFromParams();
		this._breadcrumbService.getCompanyByIdAndUpdateBreadcrumb(
			this._companiesService.subCompanyId!
		);
		this.isAuditTrailEnabled =
			this._userService.hasPermission(
				Permission.CompanyAdminAuditTrailKnowledgeBase
			) || this._userService.isSpAdmin;
	}

	ngAfterViewInit() {
		this.filteredOptions = this.iconControl.valueChanges.pipe(
			startWith('a'),
			map((value) => this._filterIcons(value || '')),
			tap(() => {
				this._cd.detectChanges();
			})
		);

		if (this.id) {
			//Check if category to be edited has been sent using router state
			this.stateAction = 'edit';
			setTimeout(async () => {
				await new Promise((resolve) => {
					this._kbService.getCategoryById(this.id).subscribe((data: any) => {
						this._breadcrumbService.updateBreadCrumbsText(
							'_kbCategoryName',
							data.name
						);
						this.form = this._fb.group({
							id: [data.id],
							name: [
								data.name,
								[
									Validators.required,
									Validators.pattern(/^[a-zA-Z0-9_ ]*$/),
									Validators.maxLength(50),
								],
							],
							iconName: [data.iconName, Validators.required],
							userId: [this._userService.user!.id],
							companyId: [
								this.user.roleId == 2 ? this.user.companyId : data.companyId,
							],
							color: [data.color],
						});

						this.selectedIconSrc = data.iconUrl;

						this.form.get('iconName')?.valueChanges.subscribe((res) => {
							this.iconControl.setValue(res);
							this._cd.detectChanges();
						});

						this._cd.detectChanges();
						resolve(true);
					});
				});
			});
		} else {
			this.stateAction = 'add';
			this.iconPlaceholder = 'Select Icon';
			this.form.patchValue({
				userId: this._userService.user!.id,
			});
		}

		const picker = new Picker(this.colorPicker.nativeElement);

		picker.setOptions({
			popup: 'bottom',
			alpha: false,
			color: this.form.get('color')?.value || '#000000',
		});
		picker.onChange = (color) => {
			this.form
				.get('color')
				?.setValue(color.hex.substring(0, color.hex.length - 2));
			this._cd.detectChanges();
		};

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}

	openColorPicker() {
		this.colorPicker.nativeElement.click();
	}

	isAlphanumeric(value: string): boolean {
		let isAlphanumeric = true;

		if (value.match(/^[0-9A-Za-z\s]+$/) === null) {
			// is not alphanumeric
			isAlphanumeric = false;
		}

		return isAlphanumeric;
	}

	getSvgSrc(name: string): SafeResourceUrl {
		const icon = this.figma.filter((e) => e.name === name);
		let url = this._sanitizer.bypassSecurityTrustResourceUrl('');

		if (icon.length > 0) {
			url = this._sanitizer.bypassSecurityTrustResourceUrl(icon[0].iconUrl);
		}

		return url;
	}

	submit() {
		this.hasSubmitted = true;

		if (
			this.form.invalid ||
			!this.isAlphanumeric(this.form.get('name')?.value!) ||
			this.form.get('name')?.value?.length! > 100
		) {
			this.form.markAllAsTouched();

			if (this.form.get('name')?.value?.trim().length === 0) {
				this.errorCategory = 'Category Name is required';
			} else if (this.form.get('name')?.value?.length! > 100) {
				this.errorCategory = 'Maximum of 100 characters only';
			} else if (this.isAlphanumeric(this.form.get('name')?.value!) === false) {
				this.errorCategory =
					'Category name only allows alphanumeric characters';
			}
			return;
		}

		this.spinner.start();

		const payload: any = this.form.getRawValue();
		payload.companyId = this.companyId ? +this.companyId : payload.companyId;
		payload.figmaIconId = this.selectedFigmaIconId;
		payload.userRole = this._userService.userRole;

		if (this.stateAction === 'add') {
			this.specialCharError = '';
			this.form
				.get('color')
				?.setValue(this.form.get('color')?.value?.substring(0, 7)!);

			this._kbService.addCategory(payload).subscribe({
				next: () => {
					this._resetKB(payload);
					this.spinner.stop();
					this._router.navigate(['../'], { relativeTo: this._route });
				},
				error: (error) => {
					this.spinner.stop();
					this._showErrorBadge(error);
				},
			});
		} else {
			this._kbService.updateCategory(payload).subscribe({
				next: () => {
					this._resetKB(payload);
					this.spinner.stop(),
						this._router.navigate(['../../'], { relativeTo: this._route });
				},
				error: (error) => {
					this.spinner.stop();
					this._showErrorBadge(error);
				},
			});
		}
	}

	onFocusOut() {
		if (!this.iconControl.value && this.form.get('name')?.value) {
			const icon = this.form.get('name')!.value!.trim();
			const filteredIcons = this._filterIcons(icon);
			this.filteredOptions =
				filteredIcons.length === 0
					? of(this._filterIcons(''))
					: of(filteredIcons);
		}
	}

	formValidate(error: HttpErrorResponse) {
		this.form.get('name')?.setErrors({ duplicateName: true });
		this.errorCategory = error.error?.Name?.toString() || error.error;
	}

	resetError() {
		this.errorCategory = '';
		if (this.isAlphanumeric(this.form.get('name')?.value!) === true) {
			this.specialCharError = '';
		}
	}

	goBack() {
		this._location.back();
	}

	selectIcon(e: MatAutocompleteSelectedEvent) {
		const iconName = e.option.value;
		this.selectedIconSrc = this.getSvgSrc(iconName);
		this.iconControl.setValue('');
		this.form.get('iconName')?.setValue(iconName);
	}

	onBlur() {
		this.form.controls['iconName'].markAsTouched();
	}

	private _filterIcons(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.figmaIconNames
			.filter((option) => option.toLowerCase().includes(filterValue))
			.sort();
	}

	private _showErrorBadge(error: any) {
		this.formValidate(error);
		this.spinner.stop();
	}

	private _resetKB(options: any) {
		this._kbService.allowToFetchNewData = true;
		this._kbService.clearKBCategoryData(options);
	}
}
