import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GlobalSettingsPermission, GlobalSettingsType } from '@app/modules/global-settings/page/global-settings/global-setting.constant';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionParamGuard implements CanActivate {
  constructor(private _userService: UserService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const ids = route.data['id'];
      const param = route.data['param'];

      let paramData: GlobalSettingsPermission = route.paramMap.get(param) as unknown as GlobalSettingsPermission;

      if(param === 'module'){
        let permissionPageId = GlobalSettingsPermission[paramData] as unknown as number
       return this.validatePermission(permissionPageId)
      }
    return false
  }

  validatePermission(permissionPageId: number){
    const permissionIds = this._userService.user!.menuIds;
    if(permissionIds.includes(permissionPageId)){
      return true
    }else{
      return false
    }
  }

}
