import { GlobalKBAddEditComponent } from './../base-knowledge-base/global-kb/global-kb-add-edit.component';
import { GlobalKnowledgeBaseComponent } from './../base-knowledge-base/global-kb/global-kb.component';
import { KbTopicTabComponent } from './../../modules/knowledge-base/pages/kb-topic-tab/kb-topic-tab.component';
import { Routes } from '@angular/router';
import { SpAdminGuard } from '@app/core/guards/sp-admin.guard';
import { UserTypeGuard } from '@app/core/guards/user-type.guard';
import { CategoryAddEditComponent } from '@app/modules/applications/pages/category-add-edit/category-add-edit.component';
import { CategoryListComponent } from '@app/modules/applications/pages/category-list/category-list.component';
import { BotAddEditComponent } from '@app/modules/knowledge-base/pages/bot-add-edit/bot-add-edit.component';
import { KbTopicListComponent } from '@app/modules/knowledge-base/pages/kb-topic-list/kb-topic-list.component';
//import { UserTypes } from './user-types.constants';
import { KBCategoryAddEditComponent } from '@app/modules/knowledge-base/pages/category-add-edit/category-add-edit.component';
import { KBCategoryListComponent } from '@app/modules/knowledge-base/pages/category-list/category-list.component';
import { Permission } from './global-constants';
import { PermissionGuard } from '@app/core/guards/permission.guard';
import { SearchByTopicComponent } from '@app/modules/company/pages/knowledge-base/search-by-topic/search-by-topic.component';
import { MenuPermissionGuard } from '@app/core/guards/menu-permission.guard';
import { GlobalApplicationsComponent } from '../base-applications/global-applications/global-applications.component';
import { GlobalAddEditApplicationsComponent } from '../base-applications/global-applications/global-add-edit-application.component';
import { BaseApplicationCatalogComponent } from '../base-application-catalog/base-application-catalog.component';
import { AppCatalogAddEditComponent } from '@app/modules/applications/pages/application-catalog/app-catalog-add-edit/app-catalog-add-edit.component';
import { UserTypes } from './global-enum';
import { MenuPermissionConstant } from './menu-permission.constant';

export const ApplicationRoute: Routes = [
  {
    path: 'application-catalog',
    data: {
      title: 'Application Catalog',
      userTypes: [UserTypes.SourcepassAdmin],
    },
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    children: [
      {
        path: '',
        component: BaseApplicationCatalogComponent,
      },
      {
        path: 'add',
        component: AppCatalogAddEditComponent,
        data: {
          title: 'Create Application Catalog',
        },
      },
      {
        path: 'edit/:id',
        component: AppCatalogAddEditComponent,
        data: {
          title: '_applicationNameCatalog',
          // userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
        },
      },
    ],
  },
  {
    path: 'categories',
    data: {
      title: 'Manage Categories - Applications',
      userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.Application.subMenus!['categories'],
    },
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    children: [
      {
        path: '',
        component: CategoryListComponent,
        data: {
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard],
      },
      {
        path: 'add',
        component: CategoryAddEditComponent,
        data: {
          title: 'Create Category',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [
            Permission.SpAdminApplicationCategoryAddEdit,
            Permission.CompanyAdminApplicationCategoryAddEdit,
          ],
        },
        canActivate: [UserTypeGuard, PermissionGuard],
      },
      {
        path: 'edit/:id',
        component: CategoryAddEditComponent,
        data: {
          title: '_applicationCategoryName',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [
            Permission.SpAdminApplicationCategoryAddEdit,
            Permission.CompanyAdminApplicationCategoryAddEdit,
          ],
        },
        canActivate: [UserTypeGuard, PermissionGuard],
      },
    ],
  },
  {
    path: 'applications',
    component: GlobalApplicationsComponent,
    data: {
      title: 'Applications',
      userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.Application.subMenus!['applications'],
    },
    canActivate: [UserTypeGuard],
  },
  {
    path: 'applications',
    data: {
      title: 'Applications',
      userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.Application.subMenus!['applications'],
    },
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    children: [
      {
        path: 'add',
        component: GlobalAddEditApplicationsComponent,
        data: {
          title: 'Add Application',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [
            Permission.SpAdminApplicationAddEdit,
            Permission.CompanyAdminApplicationAddEdit,
          ],
        },
        canActivate: [UserTypeGuard, /* PermissionGuard */],
      },
      {
        path: 'edit/:id',
        component: GlobalAddEditApplicationsComponent,
        data: {
          title: '_applicationName',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [
            Permission.SpAdminApplicationAddEdit,
            Permission.CompanyAdminApplicationAddEdit,
          ],
        },
        canActivate: [UserTypeGuard, /* PermissionGuard */],
      },
      {
        path: 'application-catalog',
        component: BaseApplicationCatalogComponent,
        data: {
          title: 'Manage Catalog List',
          userTypes: [UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard],
      },
      {
        path: 'application-catalog',
        data: {
          title: 'Manage Catalog List',
          userTypes: [UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard],
        children: [
          {
            path: 'add',
            component: AppCatalogAddEditComponent,
            data: {
              title: 'Create Application Catalog',
            },
          },
          {
            path: 'edit/:id',
            component: AppCatalogAddEditComponent,
            data: {
              title: '_applicationNameCatalog',
              // userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
            },
          },
        ],
      },
    ],
  },
];

export const KnowledgeBaseRoute: Routes = [
  // For client admin
  {
    path: 'knowledge-base',
    data: {
      title: 'Knowledge Base',
      userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
    },
    canActivate: [UserTypeGuard],
    children: [
      {
        path: 'categories',
        data: {
          title: 'Categories',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          id: MenuPermissionConstant.KnowledgeBase.subMenus!['categories'],
        },
        canActivate: [UserTypeGuard, MenuPermissionGuard],
        children: [
          {
            path: '',
            component: KBCategoryListComponent,
            data: {
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
            },
            canActivate: [UserTypeGuard],
          },
          {
            path: 'add',
            component: KBCategoryAddEditComponent,
            data: {
              title: 'Add Category',
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
              permissions: [
                Permission.SpAdminKbCategoryAddEdit,
                Permission.CompanyAdminKbCategoryAddEdit,
              ],
            },
            canActivate: [UserTypeGuard, PermissionGuard],
          },
          {
            path: 'edit/:id',
            component: KBCategoryAddEditComponent,
            data: {
              title: 'Edit Category',
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
              permissions: [
                Permission.SpAdminKbCategoryAddEdit,
                Permission.CompanyAdminKbCategoryAddEdit,
              ],
            },
            canActivate: [UserTypeGuard, PermissionGuard],
          },
        ],
      },
      {
        path: 'topics',
        data: {
          title: 'Topics',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          id: MenuPermissionConstant.KnowledgeBase.subMenus!['topics'],
        }, // UPDATE: Changed title from Topics to Categories for breadcrumbs changes
        canActivate: [UserTypeGuard, MenuPermissionGuard],
        children: [
          {
            path: '',
            component: KbTopicListComponent,
            data: {
              title: 'Topics',
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
            },
            canActivate: [UserTypeGuard],
          },
          {
            path: 'add',
            component: KbTopicTabComponent,
            data: {
              title: 'Create New Topic',
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
              permissions: [
                Permission.SpAdminKbTopicAddEdit,
                Permission.CompanyAdminKbTopicAddEdit,
              ],
            },
            canActivate: [UserTypeGuard, PermissionGuard],
          },
          {
            path: 'edit',
            component: KbTopicTabComponent,
            data: {
              title: 'Update Topic',
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
              permissions: [
                Permission.SpAdminKbTopicAddEdit,
                Permission.CompanyAdminKbTopicAddEdit,
              ],
            },
            canActivate: [UserTypeGuard, PermissionGuard],
          },
          {
            path: 'topic',
            component: SearchByTopicComponent,
            canActivate: [UserTypeGuard],
            data: {
              userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
            },
          },
        ],
      },
    ],
  },

  // For SP Admin
  {
    path: 'topics',
    data: {
      title: 'Knowledge Base',
      userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.KnowledgeBase.subMenus!['topics'],
    }, // UPDATE: Changed title from Topics to Categories for breadcrumbs changes
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    children: [
      {
        path: '',
        component: GlobalKnowledgeBaseComponent,
        data: {
          title: 'Knowledge Base',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard],
      },
      {
        path: 'add',
        component: GlobalKBAddEditComponent,
        data: {
          title: 'Create Topic',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [Permission.SpAdminKbTopicAddEdit, Permission.CompanyAdminKbTopicAddEdit],
        },
        canActivate: [UserTypeGuard, PermissionGuard],
      },
      {
        path: 'edit',
        component: GlobalKBAddEditComponent,
        data: {
          title: '_kbTopicName',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [Permission.SpAdminKbTopicAddEdit, Permission.CompanyAdminKbTopicAddEdit],
        },
        canActivate: [UserTypeGuard, PermissionGuard],
      },
      {
        path: 'topic',
        component: SearchByTopicComponent,
        canActivate: [UserTypeGuard],
        data: {
          title: '_kbTopicName',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
        },
      },
    ],
  },
  {
    path: 'categories',
    data: {
      title: 'Manage Categories - Knowledge Base',
      userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
      id: MenuPermissionConstant.KnowledgeBase.subMenus!['categories'],
    },
    canActivate: [UserTypeGuard, MenuPermissionGuard],
    children: [
      {
        path: '',
        component: KBCategoryListComponent,
        data: { userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin] },
        canActivate: [UserTypeGuard],
      },
      {
        path: 'add',
        component: KBCategoryAddEditComponent,
        data: {
          title: 'Create Category',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [
            Permission.SpAdminKbCategoryAddEdit,
            Permission.CompanyAdminKbCategoryAddEdit,
          ],
        },
        canActivate: [UserTypeGuard, PermissionGuard],
      },
      {
        path: 'edit/:id',
        component: KBCategoryAddEditComponent,
        data: {
          title: '_kbCategoryName',
          userTypes: [UserTypes.ClientAdmin, UserTypes.SourcepassAdmin],
          permissions: [
            Permission.SpAdminKbCategoryAddEdit,
            Permission.CompanyAdminKbCategoryAddEdit,
          ],
        },
        canActivate: [UserTypeGuard, PermissionGuard],
      },
    ],
  },
  {
    path: 'chatbot',
    component: BotAddEditComponent,
    canActivate: [SpAdminGuard, MenuPermissionGuard],
    data: {
      title: 'Chat Bot',
      id: MenuPermissionConstant.KnowledgeBase.subMenus!['chatbot'],
    },
  },
];

export const CommunicationsRoute: Routes = [
  {
    path: 'messages',
    loadChildren: () => import('./../../modules/message/message.module').then((m) => m.MessageModule),
    data: {
      title: 'Messages',
      id: MenuPermissionConstant.Messages,
      userTypes: [UserTypes.SourcepassAdmin, UserTypes.ClientAdmin],
      hasUrl: true,
    },
    canActivate: [UserTypeGuard, MenuPermissionGuard],
  },
  {
    path: 'notification-center',
    loadChildren: () => import('./../../modules/notification-center/notification-center.module').then((m) => m.NotificationCenterModule),
    data: {
      title: 'Notification Center',
      id: MenuPermissionConstant.NotificationCenter,
      userTypes: [UserTypes.SourcepassAdmin],
      hasUrl: true,
    },
    canActivate: [UserTypeGuard, MenuPermissionGuard],
  },
  {
    path: 'email-template-styles',
    loadChildren: () => import('./../../modules/email-template-styles/email-template-styles.module').then((m) => m.EmailTemplateStylesModule),
    data: {
      title: 'Template Styles',
    },
  },
  {
    path: '',
    loadChildren: () => import('./../../modules/email-template-builder/email-template.module').then((m) => m.EmailTemplateModule),
    canActivate: [SpAdminGuard],
  },
];
