import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationModalConfig } from './models/confirmation-modal-config.interface';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, OnChanges {
  @Input() customConfig: ConfirmationModalConfig
  @Input() show = false;
  @Input() type = 'delete'; // delete
  @Input() title = '';
  @Input() header: string | null;
  @Input() message: string | null;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<string> = new EventEmitter<string>();

  config: ConfirmationModalConfig;

  constructor() { }

  ngOnInit(): void {
    this.setConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { show } = changes;

    if(show)
      if(show.currentValue !== show.previousValue) {
        if(show.currentValue) {
          this.setConfig();
        }
      }
  }

  setConfig(): void {
    if(!this.customConfig) {
      let config: ConfirmationModalConfig | null = null;
      const title = `${this.title ? ` the "${this.title.trim()}"` : ''}`;

      switch (this.type) {
        case 'delete':
          config = {
            iconName: '',
            iconColor: 'red',
            header: 'Confirm Delete',
            message: `Are you sure you want to delete${title}? This cannot be undone.`,
            cancel: 'No',
            accept: 'Yes'
          };
          break;
        case 'archive':
          config = {
            iconName: '',
            iconColor: 'red',
            header: 'Confirm Archive',
            message: `Are you sure you want to archive${title}? This cannot be undone.`,
            cancel: 'No',
            accept: 'Yes'
          };
          break;
        case 'update':
          config = {
            iconName: '',
            iconColor: '#00baf2',
            header: 'Confirm Update',
            message: `Are you sure you want to update${title}?`,
            cancel: 'No',
            accept: 'Yes'
          };
          break;

        default:
          break;
      }

      if(config) {
        this.config = {
          ...config,
          header: this.header && this.header !== '' ? this.header : config.header,
          message: this.message && this.message !== '' ? this.message : config.message,
        };
      }
    } else {
      this.config = this.customConfig;
    }
  }

}
