import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';

import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { UserService } from '../services/user.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private _userService: UserService,
		private _router: Router,
		private _sessionStorageService: SessionStorageService,
		private _maintenance: MaintenanceService,
		private _navigationService: NavigationService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		if (localStorage.getItem('first-login')) {
			if (route?.routeConfig?.path !== 'user-details') {
				this._router.navigate(['/user-details']);
			}

			return true;
		}

		const searchText = this._sessionStorageService.getStorage('global-search');

		if (searchText) {
			this._sessionStorageService.removeStorageItem('global-search');
		}

		if (this._userService.isAuthenticated) {
			return this._checkMaintenance();
		} else if (localStorage.getItem('accessToken')) {
			return this._userService.reconnect().pipe(
				switchMap((res) => {
					return this._checkMaintenance();
				}),
				catchError((error) => {
					this._navigationService.updateStorageAndNavigate(state.url);
					return of(false);
				})
			);
		} else {
			if (!state.url.includes('page-forbidden'))
				this._navigationService.updateStorageAndNavigate(state.url);
			return false;
		}
	}

	private _checkMaintenance() {
		return this._maintenance.isAppInMaintenance().pipe(
			map((res) => {
				if (res) this._navigationService.navigateToMaintenance();
				return !res;
			})
		);
	}
}
