import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessagesService } from '@app/modules/message/messages.service';
import { UserMessageCategoryEnum } from '@app/shared/constants';
import { BannerMessage } from '@app/shared/interfaces/banner-message.interface';
import { BannerMessageService } from '@app/shared/services/banner-message.service';
import { NotificationsClienthubService } from '@app/shared/services/notifications-clienthub.service';
import { startsWithHttp } from '@app/shared/utilities/helper';

@Component({
  selector: 'banner-message-widget',
  templateUrl: './banner-message-widget.component.html',
  styleUrls: ['./banner-message-widget.component.scss']
})
export class BannerMessageWidgetComponent implements OnInit {

  messages? : BannerMessage[];
  show_more = false;

  constructor(private _messages: BannerMessageService,
              private notificationHub: NotificationsClienthubService,
              private _router: Router) { }

  ngOnInit(): void {

    this.refresh();

    this.notificationHub.bannerNotificationReceived.subscribe(s=>{

      this.refresh();

    })
  }

  viewFullMessage(m:BannerMessage){

    this._router.navigate(['/client-message/banner', m.id], { queryParams: { categoryId: UserMessageCategoryEnum.Banner}});


  }

  refresh(){

    this._messages.getBannerMessages().subscribe({
      next: s=> {
        this.messages = s.map(o=>{
          o._websiteLinkModified = (o.websiteLink && !startsWithHttp(o.websiteLink)) ? `//${o.websiteLink}` : o.websiteLink;
          return o;
        });
      },
      error: e=> { }
    })

  }

  getTopMessage(){

    if(this.messages)
      return this.messages.slice(0,5);
    return null;

  }

  getMoreMessage(){

    if(this.messages)
      return this.messages.slice(5);
    return null;

  }

  cancel(i:BannerMessage){

    this._messages.closeBannerMessage(i.id).subscribe({
      next: s=>{
        this.messages?.splice( this.messages.findIndex(o=>o == i),1);
      }
    })

  }

}


