<div class="main-contents mb-0">
  <div class="main-search-wrap">
    <div class="row m-0">
      <div class="col-md-3 p-0 my-2">
        <h3>Manage Global {{ headerName }}</h3>
      </div>
      <div class="col-md-9 p-0">
        <div class="main-searchbar"></div>
      </div>
    </div>
  </div>

  <div class="cus-wrap edit-agree">
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="row mt-4">
        <div class="col-md-3">
          <div class="cus-field text-right">
            <label for="company">{{ labelName }}</label>
          </div>
        </div>
        <div class="col-md-7 give-scroll">
          <div class="cus-field cat-select">
            <multi-select-input
              [options]="genericOption"
              [optionKeys]="{ value: 'id', label: 'name' }"
              class="form-control"
              formControlName="data"
              errorTooltip
              [etControl]="form.get('data')!"
              [etIsTouched]="form.get('data')!.touched"
              [etShow]="form.get('data')!.touched && !!form.get('data')!.errors"
            >
            </multi-select-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="editappform-btns">
            <ul class="list-unstyled d-flex d-inline-flex m-0">
              <li>
                <button type="button" routerLink="../" class="btn black-btn">
                  CANCEL
                </button>
              </li>
              <li>
                <button type="submit" class="btn blue-btn">SAVE</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
