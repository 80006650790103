/* Angular Libraries */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

/* Third Party Libraries */
import { Observable, Observer, of, Subject, takeUntil } from 'rxjs';

/* Services */
import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { FinanceService } from '../../finance.service';

/* Functions */
import { ThreeDSecureModalComponent } from '../three-d-secure-modal/three-d-secure-modal.component';
import { ComponentCanDeactivate } from './incomplete-three-d-secure-verification/incomplete-three-d-secure-verification.guard';

/* Interfaces */
import { NotificationMessages } from '@app/shared/constants';
import { MessageStatus, PaymentNote, TransactionStatus } from '@app/shared/constants/transaction-type.enum';
import { PaymentBreakdown, PaymentIntentStatus } from '@app/shared/interfaces/invoice.interface';

@Component({
  selector: 'app-payment-security',
  templateUrl: './payment-security.component.html',
  styleUrls: ['./payment-security.component.scss']
})
export class PaymentSecurityComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  /* ViewChild */
  @ViewChild(ThreeDSecureModalComponent) threeDSecureModalComponent: ThreeDSecureModalComponent;

  /* Public Properties */
  invoiceList: Array<PaymentBreakdown> = [];
  receiptPrintId: number;
  hasSomeVerifiedTransaction: boolean = false;
  hasRequiresActions: boolean = false;
  isCardType: boolean = true;
  isAllTransactionSuccess: boolean = false;
  isAllTransactionCanceled: boolean = false;
  transactionStatus = TransactionStatus;
  paymentNote = PaymentNote;
  messageStatus = MessageStatus;
  hasPaymentMethod: boolean = false;
  isAllTransactionFailed: boolean = false;

  isProcessing = false;

  /* Private Properties */
  private _$unsubscribe: Subject<void> = new Subject<void>();

  get isAdmin() {
    return this._stripeService.isAdmin;
  }

  /* Constructor */
  constructor(
    private _dataModal: DataModalService,
    private _stripeService: StripeService,
    private _spinner: SpinnerService,
    private _router: Router,
    private _notifier: NotificationService,
    private _toastMessageService: ToastMessageService,
    private _financeService: FinanceService
  ) { }

  /* Methods */
  ngOnInit(): void {
    this._initInvoiceList();
  }

  clickVerify(inv: PaymentBreakdown) {
		this.threeDSecureModalComponent.clickVerify(inv.transactionNumber);
	}

  clickCancelTransaction(inv: PaymentBreakdown) {
    const paymentIntentId = inv.transactionNumber;
      const data = this._dataModal.openConfirmCancelTransaction(paymentIntentId);

      this._dataModal.showModal(data)
      .pipe(takeUntil(this._$unsubscribe))
      .subscribe({
        next: (isRemove: boolean) => {
          if (isRemove) {
            this.closeVerificationModal(paymentIntentId, true);
          }
        },
      });
  }

  closeVerificationModal(paymentIntentId: string, isCancelTransction: boolean = false) {
    this._spinner.start();

    this._stripeService.getPaymentIntentStatus(paymentIntentId)
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (result: PaymentIntentStatus) => {
        const index = this.invoiceList.findIndex(inv => inv.transactionNumber === paymentIntentId);
        this.invoiceList[index].message = result.message;

        if (result.message === MessageStatus.requiresPaymentMethod) {
          this.invoiceList[index].isRequiresAction = result.isRequiresAction;
          this.invoiceList[index].isProcessing = result.isProcessing;
          this.invoiceList[index].isSucceeded = result.isSucceeded;
        }

        if (result.isSucceeded) {
          if (this.invoiceList[index].message === MessageStatus.succeeded) {
            this.invoiceList[index].isProcessing = true;
            this.invoiceList[index].isRequiresAction = false;
          }

        } else {
          if (this.invoiceList[index].message === MessageStatus.canceled) {
            this.invoiceList[index].isRequiresAction = false;
          }
        }

        this._updatedTransaction();

        setTimeout(() => {
          this._initInvoiceList();

          if (isCancelTransction && result.message === MessageStatus.canceled) {
            this._toastMessageService.showErrorMessage('Duplication Action', 'Another user has already canceled this invoice');
          }

          if (isCancelTransction && result.message === MessageStatus.succeeded) {
            this._toastMessageService.showErrorMessage('Duplication Action', 'Another user has already paid this invoice');
          }

          if (isCancelTransction && result.message === MessageStatus.requiresAction) {
            this.cancelTransaction(paymentIntentId);
          }
        }, 0);

        this._spinner.stop();

      }, error: () => {
        this._spinner.stop();
      }
    })
  }

  cancelTransaction(paymentIntentId: string) {
    this._spinner.start();

    /* For cancel transaction */
    this._stripeService.cancelRequiresActionPaymentIntent(paymentIntentId)
    .pipe(takeUntil(this._$unsubscribe))
    .subscribe({
      next: (result: any) => {

        if (Array.isArray(this.invoiceList) && this.invoiceList.length) {
          this.closeVerificationModal(paymentIntentId);
          const message = 'Transaction Canceled';
          this._notifier.notify(message, {duration: 5, panelClass: 'success'});
        }

        this._spinner.stop();
      },
      error: () => {
        this._spinner.stop();
      }
    });
  }

  canDeactivate(): any {
    if (this.hasRequiresActions) {
      return new Observable((observer: Observer<boolean>) => {
        const data = this._dataModal.openConfirmLeavePaymentSecurity();

        this._dataModal.showModal(data)
        .pipe(takeUntil(this._$unsubscribe))
        .subscribe(isRemove => {

          if (isRemove) {
            this._spinner.start();

            /* For cancel all transactions */
            this._stripeService.cancelAllRequiresAction()
            .pipe(takeUntil(this._$unsubscribe))
            .subscribe({
              next: (result: any) => {
                const message = NotificationMessages.success("removed", "Transaction");
                this._notifier.notify(message, {duration: 5, panelClass: 'success'});

                this._spinner.stop();
                observer.next(isRemove);
                observer.complete();

              }, error: () => {
                this._spinner.stop();
              }
            });
          }
        });
      });

    } else {
      return of(true);
    }
  }

  totalAmount(inv: PaymentBreakdown) {
    return inv.amount + inv?.creditMemoTotalAmount + inv?.unappliedPaymentTotalAmount;
  }

  private _initInvoiceList() {
    this.invoiceList = JSON.parse(localStorage.getItem('stripePaymentRecord') as any)?.data;
    this.receiptPrintId = JSON.parse(localStorage.getItem('stripePaymentRecord') as any)?.receiptPrintId;

    /* If there's no available transactions it will redirect back to invoice page. */
    if (!this.invoiceList || !this.invoiceList.length) {
      this._router.navigate([`/${this.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices`]);
    } else {
      this.hasRequiresActions = this.invoiceList.some(inv => inv.message === MessageStatus.requiresAction);
      this.hasSomeVerifiedTransaction = this.invoiceList.some(inv => inv.message === MessageStatus.succeeded);
      this.hasPaymentMethod = this.invoiceList.some(data => data.cardBrand || data.bankName);
      this.isAllTransactionSuccess = this.invoiceList.every(data => data.isSucceeded || data.isProcessing);
      this.isAllTransactionCanceled = this.invoiceList.every(data => data.message === MessageStatus.canceled);
      this.isAllTransactionFailed = this.invoiceList.every(data => !data.isRequiresAction && !data.isProcessing && !data.isSucceeded);

      if (this.hasPaymentMethod) {
        this.isCardType = this.invoiceList.some(data => data.cardBrand);
      }
    }
  }

  private _updatedTransaction() {
    const updatedTransactionList = {
      receiptPrintId: this.receiptPrintId,
      responseData: this.invoiceList
    };

    localStorage.setItem('stripePaymentRecord', JSON.stringify(updatedTransactionList));
  }

  downloadReceipt(transactionId: string | null = null) {
    this.isProcessing = false;

    if (this.receiptPrintId) {
			this._spinner.start();
      let transactionIdList = transactionId ? [transactionId] : this.invoiceList.map(x => x.transactionNumber).map((x: any) => x === null ? x = this.receiptPrintId : x);

			this._financeService.downloadReceiptByTransaction(transactionIdList).subscribe({
				next: (data: any) => {
          const link = document.createElement('a');
          link.href = data;
          link.setAttribute('download', `Invoice Receipt ${this.receiptPrintId}.pdf`);
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          this._spinner.stop();
				},
				error: (error) => {
					this._spinner.stop();
          this.isProcessing = false;
				},
        complete: () => {
          this.isProcessing = false;
        }
			});
		}
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
