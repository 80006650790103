import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})
export class CheckboxListComponent implements OnChanges {

  @Input() filterFromId: number[];
  @Input() options: any[] = [];
  @Input() filterKey: string;
  @Output() sendList: EventEmitter<any> = new EventEmitter<any>();
  @Input() allChecked: boolean;
  filteredOptions: any[] = [];


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['options'] && changes['options'].currentValue != undefined){
      this.filteredOptions = this.options;
     }
     if(changes['filterKey'] && changes['filterKey'].currentValue != undefined){
      this.filteredOptions = this.options.filter(option=> option.name.toLowerCase().includes(this.filterKey.toLowerCase()))
    }
    if(changes['allChecked'] && changes['allChecked'].currentValue != undefined){
     this.selectAll();
    }
  }

  emitValues(){
    if(this.options && this.options.length > 0){
      this.sendList.emit(this.options.filter(option=> option.checked === true));
    }
  }




  selectAll(){
    if(this.allChecked){
    this.filteredOptions.forEach((option)=>{
      option.checked = true;
    })
    }else{
      this.filteredOptions.forEach((option)=>{
        option.checked = false;
      })
    }
    this.emitValues();
  }


  setOptionValue(i: number){
    this.filteredOptions[i].checked = !this.filteredOptions[i].checked;
    this.checkSelectAll();
    this.filterOptions(i);
  }

  filterOptions(i: number){
    this.emitValues();
  }

  checkSelectAll(){
    let counter = 0;
    this.options.forEach(option=>{
      if(option.checked === true){
        counter++;
      }
    })
    if(counter === this.options.length){
      this.allChecked = true;
    }else{
      this.allChecked = false;
    }
  }

}
