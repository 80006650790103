import { Component, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { MultiSelectInputComponent } from '../../multi-select-input/multi-select-input.component';

@Component({
	selector: 'multi-select-input-2',
	templateUrl: './multi-select-input.component.html',
	styleUrls: ['./multi-select-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MultiSelectInput2Component,
			multi: true,
		},
	],
})
/**
 * NOTE: Override classes doesn't work here
 */
export class MultiSelectInput2Component extends MultiSelectInputComponent {
	@ViewChild(CdkVirtualScrollViewport) private _vs: CdkVirtualScrollViewport;
	@Input() selectAllName = 'Select All';
	@Input() hideIcon = false;

	get selectedPreview() {
		return this.selectedItems.slice(0, 4);
	}

	resetScroll() {
		this._vs.elementRef.nativeElement.classList.remove('ps--active-y');
		this._vs.scrollToIndex(0);
	}
}
