import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';
import { companyListProps, PaginationConstants, TableMessages } from '@app/shared/constants';
import { SpinnerService } from '@app/core/services/spinner.service';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { MatSort, SortDirection } from '@angular/material/sort';

@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit {
  // paginator
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @Output() sendProps = new EventEmitter<companyListProps>();

  order = '';
  column = '';
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;

  @Input() tableData: any;
  @Input() tableCol: any;
  @Input() index: number;
  // search
  @ViewChild('search') search: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  // table
  displayedColumns = ['name', 'categoryName'];
  dataSource: MatTableDataSource<any>;
  message = {
    noRecord: TableMessages.EmptyTable,
  };

  searchTimeout: any;

  constructor(public spinner: SpinnerService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.setupAndSendProps();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableCol'] && changes['tableCol'].currentValue != undefined) {
      this.displayedColumns = this.tableCol;
    }
    if (
      changes['tableData'] &&
      changes['tableData'].currentValue != undefined
    ) {
      this.dataSource = new MatTableDataSource(this.tableData.data);
      this.totalItems = this.tableData.totalCount;
    }
  }

  setupAndSendProps(page?: number) {
    this.paginator.page = page || 1;
    const data: companyListProps = {
      search: this.search.nativeElement.value,
      index: this.index,
      page: this.paginator.page,
      pageSize: this.paginator.size,
      column: this.column,
      order: this.order,
      sort: this.sort.direction.toUpperCase() as SortDirection,
    };
    this.sendProps.emit(data);

    setTimeout(() => {
      clearTimeout(this.searchTimeout);
    }, 200);
  }

  onSearch() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setupAndSendProps();
    }, 1000);
  }

  sortData(e: HeaderSort) {
    this.order = e.direction.toUpperCase();
    this.column = e.active;
    this.setupAndSendProps();
  }
}
