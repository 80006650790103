import { createAction, props } from '@ngrx/store';

import { IApplication } from '../../application.interface';

export enum ActionTypes {
	COMPANY_APPLICATION_UPDATE = '[Company Application List] Update',
	COMPANY_APPLICATION_UPDATE_SUCCESS = '[Company Application List] Update Success',
}

export const companyApplicationUpdateAction = createAction(
	ActionTypes.COMPANY_APPLICATION_UPDATE,
	props<Partial<IApplication>>()
);

export const companyApplicationUpdateSuccessAction = createAction(
	ActionTypes.COMPANY_APPLICATION_UPDATE_SUCCESS
);
