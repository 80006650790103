import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
} from '@angular/core';

import { PaginationConstants } from '@app/shared/constants';

import { CustomSelectDirective } from './custom-select.directive';

@Component({
  selector: 'table-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnChanges, AfterViewInit {
  @Input() totalItems = 0;
  @Input() pageSizes = PaginationConstants.pageSizes;
  @Input() currPage = 1;
  @Output() pageChange = new EventEmitter<number>();

  @ViewChildren('customSelect', { read: CustomSelectDirective })
  customSelects: QueryList<CustomSelectDirective>;

  size = this.pageSizes[0];
  page = 1;
  totalPages = 0;
  pages: number[] = [];
  pagination: number[];
  test: string = ''
  constructor(private _cd: ChangeDetectorRef) {}

  set currentPage(page: number) {
    this.page = page;
    this._cd.markForCheck();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalItems']) {
      this.setTotalPages();
      if (this.currPage) {
        const x = this.currPage > 5 ? this.currPage : this._getPages();
        this.setPages(x);
      }
    }
  }

  ngAfterViewInit(): void {
    this.page = 1;
  }

  onPageChange(page: number, isPage = false) {
    if (page != undefined && (page < 1 || page > this.totalPages)) {
      return;
    } else if (page) {
      this.page = page;
    }

    this.setPages(this.page > 5 ? this.page : this._getPages());
    this.pageChange.emit(this.page);
  }

  onPageChangeWithoutEmit(page: number, isPage = false) {
    if (page != undefined && (page < 1 || page > this.totalPages)) {
      return;
    } else if (page) {
      this.page = page;
    }

    this.setPages(this.page > 5 ? this.page : this._getPages());
  }

  changePageWithoutEmit(value: number) {
    this.page = value;
    this.onPageChangeWithoutEmit(this.page);
  }

  changePage(value: number) {

    this.page = value;
    this.onPageChange(this.page);
  }

  setTotalPages() {
    this.totalPages =
      Math.floor(this.totalItems / this.size) +
      (this.totalItems % this.size ? 1 : 0);

    this._cd.detectChanges();
  }

  getScrollHeight() {
    let height = (this.totalPages - 1) * 28.5;
    if (height > 85.5) height = 85.5;
    return height;
  }

  counter(l: number) {
    // changes: chunked the array so that virtual scroll will not cut some of its list from the UI
    return Array.from({ length: l }).map((_, i) => i);
  }

  pageCount(): number {
    return this.page > 5 ? this.page : this._getPages();
  }

  setPages(x = this.pageCount()) {
    this.pages = [];

    for (let index = x; index <= x + 1; --index) {
      if (index === 0) {
        break;
      }
      if (this.pages.length !== 5) {
        this.pages.push(index);
      }
    }

    this.pagination = this.pages.sort((a, b) => {
      return a - b;
    });

    this._cd.detectChanges();
  }

  private _getPages() {
    let page = this.page;

    if (this.page - 5 > 0) {
      if (this.page > this.totalPages) {
        page = --this.page;
      }
    } else {
      if (this.totalPages > 5) {
        page = 5;
      } else {
        page = this.totalPages;
      }
    }

    return page;
  }
}
