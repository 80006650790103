<ng-container *ngIf="systemRoleId == 3">
  <ng-container *ngIf="dataSource; else empty">
    <ng-container *ngTemplateOutlet="mailboxUsage"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="systemRoleId < 3">
  <ng-container *ngTemplateOutlet="mailboxUsers"></ng-container>
</ng-container>


<!-- for admins-->
<ng-template #mailboxUsers>
  <!-- <app-widget-dynamic-table
    [data]="dataTable"
    [style]="usageSettings"
  ></app-widget-dynamic-table> -->
  <responsive-table
    [table$]="dataTable$"
  ></responsive-table>
</ng-template>

<!-- for sp users-->
<ng-template #mailboxUsage>
  <ng-container *ngIf="usage.endValue > 0; else empty">
    <div class="usage-container">
      <dx-linear-gauge id="gauge" [value]="usage.value">
        <dxo-value-indicator type="rectangle" color="#9B870C"> </dxo-value-indicator>
        <dxo-scale
          [startValue]="usage.startValue"
          [endValue]="usage.endValue"
        >
          <dxo-minor-tick [visible]="false"></dxo-minor-tick>
          <dxo-label
            [format]="formatUsage"
          ></dxo-label>
        </dxo-scale>
        <dxo-title [text]="usage.title">
          <dxo-font [size]="28"></dxo-font>
        </dxo-title>
        <dxo-range-container>
          <dxi-range [startValue]="0" [endValue]="usage.value" color="gray"></dxi-range>
          <ng-container *ngIf="usageVsRange(usage.value, usage.range1!).isRange">
            <dxi-range [startValue]="usage.value" [endValue]="usage.range1!" color="#77DD77"></dxi-range>
          </ng-container>
          <dxi-range [startValue]="usageVsRange(usage.value, usage.range1!).range1" [endValue]="usage.range2!" color="#92000A"></dxi-range>
        </dxo-range-container>
      </dx-linear-gauge>
      <div class="usage-label"><span>{{getOverValue(usage.value, usage.endValue)}}</span></div>
    </div>
  </ng-container>
</ng-template>

<ng-template #empty>
  <span class="font-weight-normal text-xs no-records">No records found.</span> 
</ng-template>