import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild('phoneNumberRef') phoneNumberRef: ElementRef;
  @Input() _phoneValue: any;
  @Input() maskFormat: string = '(000) 000 0000';
  @Input() prefix: string = '+1 ';
  @Input() placeholder: string = '000 000 0000';
  
	constructor() {}

	ngOnInit(): void {}

  ngAfterViewInit(): void {
		this.phoneNumberRef.nativeElement.focus();
	}

	get phoneValue() {
		return this._phoneValue;
	}

	set phoneValue(val) {
		this._phoneValue = val;
		this.propagateChange(this._phoneValue);
	}

	/* [Start]::Control value accessor implementation */
	onChange: any = () => {};
	onTouched: any = () => {};

	writeValue(value: any): void {
		if (value !== undefined) {
			this.phoneValue = value;
		}
	}

	propagateChange = (_: any) => {};

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {}
	/* [End]::Control value accessor implementation */
}
