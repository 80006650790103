import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	catchError,
	combineLatest,
	filter,
	first,
	map,
	Observable,
	of,
	shareReplay,
	Subject,
	switchMap,
	take,
	tap,
	throwError,
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { userDataSelector } from '@app/core/store/user/user.selector';
import { HttpRoutes } from '@app/shared/constants';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { sidebarUpdateAction } from './store/sidebar.action';
import { environment } from 'environments/environment';
import {
	flatMenuSelector,
	sidebarDataSelector,
} from './store/sidebar.selector';

@Injectable({
	providedIn: 'root',
})
export class SideBarService {
	sidebarData$ = this.getSideBarContents().pipe(
		take(1),
		tap((data) => {
			this.getContents(data);
		}),
		shareReplay()
	);
	flatMenu$ = this.store.pipe(
		select(flatMenuSelector),
		first(),
		switchMap((data) => (!data ? this.sidebarData$ : of(data))),
		switchMap((_menu) => this.store.pipe(select(flatMenuSelector)))
	);
	sideMenu$ = this.store.pipe(
		select(sidebarDataSelector),
		first(),
		switchMap((data) => (!data ? this.sidebarData$ : of(data))),
		switchMap((_menu) => this.store.pipe(select(flatMenuSelector)))
	);

	private _isGetContent = false;
	private _contents: RoleMenuInterface[] = [];

	// Observable string sources
	private _contentsSource = new Subject<RoleMenuInterface[]>();

	// Observable string streams
	contents$ = this._contentsSource.asObservable();

	get contents(): RoleMenuInterface[] {
		return this._contents;
	}

	get isGetContent(): boolean {
		return this._isGetContent;
	}

	constructor(
		private _http: HttpClient,
		//private _userService: UserService, // having circular dependency with UserService
		private _utilitiesService: UtilitiesService,
		private store: Store<AppStateInterface>
	) {}

	getSidebar() {
		this.sideMenu$.subscribe();
	}

	getSidebarData() {
		return combineLatest([this.flatMenu$, this.sideMenu$]).pipe;
	}

	getSidebarObs$() {
		return this.getSideBarContents().pipe(
			take(1),
			tap((data) => {
				this.getContents(data);
			})
		);
	}

	contentsChange(data: RoleMenuInterface[]): void {
		this._contentsSource.next(data);
	}

	getSideBarMenu(): Observable<RoleMenuInterface[]> {
		return this._http.get<RoleMenuInterface[]>(
			`${environment.apiBaseUrl}${HttpRoutes.UserMenus}`
		);
	}

	getSideBarContents(): Observable<RoleMenuInterface[]> {
		this._isGetContent = true;
		return this.getSideBarMenu().pipe(
			map((res: RoleMenuInterface[]) => {
				res = this.updateRoleMenu(res, 'companies/', 23);
				res = this.updateRoleMenu(res, 'contacts/', 103);
				res = this.updateRoleMenu(res, 'contacts/', 135);
				res = this.updateRoleMenu(res, 'groups/', 186);
				return res;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(() => {
					return error;
				});
			})
		);
	}

	// To remove if backend is updated
	updateRoleMenu(res: RoleMenuInterface[], menuName: string, id: number) {
		let selectedMenu: RoleMenuInterface | undefined = res.find(
			(menu: any) => menu.id === id
		);
		if (selectedMenu) {
			selectedMenu.subMenus.forEach((menu) => {
				if (menu.subMenus.length > 0) {
					menu.subMenus.forEach((subMenu) => {
						subMenu.url = subMenu.url.replace(menuName, '');
					});
				} else {
					if (menu.url) {
						menu.url = menu.url.replace(menuName, '');
					}
				}
			});
		}

		return res;
	}

	getContents(data: RoleMenuInterface[]): void {
		let newData = [...data];
		this.store
			.pipe(
				select(userDataSelector)
			)
			.subscribe((user) => {
				if (user?.menuIds) {
					const menuIds = user.menuIds;
					const contents = this._validateContent(newData, menuIds);
					// filtered data
					if (user.roleId === 1 || user.roleId === 2) {
						this._contents = contents.filter((menu) => menu.isAdmin === true);
					} else if (user.roleId === 3) {
						this._contents = contents.filter((menu) => menu.isAdmin === false);
					}
					this.store.dispatch(sidebarUpdateAction({ sidebarData: this._contents }));
				} else {
					this.store.dispatch(sidebarUpdateAction({ sidebarData: [] }));
				}
			});
	}

	private _validateContent(
		contents: RoleMenuInterface[],
		menuIds: number[]
	): RoleMenuInterface[] {
		let newContents: RoleMenuInterface[] = [];
		// Main Menu
		contents.forEach((content) => {
			if (menuIds.includes(content.id)) {
				let newContent = { ...content };
				let subMenus: RoleMenuInterface[] = [];

				if (content.subMenus.length > 0) {
					// 1st layer sub menu
					content.subMenus.forEach((subMenu) => {
						if (menuIds.includes(subMenu.id)) {
							let subMenuss: RoleMenuInterface = { ...subMenu };
							if (subMenu.subMenus.length > 0) {
								// 2nd layer sub menu
								subMenuss.subMenus = subMenu.subMenus.filter((smenu) => {
									return menuIds.includes(smenu.id);
								});
							}
							subMenus = [...subMenus, subMenuss];
						}
					});

					newContent = {
						...newContent,
						subMenus,
					};
				}

				if (
					(newContent.url && newContent.isNavigatable) ||
					(!newContent.isNavigatable && newContent.subMenus.length > 0)
				) {
					newContents = [...newContents, newContent];
				}
			}
		});

		return newContents;
	}

	// this will get the first available menu
	getAvailableContent(): string {
		let url = '/dashboard';
		for (const content of this.contents) {
			const contentUrl = `/${content.url}`;
			if (content.url) {
				url = contentUrl;
				break;
			} else {
				for (const subMenu of content.subMenus) {
					const subMenuUrl = `/${subMenu.url}`;
					if (subMenu.url) {
						url = subMenuUrl;
						break;
					}
				}
			}
		}

		return url;
	}

	checkImageUrl(imageUrl: string) {
		if (imageUrl) {
			return imageUrl.includes('(img)');
		} else {
			return false;
		}
	}
	transformImageUrl(imageUrl: string) {
		if (imageUrl) {
			return `assets/images/menu/${this._utilitiesService.parseImage(
				imageUrl
			)}`;
		} else {
			return '';
		}
	}

	transformIconUrl(imageUrl: string) {
		if (imageUrl) {
			return `assets/images/icons/${this._utilitiesService.parseImage(
				imageUrl
			)}`;
		} else {
			return '';
		}
	}

	loadFeedbackUrl() {
		return this._http.get<{ value: string; column: string }>(
			`${environment.apiBaseUrl}DefaultEnvironmentSetting/GetSimplesatURL`
		);
	}
}
