<div class="edit-wrap">
  <label for=""><ng-content></ng-content></label>
  <select-input
  [(ngModel)]="selectedCompany"
  inputClass="form-control search-height full-width"
  placeholder="Select Company"
  [disabled]="!isEnabledInputCompany"
  [options]="filteredCompanies"
  [optionKeys]="{value:'id', label:'name'}"

  (onChange)="getChosenCompanyId($event)"></select-input>
</div>
