import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomTooltipV2Service {
  private _rowData = new BehaviorSubject<any>('');
  public rowData = this._rowData.asObservable();

  public setRowData(data: any) {
    this._rowData.next(data);
  }
}
