<div class="container-fluid py-4">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="add-bank-account-main">
					<div class="card-header pb-0">
						<div class="d-lg-flex">
							<div>
								<h5>Verify Bank Account</h5>
								<div
									class="note-message text-dark text-sm d-flex align-items-center"
								>
									<app-figma-icon-img
										[iconName]="'info-circle'"
										[isForButton]="true"
									></app-figma-icon-img>
									<span class="font-b2"
										>We have sent two small deposits to this bank account. To
										verify this account, please confirm the amounts of these
										deposits.</span
									>
								</div>
							</div>
						</div>
					</div>

					<div class="card-body px-0 pb-0 pt-0">
						<div class="add-bank-account-area">
							<form class="add-bank-account-form">
								<div class="form-group">
									<div class="row">
										<div class="col-lg-5 col-md-6 me-auto">
											<form [formGroup]="verifyBankAccountForm">
												<div class="row">
													<div class="col-lg-12 col-md-12">
														<div class="account-form-label">
															<label class="required">First Deposit</label>
															<div class="deposit-form">
																<span class="prefix">0.</span>
																<input
																	type="text"
																	class="form-control"
																	placeholder="32"
																	maxlength="2"
																	formControlName="firstDeposit"
																	(keypress)="returnAsNumber($event)"
																/>
																<span class="suffix">USD</span>
															</div>
														</div>
													</div>

													<div class="col-lg-12 col-md-12">
														<div class="account-form-label">
															<label class="required">Second Deposit</label>
															<div class="deposit-form">
																<span class="prefix">0.</span>
																<input
																	type="text"
																	class="form-control"
																	placeholder="45"
																	maxlength="2"
																	formControlName="secondDeposit"
																	(keypress)="returnAsNumber($event)"
																/>
																<span class="suffix">USD</span>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>

										<div class="col-lg-5 col-md-6 me-auto"></div>

										<div class="col-lg-10 col-md-12 mx-auto">
											<ul
												class="list-unstyled d-flex d-inline-flex mt-5 w-100 justify-content-end"
											>
												<li>
													<a
														href="javascript:void(0);"
														class="btn-2 btn-2-link"
														[routerLink]="isAdmin ? '/billing-orders/invoices' : '/billing-and-orders/invoices'"
														class="btn-2 btn-2-link"
														>Cancel</a
													>
												</li>
												<li>
													<a
														href="javascript:void(0);"
														class="submit-btn btn-2 btn-2-primary"
														(click)="saveVerifyBankAccount()"
													>
														Verify
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
