import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor() { }

  decodeJWT(token: string): {[key: string]: string}{
    const decodedToken = jwtDecode(token) as {[key: string]: string};
    return decodedToken;
  }
}
