import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ClientMessageService } from '@app/modules/client-message/client-message.service';
import { UserService } from '@app/core/services/user.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { startsWithHttp } from '@app/shared/utilities/helper';
import * as moment from 'moment';
import { map } from 'rxjs';
import { Order } from '@app/shared/interfaces/order.interface';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { PaginatorComponent } from '../../paginator/paginator.component';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationConstants, TableMessages } from '@app/shared/constants';
import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';
import { Message } from '@app/shared/interfaces/message.interface';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements  AfterViewInit, OnInit {
  @Input() orderDetail: Order;
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @Input() totalItems = 0;
  @Input() dataSourceInvoice: MatTableDataSource<any> = new MatTableDataSource();
  @Input() totalItemsInvoice = 0;
  message? : Message;
  elapsed_since_read? : string;
  impersonating : boolean;
  @Output() sendMessage = new EventEmitter<any>();
  @Output() fetchOrderDetailList = new EventEmitter<any>();

  @ViewChild('productPaginator') productPaginator: PaginatorComponent;
  @ViewChild('invoicePaginator') invoicePaginator: PaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;
  order: SortDirection = 'asc';
  column = 'identifier';
  columnInvoice = 'invoiceNumber';

  displayedColumns: string[] = [
    'id',
    'identifier',
    'description',
    'status',
    'quantity',
    'unitPrice',
    'total',
  ];
  pageSizes = PaginationConstants.pageSizes;

  displayedColumnsInvoice: string[] = [
    'invoiceNumber',
    'billAmount',
    'balance',
    'invoiceDate',
    'dueDate',
    'status',
  ];
  pageSizesInvoice = PaginationConstants.pageSizes;
  tablemessage = {
    noRecord: TableMessages.EmptyTable,
  };
  isReadOnly = true;

  private readonly _tableName = {
    Product: 'product',
    Invoice: 'invoice',
  };
  constructor(
    private _usermessage: ClientMessageService,
    private _userService : UserService,
    private _spinner: SpinnerService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _utilitiesService: UtilitiesService,
    public spinner: SpinnerService,
    private _cd: ChangeDetectorRef

  ) {
    this._route.paramMap.subscribe(s=>{
      const id = s.get('id') as any;
      let categoryId = 0;
      this._route.queryParams.subscribe((s:any)=> categoryId = s.categoryId)
    });
    this.impersonating = this._userService.user?.impersonatingBy != null;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this._cd.detectChanges();
  }

  sortData(e: HeaderSort, tableName: string) {
    this.order = e.direction.toUpperCase() as SortDirection;

    if (tableName === this._tableName.Product) {
      this.column = e.active;
      this.fetchNewData(this._tableName.Product);
    }

    if (tableName === this._tableName.Invoice) {
      this.columnInvoice = e.active;
      this.fetchNewData(this._tableName.Invoice);
    }
  }

  @Input()
  public set messageData (messages:any)
  {
    this.elapsed_since_read = undefined;
    this.message = messages;
    if (this.message) {
      this.message._broadcastWebsiteLinkModified =
        (this.message.broadcastWebsiteLink && !startsWithHttp(this.message.broadcastWebsiteLink)) ?
        `//${this.message.broadcastWebsiteLink}` : this.message.broadcastWebsiteLink;

      if(!this.message.isRead && !this.impersonating)
        this._usermessage.readMessage(this.message.id, true).subscribe();
      if(this.message?.readDate && moment(this.message.readDate) >= moment())
       this.elapsed_since_read = moment(this.message.readDate).fromNow();
    }
  }

  setMessage(){
    this.message = undefined;
    this.elapsed_since_read = undefined;
    this.message = this.messageData;
    if (this.messageData) {
      if(!this.messageData.isRead)
        this._usermessage.readMessage(this.messageData.id, true).subscribe();
      if(this.message?.readDate && moment(this.messageData.readDate) >= moment())
       this.elapsed_since_read = moment(this.messageData.readDate).fromNow();
    }
  }

  togglePin(message:Message){
    let pin = this._usermessage.pinMessage(message.id, !message.isPinned).pipe(map(s=>{
      message.isPinned = !message.isPinned;
    }));
    this._spinner.run(pin).subscribe();
  }

  toggleSave(message:Message){
    let save = this._usermessage.saveMessage(message.id, !message.isSaved).pipe(map(s=>{
      message.isSaved = !message.isSaved;
    }));
    this._spinner.run(save).subscribe();
  }

  setMessageName(title: string | undefined, id: number | undefined, catId: number | undefined) {
    const titleName = title as string;
    const message = {
      id: id,
      catId: catId
    };
    sessionStorage.setItem('message-name', titleName);
    this.sendMessage.emit(message);
  }

  badgeType(color: string) {
    if (color) {
      let value = `background: linear-gradient(310deg, ${color} 0%, ${this._utilitiesService.light(color)} 100%)`;
      return value;
    } else {
      return `background: linear-gradient(310deg, #5a5a5a 0%,  ${this._utilitiesService.light('#5a5a5a')} 100%)`;
    }
  }

  fetchNewData(tableName: string, flag = 'page', pageOrSize?: number) {
    const params: any = {
      order: this.order,
      orderId: this.orderDetail.id,
      tableName: tableName
    };
    if (tableName === this._tableName.Product) {
      if (flag === 'page') {
        this.productPaginator.page = pageOrSize || 1;
      } else if (flag === 'size') {
        this.productPaginator.size = pageOrSize!;
        this.productPaginator.setTotalPages();
        this.productPaginator.setPages();
      }

      params.page = this.productPaginator.page;
      params.pageSize = this.productPaginator.size;
      params.sort = this.column;
      this.fetchOrderDetailList.emit(params);
    }

    if (tableName === this._tableName.Invoice) {
      if (flag === 'page') {
        this.invoicePaginator.page = pageOrSize || 1;
      } else if (flag === 'size') {
        this.invoicePaginator.size = pageOrSize!;
        this.invoicePaginator.setTotalPages();
        this.invoicePaginator.setPages();
      }

      params.page = this.invoicePaginator.page;
      params.pageSize = this.invoicePaginator.size;
      params.sort = this.columnInvoice;
      this.fetchOrderDetailList.emit(params);
    }
  }

  hasData(): any {
    return !!this.dataSource?.data?.length;
  }

  hasDataInvoice(): any {
    return !!this.dataSourceInvoice?.data?.length;
  }

  print() {
    window.print();
  }
}
