import { SortDirection } from '@angular/material/sort';

export interface State {
	name: string;
	abbreviation: string;
}

export interface States extends Array<State> {}
export interface PaymentMethod {
	card: Array<CardMethod>;
	bankAccount: Array<BankMethod>;
}
export interface CardMethod {
	id: string;
	name: string;
	last4: string;
	expMonth: number;
	expYear: number;
	isDefaultPayment: boolean;
}
export interface BankMethod {
	id: string;
	name: string;
	last4: string;
	status: string;
	isDefaultPayment: boolean;
}
export interface CardAndBankMethod {
	name: string;
	last4: string;
	id: number;
	paymentMethodId: string;
	type: string;
	isForAutopay: boolean;
}
export interface StripePublishable {
	publishableKey: string;
}
export interface InvoiceSummary {
	id: number;
	companyId: number;
	subsidiaryId: number;
	invoiceNumber: string;
	description: string;
	billAmount: number;
	balance: number;
	invoiceDate: string;
	dueDate: string;
	paymentStatus: string;
	amountUnused: number;
	transactionType: string;
	creditStatus: string;
	balanceBeforeCredit: number;
	isProcessing: boolean;

	partialAmount?: string;
	partialAmountInput?: string;
	isOpen?: boolean;
}
export interface ITable {
	totalCount: number;
	pageSize: number;
	currentPage: number;
}
export interface CreditsData extends ITable {
	data: Array<Credit>;
}
export interface Credit {
	id: number;
	invoiceNumber: string;
	creditMemoDate: string;
	memo: string;
	status: string;
	currency: string;
	amount: number;
	amountUsed: number;
	originalAmount: number;
	isNetsuiteUpdated: boolean;
	transactionType: string;
	color: string;
	fontColor: string;

	isChecked?: boolean | null;
	toApply?: string;
}
export interface CreditTableList extends ITable {
	data: Array<CreditTable>;
}
export interface CreditTable {
	id: number;
	invoiceNumber: string;
	invoiceDate: string;
	dueDate: string;
	billAmount: number;
	balance: number;
	status: string;
	isAutopay: boolean;
	autopayDate: string;
	isSettled: boolean;
	color: string;
	fontColor: string;
	amountUsed: number;
	amountUnused: number;
	transactionType: string;
}

export interface PaymentTableList extends ITable {
	data: Array<PaymentTable>;
}

export interface PaymentTable {
	id: number;
	invoiceNumber: string;
	invoiceDate: string;
	dueDate: string;
	billAmount: number;
	balance: number;
	status: string;
	isAutopay: boolean;
	autopayDate: string;
	isSettled: boolean;
	color: string;
	fontColor: string;
	referenceNumber: number;
}
export interface RequestPayment {
	receiptPrintId: number;
	data: Array<PaymentBreakdown>;
}

export interface PaymentBreakdown {
	id: number;
	invoiceNumber: string;
	description: string;
	amount: number;
	invoiceDate: string;
	processDate: string;
	transactionNumber: string;
	message: string;
	isSucceeded: boolean;
	creditedAmount: number;
	isCreditMemoFailed: boolean;
	creditMemoFailedMessage: string;
	creditMemoTotalAmount: number;
	unappliedPaymentTotalAmount: number;
	cardBrand?: string;
	bankName?: string;
	last4?: string;
	isProcessing?: boolean;
	isRequiresAction?: boolean;
}
export interface PendingPayment {
	credits: number;
	invoice: number;
	pendingPaymentAmount: number;
}
export interface PayButtonLabel {
	isValid: boolean;
	isShowPayNowLabel: boolean;
	isShowApplyCreditLabel: boolean;
	isShowPaymentWithUnappliedPaymentLabel: boolean;
	isShowApplyCreditAndPayLabel: boolean;
}
export interface PaymentIntentStatus {
	isSucceeded: boolean;
	message: string;
	isRequiresAction?: boolean;
	isProcessing?: boolean;
}
export interface BankInformation {
	id: string;
	object: string;
	accountId?: string;
	accountHolderName: string;
	accountHolderType: string;
	accountType?: string;
	bankName?: string;
	country: string;
	currency: string;
	customerId: string;
	defaultForCurrency?: string;
	deleted?: string;
	fingerprint: string;
	last4: string;
	routingNumber: string;
	status: string;
	email?: string;
}

export interface CardInformation {
	id: string;
	name: string;
	brand?: string;
	country: string;
	last4?: string;
	funding: string;
	expMonth: number;
	expYear: number;
	addressCity: string;
	addressCountry: string;
	addressLine1: string;
	addressLine2: string;
	addressState: string;
	addressZip: string;
}

export interface AutopayPaymentMethod {
	paymentMethodId: string;
	isAutopay: boolean;
}

export interface ChildCompanies {
	id: number;
	name: string;
	addressLine1: string;
}

export interface ActivityGroup {
	date: string;
	groupList: InvoiceActivity[];
}
export interface InvoiceActivity {
	activityDate: string;
	activity: string;
	initiatedBy: string | null;
	status: string[];
	imagePath: string | null;
}

export interface AccountActivityList extends ITable {
	data: Array<AccountActivity>;
}

export interface AccountActivity {
	initiatedBy: string;
	activityDate: string;
	activityTime?: string;
	activity: string;
	status?: string;
	transactionId: string;
}

export interface TransactionType {
	id: string | null;
	name: string;
}

export interface AccountActivityStatusDropdown {
	value: string | number;
	display: string | number;
	selected: boolean;
}

export interface IAccountActivity {
	isFetch?: boolean;
	page?: number;
	pageSize?: number;
	column?: string;
	order?: string;
	activityStartDate?: string;
	activityEndDate?: string;
	status?: number;
	searchText?: string;
	companyId?: number;
}

export interface IPayments {
	isFetch?: boolean;
	page?: number;
	pageSize?: number;
	column?: string;
	order?: string;
	paymentStartDate?: string;
	paymentEndDate?: string;
	searchText?: string;
	companyId?: number;
}

export interface ICredits {
	isFetch?: boolean;
	page?: number;
	pageSize?: number;
	column?: string;
	order?: string;
	invoiceStartDate?: string;
	invoiceEndDate?: string;
	transactionType?: string;
	searchText?: string;
	companyId?: number;
}

export interface IInvoice {
	page: number;
	pageSize: number;
	sort?: string;
	column?: string;
	order?: SortDirection;
	query?: string;
	userRole?: number;
	statusIds?: number[] | null;
	startDate: string;
	endDate: string;
	dueStartDate?: string;
	dueEndDate?: string;
	companyId?: number | null;
	haveBalance: boolean;
	typeIds?: number[] | null;
	isAutopay?: boolean | null;
	transactionType?: string | null;
	companyIds?: number | null;
}
