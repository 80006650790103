import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ComponentWidget, DashboardCard, WidgetContainer } from '@app/shared/interfaces/dashboard.interface';

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
})
export class WidgetContainerComponent implements OnInit {

  @Input() data:Partial<ComponentWidget>;//WidgetContainer;

  constructor(
  ){
  }

  ngOnInit(): void {
  }
}
