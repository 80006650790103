import { createAction, props } from "@ngrx/store";
import { IAsset } from "./assets.interface";
export enum ActionTypes{
  ASSETS_UPDATE = '[Assets] Update',
  ASSETS_UPDATE_SUCCESS = '[Assets] Update Success',
}
export const assetsUpdateAction =
createAction(
  ActionTypes.ASSETS_UPDATE,
  props<Partial<IAsset>>()
)
export const assetsUpdateSuccessAction =
createAction(
  ActionTypes.ASSETS_UPDATE_SUCCESS
)