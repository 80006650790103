/* Angular Libraries */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

/* Third Party Libraries */
import { Subject, takeUntil } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { StripeService } from '@app/shared/services/stripe.service';
import { NotificationService } from '@app/core/services/notification.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';

/* Interfaces */
import { NotificationMessages } from '@app/shared/constants';
import {
	States,
	StripePublishable,
} from '@app/shared/interfaces/invoice.interface';
import { CardValidationCode, CardValidationMessages } from '../../validation-messages/invoice-validation-messages.inum';

declare let Stripe: any;

@Component({
	selector: 'app-add-card-details',
	templateUrl: './add-card-details.component.html',
	styleUrls: ['./add-card-details.component.scss'],
})

export class AddCardDetailsComponent implements OnInit, OnDestroy {
	/* Properties */
	cardDetailsForm: FormGroup;
	cardElement: any;
	stateList: States = [];
	isLoadingCardInput: boolean = true;
	selectedCompanyId: number;
	cardNumberValidationMessage: string = '';
	cardValidationMessages = CardValidationMessages;

	/* Private Properties */
	private _stripe: any;
	private _$unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor(
		private fb: FormBuilder,
		private _stripeService: StripeService,
		private _spinner: SpinnerService,
		private _notifier: NotificationService,
		private _location: Location,
		private _toastMessageService: ToastMessageService
	) {}

	get hasInvalidClassName() {
		return (document as any).getElementById('card-element-id').classList.contains('StripeElement--invalid');
	}

	ngOnInit(): void {
		this._initStates();
		this._initForm();
		setTimeout(() => this._initCardElements(), 100);
	}

	back() {
		this._location.back();
	}

	async onSubmit() {
		const params = {
			name: this.cardDetailsForm.controls['cardHolderName'].value,
			address_country: this.cardDetailsForm.controls['addressCountry'].value,
			address_line1: this.cardDetailsForm.controls['addressLine1'].value,
			address_line2: this.cardDetailsForm.controls['addressLine2'].value,
			address_city: this.cardDetailsForm.controls['addressCity'].value,
			address_state: this.cardDetailsForm.controls['addressState'].value,
		};

		const { token, error } = await this._stripe.createToken(
			this.cardElement,
			params
		);

		this.cardDetailsForm.markAllAsTouched();

		if (!token) {
			this._spinner.stop();
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['cardHolderName'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['addressLine1'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['addressState'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (this.cardDetailsForm.controls['addressCity'].invalid) {
			this._notifier.notifyError(NotificationMessages.Review, NotificationMessages.Required);
			return;
		}

		if (token) {
			this._spinner.start();
			this._stripeService
				.addStripeCards({ token: token.id })
				.pipe(takeUntil(this._$unsubscribe))
				.subscribe({
					next: (result) => {
						this._spinner.stop();
						this._notifier.notifySuccess(NotificationMessages.save('Card'), 'You can use this card for future transactions');
						this._location.back();
					},
					error: (err) => {
						this._spinner.stop();
						this._toastMessageService.showErrorMessage(err.errors);
					},
				});
		}
	}

	private _initForm() {
		this.cardDetailsForm = this.fb.group({
			isValidCardNumber: ['', Validators.required],
			cardHolderName: ['', Validators.required],
			addressLine1: ['', Validators.required],
			addressLine2: [''],
			addressCity: ['', Validators.required],
			addressState: ['', Validators.required],
			addressCountry: ['United States', Validators.required],
		});
	}

	private _initCardElements() {
		this.isLoadingCardInput = true;
		this._spinner.start();

		this._stripeService.getStripePublishableKey().subscribe({
			next: (result: StripePublishable) => {
				this._stripe = Stripe(result.publishableKey);

				const elements = this._stripe.elements();

				const paymentElementOptions = {
					// hidePostalCode: true,
					style: {
						base: {
							iconColor: '#c4f0ff',
							color: '#868686',
							// fontWeight: '500',
							fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif, Metropolis',
							fontSize: '16px',
							// fontSmoothing: 'antialiased',
							':-webkit-autofill': {
								color: '#AFB0B3',
							},
							'::placeholder': {
								color: '#c2c2c2',
							},
						},
						invalid: {
							iconColor: '#E96458',
							color: '#E96458',
						},
					},
				};

				this.cardElement = elements.create('card', paymentElementOptions);
				this.cardElement.mount('#card-element-id');
			},
			error: () => {
				this.isLoadingCardInput = false;
				this._spinner.stop();
			},
			complete: () => {
				this._cardNumberValidation();
				this.isLoadingCardInput = false;
				this._spinner.stop();
			},
		});
	}

	private _initStates() {
		this._stripeService
			.getStatesForUnitedStates()
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (result: States) => {
					this.stateList = result;
				},
			});
	}

	private _cardNumberValidation() {
		this.cardElement.on('blur', (event: any) => this._stripe.createToken(this.cardElement, {}));
		
		this.cardElement.on('change', (event: any) => {
			if (event.error !== undefined) {
				this.cardDetailsForm.get('isValidCardNumber')?.markAsTouched();
				this.cardNumberValidationMessage = event.error.message;

				switch(event.error.code) {
					case CardValidationCode.IncompleteNumberCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidCardNumber;
						break;

					case CardValidationCode.IncompleteExpiryCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidExpiryMonthYear;
						break;

					case CardValidationCode.IncompleteCvcCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidSecurityCode;
						break;

					case CardValidationCode.IncompleteZipCode:
						this.cardNumberValidationMessage = CardValidationMessages.InvalidPostalCode;
				}

			} else {
				this.cardDetailsForm.get('isValidCardNumber')?.markAsUntouched();
			}
		});
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
