import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable, combineLatest, filter, switchMap, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { menuIdsSelector, roleIdSelector, systemRoleIdSelector } from '../store/user/user.selector';
import { AppStateInterface } from '../store/app-state.interface';
import { NavigationService } from '@app/shared/services/navigation.service';
import { UserTypes } from '@app/shared/constants';
import { UserService } from '../services/user.service';

export type GuardType =
	| Observable<boolean | UrlTree>
	| Promise<boolean | UrlTree>
	| boolean
	| UrlTree;
@Injectable({
	providedIn: 'root',
})
export class MenuPermissionGuard implements CanActivate, CanActivateChild {
	constructor(
		private store: Store<AppStateInterface>,
		private navigationService: NavigationService,
		private _userService: UserService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): GuardType {
		if (!route.data['id']) return true; //safe check, will remove until all is fixed
		// return true
		return this.checkRoleMenu(route.data['id']);
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): GuardType {
		if (!route.data['id']) return true;
		// return true
		return this.checkRoleMenu(route.data['id']);
	}

	canLoad(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): GuardType {
		return this.checkRoleMenu(route.data['id']);
	}

	private checkRoleMenu(data: any): GuardType {
		const menuIds$ = this.store.pipe(
			take(1),
			select(menuIdsSelector),
			filter((data) => data !== null)
		);
		const userRoleId$ = this.store.pipe(
			take(1),
			select(roleIdSelector),
			filter((data) => data !== null)
		);
		const systemUserRoleId$ = this.store.pipe(
			take(1),
			select(systemRoleIdSelector),
			filter((data) => data !== null)
		);

		return combineLatest([menuIds$, userRoleId$, systemUserRoleId$]).pipe(
			switchMap(async ([menuIds, roleId, systemRoleId]) => {
				const pageId = data[roleId];

				// Check if menu permission is availble on user's other role
				if(!pageId && systemRoleId != UserTypes.User) {
					const switchRole = roleId != systemRoleId ? systemRoleId : UserTypes.User;
					const switchPageId = data[switchRole];

					if(switchPageId && !sessionStorage.getItem('switchingRole')) {
						sessionStorage.setItem('switchingRole', 'true'); // to make sure there will be no duplicate switch role from other guards
						await this._userService.switchUserRole(false);
						sessionStorage.removeItem('switchingRole');
						 
						if(this._userService.userRole === switchRole) return true;
					}
					else if(switchPageId) return true;
				}

				// if (!pageId) this.navigationService.navigateToForbiddenPage();
				if (!menuIds.includes(pageId)) {
					this.navigationService.smartNavigate();
					return false;
				}
				return true;
			})
		);
	}
}
