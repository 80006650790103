<div id="impersonate-alert-bar">
  <div class="v2-alert-bar">
    <div class="v2-alert-bar_container q-d-flex q-jc-between q-ai-center">
      <div class="v2-alert-bar_left-nav q-d-flex q-ai-center q-g-8">
        <div class="v2-alert-bar_user-right-icon">
          <mat-icon svgIcon="user-right-01" style="stroke: #444548; width: 20px; height: 20px;"></mat-icon>
        </div>

        <div class="v2-alert-bar_label font-btn">
          Impersonating:
        </div>

        <div class="v2-alert-bar_value font-btn">
          {{ getImpersonate }}
        </div>
      </div>

      <a 
        href="javascript:void(0)" 
        class="btn-2 btn-2-link v2-alert-bar_right-nav q-d-flex q-p-8 q-jc-center q-ai-center q-g-12 q-pointer"
        (click)="logoutImpersonate()">

        <mat-icon svgIcon="x-close" style="stroke: #444548; width: 20px; height: 20px;"></mat-icon>
        <div class="v2-alert-bar_close-btn-content">
          Stop Impersonation
        </div>
      </a>
    </div>
  </div>
</div>