import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { AppStateInterface } from "@app/core/store/app-state.interface";
import { createSelector } from "@ngrx/store";

export const companyKbCategorySelector = (state: AppStateInterface): IPageState => state.companyKbCategory;

export const pageSelector =
createSelector(companyKbCategorySelector, (state: IPageState)=>state.page);

export const pageSizeSelector =
createSelector(companyKbCategorySelector, (state: IPageState)=>state.pageSize);

export const orderSelector =
createSelector(companyKbCategorySelector, (state: IPageState)=>state.order);

export const columnSelector =
createSelector(companyKbCategorySelector, (state: IPageState)=>state.column);

export const querySelector =
createSelector(companyKbCategorySelector, (state: IPageState)=>state.query);

// export const companyIdSelector =
// createSelector(kbCategorySelector, (state: IKBCategory)=>state.companyId);

