<ol class="breadcrumb-2 font-b3 font-h-normal">
	<ng-container
		*ngFor="let path of paths; let i = index; let last = last"
	>
		<ng-container
			*ngIf="path.path === 'No-URL' || path.path === lastPath; else hasUrl"
		>
			<ng-container *ngIf="last; else notLastPath">
				<li>
					{{ path.title }}
				</li>
			</ng-container>
			<ng-template #notLastPath>
				<li
					*ngIf="path.allowRedirect"
					aria-current="page"
					[routerLink]="i + 1 != paths.length ? slashPaths[i] : './'"
					[relativeTo]="relativePath"
					[replaceUrl]="true"
					class="with-path"
				>
					{{ path.title }}
				</li>

				<li *ngIf="!path.allowRedirect">
						{{ path.title }}
				</li>
			</ng-template>
		</ng-container>
		<ng-template #hasUrl>
			<li
				class="with-path"
				aria-current="page"
				[routerLink]="i + 1 != paths.length ? slashPaths[i] : './'"
				[relativeTo]="relativePath"
				[queryParams]="path.queryParams"
			>
				<!-- /companies/contact-setting/groups/2 -->
				<!-- <li class="breadcrumb-item text-sm text-white " aria-current="page" [routerLink]="'../../../../../'" [relativeTo]="relativePath" [replaceUrl]="true" [queryParams]="path.queryParams"> -->
				{{ path.title }}
			</li>
		</ng-template>
		<li *ngIf="!last" class="slash"></li>
	</ng-container>
	<!-- <li class="breadcrumb-item text-sm text-white" *ngIf="breadcrumbText?._userAppCategoryName">
      <a class="opacity-5 text-white">{{breadcrumbText?._userAppCategoryName}}</a>
    </li>
    <li class="breadcrumb-item text-sm text-white" *ngIf="breadcrumbText?._userAppName">
      <a class="opacity-5 text-white">{{breadcrumbText?._userAppName}}</a>
    </li> -->
</ol>
