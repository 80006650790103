<div id="card-form" class="container-fluid py-4">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-main q-p-40">
					<div class="card-header pb-0">
						<div class="d-lg-flex">
							<div>
								<h5>Add Credit/Debit Card</h5>
							</div>

              <app-secondary-button
                [isBackButton]="true"
                (click)="back()"
              ></app-secondary-button>
						</div>
					</div>

					<div class="card-body px-0 pb-0 pt-0">
						<div class="add-bank-account-area">
							<form
								[formGroup]="cardDetailsForm"
								(ngSubmit)="onSubmit()"
								class="add-bank-account-form">

								<div class="form-group">
									<div class="row">
										<div class="col-lg-5 col-md-6 me-auto">
											<h6>Card Information</h6>
											<div class="row">
												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label class="required">Card Number</label>
														<div
															class="credit-card-form position-relative"
															[class.visibility-hidden]="isLoadingCardInput">

															<input
																type="text"
																class="card-element-form visibility-hidden"
																formControlName="isValidCardNumber">

															<div id="card-element-id" class="credit-debit-form position-relative"></div>
														</div>
													</div>
												</div>

												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label class="required">Cardholder's Name</label>

														<input
															type="text"
															id="cardholder-name"
															class="form-control"
															formControlName="cardHolderName"
															placeholder="John Hancock"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"/>
													</div>
												</div>
											</div>
										</div>

										<div class="col-lg-5 col-md-6 me-auto">
											<h6>Billing Address</h6>
											<div class="row">
												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label class="required">Address Line 1</label>

														<input
															type="text"
															id="street-1"
															class="form-control"
															formControlName="addressLine1"
															placeholder="1776 Liberty Lane"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"/>
													</div>
												</div>

												<div class="col-lg-12 col-md-12">
													<div class="account-form-label">
														<label>
															Address Line 2
															<span>(Optional)</span>
														</label>

														<input
															type="text"
															id="street-2"
															class="form-control"
															formControlName="addressLine2"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"
															placeholder="Suite 12"/>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label class="required">City</label>

														<input
															type="text"
															id="city"
															class="form-control"
															formControlName="addressCity"
															placeholder="Boston"
															onfocus="focused(this)"
															onfocusout="defocused(this)"
															autocomplete="off"/>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label class="required">State</label>

														<select
															id="state"
															class="form-control"
															formControlName="addressState"
															autocomplete="off">

															<option value="" selected>
																- Select State -
															</option>

															<ng-container *ngFor="let state of stateList">
																<option [value]="state.abbreviation">
																	{{ state.name }} ({{ state.abbreviation }})
																</option>
															</ng-container>
														</select>
													</div>
												</div>

												<div class="col-lg-6 col-md-12">
													<div class="account-form-label">
														<label class="required" for="country">
															Country
														</label>

														<input
															type="text"
															id="country"
															class="form-control"
															formControlName="addressCountry"
															autocomplete="off"
															readonly/>
													</div>
												</div>
											</div>
										</div>

										<div class="col-lg-10 col-md-12 mx-auto">
											<ul class="list-unstyled d-flex d-inline-flex mt-5 w-100 justify-content-end">
												<li>
													<a
														href="javascript:void(0);"
														class="submit-btn btn-2 btn-2-primary"
														(click)="onSubmit()">

														<span class="d-flex">
															<app-figma-icon-img
																[iconName]="'check-circle'"
																[isForButton]="false"
                                [hasSpaceOnRight]="false">
															</app-figma-icon-img>
														</span>
														Save Card
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
