import { Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
	@Input() userId: number;
	@Output() onReloadAuditTrail: EventEmitter<void> = new EventEmitter<void>();

	auditTrailRefresh() {
		this.onReloadAuditTrail.emit();
	}
}
