function min(error: any) {
	return `Minimum value for this field is ${error.min}`;
}

function max(error: any) {
	return `Maximum value for this field is ${error.max}`;
}

function minlength(error: any) {
	return `Minimum length for this field is ${error.requiredLength}`;
}

function maxlength(error: any) {
	return `Maximum length for this field is ${error.requiredLength}`;
}

function minDate(error: any) {
	return `Minimum date is ${error.minDate}`;
}

function maxDate(error: any) {
	return `Maximum date is ${error.maxDate}`;
}

function fileTypes(error: any) {
	return `Allowed file types are ${error.fileTypes}`;
}

function mask(error: any) {
	return `Format must be ${error.requiredMask}`;
}

export const validationMessages = {
	mask,
	required: (field: any) => `${field} is required`,
	min: min,
	max: max,
	minlength: minlength,
	maxlength: maxlength,
	minDate: minDate,
	maxDate: maxDate,
	email: 'Invalid email format',
	fileTypes: fileTypes,
	landscape: 'Must be in landscape orientation',
	url: 'Must be a valid and secure url',
	past: 'Must not be a past date',
	future: 'Must not be a future date',
	alphaNumeric: 'No special characters allowed',
	wholeNumber: 'Must be a whole number',
	specialCharacter: 'No special character allowed',
	pattern: 'No special character allowed',
	duplicateName: 'Duplicate is not allowed',
	invalidUrl: 'Invalid URL format',
	timeRequired: 'Time is required',
	dateRequired: 'Date is required',
	greaterThanDate: 'This date should be greater than other date',
	lessThanDate: 'This date should be less than other date',
	fileType: 'Invalid file type. Allowed file types: png, jpeg, webp',
	publishStartInvalid:
		'Publish start date and time should be before publish end.',
	publishStartInThePast: 'Publish start date and time cannot be in the past.',
	publishEndInvalid: 'Publish end date and time should be after publish start.',
	notMatched: 'Not matched',
	fileSizeExceed: 'File size exceeds 250KB limit.',
	invalidDate: 'Invalid Date',
};
