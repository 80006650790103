import { Component, EventEmitter, inject, Output } from '@angular/core';
import { StripeService } from '@app/shared/services/stripe.service';

@Component({
  selector: 'bank-authorize-form',
  templateUrl: './bank-authorize-form.component.html',
  styleUrls: ['./bank-authorize-form.component.scss']
})

export class BankAuthorizeFormComponent {
  @Output() onEmitAccept: EventEmitter<boolean> = new EventEmitter<boolean>();
  isAccept: boolean = false;

  private _stripeService = inject(StripeService);

  onAcceptChange() {
    this.onEmitAccept.emit(this.isAccept);
  }

  openPortalTermsOfUse() {
		this._stripeService.openTermsAndCondition();
	}
}
