import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-clear-filter',
  templateUrl: './clear-filter.component.html',
  styleUrls: ['./clear-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearFilterComponent implements OnInit {
  @Output() clearAll = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  resetFilters() {
    this.clearAll.emit();
  }
}
