<label class="switch-input-container" [class.reverse]="labelPosition == 'before'">
  <input
    class="switch-input"
    type="checkbox"
		[disabled]="disabled"
    [ngModel]="checked"
    (ngModelChange)="onModelChange($event)"
  />
  <div class="switch-input-v2" [class.is-disabled]="disabled">
		<svg *ngIf="!checked" class="not-checked" xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32" fill="none">
			<rect width="64" height="32" rx="16" fill="#AFB0B3"/>
			<g filter="url(#filter0_d_2296_978)">
				<circle cx="16" cy="16" r="12" fill="#FCFCFC"/>
			</g>
			<defs>
				<filter id="filter0_d_2296_978" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="out"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2296_978"/>
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2296_978" result="shape"/>
				</filter>
			</defs>
		</svg>

		<svg *ngIf="checked" [class]="color" xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32" fill="none">
			<rect width="64" height="32" rx="16" fill="#53B6ED"/>
			<g filter="url(#filter0_d_2296_966)">
			<circle cx="48" cy="16" r="12" fill="#FCFCFC"/>
			</g>
			<defs>
			<filter id="filter0_d_2296_966" x="32" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset/>
			<feGaussianBlur stdDeviation="2"/>
			<feComposite in2="hardAlpha" operator="out"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2296_966"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2296_966" result="shape"/>
			</filter>
			</defs>
		</svg>
  </div>
  <ng-content></ng-content>
</label>