import { NotifType } from './../../core/services/notification.service';
import { Injectable } from '@angular/core';

import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';

import { NotificationMessages } from '@app/shared/constants';

@Injectable({
	providedIn: 'root',
})
export class ToastMessageService {
	private readonly _error = 'error';
	private readonly _success = 'success';
	private readonly _failed = 'failed';

	constructor(
		public spinner: SpinnerService,
		private _notifier: NotificationService
	) {}

	showErrorMessage(
		error: any,
		message = NotificationMessages.RequestFailedSubmit
	) {
		let err = error?.error || error?.errors;

		this.spinner.stop();
		if (typeof err === 'object' && err !== null) {
			for (const key in err) {
				if (key && Object.prototype.hasOwnProperty.call(err, key)) {
					this._notify(err[key].message.toString() || err[key], this._error);
				}
			}
		} else if (Array.isArray(error)) {
			for (const e of error) {
				this._notify(e.message.toString(), this._error);
			}
		} else if (typeof err === 'string' || typeof error === 'string') {
			this._notify(err || error, this._error);
		} else {
      if (!this._notifier.hasErrorMessageFromHttpInterceptor) {
        this._notify(message, this._error);
      }
		}
	}

	showSuccessMessage(message: string) {
		this._notify(message, this._success);
		this.spinner.stop();
	}

	/* [Start]::Added temporary alert failed message, needs for ticket QUEST-6746 */
	showFailedMessages(messageTitle: string, errorMessage: string) {
		this._notifier.notifyError(messageTitle, errorMessage);
		this.spinner.stop();
	}
	/* [End]::Added temporary alert failed message, needs for ticket QUEST-6746 */

	private _notify(error: string, messageType: NotifType) {
		this._notifier.notify(error, {
			duration: 5,
			panelClass: messageType,
		});
	}
}
