import { createAction, props } from '@ngrx/store';

import { IContacts } from '../contact/contacts.interface';

export enum ActionTypes {
	COMPANY_CONTACTS_UPDATE = '[Company Contacts] Update',
	COMPANY_CONTACTS_UPDATE_SUCCESS = '[Company Contacts] Update Success',
	COMPANY_CONTACTS_RESET_STATE = '[Company Contacts] Reset State',
}

export const companyContactsUpdateAction = createAction(
	ActionTypes.COMPANY_CONTACTS_UPDATE,
	props<Partial<IContacts>>()
);

export const companyContactsUpdateSuccessAction = createAction(
	ActionTypes.COMPANY_CONTACTS_UPDATE_SUCCESS
);

export const companyContactsResetStateAction = createAction(
	ActionTypes.COMPANY_CONTACTS_RESET_STATE
);
