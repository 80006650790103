<a
	(click)="clearGlobalSearch()"
	data-bs-toggle="collapse"
	[href]="'#menu' + index"
	class="btn-2-nav sidebar-btn collapsed"
	[class.active-2]="isActive"
	[attr.aria-controls]="'menu' + index"
	role="button"
	aria-expanded="false"
	#sidebarEl
>
	<app-figma-icon-img
		[iconName]="sideBarData.icon"
		[hasSpaceOnRight]="false"
	></app-figma-icon-img>

	<div>{{ sideBarData.name }}</div>

	<app-filter-chevron
		class="chevron q-ml-auto"
		[isCollapsed]="sidebarEl.classList.contains('collapsed')"
		[hasSpaceOnLeft]="false"
	></app-filter-chevron>
</a>

<div class="collapse hide" [id]="'menu' + index" data-bs-parent="#accordion">
	<ul class="navbar-nav q-g-8">
		<li
			class="nav-item"
			*ngFor="let secondMenu of sideBarData.subMenus"
			[activityTracker]="secondMenu.id"
		>
			<a
				class="btn-2-nav sidebar-btn q-pointer"
				[routerLink]="
					secondMenu.id === 338 ? getUrl(secondMenu.url) : [secondMenu.url]
				"
				routerLinkActive="active-2"
			>
				<app-figma-icon-img
					[iconName]="secondMenu.icon"
					[hasSpaceOnRight]="false"
				></app-figma-icon-img>

				<div>{{ secondMenu.name }}</div>
			</a>
		</li>
	</ul>
</div>
