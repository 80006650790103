import { createAction, props } from '@ngrx/store';

import { IContacts } from './contacts.interface';

export enum ActionTypes {
	CONTACTS_UPDATE = '[Contacts] Update',
	CONTACTS_UPDATE_SUCCESS = '[Contacts] Update Success',
}

export const contactsUpdateAction = createAction(
	ActionTypes.CONTACTS_UPDATE,
	props<Partial<IContacts>>()
);

export const contactsUpdateSuccessAction = createAction(
	ActionTypes.CONTACTS_UPDATE_SUCCESS
);
