import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyActivityGraphComponent } from './company-activity-graph.component';
import { CompanyActivityGraphService } from './company-activity-graph.service';
import { DxChartModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DxChartModule,
  ],
  declarations: [CompanyActivityGraphComponent],
  providers: [CompanyActivityGraphService],
  exports: [CompanyActivityGraphComponent]
})
export class CompanyActivityGraphModule { }
