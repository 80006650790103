<div class="main-contents spdmin-main support-main">
  <div class="main-search-wrap">
    <div class="row m-0">
      <div class="col-md-5 p-0">
        <h3 class="text-capitalize">{{ headerName }}</h3>
      </div>
      <div class="col-md-7 p-0">
        <div class="main-searchbar">
          <ul class="list-unstyled d-flex d-inline-flex m-0">
            <li>
              <input
                type="text"
                class="form-control mainsearch"
                placeholder="Search"
                (keyup.enter)="fetchNewData()"
                (input)="onSearch()"
                #search
              />
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="btn addnewapp-btn"
                (click)="onManagePage()"
              >
                {{ btnName }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="applist-table agree-table table-responsive table-striped">
    <app-column-slider
    [(displayedColumns)]="displayedColumns"
    ></app-column-slider>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
      class="table"
      matSortActive="name"
      matSortDirection="asc"
      matSortDisableClear="true"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NAME</th>
        <td class="py-3" mat-cell *matCellDef="let row">
          {{ row.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CATEGORY</th>
        <td class="py-3" mat-cell *matCellDef="let row">
          {{ row.category }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>ACTIONS</th>
        <td
          class="text-center px-4 py-3"
          mat-cell
          *matCellDef="let row"
          (click)="$event.stopPropagation()"
        >
          <a
            href="javascript:void(0)"
            class="link--hidden bin visiblity-icon"
            (click)="onDelete(row)"
            mat-raised-button
          >
            <p class="tooltip">
              <span class="tooltiptext text-capitalize">{{ tooltipName }}</span>
            </p>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="2">
          {{ message.noRecord }}
        </td>
      </tr>
    </table>

    <table-paginator
      [totalItems]="totalItems"
      [pageSizes]="pageSizes"
      (pageChange)="fetchNewData()"
      class="mb-5"
    ></table-paginator>
  </div>
</div>
