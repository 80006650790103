import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsService {
  constructor(private _http: HttpClient) {}

  getGlobalDropdown(permissionTypeId: number) {
    return this._http.get(
      `${environment.apiBaseUrl}Permissions/GlobalDropdown/${permissionTypeId}`
    );
  }

  getGlobalList(
    permissionTypeId: number,
    page: number,
    pageSize: number,
    column: string,
    order: string,
    searchText: string
  ) {
    let apiUrl = `${environment.apiBaseUrl}Permissions/GlobalList?PermissionModule=${permissionTypeId}&Page=${page}&PageSize=${pageSize}&Column=${column}&Order=${order}&search=${searchText}`;
    return this._http.get(apiUrl);
  }

  deleteData(payload: Params) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/DeleteGlobalByIds`,
      payload
    );
  }

  updateData(payload: Params) {
    return this._http.post(
      `${environment.apiBaseUrl}Permissions/UpdateGlobalByIds`,
      payload
    );
  }
}
