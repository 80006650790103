<input
	[formControl]="maskControl"
	[placeholder]="placeholder"
	[matMenuTriggerFor]="timeMenu"
	(blur)="onBlur()"
	(keydown)="onKeyDown($event)"
	class="mask-input font-b1 font-h-normal"
	type="text"
	#maskRef
/>

<mat-icon svgIcon="clock"></mat-icon>
<mat-icon svgIcon="alert-triangle"></mat-icon>

<mat-menu #timeMenu="matMenu" class="q-date-time">
	<div class="q-date-meridiem q-d-flex">
		<div
			[class.active-meridiem]="meridiem == 'AM'"
			(click)="$event.stopPropagation(); onMeridiemSelected('AM')"
			class="q-d-flex q-g-10 q-ai-center q-jc-center q-pointer font-b1"
		>
			<mat-icon svgIcon="sun-setting-02"></mat-icon>
			AM
		</div>
		<div
			[class.active-meridiem]="meridiem == 'PM'"
			(click)="$event.stopPropagation(); onMeridiemSelected('PM')"
			class="q-d-flex q-g-10 q-ai-center q-jc-center q-pointer font-b1"
		>
			<mat-icon svgIcon="sun-setting-02"></mat-icon>
			PM
		</div>
	</div>
	<div class="mat-menu-scroll-wrapper">
		<div class="mat-menu-content-wrapper scroll-v2">
			<div
				*ngFor="let time of timeList; let i = index"
				(click)="$event.stopPropagation(); onTimeSelected(i)"
				class="month-year-item font-b1 q-pointer"
				[class.active-item]="i == selectedTime"
			>
				{{ time }}
			</div>
		</div>
	</div>
</mat-menu>
