import { Directive, ElementRef, Input, Renderer2, ViewContainerRef, inject } from '@angular/core';

@Directive({
  selector: '[ellipsis]'
})
export class EllipsisDirective {
  // Injection
  private _el = inject(ElementRef);
  private _renderer = inject(Renderer2);

  // Input
  @Input() maxCharacters: number = 20;
  @Input() setToParent = false;
  @Input() set ellipsis(val:string){
    let name = val;
    if(val.length > this.maxCharacters) {
      name = val.substring(0, this.maxCharacters) + '...';
      this.setToParent? this._setParentAttribute(val): this._setAttribute(val);
    }
    this._renderer.setProperty(this._el.nativeElement, 'innerHTML', name)
  }

  // private method
  private _setAttribute(val:string){
    this._renderer.setAttribute(this._el.nativeElement, 'title', val)
    this._renderer.setAttribute(this._el.nativeElement, 'data-bs-placement', 'top')
    this._renderer.setAttribute(this._el.nativeElement, 'data-bs-toggle', 'tooltip')
  }

  private _setParentAttribute(val:string){
    this._renderer.setAttribute(this._el.nativeElement.parentElement, 'data-bs-toggle', 'tooltip')
    this._renderer.setAttribute(this._el.nativeElement.parentElement, 'data-bs-placement', 'top')
    this._renderer.setAttribute(this._el.nativeElement.parentElement, 'title', val)
  }
  
}
