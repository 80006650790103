import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { menuIdsSelector, roleIdSelector, systemRoleIdSelector } from '@app/core/store/user/user.selector';
import { UserTypes } from '@app/shared/constants';
import { SideBarService } from '@app/shared/navigation/side-bar/side-bar.service';
import { flatMenuSelector } from '@app/shared/navigation/side-bar/store/sidebar.selector';
import { NavigationService } from '@app/shared/services/navigation.service';
import { Store, select } from '@ngrx/store';
import { Observable, first, map, tap } from 'rxjs';


@Component({
  selector: 'app-page-forbidden',
  templateUrl: './page-forbidden.component.html',
  styleUrls: ['./page-forbidden.component.scss']
})
export class PageForbiddenComponent implements OnInit {
  canGoBack$: Observable<boolean>;
  isSystemAdmin$: Observable<boolean>;
  roleId$: Observable<number>;
  UserTypes = UserTypes;
  isLoading: boolean;
  constructor(
    private navigationService: NavigationService,
    private sidebarService: SideBarService,
    private store: Store<AppStateInterface>,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isLoading = false;
    this.sidebarService.getSidebarObs$().subscribe(()=>{
    this.isLoading = true;
    this.canGoBack$ = this.store.pipe(select(flatMenuSelector),first(), map(data=> data && data.length > 0 ? true : false))
    this.isSystemAdmin$ = this.store.pipe(select(systemRoleIdSelector), first(), map(data=> data === UserTypes.SourcepassAdmin || data === UserTypes.ClientAdmin));
    this.roleId$ = this.store.pipe(select(roleIdSelector), first());
    });
  }

  onLogout(){
    this.userService.logout()
  }

  onSwitchMode(){
    this.userService.switchUserRole();
  }

  onNavigate(): void {
    this.navigationService.smartNavigate();
  }


}
