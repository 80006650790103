import { createSelector } from '@ngrx/store';

import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IQuotes } from './quotes.interface';

export const quotesSelector = (state: AppStateInterface): IQuotes =>
	state.quotes;

export const pageSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.page
);

export const pageSizeSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.pageSize
);

export const orderSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.order
);

export const columnSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.column
);

export const querySelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.query
);

export const statusIdSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.statusIds
);

export const typesIdSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.typeIds
);

export const dateStartSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.startDate
);

export const dateEndSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.endDate
);

export const quotesFilter = createSelector(
	dateStartSelector,
	dateEndSelector,
	statusIdSelector,
	typesIdSelector,
	(dateStart, dateEnd, statusId, typesId) => ({
		dateStart,
		dateEnd,
		statusId,
		typesId,
	})
);

export const quotesSavedFilterSelector = createSelector(
	quotesSelector,
	(state: IQuotes) => state.quotesSavedFilter
);

export const isQuotesFilterChanged = createSelector(
	quotesFilter,
	quotesSavedFilterSelector,
	(filter, savedFilter) => isSame(filter, savedFilter)
);

function isSame(val1: any, val2: any) {
	return JSON.stringify(val1) === JSON.stringify(val2);
}
