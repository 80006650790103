<div *ngIf="totalItems > 0" class="container-pagi">
	<div class="row m-0">
		<div class="flex-container">
			<div class="span-page">
				<span>
					Showing {{ size * (page - 1) + 1 }} to
					{{ page == totalPages ? totalItems : size * page }} of
					{{ totalItems }} entries
				</span>
			</div>

			<div class="pagination-main-container">
				<div class="page">
					<ul class="dataTable-pagination-list">
						<li
							class="pager"
							[ngClass]="{ disabled: page === 1 }"
							(click)="onPageChange(page - 1)"
						>
							<a data-page="1">
								<app-figma-icon-img
									[iconName]="'chevron-left'"
									[hasSpaceOnRight]="false"
								></app-figma-icon-img>
							</a>
						</li>

						<li
							*ngFor="let n of pagination"
							[ngClass]="{ active: page === n }"
							(click)="onPageChange(n, true)"
						>
							<a [attr.data-page]="n">{{ n }}</a>
						</li>

						<li
							class="pager"
							(click)="onPageChange(page + 1)"
							[ngClass]="{ disabled: page + 1 > totalPages }"
						>
							<a data-page="2" style="margin-left: 1.5px">
								<app-figma-icon-img
									[iconName]="'chevron-right'"
									[hasSpaceOnRight]="false"
								></app-figma-icon-img>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
