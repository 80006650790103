import { ToastMessageService } from './../../../../shared/services/toast-message.service';
import { PaginatorComponent } from './../../../../shared/components/paginator/paginator.component';
import { MatSort, SortDirection } from '@angular/material/sort';
import { TableMessages, PaginationConstants, NotificationMessages, UserTypes } from '@app/shared/constants';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl } from '@angular/forms';
import { UserService } from './../../../../core/services/user.service';
import { LocalStorageService } from './../../../../shared/services/local-storage.service';
import { SpinnerService } from '@services/spinner.service';
import { FinanceService } from './../../finance.service';
import { Component, OnInit, LOCALE_ID, Inject, AfterViewInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { HeaderSort } from '@app/shared/interfaces/knowledge-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice-statement',
  templateUrl: './invoice-statement.component.html',
  styleUrls: ['./invoice-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceStatementComponent implements OnInit, AfterViewInit {
  companyId: number;
  invoiceDate: any;
  form = this._fb.group({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    billAmount: new FormControl(0),
    companyName: new FormControl(''),
    paymentCredit: new FormControl(0),
    billTo: new FormControl(''),
    shipTo: new FormControl(''),
    balance: new FormControl(0)
  });
  dataSource: MatTableDataSource<any> | any[] = [];
  totalItems = 0;
  pageSizes = PaginationConstants.pageSizes;
  message = {
    noRecord: TableMessages.EmptyTable,
  };
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  displayedColumns = [
    'date',
    'invoiceNumber',
    'quoteRef',
    'amount',
    'tax',
    'total',
    'paymentCredit',
    'amountDue',
  ];
  invoiceIds: any[] = [];
  isOpenOnly: boolean;
  haveBalance: boolean;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private location: Location,
    private _invoiceService: FinanceService,
    private _spinner: SpinnerService,
    private _localStorage: LocalStorageService,
    private _toastMessageService: ToastMessageService,
    private _userService: UserService,
    private _router: Router,
    private _fb: FormBuilder,
    private _cd: ChangeDetectorRef
  )
  {
    this.companyId = Number(this._userService.user?.companyId);
  }

  ngOnInit(): void {
    this.invoiceDate = this._localStorage.getStorageObject('generateDate');
    this.isOpenOnly = JSON.parse(new URLSearchParams(window.location.search).get("isOpenOnly") as any);
    this.haveBalance = JSON.parse(new URLSearchParams(window.location.search).get("haveBalance") as any);
  }

  ngAfterViewInit(): void {
    this.getInvoiceStatement();
    this.getInvoiceSummary();
    this.getInvoiceDetails();
  }

  getInvoiceStatement() {
    this._invoiceService
      .getInvoiceStatement(
        this.invoiceDate.startDate,
        this.invoiceDate.endDate,
        this.isOpenOnly
      ).subscribe({
        next: (resp: any) => {
          //console.log('invoiceIds', resp)
          //this.invoiceIds = resp.invoiceIds;
          this.patchStatementValue(resp);
        },
        error: (error) => {
          this._toastMessageService.showErrorMessage(error);
        }
      })
  }

  getInvoiceSummary(page?: number) {
    this.paginator.page = page || 1;
    this._spinner.start();
    this._invoiceService
      .getInvoiceSummary({
        page: this.paginator.page,
        pageSize: this.paginator.size,
        sort: this.sort.active ?? '',
        order: this.sort.direction,
        startDate: this.invoiceDate.startDate,
        endDate: this.invoiceDate.endDate,
        haveBalance: this.haveBalance
      })
      .subscribe({
        next: (data: any) => {
          this._spinner.stop();
          this.initialize(data);
        },
        error: (error) => {
          this._spinner.stop();
          this._toastMessageService.showErrorMessage(error);
        }
      })
  }

  getInvoiceDetails(){
    this._spinner.start();
    this._invoiceService
      .getInvoiceDetails2({
        startDate: this.invoiceDate.startDate,
        endDate: this.invoiceDate.endDate,
        isOpenOnly: this.isOpenOnly
      })
      .subscribe({
        next: (data: any) => {
          this._spinner.stop();
          this.invoiceIds = data;
          this._cd.detectChanges()
        },
        error: (error) => {
          this._spinner.stop();
          this._toastMessageService.showErrorMessage(error);
        }
      })
  }

  initialize({ data, totalCount }: { data: any[]; totalCount: number }) {
    this.totalItems = totalCount;
    this.dataSource = new MatTableDataSource(data);
    this._cd.markForCheck();
  }
  patchStatementValue(val: any) {
    this.form.patchValue({
      startDate: this.formatDate(val.startDate),
      endDate: this.formatDate(val.endDate),
      billAmount: val.billAmount,
      companyName: val.companyName,
      paymentCredit: val.paymentCredit,
      billTo: val.billTo,
      shipTo: val.shipTo,
      balance: val.balance
    });
    this._cd.detectChanges()
  }
  
  sortData(e: HeaderSort) {
    //this.order = e.direction as SortDirection;
    this.getInvoiceSummary()
    // this.column = e.active;
    //this.updateState({order: this.order})
    //this.getInvoices();
  }

  onClose() {
    // this.location.back();
    this._router.navigate([`/${this._invoiceService.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices`]);
  }
  formatDate(val: string) {
    const valDate = new Date(val)
    const date = formatDate(valDate, 'yyyy-MM-dd', this.locale);
    return date;
  }

  downloadInvoiceStatement() {
    this._spinner.start();
    this._invoiceService.downloadInvoiceStatement({fromDate: this.form.controls['startDate'].value, toDate: this.form.controls['endDate'].value}).subscribe({
      next: (data: any) => {
        /* const url = window.URL.createObjectURL(
          new Blob([data], { type: 'application/pdf' })
        ); */

        // const link = document.createElement('a');
        // link.href = data;
        // link.target = '_blank';
        // link.setAttribute('download', `Invoice Statement ${this.form.controls['startDate'].value} to ${this.form.controls['endDate'].value}`);
        // document.body.appendChild(link);
        // link.click();

        this._router.navigate([`/${this._invoiceService.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices/invoice-statement/view-pdf`], { queryParams: { pdfUrl: data, isOpenOnly: this.isOpenOnly, haveBalance: this.haveBalance }});
        this._spinner.stop();
      },
      error: () => {
        this._toastMessageService.showErrorMessage(
          NotificationMessages.FailedToGenerateFile
        );
      },
    });
  }
}
