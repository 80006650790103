import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ConnectWiseGuard implements CanActivate {

  constructor(
    private _router: Router,
  ){

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.isConnectWise)return true
    else return this._router.parseUrl('/');
  }

  get isConnectWise(){return window != parent}
  
}