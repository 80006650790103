import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-widget',
  templateUrl: './external-widget.component.html',
  styleUrls: ['./external-widget.component.scss']
})
export class ExternalWidgetComponent implements OnInit {
  url = "https://elevatedev.sourcepasselevate.com/";
  img = 'assets/images/icons/big-logoicon.png';
  description = 'Application';

  constructor() { }

  ngOnInit() {
  }

  launchApp(e: MouseEvent) {
    /* const aTag = document.createElement('a');
    aTag.href = this._checkHttp(this.url);
    aTag.target = '_blank';
    aTag.click(); */
    const url = this._checkHttp(this.url);
    window.open(url, "_blank")
  }

  private _checkHttp = (url: string) => {
    if (!this.url.includes('https://') && !this.url.includes('http://')) {
      return 'https://' + url;
    }
    return url;
  };

}
