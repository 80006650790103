import { Component, Input, OnInit, inject } from '@angular/core';
import { StatusComponent } from '@app/modules/dashboard/pages/dashboard/reporting/project/components/billing-records/status/status.component';
import { SharedModule } from '@app/shared/shared.module';
import { DataModalService, IDataModal } from '../../data-modal.service';

@Component({
	selector: 'app-change-status',
	templateUrl: './change-status.component.html',
	styleUrls: ['./change-status.component.scss'],
	standalone: true,
	imports: [SharedModule, StatusComponent],
})
export class ChangeStatusComponent implements OnInit {
	@Input() data: IDataModal;
	dataModalService = inject(DataModalService);

	constructor() {}

	ngOnInit(): void {}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}
}
