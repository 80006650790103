import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, take, takeUntil } from 'rxjs';
import { FinanceService } from '../../finance.service';

@Component({
  selector: 'app-view-pdf-invoice-statement',
  templateUrl: './view-pdf-invoice-statement.component.html',
  styleUrls: ['./view-pdf-invoice-statement.component.scss']
})

export class ViewPdfInvoiceStatementComponent implements OnInit, OnDestroy {
  private _$unsubscribe: Subject<void> = new Subject<void>();
  pdfSrc: any;
  isOpenOnly: boolean;
  haveBalance: boolean;

  get isAdmin() {
    return this._financeService.isAdmin;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _financeService: FinanceService
  ) {}

  ngOnInit() {
    this.initQueryParam();
  }

  initQueryParam() {
    this._activatedRoute.queryParamMap
    .pipe(takeUntil(this._$unsubscribe), take(1))
    .subscribe({
      next: params => {
        this.pdfSrc = this.domSanitize(params.get('pdfUrl') as string + '#zoom=100');
        this.isOpenOnly = JSON.parse(params.get('isOpenOnly') as any);
        this.haveBalance = JSON.parse(params.get('haveBalance') as any);
      }
    });
  }

  domSanitize(value: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  onClickClose() {
    this._router.navigate([`/${this.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices/invoice-statement`], { queryParams: { isOpenOnly: this.isOpenOnly, haveBalance: this.haveBalance }});
  }

  ngOnDestroy(): void {
    this._$unsubscribe.next();
    this._$unsubscribe.complete();
  }
}
