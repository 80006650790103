import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { DateTime } from 'luxon';

import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { ClientMessageService } from '@app/modules/client-message/client-message.service';
import { ColorService } from '@app/shared/services/color.service';
import { DateService } from '@app/shared/services/date.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';

import { Message } from '@app/shared/interfaces/message.interface';
import {
	IFakeTicket,
	ITicket,
	UserTicketPreviousAndNext,
} from './ticket.interface';
import { NotificationMessages, Permission } from '@app/shared/constants';
import { AiSummaryComponent } from './ai-summary/ai-summary.component';

@Component({
	selector: 'app-view-ticket',
	templateUrl: './view-ticket.component.html',
	styleUrls: ['./view-ticket.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewTicketComponent
	implements OnInit, OnChanges, AfterViewChecked
{
	@ViewChild('collapseEl') collapseEl: ElementRef;
	@ViewChild(AiSummaryComponent) aiSummaryComponent: AiSummaryComponent;
	@Input() ticket: ITicket | null;
	@Input() messageId?: number;
	@Input() navData?: UserTicketPreviousAndNext | Message | null;
	@Input() ticketListing?: any;
	@Input() hasMessageInput: boolean = true;
	@Input() hasUrl: boolean = true;
	@Output() onNextPrev = new EventEmitter();
	@Output() onSubmitTicket = new EventEmitter();
	@Output() fakeTicketEmitter = new EventEmitter<IFakeTicket>();
	@Output() state = new EventEmitter<string>();
	@Output() pdfData = new EventEmitter<{ pdfUrl: string; pdfId: number }>();

	Math = Math;
	isLoading: boolean = false;
	isDragEnter = false;
	isForecastClosed = false;

	AISummarizationEnabled = this._userService.hasPermission([
		Permission.AISummarization,
	]);

	constructor(
		private _userMessageService: ClientMessageService,
		private _sessionStorage: SessionStorageService,
		private _clientMessageService: ClientMessageService,
		private _toastMessageService: ToastMessageService,
		public _spinner: SpinnerService,
		public _route: ActivatedRoute,
		private _router: Router,
		private _dateService: DateService,
		public _colorService: ColorService,
		private _cd: ChangeDetectorRef,
		private _sanitizer: DomSanitizer,
		private _userService: UserService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['navData'] && !changes['navData'].firstChange) {
			this.navData = null;
			this.navData = changes['navData'].currentValue;
		}
	}

	ngOnInit(): void {
		if (this.messageId)
			this._userMessageService.readMessage(this.messageId, true).subscribe();
	}

	ngAfterViewChecked() {
		const dropZone = document.getElementById('drop-zone')!;

		this.isDragEnter = dropZone?.classList.contains('on-file-hover');
		this._cd.detectChanges();
	}

	back() {
		this._router.navigate(['/service-and-support/tickets']);
	}

	loadPrint() {
		if (this.hasUrl) {
			this.loadPrintWithUrl();
		} else {
			this.loadPrintWithoutUrl();
		}
	}

	loadPrintWithoutUrl() {
		this.isLoading = true;
		let id: any;
		id = this.ticket!.ticket.id;
		if (id) {
			this._clientMessageService.downloadTicket(id).subscribe({
				next: (data: any) => {
					this.isLoading = false;
					this.pdfData.emit({ pdfId: id, pdfUrl: data });
					this.state.emit('ticketPrint');
					// this._cd.detectChanges();
				},
				error: () => {
					this.isLoading = false;
					this._cd.detectChanges();
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
			});
		}
	}

	loadPrintWithUrl() {
		this.isLoading = true;
		let id: any;
		id = this._route.snapshot.paramMap.get('id');

		if (id) {
			this._clientMessageService.downloadTicket(id).subscribe({
				next: (data: any) => {
					this.isLoading = false;
					this._cd.detectChanges();
					this._router.navigate(['/service-and-support/tickets/view-pdf'], {
						queryParams: { pdfUrl: data, id: id },
					});
				},
				error: () => {
					this.isLoading = false;
					this._cd.detectChanges();
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
			});
		}
	}

	getSubmittedValue(evt: boolean) {
		this.onSubmitTicket.emit();
	}

	getFakeTicket(evt: IFakeTicket) {
		this.fakeTicketEmitter.emit(evt);
	}

	next() {
		if (this.navData?.nextId)
			this.onNextPrev.emit({ id: this.navData?.nextId });
	}

	prev() {
		if (this.navData?.previousId)
			this.onNextPrev.emit({ id: this.navData?.previousId });
	}

	checkNullId(id: any, type: string) {
		if (!id) {
			let option = JSON.parse(
				this._sessionStorage.getStorage('ticketOption') || '{}'
			);
			option.page = type === 'prev' ? option.page - 1 : option.page + 1;
			this._sessionStorage.setStorage('ticketOption', JSON.stringify(option));
		}
	}

	getDateDetail(): string {
		let message = '';
		if (!this.ticket?.ticket.lastUpdated) return 'Unknown Time';
		const dateGiven = this.ticket.ticket.lastUpdated;
		const lastUpdated = DateTime.fromISO(dateGiven);
		const fullDateTime = lastUpdated.toLocaleString(DateTime.DATETIME_FULL);
		const i = fullDateTime.lastIndexOf(' ');

		message += `${lastUpdated.toFormat('cccc')}, ${fullDateTime.substring(
			0,
			i
		)}`;
		const minDiff = this._dateService.onGetHourDifferenceFromTimezone(
			new Date(dateGiven)
		);
		if (Math.floor(minDiff / 60) == 0 && minDiff % 60 == 0)
			message += `  (A few seconds ago)`;
		else if (Math.floor(minDiff / 60) <= 0)
			message += `  (${minDiff} minute${minDiff === 1 ? '' : 's'} ago)`;
		else if (Math.floor(minDiff / 60) <= 24)
			message += ` (${Math.floor(minDiff / 60)} hour${
				Math.floor(minDiff / 60) === 1 ? '' : 's'
			} ago)`;
		else
			message += ` (${Math.floor(minDiff / 60 / 24)} day${
				Math.floor(minDiff / 60 / 24) === 1 ? '' : 's'
			} ago)`;
		return message;
	}

	domSanitize(value: string) {
		return this._sanitizer.bypassSecurityTrustResourceUrl(value);
	}

	closeAiSummaryBox() {
		this.collapseEl.nativeElement.click();
	}

	openAI() {
		this.aiSummaryComponent.updateSummary();
	}
}
@Directive({
	selector: '[ticketDropDown]',
})
export class TicketDropdownDirective {
	constructor(public elRef: ElementRef) {}

	@HostListener('click') toggleDropdown() {
		this.elRef.nativeElement.nextSibling.classList.toggle('show');
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: any) {
		if (!event.target.classList.contains('dropbtn')) {
			if (this.elRef.nativeElement.nextSibling.classList.contains('show')) {
				this.elRef.nativeElement.nextSibling.classList.remove('show');
			}
		}
	}
}
