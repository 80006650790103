<form class="flex-container">
  <app-companies
    class="flex-item bold"
    [companyFilterIds]="companyIds"
    [isEnabledInputCompany]="isEnabledInputCompany"
    [selectedCompany]="selectedCompany"
    (chosenCompanyId)="getCompanyId($event)"
  >
    Companies
  </app-companies>
  <app-groups
    class="flex-item bold"
    [cId]="chosenCompanyId"
    (chosenGroupIds)="getGroupIds($event)"
  >
    Groups
  </app-groups>
  <app-users
    class="flex-item bold"
    [gIds]="chosenGroupIds"
    [permissionData]="permissionData"
    [cId]="chosenCompanyId"
    (uIds)="getUserIds($event)"
  >
    Users
  </app-users>
  <div class="flex-item btn-container" *ngIf="showButtons">
    <button
      type="button"
      class="btn appsave-btn blue-btn ml-40"
      (click)="onSendSave()"
      [disabled]="isSaveUsersDisabled"
    >
      Save
    </button>
    <!-- <button
      type="button"
      class="btn appcancel-btn black-btn imp-enabled"
      (click)="onCancel()"
      [disabled]="isSaveUsersDisabled"
    >
      Cancel
    </button> -->
  </div>
</form>
