<div class="container-modal">

  <div [style.display]="currentState === 'ticketList' ? 'block': 'none'">
    <div class="heading">
      <div class="item-1">
        <h4>{{this.data.templateData!['title']}}
          &nbsp;
          <span class="tooltip-container">
            <img [src]="'../../../../../../../assets/icons/info-circle.svg'"/>
            <modal-tooltip [message]="message"></modal-tooltip>
          </span>
        </h4>
      </div>
      <div class="item-2">
        <button
         type="button"
         class="close"
         data-bs-dismiss="modal"
         aria-label="Close"
         (click)="closeModal(false)"
       >
         <span aria-hidden="true">
           <app-figma-icon-img
             [iconName]="'x'"
             [hasSpaceOnRight]="false"
           ></app-figma-icon-img>
         </span>
       </button>
      </div>
    </div>
    <div class="heading-2">
      <div class="item-1">
        <h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.TotalTickets">{{capitalizeFirstLetter(this.data.templateData!['month'])}} - Last 30 Days</h3>
        <h3 *ngIf="dashboardReport.dbModal === DashboardReportTab.UserTicket">{{capitalizeFirstLetter(this.data.templateData!['user'].userName)}} - Last 30 Days</h3>
      </div>
      <div class="item-2">
        <button class="btn-dashboard" (click)="download()">
          <app-figma-icon-img
          [iconName]="'download-02'"
          [width]="24"
          [height]="24"
          [hasSpaceOnRight]="false"
          [isForButton]="false"
        >
        </app-figma-icon-img>
          Download
      </button>
      </div>
    </div>

    <div class="modal-content">
      <app-new-table-shared
      [searchFilters]="searchFilters"
      [loadingText]="'Fetching Tickets...'"
      (emitRow)="openLink($event)"
    ></app-new-table-shared>
    </div>
  </div>




  <div class="card" [style.display]="currentState === 'ticketDetails' ? 'block': 'none'">
    <div class="card-header-2 q-p-24 q-pb-0">
        <h3 class="q-mr-auto">Ticket Details</h3>

        <app-secondary-button
          [isBackButton]="true"
          (click)="goBack()"
        ></app-secondary-button>
    </div>

    <app-view-ticket
      [ticket]="ticket"
      [hasMessageInput]="false"
      [hasUrl]="false"
      (state)="onEmitState($event)"
      (pdfData)="onEmitPDFData($event)"
      >
      </app-view-ticket>
  </div>

  <div class="card" [style.display]="currentState === 'ticketPrint' ? 'block': 'none'">
    <app-pdf-ticket
    (state)="onEmitState($event)"
    [pdfData]="pdfData"
    >

    </app-pdf-ticket>
  </div>



</div>
