<div class="save-filter">
	<label><br /></label>

	<ng-container
		*ngIf="state === 'apply'; then applied; else changed"
	></ng-container>

	<ng-template #applied>
		<app-tertiary-button
			[btnText]="isProcessing ? '...Processing' : 'Save Applied Filters'"
			(click)="saveLayout()"
			[isProcessing]="isProcessing"
		>
		</app-tertiary-button>
	</ng-template>

	<ng-template #changed>
		<app-tertiary-button
			[btnText]="'Saved Filters'"
			[iconName]="'check-circle'"
			[isActiveTabOrButton]="true"
		>
		</app-tertiary-button>
	</ng-template>
</div>
