import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactInfo } from './pages/profile/interface/contact-info.interface';
import { BasicInfo } from './pages/profile/interface/basic-info.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { toFormData } from '@app/shared/utilities/helper';
import { NameId } from '@app/shared/interfaces/support.interface';
import { UserProfileAccess } from './pages/profile/interface/roles-and-permission.interface';
import { BehaviorSubject } from 'rxjs';
import { SmsState } from '@app/shared/modals/sms-request-code/sms-request-code.interface';

@Injectable({
	providedIn: 'root',
})
export class ContactsService {
	subUserId: string | null = null;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _http: HttpClient
	) {}

	getCountryPhone() {
		return this._http.get(`https://restcountries.com/v3.1/all`);
	}
	getUserParams() {
		if (this._activatedRoute.snapshot.params['userId']) {
			this.subUserId = this._activatedRoute.snapshot.params['userId'];
		}
	}

	getUseridAtob() {
		if (!this.subUserId) this.getUserParams();
		return window.atob(this.subUserId!);
	}

	getUserData(userId: number) {
		return this._http.get<UserData>(
			`${environment.apiBaseUrl}CW_Users/${userId}`
		);
	}

	updateUserData(userId: number, data: UserData) {
		return this._http.put(
			`${environment.apiBaseUrl}CW_Users/${userId}`,
			toFormData(data)
		);
	}

	deleteUserPic(id: number) {
		return this._http.delete(
			`${environment.apiBaseUrl}Users/DeleteProfileImage/${id}`
		);
	}

	getUserReportsToDropdown(companyId: number, userId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}Users/GetDropdownByCompanyId/${companyId}?userId=${userId}`
		);
	}

	getCompanySitesDropdown(companyId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}Sites/GetDropdown?companyId=${companyId}`
		);
	}

	getDepartmentsDropdown() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}Departments/GetDropdown`
		);
	}

	getCompaniesDropdown() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}Companies/GetDropdown`
		);
	}

	getTimezonesDropdown() {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}Users/GetTimezoneList`
		);
	}

	getUserRoles(userId: number) {
		return this._http.get<UserProfileAccess[]>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/GetUserRoles?userId=${userId}`
		);
	}

	getUserBoards(userId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/GetUserBoardDropdown/${userId}`
		);
	}

	getRolesByCompany(companyId: number) {
		return this._http.get<NameId[]>(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/GetRolesByCompany?companyId=${companyId}`
		);
	}

	AddOrDeleteUserRoles(
		userId: number,
		rolesAdded: number[],
		rolesRemoved: number[]
	) {
		return this._http.post(
			`${environment.apiBaseUrl}RolesAndPermissions-v2/AddOrDeleteUserRoles`,
			{ userId, rolesAdded, rolesRemoved }
		);
	}

	isUserPhoneVerified() {
		return this._http.get<{ isVerified: boolean }>(
			`${environment.apiBaseUrl}Users/IsPhoneVerified`
		);
	}

	verifyPhoneNumber(code: string, userCodeId: number, userId?: number) {
		return this._http.post(
			`${environment.apiBaseUrl}Users/PhoneVerificationCheck`,
			{ code, userCodeId, userId }
		);
	}

	sendVerificationCode(phone?: number | string, userId?: number) {
		return this._http.post<number>(
			`${environment.apiBaseUrl}Users/VerifyPhone`,
			{ phone, userId }
		);
	}

	requestVerificationCode(id: any) {
		return this._http.post(
			`${environment.apiBaseUrl}UserTicketResponse/RequestCode/${id}`,
			{}
		);
	}
}

type UserData = BasicInfo & ContactInfo;
