<div class="modal-body">
  <div class="ErrorModule-content">
    <p class="font-size-15">{{data.message}}</p>
    <div class="gray-card">
      <div class="inline-container">
        <p>Recommendation: Enable MFA for all users</p>
      </div>
      <div class="inline-container flex-item">
        <p>Status: </p><quest-status [status]="'To Address'"></quest-status>
      </div>
      <div class="inline-container">
        <p>Estimated Time: 3 Months</p>
      </div>
      <div class="inline-container">
        <p>Estimated Cost: $1,000/year</p>
      </div>

      <div class="divider"></div>
      <div class="inline-container">
        <p>Current Security Score: 75 - Fair</p>
      </div>
      <div class="inline-container">
        <p>Points Added: +10</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer no-top-border q-g-16" [class.flex-start]="!data.options && data.altMessage">
   <app-tertiary-button
    *ngIf="data.options && data.options.noText"
    [btnText]="data.options.noText"
    (click)="closeModal(false)"
  >
  </app-tertiary-button>

  <button
    class="imp-disabled-op"
    *ngIf="data.options && data.options.yesText"
    type="button"
    [class.btn-2-primary]="
      data.type == 'success' ||
      data.type == 'info' ||
      data.type == 'warn'
    "
    [class.btn-2-destructive]="data.type == 'danger'"
    class="btn-2"
    (click)="closeModal(true)"
  >
    {{data.options.yesText}}
  </button>
</div>
