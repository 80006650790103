import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { BehaviorSubject } from 'rxjs';

import { UserService } from '@app/core/services/user.service';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PwaService {
	private _currentVersion: string;
	private _hasMandatoryUpdate = false;
	private _versionCard = new BehaviorSubject<VersionStatus | null>(null);
	versionCard$ = this._versionCard.asObservable();

	constructor(
		private _swUpdate: SwUpdate,
		private _userService: UserService,
		private _http: HttpClient,
		private _router: Router
	) {
		if (!this._swUpdate.isEnabled) return;

		this._swUpdate.versionUpdates.subscribe(async (event) => {
			if (event.type === 'VERSION_READY') {
				console.log(event.type);
				this._versionCard.next(VersionStatus.NewUpdate);
				const type = await this._getUpdateType(this._currentVersion);
				if (type === VersionStatus.MandatoryUpdate) this._showMandatoryUpdate();
				localStorage.setItem('versionUpdated', '1');
			} else if (event.type === 'VERSION_DETECTED') {
				console.log(event.type);
				this._currentVersion =
					(event.version.appData as any)?.appVersion || event.version.hash;
			} else if (event.type === 'NO_NEW_VERSION_DETECTED') {
				if (this._currentVersion) return;

				this._currentVersion =
					(event.version.appData as any)?.appVersion || event.version.hash;
				console.log('version:', this._currentVersion);
				this._updateLoginVersion();
				this._displayUpdates();
			}
		});

		this._userService.isAuthenticated$.subscribe(() =>
			this._updateLoginVersion()
		);
		this._checkUpdate(); // Checks update after 5 minutes
	}

	get hasMandatoryUpdate() {
		return this._hasMandatoryUpdate;
	}

	closeCard() {
		this._versionCard.next(null);
	}

	private _checkUpdate() {
		setTimeout(() => {
			this._swUpdate.checkForUpdate();
			this._checkUpdate();
		}, 300000); // 5 minutes delay
	}

	private _updateLoginVersion() {
		if (!this._userService.isAuthenticated || !this._currentVersion) return;

		this._http
			.put(`${environment.apiBaseUrl}UserLoginReport/UpdateLoginVersion`, {
				version: this._currentVersion,
			})
			.subscribe({
				error: (err) => {
					this._currentVersion = '';
				},
			});
	}

	private _getUpdateType(version: string) {
		return new Promise<VersionStatus>((resolve) => {
			this._http.get(`${environment.apiBaseUrl}Versions/${version}`).subscribe({
				next: (res: any) =>
					resolve(
						res.isMandatory
							? VersionStatus.MandatoryUpdate
							: VersionStatus.NewUpdate
					),
				error: (err) => resolve(VersionStatus.NewUpdate),
			});
		});
	}

	private _displayUpdates() {
		const isUpdated = localStorage.getItem('versionUpdated');
		if (!isUpdated) return;

		this._versionCard.next(VersionStatus.Updated);
		localStorage.removeItem('versionUpdated');
	}

	private _showMandatoryUpdate() {
		this._hasMandatoryUpdate = true;
		this._router.navigate(['/mandatory_update'], {
			skipLocationChange: true,
		});
	}
}

export enum VersionStatus {
	MandatoryUpdate,
	NewUpdate,
	Updated,
}
