import { SortDirection } from '@angular/material/sort';

export type Companies = {
  id: number;
  name: string;
  identifier: string;
  type: string;
  territory: string;
  status: string;
};

export type Company = {
  id: number;
  name: string;
  identifier: string;
  siteId: number;
  siteName: string;
  address: string;
  phone: string;
  fax: string;
  websiteURL: string;
  linkedInURL: string;
  facebookURL: string;
  twitterURL: string;
  territory: string;
  market: string;
  segment: string;
  clientFromAcquisition: string;
  status: string;
  dateAcquired: string;
  type: string;
};

export type CompanyBranding = {
  companyId: number;
  name: string;
  customDomain: string;
  faviconPath: string;
  faviconName: string;
  faviconType: string;
  favicon: any;
  logoPath: string;
  logoName: string;
  logoType: string;
  logo: any;
  loginLogoPath: string,
  loginLogoName: string,
  loginLogoType: string;
  lightThemeColor?: string;
  darkThemeColor?: string;
  logoColorList?: string[];
  dominantColorList?: string[];
  logoColors?: string;
  dominantColors?: string;
  isDarkMode: boolean;
  isDefault: boolean;
};

export type CompanyGroupBy =
  | ''
  | 'name'
  | 'country'
  | 'department'
  | 'division';

export const CompanyConstants = {
  FirstLetters: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ],
};
export interface CompanyContactParameters {
  page: number;
  pageSize: number;
  sort?: string;
  column?: string;
  order?: SortDirection;
  query?: string;
  userRole?: number;
  companyId?: number;
  groupBy?: CompanyGroupBy;
  groupIds?: number[] | null;
  applicationIds?: number[] | null;
  departmentIds?: number[] | null;
  siteIds?: number[] | null;
  companyIds?: number[] | null;
  firstLetter?: string;
}

export interface CompanyParameters {
  page: number;
  pageSize: number;
  sort?: string;
  column?: string;
  order?: SortDirection;
  query?: string;
  userRole?: number;
  statusIds?: number[] | null;
  typeIds?: number[] | null;
  territoryIds?: number[] | null;
}

export interface CompanyContact {
  companyName: string;
  country: string;
  departmentName: string;
  email: string;
  fax: string;
  firstLetterOfLastName: string;
  firstName: string;
  fullName: string;
  id: number;
  imagePath: string;
  lastName: string;
  phone: string;
  reportsToName: string;
  siteName: string;
  title: string;
}

export interface GroupsDropdownList{
  id:number,
  name: string,
  isInGroup: boolean,
}

export interface ChildCompany {
  id: number;
  name: string;
  childId: number;
  parentId: number;
  hasInvoice: boolean;
  hasTicket: boolean;
  hasDirectory: boolean;
  hasQuote: boolean;
  hasOrder: boolean;
}

export interface ParentChildAccess {
  id: number; // recordId
  hasInvoice?: boolean;
  hasTicket?: boolean;
  hasDirectory?: boolean;
  hasQuote?: boolean;
  hasOrder?: boolean;
}

export enum ParentChildAccessType {
	'Invoice',
	'Ticket',
	'Directory',
	'Quote',
	'Order',
}

export interface ParentChildLink {
  id: number;
  name: string;
  isParent: boolean;
  isChild: boolean;
  links: GroupsDropdownList[];
}