import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, concatMap, take, tap, throwError } from 'rxjs';

import {
  NotificationMessages,
  NotifOperation,
  IDropdown,
  companyListProps,
  IPermission,
  IPermissionUser,
  PermissionType,
  UserTypes
} from '@app/shared/constants';

import { BaseUserComponent } from '@app/shared/components/user/base-user.component';

import { SpinnerService } from '@app/core/services/spinner.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { GroupsAndPermissionsService } from '@app/modules/groups-and-permissions/groups-and-permissions.service';
import { UserService } from '@app/core/services/user.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { DataModalService } from '@app/core/data-modal/data-modal.service';

@Component({
  selector: 'permission-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent
  extends BaseUserComponent
  implements OnInit, OnChanges
{
  @ViewChild('saveButton') saveButton: ElementRef;
  @Input() permissionTypeId: number;
  @Input() appName: string;
  @Input() permissionModuleId: PermissionType = 1;
  @Input() state: string;
  @Input() isSaveCompanyDisabled: boolean = false;
  @Input() isActionButtonsDisabled: boolean = false;
  @Input() isSaveUsersDisabled: boolean = false;
  @Output() sendCompanyAccess = new EventEmitter<number[]>();

  moduleTitle: string;
  moduleName: string;
  selectAll: boolean;

  companies: IDropdown[];
  selectedCompanies: number[] = [];
  permissionData: number[];

  selectedUserIds: number[] = [];

  queryProps: companyListProps;
  tableData: any;
  companyApplicationPermissionStatic: number[] = [];
  permissionCompanyId: number | null;

  hasSubmitted = false;
  private _isRemovedBtnClicked = false;

  constructor(
    _userService: UserService,
    public spinner: SpinnerService,
    private _companiesService: CompaniesService,
    private _groupsService: GroupsAndPermissionsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dataModalService: DataModalService,
    private _utilitiesService: UtilitiesService,
    private _cd: ChangeDetectorRef,
    private _toastMessageService: ToastMessageService
  ) {
    super(_userService);
    this._setIdentity();
  }

  get hasError() {
    return this.selectedCompanies?.length === 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['permissionTypeId'] &&
      changes['permissionTypeId'].currentValue != undefined
    ) {
      if (this._userService.isSpAdmin) {
        this._fetchNewData();
        this._getCompanyDropdown().subscribe({
          error: () => {
            this.spinner.stop();
          },
        });
      } else if (this._userService.isClientAdmin) {
        this.receiveCompanyId(this.companyId);
      }
    }
  }

  ngOnInit() {
    this._setIdentity();
    this._setTitle();
    this._cd.markForCheck();
  }

  receiveCancel(_flag: boolean) {
    if (this._userService.isSpAdmin) {
      this.permissionCompanyId = null;
    } else if (this._userService.isClientAdmin) {
      switch (this.permissionModuleId) {
        case PermissionType.Message:
        case PermissionType.Application:
          let path = '../../'

          if (this._route.snapshot.routeConfig?.path === 'add') {
            path = '../';
          }

          this._router.navigate([path], { relativeTo: this._route });
          break;
        case PermissionType.KbTopics:
          this._router.navigate(['../'], { relativeTo: this._route });
          break;
      }
    }
  }

  receiveSave(_flag: boolean) {
    this._checkSelectedUserId();
  }

  receiveuIds(ids: number[]) {
    this.selectedUserIds = ids;
  }

  saveCompaniesByPermissionType() {
    this.spinner.start();

    if (this.selectedCompanies.length === 0) {
      this.hasSubmitted = true;
      this.spinner.stop();
      return;
    }

    if (this.companyApplicationPermissionStatic.length === 0) {
      const data: IPermission = {
        id: this.permissionTypeId,
        permissionModule: this.permissionModuleId,
        companyIds: this.selectedCompanies,
      };

      this._groupsService
        .addCompaniesByPermissionType(data)
        .pipe(
          take(1),
          concatMap(() => this._getCompaniesByApplication()),
          tap(() => {
            const message = NotificationMessages.success(
              NotifOperation.UPDATED,
              `${this.moduleName} Permissions`
            );

            this.hasSubmitted = false;
            this._fetchNewData();
            this._toastMessageService.showSuccessMessage(message);
            // Since this is not used on other module anymore, I specifically made the routing for message for now
            this._router.navigate(['../../messages'], { relativeTo: this._route });
          }),
          catchError((err) => {
            this.spinner.stop();
            throw err;
          })
        )
        .subscribe();
    } else {
      this._isRemovedBtnClicked = false;
      this._updatePermission();
    }
  }

  receiveCompanyId(id: number) {
    this.permissionCompanyId = id;
    this._getUsersByPermissionType()
      .pipe(
        take(1),
        tap((data: IPermissionUser) => {
          this.selectAll = data.hasUserLevelPermission;
          this.permissionData = removeDuplicates(data.userIds);
        })
      )
      .subscribe();
  }

  receiveHasUserLevelPermission(selectAll: boolean) {
    this.selectAll = selectAll;
  }

  receiveDeleteCompanyId(id: number) {
    this.selectedCompanies.splice(this.selectedCompanies.indexOf(id), 1);
    this.permissionCompanyId = null;
    this._isRemovedBtnClicked = true;
    this._updatePermission();
  }

  receiveProps(props: companyListProps) {
    this.queryProps = props;

    if (this.permissionTypeId) {
      this._fetchNewData();
    }
  }

  emitCompanyAcccess(companyAccess: number[]) {
    if (this.selectedCompanies.length === 0) {
      (this.saveButton.nativeElement as HTMLElement).click();
    }
    this.sendCompanyAccess.emit(companyAccess);
  }

  private _checkSelectedUserId() {
    if (this.selectedUserIds.length <= 0) {
      const data = this._dataModalService.getSaveModel(
        'Company User Permission',
        'You have not selected any users. Do you want to continue saving?'
      );

      this._dataModalService.showModal(data).subscribe({
        next: (result) => {
          if (result) {
            this._saveCompanyUsersPermission();
          }
        },
      });
    } else {
      this._saveCompanyUsersPermission();
    }
  }

  private _getCompanyDropdown() {
    return this._companiesService.getCompanyDropdown().pipe(
      take(1),
      tap((data) => {
        this.companies = data;
      }),
      concatMap(() => this._getCompaniesByApplication())
    );
  }

  private _setTitle() {
    switch (this.permissionModuleId) {
      case 1:
        this.moduleTitle = 'Application Name';
        this.moduleName = 'Application';
        break;
      case 2:
        this.moduleTitle = 'Topic Name';
        this.moduleName = 'Knowledge Base Topic';
        break;
      case 3:
        this.moduleTitle = 'Message Title';
        this.moduleName = 'Messages';
        break;
      default:
        break;
    }
  }

  private _getCompaniesByApplication() {
    return this._groupsService
      .getCompaniesByPermmissionModule(
        this.permissionModuleId,
        this.permissionTypeId
      )
      .pipe(
        take(1),
        tap((data: IPermission | any) => {
          this.selectedCompanies = JSON.parse(JSON.stringify(data.companyIds));
          this.companyApplicationPermissionStatic = JSON.parse(
            JSON.stringify(data.companyIds)
          );

          if (this.state === 'add') {
            this.selectedCompanies = this.companies.map((resp) => resp.id);
          }

          this.sendCompanyAccess.emit(this.selectedCompanies);

          this._cd.markForCheck();
        })
      );
  }

  private _saveCompanyUsersPermission() {
    const data = {
      id: this.permissionTypeId,
      userIds: this.selectedUserIds,
      permissionModule: this.permissionModuleId,
      hasUserLevelPermission: true,
    };

    this.spinner.start();
    this._getUserPermissionAPI(data)
      .pipe(
        take(1),
        tap((data: any) => {
          const message = NotificationMessages.success(
            NotifOperation.UPDATED,
            `${this.moduleName} Permissions`
          );

          if (this._userService.isSpAdmin) {
            this.permissionCompanyId = null;
          }

          if (data.userIds.length === 0) {
            this._getCompaniesByApplication().subscribe();
          }

          this._fetchNewData();
          this.sendCompanyAccess.emit(this.selectedCompanies);
          this._toastMessageService.showSuccessMessage(message);
        })
      )
      .subscribe();
  }

  private _fetchNewData() {
    if (this._userService.isSpAdmin) {
      const data = {
        permissionModuleId: this.permissionModuleId,
        permissionTypeId: this.permissionTypeId,
        ...this.queryProps,
      };

      this.spinner.start();
      this._groupsService
        .getCompanyList(data)
        .pipe(take(1))
        .subscribe((resp: any) => {
          this.tableData = resp;
          this._cd.markForCheck();
          this.spinner.stop();
        });
    }
  }

  private _getUserPermissionAPI(data: any) {
    switch (this.roleId) {
      case UserTypes.SourcepassAdmin:
        return this._groupsService.updateUserByCompanySPAdmin(
          this.permissionCompanyId!,
          data
        );
      case UserTypes.ClientAdmin:
        return this._groupsService.updateUserByCompanyClientAdmin(data);
      default:
        return this._groupsService.updateUserByCompanyClientAdmin(data);
    }
  }

  private _getUsersByPermissionType() {
    if (this._userService.isSpAdmin) {
      return this._groupsService.getUsersByPermissionType(
        this.permissionModuleId,
        this.permissionTypeId,
        this.permissionCompanyId!
      );
    } else {
      return this._groupsService.getUsersByPermissionTypeClient(
        this.permissionModuleId,
        this.permissionTypeId
      );
    }
  }

  private _updatePermission() {
    const data: any = {
      id: this.permissionTypeId,
      permissionModule: this.permissionModuleId,
      addCompanyIds: this._utilitiesService.compareAdd(
        this.companyApplicationPermissionStatic,
        this.selectedCompanies
      ),
      removeCompanyIds: this._utilitiesService.compareRemove(
        this.companyApplicationPermissionStatic,
        this.selectedCompanies
      ),
    };

    this._groupsService
      .updateCompaniesByPermissionType(data)
      .pipe(
        take(1),
        tap(() => {
          const message = NotificationMessages.success(
            NotifOperation.UPDATED,
            `${this.moduleName} Permissions`
          );

          this.hasSubmitted = false;
          this._getCompaniesByApplication().subscribe();
          this._fetchNewData();
          this.spinner.stop();

          if (!this._isRemovedBtnClicked) {
            // Since this is not used on other module anymore, I specifically made the routing for message for now
            this._router.navigate(['../../'], { relativeTo: this._route });
          }
        }),
        catchError((error) => {
          this._toastMessageService.showErrorMessage(
            NotificationMessages.NoChangesToApply
          );
          this._router.navigate(['../../'], { relativeTo: this._route });
          return throwError(() => error);
        })
      )
      .subscribe();
  }
}

function removeDuplicates(numbers: number[]): number[] {
  return [...new Set(numbers)];
}
