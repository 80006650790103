import { createAction, props } from "@ngrx/store";
import { IRequestForms } from "../request-forms.interface";


export enum ActionTypes{
  REQUEST_FORMS_UPDATE = '[RequestForms] Update',
  REQUEST_FORMS_UPDATE_SUCCESS = '[RequestForms] Update Success',

}

export const requestFormsUpdateAction =
createAction(
  ActionTypes.REQUEST_FORMS_UPDATE,
  props<Partial<IRequestForms>>()
)

export const requestFormsUpdateSuccessAction =
createAction(
  ActionTypes.REQUEST_FORMS_UPDATE_SUCCESS
)

