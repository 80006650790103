<img
	[ngClass]="{
		'figma-icon-btn': isForButton && !(width && height),
		'figma-icon': !isForButton && !(width && height),
		'mr-12': hasSpaceOnRight,
		'ml-12': hasSpaceOnLeft
	}"
	[src]="src"
	alt=""
	class="d-flex"
	width="{{ width }}"
	height="{{ height }}"
	*ngIf="src"
/>
