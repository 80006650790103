/* Angular Libraries */ 
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

/* Third Party Libraries */ 
import { Observable, Subject, takeUntil } from 'rxjs';

/* Functions */
import { SelectInputComponent } from '../select-input/select-input.component';

/* Interfaces */
import { ChildCompanies } from '@app/shared/interfaces/invoice.interface';

/* Environment */ 
import { environment } from 'environments/environment';

@Component({
	selector: 'app-child-company-dropdown',
	templateUrl: './child-company-dropdown.component.html',
	styleUrls: ['./child-company-dropdown.component.scss'],
})
export class ChildCompanyDropdownComponent implements OnInit, OnDestroy {
	/* View Child */
	@ViewChild(SelectInputComponent) selectInputComponent: SelectInputComponent;

	/* Input/Output */
	@Input() placeholder: string = 'Select child companies';
	@Output() onBlurForm: EventEmitter<void> = new EventEmitter<void>();
	@Output() onSelectChildCompanies: EventEmitter<any> = new EventEmitter<any>();

	/* Injection */
	private _http = inject(HttpClient);

	/* Properties */
	selectInputAsync = new FormControl();
	childCompanyList: Array<ChildCompanies> = [];
	selectAsync = this.selectInputAsync;

	/* Private Properties */
	private $unsubscribe: Subject<void> = new Subject<void>();

	/* Constructor */
	constructor() {}

	/* Methods */
	ngOnInit() {
		this._getChildCompanyDropdown()
		.pipe(takeUntil(this.$unsubscribe))
		.subscribe({
			next: (result: Array<ChildCompanies>) => {
				this.childCompanyList = result;
				if (Array.isArray(this.childCompanyList) && this.childCompanyList.length) {
					this.selectInputComponent.hasClear = false;
				}
			}
		}); 
	}

	clickForm() {
		this.selectInputComponent.clickForm();
	}

	closeSelection() {
		this.selectInputComponent.closeSelection();
	}

	onBlur() {
		this.onBlurForm.emit();
	}

	onChange(id: number) {
		const index = this.childCompanyList.findIndex(x => x.id === id);

		if (index >= 0) {
			this.onSelectChildCompanies.emit({ 
				value: this.childCompanyList[index].id, 
				label: this.childCompanyList[index].name 
			});
		}
	}

	private _getChildCompanyDropdown(): Observable<ChildCompanies[]> {
		return this._http.get<ChildCompanies[]>(
			`${environment.apiBaseUrl}ParentChildCompany/GetCurrentChildren`
		);
	}

	ngOnDestroy(): void {
		this.$unsubscribe.next();
		this.$unsubscribe.complete();
	}
}
