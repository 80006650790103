import { on } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import { PaginationConstants } from '@app/shared/constants';
import {
	companyGlobalKbTopicsUpdateAction,
	companyGlobalKbTopicsUpdateSuccessAction,
} from './kb-topic-global.companies.actions';
import { IKnowledgeBase } from '../../kb-topic.interface';

const initialState: IKnowledgeBase = {
	dateStart: '',
	dateEnd: '',
	page: 1,
	pageSize: PaginationConstants.pageSize,
	order: 'asc',
	column: 'category',
	query: '',
	categoryId: [],
	totalItems: 0,
};

export const companyGlobalKbTopicsReducer = createReducer(
	initialState,
	on(
		companyGlobalKbTopicsUpdateAction,
		(state, action): IKnowledgeBase => ({
			...state,
			...action,
		})
	),
	on(
		companyGlobalKbTopicsUpdateSuccessAction,
		(state): IKnowledgeBase => ({
			...state,
		})
	)
);
