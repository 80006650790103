<div id="payment-security">
	<div class="card">
		<div class="card-header w-100 pb-0">
			<div class="d-lg-flex">
				<h6 class="mb-0">Payment Security</h6>

				<div class="ms-auto my-auto mt-lg-0 mt-4">
					<div *ngIf="hasRequiresActions" class="ms-auto my-auto">
						<span>
							<a
								href="javascript:void(0)"
								class="btn-2 btn-2-secondary"
								[routerLink]="
									isAdmin
										? '/billing-orders/invoices'
										: '/billing-and-orders/invoices'
								"
							>
								Cancel & Return to Invoices
							</a>
						</span>
					</div>

					<div
						*ngIf="!hasRequiresActions && !isAllTransactionSuccess"
						class="ms-auto my-auto"
					>
						<app-tertiary-button
							[btnText]="'Download All Receipts'"
							(click)="downloadReceipt()"
							[iconName]="'download-02'"
							[isProcessing]="isProcessing"
						>
						</app-tertiary-button>

						&nbsp;&nbsp;

						<a
							href="javascript:void(0)"
							class="btn-2 btn-2-primary"
							[routerLink]="
								isAdmin
									? '/billing-orders/invoices'
									: '/billing-and-orders/invoices'
							"
						>
							Return to Invoices
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="card-body px-0 pb-0 pt-0">
			<div class="card-gap">
				<ng-container *ngIf="hasRequiresActions">
					<p>
						Your payment method that is being used for these transactions
						requires additional verification from the institution. Please verify
						each of the transactions below to fully process your payments. If
						you would like to read more information on the protection services
						your payment method requires, please visit
						<span class="link-style">this link</span>.
					</p>
				</ng-container>

				<div
					class="payment-message-container"
					*ngIf="!hasRequiresActions && isAllTransactionSuccess"
				>
					<div class="payment-message-sub">
						<div class="payment-message-sub2">
							<div class="payment-message-sub3">
								<div class="nice-job-msg">Nice Job!</div>
								<div class="invoice-payment-msg">
									All invoice payments have been verified and successfully paid
								</div>
							</div>

							<div class="payment-message-note">
								<b>Please Note:</b>
								{{ isCardType ? paymentNote.cardNote : paymentNote.bankNote }}
							</div>
						</div>

						<div class="d-flex justify-content-center mt-3">
							<app-tertiary-button
								[btnText]="'Download All Receipts'"
								(click)="downloadReceipt()"
								[iconName]="'download-02'"
								[isProcessing]="isProcessing"
							>
							</app-tertiary-button>

							&nbsp;&nbsp;

							<a
								href="javascript:void(0)"
								class="btn-2 btn-2-primary"
								[routerLink]="
									isAdmin
										? '/billing-orders/invoices'
										: '/billing-and-orders/invoices'
								"
							>
								Return to Invoices
							</a>
						</div>
					</div>
				</div>

				<div
					*ngIf="
						!hasRequiresActions &&
						!isAllTransactionSuccess &&
						!isAllTransactionCanceled &&
						!isAllTransactionFailed
					"
					class="payment-note"
				>
					<span>
						<b>Please Note:</b>
						{{ isCardType ? paymentNote.cardNote : paymentNote.bankNote }}
					</span>
				</div>

				<div
					*ngIf="invoiceList && invoiceList.length"
					class="payment-security-gray-box"
				>
					<ng-container *ngFor="let inv of invoiceList; let i = index">
						<div class="payment-verification-cards">
							<!-- [Begin]:: Will show this screen if message is 'succeeded' -->
							<div
								*ngIf="
									inv.message === messageStatus.succeeded ||
									(!hasRequiresActions && isAllTransactionSuccess) ||
									inv.isProcessing ||
									inv.isSucceeded
								"
								class="success-info-container"
							>
								<div class="success-icon">
									<app-local-figma-icon-img
										[filename]="'check-circle-green'"
										[width]="48"
										[height]="48"
									></app-local-figma-icon-img>
								</div>

								<div class="success-info-details">
									<div class="success-head">Payment Successful</div>
									<div class="success-sub-head">
										Transaction Date: Sept 25, 2023
									</div>
								</div>
							</div>
							<!-- [End]:: Will show this screen if message is 'succeeded' -->

							<!-- [Begin]:: Will show this screen if transaction payment is failed (isRequiresAction, isProcessing & isSucceeded is equal to false) -->
							<div
								*ngIf="
									!inv.isRequiresAction &&
									!inv.isProcessing &&
									!inv.isSucceeded &&
									inv.message !== messageStatus.canceled
								"
								class="failed-info-container"
							>
								<div class="failed-icon">
									<app-local-figma-icon-img
										[filename]="'alert-triangle-red'"
										[width]="48"
										[height]="48"
									></app-local-figma-icon-img>
								</div>

								<div class="failed-info-details">
									<div class="failed-head">Payment Transaction Failed</div>
									<div
										class="failed-sub-head"
										*ngIf="inv.message !== messageStatus.requiresPaymentMethod"
									>
										{{ inv.message }}
									</div>
									<div class="failed-sub-head">
										Transaction Date: {{ inv.processDate | date }}
									</div>
								</div>
							</div>
							<!-- [End]:: Will show this screen if transaction payment is failed (isRequiresAction, isProcessing & isSucceeded is equal to false) -->

							<div class="invoice-info">
								<div class="invoice-info-left">
									<div class="invoice-no">
										Invoice # {{ inv.invoiceNumber }}
									</div>
									<!-- <div class="order-id">Order ID: 2351</div> -->
									<div class="invoice-date">{{ inv.invoiceDate | date }}</div>
								</div>

								<div class="invoice-info-right">
									<!-- Status requires action -->
									<ng-container
										*ngIf="inv.isRequiresAction && !inv.isSucceeded"
									>
										<app-badge
											[label]="'REQUIRES ACTION'"
											[type]="'#ebeef1'"
											[fontColor]="'#545969'"
										>
										</app-badge>
									</ng-container>

									<!-- Status processing -->
									<ng-container *ngIf="inv.isProcessing || inv.isSucceeded">
										<app-badge
											[label]="'PAYMENT PROCESSING'"
											[type]="'#faf2ce'"
											[fontColor]="'#705c03'"
										>
										</app-badge>
									</ng-container>

									<!-- Status Failed -->
									<ng-container
										*ngIf="
											!inv.isRequiresAction &&
											!inv.isProcessing &&
											!inv.isSucceeded &&
											inv.message !== messageStatus.canceled
										"
									>
										<app-badge
											[label]="'PAYMENT TRANSACTION FAILED'"
											[type]="'#f8d1cd'"
											[fontColor]="'#6d1008'"
										>
										</app-badge>
									</ng-container>

									<!-- Status Canceled -->
									<ng-container *ngIf="inv.message === messageStatus.canceled">
										<app-badge
											[label]="'CANCELED'"
											[type]="'#ebeef1'"
											[fontColor]="'#545969'"
										>
										</app-badge>
									</ng-container>
								</div>
							</div>

							<!-- [Begin]:: Will show this screen if message value is 'requires_action' -->
							<ng-container
								*ngIf="inv.message === messageStatus.requiresAction"
							>
								<div class="invoice-label-parent-container">
									<div class="invoice-label-container">
										<div class="invoice-label">Payment Amount</div>
										<div class="invoice-amount">
											{{ inv.amount | currency }}
										</div>
									</div>

									<div class="invoice-label-container">
										<div class="invoice-label">Applying Credits</div>
										<div class="invoice-amount">
											{{ inv.creditMemoTotalAmount | currency }}
										</div>
									</div>

									<div class="invoice-label-container">
										<div class="invoice-label">Applying Existing Payments</div>
										<div class="invoice-amount">
											{{ inv.unappliedPaymentTotalAmount | currency }}
										</div>
									</div>
								</div>

								<div class="invoice-info-footer">
									<div class="transaction-label">
										Transaction ID: {{ inv.transactionNumber }}
									</div>
								</div>

								<div class="invoice-info-footer">
									<app-tertiary-button
										[btnText]="'Remove Transaction'"
										(click)="clickCancelTransaction(inv)"
									>
									</app-tertiary-button>

									<a
										href="javascript:void(0)"
										class="btn-2 btn-2-success"
										(click)="clickVerify(inv)"
									>
										Verify
									</a>
								</div>
							</ng-container>
							<!-- [End]:: Will show this screen if message value is 'requires_action' -->

							<!-- [Begin]:: Will show this screen if transaction payment is failed (isRequiresAction, isProcessing & isSucceeded is equal to false) -->
							<ng-container
								*ngIf="
									!inv.isRequiresAction && !inv.isProcessing && !inv.isSucceeded
								"
							>
								<div class="invoice-label-parent-container">
									<div class="invoice-label-container">
										<div class="invoice-label">Payment Amount</div>
										<div class="invoice-amount">
											{{ inv.amount | currency }}
										</div>
									</div>

									<div class="invoice-label-container">
										<div class="invoice-label">Applying Credits</div>
										<div class="invoice-amount">
											{{ inv.creditMemoTotalAmount | currency }}
										</div>
									</div>

									<div class="invoice-label-container">
										<div class="invoice-label">Applying Existing Payments</div>
										<div class="invoice-amount">
											{{ inv.unappliedPaymentTotalAmount | currency }}
										</div>
									</div>
								</div>

								<div class="invoice-info-footer">
									<div class="transaction-label">
										Transaction ID: {{ inv.transactionNumber }}
									</div>
								</div>
							</ng-container>
							<!-- [End]:: Will show this screen if transaction payment is failed (isRequiresAction, isProcessing & isSucceeded is equal to false) -->

							<!-- [Begin]:: Will show this screen if message value is 'succeeded' -->
							<ng-container
								*ngIf="
									inv.message === messageStatus.succeeded ||
									(!hasRequiresActions && isAllTransactionSuccess) ||
									inv.isProcessing ||
									inv.isSucceeded
								"
							>
								<div class="invoice-label-parent-container">
									<div class="invoice-label-container">
										<div class="invoice-label">Credits Applied</div>
										<div class="invoice-amount">
											{{ inv.creditMemoTotalAmount | currency }}
										</div>
									</div>

									<!-- [Begin]:: Payment type card | bank -->
									<ng-container *ngIf="hasPaymentMethod">
										<div class="invoice-label-container">
											<div class="invoice-label card-logo-container">
												<div class="card-logo">
													<ng-container *ngIf="inv.cardBrand">
														<app-card-logos [branName]="inv.cardBrand">
														</app-card-logos>
													</ng-container>

													<ng-container *ngIf="inv.bankName">
														<app-figma-icon-img
															[iconName]="'bank'"
															[hasSpaceOnRight]="false"
														></app-figma-icon-img>
													</ng-container>
												</div>

												<div class="card-logo-label">
													<span *ngIf="inv.bankName">
														{{ inv.bankName }}&nbsp;&nbsp;
													</span>
													<span>****{{ inv.last4 }}</span>
												</div>
											</div>
											<div class="invoice-amount">
												{{ inv.amount | currency }}
											</div>
										</div>
									</ng-container>
									<!-- [End]:: Payment type card | bank -->

									<div class="divider-style"></div>

									<div class="invoice-label-container">
										<div class="invoice-label">Total</div>
										<div class="invoice-amount">
											{{ totalAmount(inv) | currency }}
										</div>
									</div>
								</div>

								<div class="invoice-info-footer">
									<div class="transaction-label">
										Transaction ID: {{ inv.transactionNumber }}
									</div>

									<app-tertiary-button
										[btnText]="'Download Receipt'"
										(click)="downloadReceipt(inv.transactionNumber)"
										[iconName]="'download-02'"
										[isProcessing]="isProcessing"
									>
									</app-tertiary-button>
								</div>
							</ng-container>
							<!-- [End]:: Will show this screen if message value is 'succeeded' -->
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- For 3Ds verification pop-up modal -->
<app-three-d-secure-modal (onCloseModal)="closeVerificationModal($event)">
</app-three-d-secure-modal>
