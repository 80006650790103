import {
	Component,
	Inject,
	ChangeDetectorRef,
	AfterViewInit,
} from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { BaseUserComponent } from './../../../../../../shared/components/user/base-user.component';

import { GroupsAndPermissionsService } from './../../../../../groups-and-permissions/groups-and-permissions.service';
import { SpinnerService } from './../../../../../../core/services/spinner.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { UserService } from './../../../../../../core/services/user.service';

@Component({
	selector: 'app-client-admin-permission',
	templateUrl: './client-admin-permission.component.html',
	styleUrls: ['./client-admin-permission.component.scss'],
})
export class ClientAdminPermissionComponent
	extends BaseUserComponent
	implements AfterViewInit
{
	invoiceData: any[] = [];
	isChanged: boolean;

	constructor(
		userService: UserService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ClientAdminPermissionComponent>,
		public spinner: SpinnerService,
		private _cd: ChangeDetectorRef,
		private _companiesService: CompaniesService,
		private _permissionService: GroupsAndPermissionsService
	) {
		super(userService);
	}

	ngAfterViewInit() {
		this.getInvoiceViaPermission();
	}

	getInvoiceViaPermission() {
		this._permissionService
			.getMenuPermissionsList(0, this.data.companyId, 0, this.data.userId)
			.subscribe({
				next: (data) => {
					const invoiceData = data.filter((invoice: any) => {
						return invoice.name === 'Invoices';
					});
					this.invoiceData = invoiceData[0].actions;
					this.getPermissionOverride();
				},
			});
	}

	getPermissionOverride() {
		this._companiesService
			.getOverride(this.data.companyId, this.data.userId)
			.subscribe({
				next: (data: any) => {
					this.isChanged = data.isChanged;
				},
			});
	}

	changeMenuPermission(data: any) {
		const option = {
			roleId: 0,
			companyId: Number(this.data.companyId),
			groupId: 0,
			userId: this.data.userId,
			isAllowed: !data.isAllowed,
			menuActionIds: [data.id],
		};
		const overrideData = {
			companyId: Number(this.data.companyId),
			userId: this.data.userId,
			isChanged: true,
		};

		this.spinner.start();
		this._permissionService
			.setMenuActionPermission(option)
			.pipe(finalize(() => this.spinner.stop()))
			.subscribe({
				complete: () => {
					this._cd.detectChanges();
				},
			});
	}
}
