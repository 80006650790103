export class TooltipContentV2Service {

  positionTop() {
    return [
      this._topCenter,
      this._topStart,
      this._topEnd,
      this._bottomCenter,
      this._bottomStart,
      this._bottomEnd,
      this._leftCenter,
      this._leftTop,
      this._leftBottom,
      this._rightCenter,
      this._rightTop,
      this._rightBottom
    ]   
  }

  positionBottom() {
    return [
      this._bottomCenter,
      this._bottomStart,
      this._bottomEnd,
      this._topCenter,
      this._topStart,
      this._topEnd,
      this._leftCenter,
      this._leftTop,
      this._leftBottom,
      this._rightCenter,
      this._rightTop,
      this._rightBottom
    ]   
  }

  positionLeft() {
    return [
      this._leftCenter,
      this._leftTop,
      this._leftBottom,
      this._rightCenter,
      this._rightTop,
      this._rightBottom,
      this._bottomCenter,
      this._bottomStart,
      this._bottomEnd,
      this._topCenter,
      this._topStart,
      this._topEnd,
    ]   
  }

  positionRight() {
    return [
      this._rightCenter,
      this._rightTop,
      this._rightBottom,
      this._leftCenter,
      this._leftTop,
      this._leftBottom,
      this._bottomCenter,
      this._bottomStart,
      this._bottomEnd,
      this._topCenter,
      this._topStart,
      this._topEnd,
    ]   
  }

  get _topCenter() {
    return {
     originY: 'top',
     originX: 'center',
     overlayX: 'center',
     overlayY: 'bottom',
     offsetX: 0,
     offsetY: -9,
   }
  }
 
  get _topStart() {
    return {
     originY: 'top',
     originX: 'start',
     overlayX: 'start',
     overlayY: 'bottom',
     offsetX: 0,
     offsetY: -9,
   }
  }
 
  get _topEnd() {
    return {
     originY: 'top',
     originX: 'end',
     overlayX: 'end',
     overlayY: 'bottom',
     offsetX: 0,
     offsetY: -9,
   }
  }
 
  get _bottomCenter() {
    return {
     originY: 'bottom',
     originX: 'center',
     overlayX: 'center',
     overlayY: 'top',
     offsetX: 0,
     offsetY: 9,
   }
  }
 
  get _bottomStart() {
    return {
     originY: 'bottom',
     originX: 'start',
     overlayX: 'start',
     overlayY: 'top',
     offsetX: 0,
     offsetY: 9,
   }
  }
 
  get _bottomEnd() {
    return {
     originY: 'bottom',
     originX: 'end',
     overlayX: 'end',
     overlayY: 'top',
     offsetX: 0,
     offsetY: 9,
   }
  }
 
  get _rightCenter() {
    return {
     originY: 'center',
     originX: 'end',
     overlayX: 'start',
     overlayY: 'center',
     offsetX: 9,
     offsetY: 0,
   }
  }
 
  get _rightTop() {
    return {
     originY: 'top',
     originX: 'end',
     overlayX: 'start',
     overlayY: 'top',
     offsetX: 9,
     offsetY: 0,
   }
  }
 
  get _rightBottom() {
    return {
     originY: 'bottom',
     originX: 'end',
     overlayX: 'start',
     overlayY: 'bottom',
     offsetX: 9,
     offsetY: 0,
   }
  }
 
  get _leftCenter() {
    return {
     originY: 'center',
     originX: 'start',
     overlayX: 'end',
     overlayY: 'center',
     offsetX: -9,
     offsetY: 0,
   }
  }
 
  get _leftTop() {
    return {
     originY: 'top',
     originX: 'start',
     overlayX: 'end',
     overlayY: 'top',
     offsetX: -9,
     offsetY: 0,
   }
  }
 
  get _leftBottom() {
    return {
     originY: 'bottom',
     originX: 'start',
     overlayX: 'end',
     overlayY: 'bottom',
     offsetX: -9,
     offsetY: 0,
   }
  }
}
